import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
import { Field, reduxForm } from "redux-form";

import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';

// import { Grid, Button, FormHelperText, Typography } from '@material-ui/core';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import Typography from '@material-ui/core/Typography';

import { RenderTextField } from './subcomponents/form_subcomponents/renderTextField';

import { validateEmail } from '../components/helpers/helperFunctions';

import { forgotPassword, logout } from "../actions/actions_index";

class Forgot extends Component {

  constructor(props) {
    super(props);

    this.state = {
      forgotSubmitted: false
    }

    this.props.logout()
    
    //resets form fields
    this.props.initialize();
    //resets any error messages
    this.props.forgotPassword();
  }

  onSubmit = (values) => {
    values.email = values.email.toLowerCase();

    this.props.forgotPassword(values, () => {
      this.setState({
        forgotSubmitted: true
      })
    });
  }

  formNotificationInsert = () => {
    const { classes } = this.props;

    if (this.props.formError) {
      // return (
      //   <FormHelperText className={`${classes.centerInfoText} ${classes.centerErrorText}`}>{this.props.formError}</FormHelperText>
      // )
      return (
        <FormHelperText className={classes.centerInfoText}>If this is an email in our system, you will receive an email to reset your password.</FormHelperText>
      )
    } else if (this.state.forgotSubmitted) {
      return (
        <FormHelperText className={classes.centerInfoText}>If this is an email in our system, you will receive an email to reset your password.</FormHelperText>
      )
    } else {
      return '';
    }
  }

	render() {
	  const { classes, handleSubmit, pristine, invalid, token } = this.props;

    if (!_.isEmpty(token)) {
      return (
        <Redirect to='/content/library'/>
      )
    }

	  return (
      <div className={classes.root}>
        <Grid container spacing={4}>
	        <Grid item lg={4} md={3} sm={false} xs={false} />
          <Grid item lg={4} md={6} sm={12} xs={12} >
            <div className={classes.loginContainer}>
            <Typography className={classes.headingBig} type="display1" color="primary">Forgot Password?</Typography>
            <br />
            <div>
              {this.formNotificationInsert()}
            </div>
            <form className={classes.container} noValidate autoComplete="off" onSubmit={handleSubmit(this.onSubmit)}>
              
              <Field name="email" component={RenderTextField} type='email' label="Email" autoFocus />
              
              <Button className={classes.buttonWide} variant="contained" type="submit" color="secondary" disabled={pristine || invalid} >Submit</Button>
            </form>
            </div>
          </Grid>
          <Grid item lg={4} md={3} sm={false} xs={false} />
	      </Grid>
	    </div>
	  );
	}
}

function validate(values) {
  const errors = {};

  // Validate the inputs from 'values'
  const requiredFields = [
    'email'
  ];

  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = 'Required';
    }
  });

  if (values.email && validateEmail(values.email)) {
    errors.email = 'Invalid email address';
  }

  // If errors is empty, the form is fine to submit
  // If errors has *any* properties, redux form assumes form is invalid
  return errors;
}

function mapStateToProps(state) {
  let formError = null

  if (state.forgot && state.forgot.error) {
    formError = state.forgot.error
  }

  return { 
    token: state.login.token,
    formError
  };
}

const mapDispatchToProps = dispatch => ({
  forgotPassword: (token, values, callback) => dispatch(forgotPassword(token, values, callback)),
  logout: () => dispatch(logout())
});

export default reduxForm({
  validate,
  form: 'ForgotPasswordForm'
})(withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Forgot))));
