import _ from "lodash";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from 'moment';

import { withStyles } from '@material-ui/core/styles';
import { styles } from '../styles';

import Grid from '@material-ui/core/Grid';

import { DashboardFormContainer } from './analytics_subcomponents/dashboard_form';
import Dashboard from './dashboard';
import Users from './users';
import Contacts from './contacts';
import Content from './content';
import Showcases from './showcases';
import Sessions from './sessions';
import Screens from './screens';

import { checkAlerts } from '../helpers/helperFunctions';

class Analytics extends PureComponent {

	constructor(props) {
    super(props);

    const { companyId, enterprise } = this.props;

    let ALL = 'All App Profiles';

    if (!enterprise) {
    	ALL = 'All Teams';
    }

    const date = new Date();
    const today = `${date.toDateString()}_${date.toTimeString()}`;
    const timezone_offset = new Date().getTimezoneOffset() / 60;

    this.state = {
      analyticsRole: '0',
      appProfile: '0',
      appProfiles: {},
      analytics: {
      	dashboard: null,
      	contacts: null,
      	content: null,
      	showcases: null,
      	sessions: null
      },
      postData: {
        company: companyId,
        app_profile: '0',
        all: ALL,
        app_profile_name: ALL,
        today,
        timezone_offset,
        start_date: moment().subtract(1, 'month'),
        end_date: moment()
      }
    }
  }

  setPostData = (data) => {
    this.setState({
      postData: data
    })
  }

	render() {
		const { company, enterprise, alerts, classes, page } = this.props;
		const { postData, appProfiles } = this.state;
		const alertsRoot = checkAlerts(company, alerts, classes);

		let alertsBelowDashboardForm = null;

		if (!enterprise && Object.keys(alerts).length > 0) {
		  alertsBelowDashboardForm = classes.alertsBelowDashboardForm;
		}

		return (
			//Form
			<div className={`${classes.root} ${alertsRoot} ${classes.rootExtendedHeader} ${classes.rootExtendedHeaderAnalytics}`} style={{height: '100%', overflowY: 'auto'}}>
				<DashboardFormContainer 
				  appProfiles={appProfiles}
				  setPostData={this.setPostData}
				  initialValues={postData}
				/>
				
				<Grid container spacing={1} className={`${classes.belowDashboardForm} ${alertsBelowDashboardForm}`}>
					{page === 'dashboard' && 
						<Dashboard
							page={page}
							postData={postData}
						/>
					}

					{page === 'users' && 
						<Users
							page={page}
							postData={postData}
						/>
					}

					{page === 'contacts' && 
						<Contacts
							page={page}
							postData={postData}
						/>
					}

					{page === 'content' && 
						<Content
							page={page}
							postData={postData}
						/>
					}

					{page === 'showcases' && 
						<Showcases
							page={page}
							postData={postData}
						/>
					}

					{page === 'sessions' && 
						<Sessions
							page={page}
							postData={postData}
						/>
					}

					{page === 'screens' && 
						<Screens
							page={page}
							postData={postData}
						/>
					}
				</Grid>
			</div>
		)

  }

}

function mapStateToProps(state) {
  if (_.isEmpty(state.login.token) || _.isEmpty(state.login.company) || _.isEmpty(state.login.user)) {
    return {
      loggedOut: true
    }
  }

  return { 
    token: state.login.token,
    companyId: state.login.company._id,
    company: state.company,
    enterprise: state.company.enterprise,
    alerts: state.alerts
  };
}

export default withRouter(connect(mapStateToProps, null)(withStyles(styles)(Analytics)));

