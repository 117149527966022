import React from 'react';
import { connect } from 'react-redux';
import { Field } from "redux-form";

import MenuItem from '@material-ui/core/MenuItem';

import { RenderSelectField } from './renderSelectField';
import { RenderMultipleSelectField } from './renderMultipleSelectField';

const adminMenu = (admins) => {
  if (admins.length > 0) {
    return (
      admins.map(value => <MenuItem key={value._id} value={value._id}>{value.name}</MenuItem>)
    )
  }
}

const RenderOwnerEditableByMenuContainer = ({ enterprise, currentUser, admins, initialValues, selectedId }) => {
  if (enterprise) {
    return (
      <div>
        <Field
          name="editable_by"
          label="Editable By"
          component={RenderSelectField}
        >
          <MenuItem key='0' value='0'>Anyone</MenuItem>
          <MenuItem key='1' value='1'>Owners Only</MenuItem>
        </Field>
        <Field
          name="owners"
          label="Owners"
          component={RenderMultipleSelectField}
        >
          {adminMenu(admins)}
        </Field>
      </div>
    )
  } else {
    return null;
  } 
}

function mapStateToProps(state) {
  return { 
    enterprise: state.login.company.enterprise
  };
}

export const RenderOwnerEditableByMenu = connect(mapStateToProps, null)(RenderOwnerEditableByMenuContainer);