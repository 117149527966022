import _ from "lodash";
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from "redux-form";
import axios from "axios";

import { withStyles } from '@material-ui/core/styles';
import { styles } from '../styles';

import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Modal from '@material-ui/core/Modal';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import Close from '@material-ui/icons/Close';
import AddCircle from '@material-ui/icons/AddCircle';
import Refresh from '@material-ui/icons/Refresh';
import Star from '@material-ui/icons/Star';
import StarHalf from '@material-ui/icons/StarHalf';
import StarBorder from '@material-ui/icons/StarBorder';
import SupervisorAccount from '@material-ui/icons/SupervisorAccount';
import AvTimer from '@material-ui/icons/AvTimer';
import PeopleOutline from '@material-ui/icons/PeopleOutline';
import Visibility from '@material-ui/icons/Visibility';

import Counter from 'mdi-material-ui/Counter';

import LoadingDivContainer from '../subcomponents/loadingDiv';
import WithLoading from '../subcomponents/withLoading';
import GridContainer from '../subcomponents/gridContainer';

import { handleAppUser, getCorePlayerUrl, getCoreAnalyticsUrl, getVideoApiUrlandKey } from '../helpers/helperFunctions';

import { fetchLibraryItem } from '../../actions/actions_index';

class LibraryItemDetailsContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      data: null,
      tabValue: 0,
      copiedLink: null,
      copiedEmbed: null
    }

    this.getData()
  }

  getData = () => {
    const { libraryItem } = this.props,
          { path } = libraryItem ? libraryItem.details : {},
          // { INTERACTIVE_ANALYTICS_URL, INTERACTIVE_ANALYTICS_API_KEY } = getVideoApiUrlandKey()
          { INTERACTIVE_ANALYTICS_URL } = getVideoApiUrlandKey()

    axios.get(`${INTERACTIVE_ANALYTICS_URL}${path}`,
      // { 
      //   headers: { 
      //     'x-api-key': INTERACTIVE_ANALYTICS_API_KEY
      //   }
      // }
      )
      .then(result => {
        this.setState({
          loading: false,
          data: result.data
        })
      })
      .catch(error => console.log(error.response.data))
  }

  handleTabChange = (event, tabValue) => {
    this.setState({ tabValue });
  }

  copyLink = (type, link) => {
    navigator.clipboard.writeText(link)

    if (type === 'link') {
      this.setState({
        copiedLink: 'Copied!'
      })
    } else if (type === 'embed') {
      this.setState({
        copiedEmbed: 'Copied!'
      })
    }
    
  }

  handleViewersAndInteractions = () => {
    const { classes } = this.props,
          { data } = this.state,
          { interactions, page_landings, viewers, total_views, avg_watch_time, avg_attention_span } = data ? data : {}

    const rows = interactions

    const columns = [
      { name: 'name', title: 'Name' },
      { name: 'type', title: 'Type' },
      { name: 'clicks', title: 'Clicks' }
    ];

    const tableColumnExtensions = [
      { columnName: 'name'},
      { columnName: 'type', width: 120 },
      { columnName: 'clicks', width: 200 }
    ]

    const sortingStateColumnExtensions = [
      { columnName: 'name', sortingEnabled: true },
      { columnName: 'type', sortingEnabled: true },
      { columnName: 'clicks', sortingEnabled: true }
    ]

    const filteringStateColumnExtensions = [
      { columnName: 'name', filteringEnabled: true },
      { columnName: 'type', filteringEnabled: false },
      { columnName: 'clicks', filteringEnabled: false }
    ]

    const sorting = [
      { columnName: 'clicks', direction: 'desc' }
    ]

    const rows2 = viewers

    const columns2 = [
      { name: 'viewer', title: 'Viewer' },
      { name: 'views', title: 'Views' },
      { name: 'watch_time', title: 'Watch Time' },
      { name: 'attention_span', title: 'Attention Span' },
      { name: 'interactions', title: 'Interactions'}
    ];

    const tableColumnExtensions2 = [
      { columnName: 'viewer', width: 300},
      { columnName: 'views', width: 120 },
      { columnName: 'watch_time', width: 120 },
      { columnName: 'attention_span', width: 120 },
      { columnName: 'interactions' }
    ]

    const sortingStateColumnExtensions2 = [
      { columnName: 'viewer', sortingEnabled: true },
      { columnName: 'views', sortingEnabled: true },
      { columnName: 'watch_time', sortingEnabled: true },
      { columnName: 'attention_span', sortingEnabled: true },
      { columnName: 'interactions', sortingEnabled: false }
    ]

    const filteringStateColumnExtensions2 = [
      { columnName: 'viewer', filteringEnabled: false },
      { columnName: 'views', filteringEnabled: false },
      { columnName: 'watch_time', filteringEnabled: false },
      { columnName: 'attention_span', filteringEnabled: false },
      { columnName: 'interactions', filteringEnabled: false }
    ]

    const sorting2 = [
      { columnName: 'watch_time', direction: 'desc' }
    ]

    return (
      <div className={classes.interactiveVideoAnalyticsContainer}>
        <Grid container spacing={4} className={classes.gridSmallMarginTop}>
          <Grid item md={3} xs={6} className={classes.bigNumberContainerInteractiveVideoAnalytics}>
            <Typography variant="h5" className={classes.bigNumberHeading}><SupervisorAccount className={classes.bigNumberHeadingIcon} /> 
              Page Landings
            </Typography>
            <div className={`${classes.bigNumberBox} ${classes.contactsBox}`}>
              <Typography variant="h2" className={classes.bigNumber}>
                {page_landings ? page_landings : 0}
              </Typography>
            </div>
          </Grid>
          <Grid item md={3} xs={6} className={classes.bigNumberContainerInteractiveVideoAnalytics}>
            <Typography variant="h5" className={classes.bigNumberHeading}><Counter className={classes.bigNumberHeadingIcon} /> 
              Total Views
            </Typography>
            <div className={`${classes.bigNumberBox} ${classes.contactsBox}`}>
              <Typography variant="h2" className={classes.bigNumber}>
                {total_views ? total_views : 0}
              </Typography>
            </div>
          </Grid>
          <Grid item md={3} xs={6} className={classes.bigNumberContainerInteractiveVideoAnalytics}>
            <Typography variant="h5" className={classes.bigNumberHeading}><AvTimer className={classes.bigNumberHeadingIcon} /> 
              Avg Watch Time
            </Typography>
            <div className={`${classes.bigNumberBox} ${classes.sessionsBox}`}>
              <Typography variant="h2" className={classes.bigNumber}>
                {avg_watch_time ? avg_watch_time : '00:00'}
              </Typography>
            </div>
          </Grid>
          <Grid item md={3} xs={6} className={classes.bigNumberContainerInteractiveVideoAnalytics}>
            <Typography variant="h5" className={classes.bigNumberHeading}><Visibility className={classes.bigNumberHeadingIcon} /> 
              Avg Attention Span
            </Typography>
            <div className={`${classes.bigNumberBox} ${classes.sessionsBox}`}>
              <Typography variant="h2" className={classes.bigNumber}>
                {avg_attention_span ? avg_attention_span : '0%'}
              </Typography>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={4} className={`${classes.gridMarginBottom} ${classes.gridMediumMarginTop} ${classes.gridNoSideMargins}`}>
          <Grid item xs={12} className={classes.topRepsContainer}>
            <Typography variant="subtitle1" className={`${classes.bigNumberHeading} ${classes.topRepsHeading}`}>
                Interactions
              </Typography>
            <div className={`${classes.repsBox}`}>
              <GridContainer 
                gridHeight={{position: 'relative', top: -5}}
                tableType={'iv-analytics'}
                rows={rows}
                columns={columns}
                hideSearch={true}
                tableColumnExtensions={tableColumnExtensions}
                sortingStateColumnExtensions={sortingStateColumnExtensions}
                sorting={sorting}
                filteringStateColumnExtensions={filteringStateColumnExtensions}
                showSelectAll={false}
                showSelectionColumn={false}
                showRowDetail={false}
                classes={classes}
              />
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={4} className={`${classes.gridMarginBottom} ${classes.gridMediumMarginTop} ${classes.gridNoSideMargins}`}>
          <Grid item xs={12} className={classes.topRepsContainer}>
            <Typography variant="subtitle1" className={`${classes.bigNumberHeading} ${classes.topRepsHeading}`}>
                Recipients
              </Typography>
            <div className={`${classes.repsBox}`}>
              <GridContainer 
                gridHeight={{position: 'relative', top: -5}}
                tableType={'iv-analytics'}
                rows={rows2}
                columns={columns2}
                hideSearch={true}
                tableColumnExtensions={tableColumnExtensions2}
                sortingStateColumnExtensions={sortingStateColumnExtensions2}
                sorting={sorting2}
                filteringStateColumnExtensions={filteringStateColumnExtensions2}
                showSelectAll={false}
                showSelectionColumn={false}
                showRowDetail={false}
                classes={classes}
              />
            </div>
          </Grid>
        </Grid>
      </div>
    )
  }

  render() {
    const { libraryItem, closedLibraryItemDetailsModal, classes } = this.props,
          { tabValue, copiedLink, copiedEmbed, loading } = this.state,
          { name, path } = libraryItem ? libraryItem.details : {},
          playerLink = `${getCorePlayerUrl()}?client_id=abc&user_id=111&video_id=${path}`,
          analyticsLink = `${getCoreAnalyticsUrl()}${path}?suid=936179`,
          embedCode = `<iframe src="${playerLink}" width="100%" height="100%" title="Interactive Video Player" frameborder=“0" autoplay allowfullscreen></iframe>`

    return (
        <div style={{height: 'calc(100% - 75px)'}}>  
          <AppBar position="static" className={classes.modalTabBar}>
            <Tabs value={tabValue} onChange={this.handleTabChange}>
              <Tab label="Direct Link" className={classes.modalTab} />
              <Tab label="Embed Code" className={classes.modalTab} />
              <Tab label="Analytics" className={classes.modalTab} />
            </Tabs>
          </AppBar>
          {tabValue === 0 &&
            <Grid container spacing={3} className={`${classes.gridExtraSpacing}`}>
              <Grid item xs={12}>
                <Typography variant="subtitle1" className={`${classes.sharedLink}`}>
                  <div className={classes.sharedHeadingInteractiveVideoDetails}>Direct Interactive Video Link:</div> 
                  <div className={classes.codeBlockArea}>{playerLink}</div>
                  <div style={{marginTop: 10}}>
                    <a className={classes.previewSpaceLink} onClick={() => this.copyLink('link', playerLink)}>Copy Link</a>
                    {copiedLink && <span className={classes.copiedLink}>{copiedLink}</span>}
                  </div>
                </Typography>
              </Grid>
            </Grid>
          }
          {tabValue === 1 &&
            <Grid container spacing={3} className={`${classes.gridExtraSpacing}`}>
              <Grid item xs={12}>
                <Typography variant="subtitle1" className={`${classes.sharedLink}`}>
                  <div className={classes.sharedHeadingInteractiveVideoDetails}>Interactive Video Embed Code:</div> 
                  <div className={classes.codeBlockArea}>{embedCode}</div>
                  <div style={{marginTop: 10}}>
                    <a className={classes.previewSpaceLink} onClick={() => this.copyLink('embed', embedCode)}>Copy Embed Code</a>
                    {copiedEmbed && <span className={classes.copiedLink}>{copiedEmbed}</span>}
                  </div>
                </Typography>
              </Grid>
            </Grid>
          }
          {(tabValue === 2 && loading) &&
            <React.Fragment>
              <Typography variant="h4" style={{textAlign: 'center', marginTop: 30}}>Loading analytics, may take up to 1 min.</Typography>
              <LoadingDivContainer type="interactive-analytics" />
            </React.Fragment>
          }
          {(tabValue === 2 && !loading) && 
            this.handleViewersAndInteractions(path)
          }
          {/*<Button 
            className={`${classes.button} ${classes.modalButton}`}
            variant="contained"
            color="secondary"
            onClick={() => closedLibraryItemDetailsModal()}
          >
            Close
          </Button>*/}
        </div>
      )
    }
}

function mapStateToProps(state) {
  return { 
    token: state.login.token,
    companyId: state.login.company._id,
    enterprise: state.login.company.enterprise,
    owner: state.login.user._id,
    appUser: handleAppUser(state),
    currentUser: state.login.user,
    currentRole: state.login.user.user_type,
    libraryItem: state.libraryItem
  };
}

const mapDispatchToProps = dispatch => ({
  fetchLibraryItem: (token, id, callback) => dispatch(fetchLibraryItem(token, id, callback))
});


class LibraryItemDetailsModal extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loadingLibraryItem: true
    }
  }

  componentDidMount() {
    const { token, companyId, selectedLibraryItemId } = this.props;

    this.props.fetchLibraryItem(token, selectedLibraryItemId, libraryItem => {
      this.setState({
        loadingLibraryItem: false
      })
    })
  }

  handleModalBody = () => {
    const { libraryItem, selectedLibraryItemId, classes, closedLibraryItemDetailsModal } = this.props;
    const { loadingLibraryItem } = this.state;
    const { name } = (libraryItem && libraryItem.details) ? libraryItem.details : {}

    if (loadingLibraryItem) {
      return (
        <LoadingDivContainer />
      )
    } else {

      return (
        <div className={classes.divContainer}>
          <div> 
            <Typography variant="h6">
              Interactive Video Details - <span className={classes.modalTitleItem}>{name}</span>
            </Typography>
            <Typography variant="subtitle1" className={classes.modalSubheading}>
              View Direct Link, Embed Code, and Analytics of Interactive Video.
            </Typography>
          </div>
          <LibraryItemDetailsContainer
            libraryItem={libraryItem}
            closedLibraryItemDetailsModal={closedLibraryItemDetailsModal}
            classes={classes}
          />
        </div>
      )
    }
  }

  render() {
    const { openLibraryItemDetailsModal, closedLibraryItemDetailsModal, classes } = this.props;
    const { updated } = this.state;

    return (
      <Modal
        aria-labelledby="preview-library-item"
        aria-describedby="preview-library-item"
        open={openLibraryItemDetailsModal}
        onClose={() => closedLibraryItemDetailsModal()}
      >
        <div className={`${classes.modal} ${classes.fullModalAnalytics}`}>
          <IconButton 
            className={classes.iconClose}
            onClick={() => closedLibraryItemDetailsModal()}
          >
            <Close />
          </IconButton>
          {this.handleModalBody()}
        </div>
      </Modal>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(LibraryItemDetailsModal));
