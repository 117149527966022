import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';
import { styles } from '../../styles';

// import { FormControl, FormControlLabel } from '@material-ui/core';

import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { BlockPicker } from 'react-color';

class RenderColorPicker extends Component {
  constructor(props) {
    super(props);
    const { selectedColor } = this.props;
    
    this.state = {
      displayColorPicker: false,
      color: {
        hex: selectedColor
      }
    }
  }

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker })
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false })
  };

  handleChange = (color) => {
    this.setState({ color: color });
    this.props.handleColor(this.props.label, color.hex);
  };

  render() {
    const { label, classes, selectedColor } = this.props;
    const { displayColorPicker, color } = this.state;

    return (
        <FormControl margin="none">
          <FormControlLabel
            label={label}
            className={classes.colorPickerLabel}
            control={
              <div style={{position: 'relative'}}>
                <div className={classes.swatchPicker} onClick={this.handleClick} >
                  <div className={classes.colorPicker} style={{backgroundColor: `${selectedColor}`}} />
                </div>
                {displayColorPicker ? <div className={classes.popoverPicker} >
                  <div className={classes.coverPicker} onClick={this.handleClose}/>
                  <BlockPicker color={color} triangle={'hide'} onChange={this.handleChange} />
                </div> : null }
              </div>
            }
          />
        </FormControl>
    )
  }
}

export default withStyles(styles)(RenderColorPicker)