import _ from "lodash";
import React, { Component } from "react";
import { compose } from 'redux';
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";

import { withStyles } from '@material-ui/core/styles';
import { styles } from '../../styles';

import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';

import WithLoading from '../../subcomponents/withLoading';
import { RenderSelectField } from '../../subcomponents/form_subcomponents/renderSelectField';
import RenderDatepicker from '../../subcomponents/form_subcomponents/renderDatepicker';

import { fetchAppProfiles } from "../../../actions/actions_index";

class DashboardForm extends Component {

  constructor(props) {
    super(props);

    const { initialValues } = this.props;

    this.state = {
      startDate: initialValues.start_date,
      endDate: initialValues.end_date
    }
  }

  setDates = (startDate, endDate) => {
    this.setState({ startDate, endDate })
  }

  submitDashboard = (values) => {
    const { companyId, enterprise, appProfiles, setPostData } = this.props;
    const { startDate, endDate } = this.state;
    // const { dashboard_role, app_profile } = values;
    const { app_profile } = values;

    const date = new Date();
    const today = `${date.toDateString()}_${date.toTimeString()}`;

    const timezone_offset = new Date().getTimezoneOffset() / 60;

    let app_profile_name = '';
    // setAnalyticsParameters(dashboard_role, app_profile);

    let ALL = 'All App Profiles';

    if (!enterprise) {
      ALL = 'All Teams';
    }

    if (app_profile === '0') {
      app_profile_name = ALL;
    } else {
      app_profile_name = _.find(appProfiles, {_id: app_profile}).name;
    }

    let postData = {
      company: companyId,
      app_profile,
      app_profile_name,
      today,
      timezone_offset,
      start_date: startDate.format('YYYY-MM-DD').toString(),
      end_date: endDate.format('YYYY-MM-DD').toString()
    }

    if (!app_profile) {
      delete postData.app_profile;
    }

    setPostData(postData)
    this.setState({ startDate, endDate })
  }

  render() {
    const { enterprise, verb_live, alerts, appProfiles, handleSubmit, classes } = this.props;
    const { startDate, endDate } = this.state;

    let alertsDashboardForm = null,
        dropdownText = 'App Profile',
        dropdownAllText = 'All App Profiles'

    if (!enterprise && Object.keys(alerts).length > 0) {
      alertsDashboardForm = classes.alertsDashboardForm
      dropdownText = 'Team'
      dropdownAllText = 'All Teams'
    }

    //dashboard_role -> selected dashBoardRole
    return (
      <div className={`${classes.dashboardFormWrapper} ${alertsDashboardForm}`}>
        <div className={classes.dashboardFormContainer}>
          <form noValidate autoComplete="off" className={classes.dashboardForm} onSubmit={handleSubmit(values => this.submitDashboard(values))}>
            <div className={classes.dashboardFormInsideContainer}>
              {/*<Grid item xs={3} className={classes.dashboardFormItem}>
                <Field
                  name="dashboard_role"
                  label="Role"
                  component={RenderSelectField}
                  marginClass={'none'}
                >
                  <MenuItem key='0' value='0'>System</MenuItem>
                  <MenuItem key='1' value='1'>Marketing</MenuItem>
                  <MenuItem key='3' value='3'>Sales</MenuItem>
                </Field>
              </Grid>*/}
              {(enterprise || !verb_live) &&
                <div className={`${classes.dashboardFormItem} ${classes.dashboardFormSelect}`}>
                  <Field
                    name="app_profile"
                    label={dropdownText}
                    component={RenderSelectField}
                    marginClass={'none'}
                    className={classes.dropdownDashboard}
                  >
                    <MenuItem key='0' value='0'>{dropdownAllText}</MenuItem>
                    {appProfiles.map((appProfile) => 
                      <MenuItem key={appProfile._id} value={appProfile._id}>{appProfile.name}</MenuItem>  
                    )}
                  </Field>
                </div>
              }
              <div className={classes.dashboardFormItem}>
                <RenderDatepicker
                  initialStartDate={startDate}
                  initialEndDate={endDate}
                  setDates={this.setDates}
                />
              </div>
              <div className={classes.dashboardFormItem}>
                <Button className={classes.dashboardButton} variant="contained" color="secondary" type="submit">Submit</Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    )

  }
}

const DashboardFormWithLoading = WithLoading(DashboardForm)

class DashboardFormWrapper extends Component {
  state = {
    loading: true
  }

  componentDidMount() {
    const { token, companyId } = this.props;

    this.props.fetchAppProfiles(token, companyId, () => {
      this.setState({
        loading: false
      })
    })
  }

  render() {
    const loading = this.state.loading;

    return (
      <DashboardFormWithLoading 
        isLoading={loading}
        form={true}
        {...this.props}
      />
    )
  }
}

function mapStateToProps(state) {
  if (_.isEmpty(state.login.token) || _.isEmpty(state.login.company) || _.isEmpty(state.login.user)) {
    return {
      loggedOut: true
    }
  }

  return { 
    token: state.login.token,
    companyId: state.login.company._id,
    enterprise: state.company.enterprise,
    verb_live: state.company.verb_live,
    alerts: state.alerts,
    owner: state.login.user._id,
    currentUser: state.login.user,
    currentRole: state.login.user.user_type,
    appProfiles: state.appProfiles
  };
}

const mapDispatchToProps = dispatch => ({
  fetchAppProfiles: (token, companyId, callback) => dispatch(fetchAppProfiles(token, companyId, callback))
});

function validate(values, props) {
  const errors = {};

  return errors;
}

export const DashboardFormContainer = compose(
  reduxForm({
    form: 'Dashboard',
    validate,
    enableReinitialize: true,
  }),
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(DashboardFormWrapper)