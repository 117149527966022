import _ from 'lodash';
import { ROOT_URL } from '../../actions/actions_constants';
import React from 'react';
import axios from 'axios';
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
import { useMediaQuery } from 'react-responsive';

import { energy } from '../../theme';

export const CREATE_ACCOUNT = 'create_account';
export const CREATE_YOUR_SOLOFIRE_ACCOUNT = 'create_your_solofire_account';

momentDurationFormatSetup(moment);
const humanizeDuration = require('humanize-duration');

export const environment = () => {
  const environment = process.env.REACT_APP_ENV;

  return environment;
};

export const siteUrl = () => {
  const environment = process.env.REACT_APP_ENV;
  let SITE_URL = '';

  switch (environment) {
    case 'local':
      SITE_URL = process.env.REACT_APP_LOCAL_URL;
      break;
    case 'staging':
      SITE_URL = process.env.REACT_APP_DEV_URL;
      break;
    case 'production':
      SITE_URL = process.env.REACT_APP_PROD_URL;
      break;
    default:
      break;
  }

  return SITE_URL;
};

export const getLiveStreamUrl = () => {
  const environment = process.env.REACT_APP_ENV;
  let LIVE_STREAM_URL = '';

  switch (environment) {
    case 'local':
      LIVE_STREAM_URL = process.env.REACT_APP_LIVE_STAGING_URL;
      break;
    case 'staging':
      LIVE_STREAM_URL = process.env.REACT_APP_LIVE_STAGING_URL;
      break;
    case 'production':
      LIVE_STREAM_URL = process.env.REACT_APP_LIVE_URL;
      break;
    default:
      break;
  }

  return LIVE_STREAM_URL;
};

export const getAttendeeLiveStreamUrl = () => {
  const environment = process.env.REACT_APP_ENV;
  let LIVE_STREAM_URL = '';

  switch (environment) {
    case 'local':
      LIVE_STREAM_URL = process.env.REACT_APP_LIVE_STAGING_ATTENDEE_URL;
      break;
    case 'staging':
      LIVE_STREAM_URL = process.env.REACT_APP_LIVE_STAGING_ATTENDEE_URL;
      break;
    case 'production':
      LIVE_STREAM_URL = process.env.REACT_APP_LIVE_ATTENDEE_URL;
      break;
    default:
      break;
  }

  return LIVE_STREAM_URL;
};

export const getCoreUrl = () => {
  const environment = process.env.REACT_APP_ENV;
  let CORE_URL = '';

  switch (environment) {
    case 'local':
      CORE_URL =
        'https://deploy-preview-2727--frontend-apps-core.netlify.app/bundle.js';
      break;
    case 'staging':
      CORE_URL = process.env.REACT_APP_STAGING_CORE_URL;
      break;
    case 'production':
      CORE_URL = process.env.REACT_APP_CORE_URL;
      break;
    default:
      break;
  }

  return CORE_URL;
};

export const getCorePlayerUrl = () => {
  const environment = process.env.REACT_APP_ENV;
  let CORE_PLAYER_URL = '';

  switch (environment) {
    case 'local':
      CORE_PLAYER_URL = process.env.REACT_APP_STAGING_CORE_PLAYER_URL;
      break;
    case 'staging':
      CORE_PLAYER_URL = process.env.REACT_APP_STAGING_CORE_PLAYER_URL;
      break;
    case 'production':
      CORE_PLAYER_URL = process.env.REACT_APP_CORE_PLAYER_URL;
      break;
    default:
      break;
  }

  return CORE_PLAYER_URL;
};

export const getCoreAnalyticsUrl = () => {
  const environment = process.env.REACT_APP_ENV;
  let CORE_ANALYTICS_URL = '';

  switch (environment) {
    case 'local':
      CORE_ANALYTICS_URL = process.env.REACT_APP_STAGING_CORE_ANALYTICS_URL;
      break;
    case 'staging':
      CORE_ANALYTICS_URL = process.env.REACT_APP_STAGING_CORE_ANALYTICS_URL;
      break;
    case 'production':
      CORE_ANALYTICS_URL = process.env.REACT_APP_CORE_ANALYTICS_URL;
      break;
    default:
      break;
  }

  return CORE_ANALYTICS_URL;
};

export const getVideoApiUrlandKey = () => {
  const environment = process.env.REACT_APP_ENV;
  let INTERACTIVE_ANALYTICS_URL = '',
    INTERACTIVE_ANALYTICS_API_KEY = '';

  switch (environment) {
    case 'local':
      INTERACTIVE_ANALYTICS_URL =
        process.env.REACT_APP_STAGING_INTERACTIVE_VIDEO_ANALYTICS_URL;
      // INTERACTIVE_ANALYTICS_API_KEY = process.env.REACT_APP_STAGING_VIDEO_API_KEY;
      break;
    case 'staging':
      INTERACTIVE_ANALYTICS_URL =
        process.env.REACT_APP_STAGING_INTERACTIVE_VIDEO_ANALYTICS_URL;
      // INTERACTIVE_ANALYTICS_API_KEY = process.env.REACT_APP_STAGING_VIDEO_API_KEY;
      break;
    case 'production':
      INTERACTIVE_ANALYTICS_URL =
        process.env.REACT_APP_INTERACTIVE_VIDEO_ANALYTICS_URL;
      // INTERACTIVE_ANALYTICS_API_KEY = process.env.REACT_APP_VIDEO_API_KEY;
      break;
    default:
      break;
  }

  return {
    INTERACTIVE_ANALYTICS_URL,
    // INTERACTIVE_ANALYTICS_API_KEY
  };
};

export const showcaseDetails = (showcase) => {
  return {
    _id: showcase._id,
    link: true,
    showcase: true,
    app_profile_id: showcase.app_profile_id,
    name: showcase.app_profile_name,
    filename: `${siteUrl()}showcase/${showcase._id}`,
    file_type: 'showcase',
    background_image: showcase.background_image,
  };
};

export const handleThumbnail = (item, item_details, classes) => {
  let default_thumbnail, thumbnail;
  if (item_details) {
    //for showcase
    if (item_details.showcase) {
      if (item && item.background_image) {
        thumbnail = item.background_image;
      } else {
        default_thumbnail = classes.roomCardNoBorder;
        thumbnail = '/images/default_thumbnail.png';
      }
    } else {
      if (item_details.custom_thumbnail) {
        thumbnail = item_details.custom_thumbnail;
      } else if (item_details.thumbnail) {
        thumbnail = item_details.thumbnail;
      } else {
        default_thumbnail = classes.roomCardNoBorder;
        thumbnail = '/images/default_thumbnail.png';
      }
    }
  }

  return {
    default_thumbnail,
    thumbnail,
  };
};

export const activeCampaignEvent = (values) => {
  if (environment !== 'local' && !values.master_admin && !values.enterprise) {
    axios
      .post(`${ROOT_URL}session_events/active_campaign_event`, values, {
        headers: {
          Authorization: 'Bearer ' + values.token,
        },
      })
      .then((result) => {
        return result;
      })
      .catch((error) => console.log(error.response.data));

    return null;
  }
};

export const stripeAPI = () => {
  const environment = process.env.REACT_APP_ENV;
  let STRIPE_API = {};

  switch (environment) {
    case 'local':
      STRIPE_API = {
        key: process.env.REACT_APP_STRIPE_TEST_KEY,
        // secret: process.env.REACT_APP_STRIPE_TEST_SECRET
      };
      break;
    case 'staging':
      STRIPE_API = {
        key: process.env.REACT_APP_STRIPE_TEST_KEY,
        // secret: process.env.REACT_APP_STRIPE_TEST_SECRET
      };
      break;
    case 'production':
      STRIPE_API = {
        key: process.env.REACT_APP_STRIPE_KEY,
        // secret: process.env.REACT_APP_STRIPE_SECRET
      };
      break;
    default:
      break;
  }

  return STRIPE_API;
};

export const verifyLoggedIn = (history, loggedOut, callback) => {
  if (loggedOut) {
    history.push('/login');
  } else {
    callback();
  }
};

export const verifyPermissions = (history, page, role) => {
  if (
    (page === 'analytics' || page === 'teams' || page === 'messages') &&
    role > '2'
  ) {
    history.push('/content/library');
  }
};

export const menuPermissions = (
  menuType,
  rowMenu,
  owner,
  currentRole,
  initialValues,
  appUser,
  personalGroupSelected
) => {
  const userId = initialValues._id,
    editableBy = initialValues.editable_by,
    userOwners = initialValues.owners;
  let show = false;

  if (!rowMenu) {
    switch (menuType) {
      case 'users':
      case 'users-new':
        const userType = initialValues.user_type;

        if (currentRole === '0') {
          show = true;
        } else if (
          currentRole === '1' &&
          userType >= '2' &&
          (editableBy === '0' || userOwners.includes(owner))
        ) {
          show = true;
        } else if (owner === userId) {
          show = true;
        }
        break;

      case 'contacts':
        if (owner === initialValues.user || currentRole === '0') {
          show = true;
        }
        break;

      case 'campaigns':
      case 'messages':
      case 'messages-new':
      case 'teams':
      case 'library':
      case 'library-new':
      case 'brandings':
      case 'appProfiles':
      case 'tradeBooths':
        if (personalGroupSelected) {
          show = true;
        } else if (!appUser && currentRole === '0') {
          show = true;
        } else if (
          !appUser &&
          currentRole === '1' &&
          (editableBy === '0' || (userOwners && userOwners.includes(owner)))
        ) {
          show = true;
        }
        break;
      default:
        break;
    }
  }

  return show;
};

//CALCULATE LOCATION OF LIBRARY ITEM MENU
export const getOffset = (el) => {
  el = el.getBoundingClientRect();
  return {
    left: el.left + window.scrollX,
    top: el.top + window.scrollY,
  };
};

export const mainTableHeight = () => {
  const iOS = isiOS();
  let height = 155;

  if (iOS) {
    height = 175;
  }

  return {
    height: `calc(100vh - ${height}px)`,
    overflow: 'visible',
    paddingBottom: 20,
  };
};

export const contactTableHeight = () => {
  const iOS = isiOS();
  let height = 160;

  if (iOS) {
    height = 180;
  }

  return {
    height: `calc(100vh - ${height}px)`,
    overflow: 'visible',
    paddingBottom: 20,
  };
};

export const isiOS = () => {
  return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
};

export const libraryTableHeight = (liveTeam) => {
  const iOS = isiOS();
  let height = 160;

  if (iOS) {
    height = 180;
  }

  if (liveTeam === 'liveTeam') {
    height = 130;
  } else if (liveTeam === 'liveTeam' && iOS) {
    height = 150;
  } else if (liveTeam === 'liveOnly') {
    return { height: '100%', overflow: 'auto', paddingBottom: 20 };
  }

  return {
    height: `calc(100vh - ${height}px)`,
    overflow: 'visible',
    paddingBottom: 20,
  };
};

export const analyticsTableHeight = () => {
  const iOS = isiOS();
  // let height = 368;
  let height = 320;

  if (iOS) {
    // height = 388;
    height = 340;
  }

  return {
    height: `calc(100vh - ${height}px)`,
    overflow: 'visible',
    paddingBottom: 20,
  };
};

export const analyticsContentTableHeight = () => {
  const iOS = isiOS();
  // let height = 450;
  let height = 405;

  if (iOS) {
    // height = 470;
    height = 425;
  }

  return {
    height: `calc(100vh - ${height}px)`,
    overflow: 'visible',
    paddingBottom: 20,
  };
};

export const analyticsScreensTableHeight = () => {
  const iOS = isiOS();
  // let height = 368;
  let height = 230;

  if (iOS) {
    // height = 388;
    height = 250;
  }

  return {
    height: `calc(100vh - ${height}px)`,
    overflow: 'visible',
    paddingBottom: 20,
  };
};

export const messageTableHeight = () => {
  const iOS = isiOS();
  let height = 140;

  if (iOS) {
    height = 160;
  }

  return {
    height: `calc(100vh - ${height}px)`,
    overflow: 'visible',
    paddingBottom: 20,
  };
};

export const modalTableHeight = (showcases) => {
  const iOS = isiOS();
  const clientWidth = window.innerWidth;

  let height = 90,
    top = 10,
    paddingTop = 0;

  if (iOS) {
    height = 110;
  }

  if (showcases) {
    height += 6;
    top = 16;
  }

  if (clientWidth < 600) {
    paddingTop = 30;
  }

  return {
    height: `calc(100% - ${height}px)`,
    overflow: 'visible',
    position: 'relative',
    top: top,
    paddingBottom: 40,
    paddingTop,
  };
};

export const modalMiniTableHeight = (showcases) => {
  const iOS = isiOS();
  let height = 20,
    top = 0;

  if (iOS) {
    height = 40;
  }

  return {
    height: `calc(100% - ${height}px)`,
    overflow: 'visible',
    position: 'relative',
    top: top,
    paddingBottom: 40,
  };
};

export const modalHalfTableHeight = (showcases) => {
  const iOS = isiOS();
  let height = 90,
    top = 10;

  if (iOS) {
    height = 110;
  }

  if (showcases) {
    height += 6;
    top = 16;
  }

  return {
    height: `calc(100% - ${height}px)`,
    overflow: 'visible',
    position: 'relative',
    top: top,
    paddingBottom: 20,
  };
};

export const modalViewButtonHeight = (showcases) => {
  const iOS = isiOS();
  let height = 20,
    top = 10;

  if (iOS) {
    height = 40;
  }

  return {
    height: `calc(100% - ${height}px)`,
    overflow: 'visible',
    position: 'relative',
    top: top,
    paddingBottom: 40,
  };
};

export const getHeight = (defaultHeader = 190) => {
  const height = window.innerHeight * 0.9 - defaultHeader;
  return height;
};

export const convertDateStringToNewDateString = (datestring) => {
  const newDateString = moment(datestring, 'YYYY-MM-DD').format('MMM Do YYYY');
  return newDateString;
};

export const convertToDateString = (datetime) => {
  const locale = 'en-us';

  if (datetime) {
    const d = new Date(datetime);
    const dateString = `${d.getDate()} ${d.toLocaleString(locale, {
      month: 'long',
    })} ${d.getFullYear()}`;

    return dateString;
  }

  return '';
};

export const convertToTimeString = (datetime) => {
  const locale = 'en-us';

  if (datetime) {
    const d = new Date(datetime);
    const timeString = d.toLocaleTimeString(locale, {
      hour: '2-digit',
      minute: '2-digit',
    });

    return timeString;
  }

  return '';
};

export const convertToMb = (file_size) => {
  let file_size_display = '';

  if (file_size >= 1000000000) {
    file_size_display = (file_size / 1000000000).toFixed(2) + ' GB';
  } else if (file_size >= 1000000) {
    file_size_display = (file_size / 1000000).toFixed(2) + ' MB';
  } else {
    file_size_display = (file_size / 1000).toFixed(0) + ' KB';
  }

  return file_size_display;
};

export const convertToGb = (file_size) => {
  let file_size_display = '';

  file_size_display = (file_size / 1000000000).toFixed(2);

  return file_size_display;
};

//0: super_admin, 1: admin, 2: app user
export const convertUserType = (user_type, enterprise) => {
  if (enterprise) {
    switch (user_type) {
      case '0':
        return 'Super Admin';
      case '1':
        return 'Admin';
      case '2':
        return 'App User';
      default:
        return '';
    }
  } else {
    switch (user_type) {
      case '0':
        return 'Admin';
      case '2':
        return 'App User';
      default:
        return '';
    }
  }
};

export const convertStatus = (status) => {
  if (status) {
    return 'Activated';
  } else {
    return 'Deactivated';
  }
};

export const cleanPutValues = (values) => {
  delete values.createdAt;
  delete values.updatedAt;
  delete values.deleted;
  delete values.user;

  //might not need anymore
  delete values.owner_name;
  delete values.owner_type;

  return values;
};

export const cleanContactPutValues = (values) => {
  delete values.createdAt;
  delete values.updatedAt;
  delete values.deleted;

  return values;
};

export const alphabeticalSort = (a, b) => {
  const nameA = a.toLowerCase();
  const nameB = b.toLowerCase();

  if (nameA === nameB) {
    return 0;
  }
  return nameA < nameB ? -1 : 1;
};

export const numberSort = (a, b) => {
  const numA = a[Object.keys(a)[0]];
  const numB = b[Object.keys(b)[0]];

  if (numA === numB) {
    return 0;
  }
  return numA < numB ? -1 : 1;
};

export const convertDuration = (value) => {
  // return moment.duration(value, "milliseconds").format("*mm:ss", {minValue: 1})
  // return moment.duration(value).humanize();
  return humanizeDuration(value, { round: true });
};

export const setCookie = (cname, cvalue, exdays) => {
  var d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  var expires = 'expires=' + d.toUTCString();
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
};

export const getCookie = (cname) => {
  var name = cname + '=';
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

export const checkCookie = (cname) => {
  var cookie = getCookie(cname);
  return cookie;
};

export const expiredAccount = (company) => {
  let expired = null;

  if (company) {
    //0: Expired, 1: Canceled, 2: Payment Failed, 3: Canceled, but Time Left
    if (company.payment_status === '0' && company.trial_countdown <= 0) {
      expired = 0;
    } else if (
      company.canceled_end_date &&
      Date.parse(company.canceled_end_date) < Date.now()
    ) {
      expired = 1;
    } else if (company.payment_status === '1') {
      expired = 2;
    } else if (company.canceled_end_date) {
      expired = 3;
    } else if (company.payment_status === '5' && company.trial_countdown <= 0) {
      expired = 4;
    }
  }

  return expired;
};

export const checkAlerts = (company, alerts, classes) => {
  if (
    company &&
    !company.tour &&
    !company.enterprise &&
    Object.keys(alerts).length > 0
  ) {
    return classes.alertsRoot;
  }

  return null;
};

export const moveArray = (arr, old_index, new_index) => {
  while (old_index < 0) {
    old_index += arr.length;
  }
  while (new_index < 0) {
    new_index += arr.length;
  }
  if (new_index >= arr.length) {
    let k = new_index - arr.length;
    while (k-- + 1) {
      arr.push(undefined);
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr;
};

export const checkIfVideoLink = (url) => {
  const domains = [
    'youtube.com',
    'youtu.be',
    'vimeo.com',
    'soundcloud.com',
    'wistia.com',
    'mixcloud.com',
    'dailymotion.com',
    'twitch.com',
  ]; //removed facebook.com wasn't fitting to screen correctly
  let isVideo = false;

  if (url) {
    const urlLower = url.toLowerCase();

    domains.map((domain) => {
      if (urlLower.includes(domain)) {
        isVideo = true;
      }
    });
  }

  return isVideo;
};

export const getItemDetails = ({ item, branding, state, classes }) => {
  const { windowWidth, rawColor, color, backgroundColor, shareCart } = state;

  let showcase_template_id = null,
    showcase = false,
    cardShowcaseClass = null,
    cardShowcaseItem = null,
    showcaseTitleClass = null,
    showcaseNameClass = null;

  if (!item._id) {
    return null;
  }

  const { _id, session, link_udid } = item;
  let item_details = item.details;

  if (!item_details) {
    item_details = {};
  }

  //if alternate content
  if (item.alt_details) {
    item_details = item.alt_details;
    item_details._id = item.details._id;
    item_details.downloadable = item.alt_details.downloadable;
  }

  //showcase or personalized video large card
  if (
    item.app_profile_name ||
    (item_details && item_details.personalized_video) ||
    (item_details && item_details.interactive_video)
  ) {
    cardShowcaseItem = classes.cardShowcaseItem;
    cardShowcaseClass = classes.cardShowcase;
    showcaseTitleClass = classes.showcaseTitleClass;
    showcaseNameClass = classes.showcaseNameClass;
  }

  if (item.app_profile_name) {
    showcase = true;
    showcase_template_id = item.app_profile_id;
    item_details = showcaseDetails(item);
  }

  const { default_thumbnail, thumbnail } = handleThumbnail(
    item,
    item_details,
    classes
  );
  const { link, filename, name, file_type, downloadable } = item_details;

  const streamVideo = checkIfVideoLink(filename);
  const reshareDetails = {
    _id,
    session,
    link_udid,
    showcase_template_id,
    showcase,
    backgroundColor,
  };
  const itemClasses = {
    cardShowcaseItem,
    cardShowcaseClass,
    showcaseTitleClass,
    showcaseNameClass,
  };

  return {
    default_thumbnail,
    thumbnail,
    item_details,
    streamVideo,
    reshareDetails,
    itemClasses,
  };
};

export const passwordHasSpaces = (password) => {
  if (/\s/.test(password)) {
    return true;
  }

  return false;
};

export const checkIfLoggedIn = (login, loggedOut) => {
  const { token, company, user } = login;

  if (!loggedOut && token && company && user) {
    return true;
  }

  return false;
};

export const checkIfMasterAdmin = (user) => {
  if (user && user.email === 'master_admin@solofire.com') {
    return true;
  }

  return false;
};

export const checkIfOnboarding = (company) => {
  if (company && !company.enterprise && company.onboarding) {
    return true;
  }

  return false;
};

export const checkDoesNotInclude = (currentPath) => {
  const checkIncludes = [
    'login',
    'sso_verification',
    CREATE_ACCOUNT,
    CREATE_YOUR_SOLOFIRE_ACCOUNT,
    'password',
    'room',
    'space',
    'viewer',
    'showcase',
    'download',
    'interactive_video_editor',
  ];

  let i = 0;

  checkIncludes.map((check) => {
    if (currentPath.includes(check)) {
      i++;
    }
  });

  if (i > 0) {
    return true;
  }

  return false;
};

export const validateName = (name) => {
  // const numberRegex = /^[a-zA-Z 0-9]{3,40}$/;
  // const regex = /^[\w.:<>[\]{}\\/()&|?!-_]{3,40}$/;
  const error = 'Must have at least 3 and less than 40 characters.';

  if (name) {
    // const pass = regex.test(name.trim().replace(/\s\s+/g, ''))
    const test = name.trim().replace(/\s\s+/g, ' ').length;

    if (test > 2 && test <= 40) {
      return {
        pass: true,
        error,
      };
    }

    return {
      pass: false,
      error,
    };
  }

  return {
    pass: false,
    error,
  };
};

export const cleanName = (name) => {
  if (name) {
    return name.trim().replace(/\s\s+/g, ' ');
  }
};

export const validateEmail = (email) => {
  const test = !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i.test(email);
  return test;
};

export const LGandAbove = ({ children }) => {
  const isLGandAbove = useMediaQuery({ minWidth: 1280 });
  return isLGandAbove ? children : null;
};

export const LGandBelow = ({ children }) => {
  const isLGandAbove = useMediaQuery({ maxWidth: 1919 });
  return isLGandAbove ? children : null;
};

export const MDandAbove = ({ children }) => {
  const isMDandAbove = useMediaQuery({ minWidth: 960 });
  return isMDandAbove ? children : null;
};

export const MDandBelow = ({ children }) => {
  const isMDandBelow = useMediaQuery({ maxWidth: 1279 });
  return isMDandBelow ? children : null;
};

export const SMandAbove = ({ children }) => {
  const isSMandAbove = useMediaQuery({ minWidth: 600 });
  return isSMandAbove ? children : null;
};

export const SMandBelow = ({ children }) => {
  const isSMandBelow = useMediaQuery({ maxWidth: 959 });
  return isSMandBelow ? children : null;
};

export const XL = ({ children }) => {
  const isXL = useMediaQuery({ minWidth: 1920 });
  return isXL ? children : null;
};

export const LG = ({ children }) => {
  const isLG = useMediaQuery({ minWidth: 1280, maxWidth: 1919 });
  return isLG ? children : null;
};

export const MD = ({ children }) => {
  const isMD = useMediaQuery({ minWidth: 960, maxWidth: 1279 });
  return isMD ? children : null;
};

export const SM = ({ children }) => {
  const isSM = useMediaQuery({ minWidth: 600, maxWidth: 959 });
  return isSM ? children : null;
};

export const XS = ({ children }) => {
  const isXS = useMediaQuery({ minWidth: 0, maxWidth: 599 });
  return isXS ? children : null;
};

export const isMDandAbove = () => {
  const check = useMediaQuery({ minWidth: 960 });
  return check;
};

export const getTagSearchOptions = (leads, contacts) => {
  let filteredContacts = [];

  if (leads) {
    filteredContacts = _.filter(contacts, { type: '1' });
  } else {
    filteredContacts = _.filter(contacts, function (item) {
      return !item.type || item.type === '0' || item.type === '2';
    });
  }

  let tagSearchOptions = [];

  filteredContacts.map((contact, index) => {
    let contactObj = {
      label: contact._id,
    };

    if (contact.email) {
      contactObj.value = contact.email;
    } else if (contact.phone) {
      contactObj.value = contact.phone;
    }

    if (contact.first_name) {
      contactObj.name = `${contact.first_name} ${
        contact.last_name ? contact.last_name : ''
      }`;
    }

    if (contact.user_details && contact.user_details.first_name) {
      contactObj.owner_name = `${contact.user_details.first_name} ${
        contact.user_details.last_name ? contact.user_details.last_name : ''
      }`;
    }

    if (contact.email && contact.phone) {
      contactObj.phone = contact.phone;
    }

    tagSearchOptions.push(contactObj);

    return null;
  });

  return tagSearchOptions;
};

export const handleAppLiveUser = (state) => {
  return state.login &&
    state.login.company &&
    state.login.user &&
    ((state.login.company.enterprise && state.login.user.user_type > '1') ||
      state.login.company.live_only)
    ? true
    : false;
};

export const handleEnterpriseAppUser = (state) => {
  return state.login &&
    state.login.company &&
    state.login.user &&
    state.login.company.enterprise &&
    (state.login.user.user_type > '1' ||
      (state.login.user.user_type < '2' && state.adminAppUser))
    ? true
    : false;
};

export const handleAppUser = (state) => {
  return (
    state.login &&
    state.login.user &&
    (state.login.user.user_type > '1' ||
      (state.login.user.user_type < '2' && state.adminAppUser))
  );
};

export const getRandomInt = (max) => {
  return Math.floor(Math.random() * max);
};

export const webAppAllowedCompanies = (id) => {
  return (
    id === '5e4c2d89d4a7e1fc8d579ba3' ||
    id === '5ac5230a0059c01b7470030b' ||
    id === '5bff136a96e19443e3bbf90b' ||
    id === '6036a1af2f60f35c3c8a9496'
  );
};

export const appProfileSettings = (appProfile) => {
  const { _id, app_user_content, personalized_videos, verb_live } = appProfile
    ? appProfile
    : {};

  const appUserPersonalContent = _id ? app_user_content : false,
    appUserInteractiveVideos = _id ? personalized_videos : true,
    appUserVerbLive = _id ? verb_live : true;

  return {
    appUserPersonalContent,
    appUserInteractiveVideos,
    appUserVerbLive,
  };
};

export const isVideo = (file_type) => {
  const videoExtensions = [
    'mov',
    'mp4',
    'mpeg',
    'm4v',
    'avi',
    'wmv',
    'flv',
    'video',
    '3gp',
  ];

  return videoExtensions.includes(file_type);
};

// export const learnJWT = (token, company, user) => {
//   const companyId = company._id,
//         { _id, email, first_name, last_name } = user,
//         expiration = Math.round((Date.now() + 2592000000)/1000)

//   const payload = {
//     "content_language": "en",
//     "crm_username": companyId,
//     "display_language": "en",
//     "email": email,
//     "exp": expiration,
//     "family_name": last_name,
//     "given_name": first_name,
//     "issuer_metadata": {
//       "base_url": siteUrl(),
//       "site_id": "42",
//       "token": token
//     },
//     "locale": {
//       "code": "en_ca",
//       "language": "en",
//       "country": "ca"
//     },
//     "market_id": "249",
//     "my_tools_app_accent_color": "#7222CD",
//     "nickname": first_name,
//     "sub": `repsites|${companyId}`,
//     "updated_at": "2022-01-07 10:51:58",
//     "distributor_id": "0999",
//     "uuid": _id
//   }

//   // const payload = {
//   //   "content_language": "en",
//   //   "crm_username": "chemy",
//   //   "display_language": "en",
//   //   "email": "kate@clickaway.io",
//   //   "exp": 1641664419,
//   //   "family_name": "tester",
//   //   "given_name": "chemy",
//   //   "issuer_metadata": {
//   //     "base_url": "https://sendbrightools.com.soundcon2.com",
//   //     "site_id": "41",
//   //     "token": "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE2NDE4MzcyMTksImlkIjoiMjMxNjM3OSIsInNpdGVfaWQiOiI0MSJ9.RuhC29bjR0xhZooQejH6BgOwTTMpgKzamkrVqhnPtvw"
//   //   },
//   //   "locale": {
//   //     "code": "en_us",
//   //     "language": "en",
//   //     "country": "us"
//   //   },
//   //   "market_id": "267",
//   //   "my_tools_app_accent_color": "#3DA63D",
//   //   "nickname": "chemy tester",
//   //   "sub": "repsites|sc-2316379",
//   //   "updated_at": "2022-01-07 10:53:39",
//   //   "distributor_id": "11051105",
//   //   "uuid": "B139AF62-6B0C-42FA-B4BC-5FB04D345A0C"
//   // }

//   const jwt_token = jwt.encode(payload, process.env.REACT_APP_LEARN_JWT_SECRET)

//   console.log(jwt_token)

//   return jwt_token
// }
