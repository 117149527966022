import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import { styles } from '../../styles';

import FormHelperText from '@material-ui/core/FormHelperText';

const ErrorTextRaw = (props) => {
  if(props.touched && props.error) {
    return <FormHelperText id="name-error-text" className={props.errorClassName} error>{props.error}</FormHelperText>
  } else {
    return <div style={{height: 0, lineHeight: 0}} />
  }
}

export const ErrorText = withStyles(styles)(ErrorTextRaw);