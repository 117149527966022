import _ from "lodash";
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from "redux-form";

import { withStyles } from '@material-ui/core/styles';
import { styles } from '../styles';

import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Modal from '@material-ui/core/Modal';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';

import Close from '@material-ui/icons/Close';

import LoadingDivContainer from '../subcomponents/loadingDiv';
import { cleanPutValues } from '../helpers/helperFunctions';

import { DeletePrivileges } from '../subcomponents/commonComponents';
import { RenderTextField } from '../subcomponents/form_subcomponents/renderTextField';
import { RenderSelectField } from '../subcomponents/form_subcomponents/renderSelectField';
import RenderColorPicker from '../subcomponents/form_subcomponents/renderColorPicker';
import { RenderUploadLabel } from '../subcomponents/form_subcomponents/renderUploadLabel';
import { RenderUpload } from '../subcomponents/form_subcomponents/renderUpload';
import { RenderOwnerEditableByMenu } from '../subcomponents/form_subcomponents/renderOwnerEditableByMenu';

import { fetchTradeBooth, fetchAdmins, fetchBrandings, fetchLibraryGroupsMain, createTradeBooth, updateTradeBooth, deleteTradeBooth } from '../../actions/actions_index';

import { torquoise, teal, energy } from '../../theme';

class TradeBoothForm extends Component {
  constructor(props) {
    super(props);

    // const { initialValues } = this.props;

    // let set_primary = torquoise,
    //     set_secondary = teal,
    //     set_accent = energy,
    //     card_image = null

    // const { primary, secondary, accent } = initialValues;

    // if (initialValues) {
    //   primary && (set_primary = primary);
    //   secondary && (set_secondary = secondary);
    //   accent && (set_accent = accent);

    //   initialValues.card_image && (card_image = initialValues.card_image);
    // }

    // this.state = {
    //   primary: set_primary,
    //   secondary: set_secondary,
    //   accent: set_accent,
    //   card_image
    // }

    this.props.reset();
  }

  submitTradeBoothModal = (values) => {
    const { token, companyId, createTradeBooth, updateTradeBooth, deleteTradeBooth, selectedTradeBooth, handleSubmitTradeBoothModal, deleteTradeBoothModal, closedTradeBoothModal } = this.props

    handleSubmitTradeBoothModal()

    if (!selectedTradeBooth) {
      values.company = companyId;
    }

    values = cleanPutValues(values);

    //no images anymore
    // let data = new FormData();
    
    // if (values.files) {
    //   data.append('card_image', values.files[0]);
    // }

    // _.map(values, (value, key) => {
    //   if (key === 'owners') {
    //     value = JSON.stringify(value)
    //   }

    //   data.append(key, value);
    // });

    if (!selectedTradeBooth) {
      createTradeBooth(token, values, result => {
        closedTradeBoothModal(true)
      })
    } else if (selectedTradeBooth && deleteTradeBoothModal) {
      deleteTradeBooth(token, selectedTradeBooth, () => {
        closedTradeBoothModal(true)
      })
    } else {
      updateTradeBooth(token, values, selectedTradeBooth, result => {
        closedTradeBoothModal(true)
      })
    } 

  }

  handleContentGroupSelectField = () => {
    const { contentGroups } = this.props

    return contentGroups.map((group, index) => {
      return <MenuItem key={index} value={group._id}>{group.name}</MenuItem>
    })
  }

  // handleColor = (type, color) => {

  //   switch(type) {
  //     case 'Primary': 
  //       this.props.change('primary', color);
  //       this.setState({
  //         primary: color
  //       })
  //       break;
  //     case 'Secondary':
  //       this.props.change('secondary', color);
  //       this.setState({
  //         secondary: color
  //       })
  //       break;
  //     case 'Accent': 
  //       this.props.change('accent', color);
  //       this.setState({
  //         accent: color
  //       })
  //       break;
  //     default:
  //       break;
  //   }
  
  // }

  // updatePreview = (name, value, uploaded) => {
  //   let reader = new FileReader();

  //   //only look for a thumbnail if one has been uploaded
  //   if (uploaded) {
  //     reader.onload = (e) => {
  //       this.setState({card_image: e.target.result});
  //     };
  //     reader.readAsDataURL(value[0]);
  //   }
    
  // }

  brandingMenu = () => {
    const { brandings } = this.props;

    if (brandings.length > 0) {
      return (
        brandings.map(value => <MenuItem key={value._id} value={value._id}>{value.name}</MenuItem>)
      )
    }
  }

  handleTradeBoothForm = () => {
    const { owner, currentUser, currentRole, admins, selectedBooth, deleteTradeBoothModal, initialValues, tradeBoothFormState, pristine, invalid, classes } = this.props
    // const { primary, secondary, accent, card_image } = this.state

    if (deleteTradeBoothModal) {
      return (
        <div>
          <Button className={`${classes.button} ${classes.modalButton}`} variant="contained" type="submit" color="secondary">Delete</Button>
        </div>
      )
    }

    // let noThumbnail = classes.tradeBoothNoThumbnailContainer,
    //     tradeBoothThumbnailUploaded = false

    // if (card_image) {
    //   noThumbnail = ''
    // }

    //Update thumbnail preview if thumbnail was uploaded
    // if (tradeBoothFormState && tradeBoothFormState.values && tradeBoothFormState.values.files && tradeBoothFormState.values.files[0].lastModified) {
    //   tradeBoothThumbnailUploaded = true;
    // }

    return (
      <div>
        {/*<div className={`${classes.tradeBoothThumbnailContainer} ${noThumbnail}`} >
          {card_image ? <img className={`${classes.libraryThumbnail} ${classes.tradeBoothThumbnail}`} src={card_image} alt={''} /> : null}
        </div>
        <FormControl margin="normal">
          <RenderUploadLabel
            htmlFor={'image-upload'}
            value={initialValues.card_image}
            addText={'Add Card Image'}
            changeText={'Change Card Image'}
            classes={classes}
            className={classes.tradeBoothThumbnailUploadContainer}
          />
          <Field 
            id="image-upload"
            name="files"
            type="file"
            className={`${classes.uploadedName} ${classes.uploadedNameTradeBooth}`}
            uploaded={tradeBoothThumbnailUploaded}
            updatePreview={this.updatePreview}
            component={RenderUpload}
          />
        </FormControl>*/}

        <Field name="name" component={RenderTextField} type="text" label="Name" />
        <Field type="text" name="description" multiline={3} component={RenderTextField} label="Description" />
        <Field
              name="branding"
              label="Brand"
              component={RenderSelectField}
            >
              {this.brandingMenu()}
            </Field>
        <Field
          name="parent_content_group"
          label="Content Group"
          component={RenderSelectField}
        >
          {this.handleContentGroupSelectField()}
        </Field>
        
        {/*<Grid container spacing={10} className={classes.colorPickerContainer}>
          <Grid item xs={12} className={classes.noPaddingTop}>
            <div className={classes.colorPickerRow}>
              <Grid container spacing={4} className={classes.colorPickerContainer}>
                <Grid item xs={4}>
                  <RenderColorPicker 
                    label={'Primary'}
                    selectedColor={primary}
                    handleColor={this.handleColor}
                  />
                </Grid>
                <Grid item xs={4}>
                  <RenderColorPicker 
                    label={'Secondary'}
                    selectedColor={secondary}
                    handleColor={this.handleColor}
                  />
                </Grid>
                <Grid item xs={4}>
                  <RenderColorPicker 
                    label={'Accent'}
                    selectedColor={accent}
                    handleColor={this.handleColor}
                  />
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>*/}

        {((!selectedBooth && (currentRole === '0' || initialValues.owners.includes(owner))) || (selectedBooth && tradeBoothFormState && tradeBoothFormState.values && tradeBoothFormState.values.edit_permissions)) &&
          <RenderOwnerEditableByMenu
            currentUser={currentUser}
            admins={admins}
            selectedId={selectedBooth}
            initialValues={initialValues}
          />
        }

        <Button className={`${classes.button} ${classes.modalButton}`} variant="contained" color="secondary" disabled={(pristine || invalid)} type="submit">Submit</Button>
      </div>
    )
  }

  render() {
    const { handleSubmit } =  this.props;

    return (
      <form noValidate autoComplete="off" onSubmit={handleSubmit(values => this.submitTradeBoothModal(values))}>
        {this.handleTradeBoothForm()}
      </form>
    )
  }

}

function validate(values, props) {
  const errors = {};
  const requiredFields = [
    'name', 'parent_content_group'
  ];

  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = 'Required';
    }
  })

  if (values.owners && values.owners.length === 0) {
    errors['owners'] = 'You must select at least one owner.'
  }

  return errors;
}

function mapStateToProps(state) {
  return { 
    token: state.login.token,
    companyId: state.login.company._id,
    enterprise: state.login.company.enterprise,
    owner: state.login.user._id,
    currentRole: state.login.user.user_type,
    currentUser: state.login.user,
    admins: state.admins,
    brandings: state.brandings,
    tradeBooth: state.tradeBooth,
    contentGroups: state.libraryGroupsMain,
    tradeBoothFormState: state.form.TradeBooth
  };
}

const mapDispatchToProps = dispatch => ({
  fetchAdmins: (token, companyId, callback) => dispatch(fetchAdmins(token, companyId, callback)),
  fetchTradeBooth: (token, id, callback) => dispatch(fetchTradeBooth(token, id, callback)),
  fetchBrandings: (token, companyId, callback) => dispatch(fetchBrandings(token, companyId, callback)),
  fetchLibraryGroupsMain: (token, companyId, callback) => dispatch(fetchLibraryGroupsMain(token, companyId, callback)),
  createTradeBooth: (token, values, callback) => dispatch(createTradeBooth(token, values, callback)),
  updateTradeBooth: (token, values, id, callback) => dispatch(updateTradeBooth(token, values, id, callback)),
  deleteTradeBooth: (token, id, callback) => dispatch(deleteTradeBooth(token, id, callback))
});

const TradeBoothFormContainer = reduxForm({
  form: 'TradeBooth',
  validate,
  enableReinitialize: true
})(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TradeBoothForm)));

class TradeBoothModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingTradeBooth: true,
      loadingAdmins: true,
      loadingBrandings: true,
      loadingLibraryGroups: true
    }
  }

  componentDidMount() {
    const { token, companyId, selectedTradeBooth, deleteTradeBoothModal } = this.props;

    if (selectedTradeBooth && !deleteTradeBoothModal) {
      this.props.fetchTradeBooth(token, selectedTradeBooth, team => {
        this.setState({
          loadingTradeBooth: false
        })
      })

      this.props.fetchAdmins(token, companyId, admins => {
        this.setState({
          loadingAdmins: false
        })
      })

      this.props.fetchBrandings(token, companyId, admins => {
        this.setState({
          loadingBrandings: false
        })
      })

      this.props.fetchLibraryGroupsMain(token, companyId, libraryGroupsMain => {
        this.setState({
          loadingLibraryGroups: false
        })
      })
    } else if (deleteTradeBoothModal) {
      this.props.fetchTradeBooth(token, selectedTradeBooth, team => {
        this.setState({
          loadingTradeBooth: false,
          loadingAdmins: false,
          loadingBrandings: false,
          loadingLibraryGroups: false
        })
      })
    } else {
      this.props.fetchAdmins(token, companyId, admins => {
        this.setState({
          loadingTradeBooth: false,
          loadingAdmins: false
        })
      })

      this.props.fetchBrandings(token, companyId, admins => {
        this.setState({
          loadingBrandings: false
        })
      })

      this.props.fetchLibraryGroupsMain(token, companyId, libraryGroupsMain => {
        this.setState({
          loadingLibraryGroups: false
        })
      })
    }
  }

  modalSize = () => {
    const { deleteTradeBoothModal, classes } = this.props;
    
    if (deleteTradeBoothModal) {
      return (
        classes.deleteModal
      )
    } else {
      return (
        classes.mediumMediumModal
      )
    }
  }

  handleSubmitTradeBoothModal = () => {
    this.setState({
      loadingTradeBooth: true
    })
  }

  handleModalBody = () => {
    const { owner, currentRole, tradeBooth, selectedTradeBooth, deleteTradeBoothModal, closedTradeBoothModal, classes } = this.props;
    const { loadingTradeBooth, loadingAdmins, loadingBrandings, loadingLibraryGroups } = this.state;

    let title = '',
        subheading = '',
        subheadingClass = null;

    if (selectedTradeBooth && !deleteTradeBoothModal) {
      title = <span>Edit - <span className={classes.modalTitleItem}>{tradeBooth.name}</span></span>;
      subheading = "Edit trade booth details.";
      subheadingClass = `${classes.modalSubheading} ${classes.smallHeightSubheading}`;
    } else if (selectedTradeBooth && deleteTradeBoothModal) {
      title = <span>Delete - <span className={classes.modalTitleItem}>{tradeBooth.name}</span></span>;
      subheading = `Are you sure you want to delete ${tradeBooth.name}?`;
      subheadingClass = classes.modalSubheadingDelete;
    } else {
      title = "Add New Trade Booth";
      subheading = "Add a trade booth.";
      subheadingClass = classes.modalSubheading;
    }

    if (loadingTradeBooth || loadingAdmins || loadingBrandings || loadingLibraryGroups) {
      if(deleteTradeBoothModal) {
        return (
          <LoadingDivContainer type={'small'} />
        )
      } else {
        return (
          <LoadingDivContainer />
        )
      }
    } else if (deleteTradeBoothModal && (currentRole !== '0' && !tradeBooth.owners.includes(owner))) {
      return (
        <DeletePrivileges 
          className={classes.modalSubheadingDelete}
        />
      )
    } else {
      let initialValues = {
        editable_by: '1',
        owners: [owner]
      };

      if (selectedTradeBooth) {
        initialValues = tradeBooth;
      }

      return (
        <div className={classes.divContainer}>
          <Typography variant="h6">
            {title}
          </Typography>
          <Typography variant="subtitle1" className={subheadingClass}>
            {subheading}
          </Typography>
          <TradeBoothFormContainer
            deleteTradeBoothModal={deleteTradeBoothModal}
            selectedTradeBooth={selectedTradeBooth}
            initialValues={initialValues}
            handleSubmitTradeBoothModal={this.handleSubmitTradeBoothModal}
            closedTradeBoothModal={closedTradeBoothModal}
          />
        </div>
      )
    }
  }

  render() {
    const { openTradeBoothModal, closedTradeBoothModal, classes } = this.props;
    
    return (
      <Modal
        aria-labelledby="add-edit-trade-booth"
        aria-describedby="add-edit-trade-booth"
        open={openTradeBoothModal}
        onClose={() => closedTradeBoothModal(false, false, null)}
      >
        <div className={`${classes.modal} ${this.modalSize()}`}>
          <IconButton 
            className={classes.iconClose}
            onClick={() => closedTradeBoothModal(false, false, null)}
          >
            <Close />
          </IconButton>
          {this.handleModalBody()}
        </div>
      </Modal>
    )
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TradeBoothModal));
