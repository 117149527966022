import { FETCH_COMPANY, UPDATE_COMPANY, CREATE_FULL_COMPANY } from "../actions/actions_companies";

export default function(state = {}, action) {
  switch (action.type) {

    case FETCH_COMPANY:
      if (!action.payload) {
        return state;
      }
      return action.payload;

    case UPDATE_COMPANY:
      if (!action.payload) {
        return state;
      }
      return action.payload;

    case CREATE_FULL_COMPANY:
      if (!action.payload) {
        return state;
      }
      return action.payload;
    
    default:
      return state;
  }
}