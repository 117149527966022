import _ from "lodash";
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from "redux-form";

import { withStyles } from '@material-ui/core/styles';
import { styles } from '../styles';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Modal from '@material-ui/core/Modal';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';

import Close from '@material-ui/icons/Close';
import Info from '@material-ui/icons/Info';
import Refresh from '@material-ui/icons/Refresh';

import { RenderTextField } from '../subcomponents/form_subcomponents/renderTextField';
import { RenderUploadLabel } from '../subcomponents/form_subcomponents/renderUploadLabel';
import { RenderUpload } from '../subcomponents/form_subcomponents/renderUpload';
import RenderColorPicker from '../subcomponents/form_subcomponents/renderColorPicker';

import { energy } from '../../theme';

import { createRoomDemo } from '../../actions/actions_index';

class RoomDemoForm extends Component {
  constructor(props) {
    super(props);

    const { initialValues } = this.props;

    let primary = energy,
        logo = null;

    const { branding_color, branding_logo } = initialValues;

    if (initialValues) {
      branding_color && (primary = branding_color);   
      branding_logo && (logo = branding_logo);
    }

    this.state = {
      primary: primary,
      logo: logo,
      anchorEl: null,
      popoverText: null
    }

    this.props.reset();
  }

  handleColor = (type, color) => {

    switch(type) {
      case 'Primary': 
        this.props.change('branding_color', color);
        this.setState({
          primary: color
        })
        break;
      default:
        break;
    }
  
  }

  updatePreview = (name, value) => {
    let reader = new FileReader();

    switch (name) {

      case 'logo':
        reader.onload = (e) => {
          this.setState({logo: e.target.result});
        };
        reader.readAsDataURL(value[0]);
        break;
      default:
        break;

    }
    
  }

  openPopover = (event, type) => {
    let text = '';

    switch (type) {
      case 'logo':
        text = 'Logo Bug for top of room header.';
        break;

      default:
        break;
    }

    this.setState({
      anchorEl: event.currentTarget,
      popoverText: text
    });
  }

  closePopover = () => {
    this.setState({
      anchorEl: null,
      popoverText: null
    });
  }

  resetBranding = () => {
    this.props.change('branding_color', null);
    this.props.change('branding_logo', null);

    this.setState({
      branding_color: energy,
      logo: null
    })
  }

  app_profileForm = () => {
    const { selectedRoomDemoId, deleteRoomDemoModal, pristine, invalid, initialValues, classes } = this.props;
    const { primary, logo } = this.state;

    let autoFocus = false;
    if (!selectedRoomDemoId) {
      autoFocus = true;
    }
    
    if (deleteRoomDemoModal) {
      return (
        <div>
          <Button className={`${classes.button} ${classes.modalButton}`} variant="contained" type="submit" color="secondary">Delete</Button>
        </div>
      )
    } else {
      return (
        <Grid container spacing={10}> 
          <Grid item xs={4} className={classes.gridBorderRight}>
            <Field name="contact_company" component={RenderTextField} type="text" label="Company Name" autoFocus={autoFocus} />
            <Field name="name" component={RenderTextField} type="text" label="User Name" />
            <Field name="email" component={RenderTextField} type="text" label="User Email" />
            <Field name="message" multiline={3} component={RenderTextField} type="text" label="Message" />
          </Grid>
          <Grid item xs={8}>
            <Typography variant="subtitle1" className={classes.brandingHeading} onClick={this.resetBranding}>
              Branding
              <Button className={`${classes.addButton} ${classes.addButtonBrandingReset}`} onClick={this.addRoomDemo}>
                Reset
                <Refresh className={classes.addCircleIconReset} />
              </Button>
            </Typography>
            <div className={classes.brandingContainer}>
              <Grid container spacing={4} className={classes.colorPickerContainer}>
                <Grid item xs={3}>
                  <RenderColorPicker 
                    label={'Primary'}
                    selectedColor={primary}
                    handleColor={this.handleColor}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={4} className={classes.colorPickerContainer}>
                <Grid item xs={4}>
                  <div className={classes.thumbContainer}>
                    {logo ? <img className={`${classes.libraryThumbnail} ${classes.roomDemoThumbnail}`} src={logo} alt={''} /> : null}
                  </div>
                  <Typography variant="subtitle1" className={classes.subheadingUpload}>
                    Upload a Logo Bug
                    <IconButton 
                      className={classes.popoverIconButton}
                      onClick={(event) => this.openPopover(event, 'logo')}
                    >
                      <Info className={classes.popoverIcon} />
                    </IconButton>
                  </Typography>
                  <FormControl margin="normal">
                    <RenderUploadLabel
                      htmlFor={'logo-upload'}
                      value={initialValues.branding_logo}
                      addText={'Add Logo'}
                      changeText={'Change Logo'}
                      className={classes.uploadRoomDemoButton}
                      classes={classes}
                    />
                    <Field 
                      id={'logo-upload'}
                      name={'logo'}
                      type={'file'}
                      className={`${classes.uploadedName} ${classes.uploadedNameRoomDemo}`}
                      updatePreview={this.updatePreview}
                      component={RenderUpload}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </div>
            <Button className={`${classes.button} ${classes.modalButton}`} variant="contained" type="submit" color="secondary" disabled={pristine || invalid}>
              Save
            </Button>
          </Grid>
        </Grid>
      )
    } 
    
  }

  submitRoomDemoModal = (values) => {
    const { token, companyId, owner, uploadedLibraryItems } = this.props;
    const { submitRoomDemoModal, closedRoomDemoModal } = this.props;

    submitRoomDemoModal();

    let content = [];

    uploadedLibraryItems.map(item => {
      content.push(item._id)
      return null
    })

    let data = new FormData();

    _.map(values, (value, key) => {
      data.append(key, value);
    });

    data.append('content', content);

    if (values.logo) {
      data.append('branding_logo', values.logo[0]);
    }

    // for (var pair of data.entries()) {
    //   console.log(pair[0]+ ', '+ pair[1]); 
    // }

    data.append('company', companyId);
    data.append('user', owner);

    this.props.createRoomDemo(token, data, result => {
      // closedRoomDemoModal(true, true, result.data);
      closedRoomDemoModal(result.data);
    });   
  }

  render() {
    const { handleSubmit } =  this.props;

    return (
      <form noValidate autoComplete="off" onSubmit={handleSubmit(values => this.submitRoomDemoModal(values))}>
        {this.app_profileForm()}
      </form>
    )
  }

}

function validate(values, props) {
  const errors = {};
  const requiredFields = [
    'company_name', 'user_email', 'branding_color'
  ];

  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = 'Required';
    }
  });

  return errors;
}

function mapStateToProps(state) {
  return { 
    token: state.login.token,
    companyId: state.login.company._id,
    owner: state.login.user._id,
    uploadedLibraryItems: state.uploadedLibraryItems
  };
}

const mapDispatchToProps = dispatch => ({
  createRoomDemo: (token, props, callback) => dispatch(createRoomDemo(token, props, callback))
});

const RoomDemoFormContainer = reduxForm({
  form: 'RoomDemo',
  validate,
  enableReinitialize: true
})(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(RoomDemoForm)));

class RoomDemoModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true
    }
  }

  componentDidMount() {
    const { token, selectedRoomDemoId, deleteRoomDemoModal } = this.props;

    if (selectedRoomDemoId && !deleteRoomDemoModal) {
      this.props.fetchRoomDemo(token, selectedRoomDemoId, team => {
        this.setState({
          loading: false
        })
      })
    } else if (deleteRoomDemoModal) {
      this.props.fetchRoomDemo(token, selectedRoomDemoId, team => {
        this.setState({
          loading: false
        })
      })
    } else {
      this.setState({
        loading: false
      })
    }
  }

  modalSize = () => {
    const { deleteRoomDemoModal, classes } = this.props;
    
    if (deleteRoomDemoModal) {
      return (
        classes.deleteModal
      )
    }
  }

  submitRoomDemoModal = () => {
    this.setState({
      loadingRoomDemo: true
    })
  }

  handleModalBody = () => {
    const { roomDemo, selectedRoomDemoId, deleteRoomDemoModal, closedRoomDemoModal, classes } = this.props;

    let title = '',
        subheading = '',
        subheadingClass = null,
        initialValues = {}

    title = "Add New Room Demo";
    subheading = "Add a room demo.";
    subheadingClass = classes.modalSubheading;

    if (selectedRoomDemoId) {
      initialValues = roomDemo;
    }

    return (
      <div className={classes.divContainer}>
        <Typography variant="h6">
          {title}
        </Typography>
        <Typography variant="subtitle1" className={subheadingClass}>
          {subheading}
        </Typography>
        <RoomDemoFormContainer
          deleteRoomDemoModal={deleteRoomDemoModal}
          selectedRoomDemoId={selectedRoomDemoId}
          initialValues={initialValues}
          submitRoomDemoModal={this.submitRoomDemoModal}
          closedRoomDemoModal={closedRoomDemoModal}
        />
      </div>
    )
  }

  render() {
    const { openRoomDemoModal, closedRoomDemoModal, classes } = this.props;
    
    return (
      <Modal
        aria-labelledby="add-edit-room-demo"
        aria-describedby="add-edit-room-demo"
        open={openRoomDemoModal}
        onClose={() => closedRoomDemoModal(null)}
      >
        <div className={`${classes.modal} ${this.modalSize()}`}>
          <IconButton 
            className={classes.iconClose}
            onClick={() => closedRoomDemoModal(null)}
          >
            <Close />
          </IconButton>
          {this.handleModalBody()}
        </div>
      </Modal>
    )
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(RoomDemoModal));
