import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import { withStyles } from '@material-ui/core/styles';
import { styles } from '../../styles';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

import { convertToDateString, validateEmail } from '../../helpers/helperFunctions';

import { ErrorText } from './errorText';

class RenderAutocompleteField extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      labelWidth: 0
    };
  }

  handleOnChange = (event, _id, onChange) => {
    const { updateOnChange } = this.props;

    if (updateOnChange) {
      updateOnChange(_id);
    }

    onChange(_id);
  }
    
  render() {
    const {
      label,
      input,
      input: { name, value, onChange },
      meta: { touched, error },
      options,
      marginClass,
      className,
      classes
    } = this.props

    let margin = 'normal'

    if (marginClass) {
      margin = marginClass;
    }

    return (
      <FormControl margin={margin} className={className}>
        <Autocomplete
          id="select-autocomplete"
          key="select-autocomplete"
          options={options}
          selectOnFocus
          autoHighlight
          autoComplete
          blurOnSelect
          renderInput={(params) => {
            // console.log('render input: ', params)
            return <TextField key="autocomplete-textfield" variant="outlined" {...params} label={label} margin={margin} />}
          }
          getOptionLabel={(option) => option.name}
          renderOption={(option) => (
            <div className={classes.masterCompany} key={option._id} value={option._id}>
              <div className={classes.masterCompanyName}>
                {option.name}
                <br />
                <span className={classes.masterCompanyCreatedAt}>{convertToDateString(option.createdAt)}</span>
              </div>
            </div>
          )}
          onChange={(event, newValue) => this.handleOnChange(event, newValue ? newValue._id : null, onChange)}
        />
        
        <ErrorText touched={touched} error={error} />
      </FormControl>
    )
  }

}

export default withStyles(styles)(RenderAutocompleteField)
