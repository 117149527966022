import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';
import { styles } from '../../styles';

// import { List, ListItem, ListItemSecondaryAction, ListItemText, ListItemIcon } from '@material-ui/core';
// import { LibraryShelves } from 'mdi-material-ui';

import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';

import AddCircle from '@material-ui/icons/AddCircle';

import LibraryGroupItems from '../library_group/library_group_items';

class LibraryGroupList extends Component {

  render() {
    const { enterprise, enterpriseAppUser, appUser, appUserPersonal, appUserPersonalLibraryGroups, appUserPersonalContent, appUserInteractiveVideos, verb_live, all_content_group, FULL_LIBRARY_ID, MY_VIDEOS_ID, SHOWCASES_ID, owner, currentRole, libraryGroups, libraryCount, showcasesCount, selectedGroupId, displayParentSubs, childrenMap, handleLibraryGroupMenu, handleGroupClick, openAddNewLibraryGroupModal, classes } = this.props

    return (
      <List dense className={classes.libraryGroupListMargin}>
        {((enterprise && !appUser) || (!enterprise && !verb_live)) && 
          <ListItem 
            id={FULL_LIBRARY_ID}
            key={FULL_LIBRARY_ID}
            button 
            onClick={handleGroupClick}
            className={`${classes.listMainHeaders} ${classes.fullLibraryItem} ${selectedGroupId === FULL_LIBRARY_ID ? classes.selectedItem : ''}`}
            disableRipple={true}
            focusRipple={true}
          >
            <ListItemText 
              primary={<span><span className={selectedGroupId === FULL_LIBRARY_ID ? classes.selectedBigLeftMenuText : classes.bigLeftMenuText}>Full Content Library</span><span className={classes.listItemCount}>{libraryCount}</span></span>}
            />
            <ListItemSecondaryAction />
          </ListItem>
        }
        {!appUser &&
          <Button 
            className={`${classes.addButton} ${classes.addLibraryGroup}`}
            onClick={openAddNewLibraryGroupModal}
            focusRipple={false}
          >
            <AddCircle className={classes.addCircleIcon} />
            Add New Content Group
          </Button>
        }
        <Typography variant="h3" className={classes.contentGroupsHeading}>
          Content Groups
        </Typography>
        <LibraryGroupItems
          key={'library-groups'}
          all_content_group={all_content_group}
          owner={owner}
          appUser={appUser}
          currentRole={currentRole}
          libraryGroups={libraryGroups}
          selectedGroupId={selectedGroupId}
          displayParentSubs={displayParentSubs}
          childrenMap={childrenMap}
          handleLibraryGroupMenu={handleLibraryGroupMenu}
          handleGroupClick={handleGroupClick}
        />
        {(appUser && (appUserPersonalContent || appUserInteractiveVideos)) &&
          <React.Fragment>
            <Typography variant="h3" className={`${classes.contentGroupsHeading} ${classes.myFilesHeading}`}>
              {appUserPersonalContent &&
                <React.Fragment>
                  <Button 
                    className={`${classes.addButton} ${classes.addPersonalLibraryGroup}`}
                    onClick={openAddNewLibraryGroupModal}
                    focusRipple={false}
                  >
                    <AddCircle className={classes.addCircleIcon} />
                    Add New Personal Group
                  </Button>
                  <br />
                </React.Fragment>
              }
              My Files
            </Typography>
            <LibraryGroupItems
              key={'my-file-groups'}
              owner={owner}
              appUser={appUser}
              appUserPersonalContent={appUserPersonalContent}
              appUserInteractiveVideos={appUserInteractiveVideos}
              personal={true}
              libraryGroups={appUserPersonalLibraryGroups}
              selectedGroupId={selectedGroupId}
              displayParentSubs={[]}
              childrenMap={childrenMap}
              handleLibraryGroupMenu={handleLibraryGroupMenu}
              handleGroupClick={handleGroupClick}
            />
          </React.Fragment>
        }
        {(enterpriseAppUser && appUser) &&
          <React.Fragment>
            <ListItem 
              id={SHOWCASES_ID}
              key={SHOWCASES_ID}
              button 
              onClick={handleGroupClick}
              className={`${classes.listMainHeaders} ${classes.fullLibraryItem} ${classes.fullShowcases} ${selectedGroupId === SHOWCASES_ID ? classes.selectedItem : ''}`}
              disableRipple={true}
              focusRipple={true}
            >
              <ListItemText 
                primary={<span><span className={selectedGroupId === SHOWCASES_ID ? classes.selectedBigLeftMenuText : classes.bigLeftMenuText}>Showcases</span><span className={classes.listItemCount}>{showcasesCount}</span></span>}
              />
              <ListItemSecondaryAction />
            </ListItem>
          </React.Fragment>
        }
      </List>
    )
    
  }

}

export default withStyles(styles)(LibraryGroupList);