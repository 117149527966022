import { FETCH_CONTACTS, CONTACT_FEED, SHARED_NOT_OPENED } from "../actions/actions_contacts";

export default function(state = [], action) {
  switch (action.type) {
    
    case FETCH_CONTACTS:
      if (!action.payload) {
        return state;
      }
      return action.payload;
      // return _.mapKeys(action.payload.data, "_id");

    case CONTACT_FEED:
      if (!action.payload) {
        return state;
      }
      return action.payload;

    case SHARED_NOT_OPENED:
      if (!action.payload) {
        return state;
      }
      return action.payload;
    
    default:
      return state;
  }
}