import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from "redux-form";

import { withStyles } from '@material-ui/core/styles';
import { styles } from '../styles';

// import { Grid, Typography, Button, IconButton, Modal, MenuItem, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core';
// import { Close, ExpandMore } from '@material-ui/icons';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Modal from '@material-ui/core/Modal';
import MenuItem from '@material-ui/core/MenuItem';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

import Close from '@material-ui/icons/Close';
import ExpandMore from '@material-ui/icons/ExpandMore';

import LoadingDivContainer from '../subcomponents/loadingDiv';
import { cleanContactPutValues, validateEmail } from '../helpers/helperFunctions';
import { DeletePrivileges } from '../subcomponents/commonComponents';

import { RenderTextField } from '../subcomponents/form_subcomponents/renderTextField';
import { RenderSelectField } from '../subcomponents/form_subcomponents/renderSelectField';
import { ErrorText } from '../subcomponents/form_subcomponents/errorText';


import { fetchContact, createContact, updateContact, deleteContact } from '../../actions/actions_index';

class EditContactForm extends Component {
  constructor(props) {
    super(props);

    const { formError } = this.props;

    this.state = {
      error: formError
    }

    this.props.reset();
  }

  submitContactModal = (values) => {
    const { leads, selectedContactId, deleteContactModal, submitContactModal, closedContactModal } = this.props;
    const { token, companyId, owner } = this.props;

    submitContactModal(true, null);
    
    if (selectedContactId && !deleteContactModal) {
      values = cleanContactPutValues(values);
      
      this.props.updateContact(token, values, selectedContactId, (data) => {
        if (data.data.error) {
          submitContactModal(false, data.data.error, values);
        } else {
          closedContactModal(true);
        }
      });

    } else if (selectedContactId && deleteContactModal) {
      this.props.deleteContact(token, selectedContactId, () => {
        closedContactModal(true);
      });

    } else {
      let type = '2';

      if (leads) {
        type = '1';
      }

      values.company = companyId;
      values.user = owner;
      values.type = type;

      this.props.createContact(token, values, (data) => {
        if (data.data.error) {
          submitContactModal(false, data.data.error, values);
        } else {
          closedContactModal(true);
        }
      });

    } 
  }

  contactForm = () => {
    const { leads, selectedContactId, deleteContactModal, classes, pristine, invalid } = this.props;
    const { error } = this.state;

    let type = 'Contact',
        autoFocus = false;

    if (leads) {
      type = 'Lead';
    }

    if (!selectedContactId) {
      autoFocus = true;
    }

    if (deleteContactModal) {
      return (
        <div>
          <Button className={`${classes.button} ${classes.modalButton}`} variant="contained" type="submit" color="secondary">Delete</Button>
        </div>
      )
    } else {
      return (
        <div className={classes.divContainer}>
          <ErrorText className={classes.bigError} touched={true} error={error} />
          <Grid container spacing={10}>
            <Grid item xs={6} className={`${classes.gridBorderRight}`}>
              {selectedContactId &&
                <Field
                  name="type"
                  label="Type"
                  component={RenderSelectField}
                >
                  {/*<MenuItem key="0" value="0">Search for Contact</MenuItem>*/}
                  <MenuItem key="1" value="1">Lead</MenuItem>
                  <MenuItem key="2" value="2">Contact</MenuItem>
                  {/*<MenuItem key="3" value="3">Channel</i></MenuItem>*/}
                </Field> 
              }
              <Field name="email" component={RenderTextField} type="email" label="Email" autoFocus={autoFocus} />
              <Field name="phone" component={RenderTextField} type="phone" label="Phone" />
              <Field name="first_name" component={RenderTextField} type="text" label="First Name" />
              <Field name="last_name" component={RenderTextField} type="text" label="Last Name" />
              <Field name="contact_company" component={RenderTextField} type="text" label="Company" />
            </Grid>
            <Grid item xs={6} className={`${classes.containerPaddingLeft}`}>
              <ExpansionPanel className="expansionPanel">
                <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                  <div className={classes.column}>
                    <Typography variant="h5">{`${type} Details`}</Typography>
                  </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Field name="address" component={RenderTextField} type="text" label="Address" />
                  <Field name="affiliation" component={RenderTextField} type="text" label="Affliation" />
                  <Field name="specialty" component={RenderTextField} type="text" label="Specialty" />
                  <Field name="country" component={RenderTextField} type="text" label="Country" />
                  <Field name="association" component={RenderTextField} type="text" label="Association" />
                  <Field name="other" component={RenderTextField} type="text" label="Other" />
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </Grid>
          </Grid>
          <Button className={`${classes.button} ${classes.modalButton}`} variant="contained" color="secondary" type="submit" disabled={pristine || invalid}>Save</Button>
        </div>
      )
    }
  }

  render() {
    const { handleSubmit } = this.props; 
    return (
      <form noValidate autoComplete="off" onSubmit={handleSubmit(values => this.submitContactModal(values))}>
        {this.contactForm()} 
      </form>
    )
  }
}

function validate(values, props) {
  const errors = {};
  let oneOfRequiredFields = [
    'email', 'phone', 'first_name'
  ];

  let noFields = true;
  
  oneOfRequiredFields.forEach(field => {
    if (values[field]) {
      noFields = false;
    }
  })

  if (noFields) {
    errors['email'] = 'Email, Phone, or First and Last Name Required';
  }

  if (!noFields && values['first_name'] && !values['last_name'] && !values['email'] && !values['phone']) {
    props.touch('last_name');
    errors['last_name'] = 'If no email or phone, must have first and last name.'
  }

  if (values.email && validateEmail(values.email)) {
    errors['email'] = 'Invalid email address';
  }

  return errors;
}

function mapStateToProps(state) {
  return { 
    token: state.login.token,
    companyId: state.login.company._id,
    currentRole: state.login.user.user_type,
    currentUser: state.login.user,
    owner: state.login.user._id,
    contact: state.contact
  };
}

const mapDispatchToProps = dispatch => ({
  fetchContact: (token, id, callback) => dispatch(fetchContact(token, id, callback)),
  createContact: (token, values, callback) => dispatch(createContact(token, values, callback)),
  updateContact: (token, values, id, callback) => dispatch(updateContact(token, values, id, callback)),
  deleteContact: (token, id, callback) => dispatch(deleteContact(token, id, callback))
});

const EditContactFormContainer = reduxForm({
  form: 'Contact',
  validate,
  enableReinitialize: true,
})(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(EditContactForm)));


class ContactModal extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      formError: null,
      previousValues: null
    }
  }

  componentDidMount() {
    const { token, selectedContactId } = this.props;

    if (selectedContactId) {
      this.props.fetchContact(token, selectedContactId, contact => {
        this.setState({
          loading: false
        })
      })
    } else {
      this.setState({
        loading: false
      })
    }
  }

  contactFormContainer = () => {
    const { leads, contact, selectedContactId, deleteContactModal, closedContactModal } = this.props;
    const { formError, previousValues } = this.state;

    let initialValues = {};

    if (selectedContactId) {
      initialValues = contact;
    }

    if (previousValues) {
      initialValues = previousValues;
    }
    
    return (
      <EditContactFormContainer 
        leads={leads}
        selectedContactId={selectedContactId}
        deleteContactModal={deleteContactModal}
        initialValues={initialValues}
        submitContactModal={this.submitContactModal}
        closedContactModal={closedContactModal}
        formError={formError}
      />
    )
  }

  modalSize = () => {
    const { deleteContactModal, classes } = this.props;
    
    if (deleteContactModal) {
      return (
        classes.deleteModal
      )
    } else {
      return (
        classes.mediumModal
      )
    }
  }

  submitContactModal = (loading, error, values) => {
    this.setState({
      loading: loading,
      formError: error,
      previousValues: values
    })
  }

  handleModalBody = () => {
    const { leads, owner, currentRole, contact, selectedContactId, deleteContactModal, classes } = this.props;
    const { loading } = this.state;

    let type = 'Contact',
        name = '',
        title = '',
        subheading = '',
        subheadingClass = classes.modalSubheading;

    if (leads) {
      type = 'Lead';
    }

    if (selectedContactId) {
      if (contact.first_name) {
        name = contact.first_name;

        if (contact.last_name) {
          name += ` ${contact.last_name}`;
        }
      } else if (contact.email) {
        name = contact.email
      } else if (contact.phone) {
        name = contact.phone
      }
    }

    if (selectedContactId && !deleteContactModal) {
      
      title = <span>Edit - <span className={classes.modalTitleItem}>{name}</span></span>;
      subheading = `Edit ${type.toLowerCase()} details.`;
    
    } else if (selectedContactId && deleteContactModal) {
      
      title = <span>Delete - <span className={classes.modalTitleItem}>{name}</span></span>;
      subheading = <span>Are you sure you want to delete <i>{name}</i>?</span>;
      
      if (contact.contact_type <= '1') {
        subheadingClass = classes.modalSubheadingDeleteSmall;
      } else {
        subheadingClass = classes.modalSubheadingDelete;
      }

    } else {
      
      title = `Add New ${type}`;
      subheading = `Add ${type.toLowerCase()} details.`;

    }

    if (loading) {
      return (
        <LoadingDivContainer 
          type={'small'}
        />
      )
    } else if (deleteContactModal && currentRole !== '0' && contact.user !== owner) {
      return (
        <DeletePrivileges 
          className={classes.modalSubheadingDelete}
        />
      )
    } else {
      return (
        <div className={classes.divContainer}>
          <Typography variant="h6">
            {title}
          </Typography>
          <Typography variant="subtitle1" className={subheadingClass}>
            {subheading}
          </Typography>
          {this.contactFormContainer()}
        </div>
      )
    }
  }

  render() {
    const { openContactModal, closedContactModal, classes } = this.props;

    return (
      <Modal
        aria-labelledby="edit-contact"
        aria-describedby="edit-contact"
        open={openContactModal}
        onClose={() => closedContactModal(false)}
      >
        <div className={`${classes.modal} ${this.modalSize()}`}>
          <IconButton 
            className={classes.iconClose}
            onClick={() => closedContactModal(false)}
          >
            <Close />
          </IconButton>
          {this.handleModalBody()}
        </div>
      </Modal>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ContactModal));
