import React from 'react';

import Button from '@material-ui/core/Button';

import Edit from '@material-ui/icons/Edit';
import SupervisorAccount from '@material-ui/icons/SupervisorAccount';
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import LibraryAdd from '@material-ui/icons/LibraryAdd';
import CreateNewFolder from '@material-ui/icons/CreateNewFolder';

import TextBoxPlus from 'mdi-material-ui/TextBoxPlus';

const handleIcon = (name, classes) => {

  switch(name) {
    case 'Team Members':
    case 'Teams':
      return <SupervisorAccount className={classes.addCircleIcon} />
    case 'Library Groups':
      return <LibraryBooks className={classes.addCircleIcon} />
    case 'Content':
    case 'Content from Library':
      return <TextBoxPlus className={classes.addCircleIcon} />
    case 'Content Groups':
      return <CreateNewFolder className={classes.addCircleIcon} />
    case 'Group Content':
      return <LibraryAdd className={classes.addCircleIcon} />
    default:
      return <Edit className={classes.addCircleIcon} />
  }

}

export const RenderAddRemoveButton = ({ show, selectedId, addRemove, fromEdit=true, fromView=false, type, name, subName, classes, addedRemoved }) => {

  let extraClass = classes.addButtonDown,
      contained = 'text';

  if (type === 'add-to-team') {
    extraClass = `${classes.addButton} ${classes.addButtonDownTeam}`;
  }

  if (type === 'add-members') {
    extraClass = `${classes.addButton} ${classes.addMembersButton}`;
  }

  if (type === 'add-teams' || type === 'add-library-groups'|| type === 'add-showcases') {
    extraClass = `${classes.addButton} ${classes.tabButtonButton}`;
  }

  if (name === 'Content from Library' || (name === 'Content Groups' && type !== 'add-library-groups') || name === 'Team Members') {
    extraClass = `${classes.plusButtonButton}`;
  }

  if (show && selectedId) {
    return (
      <Button className={`${extraClass}`} variant={contained} color="secondary" onClick={() => addRemove(type, selectedId, fromEdit, fromView, addedRemoved)}>
        {handleIcon(name, classes)}
        <span className={classes.truncateText}>Assign {name} to <i>{subName}</i></span>
      </Button>
    )
  } else {
    return ('')
  }
  
}