import React from 'react';

import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';

import Typography from '@material-ui/core/Typography';

import { ErrorText } from './errorText';

export const RenderTextField = (
  {
    type,
    formControlClassName,
    className,
    input,
    label,
    multiline,
    autoFocus,
    errorClassName,
    forceTouched,
    meta: { pristine, touched, error, dirty, visited },
    handletextfieldvariant,
    reshareInputLabel,
    reshareRootInput,
    reshareInput,
    createAccountInput,
    reshareInputUnderline,
    infoText,
    infoTextClassName,
    customStyle,
    customNotchedOutline,
    cssWhiteOutlinedInput,
    customMargin,
    ...custom
  }
  // props
) =>
  {
    // console.log(props);
    // console.log('pristine', pristine);
    // console.log('touched', touched);
    // console.log('dirty', dirty);
    // console.log('visited', visited)

    let rows = 1;

    if (!multiline) {
      multiline = false;
    } else {
      rows = multiline;
      multiline = true;
    }

    const rawLabel = label;

    //have to do this ridiculousness because material-ui is not adding enough width to outlined notch area when label animates to top
    //most likely because of font, material-ui does (full sized label text * .75 + 8)
    if (input.value === '') {
      const length = rawLabel.length;
      const padding = length * 1.2;

      label = <span style={{paddingRight: padding}}>{rawLabel}</span>
    }

    let textFieldVariant = 'outlined'

    if (handletextfieldvariant === 'none') {
      textFieldVariant = 'standard'
    }

    return (
      <FormControl margin={customMargin ? customMargin : "normal"} variant="outlined" className={formControlClassName}>
        <TextField
          type={type}
          className={className}
          margin="none"
          placeholder={rawLabel}
          label={label}
          autoFocus={autoFocus}
          multiline={multiline}
          rows={rows}
          error={((touched && error) || (forceTouched && error)) ? true : false}
          variant={textFieldVariant}
          {...input}
          {...custom}
          //starts with label in top outlined notch
          InputLabelProps={{
            classes: {
              root: reshareInputLabel
            },
            style: customStyle
          }}
          InputProps={{
            classes: {
              root: reshareRootInput,
              input: reshareInput,
              underline: reshareInputUnderline,
              notchedOutline: customNotchedOutline
            },
            style: customStyle
          }}
        />

        {infoText &&
          <Typography variant='h5' className={infoTextClassName}>{infoText}</Typography>
        }

        <ErrorText touched={touched || forceTouched} error={error} errorClassName={errorClassName} />
      </FormControl>
    )
  }