import _ from "lodash";
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import { styles } from '../styles';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Modal from '@material-ui/core/Modal';

import Close from '@material-ui/icons/Close';

import WithLoading from '../subcomponents/withLoading';
import { modalTableHeight, handleAppUser } from '../helpers/helperFunctions';
import { multipleFilter, tagsFilter, libraryNameSort } from '../helpers/gridLibraryHelpers';
import GridContainer from '../subcomponents/gridContainer';

import { fetchLibrary } from '../../actions/actions_index';

class AddEmailAlternateModal extends Component {
  submitEmailAlternateModal = () => {
    const { library, selectedFile } = this.props;

    let altContentId = '',
        altContentName = '';
    
    if (selectedFile.length > 0) {
      altContentId = library[selectedFile[0]]._id;
      altContentName = library[selectedFile[0]].name;
    }

    this.props.closedAddEmailAlternateModal(true, altContentId, altContentName);
  }

  closeEmailAlternateModal = () => {
    this.props.closedAddEmailAlternateModal(false);
  }

  libraryGrid = () => {
    const { library, selectedFile, onSelectionChange, classes } = this.props;

    const columns = [
      { name: 'thumbnail', title: ' ' },
      { name: 'file_type', title: 'Type' },
      { name: 'filename', title: 'Name / Filename' },
      { name: 'search_tags', title: '' },
      { name: 'updatedAt', title: 'Last Updated' }
    ];

    const tableColumnExtensions = [
      { columnName: 'thumbnail', width: 80 },
      { columnName: 'file_type', width: 80 },
      { columnName: 'filename' },
      { columnName: 'search_tags', width: 1 },
      { columnName: 'updatedAt', width: 170 }
    ]

    const sortingStateColumnExtensions = [
      { columnName: 'thumbnail', sortingEnabled: false },
      { columnName: 'file_type', sortingEnabled: true },
      { columnName: 'filename', sortingEnabled: true },
      { columnName: 'search_tags', sortingEnabled: false },
      { columnName: 'updatedAt', sortingEnabled: true }
    ]

    const filteringStateColumnExtensions = [
      { columnName: 'thumbnail', filteringEnabled: false },
      { columnName: 'file_type', filteringEnabled: true },
      { columnName: 'filename', filteringEnabled: false },
      { columnName: 'search_tags', filteringEnabled: false },
      { columnName: 'updatedAt', filteringEnabled: false }
    ]

    const rows = library;

    const integratedFilteringColumnExtensions = [
      { columnName: 'filename', predicate: multipleFilter },
      { columnName: 'search_tags', predicate: tagsFilter }
    ]

    const integratedSortingColumnExtensions = [
      { columnName: 'filename', compare: libraryNameSort }
    ]

    const sorting = [
      { columnName: 'updatedAt', direction: 'desc' }
    ]

    return (
      <GridContainer 
        gridHeight={modalTableHeight()}
        rows={library}
        columns={columns}
        tableColumnExtensions={tableColumnExtensions}
        sortingStateColumnExtensions={sortingStateColumnExtensions}
        sorting={sorting}
        selection={selectedFile}
        onSelectionChange={onSelectionChange}
        filteringStateColumnExtensions={filteringStateColumnExtensions}
        integratedFilteringColumnExtensions={integratedFilteringColumnExtensions}
        integratedSortingColumnExtensions={integratedSortingColumnExtensions}
        showSelectAll={false}
        showSelectionColumn={true}
        showRowDetail={false}
        classes={classes}
      />
    )
  }

  render() {
    const { selectedLibraryItemId, libraryItem, openAddEmailAlternateModal, classes } = this.props;

    let title = `Select Alternate Email Content`,
        subheading = "Select alternate email content to be sent when item is emailed.";

    if (selectedLibraryItemId) {
      title += ` for ${libraryItem.details.name}`;
    }

    return (
      <Modal
        id="add-to-modal"
        aria-labelledby="add-to-library-group-modal"
        aria-describedby="add-to-library-group-modal"
        open={openAddEmailAlternateModal}
        onClose={() => this.closeEmailAlternateModal(null, null, false)}
      >
        <div className={`${classes.modal} ${classes.mediumModal}`}>
          <IconButton 
            className={classes.iconClose}
            onClick={() => this.closeEmailAlternateModal(null, null, false)}
          >
            <Close />
          </IconButton>
          <Typography variant="h6">
            {title}
          </Typography>
          <Typography variant="subtitle1" className={`${classes.modalSubheading} ${classes.modalSubheadingTable}`}>
            {subheading}
          </Typography>
          {this.libraryGrid()}
          <Button className={`${classes.button} ${classes.modalButton}`} variant="contained" type="submit" color="secondary" onClick={this.submitEmailAlternateModal}>Save</Button>
        </div>
      </Modal>
    )
  }
}

const AddEmailAlternateModalContainerWithLoading = WithLoading(AddEmailAlternateModal)

class AddEmailAlternateModalContainer extends Component {
  state = {
    loading: true
  }

  componentDidMount() {
    const { token, companyId, appProfile } = this.props;
    const { library_groups } = appProfile

    this.props.fetchLibrary(token, companyId, library_groups, library => {
      this.setState({
        loading: false
      })
    })
  }

  render() {
    const loading = this.state.loading;

    return (
      <AddEmailAlternateModalContainerWithLoading 
        isLoading={loading}
        form={true}
        {...this.props}
      />
    )
  }
}

function mapStateToProps(state) {
  if (_.isEmpty(state.login.token)) {
    return {}
  }

  const appUser = handleAppUser(state)

  return { 
    token: state.login.token,
    companyId: state.login.company._id,
    library: state.library,
    appProfile: appUser ? state.appProfile : {},
    libraryItem: state.libraryItem
  };
}

const mapDispatchToProps = dispatch => ({
  fetchLibrary: (token, companyId, groups, callback) => dispatch(fetchLibrary(token, companyId, groups, callback))
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(AddEmailAlternateModalContainer)