import { FETCH_MESSAGES } from "../actions/actions_messages";

export default function(state = [], action) {
  switch (action.type) {
    
    case FETCH_MESSAGES:
      if (!action.payload) {
        return state;
      }
      return action.payload;
      // return _.mapKeys(action.payload.data, "_id");
    
    default:
      return state;
  }
}