import { FETCH_TRADE_BOOTH, CREATE_TRADE_BOOTH, UPDATE_TRADE_BOOTH, DELETE_TRADE_BOOTH } from "../actions/actions_trade_booths";

export default function(state = {}, action) {
  switch (action.type) {
    
    case FETCH_TRADE_BOOTH:
      if (!action.payload) {
        return state;
      }
      return action.payload;

    case CREATE_TRADE_BOOTH:
      if (!action.payload) {
        return state;
      }
      return action.payload;

    case UPDATE_TRADE_BOOTH:
      if (!action.payload) {
        return state;
      }
      return action.payload;

    case DELETE_TRADE_BOOTH:
      if (!action.payload) {
        return state;
      }
      return action.payload;

    default:
      return state;
  }
}