import _ from "lodash";
import React, { PureComponent } from "react";
import { compose } from 'redux';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { withStyles } from '@material-ui/core/styles';
import { styles } from '../styles';

import WithLoading from '../subcomponents/withLoading';
import { BrandingFormContainer } from '../content/brandingForm';

import { checkAlerts, activeCampaignEvent } from '../helpers/helperFunctions';

import { fetchBrandings } from '../../actions/actions_index';

const Branding = props => {
  const { company, alerts, brandings, submitBrandingModal, closedBrandingModal, classes } = props;
  const alertsRoot = checkAlerts(company, alerts, classes);

  const brand = brandings[0],
        brandId = brand._id;
        
  return (
    <div className={`${classes.root} ${alertsRoot}`}>
      <BrandingFormContainer
        selectedBrandingId={brandId}
        initialValues={brand}
        submitBrandingModal={submitBrandingModal}
        closedBrandingModal={closedBrandingModal}
      />
    </div>
  )
}

const BrandingWithLoading = WithLoading(Branding)

class BrandingContainer extends PureComponent {
  state = {
    loading: true
  }

  handleFetchBrand = () => {
    const { token, companyId } = this.props;

    this.props.fetchBrandings(token, companyId, brand => {
      this.setState({
        loading: false
      })
    })
  }

  componentDidMount() {
    const { token, enterprise, currentUser, location, master_admin } = this.props;

    this.handleFetchBrand();

    const values = {
      token,
      master_admin,
      enterprise,
      email: currentUser.email,
      page: location.pathname
    }

    activeCampaignEvent(values)
  }

  submitBrandingModal = () => {
    this.setState({
      loading: true
    })
  }

  closedBrandingModal = () => {
    this.handleFetchBrand();
  }

  render() {
    const loading = this.state.loading;

    return (
      <BrandingWithLoading 
        isLoading={loading}
        submitBrandingModal={this.submitBrandingModal}
        closedBrandingModal={this.closedBrandingModal}
        {...this.props}
      />
    )
  }
}

function mapStateToProps(state) {
  if (_.isEmpty(state.login.token) || _.isEmpty(state.login.company) || _.isEmpty(state.login.user)) {
    return {
      loggedOut: true
    }
  }

  return { 
    token: state.login.token,
    master_admin: state.login.master_admin,
    companyId: state.login.company._id,
    company: state.company,
    enterprise: state.company.enterprise,
    alerts: state.alerts,
    currentUser: state.login.user,
    brandings: state.brandings
  };
}

const mapDispatchToProps = dispatch => ({
  fetchBrandings: (token, companyId, callback) => dispatch(fetchBrandings(token, companyId, callback))
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(BrandingContainer)
