import _ from "lodash";
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import { styles } from '../styles';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';

import WithLoading from '../subcomponents/withLoading';
import { BrandingFormContainer } from '../content/brandingForm';

import { activeCampaignEvent } from '../helpers/helperFunctions';

import { fetchBrandings, updateBranding, updateCompany } from '../../actions/actions_index';

class OnboardingBrand extends Component {
  constructor(props) {
    super(props);

    const brand = this.props.brandings[0];
    const brandId = brand._id;

    this.state = {
      brandId,
      brand
    }
  }

  render() {
    const { submitBranding, closedBranding, classes } = this.props;
    const { brandId, brand } = this.state;

    return (
      <div className={classes.marginTop}>
        <Typography variant="h1" className={classes.onboardingH1}>
          <div className={classes.onboardingStepNumber}>5</div>Brand
        </Typography>
        <Grid container spacing={4} className={classes.gridExtraSpacing} justify="center" alignItems="center">
          <Grid item sm={3} xs={false}>
          </Grid>
          <Grid item sm={6} xs={12}>
            <Typography variant="subtitle1" className={classes.onboardingSteps}>
              Step <span className={classes.onboardingStepsBold}>5</span> of 6
            </Typography>
            <LinearProgress color="secondary" variant="determinate" value={85} />
          </Grid>
          <Grid item sm={3} xs={false}>
          </Grid>
        </Grid>
        <Grid className={`${classes.onboardingBackgroundContainer} ${classes.onboardingBrandBackgroundContainer} ${classes.gridTagsSpacing}`} container spacing={4} justify="center" alignItems="center">
          <Grid item lg={4} className={`${classes.onboardingGrid} ${classes.onboardingGridBrandGraphic}`}>
            <div className={classes.onboardingImageContainer}>
              <img className={classes.onboardingImage} src="/images/verb_onboarding/brand.png" />
            </div>
          </Grid>
          <Grid item lg={8} md={12} className={`${classes.onboardingGrid} ${classes.onboardingGridLeftTextArea}`}>
            <Typography variant="h2" className={`${classes.onboardingH2} ${classes.onboardingBrandText}`}>
              Let's add your brand.
            </Typography>
            <div className={classes.onboardingBrandText}>
              <Typography variant="subtitle1" className={`${classes.onboardingSubtitle} ${classes.onboardingSubtitleContentGroups}`}>
                Add your custom branding elements or use the verbTEAMS default branding for now.  
              </Typography>
              <Typography variant="body1" className={`${classes.onboardingSubtitle} ${classes.onboardingBody}`}>
                Upload a background image that embodies your brand, your logo <i>(preferably a light version of your logo as a PNG with a transparent background)</i> and your company's colors.
              </Typography>
              </div>
              <BrandingFormContainer
                onboarding={true}
                selectedBrandingId={brandId}
                initialValues={brand}
                submitBrandingModal={submitBranding}
                closedBrandingModal={closedBranding}
              />
              <div className={classes.onboardingBrandButtonContainer}>
                <Button className={`${classes.button} ${classes.onboardingSkipButton} ${classes.onboardingBrandSkipButton}`} color="primary" onClick={closedBranding}>Skip for Now</Button>
              </div>
          </Grid>
        </Grid> 
      </div>
    )
  }
}

const OnboardingBrandWithLoading = WithLoading(OnboardingBrand)

class OnboardingBrandContainer extends Component {
  state = {
    loading: true
  }

  componentDidMount() {
    const { token, enterprise, companyId, fetchBrandings, currentUser, history, master_admin } = this.props;

    fetchBrandings(token, companyId, () => {  
      this.setState({
        loading: false
      })        
    })

    const values = {
      token,
      master_admin,
      enterprise,
      email: currentUser.email,
      page: history.location.pathname
    }

    activeCampaignEvent(values)
  }

  submitBranding = () => {
    this.setState({
      loading: true
    })
  }

  proceed = () => {
    const { token, companyId, history, updateCompany } = this.props;

    console.log('proceed')

    updateCompany(token, {onboarding_step: 'completed'}, companyId, () => {
      history.push('/onboarding/completed');
    });
  }

  closedBranding = () => {
    this.proceed();
  }

  render() {
    const loading = this.state.loading;

    return (
      <OnboardingBrandWithLoading 
        isLoading={loading}
        submitBranding={this.submitBranding}
        closedBranding={this.closedBranding}
        {...this.props}
      />
    )
  }
}

function mapStateToProps(state) {
  if (_.isEmpty(state.login.token) || _.isEmpty(state.login.company) || _.isEmpty(state.login.user)) {
    return {
      loggedOut: true
    }
  }

  return { 
    token: state.login.token,
    master_admin: state.login.master_admin,
    companyId: state.login.company._id,
    enterprise: state.login.company.enterprise,
    owner: state.login.user._id,
    currentUser: state.login.user,
    brandings: state.brandings
  };
}

const mapDispatchToProps = dispatch => ({
  fetchBrandings: (token, companyId, callback) => dispatch(fetchBrandings(token, companyId, callback)),
  updateBranding: (token, values, id, callback) => dispatch(updateBranding(token, values, id, callback)),
  updateCompany: (token, values, id, callback) => dispatch(updateCompany(token, values, id, callback))
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(OnboardingBrandContainer)
