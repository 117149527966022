import _ from "lodash";
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import { styles } from '../styles';

// import { Typography, Button, IconButton, Modal } from '@material-ui/core';
// import { Close } from '@material-ui/icons';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Modal from '@material-ui/core/Modal';

import Close from '@material-ui/icons/Close';

import LoadingDivContainer from '../subcomponents/loadingDiv';
import { modalTableHeight, convertUserType } from '../helpers/helperFunctions';
import { multipleFilter } from '../helpers/gridLibraryHelpers';
import { compareName } from '../helpers/gridUsersHelpers'
import GridContainer from '../subcomponents/gridContainer';

import { fetchUsers, fetchTeam, updateTeam, fetchAppProfile, updateAppProfile } from '../../actions/actions_index';

class AddToTeamModal extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      selectedUsers: []
    }

    this.onSelectionChange = selectedUsers => {
      this.setState({ selectedUsers });
    }
  }

  handleCurrentMembers = ({team, users}) => {
    const currentMembers = team.data.users.map(value => _.findIndex(users.data, ['_id', value]));

    this.setState({
      loading: false,
      selectedUsers: currentMembers
    })
  }

  componentDidMount() {
    const { token, companyId, enterprise, selectedTeamId } = this.props;

    this.props.fetchUsers(token, companyId, users => {
      
      if (enterprise) {
        this.props.fetchTeam(token, selectedTeamId, team => {
          this.handleCurrentMembers({team, users});
        })
      } else {
        this.props.fetchAppProfile(token, selectedTeamId, team => {
          this.handleCurrentMembers({team, users});
        })
      }
    })
  }

  membersGrid = () => {
    const { enterprise, users, classes } = this.props;
    const { selectedUsers } = this.state;

    const columns = [
      { name: 'user_name', title: 'Name', getCellValue: row => ({first_name: row.first_name, last_name: row.last_name}) },
      { name: 'email', title: 'Email' },
      { name: 'user_type', title: 'User Type', getCellValue: row => {
        const user_type = convertUserType(row.user_type, enterprise);
        return user_type;
      }},
      { name: 'updatedAt', title: 'Last Updated' }
    ];

    const tableColumnExtensions = [
      { columnName: 'user_name' },
      { columnName: 'email', width: 250 },
      { columnName: 'user_type', width: 150 },
      { columnName: 'updatedAt', width: 170 }
    ]

    const sortingStateColumnExtensions = [
      { columnName: 'user_name', sortingEnabled: true },
      { columnName: 'email', sortingEnabled: true },
      { columnName: 'user_type', sortingEnabled: true },
      { columnName: 'updatedAt', sortingEnabled: true }
    ]

    const filteringStateColumnExtensions = [
      { columnName: 'user_name', filteringEnabled: true },
      { columnName: 'email', filteringEnabled: true },
      { columnName: 'user_type', filteringEnabled: true },
      { columnName: 'updatedAt', filteringEnabled: false }
    ]

    const integratedFilteringColumnExtensions = [
      { columnName: 'user_name', predicate: multipleFilter }
    ]

    const integratedSortingColumnExtensions = [
      { columnName: 'user_name', compare: compareName }
    ]

    const sorting = [
      { columnName: 'updatedAt', direction: 'desc' }
    ]

    return (
      <GridContainer 
        gridHeight={modalTableHeight()}
        rows={users}
        columns={columns}
        tableColumnExtensions={tableColumnExtensions}
        sortingStateColumnExtensions={sortingStateColumnExtensions}
        sorting={sorting}
        selection={selectedUsers}
        onSelectionChange={this.onSelectionChange}
        filteringStateColumnExtensions={filteringStateColumnExtensions}
        integratedFilteringColumnExtensions={integratedFilteringColumnExtensions}
        integratedSortingColumnExtensions={integratedSortingColumnExtensions}
        showSelectAll={true}
        showSelectionColumn={true}
        showRowDetail={false}
        classes={classes}
      />
    )
  }

  submitAddToTeamModal = () => {
    const { token, enterprise, users, selectedTeamId, reCheckAlerts } = this.props;
    const { selectedUsers } = this.state;

    const addedUsers = selectedUsers.map(value => {
      return users[value]._id;
    });

    if (enterprise) {
      this.props.updateTeam(token, {users: addedUsers}, selectedTeamId, data => {
        const team = data.data;
        this.closeAddToTeamModal(true, team);
      });
    } else {
      this.props.updateAppProfile(token, {users: addedUsers}, selectedTeamId, data => {
        reCheckAlerts(() => {
          const team = data.data;
          this.closeAddToTeamModal(true, team);
        })
      });
    }
  }

  closeAddToTeamModal = (updated, team) => {
    this.props.closedAddToTeamModal(updated, team);
  }

  handleModalBody = () => {
    const { team, classes } = this.props;
    const { loading } = this.state;

    let title = '',
        subheading = '';

    if (loading) {
      return (
        <LoadingDivContainer />
      )
    } else {
      title = <span>Manage Team Members - <span className={classes.modalTitleItem}>{team.name}</span></span>;
      subheading = "Add or remove team members.";
      
      return (
        <div className={classes.divContainer}>
          <Typography variant="h6">
            {title}
          </Typography>
          <Typography variant="subtitle1" className={`${classes.modalSubheading} ${classes.modalSubheadingTable}`}>
            {subheading}
          </Typography>
          {this.membersGrid()}
          <Button className={`${classes.button} ${classes.modalButton}`} variant="contained" type="submit" color="secondary" onClick={this.submitAddToTeamModal}>Save</Button>
        </div>
      )
    }
  }

  render() {
    const { openAddToTeamModal, classes } = this.props; 

    return (
      <Modal
        id="add-to-modal"
        aria-labelledby="add-to-team-modal"
        aria-describedby="add-to-team-modal"
        open={openAddToTeamModal}
        onClose={() => this.closeAddToTeamModal(false, null)}
      >
        <div className={`${classes.modal} ${classes.mediumModal}`}>
          <IconButton 
            className={classes.iconClose}
            onClick={() => this.closeAddToTeamModal(false, null)}
          >
            <Close />
          </IconButton>
          {this.handleModalBody()}
        </div>
      </Modal>
    )
  }
}

function mapStateToProps(state) {
  if (_.isEmpty(state.login.token)) {
    return {}
  }

  const enterprise = state.login.company.enterprise;
  let team = state.team;

  if (!enterprise) {
    team = state.appProfile
  }

  return { 
    token: state.login.token,
    companyId: state.login.company._id,
    enterprise: state.login.company.enterprise,
    users: state.users,
    team: team
  };
}

const mapDispatchToProps = dispatch => ({
  fetchUsers: (token, companyId, callback) => dispatch(fetchUsers(token, companyId, callback)),
  fetchTeam: (token, id, callback) => dispatch(fetchTeam(token, id, callback)),
  updateTeam: (token, values, id, callback) => dispatch(updateTeam(token, values, id, callback)),
  fetchAppProfile: (token, id, callback) => dispatch(fetchAppProfile(token, id, callback)),
  updateAppProfile: (token, values, id, callback) => dispatch(updateAppProfile(token, values, id, callback))
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AddToTeamModal));