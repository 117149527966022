import axios from "axios";
import { ROOT_URL } from "./actions_constants";

export const FETCH_SHOWCASE_TEMPLATES = "fetch_showcase_templates";
export const FETCH_SHOWCASES_IN_APP_PROFILE = "fetch_showcases_in_app_profile";
export const FETCH_SHOWCASES = "fetch_showcases";
export const FETCH_SHOWCASE = "fetch_showcase";
// export const FETCH_SHOWCASES_ANALYTICS = "fetch_showcases_analytics";
export const CREATE_SHOWCASE = "create_showcase";
export const UPDATE_SHOWCASE = "update_showcase";
export const ADD_LEAD_TO_SHOWCASE = 'add_lead_to_showcase';
export const SHOWCASE_CLOSED = "showcase_closed";
export const SHOWCASE_EVENT = "showcase_event";
export const DELETE_SHOWCASE = "delete_showcase";

const fetchAppProfileShowcasesSuccess = showcase_templates => ({
  type: FETCH_SHOWCASE_TEMPLATES,
  payload: showcase_templates
});

export const fetchAppProfileShowcases = (token, companyId, callback) => (dispatch) => {

  const TOKEN = token;

  return (axios.get(`${ROOT_URL}company/app_profile_showcases/${companyId}`, 
    { 
      headers: { 
        Authorization: 'Bearer ' + TOKEN
      }
    }))
    .then(result => {
      const showcase_templates = result.data;

      dispatch(fetchAppProfileShowcasesSuccess(showcase_templates));

      if(callback) {
        callback(result);
      }

      return result;
    })
    .catch(error => {
      console.log(error)
      
      if (error.response && error.response.data) {
        callback(error.response.data)
      }
    })
}


const fetchShowcasesInAppProfileSuccess = showcase_templates => ({
  type: FETCH_SHOWCASES_IN_APP_PROFILE,
  payload: showcase_templates
});

export const fetchShowcasesInAppProfile = (token, appProfileId, callback) => (dispatch) => {

  const TOKEN = token;

  return (axios.get(`${ROOT_URL}showcases_in_app_profile/${appProfileId}`, 
    { 
      headers: { 
        Authorization: 'Bearer ' + TOKEN
      }
    }))
    .then(result => {
      const showcase_templates = result.data;

      dispatch(fetchShowcasesInAppProfileSuccess(showcase_templates));

      if(callback) {
        callback(result);
      }

      return result;
    })
    .catch(error => {
      console.log(error)
      
      if (error.response && error.response.data) {
        callback(error.response.data)
      }
    })
}


const fetchShowcasesSuccess = showcases => ({
  type: FETCH_SHOWCASES,
  payload: showcases
});

export const fetchShowcases = (token, companyId, callback) => (dispatch) => {

  const TOKEN = token;

  return (axios.get(`${ROOT_URL}company/showcases/${companyId}`, 
    { 
      headers: { 
        Authorization: 'Bearer ' + TOKEN
      }
    }))
    .then(result => {
      const showcases = result.data;

      dispatch(fetchShowcasesSuccess(showcases));

      if(callback) {
        callback(result);
      }
      
      return result;
    })
    .catch(error => {
      console.log(error)
      
      if (error.response && error.response.data) {
        callback(error.response.data)
      }
    })
}

const fetchShowcaseSuccess = showcase => ({
  type: FETCH_SHOWCASE,
  payload: showcase
});

export const fetchShowcase = (token, _id, callback) => (dispatch) => {

  const TOKEN = token;

  return (axios.get(`${ROOT_URL}showcases/${_id}`,
    { 
      headers: { 
        Authorization: 'Bearer ' + TOKEN
      }
    }))
    .then(result => {
      const showcase = result.data;

      dispatch(fetchShowcaseSuccess(showcase));

      if(callback) {
        callback(result);
      }
      
      return result;
    })
    .catch(error => {
      console.log(error)
      
      if (error.response && error.response.data) {
        callback(error.response.data)
      }
    })
}

const fetchShowcaseDisplaySuccess = showcase => ({
  type: FETCH_SHOWCASE,
  payload: showcase
});

export const fetchShowcaseDisplay = (_id, previewInstance, callback) => (dispatch) => {

  let url = 'showcase_display';

  if (previewInstance) {
    url = 'showcase_instance_preview';
  }

  return (axios.get(`${ROOT_URL}${url}/${_id}`))
    .then(result => {
      const showcase = result.data;

      dispatch(fetchShowcaseDisplaySuccess(showcase));

      if(callback) {
        callback(result);
      }
      
      return result;
    })
    .catch(error => {
      console.log(error)
      
      if (error.response && error.response.data) {
        callback(error.response.data)
      }
    })
}

const fetchShowcasePreviewSuccess = showcase => ({
  type: FETCH_SHOWCASE,
  payload: showcase
});

export const fetchShowcasePreview = (_id, callback) => (dispatch) => {

  return (axios.get(`${ROOT_URL}showcase_preview/${_id}`))
    .then(result => {
      const showcase = result.data;

      dispatch(fetchShowcasePreviewSuccess(showcase));

      if(callback) {
        callback(result);
      }
      
      return result;
    })
    .catch(error => {
      console.log(error)
      
      if (error.response && error.response.data) {
        callback(error.response.data)
      }
    })
}

// const fetchShowcasesAnalyticsSuccess = showcase => ({
//   type: FETCH_SHOWCASES_ANALYTICS,
//   payload: showcase
// });

// export const fetchShowcasesAnalytics = (token, companyId, callback) => (dispatch) => {

//   const TOKEN = token;

//   return (axios.get(`${ROOT_URL}company/showcases_analytics/${companyId}`, 
//     { 
//       headers: { 
//         Authorization: 'Bearer ' + TOKEN
//       }
//     }))
//     .then(result => {
//       const showcaseAnalytics = result.data;

//       dispatch(fetchShowcasesAnalyticsSuccess(showcaseAnalytics));

//       if(callback) {
//         callback(result);
//       }
      
//       return result;
//     })
//     .catch(error => {
//       console.log(error)
      
//       if (error.response && error.response.data) {
//         callback(error.response.data)
//       }
//     })
// }

const createShowcaseSuccess = showcase => ({
  type: CREATE_SHOWCASE,
  payload: showcase
});

export const createShowcase = (token, values, callback) => (dispatch) => {

  const TOKEN = token;

  return (axios.post(`${ROOT_URL}showcases`,
      values,
      { 
        headers: { 
          Authorization: 'Bearer ' + TOKEN
        }
      }))
    .then(result => {
      const showcase = result.data;

      dispatch(createShowcaseSuccess(showcase));

      if(callback) {
        callback(result);
      }

      return result;
    })
    .catch(error => {
      console.log(error)
      
      if (error.response && error.response.data) {
        callback(error.response.data)
      }
    })
}

const addLeadToShowcaseSuccess = showcase => ({
  type: ADD_LEAD_TO_SHOWCASE,
  payload: showcase
});

export const addLeadToShowcase = (campaign, lead, callback) => (dispatch) => {

  return (axios.get(`${ROOT_URL}showcase_add_lead_to_showcase/${campaign}/${lead}`))
    .then(result => {
      const showcase = result.data;

      dispatch(addLeadToShowcaseSuccess(showcase));

      if(callback) {
        callback(result);
      }
      
      return result;
    })
    .catch(error => {
      console.log(error)
      
      if (error.response && error.response.data) {
        callback(error.response.data)
      }
    })
}

const updateShowcaseSuccess = showcase => ({
  type: UPDATE_SHOWCASE,
  payload: showcase
});

export const updateShowcase = (token, values, id, callback) => (dispatch) => {

  const TOKEN = token;

  return (axios.put(`${ROOT_URL}showcases/${id}`, 
    values,
    { 
      headers: { 
        Authorization: 'Bearer ' + TOKEN
      }
    }))
    .then(result => {
      const showcase = result.data;

      dispatch(updateShowcaseSuccess(showcase));

      if(callback) {
        callback(result);
      }
      
      return result;
    })
    .catch(error => {
      console.log(error)
      
      if (error.response && error.response.data) {
        callback(error.response.data)
      }
    })
}

const showcaseEventSuccess = showcase => ({
  type: SHOWCASE_EVENT,
  payload: showcase
});

export const showcaseEvent = (values, callback) => (dispatch) => {

  return (axios.post(`${ROOT_URL}showcase_event`))
    .then(result => {
      const showcase = result.data;

      dispatch(showcaseEventSuccess(showcase));

      if(callback) {
        callback(result);
      }

      return result;
    })
    .catch(error => {
      console.log(error)
      
      if (error.response && error.response.data) {
        callback(error.response.data)
      }
    })
}

const showcaseClosedSuccess = showcase => ({
  type: SHOWCASE_CLOSED,
  payload: showcase
});

export const showcaseClosed = (duration, callback) => (dispatch) => {

  return (axios.get(`${ROOT_URL}showcase_closed/${duration}`))
    .then(result => {
      const showcase = result.data;

      dispatch(showcaseClosedSuccess(showcase));

      if(callback) {
        callback(result);
      }

      return result;
    })
    .catch(error => {
      console.log(error)
      
      if (error.response && error.response.data) {
        callback(error.response.data)
      }
    })
}

const deleteShowcaseSuccess = showcase => ({
  type: DELETE_SHOWCASE,
  payload: showcase
});

export const deleteShowcase = (token, id, callback) => (dispatch) => {

  const TOKEN = token;

  return (axios.delete(`${ROOT_URL}showcases/${id}`, 
    { 
      headers: { 
        Authorization: 'Bearer ' + TOKEN
      }
    }))
    .then(result => {
      const showcase = result.data;

      dispatch(deleteShowcaseSuccess(showcase));

      if(callback) {
        callback(result);
      }
      
      return result;
    })
    .catch(error => {
      console.log(error)
      
      if (error.response && error.response.data) {
        callback(error.response.data)
      }
    })
}

//for initial Solofire showcase test marketing campaigns
const fetchSolofireShowcaseSuccess = showcase => ({
  type: FETCH_SHOWCASE,
  payload: showcase
});

export const fetchSolofireShowcase = (_id, values, callback) => (dispatch) => {

  return (axios.post(`${ROOT_URL}solofire_showcase/${_id}`,
    values
    ))
    .then(result => {
      const showcase = result.data;

      dispatch(fetchSolofireShowcaseSuccess(showcase));

      if(callback) {
        callback(result);
      }
      
      return result;
    })
    .catch(error => {
      console.log(error)
      
      if (error.response && error.response.data) {
        callback(error.response.data)
      }
    })
}
//END for initial Solofire showcase test marketing campaigns
