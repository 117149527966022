import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from "redux-form";

import { withStyles } from '@material-ui/core/styles';
import { styles } from '../styles';

// import { Grid, Typography, Button, IconButton, Modal, MenuItem } from '@material-ui/core';
// import { Close } from '@material-ui/icons';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Modal from '@material-ui/core/Modal';
import MenuItem from '@material-ui/core/MenuItem';

import Close from '@material-ui/icons/Close';

import LoadingDivContainer from '../subcomponents/loadingDiv';
import { cleanPutValues } from '../helpers/helperFunctions';
import { DeletePrivileges } from '../subcomponents/commonComponents';

import { RenderTextField } from '../subcomponents/form_subcomponents/renderTextField';
import { RenderMultipleSelectField } from '../subcomponents/form_subcomponents/renderMultipleSelectField';
import { RenderOwnerEditableByMenu } from '../subcomponents/form_subcomponents/renderOwnerEditableByMenu';
import { ErrorText } from '../subcomponents/form_subcomponents/errorText';

import { fetchMessage, createMessage, updateMessage, deleteMessage, fetchAdmins, fetchAppProfilesPermission } from '../../actions/actions_index';

class MessageForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null
    }

    this.props.reset();
  }

  appProfiles = () => {
    const { appProfilesPermission } = this.props;

    if (appProfilesPermission.length > 0) {
      return (
        appProfilesPermission.map(value => <MenuItem key={value._id} value={value._id}>{value.name}</MenuItem>)
      )
    }
  }

  handleAppProfiles = () => {
    const { enterprise } = this.props;
    let selector = enterprise ? 'App Profiles' : 'Teams';

    return (
      <div>
        <Field
          name="app_profiles"
          label={selector}
          component={RenderMultipleSelectField}
        >
          {this.appProfiles()}
        </Field>
      </div>
    )
  }

  messageForm = () => {
    const { owner, currentRole, currentUser, admins, selectedMessageId, deleteMessageModal, pristine, invalid, initialValues, classes } = this.props;
    const { error } = this.state;

    let submitButtonText = 'Post Message';

    if (selectedMessageId) {
      submitButtonText = 'Update Message';
    }

    // let autoFocus = false;
    // if (!selectedMessageId) {
    //   autoFocus = true;
    // }

    if (deleteMessageModal) {
      return (
        <div>
          <Button className={`${classes.button} ${classes.modalButton}`} variant="contained" type="submit" color="secondary">Delete</Button>
        </div>
      )
    } else {
      return (
        <div>  
          <ErrorText className={classes.bigError} touched={true} error={error} />
          <Grid container spacing={10}>
            <Grid item xs={12} className={`${classes.gridBorderRight}`}>
              {this.handleAppProfiles()}
              <Field name="title" component={RenderTextField} type="text" label="Title" />
              <Field name="body" multiline={5} component={RenderTextField} type="text" label="Body" />
              <br />
              <br />
              { (currentRole === '0' || initialValues.owners.includes(owner)) &&
              <RenderOwnerEditableByMenu
                currentUser={currentUser}
                admins={admins}
                selectedId={selectedMessageId}
                initialValues={initialValues}
              />
              }
            </Grid>
          </Grid>
          <Button className={`${classes.button} ${classes.modalButton}`} variant="contained" color="secondary" type="submit" disabled={pristine || invalid}>{submitButtonText}</Button>
        </div>
      )
    } 
    
  }

  submitMessageModal = (values) => {
    const { token, companyId } = this.props;
    const { owner, selectedMessageId, deleteMessageModal, submitMessageModal, closedMessageModal } = this.props;

    submitMessageModal();

    if (selectedMessageId && !deleteMessageModal) {
      values = cleanPutValues(values);
      values.users_read = [];

      this.props.updateMessage(token, values, selectedMessageId, data => {
        if (data.error) {
          this.setState({
            error: data.error
          })
        } else {
          closedMessageModal(true);
        } 
      });
    } else if (selectedMessageId && deleteMessageModal) {
      this.props.deleteMessage(token, selectedMessageId, () => {
        closedMessageModal(true);
      });
    } else {
      values.company = companyId;
      values.user = owner;

      this.props.createMessage(token, values, data => {
        if (data.error) {
          this.setState({
            error: data.error
          })
        } else {
          closedMessageModal(true, true, data.data);
        }
      });
    }    
  }

  render() {
    const { handleSubmit } =  this.props;

    return (
      <form noValidate autoComplete="off" onSubmit={handleSubmit(values => this.submitMessageModal(values))}>
        {this.messageForm()}
      </form>
    )
  }

}

function validate(values, props) {
  const errors = {};
  const requiredFields = [
    'title', 'body', 'app_profiles'
  ];

  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = 'Required';
    }
  });

  if (values.owners && values.owners.length === 0) {
    errors['owners'] = 'You must select at least one owner.'
  }

  if (values.app_profiles && values.app_profiles.length === 0) {
    errors['app_profiles'] = 'You must select at least one App Profile.'
  }

  return errors;
}

function mapStateToProps(state) {
  return { 
    token: state.login.token,
    companyId: state.login.company._id,
    enterprise: state.login.company.enterprise,
    owner: state.login.user._id,
    currentRole: state.login.user.user_type,
    currentUser: state.login.user,
    admins: state.admins,
    appProfilesPermission: state.appProfilesPermission,
    message: state.message
  };
}

const mapDispatchToProps = dispatch => ({
  fetchAdmins: (token, companyId, callback) => dispatch(fetchAdmins(token, companyId, callback)),
  fetchAppProfilesPermission: (token, companyId, owner, callback) => dispatch(fetchAppProfilesPermission(token, companyId, owner, callback)),
  fetchMessage: (token, id, callback) => dispatch(fetchMessage(token, id, callback)),
  createMessage: (token, values, callback) => dispatch(createMessage(token, values, callback)),
  updateMessage: (token, values, id, callback) => dispatch(updateMessage(token, values, id, callback)),
  deleteMessage: (token, id, callback) => dispatch(deleteMessage(token, id, callback)),
});

const MessageFormContainer = reduxForm({
  form: 'Message',
  validate,
  enableReinitialize: true,
  touchOnChange: true
})(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MessageForm)));

class MessageModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingMessage: true,
      loadingAdmins: true,
      loadingAppProfilesPermission: true
    }
  }

  componentDidMount() {
    const { token, companyId, owner, selectedMessageId, deleteMessageModal } = this.props;

    if (selectedMessageId && !deleteMessageModal) {
      this.props.fetchMessage(token, selectedMessageId, message => {
        this.setState({
          loadingMessage: false
        })
      })

      this.props.fetchAdmins(token, companyId, admins => {
        this.setState({
          loadingAdmins: false
        })
      })

      this.props.fetchAppProfilesPermission(token, companyId, owner, appProfilesPermission => {
        this.setState({
          loadingAppProfilesPermission: false
        })
      })
    } else if (deleteMessageModal) {
      this.props.fetchMessage(token, selectedMessageId, message => {
        this.setState({
          loadingMessage: false,
          loadingAdmins: false,
          loadingAppProfilesPermission: false
        })
      })
    } else {
      this.props.fetchAdmins(token, companyId, admins => {
        this.setState({
          loadingMessage: false,
          loadingAdmins: false
        })
      })

      this.props.fetchAppProfilesPermission(token, companyId, owner, appProfilesPermission => {
        this.setState({
          loadingAppProfilesPermission: false
        })
      })
    }
  }

  submitMessageModal = () => {
    this.setState({
      loadingMessage: true
    })
  }

  modalSize = () => {
    const { deleteMessageModal, classes } = this.props;
    
    if (deleteMessageModal) {
      return (
        classes.deleteModal
      )
    } else {
      return (
        classes.mediumModal
      )
    }
  }

  handleModalBody = () => {
    const { owner, currentRole, message, deleteMessageModal, selectedMessageId, classes, closedMessageModal } = this.props;
    const { loadingMessage, loadingAdmins, loadingAppProfilesPermission } = this.state;

    let title = '',
        subheading = '',
        subheadingClass = null;

    if (selectedMessageId && !deleteMessageModal) {
      title = <span>Edit - <span className={classes.modalTitleItem}>{message.title}</span></span>;
      subheading = "Edit message.";
      subheadingClass = `${classes.modalSubheading} ${classes.smallHeightSubheading}`;
    } else if (selectedMessageId && deleteMessageModal) {
      title = <span>Delete - <span className={classes.modalTitleItem}>{message.title}</span></span>;
      subheading = <span>Are you sure you want to delete <i>{message.title}</i>?</span>;
      subheadingClass = classes.modalSubheadingDelete;
    } else {
      title = "Send New Message";
      subheading = "Send a message to app users.";
      subheadingClass = classes.modalSubheading;
    }

    if (loadingMessage || loadingAdmins || loadingAppProfilesPermission) {
      if(deleteMessageModal) {
        return (
          <LoadingDivContainer type={'small'} />
        )
      } else {
        return (
          <LoadingDivContainer />
        )
      }
    } else if (deleteMessageModal && (currentRole !== '0' && !message.owners.includes(owner))) {
      return (
        <DeletePrivileges 
          className={classes.modalSubheadingDelete}
        />
      )
    } else {
      let initialValues = {
        app_profiles: [],
        editable_by: '1',
        owners: [owner]
      };

      if (selectedMessageId) {
        initialValues = message;
      }

      return (
        <div className={classes.divContainer}>
          <Typography variant="h6">
            {title}
          </Typography>
          <Typography variant="subtitle1" className={subheadingClass}>
            {subheading}
          </Typography>
          <MessageFormContainer
            deleteMessageModal={deleteMessageModal}
            selectedMessageId={selectedMessageId}
            initialValues={initialValues}
            submitMessageModal={this.submitMessageModal}
            closedMessageModal={closedMessageModal}
          />
        </div>
      )
    }
  }

  render() {
    const { openMessageModal, closedMessageModal, classes } = this.props;

    return (
      <Modal
        aria-labelledby="add-edit-message"
        aria-describedby="add-edit-message"
        open={openMessageModal}
        onClose={() => closedMessageModal(false)}
      >
        <div className={`${classes.modal} ${this.modalSize()}`}>
          <IconButton 
            className={classes.iconClose}
            onClick={() => closedMessageModal(false)}
          >
            <Close />
          </IconButton>
          {this.handleModalBody()}
        </div>
      </Modal>
    )
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MessageModal));
