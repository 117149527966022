import axios from "axios";
import { ROOT_URL } from "./actions_constants";

export const FETCH_APP_VERSION = "fetch_app_version";

const fetchAppVersionSuccess = app_version => ({
  type: FETCH_APP_VERSION,
  payload: app_version
});

export const fetchAppVersion = (callback) => (dispatch) => {

  return (axios.get(`${ROOT_URL}app_version`, 
    {}))
    .then(result => {
      const app_version = result.data;

      dispatch(fetchAppVersionSuccess(app_version));

      if(callback) {
        callback(result);
      }

      return app_version;
    })
    .catch(error => {
      console.log(error)
      
      if (error.response && error.response.data) {
        callback(error.response.data)
      }
    })
}
