import React from 'react';

import Typography from '@material-ui/core/Typography';

export const RenderUpload = ({
  id,
  type,
  upload_type,
  className,
  input: { name, onChange, value },
  uploaded,
  updatePreview
}) => {
  let uploadedName = '';

  if (value && value[0]) {
    uploadedName = value[0].name;
    updatePreview(name, value, uploaded, upload_type);
  }

  return (
    <div>
      <input 
        id={id}
        name={name}
        type={type}
        onChange={onChange}        
      />
      <Typography className={className} variant="h5">{uploadedName}</Typography>
    </div>
  )
};
