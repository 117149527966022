import { createTheme } from "@material-ui/core/styles";
// import { blue, green, purple } from "@material-ui/core/colors";

//verbTEAMs colors
export const verbPrimary = '#7222CD';
export const verbSecondary = '#3B82F6';
export const verbAccent = '#14B8A7';
export const verbDarkBlue = '#333953';
export const verbLavender = '#7222CD';
export const verbPurple = '#7222CD';
export const verbLightPurple = '#A785FD';
export const verbDarkPurple = '#4F1C86';
export const verbGray = '#7F8095';
export const verbDarkHeading = '#333953';
export const verbTurquoise = '#3B82F6';
export const verbHelperText = '#DA414A';
export const verbBlue = '#3B82F6';
export const verbDarkerBlue = '#0950c3';
export const verbSeaGreen = '#3CC496';

export const black = '#000000';
export const notBlack = '#011627';
export const darkBlue = '#002E4C';
export const storm = '#2F323A';
// export const energy = "#EF476F";
export const energy = verbPurple;
// export const darkEnergy = "#EB375C";
export const darkEnergy = verbDarkPurple;
// export const navy = "#004777";
export const navy = verbDarkHeading;
export const blue = "#01467B";
export const teal = "#14B8A7";
// export const torquoise = "#00C1C8";
export const torquoise = verbTurquoise;
// export const darkTorquoise = "#00adb3";
export const darkTorquoise = verbTurquoise;
// export const peacock = "#2D728F";
// export const darkPeacock = "#1f4d61";
export const peacock = verbDarkHeading;
export const darkPeacock = verbDarkHeading;
// export const lightDolphin = "#7b959d";
// export const dolphin = "#627C85";
// export const darkDolphin = '#2b373b';
export const lightDolphin = verbGray;
export const dolphin = verbGray;
export const darkDolphin = verbGray;
export const slimer = "#38EF00";
export const darkGrayBorder = "#262626";
export const darkGray = "#808080";
export const gray = "#979797";
export const inputBorderGray = "#0000003b";
export const beforeLightGray = "#D5D5D5";
export const lightGray = "#E5E5E5";
export const lighterGray = "#EDEDED";
export const evenLighterGray = "#F5F5F5";
export const borderGray = "#E5E5E5";
export const azureMist = "#BCFFFC";
export const white = "#FFF";
export const lightOpaqueBackground = "rgba(200, 200, 200, .08)";
export const opaqueBackground = "rgba(200, 200, 200, .15)";
export const menuDivider = "#323f42";
// export const companyText = "#627C85";
export const companyText = verbGray;
// export const helperText = "#F44336";
export const helperText = verbHelperText;
export const highlight = "#0000";
export const listHover = "#00000010";
// const activeBlue = "#4d7ea0";

const defaultTheme = createTheme();
const theme = createTheme({
  palette: {
    primary: {
	    // light: "#4D7EA0",
     //  main: "#004777",
     //  dark: "#003764",
      light: verbGray,
      main: verbDarkBlue,
      dark: verbDarkHeading,
      contrastText: "rgba(255, 255, 255, 1)"
		},
    secondary: {
    	light: verbLightPurple,
      main: verbPurple,
      dark: verbDarkPurple,
      contrastText: "rgba(255, 255, 255, 1)"
    }, // Switching the dark mode on is a single property value change.
    accent: {
      main: verbAccent
    },
  	text: {
  		primary: peacock,
  		secondary: dolphin
  	},
  	input: {
  		bottomLine: peacock,
  		disabled: lightGray,
  		helperText: helperText,
  		inputText: peacock,
  		labelText: dolphin
  	},
  	action: {
  	  active: peacock,
  	  disabled: dolphin
  	},
  	background: {
	    default: "#fafafa",
	    paper: white,
	    appBar: "#f5f5f5",
	    contentFrame: "#eeeeee",
	    chip: "#e0e0e0"
  	},
  },
  typography: {
    useNextVariants: true,
    fontFamily: "\"Gotham-Verb\", \"Helvetica\", \"Arial\", sans-serif",
    fontWeightLight: 300,
    fontWeightRegular: 300,
    fontWeightMedium: 600,
    fontWeightBold: 600,
    h2: {
      fontSize: '2.1rem',
      fontWeight: 300,
      [defaultTheme.breakpoints.down('lg')]: {
        fontSize: '2rem'
      }
    },
    h3: {
      color: notBlack,
      fontSize: '1.4rem',
      fontWeight: 200,
      lineHeight: 1.5,
      [defaultTheme.breakpoints.down('lg')]: {
        fontSize: '1.3rem'
      }
    },
    h4: {
      color: peacock,
      fontSize: '1.1rem',
      fontWeight: 400,
      [defaultTheme.breakpoints.down('lg')]: {
        fontSize: '1rem'
      }
    },
    h5: {
      fontSize: '.95rem',
      fontWeight: 300,
      color: peacock,
      [defaultTheme.breakpoints.down('lg')]: {
        fontSize: '.9rem'
      }
    },
    h6: {
      fontSize: '1rem',
      fontWeight: 300,
      color: peacock,
      [defaultTheme.breakpoints.down('lg')]: {
        fontSize: '.9rem' 
      }
    },
    subtitle1: {
      fontSize: '.85rem',
      lineHeight: '1.15rem',
      fontWeight: 300,
      color: dolphin,
      [defaultTheme.breakpoints.down('lg')]: {
        fontSize: '.8rem'
      }
    },
    body1: {
      fontSize: '.85rem',
      fontWeight: 300,
      lineHeight: '1.15em',
      color: verbGray,
      [defaultTheme.breakpoints.down('lg')]: {
        fontSize: '1.05em'
      }
    },
    body2: {
      fontWeight: 300,
      fontSize: '.85rem',
      [defaultTheme.breakpoints.down('lg')]: {
        fontSize: '.8rem'
      }
    }
  },
  overrides: {
    MuiCssBaseLine: {
      body: {
        fontWeight: 300
      }
    },
    MuiMenu: {
      paper: {
        borderRadius: 5
        // boxShadow: '1px 3px 6px 0px rgba(0, 0, 0, 0.05)'
      }
    },
    MuiMenuItem: {
      root: {
        boxSizing: 'content-box',
        color: peacock,
        width: 'initial',
        height: 'initial',
        fontSize: '1rem',
        '&$selected': {
          backgroundColor: '#00000010 !important',
          // backgroundColor: `${energy} !important`,
          // color: white
          // background: 'none !important',
          color: energy
        },
        minHeight: 'auto'
      },
      gutters: {
        padding: '7px 15px'
      }
    },
    MuiButtonBase: {
      root: {
        '&$disabled': {
          cursor: 'not-allowed'
        }
      }
    },
    MuiButton: {
      root: {
        borderRadius: '20px',
        textTransform: 'none',
        zIndex: 10,
        fontWeight: 300,
        fontSize: 14
      },
      mini: {
      	minHeight: '30px',
      	width: '30px',
      	height: '30px'
      }
    },
    MuiFormLabel: {
      root: {
        fontSize: '.95rem'
      }
    },
    MuiFormControl: {
    	root: {
    		width: '100%'
    	},
      marginNormal: {
        marginTop: 15,
        marginBottom: 15
      },
      marginDense: {
        marginTop: 10,
        marginBottom: 10
      }
    },
    MuiFormControlLabel: {
      label: {
        color: ` ${dolphin} !important`,
        fontSize: '.95rem !important',
        fontWeight: 300
      }
    },
    MuiFormHelperText: {
      root: {
        '&$error': {
          color: helperText,
          marginTop: 5,
          fontSize: '.85rem'
        }
      }
    },
    MuiInput: {
    	root: {
    		width: '100%',
        '&$focused': {
          color: peacock
        },
        '&$disabled': {
          color: `${dolphin} !important`
        }
    	},
    	input: {
    		color: peacock,
    		"&:after": {
    			backgroundColor: peacock
    		}
    	},
      inputMarginDense: {
        padding: '0 0 0 3px !important',
        height: '23px'
      }
    },
    MuiInputLabel: {
    	root: {
    		color: dolphin
    	},
      shrink: {
        opacity: .85,
        fontSize: '.95rem'
      },
      outlined: {
        '&$shrink': {
          transform: 'translate(14px, -6px) scale(0.67)'
        }
      }
    },
    MuiInputBase: {
      root: {
        fontSize: '1rem'
      }
    },
    MuiCheckbox: {
      root: {
        '&>span>svg': {
          marginRight: 5,
          fontSize: '1.4rem !important'
        },
        color: verbPurple
      },
      colorSecondary: {
        '&$checked': {
          color: `${verbPurple}`
        }
      }
    },
    MuiList: {
      padding: {
        paddingTop: 10,
        paddingBottom: 10
      },
      root: {
        '&>div': {
          outline: 'none !important'
        }
      }
    },
    MuiListItem: {
      root: {
        color: peacock
      },
      container: {
        borderWidth: '1px 0 0',
        borderStyle: 'solid',
        borderColor: lighterGray,
        // '&:first-of-type': {
        //   borderWidth: '0'
        // }
      },
      button: {
        '&:hover': {
          backgroundColor: listHover
        },
        '&:focus': {
          backgroundColor: 'none'
        }
      },
      secondaryAction: {
        paddingRight: 30
      }
    },
    MuiListItemSecondaryAction: {
      root: {
        right: 0
      }
    },
    MuiListItemIcon: {
      root: {
        marginRight: 12,
        position: 'relative',
        top: 0,
        left: 0,
        minWidth: 'initial'
      }
    },
    MuiListItemText: {
      root: {
        padding: 0,
        margin: 0
      },
      primary: {
        width: '100%',
        fontSize: '.95rem',
        color: peacock,
        fontWeight: 300,
        [defaultTheme.breakpoints.down('md')]: {
          fontSize: '.9rem'
        }
      },
      secondary: {
        fontSize: '.9rem'
      }
    },
    MuiSvgIcon: {
      root: {
        fontSize: 17,
        // width: 20,
        // height: 20
      }
    },
    MuiTableSortLabel: {
      root: {
        height: '40px !important',
        fontSize: '.85rem',
        lineHeight: '1.15rem',
        paddingBottom: 5,
        fontWeight: 300,
        color: darkPeacock,
        '&:hover': {
          color: `${darkEnergy} !important`
        },
        [defaultTheme.breakpoints.down('lg')]: {
          fontSize: '.8rem'
        }
      },
      active: {
        color: `${energy} !important`
      },
      icon: {
        margin: '0 5px 0 0 !important'
      }
    },
    MuiTooltip: {
      tooltip: {
        fontWeight: 300
      },
      popper: {
        display: 'none'
      }
    },
    MuiToolbar: {
      root: {
        width: '100%',
        minHeight: '30px !important'
      },
      gutters: {
        borderBottom: '0 !important'
      }
    },
    MuiTable: {
      root: {
        borderCollapse: 'collapse !important',
        marginBottom: '0 !important'
      }
    },
    MuiTableRow: {
      head: {
        height: 30,
        borderTop: 'none',
        borderBottom: `1px solid ${beforeLightGray}`,
        '&:hover': {
          background: 'none'
        }
      },
      root: {
        height: 65,
        minHeight: 30,
        borderTop: `1px solid ${lighterGray}`,
        '&:hover': {
          backgroundColor: listHover
        },
        cursor: 'pointer',
        '&:first-of-type': {
          borderTop: 'none'
        }
      }
    },
    MuiTableCell: {
      root: {
        // borderTop: `1px solid ${borderGray}`,
        borderBottom: 'none',
        wordWrap: 'break-word',
        '&:first-of-type': {
          // borderLeft: `1px solid ${borderGray}`,
        },
        '&:last-of-type': {
          // borderRight: `1px solid ${borderGray}`,
        }       
      },
      head: {
        border: 'none',
        padding: '8px 4px 0 !important',
        '&:first-of-type': {
          border: 'none',
        },
        '&:last-of-type': {
          border: 'none',
        }
      },
    },
    MuiBackdrop: {
      root: {
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: -1,
        position: 'fixed',
        willChange: 'opacity',
        backgroundColor: '#00000080',
        webkitTapHighlightColor: '#0000',
        opacity: 1,
        transition: 'opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
      }
    },
    MuiChip: {
      root: {
        border: `1px solid ${peacock}`,
        backgroundColor: peacock,
        padding: '12px 5px 12px 0px',
        fontSize: '.75rem',
        color: white,
        '&:last-of-type': {
          marginRight: '5px'
        }
      },
      label: {
        padding: '0 12px 2px 5px'
      },
      clickable: {
        '&:hover': {
          backgroundColor: peacock
        },
        '&:focus': {
          backgroundColor: peacock
        }
      },
      deletable: {
        '&:hover': {
          backgroundColor: peacock
        },
        '&:focus': {
          backgroundColor: peacock
        }
      }
    },
    MuiSelect: {
      select: {
        '&:focus': {
          background: 'none'
        }
      },
      icon: {
        top: 'calc(50% - 15px)',
        fontSize: 28
      }
    },
    // MuiGrid: {
    //   typeItem: {
    //     paddingTop: '0 !important'
    //   }
    // },
    TableSelectAllCell: {
      cell: {
        '&>span': {
          width: 24,
          height: 24,
          paddingLeft: 10
        }
      }
    },
    MuiPopover: {
      paper: {
        padding: '3px 10px'
      }
    },
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: peacock
      }
    },
    MuiTab: {
      root: {
        minHeight: 40,
        fontWeight: 300
      },
      label: {
        fontSize: '1.05rem !important',
        textTransform: 'none'
      }
    },
    MuiIconButton: {
      root: {
        padding: 0
      }
    },
    MuiSwitch: {
      colorSecondary: {
        '&$checked': {
          color: teal,
          '& + $track': {
            backgroundColor: teal
          }
        }
      }
    },
    MuiPaper: {
      elevation1: {
        marginTop: 5,
        border: `1px solid ${inputBorderGray}`,
        borderRadius: '5px !important',
        boxShadow: 'none'
      }
    },
    MuiExpansionPanel: {
      root: {
        marginTop: 15,
        '&$expanded': {
          '&:last-child': {
            paddingBottom: 15
          }
        }
      }
    },
    MuiExpansionPanelSummary: {
      root: {
        minHeight: '50px !important',
        padding: '0 14px',
        '&$focused': {
          backgroundColor: 'initial !important'
        }
      },
      content: {
        display: 'block',
        margin: '10px 0 !important',
        '&>:last-child': {
          paddingRight: 0
        }
      }
    },
    MuiExpansionPanelDetails: {
      root: {
        display: 'block',
        padding: '8px 24px 0'
      }
    },
    MuiFab: {
      root: {
        textTransform: 'none',
        fontWeight: 300,
        [defaultTheme.breakpoints.down('md')]: {
          fontSize: '.8rem'
        }
      },
      extended: {
        padding: '0 12px 0 8px !important'
      }
    },
    MuiModal: {
      root: {
        outline: 'none !important',
        '&>div': {
          outline: 'none !important'
        }
      }
    },
    MuiTabs: {
      root: {
        minHeight: 42
      }
    }
  }
});

export default theme;