import axios from "axios";
import { ROOT_URL } from "./actions_constants";

export const FETCH_ALL_APP_PROFILES = "fetch_app_profiles";
export const FETCH_APP_PROFILES = "fetch_app_profiles";
export const FETCH_APP_PROFILES_PERMISSION = "fetch_app_profiles_permission";
export const FETCH_APP_PROFILE = "fetch_app_profile";
export const FETCH_APP_USERS_APP_PROFILES = "fetch_app_users_app_profiles";
export const ACTIVE_APP_PROFILE = "active_app_profile";
export const CREATE_APP_PROFILE = "create_app_profile";
export const UPDATE_APP_PROFILE = "update_app_profile";
export const DELETE_APP_PROFILE = "delete_app_profile";

//fetch all app profiles and showcase templates
const fetchAllAppProfilesSuccess = app_profiles => ({
  type: FETCH_ALL_APP_PROFILES,
  payload: app_profiles
});

export const fetchAllAppProfiles = (token, companyId, callback) => (dispatch) => {

  const TOKEN = token;

  return (axios.get(`${ROOT_URL}company/all_app_profiles/${companyId}`, 
    { 
      headers: { 
        Authorization: 'Bearer ' + TOKEN
      }
    }))
    .then(result => {
      const app_profiles = result.data;

      dispatch(fetchAllAppProfilesSuccess(app_profiles));

      if(callback) {
        callback(result);
      }

      return result;
    })
    .catch(error => {
      console.log(error)
      
      if (error.response && error.response.data) {
        callback(error.response.data)
      }
    })
}
//end fetch all app profiles and showcase templates

const fetchAppProfilesSuccess = app_profiles => ({
  type: FETCH_APP_PROFILES,
  payload: app_profiles
});

export const fetchAppProfiles = (token, companyId, callback) => (dispatch) => {

  const TOKEN = token;

  return (axios.get(`${ROOT_URL}company/app_profiles/${companyId}`, 
    { 
      headers: { 
        Authorization: 'Bearer ' + TOKEN
      }
    }))
    .then(result => {
      const app_profiles = result.data;

      dispatch(fetchAppProfilesSuccess(app_profiles));

      if(callback) {
        callback(result);
      }

      return result;
    })
    .catch(error => {
      console.log(error)
      
      if (error.response && error.response.data) {
        callback(error.response.data)
      }
    })
}

const fetchAppProfilesPermissionSuccess = app_profiles => ({
  type: FETCH_APP_PROFILES_PERMISSION,
  payload: app_profiles
});

export const fetchAppProfilesPermission = (token, companyId, userId, callback) => (dispatch) => {

  const TOKEN = token;

  return (axios.get(`${ROOT_URL}company/app_profiles/${companyId}/${userId}`, 
    { 
      headers: { 
        Authorization: 'Bearer ' + TOKEN
      }
    }))
    .then(result => {
      const app_profiles = result.data;

      dispatch(fetchAppProfilesPermissionSuccess(app_profiles));

      if(callback) {
        callback(result);
      }

      return result;
    })
    .catch(error => {
      console.log(error)
      
      if (error.response && error.response.data) {
        callback(error.response.data)
      }
    })
}

const fetchAppProfileSuccess = app_profile => ({
  type: FETCH_APP_PROFILE,
  payload: app_profile
});

export const fetchAppProfile = (token, id, callback) => (dispatch) => {

  const TOKEN = token;

  return (axios.get(`${ROOT_URL}app_profiles/${id}`,
    { 
      headers: { 
        Authorization: 'Bearer ' + TOKEN
      }
    }))
  .then(result => {
    const app_profile = result.data;

    dispatch(fetchAppProfileSuccess(app_profile));

    if(callback) {
      callback(result);
    }

    return result;
  })
  .catch(error => {
    console.log(error)
    
    if (error.response && error.response.data) {
      callback(error.response.data)
    }
  })

}

const fetchAppUserAppProfilesSuccess = app_profiles => ({
  type: FETCH_APP_USERS_APP_PROFILES,
  payload: app_profiles
})

export const fetchAppUserAppProfiles = (token, callback) => (dispatch) => {

  const TOKEN = token;

  return (axios.get(`${ROOT_URL}app_user_app_profiles`,
    { 
      headers: { 
        Authorization: 'Bearer ' + TOKEN
      }
    }))
  .then(result => {
    const app_profiles = result.data;

    dispatch(fetchAppUserAppProfilesSuccess(app_profiles));

    if(callback) {
      callback(result);
    }

    return result;
  })
  .catch(error => {
    console.log(error)
    
    if (error.response && error.response.data) {
      callback(error.response.data)
    }
  })

}

const activeAppProfileSuccess = app_profile => ({
  type: ACTIVE_APP_PROFILE,
  payload: app_profile
})

export const activeAppProfile = (appProfile, callback) => (dispatch) => {
  dispatch(activeAppProfileSuccess(appProfile))
  callback(appProfile)
  return appProfile
} 

const createAppProfileSuccess = app_profile => ({
  type: CREATE_APP_PROFILE,
  payload: app_profile
});

export const createAppProfile = (token, values, callback) => (dispatch) => {

  const TOKEN = token;

  return (axios.post(`${ROOT_URL}app_profiles`,
      values,
      { 
        headers: { 
          Authorization: 'Bearer ' + TOKEN
        }
      }))
    .then(result => {
      const app_profile = result.data;

      dispatch(createAppProfileSuccess(app_profile));

      if(callback) {
        callback(result);
      }

      return result;
    })
    .catch(error => {
      console.log(error)
      
      if (error.response && error.response.data) {
        callback(error.response.data)
      }
    })
}

const updateAppProfileSuccess = app_profile => ({
  type: UPDATE_APP_PROFILE,
  payload: app_profile
});

export const updateAppProfile = (token, values, id, callback) => (dispatch) => {

  const TOKEN = token;

  return (axios.put(`${ROOT_URL}app_profiles/${id}`, 
    values,
    { 
      headers: { 
        Authorization: 'Bearer ' + TOKEN
      }
    }))
    .then(result => {
      const app_profile = result.data;

      dispatch(updateAppProfileSuccess(app_profile));

      if(callback) {
        callback(result);
      }

      return result;
    })
    .catch(error => {
      console.log(error)
      
      if (error.response && error.response.data) {
        callback(error.response.data)
      }
    })
}

const deleteAppProfileSuccess = appProfile => ({
  type: DELETE_APP_PROFILE,
  payload: appProfile
});

export const deleteAppProfile = (token, id, callback) => (dispatch) => {

  const TOKEN = token;

  return (axios.delete(`${ROOT_URL}app_profiles/${id}`, 
    { 
      headers: { 
        Authorization: 'Bearer ' + TOKEN
      }
    }))
    .then(result => {
      const appProfile = result.data;

      dispatch(deleteAppProfileSuccess(appProfile));

      if(callback) {
        callback(result);
      }

      return result;
    })
    .catch(error => {
      console.log(error)
      
      if (error.response && error.response.data) {
        callback(error.response.data)
      }
    })
}
