import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import { withStyles } from '@material-ui/core/styles'

import Paper from '@material-ui/core/Paper'
import MenuItem from '@material-ui/core/MenuItem'

import deepmerge from 'deepmerge'

import { peacock, teal, torquoise } from '../../../../theme';

const styles = theme => ({
  optionsContainer: {
    display: 'none',
    maxHeight: 300,
    overflowY: 'auto',
  },
  optionsContainerOpen: {
    position: 'absolute',
    display: 'block',
    zIndex: 90,
    marginBottom: theme.spacing(3),
    left: 0,
    // top: '100%',
    right: 0,
  },
  listBlock: {
    display: 'block'
  },
  smallText: {
    lineHeight: '1.2em',
    fontSize: '.9rem'
  },
  peacockText: {
    color: peacock
  },
  tealText: {
    color: teal
  },
  torquoiseText: {
    color: torquoise
  }
})

// function addBold(start, end, str) {
//   return `${str.slice(0, start)}<b>${str.slice(start, end + 1)}</b>${str.slice(end + 1, str.length)}`
// }

function showLabel({ item, matches }, { label, value }) {
  // const match = matches.find(i => i.key === value)

  // if (match) {
  //   return match.indices.reduce((acc, curr) => {
  //     acc.result = addBold(curr[0] + acc.count, curr[1] + acc.count, acc.result)
  //     acc.count += 7

  //     return acc
  //   }, { count: 0, result: match.value }).result
  // }
  let displayValue = null;

  if (item.value) {
    displayValue = item.value;
  } else if (item.phone) {
    displayValue = item.phone;
  }

  return displayValue;
}

const Options = ({ classes, open, options, onSelect, fields, focus }) => {
  const containerOpen = open && options.length > 0
  const containerClasses = cx(
    classes.optionsContainer,
    containerOpen && classes.optionsContainerOpen
  )

  return (
    <Paper className={containerClasses} square>
      {options.map((item, index) => {
        const option = item.item
        return (
          <MenuItem
            key={option.label + option.value}
            onClick={() => onSelect(option)}
            classes={{
              root: classes.menuItemRoot,
              selected: classes.menuItemSelected,
            }}
            className={classes.listBlock}
            selected={focus === index}
          >
            {option.name &&
              <div className={`${classes.smallText} ${classes.peacockText}`}>{option.name}</div>
            }
            <div 
              dangerouslySetInnerHTML={{ __html: showLabel(item, fields) }} // eslint-disable-line
            />
            {(option.value && option.phone) &&
              <div className={`${classes.smallText} ${classes.tealText}`}>{option.phone}</div>
            }
            {option.owner_name &&
              <div className={`${classes.smallText} ${classes.torquoiseText}`}>Owner: {option.owner_name}</div>
            }
          </MenuItem>
        )
      })}
    </Paper>
  )
}

Options.propTypes = {
  classes: PropTypes.object,
  open: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.object),
  onSelect: PropTypes.func,
  fields: PropTypes.object,
  focus: PropTypes.number,
}

export default (customStyles) => {
  if (customStyles) {
    return withStyles(theme => deepmerge(styles(theme), customStyles(theme)))(Options)
  }

  return withStyles(styles)(Options)
}
