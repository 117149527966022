import { applyMiddleware, createStore } from 'redux'

import promise from 'redux-promise';
import thunk from 'redux-thunk';

import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/hardSet';

import { composeWithDevTools } from 'redux-devtools-extension';

import rootReducer from "./reducers/reducers_index";

const persistConfig = {
  key: 'root',
  storage: storage,
  stateReconciler: autoMergeLevel2
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default () => {
  const composeEnhancers = composeWithDevTools({});

  const store = createStore(
    persistedReducer,
    composeEnhancers(
      applyMiddleware(
        promise,
        thunk
      )
    )
  );

  const persistor = persistStore(store);

  return { store, persistor };
};