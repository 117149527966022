import _ from "lodash";
import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";
import { styles } from "./styles";

import Typography from "@material-ui/core/Typography";

import Warning from "@material-ui/icons/Warning";

import { checkDoesNotInclude } from "./helpers/helperFunctions";

import { fetchAlerts } from "../actions/actions_index";

const MASTER_ADMIN = "Master Admin";
const ROOM = "Room";
const SPACE = "Space";
const SHOWCASES = "Showcases";
const SHOWCASE = "Showcase";

class Alerts extends Component {
  checkAlerts() {
    const { token, companyId } = this.props;

    if (companyId) {
      this.props.fetchAlerts(token, companyId, (data) => {});
    }
  }

  componentDidMount() {
    this.checkAlerts();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.location.pathname !== nextProps.location.pathname) {
      this.checkAlerts();
    }
  }

  render() {
    const { enterprise, company, alerts, classes } = this.props;
    const currentPath = this.props.location.pathname;

    let viewer = false;

    if (
      currentPath.includes(MASTER_ADMIN.toLowerCase().replace(/ /g, "_")) ||
      currentPath.includes(ROOM.toLowerCase().replace(/ /g, "_")) ||
      currentPath.includes(SPACE.toLowerCase().replace(/ /g, "_")) ||
      (!currentPath.includes(SHOWCASES.toLowerCase().replace(/ /g, "_")) &&
        currentPath.includes(SHOWCASE.toLowerCase().replace(/ /g, "_")))
    ) {
      viewer = true;
    }

    if (
      !viewer &&
      company &&
      !enterprise &&
      !company.tour &&
      !company.onboarding &&
      alerts &&
      alerts.team_no_groups
    ) {
      return (
        <div className={classes.alertContainer}>
          <Link to="/users">
            <Typography variant="h5" className={classes.alertText}>
              <Warning className={classes.alertIcon} />{" "}
              {alerts.team_no_groups.length} of your Teams haven't been assigned
              any content groups!
            </Typography>
          </Link>
        </div>
      );
    } else {
      const width =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;

      if (
        enterprise &&
        !viewer &&
        !checkDoesNotInclude(currentPath) &&
        width < 1024
      ) {
        return (
          <div className={classes.alertContainer}>
            <Link to="/users">
              <Typography variant="h5" className={classes.alertText}>
                <Warning className={classes.alertIcon} /> Please use verbTEAMS
                on a desktop or a tablet for the best experience.
              </Typography>
            </Link>
          </div>
        );
      }
      return null;
    }
  }
}

function mapStateToProps(state) {
  if (
    _.isEmpty(state.login.token) ||
    _.isEmpty(state.login.company) ||
    _.isEmpty(state.login.user)
  ) {
    return {
      loggedOut: true,
    };
  }

  return {
    token: state.login.token,
    companyId: state.login.company._id,
    enterprise: state.login.company.enterprise,
    company: state.company,
    alerts: state.alerts,
  };
}

const mapDispatchToProps = (dispatch) => ({
  fetchAlerts: (token, companyId, callback) =>
    dispatch(fetchAlerts(token, companyId, callback)),
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(Alerts);
