import axios from "axios";
import { ROOT_URL } from "./actions_constants";

export const FETCH_LIBRARY = "fetch_library";
export const FETCH_LIBRARY_ITEM = "fetch_library_item";
export const FETCH_LIBRARY_ITEMS = "fetch_library_items";
export const FETCH_APP_USER_PERSONAL = "fetch_app_user_personal";
export const CREATE_LIBRARY_ITEMS = "create_library_items";
export const UPLOAD_CONTENT = "upload_content";
export const UPDATE_LIBRARY_ITEM = "update_library_item";
export const UPDATE_LIBRARY_ITEMS = "update_library_items";
export const DELETE_LIBRARY_ITEM= "delete_library_item";
export const SHARE_LIBRARY_ITEMS= "share_library_items";

const fetchLibrarySuccess = library => ({
  type: FETCH_LIBRARY,
  payload: library
});

export const fetchLibrary = (token, companyId, groups, callback) => (dispatch) => {

  const TOKEN = token;

  const values = { groups }

  return (axios.post(`${ROOT_URL}company/libraries/${companyId}`, 
    values,
    { 
      headers: { 
        Authorization: 'Bearer ' + TOKEN
      }
    }))
    .then(result => {
      const library = result.data;

      dispatch(fetchLibrarySuccess(library));

      if(callback) {
        callback(result);
      }

      return result;
    })
    .catch(error => {
      console.log(error)
      
      if (error.response && error.response.data) {
        callback(error.response.data)
      }
    })
}

const fetchLibraryItemViewSuccess = libraryItem => ({
  type: FETCH_LIBRARY_ITEM,
  payload: libraryItem
});

export const fetchLibraryItemView = (id, callback) => (dispatch) => {
  return (axios.get(`${ROOT_URL}libraries/view/${id}`))
  .then(result => {
    const libraryItem = result.data;

    dispatch(fetchLibraryItemViewSuccess(libraryItem));

    if(callback) {
      callback(result);
    }

    return result;
  })
  .catch(error => {
    console.log(error)
    
    if (error.response && error.response.data) {
      callback(error.response.data)
    }
  })

}

const fetchLibraryItemSuccess = libraryItem => ({
  type: FETCH_LIBRARY_ITEM,
  payload: libraryItem
});

export const fetchLibraryItem = (token, id, callback) => (dispatch) => {
  
  const TOKEN = token;

  return (axios.get(`${ROOT_URL}libraries/${id}`,
    { 
      headers: { 
        Authorization: 'Bearer ' + TOKEN
      }
    }))
  .then(result => {
    const libraryItem = result.data;

    dispatch(fetchLibraryItemSuccess(libraryItem));

    if(callback) {
      callback(result);
    }

    return result;
  })
  .catch(error => {
    console.log(error)
    
    if (error.response && error.response.data) {
      callback(error.response.data)
    }
  })

}

const fetchLibraryItemsSuccess = libraryItems => ({
  type: FETCH_LIBRARY_ITEMS,
  payload: libraryItems
});

export const fetchLibraryItems = (token, values, callback) => (dispatch) => {
  
  const TOKEN = token;

  return (axios.post(`${ROOT_URL}libraries_share_cart`,
    values,
    { 
      headers: { 
        Authorization: 'Bearer ' + TOKEN
      }
    }))
  .then(result => {
    const libraryItems = result.data;

    dispatch(fetchLibraryItemsSuccess(libraryItems));

    if(callback) {
      callback(result);
    }

    return result;
  })
  .catch(error => {
    console.log(error)
    
    if (error.response && error.response.data) {
      callback(error.response.data)
    }
  })

}

const fetchAppUserPersonalSuccess = personal => ({
  type: FETCH_APP_USER_PERSONAL,
  payload: personal
})

export const fetchAppUserPersonal = (token, callback) => (dispatch) => {

  const TOKEN = token;

  return (axios.get(`${ROOT_URL}app_user_personal`,
    { 
      headers: { 
        Authorization: 'Bearer ' + TOKEN
      }
    }))
  .then(result => {
    const personal = result.data;

    dispatch(fetchAppUserPersonalSuccess(personal));

    if (callback) {
      callback(result);
    }

    return result;
  })
  .catch(error => {
    console.log(error)
    
    if (error.response && error.response.data) {
      callback(error.response.data)
    }
  })

}

const createLibraryItemsSuccess = libraryItems => ({
  type: CREATE_LIBRARY_ITEMS,
  payload: libraryItems
});

export const createLibraryItems = (token, values, groupId, callback) => (dispatch) => {
  const TOKEN = token;

  let url = `${ROOT_URL}libraries`;

  if (groupId) {
    url = `${ROOT_URL}libraries/${groupId}`
  }

  return (axios.post(url,
      values,
      { 
        headers: { 
          Authorization: 'Bearer ' + TOKEN
        }
      }))
    .then(result => {
      const libraryItems = result.data;

      dispatch(createLibraryItemsSuccess(libraryItems));

      if(callback) {
        callback(result);
      }

      return result;
    })
    .catch(error => {
      console.log(error)
      
      if (error.response && error.response.data) {
        callback(error.response.data)
      }
    })
}

const uploadContentSuccess = content => ({
  type: UPLOAD_CONTENT,
  payload: content
});

export const uploadContent = (token, values, callback) => (dispatch) => {
  const TOKEN = token;

  return (axios.post(`${ROOT_URL}libraries/upload_content`,
      values,
      { 
        headers: { 
          Authorization: 'Bearer ' + TOKEN
        }
      }))
    .then(result => {
      const content = result.data;

      dispatch(uploadContentSuccess(content));

      if(callback) {
        callback(result);
      }

      return result;
    })
    .catch(error => {
      console.log(error)
      
      if (error.response && error.response.data) {
        callback(error.response.data)
      }
    })
}

const updateLibraryItemSuccess = libraryItem => ({
  type: UPDATE_LIBRARY_ITEM,
  payload: libraryItem
});

export const updateLibraryItem = (token, values, id, callback) => (dispatch) => {

  const TOKEN = token;

  return (axios.put(`${ROOT_URL}libraries/${id}`, 
    values,
    { 
      headers: { 
        Authorization: 'Bearer ' + TOKEN
      }
    }))
    .then(result => {
      const libraryItem = result.data;

      dispatch(updateLibraryItemSuccess(libraryItem));

      if(callback) {
        callback(result);
      }

      return result;
    })
    .catch(error => {
      console.log(error)
      
      if (error.response && error.response.data) {
        callback(error.response.data)
      }
    })
}

//UPDATE MULTIPLE LIBRARY ITEMS
const updateLibraryItemsSuccess = libraryItems => ({
  type: UPDATE_LIBRARY_ITEMS,
  payload: libraryItems
});

export const updateLibraryItems = (token, values, callback) => (dispatch) => {

  const TOKEN = token;

  return (axios.put(`${ROOT_URL}libraries`, 
    values,
    { 
      headers: { 
        Authorization: 'Bearer ' + TOKEN
      }
    }))
    .then(result => {
      const libraryItems = result.data;

      dispatch(updateLibraryItemsSuccess(libraryItems));

      if(callback) {
        callback(result);
      }

      return result;
    })
    .catch(error => {
      console.log(error)
      
      if (error.response && error.response.data) {
        callback(error.response.data)
      }
    })
}
//END UPDATE MULTIPLE LIBRARY ITEMS

const deleteLibraryItemSuccess = libraryItem => ({
  type: DELETE_LIBRARY_ITEM,
  payload: libraryItem
});

export const deleteLibraryItem = (token, id, callback) => (dispatch) => {

  const TOKEN = token;

  return (axios.delete(`${ROOT_URL}libraries/${id}`, 
    { 
      headers: { 
        Authorization: 'Bearer ' + TOKEN
      }
    }))
    .then(result => {
      const libraryItem = result.data;

      dispatch(deleteLibraryItemSuccess(libraryItem));

      if(callback) {
        callback(result);
      }
      
      return result;
    })
    .catch(error => {
      console.log(error)
      
      if (error.response && error.response.data) {
        callback(error.response.data)
      }
    })
}

const shareLibraryItemsSuccess = libraryItems => ({
  type: SHARE_LIBRARY_ITEMS,
  payload: libraryItems
});

export const shareLibraryItems = (items, callback) => (dispatch) => {
  dispatch(shareLibraryItemsSuccess(items))
  callback(items)
}

