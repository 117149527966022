import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import { styles } from '../styles';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Modal from '@material-ui/core/Modal';

import Close from '@material-ui/icons/Close';

import LoadingDivContainer from '../subcomponents/loadingDiv';
import { modalTableHeight } from '../helpers/helperFunctions';
import GridContainer from '../subcomponents/gridContainer';

import {
  fetchTeams,
  fetchLibraryGroupsRaw,
  fetchLibraryGroupsMain,
  fetchAppProfileShowcases,
  fetchAppProfile,
  updateAppProfile,
} from '../../actions/actions_index';

class AddToAppProfileModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      selectedItems: [],
    };

    this.onSelectionChange = (selected) => {
      this.setState({
        selectedItems: selected,
      });
    };
  }

  componentDidMount() {
    const {
      token,
      companyId,
      showcases,
      selectedAppProfileId,
      addTeams,
      addShowcases,
    } = this.props;

    this.props.fetchAppProfile(token, selectedAppProfileId, (appProfile) => {
      if (addTeams) {
        this.props.fetchTeams(token, companyId, (teams) => {
          const currentTeams = appProfile.data.teams.map((value) =>
            _.findIndex(teams.data, ['_id', value])
          );

          this.setState({
            loading: false,
            selectedItems: currentTeams,
          });
        });
      } else if (addShowcases) {
        this.props.fetchAppProfileShowcases(
          token,
          companyId,
          (showcaseTemplates) => {
            const currentShowcases = appProfile.data.showcases.map((value) =>
              _.findIndex(showcaseTemplates.data, ['_id', value])
            );

            this.setState({
              loading: false,
              selectedItems: currentShowcases,
            });
          }
        );
      } else if (showcases) {
        this.props.fetchLibraryGroupsRaw(
          token,
          companyId,
          (libraryGroupsRaw) => {
            const currentLibraryGroups = appProfile.data.library_groups.map(
              (value) => _.findIndex(libraryGroupsRaw.data, ['_id', value])
            );

            this.setState({
              loading: false,
              selectedItems: currentLibraryGroups,
            });
          }
        );
      } else {
        this.props.fetchLibraryGroupsMain(
          token,
          companyId,
          (libraryGroupsMain) => {
            const currentLibraryGroups = appProfile.data.library_groups.map(
              (value) => _.findIndex(libraryGroupsMain.data, ['_id', value])
            );

            this.setState({
              loading: false,
              selectedItems: currentLibraryGroups,
            });
          }
        );
      }
    });
  }

  submitAddToAppProfileModal = () => {
    const {
      token,
      showcases,
      teams,
      libraryGroupsRaw,
      libraryGroupsMain,
      showcaseTemplates,
      selectedAppProfileId,
      addTeams,
      addShowcases,
      addedRemoved,
      newAppProfile,
      reCheckAlerts,
    } = this.props;
    const { selectedItems } = this.state;

    let added = [];

    this.setState({
      loading: true,
    });

    if (addTeams) {
      added = selectedItems.map((value) => {
        return teams[value]._id;
      });

      this.props.updateAppProfile(
        token,
        { teams: added },
        selectedAppProfileId,
        (updatedAppProfile) => {
          if (addedRemoved) {
            addedRemoved();
          }
          this.handleClosedAddToAppProfileModal(true, updatedAppProfile.data);
        }
      );
    } else if (addShowcases) {
      added = selectedItems.map((value) => {
        if (value >= 0) {
          return showcaseTemplates[value]._id;
        }
      });

      this.props.updateAppProfile(
        token,
        { showcases: added },
        selectedAppProfileId,
        (updatedAppProfile) => {
          if (addedRemoved) {
            addedRemoved();
          }
          this.handleClosedAddToAppProfileModal(true, updatedAppProfile.data);
        }
      );
    } else {
      selectedItems.map((value) => {
        if (showcases) {
          added.push(libraryGroupsRaw[value]._id);
        } else {
          added.push(libraryGroupsMain[value]._id);
        }

        return null;
      });

      this.props.updateAppProfile(
        token,
        { library_groups: added },
        selectedAppProfileId,
        (updatedAppProfile) => {
          if (reCheckAlerts) {
            reCheckAlerts(() => {
              if (addedRemoved) {
                addedRemoved();
              }

              this.handleClosedAddToAppProfileModal(
                true,
                updatedAppProfile.data
              );
            });
          } else {
            if (addedRemoved) {
              addedRemoved();
            }

            this.handleClosedAddToAppProfileModal(true, updatedAppProfile.data);
          }
        }
      );
    }
  };

  handleClosedAddToAppProfileModal = (
    updated,
    updatedAppProfile,
    newAppProfileTeams
  ) => {
    this.props.closedAddToAppProfileModal(
      updated,
      updatedAppProfile,
      newAppProfileTeams
    );
  };

  handleModalBody = () => {
    const {
      enterprise,
      showcases,
      teams,
      libraryGroupsRaw,
      libraryGroupsMain,
      showcaseTemplates,
      appProfile,
      addTeams,
      addShowcases,
      classes,
    } = this.props;
    const { loading, selectedItems } = this.state;

    let type = 'App Profile',
      title = '',
      subheading = '',
      subheading2 = '',
      subheading3 = '',
      rows = [],
      columns = [],
      tableColumnExtensions = [],
      sortingStateColumnExtensions = [],
      filteringStateColumnExtensions = [],
      sorting = [];

    if (showcases) {
      type = 'Showcase';
    } else if (!enterprise) {
      type = 'Team';
    }

    if (addTeams) {
      columns = [
        { name: 'name', title: 'Name' },
        {
          name: 'users',
          title: '# of Members',
          getCellValue: (row) => row.users.length,
        },
        { name: 'updatedAt', title: 'Last Updated' },
      ];

      tableColumnExtensions = [
        { columnName: 'name' },
        { columnName: 'users', width: 200 },
        { columnName: 'updatedAt', width: 170 },
      ];

      sortingStateColumnExtensions = [
        { columnName: 'name', sortingEnabled: true },
        { columnName: 'users', sortingEnabled: true },
        { columnName: 'updatedAt', sortingEnabled: true },
      ];

      filteringStateColumnExtensions = [
        { columnName: 'name', filteringEnabled: true },
        { columnName: 'users', filteringEnabled: true },
        { columnName: 'updatedAt', filteringEnabled: false },
      ];

      sorting = [{ columnName: 'name', direction: 'asc' }];
    } else if (addShowcases) {
      columns = [
        { name: 'name', title: 'Name' },
        { name: 'updatedAt', title: 'Last Updated' },
      ];

      tableColumnExtensions = [
        { columnName: 'name' },
        { columnName: 'updatedAt', width: 170 },
      ];

      sortingStateColumnExtensions = [
        { columnName: 'name', sortingEnabled: true },
        { columnName: 'updatedAt', sortingEnabled: true },
      ];

      filteringStateColumnExtensions = [
        { columnName: 'name', filteringEnabled: true },
        { columnName: 'updatedAt', filteringEnabled: false },
      ];

      sorting = [{ columnName: 'name', direction: 'asc' }];
    } else {
      columns = [
        { name: 'name', title: 'Name' },
        //NOTE: need to up date code to count all sub library group(s) files
        // { name: 'library_files', title: '# of Files', getCellValue: row => {
        //   if (Array.isArray(row.library_files) && row.library_files.length > 0) {
        //     return row.library_files.length;
        //   } else {
        //     return 0;
        //   }
        // }},
        { name: 'updatedAt', title: 'Last Updated' },
      ];

      tableColumnExtensions = [
        { columnName: 'name' },
        // { columnName: 'library_files', width: 200 },
        { columnName: 'updatedAt', width: 170 },
      ];

      sortingStateColumnExtensions = [
        { columnName: 'name', sortingEnabled: true },
        // { columnName: 'library_files', sortingEnabled: true },
        { columnName: 'updatedAt', sortingEnabled: true },
      ];

      filteringStateColumnExtensions = [
        { columnName: 'name', filteringEnabled: true },
        // { columnName: 'library_files', filteringEnabled: true },
        { columnName: 'updatedAt', filteringEnabled: false },
      ];

      sorting = [{ columnName: 'name', direction: 'asc' }];

      if (showcases) {
        columns.splice(1, 0, {
          name: 'parent',
          title: 'Parent Group',
          getCellValue: (row) => {
            if (row.parent) {
              return row.parent;
            } else {
              return 'none';
            }
          },
        });
        tableColumnExtensions.splice(1, 0, {
          columnName: 'parent',
          width: 230,
        });
        sortingStateColumnExtensions.splice(1, 0, {
          columnName: 'parent',
          sortingEnabled: true,
        });
        filteringStateColumnExtensions.splice(1, 0, {
          columnName: 'parent',
          filteringEnabled: true,
        });
      }
    }

    if (loading) {
      return <LoadingDivContainer type={'small'} />;
    } else {
      if (addTeams) {
        title = (
          <span>
            Manage Teams -{' '}
            <span className={classes.modalTitleItem}>{appProfile.name}</span>
          </span>
        );
        subheading = `Add or remove teams from selected ${type}.`;
        rows = teams;
      } else if (addShowcases) {
        title = (
          <span>
            Manage Showcases -{' '}
            <span className={classes.modalTitleItem}>{appProfile.name}</span>
          </span>
        );
        subheading = `Add or remove showcases from selected ${type}.`;
        rows = showcaseTemplates;
      } else {
        title = (
          <span>
            Manage Content -{' '}
            <span className={classes.modalTitleItem}>{appProfile.name}</span>
          </span>
        );
        subheading = `Add or remove content groups from selected ${type}.`;

        if (showcases) {
          subheading2 +=
            ' Showcase will display selected groups, and any of their sub groups (ONLY 1 level deep sub groups).';
          subheading3 +=
            ' Groups without any associated library files, will NOT display on the Showcase.';
          rows = libraryGroupsRaw;
        } else {
          console.log(libraryGroupsMain);
          libraryGroupsMain.forEach(function (libraryGroup) {
            if (libraryGroup.parent)
              libraryGroup.name =
                libraryGroup.name + ' — ' + libraryGroup.parent;
          });
          rows = libraryGroupsMain;
        }
      }

      return (
        <div className={classes.divContainer}>
          <Typography variant='h6'>{title}</Typography>
          <Typography
            variant='subtitle1'
            className={`${classes.modalSubheading} ${classes.modalSubheadingTable}`}
          >
            {subheading}
            {showcases && (
              <span>
                <br />
                {subheading2}
                <br />
                {subheading3}
              </span>
            )}
          </Typography>
          <GridContainer
            gridHeight={modalTableHeight(showcases)}
            rows={rows}
            columns={columns}
            tableColumnExtensions={tableColumnExtensions}
            sortingStateColumnExtensions={sortingStateColumnExtensions}
            sorting={sorting}
            selection={selectedItems}
            onSelectionChange={this.onSelectionChange}
            filteringStateColumnExtensions={filteringStateColumnExtensions}
            showSelectAll={true}
            showSelectionColumn={true}
            showRowDetail={false}
            classes={classes}
          />
          <Button
            className={`${classes.button} ${classes.modalButton}`}
            variant='contained'
            type='submit'
            color='secondary'
            onClick={this.submitAddToAppProfileModal}
          >
            Save
          </Button>
        </div>
      );
    }
  };

  render() {
    const { openAddToAppProfileModal, classes } = this.props;

    return (
      <Modal
        id='add-to-modal'
        aria-labelledby='add-to-app_profile-modal'
        aria-describedby='add-to-app_profile-modal'
        open={openAddToAppProfileModal}
        onClose={() => this.handleClosedAddToAppProfileModal(false, false)}
      >
        <div className={`${classes.modal} ${classes.mediumModal}`}>
          <IconButton
            className={classes.iconClose}
            onClick={() => this.handleClosedAddToAppProfileModal(false, false)}
          >
            <Close />
          </IconButton>
          {this.handleModalBody()}
        </div>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  if (_.isEmpty(state.login.token)) {
    return {};
  }

  return {
    token: state.login.token,
    companyId: state.login.company._id,
    enterprise: state.company.enterprise,
    teams: state.teams,
    libraryGroupsRaw: state.libraryGroupsRaw,
    libraryGroupsMain: state.libraryGroupsMain,
    showcaseTemplates: state.showcaseTemplates,
    appProfile: state.appProfile,
  };
}

const mapDispatchToProps = (dispatch) => ({
  fetchAppProfile: (token, id, callback) =>
    dispatch(fetchAppProfile(token, id, callback)),
  fetchTeams: (token, companyId, callback) =>
    dispatch(fetchTeams(token, companyId, callback)),
  fetchLibraryGroupsRaw: (token, companyId, callback) =>
    dispatch(fetchLibraryGroupsRaw(token, companyId, callback)),
  fetchLibraryGroupsMain: (token, companyId, callback) =>
    dispatch(fetchLibraryGroupsMain(token, companyId, callback)),
  fetchAppProfileShowcases: (token, companyId, callback) =>
    dispatch(fetchAppProfileShowcases(token, companyId, callback)),
  updateAppProfile: (token, values, id, callback) =>
    dispatch(updateAppProfile(token, values, id, callback)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(AddToAppProfileModal));
