import { FORGOT_PASSWORD } from "../actions/actions_forgot";
import { RESET_PASSWORD } from "../actions/actions_forgot";

export default function(state = {}, action) {
  switch (action.type) {
    case FORGOT_PASSWORD:

      if (!action.payload) {
        return ({
          error: null
        })
      }
      return action.payload

    case RESET_PASSWORD:

      if (!action.payload) {
        return ({
          error: null
        })
      }
      return action.payload

    default:
      return state;
  }
}