import _ from "lodash";
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import { styles } from '../styles';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Modal from '@material-ui/core/Modal';

import Close from '@material-ui/icons/Close';

import LoadingDivContainer from '../subcomponents/loadingDiv';
import { modalTableHeight } from '../helpers/helperFunctions';
import GridContainer from '../subcomponents/gridContainer';

import { fetchAppProfileShowcases } from '../../actions/actions_index';

class ShareShowcaseModal extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      selectedItems: []
    }

    this.onSelectionChange = selectedItems => {
      this.setState({ selectedItems });
    }
  }

  componentDidMount() {
    const { token, companyId, currentSelected, fetchAppProfileShowcases } = this.props;

    fetchAppProfileShowcases(token, companyId, showcaseTemplates => {
      const currentItems = currentSelected.map(value => _.findIndex(showcaseTemplates.data, ['_id', value]));

      this.setState({
        loading: false,
        selectedItems: currentItems
      })
    })
  }

  submitShareShowcaseModal = () => {
    const { showcaseTemplates, selectShareShowcase } = this.props;
    const { selectedItems } = this.state;
    const addedFiles = selectedItems.map(value => showcaseTemplates[value]._id);

    selectShareShowcase(addedFiles);
  }

  closeShareShowcaseModal = () => {
    this.props.closedShareShowcaseModal();
  }

  showcaseGrid = () => {
    const { showcaseTemplates, classes } = this.props;
    const { selectedItems } = this.state;

    // const rows = _.filter(showcaseTemplates, {showcase: true});
    const rows = showcaseTemplates;

    const columns = [
      { name: 'name', title: 'Name' },
      { name: 'updatedAt', title: 'Last Updated' }
    ];

    const tableColumnExtensions = [
      { columnName: 'name' },
      { columnName: 'updatedAt', width: 170 }
    ]

    const sortingStateColumnExtensions = [
      { columnName: 'name', sortingEnabled: true },
      { columnName: 'updatedAt', sortingEnabled: true }
    ]

    const filteringStateColumnExtensions = [
      { columnName: 'name', filteringEnabled: true },
      { columnName: 'updatedAt', filteringEnabled: false }
    ]

    const sorting = [
      { columnName: 'name', direction: 'asc' }
    ]

    return (
      <GridContainer 
        gridHeight={modalTableHeight()}
        rows={rows}
        columns={columns}
        tableColumnExtensions={tableColumnExtensions}
        sortingStateColumnExtensions={sortingStateColumnExtensions}
        sorting={sorting}
        selection={selectedItems}
        onSelectionChange={this.onSelectionChange}
        filteringStateColumnExtensions={filteringStateColumnExtensions}
        // integratedFilteringColumnExtensions={integratedFilteringColumnExtensions}
        // integratedSortingColumnExtensions={integratedSortingColumnExtensions}
        showSelectAll={true}
        showSelectionColumn={true}
        showRowDetail={false}
        classes={classes}
      />
    )
  }

  handleModalBody = () => {
    const { classes } = this.props;
    const { loading } = this.state;

    let title = '',
        subheading = '';

    if (loading) {
      return (
        <LoadingDivContainer />
      )
    } else {
      title = <span>Share Showcase(s)</span>;
      subheading = "Select showcase(s).";

      return (
        <div className={classes.divContainer}>
          <Typography variant="h6">
            {title}
          </Typography>
          <Typography variant="subtitle1" className={`${classes.modalSubheading} ${classes.modalSubheadingTable}`}>
            {subheading}
          </Typography>
          {this.showcaseGrid()}
          <Button className={`${classes.button} ${classes.modalButton}`} variant="contained" type="submit" color="secondary" onClick={this.submitShareShowcaseModal}>Select</Button>
        </div>
      )
    }
  }

  render() {
    const { openShareShowcaseModal, classes } = this.props;

    return (
      <Modal
        id="add-to-modal"
        aria-labelledby="share-showcase"
        aria-describedby="share-showcase"
        open={openShareShowcaseModal}
        onClose={this.closeShareShowcaseModal}
      >
        <div className={`${classes.modal} ${classes.mediumModal}`}>
          <IconButton 
            className={classes.iconClose}
            onClick={this.closeShareShowcaseModal}
          >
            <Close />
          </IconButton>
          {this.handleModalBody()}
        </div>
      </Modal>
    )
  }
}

function mapStateToProps(state) {
  if (_.isEmpty(state.login.token)) {
    return {}
  }

  return { 
    token: state.login.token,
    companyId: state.login.company._id,
    showcaseTemplates: state.showcaseTemplates
  };
}

const mapDispatchToProps = dispatch => ({
  fetchAppProfileShowcases: (token, companyId, callback) => dispatch(fetchAppProfileShowcases(token, companyId, callback))
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ShareShowcaseModal));