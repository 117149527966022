import _ from 'lodash';
import React, { Component } from 'react';
import { TransitionMotion, spring, presets } from 'react-motion';

import { withStyles } from '@material-ui/core/styles';
import { styles } from '../../styles';

// import { ListItem, ListItemSecondaryAction, ListItemText, ListItemIcon } from '@material-ui/core';
// import { Folder, FolderOpen } from '@material-ui/icons';
// import { FolderMultiple } from 'mdi-material-ui';

import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import Folder from '@material-ui/icons/Folder';
import FolderOpen from '@material-ui/icons/FolderOpen';
import Lock from '@material-ui/icons/Lock';
import FeaturedPlayListIcon from '@material-ui/icons/FeaturedPlayList';
import FolderMultiple from 'mdi-material-ui/FolderMultiple';

import LibraryGroupMenuButton from '../library_group/library_group_menu_button';

let GROUPS = [];
let HAS_CHILDREN = [];
let CHILDREN_MAP = {};

class LibraryGroupItems extends Component {
  //ADD CLASS IF SELECTED
  selectedGroupTextClass = (selectedGroupId, value, level, classes) => {
    let textClass = {
      root: classes.libraryGroupItemText,
    };

    if (selectedGroupId === value) {
      textClass.dense = classes.selectedItemText;
    }

    if (level > 1) {
      textClass.primary = classes.subGroupText;
    }

    return textClass;
  };

  icon = (level, id, locked) => {
    const { appUser, displayParentSubs } = this.props;

    if (appUser && locked) {
      return <Lock />;
    } else if (level === 0) {
      return <FeaturedPlayListIcon />;
    } else if (
      level === 1 &&
      HAS_CHILDREN.includes(id) &&
      !displayParentSubs.includes(id)
    ) {
      return <FolderMultiple />;
    } else if (HAS_CHILDREN.includes(id) && !displayParentSubs.includes(id)) {
      return <Folder />;
    } else if (level === 1) {
      return <FolderOpen style={{ fontSize: 18 }} />;
    } else {
      return <FolderOpen />;
    }
  };

  willEnter() {
    return {
      height: 0,
      opacity: 1,
    };
  }

  willLeave() {
    return {
      height: spring(0),
      opacity: spring(0),
    };
  }

  eachGroup = (
    value,
    personal,
    appUserPersonalContent,
    appUserInteractiveVideos
  ) => {
    const {
      all_content_group,
      appUser,
      owner,
      currentRole,
      selectedGroupId,
      displayParentSubs,
      handleLibraryGroupMenu,
      handleGroupClick,
      classes,
    } = this.props;

    const { _id, name, editable_by, owners, level, hidden, locked } = value
        ? value
        : {},
      levelIndent = level ? level * 10 - 10 : -5;

    let itemClasses = classes.libraryGroupItem;

    if (selectedGroupId === _id) {
      itemClasses += ` ${classes.selectedItem}`;
    }

    let showItem = false;
    let containerClass = '';

    if (level > 1 || displayParentSubs.includes(_id)) {
      containerClass = classes.openLibraryGroupBackground;
    }

    if (!value.parent || displayParentSubs.includes(value.parent)) {
      showItem = true;
    } else {
      containerClass = ` ${classes.noItemBorder}`;
    }

    const showPersonal = !personal
      ? true
      : (appUserPersonalContent && name !== 'My Videos') ||
        (appUserInteractiveVideos && name === 'My Videos')
      ? true
      : false;

    if ((!appUser || !hidden) && showPersonal) {
      return (
        <TransitionMotion
          key={_id}
          defaultStyle={[{ height: 0, opacity: 1 }]}
          styles={[
            {
              key: _id,
              style: {
                height: spring(showItem ? 37 : 0, presets.noWobble),
                opacity: spring(showItem ? 1 : 0, presets.noWobble),
              },
            },
          ]}
          willLeave={this.willLeave}
          willEnter={this.willEnter}
        >
          {(style) => {
            const key = style[0].key;
            const itemStyle = style[0].style;

            return (
              <ListItem
                id={key}
                key={key}
                level={level}
                parent={value.parent}
                order={value.order}
                button
                onClick={(event) =>
                  !appUser || !locked ? handleGroupClick(event, personal) : null
                }
                style={{ display: showItem ? 'flex' : 'none' }}
                className={itemClasses}
                ContainerProps={{
                  className: containerClass,
                  style: itemStyle,
                }}
                disableRipple={true}
                focusRipple={true}
              >
                <ListItemIcon
                  className={`${classes.libraryGroupIcon} ${
                    level > 1 ? classes.librarySubGroupIcon : null
                  } ${
                    selectedGroupId === _id ? classes.selectedItemText : ''
                  } ${appUser && locked ? classes.lockedContentGroup : null}`}
                  style={{ marginLeft: levelIndent }}
                >
                  {this.icon(level, _id, locked)}
                </ListItemIcon>
                <ListItemText
                  primary={
                    <div
                      className={`${classes.truncateText} ${
                        selectedGroupId === _id ? classes.selectedItemText : ''
                      }`}
                    >
                      {name}
                    </div>
                  }
                  classes={this.selectedGroupTextClass(
                    selectedGroupId,
                    _id,
                    level,
                    classes
                  )}
                  style={{ width: `calc(100% - ${level * 10 + 8}px)` }}
                />
                <ListItemSecondaryAction>
                  {(!appUser || personal) &&
                    all_content_group !== _id &&
                    (currentRole === '0' ||
                      editable_by === '0' ||
                      owners.includes(owner)) && (
                      <LibraryGroupMenuButton
                        value={value}
                        selectedGroupId={selectedGroupId}
                        handleLibraryGroupMenu={handleLibraryGroupMenu}
                      />
                    )}
                </ListItemSecondaryAction>
              </ListItem>
            );
          }}
        </TransitionMotion>
      );
    } else {
      return null;
    }
  };

  checkForChildren = (value) => {
    const { libraryGroups } = this.props;
    const id = value._id;

    const children = _.find(libraryGroups, { parent: id });
    if (children && children.groups) {
      HAS_CHILDREN.push(id);

      let childrenIds = [];

      children.groups.map((value) => {
        childrenIds.push(value._id);
        GROUPS.push(this.eachGroup(value));
        this.checkForChildren(value);
        return null;
      });

      CHILDREN_MAP[id] = childrenIds;
    } else {
      return;
    }
  };

  render() {
    const {
      libraryGroups,
      childrenMap,
      personal,
      appUserPersonalContent,
      appUserInteractiveVideos,
    } = this.props;
    const firstLevel = _.find(libraryGroups, { parent: null });
    GROUPS = [];
    HAS_CHILDREN = [];
    CHILDREN_MAP = {};

    if (libraryGroups && libraryGroups.length > 0) {
      libraryGroups.map((value) => {
        if (value.parent && !HAS_CHILDREN.includes(value.parent)) {
          HAS_CHILDREN.push(value.parent);
        }
        return null;
      });

      if (firstLevel && firstLevel.groups.length > 0) {
        firstLevel.groups.map((value) => {
          GROUPS.push(
            this.eachGroup(
              value,
              personal,
              appUserPersonalContent,
              appUserInteractiveVideos
            )
          );
          this.checkForChildren(value);
          return null;
        });

        childrenMap(CHILDREN_MAP);

        return GROUPS;
      } else {
        return <div />;
      }
    } else {
      return <div />;
    }
  }
}

export default withStyles(styles)(LibraryGroupItems);
