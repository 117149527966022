import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";

//non-logged in reducers
import MediaViewerReducer from "./reducer_media_viewer";

import { LOGIN } from "../actions/actions_login";
import { LOGOUT } from "../actions/actions_login";
import LoginReducer from "./reducer_login";
import PageLoadingReducer from "./reducer_page_loading";
import TurnOnAdminAppUserReducer from "./reducer_turn_on_admin_app_user";

import ForgotReducer from "./reducer_forgot";

import AppVersionReducer from "./reducer_app_version";

import AlertsReducer from "./reducer_alerts";

import CompaniesReducer from "./reducer_companies";
import CompanyReducer from "./reducer_company";

import AdminsReducer from "./reducer_admins";

import AnalyticsReducer from "./reducer_analytics";

import BulkUsersReducer from "./reducer_bulk_users";
import UsersReducer from "./reducer_users";
import UserReducer from "./reducer_user";
import OnboardingUsersReducer from "./reducer_onboarding_users";

import ContactsRawReducer from "./reducer_contacts_raw";
import ContactsReducer from "./reducer_contacts";
import ContactReducer from "./reducer_contact";
import ShareContactReducer from "./reducer_share_contact";

import TeamsReducer from "./reducer_teams";
import TeamReducer from "./reducer_team";

import LibraryReducer from "./reducer_library";
import AppUserPersonal from "./reducer_app_user_personal";
import FetchLibraryItemsReducer from "./reducer_fetch_library_items";
import LibraryItemsReducer from "./reducer_library_items";
import LibraryItemReducer from "./reducer_library_item";
import ShareLibraryItemsReducer from "./reducer_share_library_items";

import LibraryGroupsMainReducer from "./reducer_library_groups_main";
import LibraryGroupsRawReducer from "./reducer_library_groups_raw";
import LibraryGroupsReducer from "./reducer_library_groups";
import LibraryGroupReducer from "./reducer_library_group";

import BrandingsReducer from "./reducer_brandings";
import BrandingReducer from "./reducer_branding";

import AppProfilesReducer from "./reducer_app_profiles";
import AppProfilesPermissionReducer from "./reducer_app_profiles_permission";
import AppProfileReducer from "./reducer_app_profile";

import MessagesReducer from "./reducer_messages";
import MessageReducer from "./reducer_message";

import TagsReducer from "./reducer_tags";

import ContentGroupsReducer from "./reducer_content_groups";

import RoomDemoReducer from "./reducer_room_demo";
import RoomDemosReducer from "./reducer_room_demos";

import ShowcaseTemplatesReducer from "./reducer_showcase_templates";
import ShowcaseReducer from "./reducer_showcase";
import ShowcasesReducer from "./reducer_showcases";

import BillingsReducer from "./reducer_billings";

import TradeBoothsReducer from "./reducer_trade_booths";
import TradeBoothReducer from "./reducer_trade_booth"

import LearnReducer from "./reducer_learn"

const appReducer =
  combineReducers({
    appVersion: AppVersionReducer,
    alerts: AlertsReducer,
    analytics: AnalyticsReducer,
    mediaViewer: MediaViewerReducer,
    login: LoginReducer,
    pageLoading: PageLoadingReducer,
    adminAppUser: TurnOnAdminAppUserReducer,
    forgot: ForgotReducer,
    companies: CompaniesReducer,
    company: CompanyReducer,
    admins: AdminsReducer,
    bulkUsers: BulkUsersReducer,
    users: UsersReducer,
    user: UserReducer,
    oboardingUsers: OnboardingUsersReducer,
    contacts_raw: ContactsRawReducer,
    contacts: ContactsReducer,
    contact: ContactReducer,
    shareContact: ShareContactReducer,
    teams: TeamsReducer,
    team: TeamReducer,
    library: LibraryReducer,
    appUserPersonal: AppUserPersonal,
    libraryItem: LibraryItemReducer,
    itemsToShare: ShareLibraryItemsReducer,
    libraryGroupsMain: LibraryGroupsMainReducer,
    libraryGroupsRaw: LibraryGroupsRawReducer,
    libraryGroups: LibraryGroupsReducer,
    libraryGroup: LibraryGroupReducer,
    contentGroups: ContentGroupsReducer,
    brandings: BrandingsReducer,
    branding: BrandingReducer,
    appProfiles: AppProfilesReducer,
    appProfilesPermission: AppProfilesPermissionReducer,
    appProfile: AppProfileReducer,
    messages: MessagesReducer,
    message: MessageReducer,
    allTags: TagsReducer,
    form: formReducer,
    fetchLibraryItems: FetchLibraryItemsReducer,
    uploadedLibraryItems: LibraryItemsReducer,
    roomDemo: RoomDemoReducer,
    roomDemos: RoomDemosReducer,
    showcaseTemplates: ShowcaseTemplatesReducer,
    showcase: ShowcaseReducer,
    showcases: ShowcasesReducer,
    billings: BillingsReducer,
    tradeBooths: TradeBoothsReducer,
    tradeBooth: TradeBoothReducer,
    learn: LearnReducer
  })

const rootReducer = (state, action) => {
  
    if (action.type === LOGIN && action.payload) {
        state.forgot = null
    } else if (action.type === LOGOUT) {
        window.get_ambassador_raf = false

        state = Object.keys(state).reduce(
        (accumulator, current) => {

        if (accumulator[current]) {
            if (accumulator[current].isArray()) {
               accumulator[current] = []; 
            } else {
                accumulator[current] = {};
            }
        }

        return accumulator

    }, {});
  }

  return appReducer(state, action);
}

export default rootReducer;
