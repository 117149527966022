import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from "redux-form";

import { withStyles } from '@material-ui/core/styles';
import { styles } from '../styles';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Modal from '@material-ui/core/Modal';

import Close from '@material-ui/icons/Close';

import LoadingDivContainer from '../subcomponents/loadingDiv';
import { cleanPutValues, validateName, cleanName } from '../helpers/helperFunctions';
import { DeletePrivileges } from '../subcomponents/commonComponents';

import { RenderTextField } from '../subcomponents/form_subcomponents/renderTextField';
import { RenderSwitch } from '../subcomponents/form_subcomponents/renderSwitch';
import { RenderOwnerEditableByMenu } from '../subcomponents/form_subcomponents/renderOwnerEditableByMenu';

import { fetchTeam, createTeam, updateTeam, deleteTeam, fetchAppProfile, createAppProfile, updateAppProfile, deleteAppProfile, fetchAdmins, fetchBrandings } from '../../actions/actions_index';

class TeamForm extends Component {
  constructor(props) {
    super(props);

    this.props.reset();
  }

  teamForm = () => {
    const { enterprise, owner, currentRole, currentUser, admins, selectedTeamId, deleteTeamModal, pristine, invalid, initialValues, classes } = this.props;

    let autoFocus = false;
    if (!selectedTeamId) {
      autoFocus = true;
    }

    if (deleteTeamModal) {
      return (
        <div>
          <Button className={`${classes.button} ${classes.modalButton}`} variant="contained" type="submit" color="secondary">Delete</Button>
        </div>
      )
    } else {
      return (
        <div>  
          <Field name="name" component={RenderTextField} type="text" label="Name" autoFocus={autoFocus} />
          {!enterprise &&
            <React.Fragment>
              <Field type="checkbox" name="light_version" label="Use Light Version for Spaces" component={RenderSwitch} />
              <Field type="checkbox" name="verb_live" label="Allow App Users to Use Verb LIVE" component={RenderSwitch} />
            </React.Fragment>
          }
          {(currentRole === '0' || initialValues.owners.includes(owner)) &&
            <RenderOwnerEditableByMenu
              currentUser={currentUser}
              admins={admins}
              selectedId={selectedTeamId}
              initialValues={initialValues}
            />   
          }
          <Button className={`${classes.button} ${classes.modalButton}`} variant="contained" type="submit" color="secondary" disabled={pristine || invalid}>Save</Button>
        </div>
      )
    } 
    
  }

  submitTeamModal = (values) => {
    const { token, companyId, enterprise, reCheckAlerts } = this.props;
    const { selectedTeamId, deleteTeamModal, submitTeamModal, closedTeamModal } = this.props;

    submitTeamModal();

    delete values.users;

    values.name = cleanName(values.name)

    if (enterprise) {
      if (selectedTeamId && !deleteTeamModal) {
        values = cleanPutValues(values);

        this.props.updateTeam(token, values, selectedTeamId, result => {
          closedTeamModal(true, false, result.data);
        })
      } else if (selectedTeamId && deleteTeamModal) {
        this.props.deleteTeam(token, selectedTeamId, () => {
          closedTeamModal(true);
        })
      } else {
        values.company = companyId;

        this.props.createTeam(token, values, result => {
          closedTeamModal(true, true, result.data);
        })
      }
    } else {
      this.props.fetchBrandings(token, companyId, brandings => {
        values.branding = brandings.data[0]._id;
        values.force_downloads = true;

        if (!values.verb_live) {
          values.verb_live = false
        }

        if (selectedTeamId && !deleteTeamModal) {
          values = cleanPutValues(values);

          this.props.updateAppProfile(token, values, selectedTeamId, result => {
            reCheckAlerts(() => {
              closedTeamModal(true, false, result.data);
            })
          })
        } else if (selectedTeamId && deleteTeamModal) {
          this.props.deleteAppProfile(token, selectedTeamId, () => {
            reCheckAlerts(() => {
              closedTeamModal(true);
            })
          })
        } else {
          values.company = companyId;
          values.force_downloads = true;

          this.props.createAppProfile(token, values, result => {
            reCheckAlerts(() => {
              closedTeamModal(true, true, result.data);
            })
          })
        }
      })
    }
  }

  render() {
    const { handleSubmit } =  this.props;

    return (
      <form noValidate autoComplete="off" onSubmit={handleSubmit(values => this.submitTeamModal(values))}>
        {this.teamForm()}
      </form>
    )
  }

}

function validate(values, props) {
  const errors = {};
  const requiredFields = [
    'name'
  ];

  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = 'Required';
    }
  });

  if (values.owners && values.owners.length === 0) {
    errors['owners'] = 'You must select at least one owner.'
  }

  const checkString = validateName(values.name)

  if (checkString && !checkString.pass) {
    errors['name'] = checkString.error
  }

  return errors;
}

function mapStateToProps(state) {
  const enterprise = state.login.company.enterprise;
  let team = state.team;

  if (!enterprise) {
    team = state.appProfile
  }

  return { 
    token: state.login.token,
    companyId: state.login.company._id,
    enterprise: enterprise,
    owner: state.login.user._id,
    currentRole: state.login.user.user_type,
    currentUser: state.login.user,
    admins: state.admins,
    brandings: state.brandings,
    team: team
  };
}

const mapDispatchToProps = dispatch => ({
  fetchAdmins: (token, companyId, callback) => dispatch(fetchAdmins(token, companyId, callback)),
  fetchBrandings: (token, companyId, callback) => dispatch(fetchBrandings(token, companyId, callback)),
  fetchTeam: (token, id, callback) => dispatch(fetchTeam(token, id, callback)),
  createTeam: (token, values, callback) => dispatch(createTeam(token, values, callback)),
  updateTeam: (token, values, id, callback) => dispatch(updateTeam(token, values, id, callback)),
  deleteTeam: (token, id, callback) => dispatch(deleteTeam(token, id, callback)),
  fetchAppProfile: (token, id, callback) => dispatch(fetchAppProfile(token, id, callback)),
  createAppProfile: (token, values, callback) => dispatch(createAppProfile(token, values, callback)),
  updateAppProfile: (token, values, id, callback) => dispatch(updateAppProfile(token, values, id, callback)),
  deleteAppProfile: (token, id, callback) => dispatch(deleteAppProfile(token, id, callback))
});

const TeamFormContainer = reduxForm({
  form: 'Team',
  validate,
  enableReinitialize: true
})(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TeamForm)));

class TeamModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingTeam: true,
      loadingAdmins: true
    }
  }

  componentDidMount() {
    const { token, companyId, enterprise, selectedTeamId, deleteTeamModal } = this.props;

    if (selectedTeamId && !deleteTeamModal) {
      if (enterprise) {
        this.props.fetchTeam(token, selectedTeamId, team => {
          this.setState({
            loadingTeam: false
          })
        })
      } else {
        this.props.fetchAppProfile(token, selectedTeamId, team => {
          this.setState({
            loadingTeam: false
          })
        })
      }

      this.props.fetchAdmins(token, companyId, admins => {
        this.setState({
          loadingAdmins: false
        })
      })
    } else if (deleteTeamModal) {
      if (enterprise) {
        this.props.fetchTeam(token, selectedTeamId, team => {
          this.setState({
            loadingTeam: false,
            loadingAdmins: false
          })
        })
      } else {
        this.props.fetchAppProfile(token, selectedTeamId, team => {
          this.setState({
            loadingTeam: false,
            loadingAdmins: false
          })
        })
      }
    } else {
      this.props.fetchAdmins(token, companyId, admins => {
        this.setState({
          loadingTeam: false,
          loadingAdmins: false
        })
      })
    }
  }

  submitTeamModal = () => {
    this.setState({
      loadingTeam: true
    })
  }

  modalSize = () => {
    const { deleteTeamModal, classes } = this.props;
    
    if (deleteTeamModal) {
      return (
        classes.deleteModal
      )
    } else {
      return (
        classes.smallModal
      )
    }
  }

  handleModalBody = () => {
    const { owner, currentRole, team, deleteTeamModal, selectedTeamId, classes, closedTeamModal, reCheckAlerts } = this.props;
    const { loadingTeam, loadingAdmins } = this.state;

    let title = '',
        subheading = '',
        subheadingClass = null;

    if (selectedTeamId && !deleteTeamModal) {
      title = <span>Edit - <span className={classes.modalTitleItem}>{team.name}</span></span>;
      subheading = "Edit team details.";
      subheadingClass = `${classes.modalSubheading} ${classes.smallHeightSubheading}`;
    } else if (selectedTeamId && deleteTeamModal) {
      title = <span>Delete - <span className={classes.modalTitleItem}>{team.name}</span></span>;
      subheading = <span>Are you sure you want to delete <i>{team.name}</i>?</span>;
      subheadingClass = classes.modalSubheadingDelete;
    } else {
      title = "Add New Team";
      subheading = "Add a team to organize your users.";
      subheadingClass = classes.modalSubheading;
    }

    if (loadingTeam || loadingAdmins) {
      if(deleteTeamModal) {
        return (
          <LoadingDivContainer type={'small'} />
        )
      } else {
        return (
          <LoadingDivContainer />
        )
      }
    } else if (deleteTeamModal && (currentRole !== '0' && !team.owners.includes(owner))) {
      return (
        <DeletePrivileges 
          className={classes.modalSubheadingDelete}
        />
      )
    } else {
      let initialValues = {
        editable_by: '1',
        owners: [owner]
      };

      if (selectedTeamId) {
        initialValues = team;
      }

      return (
        <div className={classes.divContainer}>
          <Typography variant="h6">
            {title}
          </Typography>
          <Typography variant="subtitle1" className={subheadingClass}>
            {subheading}
          </Typography>
          <TeamFormContainer
            deleteTeamModal={deleteTeamModal}
            selectedTeamId={selectedTeamId}
            initialValues={initialValues}
            submitTeamModal={this.submitTeamModal}
            closedTeamModal={closedTeamModal}
            reCheckAlerts={reCheckAlerts}
          />
        </div>
      )
    }
  }

  render() {
    const { openTeamModal, closedTeamModal, classes } = this.props;

    return (
      <Modal
        aria-labelledby="add-edit-team"
        aria-describedby="add-edit-team"
        open={openTeamModal}
        onClose={() => closedTeamModal(false)}
      >
        <div className={`${classes.modal} ${this.modalSize()}`}>
          <IconButton 
            className={classes.iconClose}
            onClick={() => closedTeamModal(false)}
          >
            <Close />
          </IconButton>
          {this.handleModalBody()}
        </div>
      </Modal>
    )
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TeamModal));
