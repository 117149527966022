import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import Div100vh from 'react-div-100vh';

import BeforeRouting from './beforeRouting';

import Alerts from '../components/alerts';
import Header from '../components/header';
import Footer from '../components/footer';
import Login from "../components/login";
import LogoutPage from "../components/logout";
import CreateAccount from "../components/create_account";
import Forgot from "../components/forgot";
import Reset from "../components/reset";
import Download from "../components/download";

import SSOVerification from "../components/sso_verification";

import MasterAdmin from "../components/master_admin";

import Onboarding from "../components/onboarding/onboarding";

import Billing from "../components/billing/billing";

import Analytics from "../components/analytics/analytics";

import Library from "../components/content/library";
import Brandings from "../components/content/brandings";
import Brand from "../components/content/brand";
import AppProfiles from "../components/content/app_profiles";
import TradeBooths from "../components/content/trade_booths";

import AllUsers from "../components/users/all_users";
import Messages from "../components/users/messages";

import ShareCart from "../components/share/share_cart";
import Campaigns from "../components/share/campaigns";
import Contacts from "../components/share/contacts";

import ContactFeed from "../components/contacts/contacts";

import SolofireShowcase from "../components/content/solofireShowcase";
import ShowcasesAnalytics from "../components/analytics/showcasesAnalytics";

import Showcase from "../components/content/showcase";
import Room from "../components/content/room";
import Viewer from "../components/content/viewer";
import RoomDemo from "../components/content/roomDemo";
import RoomDemosAnalytics from "../components/analytics/roomDemosAnalytics";
import CreateRoomDemo from "../components/content/create_room_demo";

import InteractiveVideo from "../components/content/interactiveVideo";

import PdfViewer from "../components/pdfviewer/pdfViewer"
import PdfViewer2 from "../components/pdfviewer/pdfViewer2"



import { checkIfLoggedIn, checkIfMasterAdmin, checkIfOnboarding, handleAppLiveUser } from '../components/helpers/helperFunctions';

import { logout } from "../actions/actions_index";

// const ProtectedRoute = ({ component: Component, ...rest }) => (
//   <Route {...rest} render={props => {
//     	console.log(props);

//     	return (
//       // auth.isAuthenticated ? (
//         <Component {...props}/>
//       // ) : (
//       //   <Redirect to="/login"/>
//       // )
//    	)}
//   }/>
// )

class Routes extends Component {
	render() {
		const { token, company, user, appLiveUser, appProfile, loggedOut } = this.props;
		const login = { token, company, user }

		return (
			<Div100vh>
			<BrowserRouter>
				<BeforeRouting>
			  	<CssBaseline />
			  	<Alerts />
			  	<Header />
			  	<Switch>
			  		<Route path="/logout" render={() => <LogoutPage />} />
			  		<Route path="/master_admin" render={() => <MasterAdmin />} />
			  		
			  		<Route 
			  			path="/onboarding/:step"
			  			render={() => <Onboarding />}
			  		/>

			  		<Route path="/billing" render={() => <Billing />} />

					  <Route path="/pdfviewer" render={() => <PdfViewer />} />
              <Route path="/pdfviewer2" render={() => <PdfViewer2 />} />

			  		<Route 
			  			path="/activity/dashboard"
			  			render={(props) => <Redirect to="/analytics/dashboard"/>}
			  		/>
			  		<Route 
			  			path="/activity/users"
			  			render={(props) => <Redirect to="/analytics/users"/>}
			  		/>
			  		<Route 
			  			path="/activity/contacts"
			  			render={(props) => <Redirect to="/analytics/contacts"/>}
			  		/>
			  		<Route 
			  			path="/activity/content"
			  			render={(props) => <Redirect to="/analytics/content"/>}
			  		/>
			  		<Route 
			  			path="/activity/showcases"
			  			render={(props) => <Redirect to="/analytics/showcases"/>}
			  		/>
			  		<Route 
			  			path="/activity/sessions"
			  			render={(props) => <Redirect to="/analytics/sessions"/>}
			  		/>
			  		<Route 
			  			path="/activity/screens"
			  			render={(props) => <Redirect to="/analytics/screens"/>}
			  		/>

			  		<Route 
			  			path="/analytics/dashboard"
			  			render={(props) => <Analytics {...props} page="dashboard"/>}
			  		/>
			  		<Route 
			  			path="/analytics/users"
			  			render={(props) => <Analytics {...props} page="users"/>}
			  		/>
			  		<Route 
			  			path="/analytics/contacts"
			  			render={(props) => <Analytics {...props} page="contacts"/>}
			  		/>
			  		<Route 
			  			path="/analytics/content"
			  			render={(props) => <Analytics {...props} page="content"/>}
			  		/>
			  		<Route 
			  			path="/analytics/showcases"
			  			render={(props) => <Analytics {...props} page="showcases"/>}
			  		/>
			  		<Route 
			  			path="/analytics/sessions"
			  			render={(props) => <Analytics {...props} page="sessions"/>}
			  		/>
			  		<Route 
			  			path="/analytics/screens"
			  			render={(props) => <Analytics {...props} page="screens"/>}
			  		/>

			  		<Route path="/content/library/:quick?" render={() => <Library />} />
			  		<Route path="/content/brands/:quick?" render={() => <Brandings />} />
			  		<Route path="/brand" render={() => <Brand />} />
			  		<Route path="/content/showcases/:quick?" render={() => <AppProfiles />} />
			  		<Route path="/content/app_profiles/:quick?" render={() => <AppProfiles />} />
			  		<Route path="/content/trade_booths/" render={() => <TradeBooths />} />
			  		<Route path="/content/interactive_videos/" render={() => <InteractiveVideo />} />
			  		<Route path="/interactive_video_editor" render={() => <InteractiveVideo />} />

			  		<Route path="/users/messages/:quick?" render={() => <Messages />} />
			  		<Route path="/messages/:quick?" render={() => <Messages />} />
			  		<Route path="/users/:quick?" render={() => <AllUsers />} />

			  		<Route path="/share_cart/:quick?" render={() => <ShareCart />} />
			  		<Route path="/share/campaigns/:quick?" render={() => <Campaigns />} />
			  		<Route path="/share/leads/:quick?" render={() => <Contacts />} />
			  		<Route path="/share/contacts/:quick?" render={() => <Contacts />} />

			  		<Route path="/contacts" render={() => <ContactFeed />} />
						
						<Route path="/sf_showcase/:_id/:email?/:first_name?/:last_name?" render={() => <SolofireShowcase />} />
						<Route path="/showcase_analytics" render={() => <ShowcasesAnalytics />} />
						
						<Route path="/create_room_demo" render={() => <CreateRoomDemo />}/>
						
						<Route path="/room/:link_udid" render={() => <Room />} />
						<Route path="/space/:link_udid" render={() => <Room />} />
						<Route path="/space_preview/:link_udid" render={() => <Room />} />
						<Route path="/viewer/:_id" render={() => <Viewer />} />
						
						<Route path="/booth_showcase/:_id" render={() => <Showcase />} />
						<Route path="/showcase/:_id" render={() => <Showcase />} />
						<Route path="/showcase_instance_preview/:_id" render={() => <Showcase />} />
						<Route path="/showcase_preview/:_id" render={() => <Showcase />} />
						
						<Route path="/room_demo_preview/:link_udid" render={() => <RoomDemo />} />
						<Route path="/room_demo/:link_udid" render={() => <RoomDemo />} />
						<Route path="/room_demos_analytics" render={() => <RoomDemosAnalytics />} />

						<Route 
							path="/sso_verification" render={() => {
								if (!checkIfLoggedIn(login, loggedOut) || checkIfMasterAdmin(user)) {
									return <SSOVerification />
								} else if (checkIfOnboarding(company)) {
									return <Redirect to="/onboarding/company"/>
								} else {
									return <Redirect to="/content/library"/>
								}
							}
						}/>
						<Route 
							path="/login/:verification_token?" render={() => {
								if ((!checkIfLoggedIn(login, loggedOut) || checkIfMasterAdmin(user)) || (appLiveUser && !appProfile._id)) {
									return <Login />
								} else if (checkIfOnboarding(company)) {
									return <Redirect to="/onboarding/company"/>
								} else {
									return <Redirect to="/content/library"/>
								}
							}
						}/>
						<Route 
							path="/create_your_solofire_account" render={() => {			
								if (!checkIfLoggedIn(login, loggedOut)) {
									return <Login />
								} else if (checkIfOnboarding(company)) {
									return <Redirect to="/onboarding/company"/>
								} else {
									return <Redirect to="/content/library"/>
								}
							}
						}/>
						<Route 
							path="/create_account" render={() => {			
								if (!checkIfLoggedIn(login, loggedOut)) {
									return <CreateAccount />
								} else if (checkIfOnboarding(company)) {
									return <Redirect to="/onboarding/company"/>
								} else {
									return <Redirect to="/content/library"/>
								}
							}
						}/>
						<Route path="/download" render={() => <Download />}/>
						<Route path="/downloads" render={() => <Download />}/>
						<Route 
							path="/forgot_password" render={() => {			
								if (!checkIfLoggedIn(login, loggedOut)) {
									return <Forgot />
								} else if (checkIfOnboarding(company)) {
									return <Redirect to="/onboarding/company"/>
								} else {
									return <Redirect to="/content/library"/>
								}
							}
						}/>
						<Route 
							path="/reset_password/:token" render={() => {			
								if (!checkIfLoggedIn(login, loggedOut)) {
									return <Reset />
								} else if (checkIfOnboarding(company)) {
									return <Redirect to="/onboarding/company"/>
								} else {
									return <Redirect to="/content/library"/>
								}
							}
						}/>

						<Route exact path="/" render={() => {			
								if (!checkIfLoggedIn(login, loggedOut)) {
									return <Redirect to="/login"/>
								} else if (checkIfOnboarding(company)) {
									return <Redirect to="/onboarding/company"/>
								} else {
									return <Redirect to="/content/library"/>
								}
							}
						}/>
			  	</Switch>
			  	<Footer />
				</BeforeRouting>
			</BrowserRouter>
			</Div100vh>
		);
	}
}

function mapStateToProps(state) {
  if (_.isEmpty(state.login.token || state.login.company || state.login.user)) {
    return {
    	loggedOut: true
    }
  }

  return { 
    token: state.login.token,
    company: state.login.company,
    user: state.login.user,
    appLiveUser: handleAppLiveUser(state),
    appProfile: state.appProfile
  };
}

export default connect(mapStateToProps, null)(Routes);