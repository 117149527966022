const environment = process.env.REACT_APP_ENV;

let url = '';

switch (environment) {
  case 'local':
    url = process.env.REACT_APP_API_LOCAL_URL;
    break;
  case 'staging':
    url = process.env.REACT_APP_API_DEV_URL;
    break;
  case 'production':
    url = process.env.REACT_APP_API_PROD_URL;
    break;
  default:
    break;
}

export const ROOT_URL = url;
