import React from 'react';

import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

export const RenderCheckboxes = (
  {
    classes,
    className,
    labelClassName,
    oneBox,
    props,
    options,
    uploadedGroups,
    input,
    input: { onChange, value, checked, name }
  }
) => {

  return (
    <FormControl margin="none" className={className}>
      <FormGroup>
        {options.map((option, i) => {
            let disabled = false,
                checked = false,
                onboardingCheckedDone = null;

            if (uploadedGroups && uploadedGroups.includes(option._id)) {
              disabled = true;
              checked = true;
              onboardingCheckedDone = classes.onboardingCheckedDone;
            }

            if (oneBox && value === option._id) {
              checked = true;
            } else if (!oneBox && value.indexOf(option._id) !== -1) {
              checked = true;
            }

            return (
              <FormControlLabel
                key={i}
                classes={{
                  label: labelClassName
                }}
                label={option.name}
                control={
                  <Checkbox
                    name={`${name}[${i}]`}
                    value={option._id}
                    checked={checked}
                    disabled={disabled}
                    classes={{
                      checked: onboardingCheckedDone
                    }}
                    onChange={(event) => {
                      
                      let inputValue = value;
                      
                      if (oneBox) {
                        
                        if (event.target.checked) {
                          inputValue = option._id;
                        }

                        return onChange(inputValue);

                      } else {
                        
                        let arr = [...inputValue];
                        
                        if (event.target.checked) {
                          arr.push(option._id);
                        } else {
                          arr.splice(arr.indexOf(option._id), 1);
                        }

                        return onChange(arr);

                      }

                    }}
                    
                  >
                    {option}
                  </Checkbox>
                }
              />
            )
          }
        )}
      </FormGroup>
    </FormControl>
  );
}
