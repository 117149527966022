import React, { Component } from "react";
import { withRouter, Link, Redirect } from "react-router-dom";
import { compose } from 'redux';

import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { white } from '../theme';

class Download extends Component {
	
	render() {
		const { classes } = this.props

		return (
			<div className={`${classes.root} ${classes.createAccountRoot}`} style={{backgroundImage: 'linear-gradient(to right, #005FD0, #02C4E2)', minHeight: '100vh', height: 'initial'}}>
				<Grid container spacing={8} justify="center" alignItems="center" style={{paddingTop: 125, minHeight: 'calc(100vh - 100px)'}}>
				  <Grid item lg={6} sm={12} className={classes.downloadDesktopContainer}>
				  	<Typography variant='body1' style={{color: white, paddingBottom: 35}}>Desktop Apps</Typography>
				  	<img style={{height: 220, maxWidth: 'calc(100vw - 50px)'}} src="/images/download/desktop_devices.svg" alt={''} />
				  	<Grid container spacing={0} style={{paddingTop: 30}}>
				  		<Grid item xs={6}>
				  			<a href='https://verbteams.s3.amazonaws.com/verbTEAMS.pkg' target='_blank'><img style={{padding: 15, width: '100%'}} src="/images/download/download_mac.svg" alt={''} /></a>
				  		</Grid>
				  		<Grid item xs={6}>
				  			<a href='https://verbteams.s3.amazonaws.com/verbTEAMS-install.exe' target='_blank'><img style={{padding: 15, width: '100%'}} src="/images/download/download_windows.svg" alt={''} /></a>
				  		</Grid>
				  	</Grid>
				  </Grid>
				  <Grid item lg={6} sm={12} style={{textAlign: 'center', maxWidth: 500}}>
				  	<Typography variant='body1' style={{color: white, paddingBottom: 35}}>Mobile Apps</Typography>
				  	<img style={{height: 220}} src="/images/download/mobile_devices.svg" alt={''} />
				  	<Grid container spacing={0} style={{paddingTop: 30}}>
				  		<Grid item xs={6}>
				  			<a href='https://itunes.apple.com/us/app/solofire/id1464594615' target='_blank'><img style={{padding: 15, width: '100%'}} src="/images/download/download_ios.svg" alt={''} /></a>
				  		</Grid>
				  		<Grid item xs={6}>
				  			<a href='https://play.google.com/store/apps/details?id=air.com.solofire.solofire2&hl=en_US' target='_blank'><img style={{padding: 15, width: '100%'}} src="/images/download/download_android.svg" alt={''} /></a>
				  		</Grid>
				  	</Grid>
				  </Grid>
				</Grid>
				<div className={classes.downloadFooter}>
					<Typography variant='body2' style={{textAlign: 'center', color: white, fontSize: 15, padding: 15}}>
						*Note: These apps are specifically for app users. For now, the Admin Portal is best used on a desktop or tablet device.
					</Typography>
				</div>
			</div>
		)
	}
}

export default compose(
  withRouter,
  withStyles(styles)
)(Download)