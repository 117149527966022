import { FETCH_LIBRARY_GROUPS_RAW } from "../actions/actions_library_groups";

export default function(state = [], action) {
  switch (action.type) {
    case FETCH_LIBRARY_GROUPS_RAW:
      if (!action.payload) {
        return state;
      }
      return action.payload;
      // return _.mapKeys(action.payload.data, "_id");
      
    default:
      return state;
  }
}