import { FETCH_MESSAGE, CREATE_MESSAGE, UPDATE_MESSAGE, DELETE_MESSAGE } from "../actions/actions_messages";

export default function(state = {}, action) {
  switch (action.type) {
    
    case FETCH_MESSAGE:
      if (!action.payload) {
        return state;
      }
      return action.payload;

    case CREATE_MESSAGE:
      if (!action.payload) {
        return state;
      }
      return action.payload;
      // return _.mapKeys(action.payload.data, "_id");

    case UPDATE_MESSAGE:
      if (!action.payload) {
        return state;
      }
      return action.payload;

    case DELETE_MESSAGE:
      if (!action.payload ) {
        return state;
      }
      return action.payload;
    
    default:
      return state;
  }
}