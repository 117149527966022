import { FETCH_SHOWCASE, CREATE_SHOWCASE, ADD_LEAD_TO_SHOWCASE, UPDATE_SHOWCASE, SHOWCASE_CLOSED, SHOWCASE_EVENT, DELETE_SHOWCASE } from "../actions/actions_showcases";

export default function(state = {}, action) {
  switch (action.type) {
    
    case FETCH_SHOWCASE:
      if (!action.payload) {
        return state;
      }
      return action.payload;

    case CREATE_SHOWCASE:
      if (!action.payload) {
        return state;
      }
      return action.payload;
      // return _.mapKeys(action.payload.data, "_id");

    case ADD_LEAD_TO_SHOWCASE:
      if (!action.payload) {
        return state;
      }
      return action.payload;

    case UPDATE_SHOWCASE:
      if (!action.payload) {
        return state;
      }
      return action.payload;

    case SHOWCASE_CLOSED:
      if (!action.payload) {
        return state;
      }
      return action.payload;

    case SHOWCASE_EVENT:
      if (!action.payload) {
        return state;
      }
      return action.payload;

    case DELETE_SHOWCASE:
      if (!action.payload) {
        return state;
      }
      return action.payload
    
    default:
      return state;
  }
}