import React from 'react';
import { CardElement } from 'react-stripe-elements';

import Typography from '@material-ui/core/Typography';

const style = {
  base: {
    color: '#32325d',
    fontFamily: '"GillSans", "Helvetica", "Arial", sans-serif',
    fontSmoothing: 'antialiased',
    fontSize: '18px',
    '::placeholder': {
      color: '#aab7c4'
    },
    '&:focus': {
    	boxShadow: '0 1px 3px 0 #cfd7df'
    },
    '&:-webkit-autofill': {
    	backgroundColor: '#fefde5 !important'
    }
  },
  invalid: {
    color: '#fa755a',
    iconColor: '#fa755a',
    borderColor: '#fa755a'
  }
};
 
class CardSection extends React.Component {
  stripeElementChange = (event) => {
    const { cardInfoComplete, cardInfoError, clearAll } = this.props

    if (event.complete) {
      cardInfoComplete()
    } else if (event.error) {
      cardInfoError(event.error.message)
    } else {
      clearAll()
    }
  }

  render() {
  	const { update } = this.props;

    return (
      <label>
      <Typography variant="h6">
      	{`${update ? 'Update': 'Add'} Credit Card for Subscription`}
      </Typography>
        <CardElement 
          style={style} 
          onChange={(event) => this.stripeElementChange(event)}
        />
      </label>
    );
  }
}
 
export default CardSection;