import _ from "lodash";
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

import { withStyles } from '@material-ui/core/styles';
import { styles } from '../styles';

import Typography from '@material-ui/core/Typography';

import FilestackButton from '../subcomponents/filestackButton';
import EditUploadedItemsModalContainer from '../modals/editUploadedItemsModal';
import RoomDemoModalContainer from '../modals/roomDemoModal';

import { createLibraryItems } from '../../actions/actions_index';

const FULL_LIBRARY_ID = 'full-library';

class CreateRoomDemo extends Component {
	constructor(props) {
	  super(props);

	  this.state = {
	    openEditUploadedItemsModal: false,
	    openRoomDemoModal: false,
	    uploadedItems: null,
	    roomDemoLink: null
	  }

	}

	//SECTION: UPLOAD FILES
	saveUploadedFiles = (uploadedfiles, parent, groupId) => {
	  
	  const { token, companyId, owner } = this.props;
	  let saveFiles = [];

	  uploadedfiles.map((value, index) => {
	    let file = {};
	    const name = value.originalPath.replace(/\//g,"");

	    //thumbnail? - future - need to create thumbnail on backend
	    file.company = companyId;
	    file.owners = [owner];
	    file.filename = name;
	    file.name = name;
	    
	    //**CHANGE HARD CODED LINK**
	    file.aws_bucket = value.container;
	    file.aws_key = value.key;
	    file.path = `${process.env.REACT_APP_FILESTACK_S3_URL}${value.key.replace(/ /g,"+")}`;
	    file.filestack_handle = value.handle;
	    file.mimetype = value.mimetype;
	    file.file_type = name.substring(name.lastIndexOf('.') + 1).toLowerCase();
	    file.file_size = value.size;
	    file.room_demo = true;

	    saveFiles.push(file);

	    return null;
	  });

	  this.props.createLibraryItems(token, saveFiles, null, libraryItems => {
	    if (libraryItems.error) {
	      this.setState({
	        error: libraryItems.error
	      })
	    } else {
	    	console.log(libraryItems)
	      //get array of library ids
      	//open create room modal

        this.setState({
          uploadedItems: libraryItems.data
        });
	    }
	  });

	}

	uploadSuccess = (result, parent) => {
	  // filesFailed
	  //do something for failed uploaded files

	  this.saveUploadedFiles(result.filesUploaded, parent, null);

	  this.setState({
	    openEditUploadedItemsModal: true
	  });
	}

	handleEditUploadedItemsModal = () => {
	  const { openEditUploadedItemsModal, uploadedItems } = this.state;

	  if (openEditUploadedItemsModal) {
	    return (
	      <EditUploadedItemsModalContainer
	        openEditUploadedItemsModal={openEditUploadedItemsModal}
	        uploadedItems={uploadedItems}
	        closedLibraryItemModal={this.closedEditUploadedItemsModal}
	        roomDemo={true}
	      />
	    )
	  }
	}
	//END SECTION: UPLOAD FILES

	closedEditUploadedItemsModal = (updated) => {
	  
		this.setState({
	    openEditUploadedItemsModal: false,
	    openRoomDemoModal: true
	  });
	}

	handleCreateRoomDemoModal = () => {
	  const { openRoomDemoModal } = this.state;

	  if (openRoomDemoModal) {
	    return (
	      <RoomDemoModalContainer
	        openRoomDemoModal={openRoomDemoModal}
	        closedRoomDemoModal={this.closedRoomDemoModal}
	      />
	    )
	  }
	}

	closedRoomDemoModal = (data) => {

		console.log(data)
	  
		this.setState({
	    openRoomDemoModal: false,
	    roomDemoLink: data.link_udid
	  });
	}

	render() {
		const { classes } = this.props;
		const { roomDemoLink } = this.state;

		return (
			<div className={classes.root}>
				<Typography variant="h2">
					Add Room Demo
				</Typography>
				<br />
				<br />
				<FilestackButton
				  buttonType={'new'}
				  selectedGroup={null}
				  FULL_LIBRARY_ID={FULL_LIBRARY_ID}
				  uploadSuccess={this.uploadSuccess}
				/>
				<br />
				<br />
				{roomDemoLink && 
					<div>
						<Typography variant="h4">
							PREVIEW Link
						</Typography>
						<Typography variant="h5">
							{`https://app.solofire.com/room_demo_preview/${roomDemoLink}`}
						</Typography>
						<br />
						<Typography variant="h4">
							LIVE Link
						</Typography>
						<Typography variant="h5">
							{`https://app.solofire.com/room_demo/${roomDemoLink}`}
						</Typography>
					</div>
				}
				{this.handleEditUploadedItemsModal()}
				{this.handleCreateRoomDemoModal()}
			</div>
		)
	}

}

function mapStateToProps(state) {
  if (_.isEmpty(state.login.token) || _.isEmpty(state.login.company) || _.isEmpty(state.login.user)) {
    return {
      loggedOut: true
    }
  }

  return { 
    token: state.login.token,
    companyId: state.login.company._id,
    owner: state.login.user._id
  };
}

const mapDispatchToProps = dispatch => ({
  createLibraryItems: (token, files, groupId, callback) => dispatch(createLibraryItems(token, files, groupId, callback))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CreateRoomDemo)));