import _ from "lodash";
import React, { PureComponent } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

import { withStyles } from '@material-ui/core/styles';
import { styles } from '../styles';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';

import AddCircle from '@material-ui/icons/AddCircle';
import Class from '@material-ui/icons/Class';
import Search from '@material-ui/icons/Search';
import MoreVert from '@material-ui/icons/MoreVert';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import SupervisorAccount from '@material-ui/icons/SupervisorAccount';
import LibraryBooks from '@material-ui/icons/LibraryBooks';

import WithLoading from '../subcomponents/withLoading';
import ViewAppProfileModalContainer from '../modals/viewAppProfileModal';
import AppProfileModalContainer from '../modals/appProfileModal';
import AddToAppProfileModalContainer from '../modals/addToAppProfileModal';

import { torquoise, teal, energy, notBlack } from '../../theme';

import { verifyLoggedIn, menuPermissions, siteUrl } from '../helpers/helperFunctions';

import { verifyRole, fetchAllAppProfiles } from '../../actions/actions_index';

import { QUICK_ADD_SHOWCASE, QUICK_ADD_APP_PROFILE } from '../header';

class AppProfiles extends PureComponent {
  state = {
    showcases: false,
    openViewAppProfileModal: false,
    openAppProfileModal: false,
    openAddToAppProfileModal: false,
    deleteAppProfileModal: false,
    menuElement: null,
    selectedAppProfileId: null,
    addTeams: false,
    addShowcases: false,
    newAppProfile: false,
    fromEdit: false,
    fromView: false,
    addedRemoved: null,
    newAppProfile: false,
    newAppProfileTeams: false
  }

  quickAppProfile = (quick) => {
    if (quick === QUICK_ADD_SHOWCASE) {
      this.addAppProfile('showcase');
    } else if (quick === QUICK_ADD_APP_PROFILE) {
      this.addAppProfile('app_profile');
    }
  }

  isShowcases = () => {
    const history = this.props.history;

    if (history.location.pathname.includes('showcase')) {
      this.setState({
        showcases: true
      })
    } else {
      this.setState({
        showcases: false
      })
    }
  } 

  componentDidMount() {
    this.isShowcases();

    const { quick } = this.props.match.params;
    this.quickAppProfile(quick);
  }

  componentWillReceiveProps(newProps) {
    this.isShowcases();

    const { quick } = newProps.match.params;
    this.quickAppProfile(quick);
  }

  addAppProfile = () => {
    this.setState({
      newAppProfile: true,
      openAppProfileModal: true
    });
  } 

  //OPEN LIBRARY ITEM MENU
  handleAppProfileMenu = event => {
    event.stopPropagation();

    const id = event.currentTarget.getAttribute('_id');
    const element = event.currentTarget;

    this.setState({
      menuElement: element,
      selectedAppProfileId: id
    });
  }

  //CLOSE LIBRARY ITEM MENU AND HANDLE IF SELECTION (PREVIEW, EDIT, or DELETE)
  handleAppProfileMenuClose = (type, selectedAppProfileId, fromEdit=false, fromView=false, addedRemoved, event) => {
    const { newAppProfile } = this.state;

    let openViewAppProfileModal = false,
        openAppProfileModal = false,
        openAddToAppProfileModal = false,
        deleteAppProfileModal = false,
        addTeams = false,
        addShowcases = false,
        newAppProfileTeams = false;

    if (newAppProfile) {
      newAppProfileTeams = true;
    }

    if (!selectedAppProfileId && event) {
      selectedAppProfileId = event.currentTarget.getAttribute('_id');
    }

    this.setState({
      menuElement: null
    })

    switch (type) {
      case 'preview':
        window.open(`${siteUrl()}showcase_preview/${selectedAppProfileId}`, "_blank")
        selectedAppProfileId = null
        break;

      case 'view':
        if (fromEdit) {
          openAppProfileModal = true;
        }

        openViewAppProfileModal = true;
        break;

      case 'edit':
        openAppProfileModal = true;
        break;

      case 'add-teams':
        if (fromEdit) {
          openAppProfileModal = true;
        } 

        if (fromView) {
          openViewAppProfileModal = true;
        }

        openAddToAppProfileModal = true;
        addTeams = true;
        break;

      case 'add-library-groups':
        if (fromEdit) {
          openAppProfileModal = true;
        } 

        if (fromView) {
          openViewAppProfileModal = true;
        }

        openAddToAppProfileModal = true;
        break;

      case 'add-showcases':
        if (fromEdit) {
          openAppProfileModal = true;
        } 

        if (fromView) {
          openViewAppProfileModal = true;
        }

        openAddToAppProfileModal = true;
        addShowcases = true;
        break;
      
      case 'delete':
        openAppProfileModal = true;
        deleteAppProfileModal = true;
        break;
      
      default:  
        break;
    }

    this.setState({
      openViewAppProfileModal: openViewAppProfileModal,
      openAppProfileModal: openAppProfileModal,
      openAddToAppProfileModal: openAddToAppProfileModal,
      deleteAppProfileModal: deleteAppProfileModal,
      selectedAppProfileId: selectedAppProfileId,
      addTeams: addTeams,
      addShowcases: addShowcases,
      fromEdit: fromEdit,
      fromView: fromView,
      addedRemoved: addedRemoved,
      newAppProfile: false,
      newAppProfileTeams
    });

  }

  //HANDLE CLOSED VIEW APP PROFILE MODAL
  closedViewAppProfileModal = () => {
    const { fromEdit } = this.state;

    if (fromEdit) {
      this.setState({
        openViewAppProfileModal: false
      });
    } else {
      this.setState({
        openViewAppProfileModal: false,
        selectedAppProfileId: null
      });
    }
    
  }

  //HANDLE CLOSED CREATE/EDIT/DELETE APP PROFILE MODAL
  closedAppProfileModal = (updated, data) => {
    const { history } = this.props;
    const token = this.props.token;
    const companyId = this.props.companyId;
    const { showcases, newAppProfile, newAppProfileTeams, selectedAppProfileId } = this.state;

    let handleSelectedAppProfileId = selectedAppProfileId;

    if (updated) {      
      this.props.fetchAllAppProfiles(token, companyId, results => {

        if (newAppProfile || newAppProfileTeams) {
          handleSelectedAppProfileId = data._id;
        }

        this.setState({
          openAppProfileModal: false,
          deleteAppProfileModal: false,
          openAddToAppProfileModal: false,
          selectedAppProfileId: handleSelectedAppProfileId,
          fromEdit: false,
          fromView: false
        });

        if (!showcases && newAppProfile && !newAppProfileTeams) {
          this.handleAppProfileMenuClose('add-teams', handleSelectedAppProfileId);
        } else if ((!showcases && newAppProfileTeams) || (showcases && newAppProfile)) {
          this.handleAppProfileMenuClose('add-library-groups', handleSelectedAppProfileId);
        }

      });

    } else {
      this.setState({ 
        openAppProfileModal: false,
        deleteAppProfileModal: false,
        openAddToAppProfileModal: false,
        selectedAppProfileId: null,
        newAppProfile: false,
        fromEdit: false,
        fromView: false
      });
    }

    if (showcases) {
      history.push('/content/showcases');
    } else {
      history.push('/content/app_profiles');
    }
    
  }

  //HANDLE CLOSED ADD TO APP PROFILE MODAL
  closedAddToAppProfileModal = (updated, updatedAppProfile) => {
    const token = this.props.token;
    const companyId = this.props.companyId;
    const { showcases, newAppProfile, newAppProfileTeams, fromEdit, fromView, selectedAppProfileId } = this.state;

    let handleSelectedAppProfileId = selectedAppProfileId

    if (updated) {
      this.props.fetchAllAppProfiles(token, companyId);
    }

    if (!newAppProfile && !fromEdit && !fromView) {
      handleSelectedAppProfileId = null;
    }

    this.setState({
      selectedAppProfileId: handleSelectedAppProfileId,
      openAddToAppProfileModal: false
    })

    if (!showcases && updatedAppProfile) {
      this.closedAppProfileModal(updated, updatedAppProfile)
    }
  }

  handleOpenViewAppProfileModal = () => {
    const { showcases, openViewAppProfileModal, selectedAppProfileId, fromEdit } = this.state;

    if (openViewAppProfileModal) {
      return (
        <ViewAppProfileModalContainer
          showcases={showcases}
          openViewAppProfileModal={openViewAppProfileModal}
          selectedAppProfileId={selectedAppProfileId}
          fromEdit={fromEdit}
          addRemove={this.handleAppProfileMenuClose}
          closedViewAppProfileModal={this.closedViewAppProfileModal}
        />
      )
    }
  }

  handleOpenAppProfileModal = () => {
    const { showcases, openAppProfileModal, deleteAppProfileModal, selectedAppProfileId } = this.state;

    if (openAppProfileModal) {
      return (
        <AppProfileModalContainer 
          showcases={showcases}
          openAppProfileModal={openAppProfileModal}
          deleteAppProfileModal={deleteAppProfileModal}
          selectedAppProfileId={selectedAppProfileId}
          viewTeamsLibraryGroups={this.handleAppProfileMenuClose}
          closedAppProfileModal={this.closedAppProfileModal}
        />
      )
    }
  }

  handleOpenAddToAppProfileModal = () => {
    const { showcases, openAddToAppProfileModal, newAppProfile, selectedAppProfileId, addTeams, addShowcases, addedRemoved } = this.state;

    if (openAddToAppProfileModal) {
      return (
        <AddToAppProfileModalContainer
          showcases={showcases}
          openAddToAppProfileModal={openAddToAppProfileModal}
          newAppProfile={newAppProfile}
          selectedAppProfileId={selectedAppProfileId}
          addTeams={addTeams}
          addShowcases={addShowcases}
          closedAddToAppProfileModal={this.closedAddToAppProfileModal}
          //used to know if update of viewAppProfileModal needed
          addedRemoved={addedRemoved}
        />
      )
    }
  }

  appProfileClick = (event) => {
    const { owner, currentRole, appProfiles } = this.props;
    const _id = event.currentTarget.getAttribute('_id');
    const initialValues = _.find(appProfiles, {_id});

    const openEdit = menuPermissions('appProfiles', false, owner, currentRole, initialValues);
    openEdit && this.handleAppProfileMenuClose('edit', _id);
  }

  handleAppProfiles = () => {
    const { owner, currentRole, appProfiles, classes } = this.props;
    const { showcases } = this.state;

    let content = [],
        cardSpacing = 6,
        cardSpacingSmall = 12,
        footerMenu = 3;

    if (showcases) {
      content = _.filter(appProfiles, {showcase: true});
    } else {
      content = _.filter(appProfiles, function(item) {
        return !item.showcase || item.showcase === false;
      });
    }

    if (showcases) {
      cardSpacing = 4;
      cardSpacingSmall = 6;
      footerMenu = 4;
    }

    return (
      content.map(item => {
        const { _id, name, branding_details } = item ? item : {},
              { branding_background_image, branding_small_logo, branding_primary, branding_secondary, branding_accent } = branding_details ? branding_details : {}

        return (
          <Grid key={_id} item xs={12} sm={cardSpacingSmall} md={6} lg={cardSpacing} xl={4}>
            <div className={`${classes.appProfileTitleArea} clearfix`}>
              <Typography _id={_id} className={classes.appProfileName} gutterBottom variant="h4">
                {name}
              </Typography>
            </div>
            <Card
              id={_id}
              key={_id}
              className={`${classes.card} ${classes.appProfileCard} appProfileCardAfter`}
            >
              <div className={classes.cardMediaContainer}>
                {(item && item.branding_details && item.branding_details.branding_background_image) &&
                  <CardMedia
                    _id={_id}
                    className={classes.cardMedia}
                    component="img"
                    alt={name}
                    image={branding_background_image}
                    title={name}
                    onClick={event => this.appProfileClick(event)}
                  />
                }
                {item && item.branding_details && !item.branding_details.branding_background_image &&
                  <div _id={item._id} className={classes.appProfileColorBackground} onClick={event => this.appProfileClick(event)} />
                }
              </div>
              <CardContent className={classes.cardContent}>
                {/*<Typography className={classes.appProfileSubText} gutterBottom variant="body1">
                  Created On: <span className={classes.appProfileCreatedDate}>{convertToDateString(item.createdAt)}</span><br />
                  Last Updated: <span className={classes.appProfileUpdatedDate}>{convertToDateString(item.updatedAt)}</span>
                </Typography>*/}
                {(menuPermissions('appProfiles', false, owner, currentRole, item)) &&
                  <Grid container spacing={4}>
                    <Grid item xs={footerMenu} className={classes.appProfileButtonContainer}>
                      <Button 
                        _id={_id}
                        className={`${classes.addButton}`}
                        onClick={event => this.handleAppProfileMenuClose('edit', null, false, false, null, event)}
                        focusRipple={false}
                      >
                        <Edit className={classes.addCircleIcon} />
                        Edit
                      </Button>
                    </Grid>
                    <Grid item xs={footerMenu} className={classes.appProfileButtonContainer}>
                      <Button 
                        _id={_id}
                        className={`${classes.addButton}`}
                        onClick={event => this.handleAppProfileMenuClose('view', null, false, false, null, event)}
                        focusRipple={false}
                      >
                        <LibraryBooks className={classes.addCircleIcon} />
                        Content
                      </Button>
                    </Grid>
                    {!showcases &&
                      <Grid item xs={footerMenu} className={classes.appProfileButtonContainer}>
                        <Button 
                          _id={_id}
                          className={`${classes.addButton}`}
                          onClick={event => this.handleAppProfileMenuClose('view', null, false, false, null, event)}
                          focusRipple={false}
                        >
                          <SupervisorAccount className={classes.addCircleIcon} />
                          Teams
                        </Button>
                      </Grid>
                    }
                    <Grid item xs={footerMenu} className={classes.appProfileButtonContainer}>
                      <IconButton 
                        id={`${_id}-menu`}
                        _id={_id}
                        className={classes.appProfileButton}
                        aria-label="Menu"
                        onClick={event => this.handleAppProfileMenu(event)}
                      >
                        <MoreVert className={`${classes.verticalDots} ${classes.verticalDotsAppProfile}`} />
                      </IconButton>
                    </Grid>
                  </Grid>
                }
              </CardContent>
            </Card>
            <div className={`${classes.appProfileTitleArea} ${classes.appProfileCardBrandArea} clearfix`}>
              <div className={classes.appProfileColorBlocks}>
                <div className={classes.appProfileColorBlock} style={{backgroundColor: branding_primary ? branding_primary : torquoise}} />
                <div className={classes.appProfileColorBlock} style={{backgroundColor: branding_secondary ? branding_secondary : teal}} />
                <div className={classes.appProfileColorBlock} style={{backgroundColor: branding_accent ? branding_accent : energy}} />
              </div>
              {branding_small_logo &&
                <img className={classes.appProfileSmallLogo} src={branding_small_logo} alt="logo" />
              }
            </div>
          </Grid>
        )
      })
    )
  }
  
  render() {
    const { classes } = this.props;
    const { showcases, menuElement, selectedAppProfileId } = this.state;

    return (
      <div className={`${classes.root} ${classes.rootExtendedHeader}`}>
        {this.handleOpenViewAppProfileModal()}
        {this.handleOpenAppProfileModal()}
        {this.handleOpenAddToAppProfileModal()}
        <Grid container spacing={4} style={{height: '100%'}}>
          <Grid item xs={12} className={`${classes.noPaddingBottom}`}>
            <Typography variant="h4" className={classes.topButtonRow}> 
              <Button className={classes.addButton} onClick={this.addAppProfile}>
                <AddCircle className={classes.addCircleIcon} />
                {showcases ? 'Add New Showcase Template' : 'Add New App Profile'}
              </Button>
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.appProfileGrid}>
            <Grid container spacing={4}>
              {this.handleAppProfiles()}
              <Menu
                anchorEl={menuElement}
                open={Boolean(menuElement)}
                onClose={() => this.handleAppProfileMenuClose(null, null)}
              >
                {showcases &&
                  <MenuItem onClick={() => this.handleAppProfileMenuClose('preview', selectedAppProfileId)}>
                    <ListItemIcon>
                      <Class />
                    </ListItemIcon>
                    Preview Showcase
                  </MenuItem>
                }
                <MenuItem onClick={() => this.handleAppProfileMenuClose('view', selectedAppProfileId)}>
                  <ListItemIcon>
                    <Search />
                  </ListItemIcon>
                  {showcases ? 'View Content' : 'View Teams/Content'}
                </MenuItem>
                <MenuItem onClick={() => this.handleAppProfileMenuClose('edit', selectedAppProfileId)}>
                  <ListItemIcon>
                    <Edit />
                  </ListItemIcon>
                  Edit Details
                </MenuItem>
                {!showcases &&
                  <MenuItem onClick={() => this.handleAppProfileMenuClose('add-teams', selectedAppProfileId)}>
                    <ListItemIcon>
                      <SupervisorAccount />
                    </ListItemIcon>
                    Add / Remove Teams
                  </MenuItem>
                }
                <MenuItem onClick={() => this.handleAppProfileMenuClose('add-library-groups', selectedAppProfileId)}>
                  <ListItemIcon>
                    <LibraryBooks />
                  </ListItemIcon>
                  Add / Remove Content Groups
                </MenuItem>
                {!showcases && <MenuItem onClick={() => this.handleAppProfileMenuClose('add-showcases', selectedAppProfileId)}>
                  <ListItemIcon>
                    <LibraryBooks />
                  </ListItemIcon>
                  Add / Remove Showcases
                </MenuItem>}
                <MenuItem onClick={() => this.handleAppProfileMenuClose('delete', selectedAppProfileId)}>
                  <ListItemIcon>
                    <Delete />
                  </ListItemIcon>
                  Delete
                </MenuItem>
              </Menu>
            </Grid>            
          </Grid>
        </Grid>
      </div>
    );
  }
}

const AppProfilesWithLoading = WithLoading(AppProfiles)

class AppProfilesContainer extends PureComponent {
  state = {
    loading: true
  }

  componentDidMount() {
    const { history, loggedOut, token, companyId, owner } = this.props;

    verifyLoggedIn(history, loggedOut, () => {
      this.props.verifyRole(token, owner, verifyUser => {
        if (verifyUser.data.user_type <= '2') {
          this.props.fetchAllAppProfiles(token, companyId, () => {
            this.setState({
              loading: false
            });
          });
        }
      });
    });
  }

  render() {
    const loading = this.state.loading;

    return (
      <AppProfilesWithLoading 
        isLoading={loading}
        {...this.props}
      />
    )
  }
}

function mapStateToProps(state) {
  if (_.isEmpty(state.login.token) || _.isEmpty(state.login.company) || _.isEmpty(state.login.user)) {
    return {
      loggedOut: true
    }
  }

  return { 
    token: state.login.token,
    companyId: state.login.company._id,
    currentUser: state.login.user,
    currentRole: state.login.user.user_type,
    owner: state.login.user._id,
    appProfiles: state.appProfiles
  };
}

const mapDispatchToProps = dispatch => ({
  verifyRole: (token, owner, callback) => dispatch(verifyRole(token, owner, callback)),
  fetchAllAppProfiles: (token, companyId, callback) => dispatch(fetchAllAppProfiles(token, companyId, callback))
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(AppProfilesContainer)
