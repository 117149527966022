import React from 'react';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

export const radioButtons = ({options, radioLabelText, radioRoot, disabled, classes}) => {
  return options.map((radio, index) => {

    return (
      <FormControlLabel 
        className={classes.radioLabel}
        classes={{
          label: radioLabelText,
          root: radioRoot
        }}
        key={index} 
        label={radio.label} 
        value={radio.value}
        control={<Radio classes={{ root: classes.radioRoot }}/>}
        disabled={disabled}
      />
    )
  })
}

export const RenderRadioGroup = (
  { classes,
    label,
    options,
    billingFormGroup,
    radioLabelText,
    radioRoot,
    defaultValue,
    disabled,
    input: { onChange, value, checked, name } 
  }) => {

    return (
      <FormControl className={classes.radioContainer} component="fieldset">
        <FormLabel component="legend">
          {label}
        </FormLabel>
        <RadioGroup 
          defaultValue={defaultValue}
          aria-label={name}
          name={name}
          value={value}
          onChange={onChange}
          classes={{root: billingFormGroup}}
        >
          {radioButtons({options, radioLabelText, radioRoot, disabled, classes})}
        </RadioGroup>
      </FormControl>
    )
  }