import React from 'react';

import Image from '@material-ui/icons/Image';
import Class from '@material-ui/icons/Class';

import FilePdfBox from 'mdi-material-ui/FilePdfBox';
import FileWordBox from 'mdi-material-ui/FileWordBox';
import FileExcelBox from 'mdi-material-ui/FileExcelBox';
import FilePowerpointBox from 'mdi-material-ui/FilePowerpointBox';
import PlayCircle from 'mdi-material-ui/PlayCircle';
import File from 'mdi-material-ui/File';
import LinkBoxVariant from 'mdi-material-ui/LinkBoxVariant';
import OfflineBoltIcon from '@material-ui/icons/OfflineBolt';

import { checkIfVideoLink } from './helperFunctions';

import { navy, peacock, teal, torquoise, slimer, energy, verbSeaGreen } from '../../theme';

export const multipleFilter = (value, filter) => {
  //removed ${value[Object.keys(value)[2]]} this is covered in tags filter
  const fullText = `${value[Object.keys(value)[0]]} ${value[Object.keys(value)[1]]}`;

  if (fullText.toLowerCase().includes(filter.value.toLowerCase())) {
    return true;
  }
  return false;
};

//compares subset to superset, superset must include all of subset to return true
function arrayContainsArray (superset, subset) {
  if (0 === subset.length || superset.length < subset.length) {
    return false;
  }
  for(var i = 0; i < subset.length; i++) {
    if(superset.indexOf(subset[i]) === -1) return false;
  }
  return true;
}

export const tagsFilter = (value, filter) => 
{
  const newValueString = value.replace(/ /g, '').toLowerCase();
  const newFilterString = filter.value.replace(/ /g, '').toLowerCase();
  // const newFilterString = filter.value.replace(/ /g, ',').toLowerCase().replace(/,,,/g, ',').replace(/,,/g, ',');

  if (newValueString.includes(newFilterString)) {
    return true;
  } else {
    const newValueArray = newValueString.split(',');
    const newFilterArray = newFilterString.split(',');

    //OR search
    // let found = newValueArray.some(r => newFilterArray.indexOf(r) >= 0);
    
    //AND search
    const found = arrayContainsArray(newValueArray, newFilterArray);
    
    return found;
  }

}

export const statusFilter = (value, filter) => {
  if (value.toLowerCase().startsWith(filter.value.toLowerCase())) {
    return true;
  }

  return false
}

export const getFileType = (type, location, url) => {
  let color = '#00C1C8',
      iconStyle = {}

  if (location === 'room') {
    iconStyle = {
      height: '25px',
      width: '25px'
    };
  } else {
    iconStyle = {
      height: '30px',
      width: '30px'
    };
  }

  if (type === 'link' && url && checkIfVideoLink(url)) {
    type = 'video';
  }

  if (type) {
    type = type.toLowerCase()
  }

  switch(type) {
    case 'pdf':
      color = torquoise;
      iconStyle.color = color;
      return (<FilePdfBox style={iconStyle}/>)
    case 'doc':
    case 'docx':
      color = navy;
      iconStyle.color = color;
      return (<FileWordBox style={iconStyle}/>)
    case 'xls': 
    case 'xlsx':
      color = slimer;
      iconStyle.color = color;
      return (<FileExcelBox style={iconStyle}/>)
    case 'ppt':
    case 'pptx':
    case 'zip':
      color = teal;
      iconStyle.color = color;
      return (<FilePowerpointBox style={iconStyle}/>)
    case 'mov':
    case 'mp4':
    case 'mpeg':
    case 'm4v':
    case 'avi':
    case 'wmv':
    case 'flv':
    case 'video':
    case '3gp':
    case '360':
      color = peacock;
      iconStyle.color = color;
      return (<PlayCircle style={iconStyle}/>)
    case 'iv':
      color = verbSeaGreen;
      iconStyle.color = color;
      return (<OfflineBoltIcon style={iconStyle}/>)
    case 'img':
    case 'image':
    case 'png':
    case 'jpg':
    case 'jpeg':
    case 'tiff':
    case 'gif':
      color = peacock;
      iconStyle.color = color;
      return (<Image style={iconStyle}/>)
    case 'link':
      color = energy;
      iconStyle.color = color;
      return (<LinkBoxVariant style={iconStyle}/>)
    case 'showcase':
      color = energy;
      iconStyle.color = color;
      iconStyle.width = 30;
      iconStyle.height = 30;
      return (<Class style={iconStyle}/>)
    default:
      color = teal;
      iconStyle.color = color;
      return (<File style={iconStyle}/>)
  }
}

export const libraryNameSort = (a, b) => {
  const nameA = a[Object.keys(a)[0]].toLowerCase();
  const nameB = b[Object.keys(b)[0]].toLowerCase();

  if (nameA === nameB) {
    return 0;
  }
  return (nameA < nameB) ? -1 : 1;
}
