import _ from "lodash";
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Redirect } from "react-router-dom";

import { withStyles } from '@material-ui/core/styles';
import { styles } from '../styles';

import OnboardingWelcome from './onboarding_welcome';
import { OnboardingCompany } from './onboarding_company';
import { OnboardingContentGroups } from './onboarding_content_groups';
import { OnboardingContent } from './onboarding_content';
import { OnboardingTeam } from './onboarding_team';
import { OnboardingUsers } from './onboarding_users';
import OnboardingBrand from './onboarding_brand';
import OnboardingCompleted from './onboarding_completed';

import { verifyLoggedIn } from '../helpers/helperFunctions';

import { updateTeam, createUser, updateBranding } from '../../actions/actions_index';

class Onboarding extends Component {
  componentDidMount() {
    const { history, loggedOut } = this.props;

    verifyLoggedIn(history, loggedOut, () => { });
  }

  render() {
  	const { history, loggedOut, classes } = this.props;
  	const { step } = this.props.match.params;

  	if (loggedOut) {
      return (
        <Redirect to='/login'/>
      )
    }

  	return (
  		<div className={`${classes.root} ${classes.onboardingRoot}`}>
	  		{step === 'welcome' && 
          <OnboardingWelcome history={history} />
        }

        {step === 'company' && 
	  			<OnboardingCompany history={history} />
	  		}

	  		{step === 'content_groups' && 
	  			<OnboardingContentGroups history={history} />
	  		}

	  		{/*{step === 'content' && 
	  			<OnboardingContent history={history} />
	  		}*/}

	  		{/*{step === 'team' && 
	  			<OnboardingTeam history={history} />
	  		}*/}

	  		{step === 'users' && 
	  			<OnboardingUsers history={history} />
	  		}

	  		{step === 'brand' && 
	  			<OnboardingBrand history={history} />
	  		}

	  		{step === 'completed' && 
	  			<OnboardingCompleted history={history} />
	  		}
  		</div>
  	)
  }
}

function mapStateToProps(state) {
  if (_.isEmpty(state.login.token) || _.isEmpty(state.login.company) || _.isEmpty(state.login.user)) {
    return {
      loggedOut: true
    }
  }

  return { 
    token: state.login.token,
    companyId: state.login.company._id,
    enterprise: state.login.company.enterprise,
    owner: state.login.user._id,
    currentUser: state.login.user
  };
}

const mapDispatchToProps = dispatch => ({
  updateTeam: (token, values, id, callback) => dispatch(updateTeam(token, values, id, callback)),
  createUser: (token, values, teamId, callback) => dispatch(createUser(token, values, teamId, callback)),
  updateBranding: (token, values, id, callback) => dispatch(updateBranding(token, values, id, callback))
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(Onboarding)