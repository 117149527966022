import _ from "lodash";
import React, {Component} from "react";
import {compose} from "redux";
import {connect} from "react-redux";
import {Link, Redirect, withRouter} from "react-router-dom";
import {Field, reduxForm} from "redux-form";
import PropTypes from "prop-types";
import queryString from "querystring";

import {withStyles} from "@material-ui/core/styles";
import {styles} from "./styles";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import FormHelperText from "@material-ui/core/FormHelperText";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";

import WithLoading from "./subcomponents/withLoading";
import LoadingDivContainer from "./subcomponents/loadingDiv";
import {RenderTextField} from "./subcomponents/form_subcomponents/renderTextField";
import {RenderSelectField} from "./subcomponents/form_subcomponents/renderSelectField";

import {
    CREATE_YOUR_SOLOFIRE_ACCOUNT,
    handleAppLiveUser,
    handleEnterpriseAppUser,
    validateEmail,
} from "../components/helpers/helperFunctions";

import {
    activeAppProfile,
    createFullCompany,
    fetchAppUserAppProfiles,
    fetchCompany,
    fetchLearnJwt,
    login,
    loginViaSSO,
    logout,
    useSSO,
    verifyUser
} from "../actions/actions_index";

class LoginForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loadingAppProfiles: false,
        };

        //resets form fields
        this.props.initialize();

        //resets any error messages
        this.props.login();
    }

    onSubmit = (values) => {
        const {
            login,
            createFullCompany,
            currentPath,
            createAccount,
            loginPath,
            fetchAppUserAppProfiles,
            activeAppProfile,
            submitLogin,
            afterLogin,
            fetchCompany,
            fetchLearnJwt,
        } = this.props;

        submitLogin();

        values.email = values.email.toLowerCase();

        if (createAccount) {
            values.verified = true;

            createFullCompany(values, (company) => {
                if (company.error) {
                    this.setState({
                        loginError: true,
                        error: company.error,
                    });
                } else {
                    this.setState({
                        redirectToLogin: true,
                    });
                }
            });
        } else {
            login(values, (data) => {
                const token = data.token;

                if (data.error) {
                    this.setState({
                        error: data.error,
                    });
                } else if (data.company) {
                    const {_id, enterprise, solopreneur, live_only} = data.company,
                        {user_type} = data.user,
                        type = user_type > "1" ? "user" : "admin";

                    fetchCompany(token, _id, (company) => {
                        if (company && company.data && company.data.learn) {
                            fetchLearnJwt(token, type, (data) => {
                            });
                        }
                    });

                    if (user_type > "1" || live_only) {
                        this.setState({
                            loadingAppProfile: true,
                        });

                        fetchAppUserAppProfiles(token, (data) => {
                            if (!enterprise) {
                                activeAppProfile(data.data[0], () => {
                                });
                            } else {
                                afterLogin();
                                this.setState({
                                    loadingAppProfile: false,
                                });
                            }
                        });
                    }

                    let planLevel = enterprise ? "Enterprise" : "Teams";

                    if (solopreneur) {
                        planLevel = "Solopreneurs";
                    }

                    if (token && planLevel !== "Enterprise") {
                        window.get_ambassador_raf = true;
                    } else {
                        window.get_ambassador_raf = false;
                    }
                }
            });
        }
    };

    onSubmitAppProfile = (values) => {
        const {appProfiles, activeAppProfile} = this.props;
        const appProfile = appProfiles.filter(
            (item) => item._id === values.app_profile
        );

        activeAppProfile(appProfile[0], () => {
        });
    };

    selectAppProfiles = () => {
        const {appProfiles} = this.props;

        if (appProfiles.length > 0) {
            return appProfiles.map((value) => (
                <MenuItem key={value._id} value={value._id}>
                    {value.name}
                </MenuItem>
            ));
        }
    };

    checkEmailOnBlur = (e) => {
        const values = {email: e.target.value, callbackUrl: window.location.href};
        this.props.useSSO(values, () => {
        });
    };

    handleNext = (values) => {
        window.location.href = this.props.sso.loginUrl;
    }


    render() {
        const {loadingAppProfile} = this.state;
        const {
            token,
            enterpriseAppUser,
            createAccount,
            pristine,
            invalid,
            handleSubmit,
            classes,
            sso,
        } = this.props;

        if (loadingAppProfile) {
            return <LoadingDivContainer/>;
        }

        if (token && enterpriseAppUser) {
            return (
                <form
                    className={classes.loginForm}
                    onSubmit={handleSubmit(this.onSubmitAppProfile)}>
                    <Field
                        name="app_profile"
                        label="Select App Profile"
                        component={RenderSelectField}>
                        {this.selectAppProfiles()}
                    </Field>
                    <Button
                        className={classes.loginButton}
                        variant="contained"
                        type="submit"
                        color="secondary"
                        disabled={pristine || invalid}>
                        Submit
                    </Button>
                </form>
            );
        }

        return (
            <form
                className={classes.loginForm}
                onSubmit={handleSubmit(!sso ? this.onSubmit : this.handleNext)}>
                <Field
                    name="email"
                    component={RenderTextField}
                    type="email"
                    label="Email"
                    autoFocus
                    disabled={createAccount}
                    onBlur={this.checkEmailOnBlur}
                />
                {!sso ?
                    <Field
                        name="password"
                        component={RenderTextField}
                        type="password"
                        label="Password"
                        custom="false"
                    /> : <></>
                }


                {/*!createAccount &&
          <Typography variant="body1" className={classes.create}><Link to="/create_account">Create Account</Link></Typography>
        */}

                {!createAccount && (
                    <Typography variant="body1" className={classes.forgot}>
                        <Link to="/forgot_password">Forgot Password?</Link>
                    </Typography>
                )}

                <Button
                    className={classes.loginButton}
                    variant="contained"
                    type="submit"
                    color="secondary"
                    disabled={pristine || invalid}>
                    {!sso ? "Submit" : "Log in with SSO"}
                </Button>
            </form>
        );
    }
}

const validate = (values) => {
    const errors = {};

    // Validate the inputs from 'values'
    const requiredFields = ["email", "password"];

    requiredFields.forEach((field) => {
        if (!values[field]) {
            errors[field] = "Required";
        }
    });

    if (values.email && validateEmail(values.email)) {
        errors.email = "Invalid email address";
    }

    // If errors is empty, the form is fine to submit
    // If errors has *any* properties, redux form assumes form is invalid
    return errors;
};

const LoginFormContainer = reduxForm({
    form: "LoginForm",
    validate,
    enableReinitialize: true,
})(LoginForm);

class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
            redirectToLogin: false,
            loading: false,
        };

        // this.props.logout()
    }

    submitLogin = () => {
        this.setState({loading: true});
    };

    afterLogin = () => {
        this.setState({loading: false});
    };

    formNotificationInsert = () => {
        const {classes} = this.props;

        if (this.props.formError) {
            return (
                <FormHelperText
                    className={`${classes.centerInfoText} ${classes.centerErrorText}`}>
                    {this.props.formError}
                </FormHelperText>
            );
        } else if (this.props.forgot && this.props.forgot.resetSuccess) {
            return (
                <FormHelperText className={classes.centerInfoText}>
                    Your password has been reset.
                </FormHelperText>
            );
        } else {
            return "";
        }
    };

    checkPath = () => {
        const currentPath = this.props.location.pathname;
        const createAccount = currentPath.includes(CREATE_YOUR_SOLOFIRE_ACCOUNT);
        const loginPath = currentPath.includes("login");

        return {
            currentPath,
            createAccount,
            loginPath,
        };
    };

    render() {
        const {
            enterpriseAppUser,
            appLiveUser,
            appProfile,
            initialValues,
            token,
            company,
            currentUser,
            verified,
            classes,
        } = this.props;
        const {error, redirectToLogin, loading} = this.state;
        const {currentPath, createAccount, loginPath} = this.checkPath();

        if (
            !_.isEmpty(token) &&
            (!appLiveUser || (appLiveUser && appProfile && appProfile._id))
        ) {
            if (currentUser.email === "master_admin@solofire.com") {
                return <Redirect to="/master_admin"/>;
            }

            if (company && !company.enterprise && company.onboarding) {
                if (company.onboarding_step) {
                    return <Redirect to={`/onboarding/${company.onboarding_step}`}/>;
                } else {
                    return <Redirect to="/onboarding/welcome"/>;
                }
            } else if (!appLiveUser || (appLiveUser && appProfile._id)) {
                //if enterprise app user, make sure app profile selected
                return <Redirect to="/content/library"/>;
            }
        }

        // if (!loginPath && redirectToLogin) {
        //   return (
        //     <Redirect to='/login'/>
        //   )
        // }

        let heading = "Log In";

        if (createAccount) {
            heading = "Finish Setting Up Your Account";
        }

        if (token && enterpriseAppUser && !loading) {
            heading = "Choose an App Profile";
        }

        let messageText = "Your email has been verified! You can now login.";

        if (redirectToLogin) {
            messageText = "Account created, you may now login!";
        }

        return (
            <div className={`${classes.root} ${classes.blurBackgroundWrapper}`}>
                <Grid container spacing={4} className={classes.loginSizeOfScreen}>
                    <Grid item lg={4} md={3} sm={false} xs={false}/>
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <div className={classes.loginContainer}>
                            {/*<Grid container spacing={4}>
                <Grid item lg={6} md={6} sm={12} xs={12} >*/}
                            {(verified || redirectToLogin) && (
                                <div className={classes.topMessageContainer}>
                                    <Typography
                                        className={classes.topMessage}
                                        type="body1"
                                        color="primary">
                                        {messageText}
                                    </Typography>
                                </div>
                            )}
                            {/*{error &&
                    <div>
                      <Typography className={classes.centerBigErrorText} type="body1" color="primary">{error}</Typography>
                    </div>
                  }*/}
                            <Typography
                                className={classes.headingBig}
                                type="display1"
                                color="primary">
                                {heading}
                            </Typography>
                            <br/>
                            <div>{this.formNotificationInsert()}</div>
                            <LoginFormContainer
                                {...this.props}
                                createAccount={createAccount}
                                currentPath={currentPath}
                                loginPath={loginPath}
                                submitLogin={this.submitLogin}
                                afterLogin={this.afterLogin}
                            />
                            {/*</Grid>
                <Grid item lg={6} md={6} sm={12} xs={12} >
                  {!createAccount &&  
                    <img className={`${classes.verbLogin}`} src="/images/verb_login.png" alt={''} />
                  }
                  {createAccount &&
                    <div className={`${classes.createAccountInfo} ${classes.createSolofireAccountInfo}`}>
                      <Typography variant="h2" className={classes.createAccountH2}>Try SoloFire FREE for 30 Days</Typography>
                      <Typography variant="h4" className={classes.createAccountH4}>Find, show and share compliant content</Typography>
                      <img className={`${classes.createAccountImage}`} src="/images/solofire_everywhere.png" alt={''} />
                    </div>
                  }
                </Grid>
              </Grid>*/}
                        </div>
                    </Grid>
                    <Grid item lg={4} md={3} sm={false} xs={false}/>
                </Grid>
            </div>
        );
    }
}

Login.propTypes = {
    classes: PropTypes.object.isRequired,
};

const LoginWithLoading = WithLoading(Login);

class LoginContainer extends Component {
    state = {
        verified: false,
        loading: false,
        loginPath: false,
        createAccount: false,
        email: "",
    };

    componentDidMount() {
        const {token, company, currentUser} = this.props,
            {verification_token} = this.props.match.params;

        if (!token || !company || !currentUser) {
            let paramsString = this.props.location.search;

            //remove ? at the beginning of query string
            if (paramsString.charAt(0) === "?") {
                paramsString = paramsString.substr(1);
            }

            const params = queryString.parse(paramsString);

            if (verification_token) {
                let verified = false;

                this.setState({
                    loading: true,
                });

                this.props.verifyUser(verification_token, (data) => {
                    if (data.data) {
                        verified = true;
                    }

                    this.setState({
                        loading: false,
                        verified,
                    });
                });
            }

            if (params.code && params.state) {
                console.log('here');
                if (!params.state && clientID) {
                    params.state = clientID;
                }
                this.setState({
                    loading: true,
                });

                console.log(params);
                this.props.loginViaSSO({
                    code: params.code,
                    state: params.state,
                    callback_url: `${window.location.origin}/login`,
                }, (data) => {
                    console.log('logged in', data);
                    const token = data.token;

                    if (data.error) {
                        this.setState({
                            error: data.error,
                        });
                    } else if (data.company) {
                        const {_id, enterprise, solopreneur, live_only} = data.company,
                            {user_type} = data.user,
                            type = user_type > "1" ? "user" : "admin";

                        fetchCompany(token, _id, (company) => {
                            if (company && company.data && company.data.learn) {
                                fetchLearnJwt(token, type, (data) => {
                                });
                            }
                        });

                        if (user_type > "1" || live_only) {
                            this.setState({
                                loadingAppProfile: true,
                            });

                            fetchAppUserAppProfiles(token, (data) => {
                                if (!enterprise) {
                                    activeAppProfile(data.data[0], () => {
                                    });
                                } else {
                                    afterLogin();
                                    this.setState({
                                        loadingAppProfile: false,
                                    });
                                }
                            });
                        }

                        let planLevel = enterprise ? "Enterprise" : "Teams";

                        if (solopreneur) {
                            planLevel = "Solopreneurs";
                        }

                        if (token && planLevel !== "Enterprise") {
                            window.get_ambassador_raf = true;
                        } else {
                            window.get_ambassador_raf = false;
                        }
                    }
                });

            } else {
                this.setState({
                    loading: false,
                    email: params.email,
                });
            }
        }
    }

    render() {
        const {loading, verified, createAccount, email} = this.state;

        const initialValues = {email};

        return (
            <LoginWithLoading
                isLoading={loading}
                verified={verified}
                initialValues={initialValues}
                {...this.props}
            />
        );
    }
}

function mapStateToProps(state) {
    return {
        sso: state.login.sso,
        token: state.login.token,
        company: state.login.company,
        currentUser: state.login.user,
        enterpriseAppUser: handleEnterpriseAppUser(state),
        appLiveUser: handleAppLiveUser(state),
        appProfiles: state.appProfiles,
        appProfile: state.appProfile,
        formError: state.login.error,
        forgot: state.forgot,
        formInfo: state.form,
    };
}

export default compose(
    withRouter,
    connect(mapStateToProps, {
        verifyUser,
        login,
        logout,
        loginViaSSO,
        useSSO,
        fetchCompany,
        fetchLearnJwt,
        createFullCompany,
        fetchAppUserAppProfiles,
        activeAppProfile,
    }),
    withStyles(styles)
)(LoginContainer);
