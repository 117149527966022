import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";
import { Field, reduxForm } from "redux-form";

import Box from "@material-ui/core/Box";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";

import { RenderTextField } from "../subcomponents/form_subcomponents/renderTextField";

import { styles } from "../styles";

import LoadingDivContainer from "../subcomponents/loadingDiv";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: 20,
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

class FileNameForm extends Component {
  constructor(props) {
    super(props);

    this.props.reset();
  }

  fileForm = () => {
    const { classes } = this.props;
    return (
      <div>
        <Field
          name="fileName"
          component={RenderTextField}
          type="text"
          label="Enter file name"
        />
        <Button
          className={`${classes.button}`}
          variant="contained"
          type="submit"
          color="secondary">
          Save
        </Button>
        {/* <Button
          className={`${classes.button}`}
          variant="contained"
          type="button"
          color="secondary">
          Save to My Files
        </Button> */}
      </div>
    );
  };

  submitTeamModal = (values) => {
    this.props.onSubmitForm(values);
  };

  render() {
    const { handleSubmit } = this.props;

    return (
      <form
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit((values) => this.submitTeamModal(values))}>
        {this.fileForm()}
      </form>
    );
  }
}

function validate(values, props) {
  const errors = {};

  if (!values.fileName) {
    errors["fileName"] = "File name is required";
  }

  return errors;
}

const FileNameFormContainer = reduxForm({
  form: "EditedFileName",
  validate,
  enableReinitialize: true,
})(withStyles(styles)(FileNameForm));

class FileSaveModal extends Component {
  constructor(props) {
    super(props);
  }

  handleModalBody = () => {
    return <div>modal - body</div>;
  };

  render() {
    const { openTeamModal, handleClose, onSubmitForm, savingFile } = this.props;

    return (
      <Modal
        open={openTeamModal}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description">
        <Box sx={{ ...style, width: 400 }}>
          {!savingFile.loading ? (
            <div>
              <h2 id="parent-modal-title">Save Edited File!</h2>
              <FileNameFormContainer
                handleClose={handleClose}
                onSubmitForm={onSubmitForm}
              />
            </div>
          ) : (
            <div>
              {savingFile.message ? (
                <h2 id="parent-modal-title">{savingFile.message}</h2>
              ) : (
                <LoadingDivContainer type={"small"} />
              )}
            </div>
          )}
        </Box>
      </Modal>
    );
  }
}

export default withStyles(styles)(FileSaveModal);
