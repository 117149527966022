import axios from "axios";
import { ROOT_URL } from "./actions_constants";

export const FORGOT_PASSWORD = "forgot";
export const RESET_PASSWORD = "reset";

const forgotPasswordSuccess = result => ({
  type: FORGOT_PASSWORD,
  payload: result
});

export const forgotPassword = (values, callback) => (dispatch) => {

  if (values) {
    return (axios.post(`${ROOT_URL}users/forgot_password`, values))
      .then(result => {

        const data = result.data;

        dispatch(forgotPasswordSuccess(data));

        if(callback) {
          callback(result);
        }      

        return data;
      })
      .catch(error => {
        console.log(error)
        
        if (error.response && error.response.data) {
          callback(error.response.data)
        }
      })
  } else {
    dispatch(forgotPasswordSuccess(null));
  }
}

const resetPasswordSuccess = result => ({
  type: RESET_PASSWORD,
  payload: result
});

export const resetPassword = (token, values, callback) => (dispatch) => {

  if (token) {
    return (axios.post(`${ROOT_URL}users/reset_password/${token}`, values))
      .then(result => {

        const data = result.data;

        dispatch(resetPasswordSuccess(data));

        if(callback) {
          callback(result);
        }

        return data;
      })
      .catch(error => {
        console.log(error)
        
        if (error.response && error.response.data) {
          callback(error.response.data)
        }
      })
  } else {
    dispatch(resetPasswordSuccess(null));
  }
}
