import { FETCH_CONTACTS_RAW } from "../actions/actions_contacts";

export default function(state = [], action) {
  switch (action.type) {
    
    case FETCH_CONTACTS_RAW:
      if (!action.payload) {
        return state;
      }
      return action.payload;
    
    default:
      return state;
  }
}