import axios from "axios";
import { ROOT_URL } from "./actions_constants";

export const FETCH_TEAMS = "fetch_teams";
export const FETCH_TEAM = "fetch_team";
export const CREATE_TEAM = "create_team";
export const UPDATE_TEAM = "update_team";
export const DELETE_TEAM = "delete_team";

const fetchTeamsSuccess = teams => ({
  type: FETCH_TEAMS,
  payload: teams
});

export const fetchTeams = (token, companyId, callback) => (dispatch) => {

  const TOKEN = token;

  return (axios.get(`${ROOT_URL}company/teams/${companyId}`, 
    { 
      headers: { 
        Authorization: 'Bearer ' + TOKEN
      }
    }))
    .then(result => {
      const teams = result.data;

      dispatch(fetchTeamsSuccess(teams));

      if(callback) {
        callback(result);
      }
      
      return result;
    })
    .catch(error => {
      console.log(error)
      
      if (error.response && error.response.data) {
        callback(error.response.data)
      }
    })
}

const fetchTeamSuccess = team => ({
  type: FETCH_TEAM,
  payload: team
});

export const fetchTeam = (token, id, callback) => (dispatch) => {

  const TOKEN = token;

  return (axios.get(`${ROOT_URL}teams/${id}`,
    { 
      headers: { 
        Authorization: 'Bearer ' + TOKEN
      }
    }))
    .then(result => {
      const team = result.data;

      dispatch(fetchTeamSuccess(team));

      if(callback) {
        callback(result);
      }
      
      return result;
    })
    .catch(error => {
      console.log(error)
      
      if (error.response && error.response.data) {
        callback(error.response.data)
      }
    })

}

const createTeamSuccess = team => ({
  type: CREATE_TEAM,
  payload: team
});

export const createTeam = (token, values, callback) => (dispatch) => {

  const TOKEN = token;

  return (axios.post(`${ROOT_URL}teams`,
      values,
      { 
        headers: { 
          Authorization: 'Bearer ' + TOKEN
        }
      }))
    .then(result => {
      const team = result.data;

      dispatch(createTeamSuccess(team));

      if(callback) {
        callback(result);
      }

      return result;
    })
    .catch(error => {
      console.log(error)
      
      if (error.response && error.response.data) {
        callback(error.response.data)
      }
    })
}

const updateTeamSuccess = team => ({
  type: UPDATE_TEAM,
  payload: team
});

export const updateTeam = (token, values, id, callback) => (dispatch) => {

  const TOKEN = token;

  return (axios.put(`${ROOT_URL}teams/${id}`, 
    values,
    { 
      headers: { 
        Authorization: 'Bearer ' + TOKEN
      }
    }))
    .then(result => {
      const team = result.data;

      dispatch(updateTeamSuccess(team));

      if(callback) {
        callback(result);
      }
      
      return result;
    })
    .catch(error => {
      console.log(error)
      
      if (error.response && error.response.data) {
        callback(error.response.data)
      }
    })
}

const deleteTeamSuccess = team => ({
  type: DELETE_TEAM,
  payload: team
});

export const deleteTeam = (token, id, callback) => (dispatch) => {

  const TOKEN = token;

  return (axios.delete(`${ROOT_URL}teams/${id}`, 
    { 
      headers: { 
        Authorization: 'Bearer ' + TOKEN
      }
    }))
    .then(result => {
      const team = result.data;

      dispatch(deleteTeamSuccess(team));

      if(callback) {
        callback(result);
      }
      
      return result;
    })
    .catch(error => {
      console.log(error)
      
      if (error.response && error.response.data) {
        callback(error.response.data)
      }
    })
}
