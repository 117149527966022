import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';
import { styles } from '../styles';

import Typography from '@material-ui/core/Typography';

class LoadingDiv extends Component {
  constructor(props) {
    super(props);

    this.state = {
      wait: true
    }

    // to not show loading when it is a super quick refresh
    // this.timer = setTimeout(() => {
    //   this.setState({
    //     wait: false
    //   })
    // }, 100);
  }

  // componentWillUnmount() {
  //   clearTimeout(this.timer);
  // }

  render() {
    const { type, report, classes } = this.props;
    // const { wait } = this.state;

    let className = classes.loading,
        smallLoadingContainer = null,
        extraClass = null,
        fadeIn = null

    if (type === 'interactive-analytics') {
      className = classes.loadingSmall;
      smallLoadingContainer = classes.interactiveAnalyticsLoadingContainer;
    } else if (type === 'small' || type === 'smallWhite') {
      className = classes.loadingSmall;
      smallLoadingContainer = classes.smallLoadingContainer;
    } else if (type === 'noPadding') {
      className = classes.loadingSmall;
      smallLoadingContainer = classes.noPaddingLoadingContainer;
    } else if (type === 'pageLoading') {
      className = classes.loadingSmall
      extraClass = classes.pageLoading
      fadeIn = 'fade-in'
    }

    // if (wait) {
    //   return null;
    // } else {
      return (
        <div className={`${classes.loadingContainer} ${smallLoadingContainer} ${extraClass}`}>
          {report &&
            <Typography variant="h2" className={classes.loadingH2}>
              Generating Report
            </Typography>
          }
          {type !== 'smallWhite' &&
            <img className={`${className} ${fadeIn}`} src="/images/loading.svg" alt={''} />
          }
          {type === 'smallWhite' &&
            <img className={`${className} ${fadeIn}`} src="/images/loadingWhite.svg" alt={''} />
          }
        </div>
      )
    // }
  }

}

export default withStyles(styles)(LoadingDiv);