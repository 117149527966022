import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';
import { styles } from '../../styles';

import FormControl from '@material-ui/core/FormControl';

import InputChips from './tags/index';

import { validateName, cleanName } from '../../helpers/helperFunctions';
import { ErrorText } from './errorText'

class TagsField extends Component {
  constructor(props) {
    super(props);
    const { updateTags } = this.props;
    let { label, id } = this.props;

    if (!id) {
      id = "tags-input";
    }

    this.state = {
      id: id,
      selected: this.props.tags,
      error: null
    }

    updateTags(label, id, this.state.selected);
  }

  handleError = (error) => {
    const { handleTagError } = this.props;

    handleTagError(error);

    this.setState({ 
      error: error
    });
  }

  handleChange = (selected) => {
    const { label, handleTagError, updateTags } = this.props;
    const { id } = this.state;

    let check = new Set();
    const duplicate = selected.some(function(item) {
        return check.size === check.add(item.value).size;
    })

    let handleSelected = selected,
        length = handleSelected.length,
        newestItem = handleSelected.length > 0 ? handleSelected[length - 1].value : null

    if (handleSelected && length > 0) {
      handleSelected[length - 1].value = cleanName(handleSelected[length - 1].value)
      handleSelected[length - 1].label = handleSelected[length - 1].label.replace(/ /g, '')
    }

    const checkString = (handleSelected && length) ? validateName(newestItem) : {pass: true}

    if (duplicate) {
      this.setState({ error: "Tag already added"});
    } else if (checkString && !checkString.pass && (length === 1 || newestItem.length > 0)) {
      this.setState({ error: checkString.error})
    } else if (length > 1 && newestItem.length === 0) {
      return
    } else {
      this.setState({ 
        selected: handleSelected, 
        error: null
      });
      handleTagError(null);
      updateTags(label, id, handleSelected, null, true);
    }
  }

  render() {
    const { label, name, classes, className, allTags, type } = this.props;
    const { id } = this.state;

    let placeholder = 'Enter Tags'

    if (type === 'contacts') {
      placeholder = 'Enter Emails'
    } else if (type === 'links') {
      placeholder = 'Enter Links'
    }

    const inputProps = {
      id: id,
      name: name,
      placeholder: placeholder
    }

    let tagInputContainer = classes.tagInputContainer,
        tagsContainer = classes.tagsContainer,
        inputContainer = classes.inputContainer;

    if (type === 'contacts') {
      tagInputContainer += ` ${classes.tagContactInputContainer}`;
      tagsContainer += ` ${classes.tagsContactContainer}`;
      inputContainer += ` ${classes.inputContactContainer}`;
    }

    //DROP DOWN SELECTION
    // const customOptionsClasses = function() {
    //   return {
    //     optionsContainer: {
    //       border: '3px solid red !important',
    //     },
    //     menuItemRoot: {
    //       color: 'green',
    //     },
    //     menuItemSelected: {
    //       border: '3px solid blue !important',
    //     }
    //   };
    // }

    // You can only override one of the following: container,formControl,inputContainer,chipsWrapper,chips,chipFocus,chip,error,errorText,chipWrapper,input,disabled,inkbar,focused,underline

    return (
      <FormControl className={className} margin="normal">
        <InputChips
          id={id}
          type={type}
          label={label}
          selected={this.state.selected}
          onChange={(selected) => this.handleChange(selected)}
          updateError={(error) => this.handleError(error)}
          inputProps={inputProps}
          classes={{formControl: tagInputContainer, chips: tagsContainer, inputContainer: inputContainer, chip: classes.tag}}
          options={allTags}
          openOnFocus
          // fixedOptions
          //DROP DOWN SELECTION
          // optionsClasses={customOptionsClasses}
        />
        <ErrorText touched={this.state.error} error={this.state.error} />
      </FormControl>
    )
  }
}

export default withStyles(styles)(TagsField)