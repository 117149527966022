import _ from "lodash";
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Field, reduxForm } from "redux-form";
import ReactPlayer from 'react-player';

import { withStyles } from '@material-ui/core/styles';
import { styles } from '../styles';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';

import { ErrorText } from '../subcomponents/form_subcomponents/errorText';
import { RenderTextField } from '../subcomponents/form_subcomponents/renderTextField';
import { RenderRadioGroup } from '../subcomponents/form_subcomponents/renderRadioGroup';

import { activeCampaignEvent } from '../helpers/helperFunctions';

import { updateCompany, updateUser } from '../../actions/actions_index';

class OnboardingCompanyForm extends Component {
	constructor(props) {
    super(props);

    this.state = {
      error: null
    }

    this.props.reset();
  }

  componentDidMount() {
    const { token, enterprise, currentUser, history, master_admin } = this.props;

    const values = {
      token,
      master_admin,
      enterprise,
      email: currentUser.email,
      page: history.location.pathname
    }

    activeCampaignEvent(values)
  }

  proceed = () => {
    const { token, companyId, updateCompany, history } = this.props

    updateCompany(token, {onboarding_step: 'content_groups'}, companyId, () => {
      history.push('/onboarding/content_groups');
    })
  }

  // submitOnboardingCompany = (values) => {
  //   const { token, companyId, owner, currentUser, updateCompany, updateUser, history } = this.props;
  //   const { name, first_name, last_name } = values

  //   updateCompany(token, {name}, companyId, () => {
      
  //     let status = currentUser.active_campaign_status;

  //     if (status <= '2') {
  //       status = '3';
  //     }

  //     updateUser(token, {active_campaign_status: status, first_name, last_name, create_live_account: true}, owner, () => {
  //       updateCompany(token, {onboarding_step: 'content_groups'}, companyId, () => {
  //         history.push('/onboarding/content_groups');
  //       });
  //     })
  //   });
  // }

  render() {
  	const { handleSubmit, pristine, invalid, classes } = this.props;
    const { error } = this.state;

    const radioOptions = [ "1 - 5", "6 - 10", "11 - 20", "21 - 50", "51 - 100", "100+"];

  	return (
      <div className={classes.marginTop}>
        <Typography variant="h1" className={classes.onboardingH1}>
          <div className={classes.onboardingStepNumber}>2</div>Account
        </Typography>
        <Grid container spacing={4} className={classes.gridExtraSpacing} justify="center" alignItems="center">
          <Grid item sm={3} xs={false}>
          </Grid>
          <Grid item sm={6} xs={12}>
            <Typography variant="subtitle1" className={classes.onboardingSteps}>
              Step <span className={classes.onboardingStepsBold}>2</span> of 6
            </Typography>
            <LinearProgress color="secondary" variant="determinate" value={33} />
          </Grid>
          <Grid item sm={3} xs={false}>
          </Grid>
        </Grid>
        <Grid className={`${classes.gridTagsSpacing}`} container spacing={4} justify="center" alignItems="center">
          <Grid item md={6} xs={12}>
            {/*<div className={classes.onboardingImageContainer}>
              <img className={classes.onboardingImage} src="/images/verb_onboarding/company.png" />
            </div>*/}
            <div className={classes.reactPlayerWrapper}> 
              <ReactPlayer 
                className={classes.reactPlayer}
                width='100%'
                height='100%'
                url="https://vimeo.com/479659568" 
                controls={false}
                muted={true} 
                playing={true}
                loop={true}
              />
            </div>
          </Grid>
          <Grid item md={6} xs={12} className={classes.onboardingGridLeftTextArea}>
            <Typography variant="h2" className={`${classes.onboardingH2}`}>
              Time to set up your account.
            </Typography>
            <br/>
            <Typography variant="h4">
              1. Add Content Groups<br/>
              2. Add Users<br/>
              3. Add Brand<br/>
              4. Distribute Content<br/>
              5. See Activity<br/>
            </Typography>
            {/*<form className={classes.formNoMaxHeight} noValidate autoComplete="off" onSubmit={handleSubmit(values => this.submitOnboardingCompany(values))}>
              <ErrorText className={classes.bigError} touched={true} error={error} />
              <Field className={classes.onboardingTextfield} name="name" component={RenderTextField} type="text" label="Company Name" />
              <Field className={classes.onboardingTextfield} name="first_name" component={RenderTextField} type="text" label="First Name" />
              <Field className={classes.onboardingTextfield} name="last_name" component={RenderTextField} type="text" label="Last Name" />
              <br /><br />
              <div>
                <Typography variant="subtitle1" className={classes.onboardingSubtitle}>
                  How many people do you plan to work with?
                </Typography>
                <Field type="radio" name="people" options={radioOptions} classes={classes} component={RenderRadioGroup} />
              </div>
              <Button className={`${classes.button} ${classes.onboardingButton}`} variant="contained" color="secondary" type="submit" disabled={pristine || invalid}>Continue</Button>
            </form>*/}
            <br />
            <br />
            <div className={classes.onboardingButtonContainer}>
              <Button className={`${classes.button} ${classes.onboardingButton} ${classes.onboardingCompletedButton}`} variant="contained" color="secondary" onClick={this.proceed}>Get Started</Button>
            </div>
          </Grid>
        </Grid> 
      </div>
    )
  }
}

function validate(values, props) {
  const errors = {};
  const requiredFields = [
    'name', 'first_name', 'last_name', 'people'
  ];
  
  if (!_.isEmpty(values)) {
    requiredFields.forEach(field => {
      if (!values[field]) {
        errors[field] = 'Required';
      }
    });
  }

  return errors;
}

function mapStateToProps(state) {
  if (_.isEmpty(state.login.token) || _.isEmpty(state.login.company) || _.isEmpty(state.login.user)) {
    return {
      loggedOut: true
    }
  }

  return { 
    token: state.login.token,
    master_admin: state.login.master_admin,
    companyId: state.login.company._id,
    enterprise: state.login.company.enterprise,
    owner: state.login.user._id,
    currentUser: state.login.user
  };
}

const mapDispatchToProps = dispatch => ({
	updateCompany: (token, values, id, callback) => dispatch(updateCompany(token, values, id, callback)),
  updateUser: (token, values, id, callback) => dispatch(updateUser(token, values, id, callback))
});

export const OnboardingCompany =
compose(
  reduxForm({
    form: 'OnboardingCompany',
    validate,
    enableReinitialize: true
  }),
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(OnboardingCompanyForm)
