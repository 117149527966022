import _ from "lodash";
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter, NavLink, Link } from "react-router-dom";

import { withStyles } from '@material-ui/core/styles';
import { styles } from '../styles';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';

import Close from '@material-ui/icons/Close';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Share from '@material-ui/icons/Share';
import OfflineBoltIcon from '@material-ui/icons/OfflineBolt';

import WithLoading from '../subcomponents/withLoading';
import LoadingDivContainer from '../subcomponents/loadingDiv';
import GridContainer from '../subcomponents/gridContainer';
import ShareCartModalContainer from '../modals/shareCartModal';
import InteractiveModalContainer from '../modals/interactiveModal';
import GoLiveModalContainer from '../modals/goLiveModal';

import { libraryTableHeight, checkAlerts, getLiveStreamUrl, getAttendeeLiveStreamUrl, MDandAbove, SMandBelow, handleAppUser, appProfileSettings } from '../helpers/helperFunctions';
import { multipleFilter, tagsFilter, libraryNameSort } from '../helpers/gridLibraryHelpers';

import { fetchUser, fetchLibraryItems, shareLibraryItems, goLive } from '../../actions/actions_index';

import { QUICK_GO_LIVE, QUICK_INTERACTIVE, QUICK_CREATE_LINK } from '../header';

class ShareCart extends Component {
	constructor(props) {
		super(props)

		const { selectedAppProfile } = this.props,
					{ appUserInteractiveVideos, appUserVerbLive } = appProfileSettings(selectedAppProfile)

		this.state = {
			sorting: null,
			searchValue: null,
			openShareModal: false,
			openInteractiveModal: false,
			openGoLiveModal: false,
			liveStreamUrl: getAttendeeLiveStreamUrl(),
			loadingLive: false,
	    liveUrl: null,
	    liveStreamId: null,
	    anchor: false,
	    copied: false,
	    showcasesInCart: [],
	    showcasesForList: [],
	    appUserInteractiveVideos,
	    appUserVerbLive
		}

	}

	componentDidMount() {
	  const { itemsToShare } = this.props
	  const { quick } = this.props.match.params;
	  this.quickGo(quick);

	  this.findShowcases(itemsToShare)
	}

	componentWillReceiveProps(newProps) {
	  const { quick } = newProps.match.params;
	  this.quickGo(quick);
	}

	quickGo = (quick) => {
	  switch(quick) {
	  	case QUICK_GO_LIVE:
	  		this.setState({
	  		  openGoLiveModal: true
	  		})
	  		break

	  	case QUICK_INTERACTIVE:
	  		this.setState({
	  		  openInteractiveModal: true
	  		})
	  		break

	  	case QUICK_CREATE_LINK:
	  		this.setState({
	  		  openShareModal: true
	  		})
	  		break

	  	default:
	  		break
	  }
	}

	handleGoLiveSubmit = () => {
		this.setState({
			loadingLive: true
		})

		const { token, owner, owner_email, libraryItemsInCart, goLive, getUser, selectedAppProfile } = this.props;

		const values = {
		  appProfileId: selectedAppProfile ? selectedAppProfile._id : null,
		  userId: owner,
		  email: owner_email,
		  media: libraryItemsInCart
		}

		goLive(token, values, data => {
		  getUser()

		  this.setState({
		    loadingLive: false,
		    liveUrl: data.data.liveUrl,
		    liveStreamId: data.data.liveStreamId
		  })
		})
	}

	// handleGoLive = () => {
	//   this.setState({
	//     openGoLiveModal: true
	//   });
	// }

	handleSearchValue = (searchValue) => {
	  this.setState({
	    searchValue
	  })
	}

	removeFromShareCart = (event) => {
	  const { loadData, itemsToShare, shareLibraryItems } = this.props
	  const index = event[0]
	  const newShareItems = [...itemsToShare]

	  newShareItems.splice(index, 1)

	  shareLibraryItems(newShareItems, () => {
	  	loadData(newShareItems)
	  	this.findShowcases(newShareItems)
	  })
	}

	clearShareQueue = () => {
		const { loadData, shareLibraryItems } = this.props

		shareLibraryItems([], () => {
	    loadData([])
	    this.setState({
	    	anchor: false
	    })
	  })
	}

	openGoLiveModalSwitch = () => {
		const { history } = this.props

		this.setState({
			openGoLiveModal: !this.state.openGoLiveModal,
			anchor: false
		})

		history.push('/share_cart')
	}

	openShareModalSwitch = () => {
		const { history } = this.props

		this.setState({
			openShareModal: !this.state.openShareModal,
			anchor: false
		})

	  history.push('/share_cart');
	}

	openInteractiveModalSwitch = () => {
		const { history } = this.props
		const { openInteractiveModal } = this.state

		this.setState({
			openInteractiveModal: !this.state.openInteractiveModal,
			anchor: false
		})

		history.push('/share_cart');
	}

	handleOpenGoLiveModal = () => {
		const { libraryItemsInCart } = this.props
    const { openGoLiveModal, loadingLive, liveUrl, liveStreamId, copied } = this.state

    if (openGoLiveModal) {
      return (
        <GoLiveModalContainer
          openGoLiveModal={openGoLiveModal}
          handleGoLiveSubmit={this.handleGoLiveSubmit}
          loadingLive={loadingLive}
          handleCopyLivestreamUrl={this.handleCopyLivestreamUrl}
          liveUrl={liveUrl}
          copied={copied}
          sharedItems={libraryItemsInCart}
          closedGoLiveModal={this.openGoLiveModalSwitch}
        />
      )
    }
  }

	handleOpenShareModal = () => {
    const { libraryItemsInCart } = this.props
    const { openShareModal, showcasesInCart } = this.state

    const sharedItems = { libraryItemsInCart, showcasesInCart }

    if (openShareModal && (libraryItemsInCart.length > 0 || showcasesInCart.length > 0)) {
      return (
        <ShareCartModalContainer
          openShareModal={openShareModal}
          sharedItems={sharedItems}
          closedShareModal={this.openShareModalSwitch}
        />
      )
    }
  }

  handleOpenInteractiveModal = () => {
    const { loadData, libraryItemsInCart } = this.props
    const { openInteractiveModal } = this.state

    if (openInteractiveModal) {
      return (
        <InteractiveModalContainer
          openInteractiveModal={openInteractiveModal}
          sharedItems={libraryItemsInCart}
          reloadShareCart={loadData}
          closedInteractiveModal={this.openInteractiveModalSwitch}
        />
      )
    }
  }

  copyLink = (link) => {
    navigator.clipboard.writeText(link)

    this.setState({
    	copied: true
    })
  }

  handleCopyLivestreamUrl = () => {
  	const { live_stream_id, classes } = this.props
  	const { liveStreamId, liveStreamUrl, copied } = this.state

  	const handleLivestreamId = live_stream_id ? live_stream_id : liveStreamId

  	return (
		  <div className={classes.livestreamContainer}>
	  	  <Typography variant="h5" className={classes.livestreamH5}>
	  	  	<Button className={`${classes.copyLivestreamLink}`} variant="contained" color="secondary" onClick={() => this.copyLink(`${liveStreamUrl}${handleLivestreamId}`)}>
	  	  		Copy Link
	  	  	</Button>
	  	  	{copied && <span style={{paddingLeft: 5, fontSize: 12}}>Copied</span>}
	  	  </Typography>
	  	  <Typography variant="body1" style={{margin: '10px 0 10px 0'}}>Share Livestream URL:</Typography>
	  	  <Typography variant="body1" className={classes.livestreamLink}>{liveStreamUrl}{handleLivestreamId}</Typography>
		  </div>
	  )
  }

  leftSide = ({live_only, live_stream_id, loadingLive, appUserInteractiveVideos, appUserVerbLive, classes}) => (
  	<React.Fragment>
    	<React.Fragment>
	    	{/*<Typography 
	    		variant="h5"
	    		component={NavLink}
	    		to={'/content/library'}
	    		className={classes.returnToLibrary}
	    	>
	    	  Return to All Content
	    	</Typography>*/}
	    	<Typography variant="h3" className={`${classes.contentGroupsHeading} ${classes.shareHeading}`}>
	    	  Share Content
	    	</Typography>
	    	<List dense> 
	  	    <ListItem 
	  	      id="create-link"
	  	      key="create-link"
	  	      button 
	  	      onClick={this.openShareModalSwitch}
	  	      disableRipple={true}
	  	      focusRipple={true}
	  	    >
	  	      <ListItemIcon>
	  	        {/*<img style={{width: 40, height: 40}} src="/images/go_link.png" alt={''} />*/}
	  	        <Share style={{backgroundColor: '#011627', borderRadius: 50, color: '#FFFFFF', width: 41, height: 41, padding: 6}} />
	  	      </ListItemIcon>
	  	      <ListItemText 
	  	        primary="Create Content Link(s)"
	  	      />
	  	    </ListItem>
	  	    {appUserInteractiveVideos &&
		  	    <ListItem 
		  	      id="create-video"
		  	      key="create-video"
		  	      button
		  	      // component={Link}
		  	      // to="/content/interactive_videos"
		  	      onClick={this.openInteractiveModalSwitch}
		  	      disableRipple={true}
		  	      focusRipple={true}
		  	      style={{position: 'relative', left: -5}}
		  	    >
		  	      <ListItemIcon>
		  	        {/*<img style={{width: 40, height: 40}} src="/images/go_link.png" alt={''} />*/}
		  	        <OfflineBoltIcon style={{color: '#3B82F6', width: 50, height: 50}} />
		  	      </ListItemIcon>
		  	      <ListItemText 
		  	        style={{position: 'relative', left: -5}}
		  	        primary="Create Interactive Video"
		  	      />
		  	    </ListItem>
	  	  	}
	  	  </List>
	  	</React.Fragment>
  	  {appUserVerbLive &&	
	  	  <React.Fragment>
		  	  <Typography variant="h3" className={`${classes.contentGroupsHeading} ${classes.shareHeading} ${classes.shareHeading2}`}>
		  	    Livestream
		  	  </Typography>
		  	  <List dense> 
		  	    <ListItem 
		  	      id="go-live"
		  	      key="go-live"
		  	      button 
		  	      onClick={this.openGoLiveModalSwitch}
		  	      disableRipple={true}
		  	      focusRipple={true}
		  	    >
		  	      <ListItemIcon>
		  	        <img style={{width: 40, height: 40}} src="/images/go_live.png" alt={''} />
		  	      </ListItemIcon>
		  	      <ListItemText 
		  	        primary="Go Live"
		  	      />
		  	    </ListItem>
		  	  </List>
			    {!live_stream_id &&
		  	    <div className={classes.generateLivestreamContainer}>
		    	    <Button className={`${classes.copyLivestreamLink} ${classes.generateLivestreamLink}`} variant="contained" color="secondary" onClick={this.handleGoLiveSubmit} disabled={loadingLive}>
		    	  		Get Live Share Link
		    	  	</Button>
		    	  	{loadingLive &&
		    	  		<img className={classes.livestreamLoading} src="/images/loading.svg" alt="" />
		    	  	}
		  	  	</div>
			  	}
		  	  {live_stream_id &&
		    	  this.handleCopyLivestreamUrl()
		  		}
	  		</React.Fragment>
  		}
  	  <Typography variant="h3" className={`${classes.contentGroupsHeading} ${classes.shareHeadingLine}`}>
  	  </Typography>
    	<List dense> 
  	    <ListItem 
  	      id="clear-share-queue"
  	      key="clear-share-queue"
  	      button 
  	      onClick={this.clearShareQueue}
  	      disableRipple={true}
  	      focusRipple={true}
  	    >
  	      <ListItemIcon>
  	        <img style={{width: 40, height: 40}} src="/images/clear_share_queue.png" alt={''} />
  	      </ListItemIcon>
  	      <ListItemText 
  	        primary="Clear Share Queue"
  	      />
  	    </ListItem>
  	  </List>
  	</React.Fragment>
  )

  toggleDrawer = (open) => (event) => {
    this.setState({anchor: open})
  }

  findShowcases = (shareItems) => {
  	const { showcases } = this.props

  	const showcasesInCartWithNull = shareItems.map(item => {
  		const isShowcase = showcases.filter(showcase => item === showcase._id)
  		return isShowcase.length > 0 ? isShowcase[0] : null
  	})

  	//remove any nulls
  	const showcasesInCart = showcasesInCartWithNull.filter(s=>s)

  	const showcasesForList = showcasesInCart.map(showcase => {
  		const { _id, branding, name, updatedAt } = showcase

  		return {
  			_id,
  			thumbnail: branding.branding_background_image,
  			file_type: 'showcase',
  			filename: {name, filename: '', tags: '' },
  			rating: '',
  			search_tags: '',
  			file_size: 'n/a',
  			updatedAt
  		}
  	})

  	this.setState({showcasesInCart, showcasesForList})
  }

	render() {
	  const { company, enterprise, live_only, verb_live, appUser, all_content_group, alerts, owner, classes, libraryItemsInCart, live_stream_id } = this.props;
	  const { sorting, searchValue, loadingLive, liveStreamUrl, anchor, showcasesInCart, showcasesForList, appUserInteractiveVideos, appUserVerbLive } = this.state;
	  const alertsRoot = checkAlerts(company, alerts, classes);

	  let tableType = 'large',
	      rootExtendedHeader = null,
	      thumbnailWidth = 90,
	      liveTeam = 'liveTeam',
	      leftBarClass = classes.leftBarDrawer

	  const mobile = /iPad|iPhone|iPod|Android/.test(navigator.userAgent) && !window.MSStream;

	  if (enterprise && !appUser) {
	    rootExtendedHeader = classes.rootExtendedHeader;
	  }

	  if (!enterprise) {
      rootExtendedHeader = classes.rootTeamsHeader;
    }

	  if (mobile) {
	    thumbnailWidth = 70;
	  }

	  let showSelectAll = false,
	      showSelectionColumn = false;

	  const shareLibraryItems = Array.isArray(libraryItemsInCart) ? libraryItemsInCart : [];

	  const rows = (showcasesForList.length > 0) ? shareLibraryItems.concat(showcasesForList) : shareLibraryItems

	  const columns = [
	    { name: 'share', title: 'Share', getCellValue: row => row._id },
	    { name: 'thumbnail', title: ' ' },
	    { name: 'file_type', title: 'Type'},
	    { name: 'filename', title: 'Name / Filename / Tags' },
	    { name: 'rating', title: 'Rating' },
	    { name: 'search_tags', title: '' },
	    { name: 'file_size', title: 'Size' },
	    { name: 'updatedAt', title: 'Last Updated'}
	  ];

	  const tableColumnExtensions = [
	    { columnName: 'share', width: 90 },
	    { columnName: 'thumbnail', width: thumbnailWidth },
	    { columnName: 'file_type', width: 70 },
	    { columnName: 'filename', width: 250 },
	    { columnName: 'rating', width: 80 },
	    { columnName: 'search_tags', width: 1 },
	    { columnName: 'file_size', width: 100, },
	    { columnName: 'updatedAt', width: 120 }
	  ]

	  let sortingStateColumnExtensions = [
	    { columnName: 'share', sortingEnabled: false },
	    { columnName: 'thumbnail', sortingEnabled: false },
	    { columnName: 'file_type', sortingEnabled: true },
	    { columnName: 'filename', sortingEnabled: true },
	    { columnName: 'rating', sortingEnabled: true },
	    { columnName: 'search_tags', sortingEnabled: false },
	    { columnName: 'file_size', sortingEnabled: true },
	    { columnName: 'updatedAt', sortingEnabled: true }
	  ]

	  let filteringStateColumnExtensions = [
	    { columnName: 'share', filteringEnabled: false },
	    { columnName: 'thumbnail', filteringEnabled: false },
	    { columnName: 'file_type', filteringEnabled: true },
	    { columnName: 'filename', filteringEnabled: false },
	    { columnName: 'rating', filteringEnabled: true },
	    { columnName: 'search_tags', filteringEnabled: false },
	    { columnName: 'file_size', filteringEnabled: true },
	    { columnName: 'updatedAt', filteringEnabled: true }
	  ]

	  const integratedFilteringColumnExtensions = [
	    { columnName: 'filename', predicate: multipleFilter },
	    { columnName: 'search_tags', predicate: tagsFilter }
	  ]

	  const integratedSortingColumnExtensions = [
	    { columnName: 'filename', compare: libraryNameSort }
	  ]

	  // if (!enterprise && verb_live) {
	  //   columns.splice(0, 0, )
	  //   tableColumnExtensions.splice(0, 0, )
	  // }

	  // if (!enterprise && verb_live) {
	  //   sortingStateColumnExtensions.splice(0, 0, )
	  //   filteringStateColumnExtensions.splice(0, 0, )
	  // }

	  if (live_only) {
	  	rootExtendedHeader = classes.rootTeamsHeaderLiveOnly;
	  	leftBarClass = classes.leftBarDrawerLiveOnly
      liveTeam = 'liveOnly'
    }

	  return (
	    <div className={`${classes.root} ${alertsRoot} ${rootExtendedHeader}`}>
	      <Grid container spacing={4} className={`${classes.mainRightGrid} ${live_only ? classes.mainRightGridShareCartLiveOnly : null} ${appUser ? classes.mainRightGridShareCartAppUser : null}`}>

	      	<SMandBelow>
	      	  <Drawer anchor={'left'} open={anchor} onClose={this.toggleDrawer(false)} classes={{root: classes.menuDrawerContainer, paper: leftBarClass}}>
	      	    <IconButton 
	      	      className={classes.rightBarCloseIcon}
	      	      onClick={this.toggleDrawer(false)}
	      	    >
	      	      <ChevronLeft />
	      	    </IconButton>
	      	    {this.leftSide({live_only, live_stream_id, loadingLive, appUserInteractiveVideos, appUserVerbLive, classes})}
	      	  </Drawer>
	      	  <Grid item sm={1} className={`${classes.gridRightBorder} ${classes.leftBarArea}`} style={{marginBottom: 15}}>
	      	    <IconButton 
	      	      onClick={this.toggleDrawer(true)}
	      	      className={classes.leftBarOpenIcon}
	      	    >
	      	      <ChevronRight />
	      	      <Typography variant="h6" className={classes.verticalDrawerName}>{live_only ? 'Go Live Options' : 'Share Options'}</Typography>
	      	    </IconButton>
	      	  </Grid>
	      	</SMandBelow>

	      	<MDandAbove>
	      	  <Grid item xs={3} className={`${classes.gridRightBorder}`}>
	      	    {this.leftSide({live_only, live_stream_id, loadingLive, appUserInteractiveVideos, appUserVerbLive, classes})}
	      	  </Grid>
	      	</MDandAbove>

	        <Grid item md={9} sm={11} className={`${classes.gridPaddingLeft} ${classes.rightBarArea}`}>
	          
	         {this.handleOpenShareModal()}
	         {this.handleOpenInteractiveModal()}
	         {this.handleOpenGoLiveModal()}
	          
	          <div style={{position: 'relative', height: '100%'}}>
	            <GridContainer 
	              tableType={tableType}
	              menuType={'share-cart'}
	              gridHeight={libraryTableHeight(liveTeam)}
	              rows={rows}
	              columns={columns}
	              tableColumnExtensions={tableColumnExtensions}
	              sortingStateColumnExtensions={sortingStateColumnExtensions}
	              sorting={sorting}
	              handleSortingChange={this.handleSortingChange}
	              filteringStateColumnExtensions={filteringStateColumnExtensions}
	              integratedFilteringColumnExtensions={integratedFilteringColumnExtensions}
	              integratedSortingColumnExtensions={integratedSortingColumnExtensions}
	              showRowDetail={false}
	              classes={classes}
	              handleMenu={this.handleLibraryItemMenu}
	              menuType={"share"}
	              containerExtraClass={classes.plusButtonGrid}
	              // sortable={true}
	              handleSearchValue={this.handleSearchValue}
	              // bulkEdit={this.turnOnBulkEdit}
	              alternateSelection={this.removeFromShareCart}
	              // onSelectionChange={this.removeFromShareCart}
	              // selection={selectedItems}
	              showSelectAll={false}
	              showSelectionColumn={false}
	            />
	          </div>
	        </Grid>
	      </Grid>
	    </div>
	  )
	}
}

const ShareCartWithLoading = WithLoading(ShareCart)

class ShareCartContainer extends Component {
	state = {
		loadingUser: true,
		loadingItems: true
	}

	getUser = () => {
		const { token, owner } = this.props

		this.props.fetchUser(token, owner, user => {
      this.setState({
        loadingUser: false
      })
    })
	}

	loadData = (handleItemsToShare) => {
		const { token, itemsToShare, fetchLibraryItems } = this.props

		this.getUser()

		const newItemsToShare = handleItemsToShare ? handleItemsToShare : itemsToShare

		fetchLibraryItems(token, newItemsToShare, data => { 
			this.setState({
				loadingItems: false
			})
		})
	}

	componentDidMount() {
		this.loadData()
	}
	
	render() {
	  const { loadingUser, loadingItems } = this.state;

	  return (
	    <ShareCartWithLoading 
	      isLoading={loadingUser || loadingItems}
	      loadData={this.loadData}
	      getUser={this.getUser}
	      {...this.props}
	    />
	  )
	}
}

function mapStateToProps(state) {
  if (_.isEmpty(state.login.token) || _.isEmpty(state.login.company) || _.isEmpty(state.login.user)) {
    return {
      loggedOut: true
    }
  }

  return { 
    token: state.login.token,
    master_admin: state.login.master_admin,
    companyId: state.login.company._id,
    company: state.company,
    enterprise: state.login.user.user_type > '1' ? false : state.login.company.enterprise,
    selectedAppProfile: state.appProfile,
    live_only: state.login.company.live_only ? state.login.company.live_only : state.login.user.user_type > '1',
    appUser: handleAppUser(state),
    verb_live: state.company.verb_live,
    alerts: state.alerts,
    owner: state.login.user._id,
    owner_email: state.login.user.email,
    currentRole: state.login.user.user_type,
    currentUser: state.login.user,
    live_stream_id: state.user.live_stream_id,
    itemsToShare: state.itemsToShare,
    libraryItemsInCart: state.fetchLibraryItems,
    showcases: state.showcases
  };
}

const mapDispatchToProps = dispatch => ({
	fetchUser: (token, id, callback) => dispatch(fetchUser(token, id, callback)),
  fetchLibraryItems: (token, values, callback) => dispatch(fetchLibraryItems(token, values, callback)),
  shareLibraryItems: (items, callback) => dispatch(shareLibraryItems(items, callback)),
  goLive: (token, values, callback) => dispatch(goLive(token, values, callback))
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(ShareCartContainer)