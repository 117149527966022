import React, { Component } from 'react';

// import { ListItemIcon, Menu, MenuItem } from '@material-ui/core';
// import { Edit, ArrowUpward, ArrowDownward, LibraryAdd, AddCircle, Delete } from '@material-ui/icons';

import ListItemIcon from '@material-ui/core/ListItemIcon';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import Edit from '@material-ui/icons/Edit';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import LibraryAdd from '@material-ui/icons/LibraryAdd';
import AddCircle from '@material-ui/icons/AddCircle';
import Delete from '@material-ui/icons/Delete';

class LibraryGroupMenu extends Component {
  render() {
    const { appUser, anchorEl, open, onClose, className } = this.props;
    // console.log(this.props);
    const level = anchorEl.getAttribute('level');
    return (
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
        className={className}
      >
        <MenuItem key='edit' onClick={() => onClose('edit', null, true)}>
          <ListItemIcon>
            <Edit />
          </ListItemIcon>
          Edit
        </MenuItem>
        {!appUser && level != 0 && (
          <MenuItem key='assign' onClick={() => onClose('add')}>
            <ListItemIcon>
              <LibraryAdd />
            </ListItemIcon>
            Assign Content from Library
          </MenuItem>
        )}

        {!appUser && [
          <MenuItem key='add' onClick={() => onClose('add_group')}>
            <ListItemIcon>
              <AddCircle />
            </ListItemIcon>
            Add New Sub Content Group
          </MenuItem>,
          <MenuItem key='up' onClick={() => onClose('move_up')}>
            <ListItemIcon>
              <ArrowUpward />
            </ListItemIcon>
            Move Up
          </MenuItem>,
          <MenuItem key='down' onClick={() => onClose('move_down')}>
            <ListItemIcon>
              <ArrowDownward />
            </ListItemIcon>
            Move Down
          </MenuItem>,
        ]}

        <MenuItem key='delete' onClick={() => onClose('delete')}>
          <ListItemIcon>
            <Delete />
          </ListItemIcon>
          Delete from System
        </MenuItem>
      </Menu>
    );
  }
}

export default LibraryGroupMenu;
