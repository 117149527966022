import {
    CHANGE_BACK_TO_MASTER_ADMIN,
    CHANGE_COMPANY,
    LOGIN,
    LOGOUT,
    USE_SSO,
    VERIFY_ROLE
} from "../actions/actions_login";

export default function (state = {}, action) {
    switch (action.type) {
        case LOGIN:
            const version = process.env.REACT_APP_VERSION;

            if (!action.payload) {
                return ({
                    error: null,
                    token: null
                })
            }
            return {
                ...action.payload,
                version
            };

        case USE_SSO:
            if (!action.payload) {
                return {
                    ...state,
                    sso: null
                }
            }
            return {
                ...state,
                sso: action.payload
            }

        case CHANGE_COMPANY:
            return {
                ...state,
                company: action.payload.company,
                user: action.payload.user,
                master_admin: {
                    company: state.company,
                    user: state.user
                }
            }

        case CHANGE_BACK_TO_MASTER_ADMIN:
            const master_admin = state.master_admin;

            return {
                token: state.token,
                version: state.version,
                company: master_admin.company,
                user: master_admin.user
            }

        case LOGOUT:
            return {};

        case VERIFY_ROLE:
            if (!action.payload) {
                return state;
            }
            return {
                ...state,
                user: action.payload
            }

        default:
            return state;
    }
}