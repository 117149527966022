import _ from "lodash";
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FieldArray, Field, reduxForm } from "redux-form";
import ReactPlayer from 'react-player';

import { withStyles } from '@material-ui/core/styles';
import { styles } from '../styles';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';

import AddCircle from '@material-ui/icons/AddCircle';

import { ErrorText } from '../subcomponents/form_subcomponents/errorText';
import { RenderTextField } from '../subcomponents/form_subcomponents/renderTextField';

import { validateEmail } from '../helpers/helperFunctions';

import { createOnboardingUsers, updateCompany } from '../../actions/actions_index';

class OnboardingUsersForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null
    }

    this.props.reset();
  }

  proceed = () => {
    const { token, companyId, history, updateCompany } = this.props;

    updateCompany(token, {onboarding_step: 'brand'}, companyId, () => {
      history.push('/onboarding/brand');
    });
  }

  closedUsers = () => {
    this.proceed();
  }

  submitOnboardingUsers = (values) => {
    const { token, companyId, enterprise, owner, createOnboardingUsers } = this.props;

    values.company = companyId
    values.enterprise = enterprise
    values.owners = [owner]

    createOnboardingUsers(token, values, companyId, data => {
      if (data.data.errors) {
        this.setState({
          error: "One or more of these email addresses is already in use."
        })
      } else {
        this.proceed();
      }
    });
  }

  renderUsers = ({ fields }) => {
    const { classes } = this.props;

    return (
      <div>
        {
          fields.map((user, i) => (
            <Grid container spacing={0} key={i}>
              <Grid item md={9} xs={12}>
                <Field className={classes.onboardingTextfield} name={`${user}.email`} component={RenderTextField} type="email" label="User Email" errorClassName={classes.onboardingUserError} />
              </Grid>
              {/*<Grid item xs={3}>
                <Field className={classes.onboardingAdminCheckbox} type="checkbox" name={`${user}.admin`} label="Admin?" component={RenderCheckbox}  />
              </Grid>*/}
            </Grid>
          ))
        }
        <div className={classes.onboardingContentGroupsCreateContainer}>
          <Button 
            className={`${classes.addButton} ${classes.onboardingAdd}`}
            onClick={() => fields.push({})}
            focusRipple={false}
          >
            <AddCircle className={classes.addCircleIcon} />
            Add more users?
          </Button>
          <Typography variant="subtitle1" className={`${classes.onboardingSubtitle} ${classes.onboardingContentGroupsExtraText}`}>
            <i>...You can add more later.</i>
          </Typography>
        </div>
      </div>
    )
  }

  render() {
    const { handleSubmit, pristine, invalid, classes } = this.props;
    const { error } = this.state;

    return (
      <div className={classes.marginTop}>
        <Typography variant="h1" className={classes.onboardingH1}>
          <span className={classes.onboardingStepNumber}>4</span>Users
        </Typography>
        <Grid container spacing={4} className={classes.gridExtraSpacing}>
          <Grid item sm={3} xs={false}>
          </Grid>
          <Grid item sm={6} xs={12}>
            <Typography variant="subtitle1" className={classes.onboardingSteps}>
              Step <span className={classes.onboardingStepsBold}>4</span> of 6
            </Typography>
            <LinearProgress color="secondary" variant="determinate" value={68} />
          </Grid>
          <Grid item sm={3} xs={false}>
          </Grid>
        </Grid>
        <Grid className={`${classes.onboardingBackgroundReverseContainer} ${classes.gridTagsSpacing}`} container spacing={4}>
          <Grid item md={6} xs={12} className={classes.onboardingGrid}>
            <div className={classes.reactPlayerWrapper}>
              {/*<img className={classes.onboardingImage} src="/images/verb_onboarding/users.jpg" />*/}
              <ReactPlayer 
                className={classes.reactPlayer}
                width='100%'
                height='100%'
                url="https://vimeo.com/515920787"
                controls={true} 
                playing={false}
              />
            </div>
          </Grid>
          <Grid item md={6} xs={12} className={`${classes.onboardingGrid} ${classes.onboardingGridLeftTextArea}`}>
            <Typography variant="h2" className={classes.onboardingH2}>
              Let's add users to your team.
            </Typography>
            <form className={classes.formNoMaxHeight} noValidate autoComplete="off" onSubmit={handleSubmit(values => this.submitOnboardingUsers(values))}>
              <ErrorText className={classes.bigError} touched={true} error={error} />
              <Typography variant="subtitle1" className={`${classes.onboardingSubtitle} ${classes.onboardingSubtitleContentGroups}`}>
                Administrators have access to the admin portal and can share content with recipients. App users have access to your content in the mobile apps. Would you like to add any App Users now? Otherwise, add them later and they will be notified as they are added. <i>BTW, admins also have access to mobile apps.</i>
              </Typography>
              <FieldArray name="users" component={this.renderUsers} />
              <div className={classes.onboardingButtonContainer}>
                <Button className={`${classes.button} ${classes.onboardingSkipButton}`} color="primary" onClick={this.closedUsers}>Skip for Now</Button>
                <Button className={`${classes.button} ${classes.onboardingButton}`} variant="contained" color="secondary" type="submit" disabled={pristine || invalid}>Continue</Button>
              </div>
            </form>
          </Grid>
        </Grid> 
      </div>
    )
  }
}

function validate(values, props) {
  const errors = {};
  const usersArrayErrors = [];
  
  if (values.users) {
    values.users.map((user, index) => {
      const userErrors = {};
      if (user.email && validateEmail(user.email)) {
        userErrors.email = 'Invalid email address';
        usersArrayErrors[index] = userErrors;
      }
      return null
    })

    if (usersArrayErrors.length) {
      errors.users = usersArrayErrors;
    }
  }

  return errors;
}

function mapStateToProps(state) {
  if (_.isEmpty(state.login.token) || _.isEmpty(state.login.company) || _.isEmpty(state.login.user)) {
    return {
      loggedOut: true
    }
  }

  return { 
    token: state.login.token,
    companyId: state.login.company._id,
    enterprise: state.login.company.enterprise,
    owner: state.login.user._id,
    currentUser: state.login.user
  };
}

const mapDispatchToProps = dispatch => ({
  createOnboardingUsers: (token, values, companyId, callback) => dispatch(createOnboardingUsers(token, values, companyId, callback)),
  updateCompany: (token, values, id, callback) => dispatch(updateCompany(token, values, id, callback))
});

export const OnboardingUsers = reduxForm({
  form: 'OnboardingUsers',
  validate,
  enableReinitialize: true,
  initialValues: {
    //add more parantheses to add more initial users
    // users: ['', '', '']
    users: ['']
  }
})(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(OnboardingUsersForm)));
