import React, { Component } from 'react';
import ReactDOM from 'react-dom';

// import { FormControl, InputLabel, MenuItem, Select, OutlinedInput } from '@material-ui/core';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';

import { ErrorText } from './errorText';

export class RenderSelectField extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      labelWidth: 0
    };
  }

  componentDidMount() {
    const value = this.props.input.value;
    let multiply = 1;

    //have to do this ridiculousness because material-ui is not adding enough width to outlined notch area when label animates to top
    //most likely because of font, material-ui does (full sized label text * .75 + 8)
    // if (value.length === 0) {
    //   multiply = 1.14;
    // }

    this.setState({
      labelWidth: ReactDOM.findDOMNode(this.labelWidth).offsetWidth * multiply
    });
  }

  handleOnChange = (event, onChange) => {
    const { updateOnChange } = this.props;

    if (updateOnChange) {
      updateOnChange(event.target.value);
    }

    onChange(event);
  }
    
  render() {
    const {
      label,
      input: { name, value, onChange },
      meta: { touched, error },
      marginClass,
      className
    } = this.props;

    let children = this.props.children,
        margin = 'normal';

    if (children && children.props && children.props.children && Array.isArray(children.props.children)) {
      children = children.props.children;
    }

    if (marginClass) {
      margin = marginClass;
    }

    let newChildren = [];    

    if(Array.isArray(children) && children.length > 0) {
      newChildren = children.slice(0);
      newChildren.unshift(<MenuItem key={`select-${name}`} value={null} disabled>Select {label}</MenuItem>)
    } else {
      newChildren.unshift(<MenuItem key={`select-${name}`} value={null} disabled>No {label} Available</MenuItem>)
    }

    return (
      <FormControl margin={margin} className={className} variant="outlined">
        <InputLabel 
          ref={ref => {
            this.labelWidth = ref;
          }}
          htmlFor={name}>{label}
        </InputLabel>
        <Select
          value={value}
          onChange={event => this.handleOnChange(event, onChange)}
          input={
            <OutlinedInput
              id={name}
              name={name}
              labelWidth={this.state.labelWidth}
            />
          }
          autoWidth
          children={newChildren}
        />
        <ErrorText touched={touched} error={error} />
      </FormControl>
    )
  }

}
