import _ from "lodash";
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from "redux-form";

import { withStyles } from '@material-ui/core/styles';
import { styles } from '../styles';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import FilestackButton from '../subcomponents/filestackButton';

import LoadingDivContainer from '../subcomponents/loadingDiv';
import { ErrorText } from '../subcomponents/form_subcomponents/errorText';
import { RenderCheckboxes } from '../subcomponents/form_subcomponents/renderCheckboxes';

import { activeCampaignEvent } from '../helpers/helperFunctions';

import { fetchLibraryGroupsMain, createLibraryItems, updateCompany } from '../../actions/actions_index';

class OnboardingContentForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      loading: false,
      uploadedGroups: []
    }

    this.props.reset();
  }

  componentWillMount() {
    const { token, companyId } = this.props;

    this.props.fetchLibraryGroupsMain(token, companyId, groups => {
      this.setState({
        loading: false
      })
    })
  }

  componentDidMount() {
    const { token, enterprise, currentUser, history } = this.props;

    const values = {
      token,
      enterprise,
      email: currentUser.email,
      page: history.location.pathname
    }

    activeCampaignEvent(values)
  }

  proceed = () => {
    const { token, companyId, history, updateCompany } = this.props;

    updateCompany(token, {onboarding_step: 'team'}, companyId, () => {
      history.push('/onboarding/team');
    });
  }

  closedContent = () => {
    this.proceed();
  }

  uploadSuccess = (result, parent, groupId) => {
    this.saveUploadedFiles(result.filesUploaded, parent, groupId);

    //reset form values
    this.props.reset();

    this.setState({
      loading: true
    });
  }

  saveUploadedFiles = (uploadedfiles, parent, groupId) => {
    const { token, companyId, owner } = this.props;
    const { uploadedGroups } = this.state;

    let addToUploadedGroups = uploadedGroups,
        saveFiles = [];

    addToUploadedGroups.push(groupId);

    uploadedfiles.map((value, index) => {
      let file = {};
      const name = value.filename.replace(/\//g,"");

      file.company = companyId;
      file.owners = [owner];
      file.filename = name;
      file.name = name.replace(/_/g, ' ');
      
      //**CHANGE HARD CODED LINK**
      file.aws_bucket = value.container;
      file.aws_key = value.key;
      file.path = `${process.env.REACT_APP_FILESTACK_S3_URL}${value.key.replace(/ /g,"+")}`;
      file.filestack_handle = value.handle;
      file.mimetype = value.mimetype;
      file.file_type = name.substring(name.lastIndexOf('.') + 1).toLowerCase();
      file.file_size = value.size;

      saveFiles.push(file);

      return null;
    });

    this.props.createLibraryItems(token, saveFiles, groupId, libraryItems => {
      this.setState({
        loading: false,
        uploadedGroups: addToUploadedGroups
      });
    });

  }

  submitOnboardingContent = (values) => {
    this.proceed();
  }

  render() {
    const { libraryGroupsMain, forms, pristine, invalid, handleSubmit, classes } = this.props;
    const { error, loading, uploadedGroups } = this.state;
    let selectedGroup = null;

    if (!pristine && !invalid) {
      selectedGroup = {
        _id: forms.OnboardingContent.values.content_group
      }
    }

    return (
      <div className={classes.marginTop}>
        <Typography variant="h1" className={classes.onboardingH1}>
          <div className={classes.onboardingStepNumber}>3</div>Content
        </Typography>
        <Grid container spacing={4} className={classes.gridExtraSpacing} justify="center" alignItems="center">
          <Grid item xs={3}>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle1" className={classes.onboardingSteps}>
              Step <span className={classes.onboardingStepsBold}>3</span> of 7
            </Typography>
            <LinearProgress color="secondary" variant="determinate" value={42.87} />
          </Grid>
          <Grid item xs={3}>
          </Grid>
        </Grid>
        <Grid className={`${classes.onboardingBackgroundReverseContainer} ${classes.gridTagsSpacing}`} container spacing={4} justify="center" alignItems="center">
          <Grid item xs={6} className={`${classes.onboardingGrid} ${classes.onboardingContentFormGrid}`}>
            <Typography variant="h2" className={classes.onboardingH2}>
              Let's add some <span className={classes.onboardingH2Bold}>CONTENT</span>
            </Typography>
            <form className={classes.formNoMaxHeight} noValidate autoComplete="off" onSubmit={handleSubmit(values => this.submitOnboardingContent(values))}>
              <ErrorText className={classes.bigError} touched={true} error={error} />
              <div className={classes.onboardingContentCheckboxes}>
                <Typography variant="subtitle1" className={`${classes.onboardingSubtitle} ${classes.onboardingSubtitleContentGroups}`}>
                  Content is the heart of what we do. Select each Content Group individually and then <i>Upload</i> some amazing content.
                </Typography>
                {loading && <LoadingDivContainer type={'noPadding'} />}
                {!loading &&
                  <Field classes={classes} className={classes.onboardingCheckbox} type="checkbox" name="content_group" oneBox={true} options={libraryGroupsMain} uploadedGroups={uploadedGroups} component={RenderCheckboxes} />
                }
                {(!loading && selectedGroup) && 
                  <FilestackButton
                    onboarding={true}
                    buttonType={'new'}
                    selectedGroup={selectedGroup}
                    uploadSuccess={this.uploadSuccess}
                  />
                }
              </div>
              {/*<Button className={`${classes.button} ${classes.onboardingButton}`} variant="contained" color="secondary" type="submit">{pristine ? 'Skip for Now' : 'Continue to Next Step'}</Button>*/}
              {!loading && 
                <div className={classes.onboardingButtonContainer}>
                  <Button className={`${classes.button} ${classes.onboardingSkipButton} ${classes.onboardingContentSkipButton}`} color="primary" onClick={this.closedContent}>{(uploadedGroups.length > 0) ? 'Continue to Next Step' : 'Skip for Now'}</Button>
                </div>
              }
            </form>
          </Grid>
          <Grid item xs={6} className={classes.onboardingGrid}>
            <div className={`${classes.onboardingImageContainer} ${classes.onboardingImageOrganizationContainer}`}>
              <img className={classes.onboardingImage} src="/images/organization.png" />
            </div>
          </Grid>
        </Grid> 
      </div>
    )
  }
}

function validate(values, props) {
  const errors = {};

  return errors;
}

function mapStateToProps(state) {
  if (_.isEmpty(state.login.token) || _.isEmpty(state.login.company) || _.isEmpty(state.login.user)) {
    return {
      loggedOut: true
    }
  }

  return { 
    token: state.login.token,
    companyId: state.login.company._id,
    enterprise: state.login.company.enterprise,
    owner: state.login.user._id,
    currentUser: state.login.user,
    libraryGroupsMain: state.libraryGroupsMain,
    forms: state.form
  };
}

const mapDispatchToProps = dispatch => ({
  fetchLibraryGroupsMain: (token, companyId, callback) => dispatch(fetchLibraryGroupsMain(token, companyId, callback)),
  createLibraryItems: (token, files, groupId, callback) => dispatch(createLibraryItems(token, files, groupId, callback)),
  updateCompany: (token, values, id, callback) => dispatch(updateCompany(token, values, id, callback))
});

export const OnboardingContent = reduxForm({
  form: 'OnboardingContent',
  validate,
  enableReinitialize: true,
  // initialValues
})(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(OnboardingContentForm)));
