import axios from "axios";
import { ROOT_URL } from "./actions_constants";

export const FETCH_CONTENT_GROUPS = "fetch_content_groups";

export function fetchContentGroups(token, companyId) {

  const TOKEN = token;

  const request = axios.get(`${ROOT_URL}company/content_groups/${companyId}`, 
    { 
      headers: { 
        Authorization: 'Bearer ' + TOKEN
      }
    });

  return {
    type: FETCH_CONTENT_GROUPS,
    payload: request.data
  };

}