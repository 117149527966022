import { FETCH_APP_VERSION } from "../actions/actions_app_version";

export default function(state = {}, action) {
  switch (action.type) {
    case FETCH_APP_VERSION:
      if (!action.payload) {
        return state;
      }
      return action.payload;
    default:
      return state;
  }
}