import React, { Component } from 'react';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import { styles } from '../styles';

import IconButton from '@material-ui/core/IconButton';
import Modal from '@material-ui/core/Modal';

import Close from '@material-ui/icons/Close';

class ExplainerModal extends Component {

  render() {
    const { openExplainerModal, closedExplainerModal, classes } = this.props;

    return (
      <Modal
        aria-labelledby="preview-library-item"
        aria-describedby="preview-library-item"
        open={openExplainerModal}
        onClose={closedExplainerModal}
      >
        <div className={`${classes.modal} ${classes.fullModal} ${classes.fullModalImageContainer}`}>
          <IconButton 
            className={classes.iconClose}
            onClick={closedExplainerModal}
          >
            <Close />
          </IconButton>
          <img className={classes.fullModalImage} src="/images/explainer.png" alt={''} onClick={closedExplainerModal} />
        </div>
      </Modal>
    )

  }

}

export default connect(null, null)(withStyles(styles)(ExplainerModal));
