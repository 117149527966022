import { FETCH_LIBRARY_GROUP, CREATE_LIBRARY_GROUP, UPDATE_LIBRARY_GROUP, MOVE_LIBRARY_GROUP, DELETE_LIBRARY_GROUP } from "../actions/actions_library_groups";

export default function(state = {}, action) {
  switch (action.type) {
    
    case FETCH_LIBRARY_GROUP:
      if (!action.payload) {
        return state;
      }
      return action.payload;

    case CREATE_LIBRARY_GROUP:
      if (!action.payload) {
        return state;
      }
      return action.payload;
      // return _.mapKeys(action.payload.data, "_id");

    case UPDATE_LIBRARY_GROUP:
      if (!action.payload) {
        return state;
      }
      return action.payload;

    case MOVE_LIBRARY_GROUP:
      if (!action.payload) {
        return state;
      }
      return action.payload;

    case DELETE_LIBRARY_GROUP:
      if (!action.payload) {
        return state;
      }
      return action.payload
    
    default:
      return state;
  }
}