import _ from "lodash";
import React, { Component } from "react";
import { compose } from 'redux';
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import { Helmet } from "react-helmet";

import { withStyles } from '@material-ui/core/styles';
import { styles } from '../styles';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import WithLoading from '../subcomponents/withLoading';

import { expiredAccount, checkAlerts, activeCampaignEvent } from '../helpers/helperFunctions';
import BillingModalContainer from '../modals/billingModal';
import { RenderRadioGroup } from '../subcomponents/form_subcomponents/renderRadioGroup';

import { fetchCompany, fetchUsers } from '../../actions/actions_index';

class BillingForm extends Component {

  onSubmit = (values) => {
    const { handleBillingModal } = this.props

    handleBillingModal(true, false, false, null, values)
  }

  cancel = () => {
    this.props.handleBillingModal(true, false, true)
  }

  render() {
    const { company, pricing, annualPricing, needBilling, nextBillingDate, initialValues, handleSubmit, classes } = this.props
    const { old_pricing, payment_card, payment_status, billing_cycle } = company

    let buttonText = 'Add New Card',
        disabledRadioGroup = false,
        canceled = false

    if (payment_status !== '2' && payment_card) {
      buttonText = 'Update Card'
    }

    if (payment_card) {
      disabledRadioGroup = true
    }

    if (payment_status === '2') {
      canceled = true
    }

    const monthly = 
      <div>
        <Typography variant="h4" style={{fontWeight: 300}}>
          Billed Monthly
          {(!needBilling && nextBillingDate && billing_cycle === '0') && 
            <div className={classes.billingSmallText}>Next Billing Date: {nextBillingDate}</div>
          }
        </Typography>
        <Typography variant="h2">
          <span style={{fontSize: 34, fontWeight: 600}}>${pricing}</span> <span className={classes.billingSmallText}>Total</span>
        </Typography>
        <Typography variant="body2">
          {old_pricing ? '$9.99 user/mo' : '$49 user/mo'}
        </Typography>
      </div>

    const yearly =
      <div>
        <Typography variant="h4" style={{fontWeight: 300}}>
          <span className={classes.billedAnnually}>{disabledRadioGroup && <span>*</span>}Save 20% Billed Annually</span>
          {(!needBilling && nextBillingDate && billing_cycle === '1') && 
            <div className={classes.billingSmallText}>Next Billing Date: {nextBillingDate}</div>
          }
        </Typography>
        <Typography variant="h2">
          <span style={{fontSize: 34, fontWeight: 600}}>${annualPricing}</span> <span className={classes.billingSmallText}>Total</span>
        </Typography>
        <Typography variant="body2">
          {old_pricing ? '$7.99 user/mo' : '$40 user/mo'}
        </Typography>
      </div>

    const radioOptions = [ {label: monthly, value: '0'}, {label: yearly, value: '1'}];

    return (
      <div style={{padding: 25, backgroundColor: '#F5F5F5'}}>
        <form id="billing" style={{maxHeight: '100%', overflowY: 'hidden'}} noValidate autoComplete="off" onSubmit={handleSubmit(this.onSubmit)}>
          <Grid container spacing={5} className={classes.billingSettingsArea}>
            <Grid item lg={6} xs={12} className={`${classes.billingGridBody} ${classes.billingGridBodyRight}`}>
              <Field 
                type="radio"
                name="billing_cycle"
                defaultValue={initialValues.billing_cycle}
                options={radioOptions}
                classes={classes}
                component={RenderRadioGroup}
                billingFormGroup={classes.billingFormGroup}
                radioLabelText={classes.billingRadioLabelText}
                radioRoot={classes.billingRadioRoot}
                disabled={disabledRadioGroup}
              />
              <div className={`${classes.billingRow} ${classes.billingHints}`}>
                <Typography variant="body2" className={classes.billingSmallText}>
                  {disabledRadioGroup &&
                    <span>*Contact us to switch to annual billing.<br/></span>
                  }
                  (Prices do not include applicable sales tax.)
                </Typography>
              </div>
            </Grid>
            <Grid item lg={6} xs={12} className={classes.billingGridBody}>
                <div className={classes.billingRow}>
                  {(payment_card && !canceled) &&
                    <Typography variant="h5" className={classes.billingPaymentCard}>{payment_card}</Typography>
                  }
                  <Button className={`${classes.button} ${classes.onboardingButton} ${classes.onboardingCompletedButton} ${classes.billingAddNewCard}`} variant="contained" color="secondary" type="submit" onClick={this.updateBilling}>{buttonText}</Button>
                  {/*{payment_status !== '2' && payment_card &&
                    <span>{payment_card} <span className={classes.billingButton} onClick={() => this.handleBillingModal(true)}>Edit</span></span>
                  }
                  {payment_status !== '2' && !payment_card &&
                    <span className={classes.billingButton} onClick={() => this.handleBillingModal(true)}>Add Card</span>
                  }*/}
                </div>
                {/*
                {(payment_status === '1' || payment_status === '2' || payment_status === '3') &&
                  <div className={classes.billingRow}>
                    <span className={classes.billingButton}>View Last Bill</span>
                  </div>
                }
                */}
                {(payment_status === '1' || payment_status === '3') && payment_card &&
                  <div className={classes.billingRow} className={classes.billingCancelContainer}>
                    <Typography variant="body2" className={classes.billingCancelText} onClick={this.cancel}>
                      Cancel Subscription
                    </Typography>
                  </div>
                }
            </Grid>
          </Grid>
        </form>
      </div>
    )
  }
}

const BillingFormWithLoading = reduxForm({
  form: 'BillingForm',
  // validate,
  enableReinitialize: true
})(WithLoading(BillingForm))


class Billing extends Component {
  state = {
    openBillingModal: false,
    billingError: null,
    billingValues: null,
    cancel: false
  }

  handleBillingModal = (boolean, updated=false, cancel=false, billingError=null, values) => {
    const { handleFetchBilling } = this.props

    this.setState({
      openBillingModal: boolean,
      billingValues: values,
      billingError: billingError,
      cancel: cancel
    })

    if (updated) {
      handleFetchBilling();
    }
  }

  billingModal = () => {
    const { company } = this.props;
    const { openBillingModal, billingValues, billingError, cancel } = this.state

    let handleBillingCycle = '0'

    if (billingValues) {
      handleBillingCycle = billingValues.billing_cycle
    }

    if (openBillingModal) {
      return (
        <BillingModalContainer 
          openBillingModal={openBillingModal}
          billingError={billingError}
          update={Boolean(company.payment_card)}
          billingCycle={handleBillingCycle}
          cancel={cancel}
          handleBillingModal={this.handleBillingModal}
        />
      )
    }

    return null; 
  }

  render() {
    const { token, company, live_only, owner, alerts, users, classes, handleFetchBilling, billings } = this.props
    const alertsRoot = checkAlerts(company, alerts, classes)

    const { old_pricing, billing_date } = company,
          priceMonthly = old_pricing ? 9.99 : 49,
          priceAnnually = old_pricing ? 7.99 : 40

    let expired = expiredAccount(company),
        needBilling = false,
        needBillingMessage = null,
        nextBillingDate = null,
        pricing = null,
        annualPricing= null,
        canceledEndDate = null,
        activeUsersText = `You have ${users.length} active users`

    if (company.canceled_end_date) {
      const cancel = new Date(company.canceled_end_date);
      canceledEndDate = `${cancel.getMonth() + 1}/${cancel.getDate()}/${cancel.getFullYear()}`
      activeUsersText += ` until ${canceledEndDate}`
    }

    if (expired) {
      needBilling = true;

      if (expired === 0) {
        needBillingMessage = 'Add your credit card to continue using your account.'
      } else if (expired === 1) {
        needBillingMessage = 'Reactivate to continue using your account.'
      } else if (expired === 2) {
        needBillingMessage = 'Update your credit card to continue using your account.'
      } else if (expired === 3) {
        needBillingMessage = `Last Day of Subscription: ${canceledEndDate}`
      }
    }

    if (billing_date) {
      nextBillingDate = new Date(billing_date);
      const day = nextBillingDate.getDate();
      let month = nextBillingDate.getMonth() + 1;
      let year = nextBillingDate.getFullYear();

      if (nextBillingDate < new Date()) {
        if (month === 11) {
          month = 1;
          year++;
        } else {
          month++;
        }
      }

      nextBillingDate = `${month}/${day}/${year}`;
    }

    pricing = (priceMonthly * users.length).toFixed(2)
    annualPricing = (priceAnnually * users.length * 12).toFixed(2)

    if (_.isEmpty(token)) {
      return (
        <Redirect to='/login'/>
      )
    }
      
    return (
      <div className={`${classes.root} ${alertsRoot} ${live_only ? classes.rootBillingLiveOnly : null}`}>
        <Helmet>
          <script src="https://js.stripe.com/v3/"></script>
        </Helmet>
        <Typography variant="h2" className={classes.billingTitle}>
          <span style={{fontWeight: 600}}>verb</span>TEAMS: Billing 
        </Typography>
        {needBilling &&
          <Typography className={classes.needBilling} variant="h4">
            { needBillingMessage }
          </Typography>
        }
        <Grid container spacing={7} justify="center" alignItems="center" className={classes.billingGrid}> 
          <Grid item xs={12} className={classes.billingGridHeader}>
            <Grid container spacing={10}> 
              <Grid item xs={12}>
                <img className={classes.billingLogo} src="/images/verb/verb-bug.png" alt={''} />
                <Typography variant="h4" className={classes.billingSubtitle}>
                  {activeUsersText}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <BillingFormWithLoading
          initialValues={{billing_cycle: company.billing_cycle}} 
          company={company}
          pricing={pricing}
          annualPricing={annualPricing}
          needBilling={needBilling}
          nextBillingDate={nextBillingDate}
          handleBillingModal={this.handleBillingModal}
          classes={classes}
        />
        {this.billingModal()}
      </div>
    )
  }
}

const BillingWithLoading = WithLoading(Billing)

class BillingContainer extends Component {
  state = {
    loading: true
  }

  handleFetchBilling = () => {
    const { token, companyId } = this.props;

    this.setState({
      loading: true
    })

    this.props.fetchCompany(token, companyId, company => {
      this.props.fetchUsers(token, companyId, users => {
        this.setState({
          loading: false
        })
      })
    })
  }

  componentDidMount() {
    const { token, enterprise, currentUser, location, master_admin } = this.props;

    this.handleFetchBilling();

    const values = {
      token,
      master_admin,
      enterprise,
      email: currentUser.email,
      page: location.pathname
    }

    activeCampaignEvent(values)
  }

  render() {
    const loading = this.state.loading;

    return (
      <BillingWithLoading 
        isLoading={loading}
        handleFetchBilling={this.handleFetchBilling}
        {...this.props}
      />
    )
  }
}

function mapStateToProps(state) {
  if (_.isEmpty(state.login.token) || _.isEmpty(state.login.company) || _.isEmpty(state.login.user)) {
    return {
      loggedOut: true
    }
  }

  return { 
    token: state.login.token,
    master_admin: state.login.master_admin,
    companyId: state.login.company._id,
    oldPricing: state.company.old_pricing,
    enterprise: state.login.user.user_type > '1' ? false : state.login.company.enterprise,
    live_only: state.login.company.live_only ? state.login.company.live_only : state.login.user.user_type > '1',
    alerts: state.alerts,
    company: state.company,
    owner: state.login.user._id,
    currentUser: state.login.user,
    currentRole: state.login.user.user_type,
    users: state.users,
    billings: state.billings
  };
}

const mapDispatchToProps = dispatch => ({
  fetchCompany: (token, companyId, callback) => dispatch(fetchCompany(token, companyId, callback)),
  fetchUsers: (token, companyId, callback) => dispatch(fetchUsers(token, companyId, callback))
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(BillingContainer)
