import React, { Component } from 'react';
import ReactDOM from 'react-dom';

// import { FormControl, Select, MenuItem, InputLabel, OutlinedInput } from '@material-ui/core';

import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';

import { ErrorText } from './errorText';

export class RenderMultipleSelectField extends Component {

  constructor(props) {
    super(props);
    
    this.state = {
      labelWidth: 0,
      open: false
    };
  }

  componentDidMount() {
    const value = this.props.input.value;
    let multiply = 1;

    //have to do this ridiculousness because material-ui is not adding enough width to outlined notch area when label animates to top
    //most likely because of font, material-ui does (full sized label text * .75 + 8)
    if (value.length === 0) {
      multiply = 1.14;
    }

    this.setState({
      labelWidth: ReactDOM.findDOMNode(this.labelWidth).offsetWidth * multiply
    });
  }

  render() {
    const {
      label,
      // input,
      input: { name, value, onChange },
      meta: { touched, error },
      children
    } = this.props;

    let newValue = value;
    let newChildren = [];

    if(Array.isArray(children) && children.length > 0) {
      newChildren = children.slice(0);
      newChildren.unshift(<MenuItem key={`select-${name}`} value={null} disabled>Select {label}</MenuItem>)
    } else {
      newChildren.unshift(<MenuItem key={`select-${name}`} value={null} disabled>No {label} Available</MenuItem>)
    }

    if (!value || typeof value === 'string' || value === '') {
      newValue = [];
    }

    return (
      <FormControl margin="normal" variant="outlined">
        <InputLabel 
          ref={ref => {
            this.labelWidth = ref;
          }}
          htmlFor={name}>{label}
        </InputLabel>
        <Select
          multiple
          value={newValue}
          onChange={onChange}
          //{...input}
          input={
            <OutlinedInput
              id={name}
              name={name}
              labelWidth={this.state.labelWidth}
            />
          }
          autoWidth
          children={newChildren}
        />
        <ErrorText touched={touched} error={error} />
      </FormControl>
    )
  }

}