import _ from "lodash";
import React, { PureComponent } from "react";
import { compose } from 'redux';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { withStyles } from '@material-ui/core/styles';
import { styles } from '../styles';

import WithLoading from '../subcomponents/withLoading';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import GridContainer from '../subcomponents/gridContainer';
import DrillDown from '../modals/drillDown';

import { analyticsTableHeight, activeCampaignEvent } from '../helpers/helperFunctions';
import { libraryNameSort } from '../helpers/gridLibraryHelpers';

import { fetchViewRate, fetchSessionsAnalytics  } from "../../actions/actions_index";

class Sessions extends PureComponent {
  state = {
    openDrillDown: false,
    selectedDetailId: null,
    selectedDetailName: null,
    sorting: [{ columnName: 'session_start', direction: 'desc' }]
  }

  handleSortingChange = sorting => {
    this.setState({
      sorting
    })
  }

  componentWillReceiveProps(newProps) {
    if (!_.isEqual(this.props.postData, newProps.postData)) {
      newProps.loadData(newProps.postData);
    }
  }

  rowClick = (event) => {
    const { sessionsAnalytics } = this.props
    const rows = sessionsAnalytics.sessionData
    const { _id } = rows[event[0]]

    this.setState({
      openDrillDown: true,
      selectedDetailId: _id,
      selectedDetailName: _id
    })
  }

  closeDrillDown = () => {
    this.setState({
      openDrillDown: false
    })
  }

  handleDrillDown = () => {
    const { openDrillDown, selectedDetailId, selectedDetailName } = this.state
    const { postData } = this.props

    if (openDrillDown) {
      return (
        <DrillDown
          type="session_details"
          openDrillDown={openDrillDown}
          selectedDetailId={selectedDetailId}
          selectedDetailName={selectedDetailName}
          postData={postData}
          closeDrillDown={this.closeDrillDown}
        />
      )
    }
  }

  render() {
    const { enterprise, verb_live, page, postData, sessionsAnalytics, viewRate, classes } = this.props,
          { sorting } = this.state

    const rows = sessionsAnalytics.sessionData;

    const columns = [
      { name: 'session_start', title: 'Session Date'},
      // { name: 'session_start', title: 'Session Date', getCellValue: row => ({name: row.name, filename: row.filename})},
      { name: 'user', title: 'User'},
      { name: 'contactsDivider', title: 'Contacts', getCellValue: row => row.contacts },
      { name: 'browsesAfter', title: 'Browses', getCellValue: row => row.browses },
      { name: 'shows', title: 'Shows', getCellValue: row => row.shows },
      { name: 'sharesDivider', title: 'Shares', getCellValue: row => row.shares },
      
      { name: 'totalMediaOpensAfter', title: 'Total Opens', getCellValue: row => row.media_opens },
      { name: 'media_views', title: 'Total Views', getCellValue: row => row.media_views },
      { name: 'downloads', title: 'Downloads', getCellValue: row => row.media_downloads },
      { name: 'media_shares', title: 'Re-Shares', getCellValue: row => row.media_shares },
      { name: 'session_length', title: 'Duration' }
    ]

    let columnBands = [
      {
        title: '',
        children: [
          { columnName: 'session_start' },
          { columnName: 'user' },
          { columnName: 'contactsDivider' }
        ]
      },
      {
        title: 'User Activity',
        children: [
          { columnName: 'browsesAfter' },
          { columnName: 'shows' },
          { columnName: 'sharesDivider' }
        ],
      },
      {
        title: 'Contact Activity',
        children: [
          { columnName: 'totalMediaOpensAfter' },
          { columnName: 'media_views' },
          { columnName: 'downloads' },
          { columnName: 'media_shares' },
          { columnName: 'session_length' }
        ],
      }
    ]

    const tableColumnExtensions = [
      { columnName: 'session_start', width: 150 },
      { columnName: 'user', width: 140 },
      { columnName: 'contactsDivider', width: 270 },
      { columnName: 'browsesAfter', width: 110 },
      { columnName: 'shows', width: 80 },
      { columnName: 'sharesDivider', width: 85 },
      
      { columnName: 'totalMediaOpensAfter', width: 95 },
      { columnName: 'media_views', width: 85 },
      { columnName: 'downloads', width: 120 },
      { columnName: 'media_shares', width: 100 },
      { columnName: 'session_length', width: 120 }
    ]

    const sortingStateColumnExtensions = [
      { columnName: 'session_start', sortingEnabled: true },
      { columnName: 'user', sortingEnabled: true },
      { columnName: 'contactsDivider', sortingEnabled: true },
      { columnName: 'browsesAfter', sortingEnabled: true },
      { columnName: 'shows', sortingEnabled: true },
      { columnName: 'sharesDivider', sortingEnabled: true },

      { columnName: 'totalMediaOpensAfter', sortingEnabled: true },
      { columnName: 'media_views', sortingEnabled: true },
      { columnName: 'downloads', sortingEnabled: true },
      { columnName: 'media_shares', sortingEnabled: true },
      { columnName: 'session_length', sortingEnabled: true }
    ]

    const filteringStateColumnExtensions = [
      { columnName: 'session_start', filteringEnabled: false },
      { columnName: 'user', filteringEnabled: true },
      { columnName: 'contactsDivider', filteringEnabled: true },
      { columnName: 'browsesAfter', filteringEnabled: false },
      { columnName: 'shows', filteringEnabled: false },
      { columnName: 'sharesDivider', filteringEnabled: false },

      { columnName: 'totalMediaOpensAfter', filteringEnabled: false },
      { columnName: 'media_views', filteringEnabled: false },
      { columnName: 'downloads', filteringEnabled: false },
      { columnName: 'media_shares', filteringEnabled: false },
      { columnName: 'session_length', filteringEnabled: false }
    ]

    const integratedSortingColumnExtensions = [
      { columnName: 'session_start', compare: libraryNameSort }
    ]

    if (!enterprise && verb_live) {
      columns.splice(4, 0, { name: 'verb_live', title: 'LIVE' })
      tableColumnExtensions.splice(4, 0, { columnName: 'verb_live', width: 80 })
      sortingStateColumnExtensions.splice(4, 0, { columnName: 'verb_live', sortingEnabled: false })
      filteringStateColumnExtensions.splice(4, 0, { columnName: 'verb_live', filteringEnabled: false })

      columnBands = [
            {
              title: '',
              children: [
                { columnName: 'session_start' },
                { columnName: 'user' },
                { columnName: 'contactsDivider' }
              ]
            },
            {
              title: 'User Activity',
              children: [
                { columnName: 'browsesAfter' },
                { columnName: 'shows' },
                { columnName: 'verb_live' },
                { columnName: 'sharesDivider' }
              ],
            },
            {
              title: 'Contact Activity',
              children: [
                { columnName: 'totalMediaOpensAfter' },
                { columnName: 'media_views' },
                { columnName: 'downloads' },
                { columnName: 'media_shares' },
                { columnName: 'session_length' }
              ],
            }
          ]
    }

    // console.log(sorting)
    // console.log(integratedSortingColumnExtensions)

    return (
      <Grid item className={classes.topDrilldownPages}> 
        
        {this.handleDrillDown()} 

        <Grid container spacing={1} className={classes.gridSmallMarginTop}>
          <Grid item xs={1} className={classes.gridAnalyticsSpacer}>
          </Grid>
          <Grid item xs={1} className={`${classes.gridNine} ${classes.gridNineSmallPaddingRight}`}>
            <Typography variant="h5" className={classes.smallNumberHeading}>
              Sessions
            </Typography>
            <div className={`${classes.smallNumberBox} ${classes.sessionsBox}`}>
              <Typography variant="h3" className={`${classes.smallNumber}`}>
                {sessionsAnalytics.sessionsCount ? sessionsAnalytics.sessionsCount : 0}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={1} className={`${classes.gridNine} ${classes.gridNineSmallPaddingRightLeft}`}>
            <Typography variant="h5" className={classes.smallNumberHeading}>
              Avg Session
            </Typography>
            <div className={`${classes.smallNumberBox} ${classes.sessionsBox}`}>
              <Typography variant="h3" className={`${classes.smallNumber}`}>
                {sessionsAnalytics.sessionsAvg && sessionsAnalytics.sessionsAvg.averageSessionLength ? sessionsAnalytics.sessionsAvg.averageSessionLength : 0}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={1} className={`${classes.gridNine} ${classes.gridNineSmallPaddingLeft}`}>
            <Typography variant="h5" className={classes.smallNumberHeading}>
              Browse
            </Typography>
            <div className={`${classes.smallNumberBox} ${classes.sessionsBox}`}>
              <Typography variant="h3" className={`${classes.smallNumber}`}>
                {viewRate.sessionEventCounts.browses ? viewRate.sessionEventCounts.browses : 0}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={1} className={`${classes.gridNine} ${classes.gridNineSmallPaddingRight}`}>
            <Typography variant="h5" className={classes.smallNumberHeading}> 
              Shows
            </Typography>
            <div className={`${classes.smallNumberBox} ${classes.showsBox}`}>
              <Typography variant="h3" className={classes.smallNumber}>
                {viewRate.sessionEventCounts.shows ? viewRate.sessionEventCounts.shows : 0}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={1} className={`${classes.gridNine} ${classes.gridNineSmallPaddingLeft}`}>
            <Typography variant="h5" className={classes.smallNumberHeading}> 
              Shares
            </Typography>
            <div className={`${classes.smallNumberBox} ${classes.showsBox}`}>
              <Typography variant="h3" className={classes.smallNumber}>
                {viewRate.sessionEventCounts.shares ? viewRate.sessionEventCounts.shares : 0}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={1} className={`${classes.gridNine} ${classes.gridNineSmallPaddingRight}`}>
            <Typography variant="h5" className={classes.smallNumberHeading}> 
              Total Views
            </Typography>
            <div className={`${classes.smallNumberBox} ${classes.viewsBox}`}>
              <Typography variant="h3" className={classes.smallNumber}>
                {viewRate.sessionEventCounts.media_views ? viewRate.sessionEventCounts.media_views : 0}
              </Typography>
            </div>
          </Grid>
          {/*<Grid item xs={1} className={`${classes.gridNine} ${classes.gridNineSmallPaddingLeft}`}>
            <Typography variant="h5" className={classes.smallNumberHeading}> 
              View Rate
            </Typography>
            <div className={`${classes.smallNumberBox} ${classes.viewsBox}`}>
              <Typography variant="h3" className={classes.smallNumber}>
                {analytics.viewRate}%
              </Typography>
            </div>
          </Grid>*/}
          <Grid item xs={1} className={`${classes.gridNine} ${classes.gridNineSmallPaddingRightLeft}`}>
            <Typography variant="h5" className={classes.smallNumberHeading}> 
              Downloads
            </Typography>
            <div className={`${classes.smallNumberBox} ${classes.viewsBox}`}>
              <Typography variant="h3" className={classes.smallNumber}>
                {viewRate.sessionEventCounts.media_downloads ? viewRate.sessionEventCounts.media_downloads : 0}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={1} className={`${classes.gridNine} ${classes.gridNineSmallPaddingLeft}`}>
            <Typography variant="h5" className={classes.smallNumberHeading}> 
              Re-Shares
            </Typography>
            <div className={`${classes.smallNumberBox} ${classes.viewsBox}`}>
              <Typography variant="h3" className={classes.smallNumber}>
                {viewRate.sessionEventCounts.media_shares ? viewRate.sessionEventCounts.media_shares : 0}
              </Typography>
            </div>
          </Grid>
        </Grid>
        <Grid item className={`${classes.analyticsTableArea} ${classes.analyticsUsersTableArea}`}>
          <GridContainer 
            tableType={'separation'}
            menuType={'-new'}
            appWebSeparation={'sessions'}
            rows={rows}
            columns={columns}
            columnBands={columnBands}
            tableColumnExtensions={tableColumnExtensions}
            sortingStateColumnExtensions={sortingStateColumnExtensions}
            sorting={sorting}
            handleSortingChange={this.handleSortingChange}
            filteringStateColumnExtensions={filteringStateColumnExtensions}
            integratedSortingColumnExtensions={integratedSortingColumnExtensions}
            showSelectAll={false}
            showSelectionColumn={false}
            showRowDetail={false}
            onSelectionChange={this.rowClick}
            classes={classes}
            //Export to CSV
            page={page}
            postData={postData}
            //END Export to CSV
          />
        </Grid>
      </Grid>
    );
  }
}

const SessionsWithLoading = WithLoading(Sessions);

class SessionsContainer extends PureComponent {
  state = {
    loading: true
  }

  loadData = (postData) => {
    const { token } = this.props;

    let dataCalls = [];

    dataCalls.push(
      this.props.fetchViewRate(token, postData, result => {})
    )

    dataCalls.push(
      this.props.fetchSessionsAnalytics(token, postData, result => {})
    )

    Promise.all(dataCalls)
      .then(result => {
        this.setState({
          loading: false
        })
      })
  }

  componentDidMount() {
    const { token, enterprise, currentUser, location, master_admin } = this.props;

    this.loadData(this.props.postData);

    const values = {
      token,
      master_admin,
      enterprise,
      email: currentUser.email,
      page: location.pathname
    }

    activeCampaignEvent(values)
  }

  render() {
    const loading = this.state.loading;

    return (
      <SessionsWithLoading 
        isLoading={loading}
        loadingReport={true}
        loadData={this.loadData}
        {...this.props}
      />
    )
  }
}

function mapStateToProps(state) {
  if (_.isEmpty(state.login.token) || _.isEmpty(state.login.company) || _.isEmpty(state.login.user)) {
    return {
      loggedOut: true
    }
  }

  return { 
    token: state.login.token,
    master_admin: state.login.master_admin,
    companyId: state.login.company._id,
    enterprise: state.company.enterprise,
    verb_live: state.company.verb_live,
    owner: state.login.user._id,
    currentUser: state.login.user,
    currentRole: state.login.user.user_type,
    sessionsAnalytics: state.analytics.sessions,
    viewRate: state.analytics.viewRate
  };
}

const mapDispatchToProps = dispatch => ({
  fetchViewRate: (token, values, callback) => dispatch(fetchViewRate(token, values, callback)),
  fetchSessionsAnalytics: (token, values, callback) => dispatch(fetchSessionsAnalytics(token, values, callback))
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(SessionsContainer)
