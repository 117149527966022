import _ from "lodash";
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";

import WithLoading from '../components/subcomponents/withLoading';

import { expiredAccount, checkDoesNotInclude, handleAppUser } from '../components/helpers/helperFunctions';

import { fetchAppVersion, fetchCompany, handlePageLoading, logout } from '../actions/actions_index';

class BeforeRouting extends Component {
  componentWillReceiveProps(nextProps) {
    const handleFetchCompany = this.props.handleFetchCompany;

    if (this.props.location.pathname !== nextProps.location.pathname) {
      handleFetchCompany();

      return true;
    } else {
      return false;
    }
  }

  render() {
  	const { token, loginCompany, company, appProfile, currentUser, appUser, history, logout } = this.props
    const currentPath = this.props.location.pathname

    if (currentPath.includes('logout')) {
      return this.props.children
    }

    const check = checkDoesNotInclude(currentPath)

    let path = '',
        expired = expiredAccount(company),
        handleCompany = company

    if ((!handleCompany || !handleCompany._id) && loginCompany) {
      handleCompany = loginCompany
    }

    const { enterprise, onboarding, onboarding_step, trial_countdown, payment_status } = handleCompany

    if ((!currentPath.includes('billing') && (payment_status <= '2' || payment_status === '5') && trial_countdown <= 0) || (!currentPath.includes('billing') && payment_status === '2' && expired)) {

      return (
        <Redirect to='/billing'/>
      )
    } 
    else if (appUser && (!appProfile || !appProfile._id) && !currentPath.includes('login')) { 
      return (
        <Redirect to='/login' />
      )
    } 
    else if (appUser && appProfile && appProfile._id && ((!currentPath.includes('library') && !currentPath.includes('contacts') && !currentPath.includes('preview') && !currentPath.includes('space') && !currentPath.includes('showcase') && !currentPath.includes('share_cart')) || (currentPath.includes('analytics') || currentPath.includes('/content/showcases')))) {

      return (
        <Redirect to='/content/library' />
      )
    } else if (!check && !enterprise && onboarding && !currentPath.includes('billing')) {

      switch(onboarding_step) {
        case 'welcome':
          path = '/onboarding/welcome';
          break;

        case 'company':
          path = '/onboarding/company';
          break;

        case 'content_groups':
          path = '/onboarding/content_groups';
          break;

        case 'content':
          path = '/onboarding/content';
          break;

        case 'team': 
          path = '/onboarding/team';
          break;

        case 'users':
          path = '/onboarding/users'
          break;

        case 'brand':
          path = '/onboarding/brand';
          break;

        case 'completed':
          path = '/onboarding/completed';
          break;

        default:
          path = '/content/library';
          break;
      }

      if (path !== currentPath) {
        return (
          <Redirect to={path} />
        )
      } else {
        return(
          this.props.children
        );
      }
    } else if (!onboarding && currentPath.includes('onboarding')) {

      return (
        <Redirect to='/content/library' />
      )
    } else {

      return this.props.children
    }
  }
}

const BeforeRoutingWithLoading = WithLoading(BeforeRouting)

class BeforeRoutingContainer extends Component {
  state = {
    setup: true,
    loading: true
  }

  afterHandleFetchCompany = () => {
    this.setState({
      setup: false,
      loading: false
    })
  }

  handleFetchCompany = () => {
    const { token, version, companyId, fetchAppVersion, fetchCompany, loggedOut, logout } = this.props;
    const setup = this.state.setup;

    // console.log('version', version)
    // console.log('env version', process.env.REACT_APP_VERSION)

    // if (!loggedOut && version !== process.env.REACT_APP_VERSION) {
    //   logout();
    //   this.afterHandleFetchCompany();
    // } else 

    if (!loggedOut) {
      fetchAppVersion(result => {
        fetchCompany(token, companyId, data => {
          if (!data || data.data.suspended) {
            logout()
          } else if (setup) {
            this.afterHandleFetchCompany();
          }
        })
      })
    } else {
      if (setup) {
        this.afterHandleFetchCompany();
      }
    }
  }

  componentDidMount() {
    const { token, loginCompany, company, currentUser, handlePageLoading, logout } = this.props
    const currentPath = this.props.location.pathname

    //logout fully if anything critical is missing
    if (currentPath.includes('logout') && !token || !loginCompany || !company || !currentUser || !loginCompany._id || !company._id || !currentUser._id) {
      logout()
    }

    this.handleFetchCompany()

    handlePageLoading(false, () => {})
  }

  render() {
    const pageLoading = this.props.pageLoading,
          loading = this.state.loading,
          handleLoading = pageLoading ? pageLoading : loading

    return (
      <BeforeRoutingWithLoading 
        isLoading={handleLoading}
        handleFetchCompany={this.handleFetchCompany}
        {...this.props}
      />
    )
  }
}

function mapStateToProps(state) {
  if (_.isEmpty(state.login.token) || _.isEmpty(state.login.company) || _.isEmpty(state.login.user)) {
    return {
      loggedOut: true,
      company: {}
    }
  }

  return { 
    pageLoading: state.pageLoading,
    token: state.login.token,
    version: state.login.version,
    loginCompany: state.login.company,
    companyId: state.login.company._id,
    company: state.company,
    enterprise: state.company.enterprise,
    appProfile: state.appProfile,
    appUser: handleAppUser(state),
    owner: state.login.user._id,
    currentUser: state.login.user
  };
}

const mapDispatchToProps = dispatch => ({
  handlePageLoading: (data, callback) => dispatch(handlePageLoading(data, callback)),
  fetchAppVersion: (callback) => dispatch(fetchAppVersion(callback)),
  fetchCompany: (token, companyId, callback) => dispatch(fetchCompany(token, companyId, callback)),
  logout: () => dispatch(logout())
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(BeforeRoutingContainer)