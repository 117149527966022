import _ from "lodash";
import React, { Component } from "react";
import { compose } from 'redux';
import { connect } from "react-redux";
import { withRouter, Link, Redirect } from "react-router-dom";

import { logout } from "../actions/actions_index";

class Logout extends Component {

  componentDidMount() {
    console.log('logout mount')
    this.props.logout()
  }

	render() {
    const { token, logout } = this.props

    console.log('logout page')

    logout()

    if (!token) {
      return (
        <Redirect to='/login'/>
      )
    } else {
      return null
    }
  }
}

function mapStateToProps(state) {
  console.log(state)

  return { 
    token: state.login.token
  }
}

export default compose(
  withRouter,
  connect(mapStateToProps, {logout})
)(Logout)
