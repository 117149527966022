import _ from "lodash";
import axios from "axios";
import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { withRouter } from "react-router-dom";
import PageVisibility from "react-page-visibility";
import { Beforeunload } from "react-beforeunload";
import { Helmet } from "react-helmet";
import ReactHtmlParser from "react-html-parser";

import { withStyles } from "@material-ui/core/styles";
import { styles } from "../styles";

import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";

import MailOutline from "@material-ui/icons/MailOutline";
import CloudDownload from "@material-ui/icons/CloudDownload";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import Share from "@material-ui/icons/Share";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Close from "@material-ui/icons/Close";

import WithLoading from "../subcomponents/withLoading";
import PreviewLibraryItemModalContainer from "../modals/previewLibraryItemModal";
import FilePreview from "../subcomponents/filePreview";
import { RenderTextField } from "../subcomponents/form_subcomponents/renderTextField";

import {
  convertToDateString,
  showcaseDetails,
  handleThumbnail,
  checkIfVideoLink,
  getItemDetails,
} from "../helpers/helperFunctions";
import { getFileType } from "../helpers/gridLibraryHelpers";

import { ROOT_URL } from "../../actions/actions_constants";
import {
  fetchRoom,
  createSessionEvent,
  shareToContact,
} from "../../actions/actions_index";

import { energy, white, notBlack, gray } from "../../theme";

//Time Duration Tracker
let TOTAL_DURATION = 0,
  DURATION = 0;

class ShareForm extends Component {
  constructor(props) {
    super(props);
    this.props.reset();
  }

  onSubmit = (values) => {
    const { shareToContact, shareCart, details, branding, cartSent } =
        this.props,
      { company, app_profile, user, user_email, session, contact } = details,
      user_details = {
        company,
        app_profile,
        user,
        user_email,
        originating_session: session,
        originating_contact: contact,
      },
      link_message = values.link_message,
      custom_text = { link_message },
      session_details = { session_type: 5, share_type: 5, custom_text },
      emailsRaw = values.contact_emails.replace(/\s/g, "").toLowerCase(),
      emails = emailsRaw.split(","),
      sharedNumber = shareCart.length;

    let newContacts = [];

    emails.map((email) => {
      if (email && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
        newContacts.push({ email });
      }
    });

    if (newContacts.length > 0) {
      let shareContentItems = [],
        shareShowcases = [];

      shareCart.map((item) => {
        if (item.showcase) {
          shareShowcases.push(item);
        } else {
          shareContentItems.push(item);
        }
      });

      const data = {
        user_details,
        session_details,
        branding,
        newContacts,
        shareShowcases,
        shareContentItems,
      };

      shareToContact(data, (result) => {
        cartSent(sharedNumber);
      });
    }
  };

  render() {
    const { color, backgroundColor, classes, handleSubmit, pristine, invalid } =
      this.props;

    return (
      <form
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(this.onSubmit)}>
        <Field
          className={classes.reshareTextField}
          formControlClassName={classes.reshareFormControl}
          handletextfieldvariant="none"
          reshareInputLabel={classes.reshareInputLabel}
          reshareRootInput={classes.reshareInput}
          reshareInput={classes.reshareInput}
          reshareInputUnderline={classes.reshareInputUnderline}
          errorClassName={classes.reshareError}
          name="contact_emails"
          component={RenderTextField}
          type="email"
          label="Emails"
          infoText="(comma separated)"
          infoTextClassName={classes.reshareInfoText}
        />
        <Field
          className={classes.reshareTextField}
          formControlClassName={classes.reshareFormControl}
          handletextfieldvariant="none"
          reshareInputLabel={classes.reshareInputLabel}
          reshareInput={classes.reshareInput}
          reshareInputUnderline={classes.reshareInputUnderline}
          errorClassName={classes.reshareError}
          type="text"
          name="link_message"
          multiline={3}
          component={RenderTextField}
          label="Message"
        />
        <Button
          style={color}
          className={classes.reshareButton}
          classes={{ disabled: classes.reshareButtonDisabled }}
          variant="contained"
          type="submit"
          color="secondary"
          disableRipple={true}
          disabled={pristine || invalid}>
          Share
        </Button>
      </form>
    );
  }
}

function validate(values) {
  const errors = {};
  const requiredFields = ["contact_emails"];

  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = "Required";
    }
  });

  return errors;
}

function mapStateToProps(state) {
  return {
    mediaViewer: state.mediaViewer,
  };
}

const mapDispatchToProps = (dispatch) => ({
  fetchRoom: (link_udid, preview, callback) =>
    dispatch(fetchRoom(link_udid, preview, callback)),
  createSessionEvent: (files, callback) =>
    dispatch(createSessionEvent(files, callback)),
  shareToContact: (values, callback) =>
    dispatch(shareToContact(values, callback)),
});

const ShareFormContainer = reduxForm({
  form: "ShareSpace",
  validate,
  enableReinitialize: true,
})(connect(null, mapDispatchToProps)(withStyles(styles)(ShareForm)));

class Room extends Component {
  constructor(props) {
    super(props);

    const { branding, details } = props.mediaViewer ? props.mediaViewer : {},
      { light_version } = details ? details : {};

    let color = null,
      backgroundColor = null,
      contactButton = null,
      primary = energy;

    if (branding && branding.branding_primary) {
      primary = branding.branding_primary;

      color = {
        color: primary,
      };
      backgroundColor = {
        backgroundColor: primary,
      };
      contactButton = {
        borderColor: primary,
        color: primary,
      };
    }

    const notBlackText = light_version ? { color: notBlack } : null;

    this.state = {
      expired: false,
      timer: null,
      pageActive: true,
      selectedLinkUdid: null,
      selectedLibraryItem: {},
      openPreviewLibraryItemModal: false,
      initiateClosePreviewLibraryItemModal: false,
      windowWidth: null,
      backgroundHeight: null,
      item_open_id: null,
      rawColor: primary,
      color,
      backgroundColor,
      contactButton,
      shareCart: [],
      openShareCart: false,
      sharedSent: false,
      sharedNumber: 0,
      lightVersion: light_version,
      notBlackText,
      windowScrollPosition: 0,
      onlyOneItemIV: false,
      fullscreen: false,
    };
  }

  updateDimensions = () => {
    const windowWidth = document.documentElement.clientWidth;
    let backgroundHeight = null;

    if (windowWidth > 1350) {
      backgroundHeight = "40vw";
    } else if (windowWidth <= 1350 && windowWidth > 1150) {
      backgroundHeight = "40vw";
    } else if (windowWidth <= 1150 && windowWidth > 820) {
      backgroundHeight = "40vw";
    } else if (windowWidth <= 820 && windowWidth > 620) {
      backgroundHeight = "40vw";
    } else if (windowWidth <= 620 && windowWidth > 520) {
      backgroundHeight = "40vw";
    } else if (windowWidth <= 520 && windowWidth > 470) {
      backgroundHeight = "40vw";
    } else {
      backgroundHeight = "40vw";
    }

    this.setState({
      windowWidth,
      backgroundHeight,
    });
  };

  //Count Duration
  startTimer = () => {
    setInterval(this.tick, 1000);
  };

  handleExpiration = () => {
    const mediaViewer = this.props.mediaViewer ? this.props.mediaViewer : {},
      details = mediaViewer.details ? mediaViewer.details : {},
      { link_expiration, link_date } = details;

    if (link_expiration && link_expiration > 0) {
      const today = new Date();
      let linkCheck = new Date(link_date);
      linkCheck.setDate(linkCheck.getDate() + link_expiration);

      if (today >= linkCheck) {
        this.setState({
          expired: true,
        });
      }
    }
  };

  componentDidMount() {
    const { preview, mediaViewer, classes } = this.props,
      { details, allSessionEvents, allLibraryItems, branding } = mediaViewer
        ? mediaViewer
        : {},
      { company, user } = details ? details : {};

    if (!preview && details) {
      this.startTimer();
    }

    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions);

    if (!preview) {
      this.handleExpiration();
    }

    setTimeout(() => {
      let segment = null;

      switch (user) {
        case "5c09966390aac95469fc7c42":
          segment = "dustin";
          break;

        case "5bff13e096e19443e3bbf90f":
          segment = "ben";
          break;

        default:
          segment = "dustin";
          break;
      }

      if (
        company === "59ea2db254ba54369fc728e4" ||
        company === "5bff136a96e19443e3bbf90b"
      ) {
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.innerHTML = `$crisp=[];CRISP_WEBSITE_ID="0abe5e35-8164-4e39-8c76-e5a2bed85d86";(function(){d=document;s=d.createElement("script");s.src="https://client.crisp.chat/l.js";s.async=1;d.getElementsByTagName("head")[0].appendChild(s);})();$crisp.push(["set", "session:segments", [["${segment}"], true]])`;
        script.async = true;

        document.body.appendChild(script);
      }
    }, 1000);

    const firstSessionItem =
      allSessionEvents &&
      Array.isArray(allSessionEvents) &&
      allSessionEvents[0].sessions &&
      Array.isArray(allSessionEvents[0].sessions) &&
      allSessionEvents[0].sessions[0].library_items &&
      Array.isArray(allSessionEvents[0].sessions[0].library_items)
        ? allSessionEvents[0].sessions[0].library_items[0]
        : {};

    const firstItemIsInteractiveVideo = firstSessionItem.details
        ? firstSessionItem.details.interactive_video
        : false,
      onlyOneItem =
        allLibraryItems &&
        Array.isArray(allLibraryItems) &&
        allLibraryItems.length === 1;

    //auto open first item, IF only 1 item or if first session has an interactive video
    if (firstItemIsInteractiveVideo && onlyOneItem) {
      const onlyOneItemIV = true,
        { details, link_udid } = firstSessionItem;

      this.setState({ onlyOneItemIV });
      this.roomItemClick(details, link_udid, false, null, onlyOneItemIV);
    } else if (firstItemIsInteractiveVideo || onlyOneItem) {
      setTimeout(() => {
        const firstItem = firstItemIsInteractiveVideo
          ? firstSessionItem
          : allLibraryItems[0];

        const { item_details, reshareDetails } = getItemDetails({
          item: firstItem,
          branding,
          state: this.state,
          classes,
        });

        if (item_details && !item_details.link) {
          this.roomItemClick(
            item_details,
            firstItem.link_udid,
            false,
            reshareDetails
          );
        }
      }, 1250);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  handleVisibilityChange = (active) => {
    this.setState({
      pageActive: active,
    });
  };

  tick = () => {
    const { pageActive } = this.state;

    if (pageActive) {
      DURATION += 1000;
      TOTAL_DURATION += 1000;

      if (TOTAL_DURATION % 3000 === 0) {
        this.recordEvent("update_total_duration", null);
      }
    }
  };
  //End Duration

  //Record View/Download Event
  recordDownloadEvent = (event, library_item_id) => {
    event.stopPropagation();
    this.recordEvent("download", library_item_id);
  };

  recordReshareEvent = (event, library_item_id) => {
    event.stopPropagation();
    this.recordEvent("reshare", library_item_id);
  };

  recordEvent = (type, item, showcase = false, link = false) => {
    const preview = this.props.preview;

    if (!preview) {
      const { user_agent, mediaViewer } = this.props,
        { details } = mediaViewer ? mediaViewer : {},
        {
          company,
          user,
          contact,
          session,
          session_type,
          app_profile,
          link_udid,
          openMediaViewerEventId,
        } = details ? details : {},
        { item_open_id } = this.state;

      let data = {
        company: company,
        user: user,
        contact: contact,
        session: session,
        session_type: session_type,
        app_profile: app_profile,
        link_udid: link_udid,
        media_viewer_open_id: openMediaViewerEventId,
        item_open_id: item_open_id,
        library_item: item,
        duration: DURATION,
        total_duration: TOTAL_DURATION,
        type: 8,
        user_agent: user_agent,
      };

      if ((link || showcase) && type === "view_opened") {
        return;
      } else if (showcase) {
        data.showcase = item;
        delete data.library_item;
      }

      if (type === "link") {
        data.link = true;
      } else if (type === "download") {
        data.type = 10;
      } else if (type === "reshare") {
        //not exactly sure how reshare will work
        data.type = 9;
      } else if (type === "update_total_duration" || type === "closed") {
        data = {
          media_viewer_open_id: details.openMediaViewerEventId,
          item_open_id: item_open_id,
          duration: DURATION,
          total_duration: TOTAL_DURATION,
        };
      }

      if (data.contact === null) {
        delete data.contact;
      }

      axios.post(`${ROOT_URL}session_events`, data).then((result) => {
        if (type === "view_opened") {
          this.setState({
            item_open_id: result.data.sessionEvent._id,
          });
        } else if (type === "view_closed") {
          this.setState({
            item_open_id: null,
          });

          DURATION = 0;
        }
      });
    }
  };

  roomItemClick = (
    library_item,
    link_udid,
    linkClick = false,
    reshareDetails,
    onlyOneItemIV,
    fullscreen
  ) => {
    let type = "view_opened",
      openModal = true;

    const { _id, link, filename, showcase } = library_item ? library_item : {},
      streamVideo = checkIfVideoLink(filename),
      linkItem = link && !streamVideo ? link : false;

    if ((linkItem || showcase) && !streamVideo && linkClick) {
      type = "link";
      openModal = false;
    } else if (onlyOneItemIV) {
      openModal = false;
    }

    if (!fullscreen) {
      this.recordEvent("update");
    }

    DURATION = 0;

    this.setState(
      {
        openPreviewLibraryItemModal: openModal,
        initiateClosePreviewLibraryItemModal: false,
        selectedLinkUdid: link_udid,
        selectedLibraryItem: library_item,
        item_open_id: null,
        reshareDetails,
        fullscreen,
      },
      () => {
        if (!fullscreen) {
          this.recordEvent(type, _id, showcase, linkItem);
        }
      }
    );
  };

  closedLibraryItemModal = () => {
    this.recordEvent("view_closed");

    this.setState({
      initiateClosePreviewLibraryItemModal: true,
    });

    setTimeout(() => {
      this.setState({
        openPreviewLibraryItemModal: false,
        selectedLibraryItem: null,
        item_open_id: null,
        fullscreen: false,
      });
    }, 500);
  };

  handlePreviewLibraryItemModal = () => {
    const { mediaViewer, classes } = this.props;
    const { branding, allLibraryItems } = mediaViewer;
    const {
      openPreviewLibraryItemModal,
      initiateClosePreviewLibraryItemModal,
      selectedLinkUdid,
      selectedLibraryItem,
      color,
      backgroundColor,
      reshareDetails,
      shareCart,
      windowScrollPosition,
      fullscreen,
    } = this.state;

    if (openPreviewLibraryItemModal) {
      let updatedReshareDetails = reshareDetails;

      if (reshareDetails && _.find(shareCart, { _id: reshareDetails._id })) {
        updatedReshareDetails = {
          ...reshareDetails,
          shareExtraClass: classes.selectedRoomShareButton,
        };
        updatedReshareDetails.backgroundColor = { backgroundColor: notBlack };
      }

      //KEEP FOR NOW: This was an attempt to fix the scrolling to the top when the modal opened. Putting disableScrollLock on the preview modal directly seems to have fixed the issue.
      // document.body.style.position = 'fixed';
      // window.scrollTo(0, windowScrollPosition);

      // setTimeout(() => {
      //   document.body.style.overflow = 'visible'
      // }, 250)
      // setTimeout(() => {
      //   console.log(windowScrollPosition)
      //   window.scrollTo(0, windowScrollPosition);
      // }, 500)

      return (
        <PreviewLibraryItemModalContainer
          openPreviewLibraryItemModal={openPreviewLibraryItemModal}
          initiateClosePreviewLibraryItemModal={
            initiateClosePreviewLibraryItemModal
          }
          allLibraryItems={allLibraryItems}
          selectedLinkUdid={selectedLinkUdid}
          selectedLibraryItem={selectedLibraryItem}
          roomItemClick={this.roomItemClick}
          closedLibraryItemModal={this.closedLibraryItemModal}
          type="room"
          color={color}
          backgroundColor={backgroundColor}
          recordDownloadEvent={this.recordDownloadEvent}
          handleShareButton={this.handleShareButton}
          reshareDetails={updatedReshareDetails}
          parentState={this.state}
          branding={branding}
          fullscreen={fullscreen}
        />
      );
    }
  };

  // handleContentShowcases = (type, showcases) => {

  // }

  handleShareCartItems = () => {
    const { mediaViewer, classes } = this.props;
    const { allLibraryItems } = mediaViewer;
    const { shareCart } = this.state;

    return shareCart.map((cartItem) => {
      const { _id, link_udid } = cartItem;
      const item = _.find(allLibraryItems, { _id, link_udid });
      let item_details = item.details,
        showcase = false;

      if (item.alt_details) {
        item_details = item.alt_details;
      }

      if (item.app_profile_name) {
        showcase = true;
        item_details = showcaseDetails(item);
      }

      const { default_thumbnail, thumbnail } = handleThumbnail(
        item,
        item_details,
        classes
      );

      return (
        <div
          key={`${item._id}-${Math.random() * 10000}()}`}
          className={classes.reshareCartContainer}>
          <div
            className={`${classes.reshareCartItem} ${classes.justVerticalMiddle}`}>
            <img
              className={classes.reshareItemImage}
              src={thumbnail}
              alt={""}
            />
          </div>
          <Typography
            className={`${classes.reshareItemText} ${classes.justVerticalMiddle} ${classes.truncateText}`}
            variant="h5">
            {item_details.name}
          </Typography>
          <IconButton
            className={`${classes.reshareRemoveButton} ${classes.justVerticalMiddle}`}
            onClick={(event) =>
              this.updateCart({ event, _id, link_udid, showcase })
            }>
            <Close />
          </IconButton>
        </div>
      );
    });
  };

  handleShareFormContainer = () => {
    const { details, branding } = this.props.mediaViewer;
    const { color, backgroundColor, shareCart } = this.state;

    return (
      <ShareFormContainer
        details={details}
        branding={branding}
        color={color}
        backgroundColor={backgroundColor}
        shareCart={shareCart}
        cartSent={this.cartSent}
      />
    );
  };

  flipShareCart = () => {
    const { openShareCart } = this.state;
    this.setState({ openShareCart: !openShareCart });
  };

  closeAfterShare = () => {
    this.setState({
      openShareCart: false,
      sharedSent: false,
    });
  };

  handleShareCart = () => {
    const { classes } = this.props;
    const {
      openShareCart,
      backgroundColor,
      color,
      shareCart,
      sharedSent,
      sharedNumber,
    } = this.state;

    let itemText = "Item has";

    if (sharedNumber > 1) {
      itemText = "Items have";
    }

    if (openShareCart && shareCart.length > 0) {
      return (
        <ExpansionPanel
          className={`expansionPanel ${classes.reshareExpansionPanel}`}
          classes={{ expanded: classes.reshareExpansionPanelExpanded }}
          style={backgroundColor}
          defaultExpanded={true}>
          <ExpansionPanelSummary
            className={classes.reshareExpansionPanelSummary}
            classes={{ expandIcon: classes.reshareExpandIcon }}
            expandIcon={<ExpandMore />}>
            <IconButton
              className={classes.iconCloseShareCart}
              onClick={this.flipShareCart}>
              <Close />
            </IconButton>
            <Typography className={classes.reshareCartHeaderText} variant="h3">
              Share Cart
              <span
                className={`${classes.listItemCount} ${classes.reshareListItemCount}`}
                style={color}>
                {shareCart.length}
              </span>
            </Typography>
            {this.handleShareFormContainer()}
          </ExpansionPanelSummary>
          <ExpansionPanelDetails
            className={classes.reshareExpansionPanelDetails}>
            {this.handleShareCartItems()}
          </ExpansionPanelDetails>
        </ExpansionPanel>
      );
    } else if (sharedSent) {
      return (
        <ExpansionPanel
          className={`expansionPanel ${classes.reshareExpansionPanel}`}
          classes={{ expanded: classes.reshareExpansionPanelExpanded }}
          style={backgroundColor}>
          <ExpansionPanelSummary
            className={classes.reshareExpansionPanelSummary}>
            <IconButton
              className={classes.iconCloseShareCart}
              onClick={this.closeAfterShare}>
              <Close />
            </IconButton>
            <Typography
              className={classes.reshareCartHeaderText}
              style={{ textAlign: "center" }}
              variant="h3">{`${itemText} been Shared!`}</Typography>
          </ExpansionPanelSummary>
        </ExpansionPanel>
      );
    }

    return null;
  };

  updateCart = ({
    event,
    _id,
    session,
    link_udid,
    showcase_template_id,
    showcase,
  }) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    const { shareCart } = this.state;
    const itemInCart = _.find(shareCart, { _id, link_udid });

    let newCart = [];

    if (itemInCart) {
      newCart = shareCart.filter((item) => item._id !== _id);
    } else {
      newCart = [
        {
          _id,
          session,
          link_udid,
          showcase_template_id,
          showcase,
        },
        ...shareCart,
      ];
    }

    this.setState({
      reshare: false,
      shareCart: newCart,
      sharedSent: false,
      sharedNumber: 0,
      openShareCart: true,
    });
  };

  cartSent = (sharedNumber) => {
    this.setState({
      sharedSent: true,
      sharedNumber,
      shareCart: [],
    });
  };

  handleMainReshare = () => {
    const { allLibraryItems } = this.props.mediaViewer;

    const cart = [];
    allLibraryItems.map((item) => {
      const { _id, session, link_udid, showcase_template_id, showcase } = item;
      cart.push({
        _id,
        session,
        link_udid,
        showcase_template_id,
        showcase,
      });
    });

    this.flipShareCart();

    this.setState({
      shareCart: cart,
    });
  };

  handleDownloadButton = ({ _id, item_details }) => {
    const { classes } = this.props;
    const { color, windowWidth } = this.state;

    let roomDownloadButton = classes.roomDownloadButton,
      roomDownloadButtonMobile = null,
      roomDownloadIconMobile = null;

    if (windowWidth < 600) {
      roomDownloadButton = null;
      roomDownloadButtonMobile = classes.roomDownloadButtonMobile;
      roomDownloadIconMobile = classes.roomDownloadIconMobile;
    }

    return (
      <a
        href={item_details.download_url}
        download={item_details.name}
        type="download"
        onClick={(event) => this.recordDownloadEvent(event, _id)}
        target="_blank"
        className={roomDownloadButton}>
        <IconButton className={roomDownloadButtonMobile}>
          <CloudDownload
            style={color}
            className={`${classes.roomDownloadIcon} ${roomDownloadIconMobile}`}
          />
        </IconButton>
      </a>
    );
  };

  handleShareButton = (reshareDetails) => {
    const {
      _id,
      session,
      link_udid,
      showcase_template_id,
      showcase,
      backgroundColor,
      location,
    } = reshareDetails;
    const { classes } = this.props;
    const { shareCart, rawColor, windowWidth, fullscreen } = this.state;

    let previewWindowClass = null,
      shareExtraClass = null,
      renderedBackgroundColor = backgroundColor;

    if (location === "preview_window") {
      previewWindowClass = classes.resharePreviewWindow;
    } else if (location === "preview_window_mobile") {
      previewWindowClass = classes.resharePreviewWindowMobile;
    }

    if (_.find(shareCart, { _id, link_udid })) {
      renderedBackgroundColor = { backgroundColor: notBlack };
      shareExtraClass = classes.selectedRoomShareButton;
    }

    if (!fullscreen) {
      return (
        <IconButton
          type="reshare"
          style={{ borderColor: rawColor, ...renderedBackgroundColor }}
          className={`${classes.roomShareButton} ${shareExtraClass} ${previewWindowClass}`}
          onClick={(event) => this.updateCart({ event, ...reshareDetails })}>
          <Share className={`${classes.roomShareIcon}`} />
        </IconButton>
      );
    } else {
      return null;
    }
  };

  handleContentItems = (type, library_items) => {
    const { classes } = this.props;
    const { branding } = this.props.mediaViewer;
    const { windowWidth, lightVersion, notBlackText } = this.state;

    let itemExtraClass = null,
      titleExtraClass = null,
      nameExtraClass = null,
      cardExtraClass = null,
      cardBorderExtraClass = null;

    if (type === 1) {
      itemExtraClass = classes.roomMostRecentItem;
      titleExtraClass = classes.roomMostRecentCardTitle;
      nameExtraClass = classes.roomMostRecentItemName;
      cardExtraClass = classes.roomMostRecentCard;
    }

    if (lightVersion) {
      cardBorderExtraClass = classes.roomMostRecentCardLight;
    }

    return library_items.map((item) => {
      const {
        default_thumbnail,
        thumbnail,
        item_details,
        streamVideo,
        reshareDetails,
        itemClasses,
      } = getItemDetails({ item, branding, state: this.state, classes });

      const {
        interactive_video,
        link,
        filename,
        name,
        file_type,
        downloadable,
        reshareable,
      } = item_details;
      const {
        _id,
        session,
        link_udid,
        showcase_template_id,
        showcase,
        shareExtraClass,
        backgroundColor,
      } = reshareDetails;
      const {
        cardShowcaseItem,
        cardShowcaseClass,
        showcaseTitleClass,
        showcaseNameClass,
      } = itemClasses;

      return (
        <div
          key={`${_id}-${Math.random() * 10000}()}`}
          className={`${classes.roomItem} ${itemExtraClass} ${cardShowcaseItem}`}>
          {link && !streamVideo && (
            <a href={filename} target="_blank">
              <Card
                _id={_id}
                key={`${_id}-${Math.random() * 10000}()}`}
                className={`${classes.card} ${
                  classes.roomCard
                } ${cardExtraClass} ${
                  type === 1 ? "roomMostRecentCard" : null
                } ${default_thumbnail} roomCard ${cardShowcaseClass} ${cardBorderExtraClass}`}
                type="link"
                onClick={(event) =>
                  this.roomItemClick(
                    item_details,
                    link_udid,
                    true,
                    reshareDetails
                  )
                }>
                <CardMedia
                  _id={_id}
                  className={`${classes.cardMedia} ${classes.roomCardMedia}`}
                  component="img"
                  alt={name}
                  image={thumbnail}
                  title={name}
                />
                {reshareable !== false &&
                  windowWidth >= 600 &&
                  this.handleShareButton(reshareDetails)}
              </Card>
              <div
                className={`${classes.roomCardTitle} ${titleExtraClass} ${showcaseTitleClass}`}>
                {getFileType(file_type, "room")}
                <div
                  className={`${classes.roomItemName} ${nameExtraClass} ${showcaseNameClass} ${classes.truncateText}`}
                  style={notBlackText}>
                  {name}
                </div>
              </div>
              {reshareable !== false &&
                windowWidth < 600 &&
                this.handleShareButton(reshareDetails)}
            </a>
          )}
          {(!link || streamVideo) && (
            <div className={classes.roomCardContainer}>
              <Card
                _id={_id}
                key={`${_id}-${Math.random() * 10000}()}`}
                className={`${classes.card} ${
                  classes.roomCard
                } ${cardExtraClass} ${
                  type === 1 ? "roomMostRecentCard" : null
                } ${default_thumbnail} roomCard ${cardShowcaseClass} ${cardBorderExtraClass}`}
                type="view_opened"
                onClick={(event) =>
                  this.roomItemClick(
                    item_details,
                    link_udid,
                    false,
                    reshareDetails
                  )
                }>
                <CardMedia
                  _id={_id}
                  className={`${classes.cardMedia} ${classes.roomCardMedia}`}
                  component="img"
                  alt={name}
                  image={thumbnail}
                  title={name}
                />
                {!link &&
                  !interactive_video &&
                  downloadable &&
                  windowWidth >= 600 &&
                  this.handleDownloadButton({ _id, item_details })}
                {reshareable !== false &&
                  windowWidth >= 600 &&
                  this.handleShareButton(reshareDetails)}
              </Card>
              <div
                className={`${classes.roomCardTitle} ${titleExtraClass} ${showcaseTitleClass}`}
                type="view_opened"
                onClick={(event) =>
                  this.roomItemClick(
                    item_details,
                    link_udid,
                    false,
                    reshareDetails
                  )
                }>
                {getFileType(file_type, "room", filename)}
                <div
                  className={`${classes.roomItemName} ${nameExtraClass} ${showcaseNameClass} ${classes.truncateText}`}
                  style={notBlackText}
                  onClick={(event) =>
                    this.roomItemClick(
                      item_details,
                      link_udid,
                      false,
                      reshareDetails
                    )
                  }>
                  {name}
                </div>
              </div>
              {!link &&
                !interactive_video &&
                downloadable &&
                windowWidth < 600 &&
                this.handleDownloadButton({ _id, item_details })}
              {reshareable !== false &&
                windowWidth < 600 &&
                this.handleShareButton(reshareDetails)}
            </div>
          )}
        </div>
      );
    });
  };

  handleSessions = (type, sessionEvents, onlyOneItemIV) => {
    const { classes } = this.props,
      { details } = this.props.mediaViewer,
      { notBlackText } = this.state;

    let sectionExtraClass = null,
      containerExtraClass = null,
      textExtraClass = null,
      initialsExtraClass = null;

    if (type === 1) {
      sectionExtraClass = classes.roomMostRecentSectionSession;
      containerExtraClass = classes.roomMostRecentItemsContainer;
      textExtraClass = classes.roomMostRecentText;
      initialsExtraClass = classes.roomMostRecentInitialsContainer;
    }

    return sessionEvents.sessions.map((session) => (
      <div
        key={session.link_udid}
        className={`${classes.roomSectionSession} ${sectionExtraClass}`}>
        <Typography
          variant="body1"
          className={`${classes.roomText} ${textExtraClass}`}
          style={notBlackText}>
          <span className={classes.roomSectionHeader}>
            {convertToDateString(sessionEvents.date)} -
          </span>
          {details.user_initials && (
            <span
              className={`${classes.roomInitialsContainer} ${initialsExtraClass}`}>
              <span className={classes.roomInitials}>
                {details.user_initials}
              </span>
            </span>
          )}
          {details.user_name && (
            <i style={{ fontWeight: 600 }}>{details.user_name}</i>
          )}
          {!details.user_name && details.user_email && (
            <i style={{ fontWeight: 600 }}>{details.user_email}</i>
          )}
          : {ReactHtmlParser(session.link_message)}
        </Typography>
        {!onlyOneItemIV && (
          <div
            className={`${classes.roomItemsContainer} ${containerExtraClass}`}>
            {/*this.handleContentItems(type, session.showcases)*/}
            {/*showcases lumped in with library items now*/}
            {this.handleContentItems(type, session.library_items)}
          </div>
        )}
      </div>
    ));
  };

  handleSection = (type, sessionEvents) => {
    const { classes } = this.props;

    //type => 1: most recent, 2: previous
    return (
      <div
        className={
          type === 1 ? classes.roomMostRecentSection : classes.roomSection
        }>
        <div
          className={
            type === 1
              ? classes.roomMostRecentSectionContainer
              : classes.roomSectionContainer
          }>
          {this.handleSessions(type, sessionEvents)}
        </div>
      </div>
    );
  };

  handlePreviousSections = (allSessionEvents) => {
    const { classes } = this.props;

    return allSessionEvents.map((sessionEvents, index) => {
      if (index !== 0) {
        return (
          <div
            key={sessionEvents.date}
            className={classes.roomFirstPreviousSection}>
            {this.handleSection(2, sessionEvents)}
          </div>
        );
      }

      return null;
    });
  };

  handleOnlyOneItemIV = () => {
    const { mediaViewer, classes } = this.props,
      { color, backgroundColor, selectedLinkUdid, onlyOneItemIV } = this.state,
      { allLibraryItems, allSessionEvents } = mediaViewer ? mediaViewer : [],
      item = allLibraryItems[0] ? allLibraryItems[0] : {},
      { details, link_udid } = item;

    return (
      <div className={classes.oneIVContainer}>
        {this.handleSessions(1, allSessionEvents[0], onlyOneItemIV)}
        <div className={classes.oneIV}>
          <div>
            <div
              className={classes.fullscreenButton}
              onClick={(event) =>
                this.roomItemClick(details, link_udid, false, null, false, true)
              }>
              Go Fullscreen
            </div>
          </div>
          <FilePreview
            item={details}
            classes={classes}
            view={"media"} //designates if from admin or space
            color={color}
            backgroundColor={backgroundColor}
            roomItemClick={this.roomItemClick}
            selectedLinkUdid={selectedLinkUdid}
            // closedLibraryItemModal={closedLibraryItemModal}
          />
        </div>
      </div>
    );
  };

  render() {
    const { error, mediaViewer, classes } = this.props,
      { onlyOneItemIV } = this.state;

    if (error) {
      return (
        <div>
          <Typography
            variant="h3"
            style={{ textAlign: "center", margin: "40px 0" }}>
            {error}
          </Typography>
        </div>
      );
    } else if (!mediaViewer) {
      return (
        <div>
          <Typography
            variant="h3"
            style={{ textAlign: "center", margin: "40px 0" }}>
            There is a problem with this Space. Please contact the
            administrator.
          </Typography>
        </div>
      );
    }

    const { details, branding, allSessionEvents, allLibraryItems } = mediaViewer
      ? mediaViewer
      : null;
    const {
      expired,
      selectedLibraryItem,
      windowWidth,
      backgroundHeight,
      color,
      backgroundColor,
      contactButton,
      lightVersion,
      notBlackText,
    } = this.state;
    const {
      user_name,
      user_initials,
      user_email,
      company_name,
      contact_type,
      contact_phone,
      contact_name,
      contact_email,
    } = details;

    let selectedItemId = null,
      backgroundImageContainer = null,
      imageBackground = "1, 22, 39",
      roomContainerWhite = null,
      roomWideBackgroundLightBorder = null;

    if (selectedLibraryItem) {
      selectedItemId = selectedLibraryItem._id;
    }

    if (lightVersion) {
      imageBackground = "255, 255, 255";
      roomContainerWhite = classes.roomContainerWhite;
      roomWideBackgroundLightBorder = classes.roomWideBackgroundLightBorder;
    }

    if (branding.branding_background_image) {
      backgroundImageContainer = {
        position: "absolute",
        top: 0,
        left: 0,
        background: `
          
          linear-gradient(90deg, rgba(${imageBackground}, 1) 0%, rgba(${imageBackground}, 1) 30%, rgba(${imageBackground}, 1) 31%, rgba(${imageBackground}, .95) 32%, rgba(${imageBackground}, .9) 33%, rgba(${imageBackground}, .85) 34%, rgba(${imageBackground}, .8) 35%, rgba(${imageBackground}, .75) 36%, rgba(${imageBackground}, .7) 37%, rgba(${imageBackground}, .65) 38%, rgba(${imageBackground}, .6) 39%, rgba(${imageBackground}, .55) 40%, rgba(${imageBackground}, .5) 41%, rgba(${imageBackground}, .45) 42%, rgba(${imageBackground}, .4) 43%, rgba(${imageBackground}, .35) 44%, rgba(${imageBackground}, .3) 45%, rgba(${imageBackground}, .25) 46%, rgba(${imageBackground}, .2) 47%, rgba(${imageBackground}, .15) 48%, rgba(${imageBackground}, .1) 49%, rgba(${imageBackground}, .05) 50%, rgba(${imageBackground}, 0) 100%),

          linear-gradient(0deg, rgba(${imageBackground}, 1) 0%, rgba(${imageBackground}, 1) 20%, rgba(${imageBackground}, 1) 21%, rgba(${imageBackground}, .95) 22%, rgba(${imageBackground}, .9) 23%, rgba(${imageBackground}, .85) 24%, rgba(${imageBackground}, .8) 25%, rgba(${imageBackground}, .75) 26%, rgba(${imageBackground}, .7) 27%, rgba(${imageBackground}, .65) 28%, rgba(${imageBackground}, .6) 29%, rgba(${imageBackground}, .55) 30%, rgba(${imageBackground}, .5) 31%, rgba(${imageBackground}, .45) 32%, rgba(${imageBackground}, .4) 33%, rgba(${imageBackground}, .35) 34%, rgba(${imageBackground}, .3) 35%, rgba(${imageBackground}, .25) 36%, rgba(${imageBackground}, .2) 37%, rgba(${imageBackground}, .15) 38%, rgba(${imageBackground}, .1) 39%, rgba(${imageBackground}, .05) 40%, rgba(${imageBackground}, 0) 100%),

          radial-gradient(73% 98% at 100% top, rgba(${imageBackground}, 0) 0%, rgba(${imageBackground}, 0) 90%, rgba(${imageBackground}, 1)),

          url("${branding.branding_background_image}")
        `,

        backgroundRepeat: "no-repeat, no-repeat, no-repeat,no-repeat",
        backgroundSize: "auto 100%, auto 100%, auto 100%, 72% auto",
        backgroundPosition: "left top, bottom left, top left, right top",

        // backgroundRepeat: 'no-repeat,no-repeat',
        // backgroundSize: 'auto 100%, 72% auto',
        // backgroundPosition: 'top left, right top',

        width: "100%",
        height: backgroundHeight,
      };
    } else {
      backgroundImageContainer = {
        background: notBlack,
      };
    }

    let contactName = null;

    if (contact_type !== "0" && contact_name) {
      contactName = contact_name;
    } else if (contact_type !== "0" && contact_email) {
      contactName = contact_email;
    } else if (contact_type !== "0" && contact_phone) {
      contactName = contact_phone;
    }

    let pageTitle = `${company_name} Space`,
      pageDescription = `Prepared by ${company_name}`;

    if (user_name) {
      pageTitle += ` Prepared by ${user_name}`;
      pageDescription = `Prepared by ${user_name}`;
    }

    if (contactName) {
      pageTitle += ` for ${contactName}`;
    }

    let imageWidth = null;
    let imageHeight = null;

    if (branding.branding_background_image) {
      if (branding.branding_background_image_width) {
        imageWidth = branding.branding_background_image_width;
        imageHeight = branding.branding_background_image_height;
      }
    } else if (branding.branding_small_logo) {
      if (branding.branding_small_logo_width) {
        imageWidth = branding.branding_small_logo_width;
        imageHeight = branding.branding_small_logo_height;
      }
    }

    return (
      <div
        id="room_container"
        className={`${classes.roomContainer} ${roomContainerWhite} ${
          onlyOneItemIV ? classes.roomContainerOnlyOneItemIV : null
        }`}>
        <div style={backgroundImageContainer}></div>
        <Helmet>
          <title>{pageTitle}</title>
          <meta property="og:title" content={pageTitle} />
          <meta name="og:description" content={pageDescription} />
          <meta
            property="og:image"
            content={
              branding.branding_background_image
                ? branding.branding_background_image
                : branding.branding_small_logo
            }
          />
          <meta
            property="og:image:width"
            content={imageWidth ? imageWidth : null}
          />
          <meta
            property="og:image:height"
            content={imageHeight ? imageHeight : null}
          />
        </Helmet>
        <div
          className={`${classes.roomWideBackground} ${roomWideBackgroundLightBorder}`}>
          <div className={classes.roomMostRecentContainer}>
            <AppBar position="static" className={classes.roomHeader}>
              <Toolbar className={classes.roomHeaderToolbar}>
                <div className={classes.roomHeaderContainer}>
                  <Typography
                    variant="body1"
                    className={`${classes.roomHeaderText} ${classes.roomTextEnergy}`}
                    style={color}>
                    Shared by
                  </Typography>
                  {user_name && (
                    <Typography
                      variant="h3"
                      className={`${classes.roomHeaderName} ${classes.truncateText}`}
                      style={notBlackText}>
                      {user_name}
                    </Typography>
                  )}
                  {!user_name && user_email && (
                    <Typography
                      variant="h3"
                      className={`${classes.roomHeaderName} ${classes.truncateText}`}
                      style={notBlackText}>
                      {user_email}
                    </Typography>
                  )}
                  <Typography
                    variant="body1"
                    className={classes.roomHeaderText}>
                    {company_name}
                  </Typography>
                </div>
                <div
                  className={`${classes.roomHeaderContainer} ${classes.roomHeaderContainerLogo}`}>
                  {!branding.branding_small_logo && (
                    <img src="/images/verb/verb-bug.png" alt={""} />
                  )}
                  {branding.branding_small_logo && (
                    <img src={branding.branding_small_logo} alt={""} />
                  )}
                </div>
              </Toolbar>
            </AppBar>
            <div className={classes.roomContactInfoContainer}>
              <a href={`mailto:${user_email}`} target="_blank">
                <Button
                  className={classes.roomContactIconButton}
                  style={contactButton}
                  variant="outlined">
                  <MailOutline className={classes.roomContactIcon} /> Email Me
                </Button>
              </a>
            </div>
            <div className={classes.spaceWelcomeArea}>
              <Typography
                variant="h2"
                className={classes.roomHeaderContact}
                style={notBlackText}>
                {contact_type === "0" && (
                  <span className={classes.roomHeaderWelcome}>Welcome!</span>
                )}
                {contact_type !== "0" && (
                  <span>
                    {contactName}
                    <span className={classes.roomHeaderWelcome}>
                      , welcome!
                    </span>
                  </span>
                )}
              </Typography>
            </div>
            <div className={classes.mainReshareButtonContainer}>
              <Button
                style={backgroundColor}
                className={classes.mainReshareButton}
                variant="contained"
                type="submit"
                color="secondary"
                onClick={this.handleMainReshare}>
                Share
              </Button>
            </div>
            <PageVisibility
              onChange={this.handleVisibilityChange}></PageVisibility>
            <Beforeunload
              onBeforeunload={(event) => {
                this.recordEvent("closed");
              }}
            />
            {!expired && onlyOneItemIV && this.handleOnlyOneItemIV()}
            {!expired &&
              !onlyOneItemIV &&
              this.handleSection(1, allSessionEvents[0])}
            {expired && (
              <div className={classes.spaceWelcomeArea}>
                <Typography
                  variant="h2"
                  className={classes.roomHeaderContact}
                  style={notBlackText}>
                  <span className={classes.expiredHeader}>
                    This link has expired, contact the sender to get a new link.
                  </span>
                </Typography>
              </div>
            )}
          </div>
        </div>
        {!expired && allSessionEvents.length > 1 && (
          <div className={classes.roomWideBackgroundWhite}>
            <div className={classes.roomPreviousSection}>
              <Button
                style={backgroundColor}
                className={classes.roomButton}
                variant="contained"
                color="secondary">
                Previously Shared
                <ArrowDropDown className={classes.addCircleIcon} />
              </Button>
              {this.handlePreviousSections(allSessionEvents)}
            </div>
          </div>
        )}
        {this.handlePreviewLibraryItemModal()}
        {this.handleShareCart()}
        <div
          className={`${classes.roomPoweredByBar} ${
            onlyOneItemIV ? classes.roomPoweredByBarOnlyOneItemIV : null
          }`}>
          <Typography variant="body1" className={classes.roomPoweredByText}>
            <span className={classes.roomPoweredByTextSpan}>Powered by</span>
            <a href="https://solofire.com">
              <img
                className={classes.roomPoweredByLogo}
                src="/images/verb/verb-dark-logo.png"
              />
            </a>
          </Typography>
        </div>
      </div>
    );
  }
}

const RoomWithLoading = WithLoading(Room);

class RoomContainer extends Component {
  constructor(props) {
    super(props);

    const { path } = this.props.match;
    const user_agent = navigator.userAgent;

    let preview = false;

    if (
      path.includes("preview") ||
      user_agent.includes("Prerender") ||
      user_agent.includes("Headless") ||
      user_agent.includes("headless")
    ) {
      preview = true;
    }

    this.state = {
      loading: true,
      user_agent,
      preview,
    };
  }

  componentDidMount() {
    const { link_udid } = this.props.match.params;
    const { preview } = this.state;

    this.props.fetchRoom(link_udid, preview, (data) => {
      const result = data.data,
        { error } = result;

      this.setState({
        error,
        loading: false,
      });
    });
  }

  render() {
    const { loading, user_agent, preview, error } = this.state;

    return (
      <RoomWithLoading
        isLoading={loading}
        user_agent={user_agent}
        preview={preview}
        error={error}
        {...this.props}
      />
    );
  }
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(RoomContainer);
