import _ from "lodash";
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Redirect } from "react-router-dom";
import { Field, reduxForm } from "redux-form";

import { withStyles } from '@material-ui/core/styles';
import { styles } from '../styles';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';

import ArrowLeft from '@material-ui/icons/ArrowLeft';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import Launch from '@material-ui/icons/Launch';
import Share from '@material-ui/icons/Share';
import Visibility from '@material-ui/icons/Visibility';
import Reply from '@material-ui/icons/Reply';
import CloudDownload from '@material-ui/icons/CloudDownload';

import LoadingDivContainer from '../subcomponents/loadingDiv';
import WithLoading from '../subcomponents/withLoading';
import GridContainer from '../subcomponents/gridContainer';
import { RenderSelectField } from '../subcomponents/form_subcomponents/renderSelectField';
import RenderAutocompleteField from '../subcomponents/form_subcomponents/renderAutocompleteField';

//componenents
//

//SUBCOMPONENTS
//Contacts List > Contact Row
//Timeline View > Timeline Card
//Items List > Content Card

import { verifyLoggedIn, checkAlerts, convertDateStringToNewDateString, convertToTimeString, convertDuration, handleEnterpriseAppUser, handleAppUser } from '../helpers/helperFunctions';
import { contactFeed, sharedNotOpened, timeline, fetchLibraryItems } from '../../actions/actions_index';
//import { contact_feed, shared_not_opened, timeline } from '../../actions/actions_index';

const TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone

class Contacts extends Component {
	state = {
		loading: false,
		timelineClicked: false,
		contactName: '',
		itemsType: '',
		clientWidth: null,
		contactSearch: null
	}

	componentDidMount() {
		this.updateWindowSize()
    window.addEventListener("resize", this.updateWindowSize)
	}

	componentDidUnMount() {
		window.removeEventListener("resize", this.updateWindowSize)
	}

	updateWindowSize = () => {
    this.setState({clientWidth: window.innerWidth})
  }
	
	shouldComponentUpdate(nextProps, nextState) {
	  const { appProfile } = this.props,
	        newAppProfile = nextProps.appProfile,
	        oldId = appProfile._id,
	        newId = newAppProfile._id

	  if (oldId !== newId) {	  	
	  	this.setState({
        timelineClicked: false
	  	})
	  }

	  return true
	}

	openTimeline = (contact, contactName) => {
		const { token, companyId, appProfile, owner, timeline } = this.props
		const appProfileID = appProfile ? appProfile._id : null

		this.setState({timelineClicked: false})

		timeline(token, {company: companyId, app_profile: appProfileID, user: owner, contact, timezone: TIMEZONE }, data => { 
			this.setState({timelineClicked: true, contactName})
		})
	}

	handleTimeline = () => {
		const { contact, classes } = this.props

		if (contact && Array.isArray(contact)) {
			return contact.map(date => (
				<React.Fragment key={date.date}>
					<Typography variant="h6">{convertDateStringToNewDateString(date.date)}</Typography>
					<Card className={`${classes.contactsCard} ${classes.contactsCardTimeline}`}>
			      <CardContent className={classes.contactsCardContent}>
			        {this.handleEvents(true, date)}
			      </CardContent>
			    </Card>
		    </React.Fragment>
			))
		}
	}

	handleItems = () => {
		const { libraryItems } = this.props

		return libraryItems.map(item => {
			const { _id, name } = item

			return (
				<li key={_id}>
					{name}
				</li>
			)
		})
	}

	calculateSpaceTime = (analytics) => {
		const sortedAnalytics = _.sortBy(analytics, 'date');

		return convertToTimeString(sortedAnalytics[0].date)
	}

	calculateSpaceDuration = (analytics) => {
		const durationTotalRaw = analytics.reduce((acc, row) => { return acc + row.duration; }, 0)

		return convertDuration(durationTotalRaw)
	}

	findPreviousItem = (analytics, index) => {
		const { date, duration } = analytics[index - 1]

		return { date, duration }
	}

	findItems = (items, analytics) => {
		const { classes } = this.props,
					sortAnalytics = _.sortBy(analytics, 'date');

		return (
			<ul className={classes.contactsItemsList}>
				{sortAnalytics.map((event, index) => {
					const { library_item, date, duration } = event,
								{ _id, name, file_type } = _.find(items, {_id: library_item}) ? _.find(items, {_id: library_item}) : {},
								previousData = sortAnalytics[index - 1] ? this.findPreviousItem(sortAnalytics, index) : {},
								itemTime = convertToTimeString(date),
								itemDuration = duration > 0 ? ` for ${convertDuration(duration)}` : '',
								printTime = (itemTime === convertToTimeString(previousData.date)) ? false : true

					return (
						<li key={`${itemTime}-${_id}`} className={`${classes.contactsItemRow} ${!printTime ? classes.contactsItemRowNoMargin : null}`}>
							{printTime && <React.Fragment><span className={classes.contactsItemsSub} style={{position: 'relative', left: -8}}>{itemTime}</span><br /></React.Fragment>}
							<FiberManualRecordIcon className={classes.contactsListDisc} />{name} ({file_type})<span className={`${classes.contactsItemsSub} ${classes.contactsItemsSubDuration}`}>{itemDuration}</span>							
						</li>
					)
				})}
			</ul>
		)
	}

	handleEvents = (timeline, contact) => {
		const { classes } = this.props,
					{ date, events, items } = contact

		let spaceOpened, itemsSharedCount, itemsSharedWithCount, itemsViewedCount, itemsResharedCount, itemsDownloadedCount, itemsShared, itemsSharedWith, itemsViewed, itemsReshared, itemsDownloaded, originating_contact, originating_contact_details, reshare_contact_details, originatingContactName = '', reshareContactName = '', spaceEarliest, spaceDuration, itemsSharedWithAnalytics, itemsSharedAnalytics, itemsViewedAnalytics, itemsResharedAnalytics, itemsDownloadedAnalytics

		// 5: App Library Item Share
    // 7: Space Open
    // 8: Space Library Item View
    // 9: Space Library Item Reshare
    // 10: Space Library Item Download
    // 11: Sent to Verb LIVE

		if (timeline) {
			const sharedWith = events.filter(event => event.type === 5 && event.originating_contact_details.length > 0)[0],
						space = _.find(events, {type: 7}) ? _.find(events, {type: 7}) : null,
						shared = events.filter(event => event.type === 5 && event.originating_contact_details.length === 0)[0],
						viewed = _.find(events, {type: 8}) ? _.find(events, {type: 8}) : null,
						reshared = _.find(events, {type: 9}) ? _.find(events, {type: 9}) : null,
						downloaded = _.find(events, {type: 10}) ? _.find(events, {type: 10}) : null
		
			originating_contact_details = sharedWith ? sharedWith.originating_contact_details[0] : null
			reshare_contact_details = reshared ? reshared.reshare_contact_details[0] : null

			itemsSharedWithCount = sharedWith ? sharedWith.total : 0
			itemsSharedCount = shared ? shared.total : 0
			spaceOpened = space ? { total: space.total, analytics: space.item_analytics } : null
			itemsViewedCount = viewed ? viewed.total : 0
			itemsResharedCount = reshared ? reshared.total : 0
			itemsDownloadedCount = downloaded ? downloaded.total : 0

			spaceEarliest = spaceOpened ? this.calculateSpaceTime(spaceOpened.analytics) : null
			spaceDuration = spaceOpened ? this.calculateSpaceDuration(spaceOpened.analytics) : null
			
			itemsShared = shared ? shared.item_details : null
			itemsSharedWith = sharedWith ? sharedWith.item_details : null
			itemsViewed = viewed ? viewed.item_details : null
			itemsReshared = reshared ? reshared.item_details : null
			itemsDownloaded = downloaded ? downloaded.item_details : null

			itemsSharedAnalytics = shared ? shared.item_analytics : null
			itemsSharedWithAnalytics = sharedWith ? sharedWith.item_analytics : null
			itemsViewedAnalytics = viewed ? viewed.item_analytics : null
			itemsResharedAnalytics = reshared ? reshared.item_analytics : null
			itemsDownloadedAnalytics = downloaded ? downloaded.item_analytics : null
		} else {
			originating_contact_details = (contact.originating_contact_details && contact.originating_contact_details.length > 0 && contact.originating_contact_details[0]) ? contact.originating_contact_details[0] : null
			reshare_contact_details = (contact.reshare_contact_details && contact.reshare_contact_details.length > 0 && contact.reshare_contact_details[0]) ? contact.reshare_contact_details[0] : null

			itemsSharedWithCount = items ? (contact.originating_contact_details.length > 0 ? items.length : 0) : (events ? events.filter(event => event.type === 5 && Boolean(event.originating_contact)).length : 0)
			itemsSharedCount = items ? (!contact.originating_contact_details.length > 0 ? items.length : 0) : (events ? events.filter(event => event.type === 5 && !Boolean(event.originating_contact)).length : 0)
			spaceOpened = events ? { total: events.filter(event => event.type === 7).length } : null
			itemsViewedCount = events ? events.filter(event => event.type === 8).length : 0
			itemsResharedCount = events ? events.filter(event => event.type === 9).length : 0
			itemsDownloadedCount = events ? events.filter(event => event.type === 10).length : 0
		}

		if (originating_contact_details) {
			const { first_name, last_name, email, phone } = originating_contact_details

			if (first_name && last_name) {
				originatingContactName = `${first_name} ${last_name}`
			} else if (email) {
				originatingContactName = email
			} else if (first_name) {
				originatingContactName = first_name
			} else if (phone) {
				originatingContactName = phone
			}
		}

		reshareContactName = reshare_contact_details ? reshare_contact_details.email : ''

    return (
    	<React.Fragment>
    		{(itemsSharedWithCount > 0 || itemsSharedCount > 0) &&
    			<div className={`${classes.contactsFeedItem} ${timeline ? classes.contactsFeedItemTimeline : null}`}>
    				{itemsSharedWithCount > 0 &&
    					<span className={timeline ? classes.contactsCardName : null}>
    						<Share className={classes.contactsFeedItemIcon} />Received {itemsSharedWithCount} Item{itemsSharedWithCount > 1 ? 's' : ''} <span className={classes.contactsItemsSubDuration}>from {originatingContactName}</span>{timeline ? ':' : ''}
    					</span>
    				}
    				{itemsSharedWith && this.findItems(itemsSharedWith, itemsSharedWithAnalytics)}
    				
    				{itemsSharedCount > 0 && 
    					<span className={timeline ? classes.contactsCardName : null}>
    						<Share className={classes.contactsFeedItemIcon} />Received {itemsSharedCount} Item{itemsSharedCount > 1 ? 's' : ''}{timeline ? ':' : ''}
    					</span>
    				}
    				{itemsShared && this.findItems(itemsShared, itemsSharedAnalytics)}
    			</div>
    		}
    		{(spaceOpened && spaceOpened.total > 0) && 
    			<div className={`${classes.contactsFeedItem} ${timeline ? classes.contactsFeedItemTimeline : null}`}>
    				<span className={timeline ? classes.contactsCardName : null}>
    					<Launch className={classes.contactsFeedItemIcon} />Opened Space {spaceOpened.total} Time{spaceOpened.total > 1 ? 's' : ''}
    					{timeline &&
    						<React.Fragment>
    							<br />
    							<span className={classes.contactsItemsSub} style={{paddingLeft: 20}}>{spaceOpened.total > 1 ? 'First open was at ' : ''}{spaceEarliest} {spaceOpened.total > 1 ? 'and all opens totaled ' : 'for '}<span className={classes.contactsItemsSubDuration}>{spaceDuration}</span></span>
    						</React.Fragment>
    					}
    				</span>
    			</div>
    		}
    		{itemsViewedCount > 0 &&
    			<div className={`${classes.contactsFeedItem} ${timeline ? classes.contactsFeedItemTimeline : null}`}>
    				<span className={timeline ? classes.contactsCardName : null}>
    					<Visibility className={classes.contactsFeedItemIcon} />Viewed {itemsViewedCount} Item{itemsViewedCount > 1 ? 's' : ''}{timeline ? ':' : ''}
    				</span>
    				{itemsViewed && this.findItems(itemsViewed, itemsViewedAnalytics)}
    			</div>
    		}
    		{itemsResharedCount > 0 &&
    			<div className={`${classes.contactsFeedItem} ${timeline ? classes.contactsFeedItemTimeline : null}`}>
    				<span className={timeline ? classes.contactsCardName : null}>
    					<Reply className={classes.contactsFeedItemIcon} />Reshared {itemsResharedCount} Item{itemsResharedCount > 1 ? 's' : ''} <span className={classes.contactsItemsSubDuration}>with {reshareContactName}</span>{timeline ? ':' : ''}
    				</span>
    				{itemsReshared && this.findItems(itemsReshared, itemsResharedAnalytics)}
    			</div>
    		}
    		{itemsDownloadedCount > 0 &&
    			<div className={`${classes.contactsFeedItem} ${timeline ? classes.contactsFeedItemTimeline : null}`}>
    				<span className={timeline ? classes.contactsCardName : null}>
    					<CloudDownload className={classes.contactsFeedItemIcon} />Downloaded {itemsDownloadedCount} Item{itemsDownloadedCount > 1 ? 's' : ''}{timeline ? ':' : ''}
    				</span>
    				{itemsDownloaded && this.findItems(itemsDownloaded, itemsDownloadedAnalytics)}
    			</div>
    		}
    	</React.Fragment>
    )

		// {return (
		// 	<React.Fragment>
		// 		<ul className={classes.contactsItemList}>
		// 			{itemsSharedCount > 0 ? <li key='items-shared' onClick={timeline ? () => this.openItems('Shared', itemsShared) : null} className={timeline ? classes.contactsItemRowClick : null}>{`${itemsSharedCount} Items ${originating_contact ? 'Reshared' : 'Shared'}`}</li> : ''}
		// 			{(!timeline && itemsViewedCount === 0 && itemsResharedCount === 0 && itemsDownloadedCount === 0 && spaceOpened > 0) ? <li key='space-opened'>Space Opened</li> : null}
		// 			{timeline && spaceOpened > 0 ? <li key='space-opened'>{`Opened Space ${spaceOpened} Times`}</li> : null}
		// 			{itemsViewedCount > 0 ? <li key='items-viewed' onClick={timeline ? () => this.openItems('Viewed', itemsViewed) : null} className={timeline ? classes.contactsItemRowClick : null}>{`${itemsViewedCount} Item${itemsViewedCount > 1 ? 's' : ''} Viewed`}</li> : null}
		// 			{itemsResharedCount > 0 ? <li key='items-reshared' onClick={timeline ? () => this.openItems('Reshared', itemsReshared) : null} className={timeline ? classes.contactsItemRowClick : null}>{`${itemsResharedCount} Item${itemsResharedCount > 1 ? 's' : ''} Reshared`}</li> : null}
		// 			{itemsDownloadedCount > 0 ? <li key='items-downloaded' onClick={timeline ? () => this.openItems('Downloaded', itemsDownloaded) : null} className={timeline ? classes.contactsItemRowClick : null}>{`${itemsDownloadedCount } Item${itemsDownloadedCount > 1 ? 's' : ''} Downloaded`}</li> : null}
		// 		</ul>
		// 	</React.Fragment>	      
		// )}
	}

	handleFeed = (feed, newContacts) => {
		const { contacts, classes } = this.props,
					{ contactSearch } = this.state

		let handleContacts = newContacts ? newContacts : contacts

		//By default list should only show contacts that have opened something "Recently Opened" -> pull from contactFeed
		switch (feed) {
			case '0':
				handleContacts = contacts.filter(contact => {			
					if (_.find(contact.events, {type: 7})) {
						return contact
					}
				})
				break

			case '1':
				break

			case '2':
				break

			default:
				handleContacts = contacts.filter(contact => _.find(contact.events, {type: 7}))
				break
		}

		const updateHandleContacts = contactSearch ? handleContacts.filter(contactObj => contactObj.contact === contactSearch) : handleContacts

		let feedOptions = []

		const htmlFeed = updateHandleContacts.map((contact, index) => {
			const { date } = contact,
						{ _id, first_name, last_name, email } = contact.contact_details,
						contactID = contact.contact,
						printDate = ((handleContacts[index -1] && date === handleContacts[index -1].date) ? false : true)

			let contactName = ''

			if (first_name && last_name) {
				contactName = `${first_name} ${last_name}`
			} else if (first_name) {
				contactName = `${first_name}`
			} else if (email) {
				contactName = email
			}

			feedOptions.push({ _id, name: contactName })

			return (
				<React.Fragment key={`${contactID}-${date}`}>
					{printDate && <Typography variant="h6">{convertDateStringToNewDateString(date)}</Typography>}
					<Card className={classes.contactsCard} onClick={() => this.openTimeline(contactID, contactName)}>
			      <CardContent className={classes.contactsCardContent}>
			        <Typography variant="h5" className={classes.contactsCardName}>{contactName}</Typography>
			        {this.handleEvents(false, contact)}
			      </CardContent>
			    </Card>
		    </React.Fragment>
			)
		})

		return { htmlFeed, feedOptions }
	}

	clearTimeline = () => {
		this.setState({timelineClicked: false})
	}

	feedChanged = (feed) => {
		this.setState({
			loading: true,
			timelineClicked: false
		})

		this.props.loadPageData(feed, null, () => {this.setState({loading: false})})
	}

	handleContactSearch = (contactId) => {
		this.setState({ contactSearch: contactId })
	}

	// createContactsOptions = (feedOptions) => {
	//   // const companyOptions = companies.map((company) => {
	//   //   const { _id, name, createdAt } = company

	//   //   return { _id, name, createdAt }
	//   // })

	//   return feedOptions
	// }
	
	render() {
		const { company, appUser, alerts, contacts, classes, contactFeedForm } = this.props,
					{ loading, timelineClicked, contactName, itemsType, clientWidth } = this.state,
					alertsRoot = checkAlerts(company, alerts, classes),
					feed = contactFeedForm.values ? contactFeedForm.values.feed : '0'

		let feedName = ''

		switch(feed) {
			case '0':
				feedName = 'Recently Opened'
				break
			case '1':
				feedName = 'Shared But Not Opened'
				break
			case '2':
				feedName = 'All Contacts'
				break
			default:
				feedName = 'Recently Opened'
		}

		let offset = 115

		if (clientWidth < 1280 ) {
			offset = 170
		}

		if (!appUser) {
			return (
			  <Redirect to='/content/library'/>
			)
		}

		const feedResults = this.handleFeed(feed)

		return (
			<div className={`${classes.root} ${classes.contactsRoot} ${alertsRoot}`}>
				<Typography variant="h3">Contacts</Typography>
				<form style={{height: 'initial'}} noValidate autoComplete="off" className={classes.dashboardForm}>
					<Grid container spacing={2}>
						<Grid item>
							<Field
							  name="feed"
							  component={RenderSelectField}
							  marginClass={'none'}
							  updateOnChange={this.feedChanged}
							  className={classes.contactsDropdown}
							>
							  <MenuItem key='0' value='0'>Recently Opened</MenuItem>
							  <MenuItem key='1' value='1'>Shared, Not Opened</MenuItem>
							  <MenuItem key='2' value='2'>All Contacts</MenuItem>
							</Field>
						</Grid>
						<Grid item>
							<Field
							  name="company"
							  label="Search for Contact"
							  component={RenderAutocompleteField}
							  marginClass={'none'}
							  className={classes.dropdownContactSearch}
							  updateOnChange={this.handleContactSearch}
							  options={feedResults ? feedResults.feedOptions : []}
							/>
						</Grid>
					</Grid>
				</form>
				{loading &&
					<LoadingDivContainer />
				}
				{!loading &&
					<Grid container spacing={4} style={{marginTop: 10, height: '100%'}}>
						{(clientWidth >= 600 || (clientWidth <= 600 && !timelineClicked)) &&
							/*(clientWidth >= 1280 || (clientWidth >= 600 && !itemsClicked) || (clientWidth <= 600 && !timelineClicked && !itemsClicked)) &&*/
							<Grid item sm={6} xs={12} style={{height: `calc(100% - ${offset}px)`, overflow: 'auto', paddingTop: 0, marginTop: 16}}>
								<Grid container spacing={2} direction="row" alignItems="center" className={classes.contactsColumnHeaderContainer}>
									<Grid item>
										<Typography variant="h5" className={classes.contactsColumnHeader}>{feedName}</Typography>
									</Grid>
								</Grid>
								{feedResults && feedResults.htmlFeed}
							</Grid>
						}
						{(clientWidth >= 600 || (clientWidth <= 600 && timelineClicked)) &&
							/*(clientWidth >= 1280 || (clientWidth >= 600 && timelineClicked) || (clientWidth <= 600 && timelineClicked && !itemsClicked)) &&*/
							<Grid item sm={6} xs={12} style={{height: `calc(100% - ${offset}px)`, overflow: 'auto', paddingTop: 0, marginTop: 16}}>
								{timelineClicked && 
									<React.Fragment>
										<Grid container spacing={2} direction="row" alignItems="center" className={classes.contactsColumnHeaderContainer}>
											<Grid item>
												<Button onClick={this.clearTimeline} variant="contained" className={classes.contactsButton}>
													<ArrowLeft className={classes.contactsListArrow} />
													{clientWidth >= 700 && 'Back'}
												</Button>
											</Grid>
											<Grid item>
												<Typography variant="h5" className={classes.contactsColumnHeader}> {contactName}</Typography>
											</Grid>
										</Grid>
										{this.handleTimeline()}
									</React.Fragment>
								}
							</Grid>
						}
						{/*itemsClicked &&
							<Grid item lg={4} sm={6} xs={12} style={{height: `calc(100% - ${offset}px)`, overflow: 'auto', paddingTop: 0, marginTop: 16}}>
								<React.Fragment>
									<Typography variant="h5"><ArrowLeft onClick={this.clearItems} className={classes.contactsListArrow} /> {itemsClicked ? `${itemsType} Items` : ''}</Typography>
									<Card className={classes.contactsCard}>
							      <CardContent className={classes.contactsCardContent}>
							        <ul className={classes.contactsItemList}>
							        	{this.handleItems()}
							        </ul>
							      </CardContent>
							    </Card>
						    </React.Fragment>
							</Grid>
						*/}
					</Grid>
				}
			</div>
		)
	}
}

const ContactsWithLoading = reduxForm({
    form: 'ContactFeed',
    enableReinitialize: true,
  })(WithLoading(Contacts))

class ContactsContainer extends Component {
  state = {
    loading: true
  }

  loadPageData = (feed, newAppProfile, callback) => {
    const { token, companyId, appProfile, owner, contactFeed, sharedNotOpened, history, loggedOut } = this.props;

    verifyLoggedIn(history, loggedOut, () => {
      const handleAppProfile = newAppProfile ? newAppProfile : appProfile ? appProfile._id : null

      let loadData = [];

      if (feed === '1') {
      	loadData.push(sharedNotOpened(token, {company: companyId, app_profile: handleAppProfile, user: owner, timezone: TIMEZONE }, data => {}))
      } else {
      	loadData.push(contactFeed(token, {company: companyId, app_profile: handleAppProfile, user: owner, timezone: TIMEZONE }, data => {}))
      }

      Promise.all(loadData)
        .then(result => {
          if (callback) {
          	callback()
        	}

          this.setState({
            loading: false
          })    
        })
    });
  }

  componentDidMount() {
    this.loadPageData()
  }

  //if user changes app profiles
  shouldComponentUpdate(nextProps, nextState) {
    const { appProfile } = this.props,
          newAppProfile = nextProps.appProfile,
          oldId = appProfile._id,
          newId = newAppProfile._id

    if (oldId !== newId) {
      this.setState({loading: true})
      this.loadPageData(null, newId)
    }

    return true
  }

  render() {
    const loading = this.state.loading;

    return (
      <ContactsWithLoading 
        isLoading={loading}
        initialValues={{feed: '0'}}
        loadPageData={this.loadPageData}
        {...this.props}
      />
    )
  }
}

function mapStateToProps(state) {
  if (_.isEmpty(state.login.token) || _.isEmpty(state.login.company) || _.isEmpty(state.login.user)) {
    return {
      loggedOut: true
    }
  }

  return { 
    token: state.login.token,
    companyId: state.login.company._id,
    company: state.company,
    enterprise: state.login.user.user_type > '1' ? false : state.login.company.enterprise,
    alerts: state.alerts,
    live_only: state.login.company.live_only ? state.login.company.live_only : state.login.user.user_type > '1',
    enterpriseAppUser: handleEnterpriseAppUser(state),
    appUser: handleAppUser(state),
    appProfile: state.appProfile,
    owner: state.login.user._id,
    currentUser: state.login.user,
    contacts: state.contacts,
    contact: state.contact,
    contactFeedForm: state.form.ContactFeed ? state.form.ContactFeed : {},
    libraryItems: state.fetchLibraryItems
  };
}

const mapDispatchToProps = dispatch => ({
	contactFeed: (token, values, callback) => dispatch(contactFeed(token, values, callback)),
	sharedNotOpened: (token, values, callback) => dispatch(sharedNotOpened(token, values, callback)),
	timeline: (token, values, callback) => dispatch(timeline(token, values, callback)),
	fetchLibraryItems: (token, values, callback) => dispatch(fetchLibraryItems(token, values, callback))
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(ContactsContainer)