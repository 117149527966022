import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';
import { styles } from '../../styles';

// import { IconButton } from '@material-ui/core';
// import { MoreVert } from '@material-ui/icons';

import IconButton from '@material-ui/core/IconButton';

import MoreVert from '@material-ui/icons/MoreVert';

class LibraryGroupMenu extends Component {
  render() {
    const { value, handleLibraryGroupMenu, selectedGroupId, classes } =
      this.props;

    return (
      <div className={classes.marginRightSmall}>
        <IconButton
          id={value._id}
          parent={value.parent}
          name={value.name}
          level={value.level}
          aria-label='Library Group Menu'
          onClick={handleLibraryGroupMenu}
          className={classes.iconRight}
        >
          <MoreVert
            className={
              selectedGroupId === value._id
                ? `${classes.verticalDots} ${classes.verticalDotsSmall} ${classes.selectedVerticalDots}`
                : `${classes.verticalDots} ${classes.verticalDotsSmall}`
            }
          />
        </IconButton>
      </div>
    );
  }
}

export default withStyles(styles)(LibraryGroupMenu);
