import _ from "lodash";
import React, { Component } from "react";
import { compose } from 'redux';
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import Papa from 'papaparse';

import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';

import WithLoading from './subcomponents/withLoading';
import { ErrorText } from './subcomponents/form_subcomponents/errorText';
import RenderAutocompleteField from './subcomponents/form_subcomponents/renderAutocompleteField';
import { RenderSelectField } from './subcomponents/form_subcomponents/renderSelectField';
import { RenderSwitch } from './subcomponents/form_subcomponents/renderSwitch';
import { RenderUploadLabel } from './subcomponents/form_subcomponents/renderUploadLabel';
import { RenderUpload } from './subcomponents/form_subcomponents/renderUpload';
import { RenderTextField } from './subcomponents/form_subcomponents/renderTextField';

import { validateEmail } from './helpers/helperFunctions';

import { verifyMasterToken, fetchCompanies, fetchAdmins, changeCompany, changeBackToMasterAdmin, createUser, uploadContent, createFullCompany, logout } from "../actions/actions_index";

class MasterAdminForm extends Component {
	constructor(props) {
    super(props);

    this.state = {
      company_users: false,
      uploaded_users: null,
      uploaded_content: null,
      uploaded: false,
      error: null
    }
    
    //resets form fields
    this.props.initialize();
  }

  handleUploadedUsers = ({ selectedCompany, selectedUser, uploaded_users }) => {
    const final_uploaded_users = uploaded_users.map(upload => {
      //enterprise/team -> need if adding directly to teams

      const newUser = {
        company: selectedCompany._id,
        enterprise: selectedCompany.enterprise,
        owners: [selectedUser._id],
        editable_by: 1,
        status: false,
        email: upload[0], //whatever order on csv
        first_name: upload[1],
        last_name: upload[2],
        user_type: upload[3]
      }

      return newUser
    })

    return final_uploaded_users
  }

  handleUploadedContent = ({ selectedCompany, selectedUser, uploaded_content }) => {
    const final_uploaded_content = uploaded_content.map(upload => {

      const newContent = {
        company: selectedCompany._id,
        enterprise: selectedCompany.enterprise,
        owners: [selectedUser._id],
        editable_by: 1,
        name: upload[0], //whatever order on csv
        description: upload[1],
        path: upload[2],
        shareable: upload[3],
        persona_tags: upload[4],
        product_tags: upload[5],
        meta_tags: upload[6]
      }

      return newContent
    })

    // const temp_final_uploaded_content = [final_uploaded_content[0], final_uploaded_content[1], final_uploaded_content[2]]
    // return temp_final_uploaded_content

    return final_uploaded_content
  }

	submitMasterAdminForm = (values) => {
		const { token, masterId, companies, users, verifyMasterToken, changeCompany, createUser, uploadContent, handleRedirectToCompany } = this.props;
    const { uploaded_users, uploaded_content } = this.state;
    const { company, user, upload_users, upload_content } = values;

		const selectedCompany = _.find(companies, {_id: company})
		const selectedUser = _.find(users, {_id: user})
    
		verifyMasterToken(token, masterId, verify => {
			if (verify) {
        if (upload_users && uploaded_users) {
          const final_uploaded_users = this.handleUploadedUsers({selectedCompany, selectedUser, uploaded_users})

          createUser(token, final_uploaded_users, results => {
            this.setState({
              uploaded: true
            })
          })
        } else if (upload_content && uploaded_content) {
          const final_uploaded_content = this.handleUploadedContent({selectedCompany, selectedUser, uploaded_content})

          uploadContent(token, final_uploaded_content, results => {
            this.setState({
              uploaded: true
            })
          })
        } else if (upload_users || upload_content) {
          this.setState({
            error: 'You must upload a csv.'
          })
        } else {
          const data = { company: selectedCompany, user: selectedUser }

          changeCompany(data, update => {
            handleRedirectToCompany();
          })
        }
			}
		})
	}

	updateUsers = (companyId) => {
		const { token, fetchAdmins } = this.props;

		this.setState({company_users: false})

		fetchAdmins(token, companyId, users => {
			this.setState({company_users: true})
		})
	}

  handleUpload = (name, value, uploaded, upload_type) => {
    const file = value[0];
    const blob = file instanceof Blob;

    if (blob) {
      const config = {
        complete: (results, file) => {
          console.log("Parsing complete:", results);
          const uploaded_data = results.data;

          if (upload_type === 'users') {
            this.setState({ uploaded_users: uploaded_data })
          } else if (upload_type === 'content') {
            this.setState({ uploaded_content: uploaded_data })
          }
        }
      }

      Papa.parse(file, config)
    }
  }

  createCompanyOptions = (companies) => {
    const companyOptions = companies.map((company) => {
      const { _id, name, createdAt } = company

      return { _id, name, createdAt }
    })

    return companyOptions
  }

	render() {
		const { companies, users, handleSubmit, forms, pristine, invalid, classes, history, loggedOut } = this.props;
		const { company_users, uploaded, error } = this.state;

    let uploadingUsers = false,
        uploadingContent = false,
        selectedCompany = null,
        selectedUser = null;

    if (loggedOut) {
      history.push('/login');
    }

    if (!pristine && !invalid) {
      selectedCompany = forms.MasterAdmin.values.company;
      selectedUser = forms.MasterAdmin.values.user;
      uploadingUsers = forms.MasterAdmin.values.upload_users;
      uploadingContent = forms.MasterAdmin.values.upload_content;
    }

		return(
			<form noValidate className={classes.dashboardForm} onSubmit={handleSubmit(values => this.submitMasterAdminForm(values))}>
        <ErrorText errorClassName={classes.formError} touched={true} error={error} />
        <Field
          name="company"
          label="Search for Company"
          component={RenderAutocompleteField}
          marginClass={'none'}
          className={classes.dropdownMasterAdmin}
          updateOnChange={this.updateUsers}
          options={this.createCompanyOptions(companies)}
        />

        {/*
        <Field
				  name="company"
				  label="Companies"
				  component={RenderSelectField}
				  marginClass={'none'}
				  className={classes.dropdownMasterAdmin}
				  updateOnChange={this.updateUsers}
				>
				  {companies.map((company) => 
				    <MenuItem className={classes.masterCompany} key={company._id} value={company._id}>
              <div className={classes.masterCompanyName}>{company.name}</div>
              <div className={classes.masterCompanyCreatedAt}>{convertToDateString(company.createdAt)}</div>
            </MenuItem>  
				  )}
				</Field>
        */}

				{company_users &&
					<Field
					  name="user"
					  label="Users"
					  component={RenderSelectField}
					  marginClass={'none'}
					  className={`${classes.dropdownMasterAdmin} ${classes.secondDropdownMasterAdmin}`}
					>
					  {users && Array.isArray(users) && users.map((user) => 
					    <MenuItem key={user._id} value={user._id}>{user.email}</MenuItem>  
					  )}
					</Field>
				}
        <Grid container spacing={5}>
          <Grid item xs={3}>
            {(selectedCompany && selectedUser) &&
              <React.Fragment>
                <Field type="checkbox" name="upload_users" label="Upload Users" component={RenderSwitch} />
                <Field type="checkbox" name="upload_content" label="Upload Content" component={RenderSwitch} />
              </React.Fragment>
            }
          </Grid>
          <Grid item xs={3}>
            {uploadingUsers &&
              <FormControl margin="normal">
                <RenderUploadLabel
                  htmlFor={'users-upload'}
                  addText={'Upload Users'}
                  classes={classes}
                />
                <Field 
                  id="users-upload"
                  name="files"
                  type="file"
                  upload_type="users"
                  className={classes.uploadedName}
                  updatePreview={this.handleUpload}
                  component={RenderUpload}
                />
              </FormControl>
            }
            {uploadingContent &&
              <FormControl margin="normal">
                <RenderUploadLabel
                  htmlFor={'content-upload'}
                  addText={'Upload Content'}
                  classes={classes}
                />
                <Field 
                  id="content-upload"
                  name="files"
                  type="file"
                  upload_type="content"
                  className={classes.uploadedName}
                  updatePreview={this.handleUpload}
                  component={RenderUpload}
                />
              </FormControl>
            }
          </Grid>
          <Grid item xs={6}>
            {uploaded &&
              <div className={`${classes.topMessageContainer} ${classes.topMessageLeftContainer}`}>
                <Typography className={classes.topMessage} type="body1" color="primary">Users or Content Successfully Uploaded</Typography>
              </div>
            }
          </Grid>
        </Grid>
        <br />
				<div>
					<Button className={classes.masterAdminButton} variant="contained" color="secondary" type="submit" disabled={pristine || invalid}>Submit</Button>
				</div>
			</form>
		)
	}
}

function mapStateToProps(state) {
  if (_.isEmpty(state.login.token) || _.isEmpty(state.login.company) || _.isEmpty(state.login.user)) {
    return {
      loggedOut: true
    }
  }

  return { 
    token: state.login.token,
    companies: state.companies,
    users: state.admins,
    masterId: state.login.user._id,
    master: state.login.user,
    master_admin: state.login.master_admin,
    forms: state.form
  };
}

const mapDispatchToProps = dispatch => ({
  verifyMasterToken: (token, masterId, callback) => dispatch(verifyMasterToken(token, masterId, callback)),
  fetchCompanies: (token, masterId, callback) => dispatch(fetchCompanies(token, masterId, callback)),
  fetchAdmins: (token, companyId, callback) => dispatch(fetchAdmins(token, companyId, callback)),
  createUser: (token, values, teamId, callback) => dispatch(createUser(token, values, teamId, callback)),
  uploadContent: (token, values, callback) => dispatch(uploadContent(token, values, callback)),
  createFullCompany: (values, callback) => dispatch(createFullCompany(values, callback)),
  changeCompany: (company, callback) => dispatch(changeCompany(company, callback)),
  changeBackToMasterAdmin: (callback) => dispatch(changeBackToMasterAdmin(callback)),
  logout: () => dispatch(logout())
})

function validateMasterAdmin(values) {
  const errors = {}

  const requiredFields = [
    'company',
    'user'
  ]

  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = 'Required';
    }
  })

  return errors
}

export const MasterAdminFormContainer = reduxForm({
  form: 'MasterAdmin',
  validate: validateMasterAdmin,
  enableReinitialize: true,
})(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MasterAdminForm)));

class CreateCompanyForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      created: false,
      error: null
    }
    
    //resets form fields
    this.props.initialize();
  }

  submitCreateCompanyForm = (values) => {
    const { token, createFullCompany } = this.props;

    const newValues = {
      ...values,
      enterprise: true,
      onboarding: true
    }

    createFullCompany(newValues, results => {
      this.setState({
        created: true
      })
    })
  }

  render() {
    const { handleSubmit, pristine, invalid, classes } = this.props;
    const { created, error } = this.state;

    return(
      <form noValidate className={classes.dashboardForm} onSubmit={handleSubmit(values => this.submitCreateCompanyForm(values))}>
        {created &&
          <div className={`${classes.topMessageContainer} ${classes.topMessageLeftContainer}`}>
            <Typography className={classes.topMessage} type="body1" color="primary">Company Created Successfully</Typography>
          </div>
        }
        <Field name="name" className={classes.masterAdminTextfield} component={RenderTextField} type="text" label="Company Name" autoComplete="new-email" />
        <Field name="first_name" className={classes.masterAdminTextfield} component={RenderTextField} type="text" label="First Name" autoComplete="new-email" />
        <Field name="last_name" className={classes.masterAdminTextfield} component={RenderTextField} type="text" label="Last Name" autoComplete="new-email" />
        <Field name="email" className={classes.masterAdminTextfield} component={RenderTextField} type="email" label="Email" autoComplete="new-email" />
        <Field name="password" className={classes.masterAdminTextfield} component={RenderTextField} type="text" label="Password" autoComplete="new-email" />
        <br />
        <br />
        <div>
          <Button className={classes.masterAdminButton} variant="contained" color="secondary" type="submit" disabled={pristine || invalid}>Submit</Button>
        </div>
      </form>
    )
  }
}

function validateCreateCompany(values) {
  const errors = {};

  const requiredFields = [
    'name',
    'first_name',
    'last_name',
    'email',
    'password'
  ];

  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = 'Required';
    }
  });

  if (values.email && validateEmail(values.email)) {
    errors['email'] = 'Invalid email address';
  }

  return errors
}

export const CreateCompanyFormContainer = reduxForm({
  form: 'CreateCompany',
  validate: validateCreateCompany,
  enableReinitialize: true,
})(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CreateCompanyForm)));

class MasterAdmin extends Component {
  state = {
    redirectToCompany: false
  }

  handleRedirectToCompany = () => {
  	this.setState({
  		redirectToCompany: true
  	})
  }

 	render() {
 		const { failVerify, logout, classes } = this.props;
 		const { redirectToCompany } = this.state;

 		if (failVerify || redirectToCompany) {
 		  return (
 		    <Redirect to='/content/library'/>
 		  )
 		}

 		return (
 			<div className={classes.masterAdminContainer}>
	 			<Typography variant="h2" className={classes.masterAdminHeading}>
          Master Admin
          <span className={classes.masterAdminLogout} onClick={logout}>logout</span>
        </Typography>

        <Typography variant="h6" className={classes.masterAdminSubHeading}>
          Login or Upload Users
        </Typography>
	 			<MasterAdminFormContainer handleRedirectToCompany={this.handleRedirectToCompany} />

        <Typography variant="h6" className={classes.masterAdminSubHeading2}>
          Create New Enterprise Company
        </Typography>
        <CreateCompanyFormContainer />
	 		</div>
 		)
 	}

}

const MasterAdminWithLoading = WithLoading(MasterAdmin)

class MasterAdminContainer extends Component {
  state = {
    loading: false,
    failVerify: false
  }

  finishComponentDidMount = (master_admin) => {
    const { token, masterId, verifyMasterToken, fetchCompanies } = this.props;

    let updateMasterId = masterId;
    
    //redux wasn't updating in time, so had to pass the master admin info...
    if (master_admin) {
      updateMasterId = master_admin.user._id;
    }

    verifyMasterToken(token, updateMasterId, verify => {
      if (verify.data) {
        fetchCompanies(token, updateMasterId, companies => {
          this.setState({loading: false})
        })
      } else {
        this.setState({failVerify: true})
      }
    })
  }

  componentDidMount() {
    const { master_admin, changeBackToMasterAdmin } = this.props;

    if (master_admin) {
      changeBackToMasterAdmin(callback => {
        this.finishComponentDidMount(master_admin);
      })
    } else {
      this.finishComponentDidMount();
    }
  }

  render() {
    const { loading, failVerify } = this.state;

    return (
      <MasterAdminWithLoading
        isLoading={loading}
        failVerify={failVerify}
        {...this.props}
      />
    )
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(MasterAdminContainer)
