import _ from "lodash";
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

import { withStyles } from '@material-ui/core/styles';
import { styles } from '../styles';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';

import { activeCampaignEvent } from '../helpers/helperFunctions';

import { updateCompany, updateUser } from '../../actions/actions_index';

import { verbGray } from '../../theme';

class OnboardingCompleted extends Component {

  adminPortal = () => {
    const { token, companyId, updateCompany } = this.props;

    const values = {
      onboarding: false,
      tour: true
    }

    updateCompany(token, values, companyId, () => {
      this.props.history.push('/content/library')
    });
  }

  componentDidMount() {
    const { token, enterprise, owner, currentUser, history, updateUser, master_admin } = this.props;

    let status = currentUser.active_campaign_status;

    if (status <= '3') {
      status = '4';
    }

    updateUser(token, {active_campaign_status: status}, owner, () => {})

    const values = {
      token,
      master_admin,
      enterprise,
      email: currentUser.email,
      page: history.location.pathname
    }

    activeCampaignEvent(values)
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.marginTop}>
        <Typography variant="h1" className={classes.onboardingH1}>
          <div className={classes.onboardingStepNumber}>6</div>Complete
        </Typography>
        <Grid container spacing={4} className={classes.gridExtraSpacing} justify="center" alignItems="center">
          <Grid item sm={3} xs={false}>
          </Grid>
          <Grid item sm={6} xs={12}>
            <Typography variant="subtitle1" className={classes.onboardingSteps}>
              Step <span className={classes.onboardingStepsBold}>6</span> of 6
            </Typography>
            <LinearProgress color="secondary" variant="determinate" value={100} />
          </Grid>
          <Grid item sm={3} xs={false}>
          </Grid>
        </Grid>
        <Grid className={`${classes.gridTagsSpacing}`} container spacing={4} justify="center" alignItems="center">
          <Grid item md={6} xs={12} className={classes.onboardingGrid}>
            <div className={classes.onboardingImageContainer}>
              <img className={classes.onboardingImage} src="/images/verb_onboarding/complete.jpg" />
            </div>
          </Grid>
          <Grid item md={6} xs={12} className={`${classes.onboardingGrid} ${classes.onboardingGridLeftTextArea}`}>
            <Typography variant="h2" className={classes.onboardingH2}>
              Done!
            </Typography>
            <Typography variant="subtitle1" className={`${classes.onboardingSubtitle} ${classes.onboardingSubtitleContentGroups}`}>
              You are seconds away from giving you and your team an amazing content sharing experience!
            </Typography>
            <Typography variant="subtitle1" className={`${classes.onboardingSubtitle} ${classes.onboardingSubtitleContentGroups}`}>
              Your management portal and your apps are ready, go check them out.
            </Typography>
            <br/>
            <Typography variant="h2" className={classes.onboardingH2} style={{fontSize: '1.2rem', marginBottom: 5, color: verbGray}}>
              Welcome to
            </Typography>
            <img className={classes.onboardingWelcomeImage} src="/images/verb_onboarding/verb_teams.png" style={{maxWidth: 400}} />
            <br />
            <br />
            <div className={classes.onboardingButtonContainer}>
              {/*<Button className={`${classes.button} ${classes.onboardingCompletedButton}`} variant="contained" color="secondary" onClick={() => window.open("https://solofire.com/download/", "_blank")}>Go Get the App Now</Button>*/}
              <Button className={`${classes.button} ${classes.onboardingButton} ${classes.onboardingCompletedButton}`} variant="contained" color="secondary" onClick={this.adminPortal}>Continue to Admin Portal</Button>
            </div>
          </Grid>
        </Grid> 
      </div>
    )
  }
}

function mapStateToProps(state) {
  if (_.isEmpty(state.login.token) || _.isEmpty(state.login.company) || _.isEmpty(state.login.user)) {
    return {
      loggedOut: true
    }
  }

  return { 
    token: state.login.token,
    master_admin: state.login.master_admin,
    companyId: state.login.company._id,
    enterprise: state.login.company.enterprise,
    owner: state.login.user._id,
    currentUser: state.login.user
  };
}

const mapDispatchToProps = dispatch => ({
  updateCompany: (token, values, id, callback) => dispatch(updateCompany(token, values, id, callback)),
  updateUser: (token, values, id, callback) => dispatch(updateUser(token, values, id, callback))
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(OnboardingCompleted)
