import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

import { withStyles } from '@material-ui/core/styles';
import { styles } from '../styles';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Modal from '@material-ui/core/Modal';

import Close from '@material-ui/icons/Close';

import LoadingDivContainer from '../subcomponents/loadingDiv';
import { DeletePrivileges } from '../subcomponents/commonComponents';
import GridContainer from '../subcomponents/gridContainer';

import {
  cleanPutValues,
  modalTableHeight,
  validateName,
  cleanName,
  handleAppUser,
} from '../helpers/helperFunctions';

import { RenderTextField } from '../subcomponents/form_subcomponents/renderTextField';
import { RenderSwitch } from '../subcomponents/form_subcomponents/renderSwitch';
import { RenderOwnerEditableByMenu } from '../subcomponents/form_subcomponents/renderOwnerEditableByMenu';

import {
  fetchAdmins,
  fetchAppProfiles,
  fetchLibraryGroup,
  createLibraryGroup,
  updateLibraryGroup,
  deleteLibraryGroup,
} from '../../actions/actions_index';

class LibraryGroupForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedChange: false,
      selectedItems: [],
    };

    this.onSelectionChange = (selected) => {
      this.setState({
        selectedChange: true,
        selectedItems: selected,
      });
    };

    this.props.reset();
  }

  componentDidMount() {
    const { enterprise, addSubGroup, groupId, initialValues, appProfiles } =
      this.props;

    if (!enterprise && !addSubGroup && initialValues.level === 1) {
      let selectedGroups = [];

      appProfiles.map((profile, index) => {
        if (groupId && profile.library_groups.includes(groupId)) {
          selectedGroups.push(index);
        } else if (!groupId) {
          //not sure why this is here...
          selectedGroups.push(index);
        }
        return null;
      });

      this.setState({
        selectedItems: selectedGroups,
      });
    }
  }

  teams = () => {
    const { appProfiles, classes } = this.props;
    const { selectedItems } = this.state;

    const columns = [
      { name: 'name', title: 'Name' },
      { name: 'updatedAt', title: 'Last Updated' },
    ];

    const tableColumnExtensions = [
      { columnName: 'name' },
      { columnName: 'updatedAt', width: 170 },
    ];

    const sortingStateColumnExtensions = [
      { columnName: 'name', sortingEnabled: true },
      { columnName: 'updatedAt', sortingEnabled: true },
    ];

    const filteringStateColumnExtensions = [
      { columnName: 'name', filteringEnabled: true },
      { columnName: 'updatedAt', filteringEnabled: false },
    ];

    const sorting = [{ columnName: 'name', direction: 'asc' }];

    const rows = appProfiles;

    return (
      <div
        style={{
          position: 'relative',
          marginTop: 10,
          height: 'calc(100% - 100px)',
        }}
      >
        <Typography
          variant='subtitle1'
          className={`${classes.modalSubheadingTableAssignToTeams}`}
        >
          Assign Content Group to Teams
        </Typography>
        <GridContainer
          gridHeight={modalTableHeight()}
          rows={rows}
          columns={columns}
          tableColumnExtensions={tableColumnExtensions}
          sortingStateColumnExtensions={sortingStateColumnExtensions}
          sorting={sorting}
          selection={selectedItems}
          onSelectionChange={this.onSelectionChange}
          filteringStateColumnExtensions={filteringStateColumnExtensions}
          showSelectAll={true}
          showSelectionColumn={true}
          showRowDetail={false}
          classes={classes}
        />
      </div>
    );
  };

  libraryGroupForm = () => {
    const {
      enterprise,
      verb_live,
      appUser,
      currentUser,
      admins,
      groupId,
      deleteGroup,
      initialValues,
      addSubGroup,
      pristine,
      invalid,
      classes,
    } = this.props;
    const { selectedChange } = this.state;

    let autoFocus = false;
    if (!groupId) {
      autoFocus = true;
    }

    if (deleteGroup) {
      return (
        <div>
          <Button
            className={`${classes.button} ${classes.modalButton}`}
            variant='contained'
            type='submit'
            color='secondary'
          >
            Delete
          </Button>
        </div>
      );
    }

    return (
      <div>
        <Field
          name='name'
          component={RenderTextField}
          type='text'
          label='Name'
          autoFocus={autoFocus}
        />
        <Field
          type='text'
          name='description'
          multiline={3}
          component={RenderTextField}
          label='Description'
        />
        {!appUser && (
          <React.Fragment>
            <Field
              type='checkbox'
              name='presentation'
              label='Include as Presentation in App'
              component={RenderSwitch}
            />
            <Field
              type='checkbox'
              name='hidden'
              label='Hide Group from App'
              component={RenderSwitch}
            />
            <Field
              type='checkbox'
              name='locked'
              label='Lock Group from App'
              component={RenderSwitch}
            />
            <RenderOwnerEditableByMenu
              currentUser={currentUser}
              admins={admins}
              selectedId={groupId}
              initialValues={initialValues}
            />
          </React.Fragment>
        )}

        {!enterprise &&
          !verb_live &&
          !appUser &&
          !addSubGroup &&
          (initialValues.level === 1 || !initialValues.level) &&
          this.teams()}
        {!groupId && !addSubGroup && (
          <div>
            <Field
              type='checkbox'
              name='collectionContainer'
              label='Collection Container only'
              component={RenderSwitch}
            />
            <Typography variant='subtitle1'>
              Collection containers cannot be assigned to app profiles but their
              sub groups can.
            </Typography>
          </div>
        )}
        <Button
          className={`${classes.button} ${classes.modalButton}`}
          variant='contained'
          type='submit'
          color='secondary'
          disabled={(pristine || invalid) && !selectedChange}
        >
          Submit
        </Button>
      </div>
    );
  };

  submitLibraryGroupModal = (values) => {
    const {
      enterprise,
      appUser,
      owner,
      deleteGroup,
      parentId,
      groupId,
      appProfiles,
      addSubGroup,
      submittingLibraryGroupModal,
      closedLibraryGroupModal,
    } = this.props;
    const token = this.props.token;
    const selectedItems = this.state.selectedItems;

    submittingLibraryGroupModal();

    values.name = cleanName(values.name);

    values.company = this.props.companyId;

    if (parentId) {
      values.parent = parentId;
    }

    if (!enterprise && !addSubGroup) {
      const added = selectedItems.map((value) => {
        return appProfiles[value]._id;
      });

      values.teams = added;
    }

    if (appUser) {
      values.personal = owner;
    }

    if (values.collectionContainer) {
      values.level = 0;
    }

    if (groupId && !deleteGroup) {
      values = cleanPutValues(values);

      this.props.updateLibraryGroup(token, values, groupId, (data) => {
        closedLibraryGroupModal(true, false, data.data);
      });
    } else if (groupId && deleteGroup) {
      this.props.deleteLibraryGroup(token, groupId, () => {
        closedLibraryGroupModal(true);
      });
    } else {
      this.props.createLibraryGroup(token, values, (data) => {
        closedLibraryGroupModal(true, true, data.data);
      });
    }
  };

  render() {
    const { handleSubmit } = this.props;

    return (
      <form
        noValidate
        autoComplete='off'
        onSubmit={handleSubmit((values) =>
          this.submitLibraryGroupModal(values)
        )}
      >
        {this.libraryGroupForm()}
      </form>
    );
  }
}

function validate(values, props) {
  const errors = {};
  const requiredFields = ['name'];

  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = 'Required';
    }
  });

  if (values.owners && values.owners.length === 0) {
    errors['owners'] = 'You must select at least one owner.';
  }

  const checkString = validateName(values.name);

  if (checkString && !checkString.pass) {
    errors['name'] = checkString.error;
  }

  return errors;
}

function mapStateToProps(state) {
  return {
    token: state.login.token,
    companyId: state.login.company._id,
    enterprise: state.company.enterprise,
    verb_live: state.company.verb_live,
    appUser: handleAppUser(state),
    owner: state.login.user._id,
    currentRole: state.login.user.user_type,
    currentUser: state.login.user,
    admins: state.admins,
    appProfiles: state.appProfiles,
    libraryGroup: state.libraryGroup,
  };
}

const mapDispatchToProps = (dispatch) => ({
  fetchAdmins: (token, companyId, callback) =>
    dispatch(fetchAdmins(token, companyId, callback)),
  fetchAppProfiles: (token, companyId, callback) =>
    dispatch(fetchAppProfiles(token, companyId, callback)),
  fetchLibraryGroup: (token, id, callback) =>
    dispatch(fetchLibraryGroup(token, id, callback)),
  createLibraryGroup: (token, values, callback) =>
    dispatch(createLibraryGroup(token, values, callback)),
  updateLibraryGroup: (token, values, id, callback) =>
    dispatch(updateLibraryGroup(token, values, id, callback)),
  deleteLibraryGroup: (token, id, callback) =>
    dispatch(deleteLibraryGroup(token, id, callback)),
});

const LibraryGroupFormContainer = reduxForm({
  form: 'LibraryGroup',
  validate,
  enableReinitialize: true,
})(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(LibraryGroupForm))
);

class LibraryGroupModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingGroup: true,
      loadingOther: true,
    };
  }

  componentDidMount() {
    const { token, companyId, enterprise, addSubGroup, selectedGroup } =
      this.props;

    if (selectedGroup && selectedGroup._id) {
      this.props.fetchLibraryGroup(token, selectedGroup._id, (libraryGroup) => {
        this.setState({
          loadingGroup: false,
        });
      });

      if (enterprise) {
        this.props.fetchAdmins(token, companyId, (admins) => {
          this.setState({
            loadingOther: false,
          });
        });
      } else {
        this.props.fetchAppProfiles(token, companyId, (admins) => {
          this.setState({
            loadingOther: false,
          });
        });
      }
    } else if (addSubGroup) {
      const parentId = selectedGroup._id;

      this.props.fetchLibraryGroup(token, parentId, (libraryGroup) => {
        this.setState({
          loadingGroup: false,
        });
      });

      if (enterprise) {
        this.props.fetchAdmins(token, companyId, (admins) => {
          this.setState({
            loadingOther: false,
          });
        });
      } else {
        this.setState({
          loadingOther: false,
        });
      }
    } else {
      this.props.fetchAdmins(token, companyId, (admins) => {
        this.setState({
          loadingGroup: false,
          loadingOther: false,
        });
      });
    }
  }

  modalSize = () => {
    const { enterprise, verb_live, addSubGroup, deleteGroup, classes } =
      this.props;

    if (deleteGroup) {
      return classes.deleteModal;
    } else if (!enterprise && !verb_live && !addSubGroup) {
      return classes.mediumModal;
    } else {
      return classes.smallMediumModal;
    }
  };

  submittingLibraryGroupModal = () => {
    this.setState({
      loadingGroup: true,
    });
  };

  handleModalBody = () => {
    const {
      enterprise,
      appUser,
      owner,
      currentRole,
      addGroup,
      addSubGroup,
      deleteGroup,
      selectedGroup,
      libraryGroup,
      closedLibraryGroupModal,
      classes,
    } = this.props;
    const { loadingGroup, loadingOther, selectedItems } = this.state;

    let id = null,
      parent = null,
      name = '',
      title = '',
      subheading = '',
      subheadingClass = null;

    if (selectedGroup) {
      id = selectedGroup._id;
      name = selectedGroup.name;
    }

    if (addGroup) {
      title = `Add ${appUser ? 'a Personal ' : ''}Content Group`;
      subheading = appUser
        ? 'Add a personal content group to My Files.'
        : 'Add a content group for segmenting your library.';
      subheadingClass = classes.modalSubheading;
      id = null;
    } else if (addSubGroup) {
      title = `Add Sub Content Group to ${name}`;
      subheading = 'Add a sub content group for segmenting your library.';
      subheadingClass = classes.modalSubheading;
      parent = id;
      id = null;
    } else if (id && !deleteGroup) {
      title = (
        <span>
          Edit - <span className={classes.modalTitleItem}>{name}</span>
        </span>
      );
      subheading = "Edit this content group's details.";
      subheadingClass = `${classes.modalSubheading} ${classes.smallHeightSubheading}`;
    } else if (id && deleteGroup) {
      title = (
        <span>
          Delete - <span className={classes.modalTitleItem}>{name}</span>
        </span>
      );
      subheading = (
        <span>
          Are you sure you want to delete <i>{name}</i>?<br />
          <i>NOTE: This will also delete all of its sub groups.</i>
        </span>
      );
      subheadingClass = classes.modalSubheadingDelete;
    }

    if (loadingGroup || loadingOther) {
      if (deleteGroup) {
        return <LoadingDivContainer type={'small'} />;
      } else {
        return <LoadingDivContainer />;
      }
    } else if (
      deleteGroup &&
      currentRole !== '0' &&
      !libraryGroup.owners.includes(owner)
    ) {
      return <DeletePrivileges className={classes.modalSubheadingDelete} />;
    } else {
      let initialValues = {
        editable_by: '1',
        owners: [owner],
      };

      if (!addGroup && !addSubGroup && id) {
        initialValues = libraryGroup;
      } else if (addSubGroup) {
        initialValues.owners = libraryGroup.owners;
      }

      return (
        <div className={classes.divContainer}>
          <Typography variant='h6'>{title}</Typography>
          <Typography variant='subtitle1' className={subheadingClass}>
            {subheading}
          </Typography>
          {(enterprise || addSubGroup) && <br />}
          <LibraryGroupFormContainer
            deleteGroup={deleteGroup}
            addSubGroup={addSubGroup}
            parentId={parent}
            groupId={id}
            initialValues={initialValues}
            selectedItems={selectedItems}
            submittingLibraryGroupModal={this.submittingLibraryGroupModal}
            closedLibraryGroupModal={closedLibraryGroupModal}
          />
        </div>
      );
    }
  };

  render() {
    const { openLibraryGroupModal, closedLibraryGroupModal, classes } =
      this.props;

    return (
      <Modal
        aria-labelledby='add-edit-library-group'
        aria-describedby='add-edit-library-group'
        open={openLibraryGroupModal}
        onClose={() => closedLibraryGroupModal(false)}
      >
        <div className={`${classes.modal} ${this.modalSize()}`}>
          <IconButton
            className={classes.iconClose}
            onClick={() => closedLibraryGroupModal(false)}
          >
            <Close />
          </IconButton>
          {this.handleModalBody()}
        </div>
      </Modal>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(LibraryGroupModal));
