import axios from "axios";
import {ROOT_URL} from "./actions_constants";

import {webAppAllowedCompanies} from '../components/helpers/helperFunctions';

export const LOGIN = "login";
export const TURN_ON_ADMIN_APP_USER = "turn_on_admin_app_user";
export const CHANGE_COMPANY = "change_company";
export const CHANGE_BACK_TO_MASTER_ADMIN = "change_back_to_master_admin";
export const VERIFY_MASTER_TOKEN = "verify_master_token";
export const VERIFY_TOKEN = "verify_token";
export const VERIFY_ROLE = "verify_role";
export const LOGOUT = "logout";
export const USE_SSO = "use_sso";

const loginSuccess = result => ({
    type: LOGIN,
    payload: result
});

export const login = (values, callback) => (dispatch) => {

    if (values) {
        return (axios.post(`${ROOT_URL}token`, values))
            .then(result => {

                let data = result.data;

                if (data.user && data.user.user_type > '1' && data.user.user_type !== 'MA' && !webAppAllowedCompanies(data.user.company) && (data.company && !data.company.web_app)) {
                    data = {
                        error: "You do not have administration access."
                    }
                }

                dispatch(loginSuccess(data))

                if (callback) {
                    callback(data);
                }

                return data;
            })
            .catch(error => {
                console.log(error)

                if (error.response && error.response.data) {
                    callback(error.response.data)
                }
            })
    } else {
        dispatch(loginSuccess(null));
    }
}

const useSSOSuccess = result => ({
    type: USE_SSO,
    payload: result
});

export const useSSO = (values, callback) => (dispatch) => {
    const {email, callbackUrl} = values;
    if (email && callbackUrl) {
        return (axios.post(`${ROOT_URL}oauth/sso_needed`, values))
            .then(result => {
                const data = result.data;

                if (data.loginUrl) {
                    dispatch(useSSOSuccess(data))
                    return;
                }

                if (callback) {
                    callback(data);
                }

                return data;
            })
            .catch(error => {
                console.log(error)

                if (error.response && error.response.data) {
                    callback(error.response.data)
                }
            })
    } else {
        dispatch(useSSOSuccess(null));
    }
}

export const loginViaSSO = (values, callback) => (dispatch) => {

    const {code, state, callback_url} = values;

    console.log('GDATFAZFAD', values);

    if (code && state && callback_url) {
        return (axios.post(`${ROOT_URL}oauth/callback`, values))
            .then(result => {
                console.log(result);

                let data = result.data;

                if (data.user && data.user.user_type > '1' && data.user.user_type !== 'MA' && !webAppAllowedCompanies(data.user.company) && (data.company && !data.company.web_app)) {
                    data = {
                        error: "You do not have administration access."
                    }
                }

                dispatch(loginSuccess(data))

                if (callback) {
                    callback(data);
                }

                return data;
            })
            .catch(error => {
                console.log(error)

                if (error.response && error.response.data) {
                    callback(error.response.data)
                }
            })
    } else {
        dispatch(loginSuccess(null));
    }
}

const turnOnAdminAppUserSuccess = result => ({
    type: TURN_ON_ADMIN_APP_USER,
    payload: result
});

//flip between admin and app user on web side
export const turnOnAdminAppUser = (data) => (dispatch) => {
    dispatch(turnOnAdminAppUserSuccess(data))
}

const changeCompanySuccess = result => ({
    type: CHANGE_COMPANY,
    payload: result
});

export const changeCompany = (data, callback) => (dispatch) => {
    dispatch(changeCompanySuccess(data))
    callback(data)
}

const changeBackToMasterAdminSuccess = result => ({
    type: CHANGE_BACK_TO_MASTER_ADMIN,
    payload: result
});

export const changeBackToMasterAdmin = (callback) => (dispatch) => {
    dispatch(changeBackToMasterAdminSuccess())
    callback()
}

const verifyMasterTokenSuccess = result => ({
    type: VERIFY_TOKEN,
    payload: result
});

export const verifyMasterToken = (token, userId, callback) => (dispatch) => {
    const TOKEN = token;

    return (axios.get(`${ROOT_URL}verify_master_token/${userId}`,
        {
            headers: {
                Authorization: 'Bearer ' + TOKEN
            }
        }))
        .then(result => {
            const verify = result.data;

            dispatch(verifyMasterTokenSuccess(verify));

            if (callback) {
                callback(result);
            }

            return verify;
        })
        .catch(error => {
            console.log(error)

            if (error.response && error.response.data) {
                callback(error.response.data)
            }
        })
}

const verifyTokenSuccess = result => ({
    type: VERIFY_TOKEN,
    payload: result
});

export const verifyToken = (token, userId, callback) => (dispatch) => {
    const TOKEN = token;

    return (axios.get(`${ROOT_URL}verify_token/${userId}`,
        {
            headers: {
                Authorization: 'Bearer ' + TOKEN
            }
        }))
        .then(result => {
            const verify = result.data;

            dispatch(verifyTokenSuccess(verify));

            if (callback) {
                callback(result);
            }

            return verify;
        })
        .catch(error => {
            console.log(error)

            if (error.response && error.response.data) {
                callback(error.response.data)
            }
        })
}

const verifyRoleSuccess = result => ({
    type: VERIFY_ROLE,
    payload: result
});

export const verifyRole = (token, id, callback) => (dispatch) => {

    const TOKEN = token;

    return (axios.get(`${ROOT_URL}users/${id}`,
        {
            headers: {
                Authorization: 'Bearer ' + TOKEN
            }
        }))
        .then(result => {
            const user = result.data;

            dispatch(verifyRoleSuccess(user));

            if (callback) {
                callback(result);
            }

            return user;
        })
        .catch(error => {
            console.log(error)

            if (error.response && error.response.data) {
                callback(error.response.data)
            }
        })
}

export function logout() {
    const request = '';

    return {
        type: LOGOUT,
        payload: request
    }
}