import React, { Component } from 'react';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import { styles } from '../styles';

// import { Typography, Button, IconButton, Modal } from '@material-ui/core';
// import { Close } from '@material-ui/icons';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Modal from '@material-ui/core/Modal';

import Close from '@material-ui/icons/Close';

import LoadingDivContainer from '../subcomponents/loadingDiv';
import { DeletePrivileges } from '../subcomponents/commonComponents';

import { fetchLibraryItem, deleteLibraryItem, updateLibraryItems } from '../../actions/actions_index';

class DeleteLibraryItemModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true
    }
  }

  componentDidMount() {
    const { token, selectedLibraryItemId, selectedContent, fetchLibraryItem } = this.props;

    if (!selectedContent) {
      fetchLibraryItem(token, selectedLibraryItemId, libraryItem => {
        this.setState({
          loading: false
        })
      })
    } else {
      this.setState({
        loading: false
      })
    }
  }

  closeDeleteLibraryItemModal = () => {
    this.props.closedLibraryItemModal(false)
  }

  handleDeleteLibaryItem = () => {
    const { token, selectedGroup, selectedLibraryItemId, selectedContent, deleteLibraryItem, updateLibraryItems, closedLibraryItemModal } = this.props;

    this.submitDeleteLibraryItem();
    
    if (selectedContent) {
      const values = {
        deleteItems: true,
        content: selectedContent
      }

      updateLibraryItems(token, values, data => {
        closedLibraryItemModal(true, selectedGroup);
      })
    } else {
      deleteLibraryItem(token, selectedLibraryItemId, result => {
        closedLibraryItemModal(true, selectedGroup);
      });
    }
  }

  submitDeleteLibraryItem = () => {
    this.setState({
      loading: true
    })
  }

  handleModalBody = () => {
    const { owner, currentRole, libraryItem, selectedContent, classes } = this.props;
    const { loading } = this.state;

    let name = '',
        title = '',
        subheading = '';

    if (loading) {
      return (
        <LoadingDivContainer 
          type={'small'}
        />
      )
    } else if (currentRole !== '0' && !libraryItem.details.owners.includes(owner)) {
      return (
        <DeletePrivileges 
          className={classes.modalSubheadingDelete}
        />
      )
    } else if (selectedContent) {
      title = <span>Delete ALL Selected Items</span>;
      subheading = <span>Are you sure you want to delete ALL of these items?</span>;

      return (
        <div className={classes.divContainer}>
          <Typography variant="h6">
            {title}
          </Typography>
          <Typography variant="subtitle1" className={classes.modalSubheadingDelete}>
            {subheading}
          </Typography>
          <Button className={`${classes.button} ${classes.modalButton}`} variant="contained" type="submit" color="secondary" onClick={this.handleDeleteLibaryItem}>Delete</Button>
        </div>
      )
    } else {
      name = libraryItem.details.name;
      title = <span>Delete - <span className={classes.modalTitleItem}>{name}</span></span>;
      subheading = <span>Are you sure you want to delete <i>{name}</i>?</span>;

      return (
        <div className={classes.divContainer}>
          <Typography variant="h6">
            {title}
          </Typography>
          <Typography variant="subtitle1" className={classes.modalSubheadingDelete}>
            {subheading}
          </Typography>
          <Button className={`${classes.button} ${classes.modalButton}`} variant="contained" type="submit" color="secondary" onClick={this.handleDeleteLibaryItem}>Delete</Button>
        </div>
      )
    }
  }

  render() {
    const { openDeleteLibraryItemModal, classes } = this.props;

    return (
      <Modal
        aria-labelledby="preview-library-item"
        aria-describedby="preview-library-item"
        open={openDeleteLibraryItemModal}
        onClose={this.closeDeleteLibraryItemModal}
      >
        <div className={`${classes.modal} ${classes.deleteModal}`}>
          <IconButton 
            className={classes.iconClose}
            onClick={this.closeDeleteLibraryItemModal}
          >
            <Close />
          </IconButton>
          {this.handleModalBody()}
        </div>
      </Modal>
    )
  }

}

function mapStateToProps(state) {
  return { 
    token: state.login.token,
    owner: state.login.user._id,
    currentRole: state.login.user.user_type,
    libraryItem: state.libraryItem
  };
}

const mapDispatchToProps = dispatch => ({
  fetchLibraryItem: (token, id, callback) => dispatch(fetchLibraryItem(token, id, callback)),
  deleteLibraryItem: (token, id, callback) => dispatch(deleteLibraryItem(token, id, callback)),
  updateLibraryItems: (token, values, callback) => dispatch(updateLibraryItems(token, values, callback))
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DeleteLibraryItemModal));
