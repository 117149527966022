import axios from "axios";
import { ROOT_URL } from "./actions_constants";

export const FETCH_BRANDINGS = "fetch_brandings";
export const FETCH_BRANDING = "fetch_branding";
export const CREATE_BRANDING = "create_branding";
export const UPDATE_BRANDING = "update_branding";
export const DELETE_BRANDING = "delete_branding";

const fetchBrandingsSuccess = brandings => ({
  type: FETCH_BRANDINGS,
  payload: brandings
});

export const fetchBrandings = (token, companyId, callback) => (dispatch) => {

  const TOKEN = token;

  return (axios.get(`${ROOT_URL}company/brandings/${companyId}`, 
    { 
      headers: { 
        Authorization: 'Bearer ' + TOKEN
      }
    }))
    .then(result => {
      const brandings = result.data;

      dispatch(fetchBrandingsSuccess(brandings));

      if(callback) {
        callback(result);
      }

      return result;
    })
    .catch(error => {
      console.log(error)
      
      if (error.response && error.response.data) {
        callback(error.response.data)
      }
    })
}

const fetchBrandingSuccess = branding => ({
  type: FETCH_BRANDING,
  payload: branding
});

export const fetchBranding = (token, id, callback) => (dispatch) => {

  const TOKEN = token;

  return (axios.get(`${ROOT_URL}brandings/${id}`,
    { 
      headers: { 
        Authorization: 'Bearer ' + TOKEN
      }
    }))
  .then(result => {
    const branding = result.data;

    dispatch(fetchBrandingSuccess(branding));

    if(callback) {
      callback(result);
    }

    return result;
  })
  .catch(error => {
    console.log(error)
    
    if (error.response && error.response.data) {
      callback(error.response.data)
    }
  })

}

const createBrandingSuccess = branding => ({
  type: CREATE_BRANDING,
  payload: branding
});

export const createBranding = (token, values, callback) => (dispatch) => {

  const TOKEN = token;

  return (axios.post(`${ROOT_URL}brandings`,
      values,
      { 
        headers: { 
          Authorization: 'Bearer ' + TOKEN
        }
      }))
    .then(result => {
      const branding = result.data;

      dispatch(createBrandingSuccess(branding));

      if(callback) {
        callback(result);
      }

      return result;
    })
    .catch(error => {
      console.log(error)
      
      if (error.response && error.response.data) {
        callback(error.response.data)
      }
    })
}

const updateBrandingSuccess = branding => ({
  type: UPDATE_BRANDING,
  payload: branding
});

export const updateBranding = (token, values, id, callback) => (dispatch) => {

  const TOKEN = token;

  return (axios.put(`${ROOT_URL}brandings/${id}`, 
    values,
    { 
      headers: { 
        Authorization: 'Bearer ' + TOKEN
      }
    }))
    .then(result => {
      const brandingsItem = result.data;

      dispatch(updateBrandingSuccess(brandingsItem));

      if(callback) {
        callback(result);
      }

      return result;
    })
    .catch(error => {
      console.log(error)
      
      if (error.response && error.response.data) {
        callback(error.response.data)
      }
    })
}

const deleteBrandingSuccess = branding => ({
  type: DELETE_BRANDING,
  payload: branding
});

export const deleteBranding = (token, id, callback) => (dispatch) => {

  const TOKEN = token;

  return (axios.delete(`${ROOT_URL}brandings/${id}`, 
    { 
      headers: { 
        Authorization: 'Bearer ' + TOKEN
      }
    }))
    .then(result => {
      const branding = result.data;

      dispatch(deleteBrandingSuccess(branding));

      if(callback) {
        callback(result);
      }

      return result;
    })
    .catch(error => {
      console.log(error)
      
      if (error.response && error.response.data) {
        callback(error.response.data)
      }
    })
}
