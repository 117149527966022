import axios from "axios";
import { ROOT_URL } from "./actions_constants";

export const FETCH_TRADE_BOOTHS = "fetch_trade_booths";
export const FETCH_TRADE_BOOTH = "fetch_trade_booth";
export const CREATE_TRADE_BOOTH = "create_trade_booth";
export const UPDATE_TRADE_BOOTH = "update_trade_booth";
export const DELETE_TRADE_BOOTH= "delete_trade_booth";

const fetchTradeBoothsSuccess = tradeBooth => ({
  type: FETCH_TRADE_BOOTHS,
  payload: tradeBooth
});

export const fetchTradeBooths = (token, companyId, callback) => (dispatch) => {

  const TOKEN = token;

  return (axios.get(`${ROOT_URL}company/trade_booths/${companyId}`, 
    { 
      headers: { 
        Authorization: 'Bearer ' + TOKEN
      }
    }))
    .then(result => {
      const tradeBooth = result.data;

      dispatch(fetchTradeBoothsSuccess(tradeBooth));

      if(callback) {
        callback(result);
      }

      return result;
    })
    .catch(error => {
      console.log(error)
      
      if (error.response && error.response.data) {
        callback(error.response.data)
      }
    })
}

const fetchTradeBoothSuccess = tradeBooth => ({
  type: FETCH_TRADE_BOOTH,
  payload: tradeBooth
});

export const fetchTradeBooth = (token, id, callback) => (dispatch) => {
  
  const TOKEN = token;

  return (axios.get(`${ROOT_URL}trade_booths/${id}`,
    { 
      headers: { 
        Authorization: 'Bearer ' + TOKEN
      }
    }))
  .then(result => {
    const tradeBooth = result.data;

    dispatch(fetchTradeBoothSuccess(tradeBooth));

    if(callback) {
      callback(result);
    }

    return result;
  })
  .catch(error => {
    console.log(error)
    
    if (error.response && error.response.data) {
      callback(error.response.data)
    }
  })

}

const createTradeBoothSuccess = tradeBooths => ({
  type: CREATE_TRADE_BOOTH,
  payload: tradeBooths
});

export const createTradeBooth = (token, values, callback) => (dispatch) => {
  const TOKEN = token;

  let url = `${ROOT_URL}trade_booths`;

  return (axios.post(url,
      values,
      { 
        headers: { 
          Authorization: 'Bearer ' + TOKEN
        }
      }))
    .then(result => {
      const tradeBooths = result.data;

      dispatch(createTradeBoothSuccess(tradeBooths));

      if(callback) {
        callback(result);
      }

      return result;
    })
    .catch(error => {
      console.log(error)
      
      if (error.response && error.response.data) {
        callback(error.response.data)
      }
    })
}

const updateTradeBoothSuccess = tradeBooth => ({
  type: UPDATE_TRADE_BOOTH,
  payload: tradeBooth
});

export const updateTradeBooth = (token, values, id, callback) => (dispatch) => {

  const TOKEN = token;

  return (axios.put(`${ROOT_URL}trade_booths/${id}`, 
    values,
    { 
      headers: { 
        Authorization: 'Bearer ' + TOKEN
      }
    }))
    .then(result => {
      const tradeBooth = result.data;

      dispatch(updateTradeBoothSuccess(tradeBooth));

      if(callback) {
        callback(result);
      }

      return result;
    })
    .catch(error => {
      console.log(error)
      
      if (error.response && error.response.data) {
        callback(error.response.data)
      }
    })
}

const deleteTradeBoothSuccess = tradeBooth => ({
  type: DELETE_TRADE_BOOTH,
  payload: tradeBooth
});

export const deleteTradeBooth = (token, id, callback) => (dispatch) => {

  const TOKEN = token;

  return (axios.delete(`${ROOT_URL}trade_booths/${id}`, 
    { 
      headers: { 
        Authorization: 'Bearer ' + TOKEN
      }
    }))
    .then(result => {
      const tradeBooth = result.data;

      dispatch(deleteTradeBoothSuccess(tradeBooth));

      if(callback) {
        callback(result);
      }
      
      return result;
    })
    .catch(error => {
      console.log(error)
      
      if (error.response && error.response.data) {
        callback(error.response.data)
      }
    })
}
