import _ from "lodash";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { withRouter } from "react-router-dom";
import PageVisibility from "react-page-visibility";
import { Beforeunload } from "react-beforeunload";
import QueryString from "query-string";
import { Helmet } from "react-helmet";

import { withStyles } from "@material-ui/core/styles";
import { styles } from "../styles";

import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";

import MailOutline from "@material-ui/icons/MailOutline";
import CloudDownload from "@material-ui/icons/CloudDownload";

import LoadingDivContainer from "../subcomponents/loadingDiv";
import PreviewLibraryItemModalContainer from "../modals/previewLibraryItemModal";

import {
  setCookie,
  checkCookie,
  checkIfVideoLink,
  getItemDetails,
  validateEmail,
  getRandomInt,
} from "../helpers/helperFunctions";
import { getFileType } from "../helpers/gridLibraryHelpers";

import { RenderTextField } from "../subcomponents/form_subcomponents/renderTextField";

import { ROOT_URL } from "../../actions/actions_constants";
import {
  fetchShowcasePreview,
  fetchShowcaseDisplay,
  createLead,
  addLeadToShowcase,
} from "../../actions/actions_index";

import { notBlack, white } from "../../theme";

//Time Duration Tracker
let DURATION = 0,
  TOTAL_DURATION = 0,
  GATE_VIEWS = 0;

class GateForm extends Component {
  constructor(props) {
    super(props);

    this.props.reset();
  }

  onSubmit = (values) => {
    const { showcaseInstanceId, openedId, disableGate } = this.props;

    values.campaign = showcaseInstanceId;
    values.openedId = openedId;
    values.email = values.email.toLowerCase();

    this.props.createLead(values, (data) => {
      setCookie("needEmail", false, 90);
      setCookie("showcase_lead", data.data, 90);
      disableGate(data.data);
    });
  };

  render() {
    const {
      color,
      gate_text,
      company_field,
      name_field,
      phone_field,
      classes,
      handleSubmit,
      pristine,
      invalid,
    } = this.props;

    return (
      <div className={classes.showcaseGateContainer}>
        <Grid container spacing={0}>
          <Grid item xs>
            <div className={classes.leadFormContainer}>
              <Typography
                className={classes.gateText}
                style={color}
                type="display2"
                color="primary">
                {gate_text}
              </Typography>
              <form
                className={classes.container}
                noValidate
                autoComplete="off"
                onSubmit={handleSubmit(this.onSubmit)}>
                <Field
                  name="email"
                  component={RenderTextField}
                  type="email"
                  label="Email"
                  autoFocus
                />
                {company_field && (
                  <Field
                    name="contact_company"
                    component={RenderTextField}
                    type="text"
                    label="Company"
                  />
                )}
                {name_field && (
                  <Field
                    name="name"
                    component={RenderTextField}
                    type="text"
                    label="Name"
                  />
                )}
                {phone_field && (
                  <Field
                    name="phone"
                    component={RenderTextField}
                    type="tel"
                    label="Phone"
                  />
                )}
                <Button
                  className={classes.buttonWide}
                  variant="contained"
                  type="submit"
                  color="secondary"
                  disabled={pristine || invalid}>
                  Submit
                </Button>
              </form>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

function validate(values) {
  const errors = {};

  // Validate the inputs from 'values'
  const requiredFields = ["email"];

  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = "Required";
    }
  });

  if (values.email && validateEmail(values.email)) {
    errors.email = "Invalid email address";
  }

  return errors;
}

const mapDispatchToProps = (dispatch) => ({
  fetchShowcasePreview: (_id, callback) =>
    dispatch(fetchShowcasePreview(_id, callback)),
  fetchShowcaseDisplay: (_id, instancePreview, callback) =>
    dispatch(fetchShowcaseDisplay(_id, instancePreview, callback)),
  createLead: (values, callback) => dispatch(createLead(values, callback)),
  addLeadToShowcase: (campaign, lead, callback) =>
    dispatch(addLeadToShowcase(campaign, lead, callback)),
});

const GateFormContainer = reduxForm({
  form: "Lead",
  validate,
  enableReinitialize: true,
})(connect(null, mapDispatchToProps)(withStyles(styles)(GateForm)));

class Showcase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      expired: false,
      preview: false,
      instancePreview: false,
      gate: null,
      turnOnGate: false,
      loading: true,
      timer: null,
      pageActive: true,
      selectedGroupId: null,
      selectedLibraryItem: {},
      showcase: {},
      openedId: null,
      details: {},
      openPreviewLibraryItemModal: false,
      initiateClosePreviewLibraryItemModal: false,
      eventsNoContact: [],
      user_agent: null,
      hideScrollDown: false,
      error: null,
    };
  }

  handleScroll = () => {
    const { hideScrollDown } = this.state;

    if (!hideScrollDown) {
      this.setState({
        hideScrollDown: true,
      });
    }
  };

  handleExpiration = (details) => {
    const { link_expiration, link_date } = details ? details : {};

    if (link_expiration && link_expiration > 0) {
      const today = new Date();
      let linkCheck = new Date(link_date);
      linkCheck.setDate(linkCheck.getDate() + link_expiration);

      if (today >= linkCheck) {
        this.setState({
          expired: true,
        });

        return true;
      }
    }
  };

  componentDidMount() {
    const { path } = this.props.match;
    const { _id } = this.props.match.params;

    let preview = false,
      instancePreview = false,
      gate = null,
      gate_text = "",
      turnOnGate = false,
      tradebooth = false;

    const user_agent = navigator.userAgent;

    //preview -> preview of template
    //instancePreview -> preview of actual showcase shared instance
    if (
      path.includes("instance_preview") ||
      user_agent.includes("Prerender") ||
      user_agent.includes("Headless") ||
      user_agent.includes("headless")
    ) {
      instancePreview = true;
    } else if (path.includes("preview")) {
      preview = true;
    } else if (path.includes("booth")) {
      tradebooth = true;
    }

    if (preview) {
      this.props.fetchShowcasePreview(_id, (data) => {
        //**Note: showcase._id is from parent app_profile
        const { showcase, error } = data.data,
          { light_version } = showcase ? showcase : {},
          notBlackText = light_version ? { color: notBlack } : null;

        this.setState({
          preview: preview,
          instancePreview: instancePreview,
          gate: false,
          loading: false,
          error,
          lightVersion: light_version,
          notBlackText,
          tradebooth,
          showcase: showcase,
          details: {
            user_name: "Preview",
            user_email:
              "[NOTE: this will populate with the showcase sharer's email when not in preview mode]",
            company_name: "Showcase",
          },
        });
      });
    } else {
      this.props.fetchShowcaseDisplay(_id, instancePreview, (data) => {
        //**Note: showcase._id is from parent app_profile
        const { showcase, openedId, error } = data.data,
          { light_version } = showcase ? showcase : {},
          notBlackText = light_version ? { color: notBlack } : null;

        let details = data.data.details ? data.data.details : {};
        const {
          showcase_gate,
          showcase_gate_text,
          showcase_instance_id,
          contact,
        } = details;

        let handleContact = contact;

        details.contact = handleContact;

        if (!instancePreview) {
          this.handleExpiration(details);
        }

        if (!instancePreview && showcase_gate > "0") {
          gate = showcase_gate;
          gate_text = showcase_gate_text;

          const needEmail = checkCookie("needEmail");

          if (needEmail === "true") {
            turnOnGate = true;
          }

          if (needEmail !== "false" && showcase_gate === "1") {
            turnOnGate = true;
          }
        }

        let queryParams = QueryString.parse(this.props.location.search);

        if (
          (showcase_instance_id && queryParams.email) ||
          queryParams.first_name
        ) {
          queryParams.campaign = showcase_instance_id;
          queryParams.openedId = openedId;

          this.props.createLead(queryParams, (data) => {
            handleContact = data.data;
            details.contact = handleContact;

            this.setState({
              preview: preview,
              instancePreview: instancePreview,
              gate: gate,
              gate_text: gate_text,
              turnOnGate: turnOnGate,
              loading: false,
              error,
              lightVersion: light_version,
              notBlackText,
              tradebooth,
              showcase: showcase,
              openedId: openedId,
              details: details,
              user_agent: user_agent,
            });
          });
        } else if (showcase_instance_id && checkCookie("showcase_lead")) {
          handleContact = checkCookie("showcase_lead");
          details.contact = handleContact;

          this.props.addLeadToShowcase(
            showcase_instance_id,
            handleContact,
            (data) => {
              this.setState({
                preview: preview,
                instancePreview: instancePreview,
                gate: gate,
                gate_text: gate_text,
                turnOnGate: turnOnGate,
                loading: false,
                error,
                lightVersion: light_version,
                notBlackText,
                tradebooth,
                showcase: showcase,
                openedId: openedId,
                details: details,
                user_agent: user_agent,
              });
            }
          );
        } else {
          this.setState({
            preview: preview,
            instancePreview: instancePreview,
            gate: gate,
            gate_text: gate_text,
            turnOnGate: turnOnGate,
            loading: false,
            error,
            lightVersion: light_version,
            notBlackText,
            tradebooth,
            showcase: showcase,
            openedId: openedId,
            details: details,
            user_agent: user_agent,
          });
        }
      });

      this.startTimer();
    }

    window.addEventListener("scroll", this.handleScroll);

    // setTimeout(() => {

    //   const { details } = this.state,
    //         { company, user } = details ? details : {}

    //   let segment = null;

    //   switch (user) {
    //     //TEST for local and staging
    //     // case '5cd1f51a02ca3c5e84c56085':
    //     //   segment = 'michael'
    //     //   break;

    //     // case '5a0b444ecc03eb1e6aa4f895':
    //     //   segment = 'michael'
    //     //   break;

    //     //END TEST
    //     case '5c09966390aac95469fc7c42':
    //       segment = 'dustin'
    //       break;

    //     case '5bff13e096e19443e3bbf90f':
    //       segment = 'ben';
    //       break;

    //     case '5d236b3eb106e012d7f718d0':
    //       segment = 'greg';
    //       break;

    //     case '5d236b5eb106e012d7f718d1':
    //       segment = 'michael';
    //       break;

    //     default:
    //       segment = 'dustin'
    //       break;
    //   }

    //   if (company === '59ea2db254ba54369fc728e4' || company === '5bff136a96e19443e3bbf90b') {
    //     const script = document.createElement("script");
    //     script.type = 'text/javascript';
    //     script.innerHTML = `window.$crisp=[];$crisp.push(["set", "session:segments",[["${segment}"]]]);CRISP_WEBSITE_ID="0abe5e35-8164-4e39-8c76-e5a2bed85d86";(function(){d=document;s=d.createElement("script");s.src="https://client.crisp.chat/l.js";s.async=1;d.getElementsByTagName("head")[0].appendChild(s);})();`;
    //     script.async = true;

    //     document.body.appendChild(script);
    //   }
    // }, 1000);
  }

  componentWillUnmount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  //Count Duration
  startTimer = () => {
    setInterval(this.tick, 1000);
  };

  handleVisibilityChange = (active) => {
    this.setState({
      pageActive: active,
    });
  };

  tick = () => {
    const {
      pageActive,
      gate,
      openPreviewLibraryItemModal,
      selectedLibraryItem,
    } = this.state;

    if (pageActive) {
      DURATION += 1000;
      TOTAL_DURATION += 1000;

      if (TOTAL_DURATION % 3000 === 0) {
        this.recordEvent("update_total_duration", null);
      }

      if (TOTAL_DURATION === 30000 && gate === "2") {
        if (openPreviewLibraryItemModal) {
          this.recordEvent("view_closed", selectedLibraryItem._id);
        }

        const needEmail = checkCookie("needEmail");

        //cookies are strings, can't store a boolean
        if (needEmail !== "false") {
          setCookie("needEmail", true, 30);

          this.setState({
            turnOnGate: true,
            openPreviewLibraryItemModal: false,
          });
        }
      }
    }
  };
  //End Duration

  //Record View/Download Event
  recordDownloadEvent = (event, library_item_id) => {
    event.stopPropagation();
    this.recordEvent("download", library_item_id);
  };

  recordReshareEvent = (event, library_item_id) => {
    event.stopPropagation();
    this.recordEvent("reshare", library_item_id);
  };

  recordEvent = (type, item, showcase = false, link = false) => {
    const { preview, instancePreview } = this.state;

    if (!preview && !instancePreview) {
      const { details, openedId, item_open_id, gate, user_agent } = this.state;

      let data = {
        showcaseId: details.showcase_instance_id,
        openedId,
        parent: openedId,
        item_open_id,
        item: item,
        duration: DURATION,
        total_duration: TOTAL_DURATION,
        type: 2,
        user_agent,
        event_type: type,
        link: false,
      };

      if (details.contact) {
        data.contact = details.contact;
      }

      if ((link || showcase) && type === "view_opened") {
        return;
      } else if (showcase) {
        data.showcase = item;
        delete data.library_item;
      }

      //****CONTACT/CAMPAIGN => will be updated if it is a campaign, and if a contact has been created via a form gate*****
      //type === 'closed' => don't need to do anything...

      if (type === "link") {
        data.link = true;
        data.duration = 0;
      } else if (type === "download") {
        data.type = 4;
      } else if (type === "reshare") {
        //not exactly sure how reshare will work
        data.type = 3;
      } else if (type === "update_total_duration" || type === "closed") {
        delete data.showcaseId;
        delete data.parent;
        delete data.item;
        delete data.type;
      } else if (type === "view_opened") {
        GATE_VIEWS++;

        const needEmail = checkCookie("needEmail");

        let handleGate = false;

        if (gate === "3" && GATE_VIEWS === 2 && needEmail !== "false") {
          handleGate = true;
        } else if (gate === "4" && GATE_VIEWS === 3 && needEmail !== "false") {
          handleGate = true;
        }

        if (handleGate) {
          setCookie("needEmail", true, 30);

          setTimeout(() => {
            this.setState({
              turnOnGate: true,
              openPreviewLibraryItemModal: false,
              initiateClosePreviewLibraryItemModal: false,
            });
          }, 250);
        }
      }

      if (data.contact === null) {
        delete data.contact;
      }

      axios.post(`${ROOT_URL}showcase_event`, data).then((result) => {
        if (type === "view_opened") {
          this.setState({
            item_open_id: result.data.showcaseEvent._id,
          });
        } else if (type === "view_closed") {
          this.setState({
            item_open_id: null,
          });

          DURATION = 0;
        }
      });
    }
  };

  roomItemClick = (library_item, groupId, linkClick = false) => {
    let type = "view_opened",
      openModal = true;

    const { _id, link, filename, showcase } = library_item ? library_item : {},
      streamVideo = checkIfVideoLink(filename),
      linkItem = link && !streamVideo ? link : false;

    if ((linkItem || showcase) && !streamVideo && linkClick) {
      type = "link";
      openModal = false;
    }

    this.recordEvent("update");

    DURATION = 0;

    this.setState(
      {
        openPreviewLibraryItemModal: openModal,
        initiateClosePreviewLibraryItemModal: false,
        selectedGroupId: groupId,
        selectedLibraryItem: library_item,
        item_open_id: null,
      },
      () => {
        this.recordEvent(type, _id, showcase, linkItem);
      }
    );
  };

  closedLibraryItemModal = () => {
    this.recordEvent("view_closed");

    this.setState({
      initiateClosePreviewLibraryItemModal: true,
    });

    setTimeout(() => {
      this.setState({
        openPreviewLibraryItemModal: false,
        selectedLibraryItem: null,
        item_open_id: null,
      });
    }, 500);
  };

  handlePreviewLibraryItemModal = () => {
    const {
      showcase,
      openPreviewLibraryItemModal,
      initiateClosePreviewLibraryItemModal,
      selectedGroupId,
      selectedLibraryItem,
    } = this.state;
    const { allLibraryItems, branding } = showcase;
    let color = null,
      backgroundColor = null;

    if (branding.branding_primary) {
      const primary = branding.branding_primary;

      color = {
        color: primary,
      };
      backgroundColor = {
        backgroundColor: primary,
      };
    }

    if (openPreviewLibraryItemModal) {
      return (
        <PreviewLibraryItemModalContainer
          openPreviewLibraryItemModal={openPreviewLibraryItemModal}
          initiateClosePreviewLibraryItemModal={
            initiateClosePreviewLibraryItemModal
          }
          allLibraryItems={allLibraryItems}
          selectedLinkUdid={selectedGroupId}
          selectedLibraryItem={selectedLibraryItem}
          roomItemClick={this.roomItemClick}
          closedLibraryItemModal={this.closedLibraryItemModal}
          type="room"
          color={color}
          backgroundColor={backgroundColor}
          recordDownloadEvent={this.recordDownloadEvent}
          branding={branding}
          parentState={this.state}
        />
      );
    }
  };

  getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }

  handleContentItems = (type, branding, groupId, library_items) => {
    const { classes } = this.props;
    const { showcase, lightVersion, notBlackText } = this.state;
    const items = showcase.items;

    let color = null,
      itemExtraClass = null,
      titleExtraClass = null,
      nameExtraClass = null,
      cardExtraClass = null,
      cardBorderExtraClass = null;

    if (branding.branding_primary) {
      color = {
        color: branding.branding_primary,
      };
    }

    if (type === 1) {
      itemExtraClass = classes.roomMostRecentItem;
      titleExtraClass = classes.roomMostRecentCardTitle;
      nameExtraClass = classes.roomMostRecentItemName;
      cardExtraClass = classes.roomMostRecentCard;
    }

    if (lightVersion) {
      cardBorderExtraClass = classes.roomMostRecentCardLight;
    }

    return library_items.map((id) => {
      const initial_item_details = _.find(items, { _id: id });
      const _id = initial_item_details._id;

      const {
        default_thumbnail,
        thumbnail,
        item_details,
        streamVideo,
        reshareDetails,
        itemClasses,
      } = getItemDetails({
        item: { _id, details: initial_item_details },
        branding,
        state: this.state,
        classes,
      });

      const { link, filename, name, file_type, downloadable } = item_details;
      const {
        cardShowcaseItem,
        cardShowcaseClass,
        showcaseTitleClass,
        showcaseNameClass,
      } = itemClasses;

      return (
        <div
          key={`${_id}-${getRandomInt(100)}`}
          className={`${classes.roomItem} ${itemExtraClass} ${cardShowcaseItem}`}>
          {item_details.link && !streamVideo && (
            <a href={item_details.filename} target="_blank">
              <Card
                className={`${classes.card} ${
                  classes.roomCard
                } ${cardExtraClass} ${
                  type === 1 ? "roomMostRecentCard" : null
                } ${default_thumbnail} roomCard ${cardShowcaseClass} ${cardBorderExtraClass}`}
                type="link"
                onClick={(event) =>
                  this.roomItemClick(item_details, groupId, true)
                }>
                <CardMedia
                  className={`${classes.cardMedia} ${classes.roomCardMedia}`}
                  component="img"
                  alt={item_details.name}
                  image={thumbnail}
                  title={item_details.name}
                />
              </Card>
              <div
                className={`${classes.roomCardTitle} ${titleExtraClass} ${showcaseTitleClass}`}>
                {getFileType(item_details.file_type, "room")}
                <div
                  className={`${classes.roomItemName} ${nameExtraClass} ${classes.truncateText} ${showcaseNameClass}`}
                  style={notBlackText}>
                  {item_details.name}
                </div>
              </div>
            </a>
          )}
          {(!item_details.link || streamVideo) && (
            <div className={classes.roomCardContainer}>
              <Card
                className={`${classes.card} ${
                  classes.roomCard
                } ${cardExtraClass} ${
                  type === 1 ? "roomMostRecentCard" : null
                } ${default_thumbnail} roomCard ${cardShowcaseClass} ${cardBorderExtraClass}`}
                type="view_opened"
                onClick={(event) => this.roomItemClick(item_details, groupId)}>
                <CardMedia
                  className={`${classes.cardMedia} ${classes.roomCardMedia}`}
                  component="img"
                  alt={item_details.name}
                  image={thumbnail}
                  title={item_details.name}
                />
                {!item_details.link &&
                  item_details.downloadable &&
                  item_details.aws_bucket && (
                    <a
                      href={item_details.download_url}
                      download={item_details.name}
                      type="download"
                      onClick={(event) => this.recordDownloadEvent(event, _id)}
                      target="_blank"
                      className={classes.roomDownloadButton}>
                      <IconButton>
                        <CloudDownload
                          style={color}
                          className={classes.roomDownloadIcon}
                        />
                      </IconButton>
                    </a>
                  )}
              </Card>
              <div
                className={`${classes.roomCardTitle} ${titleExtraClass} ${showcaseTitleClass}`}
                type="view_opened"
                onClick={(event) => this.roomItemClick(item_details, groupId)}>
                {getFileType(
                  item_details.file_type,
                  "room",
                  item_details.filename
                )}
                <div
                  className={`${classes.roomItemName} ${nameExtraClass} ${classes.truncateText} ${showcaseNameClass} `}
                  style={notBlackText}>
                  {item_details.name}
                </div>
              </div>
              {!item_details.link &&
                item_details.downloadable &&
                item_details.aws_bucket && (
                  <a
                    href={item_details.download_url}
                    download={item_details.name}
                    type="download"
                    onClick={(event) => this.recordDownloadEvent(event, _id)}
                    target="_blank">
                    <IconButton className={classes.roomDownloadButtonMobile}>
                      <CloudDownload
                        style={color}
                        className={`${classes.roomDownloadIcon} ${classes.roomDownloadIconMobile}`}
                      />
                    </IconButton>
                  </a>
                )}
            </div>
          )}
        </div>
      );
    });
  };

  handleSessions = (type, branding, libraryGroups) => {
    const { classes } = this.props;
    const { notBlackText } = this.state;

    let sectionExtraClass = null,
      containerExtraClass = null,
      textExtraClass = null;

    if (type === 1) {
      sectionExtraClass = classes.roomMostRecentSectionSession;
      containerExtraClass = classes.roomMostRecentItemsContainer;
      textExtraClass = classes.roomMostRecentText;
    }

    return libraryGroups.map((group) => {
      if (group.library_files.length > 0) {
        return (
          <div
            key={`${group._id}-${getRandomInt(100)}`}
            className={`${classes.roomSectionSession} ${sectionExtraClass}`}>
            <Typography
              variant="body1"
              className={`${classes.showcaseGroupText} ${textExtraClass}`}
              style={notBlackText}>
              {group.name}
            </Typography>
            <div
              className={`${classes.roomItemsContainer} ${containerExtraClass}`}>
              {this.handleContentItems(
                type,
                branding,
                group._id,
                group.library_files
              )}
            </div>
          </div>
        );
      } else {
        return null;
      }
    });
  };

  handleSection = (
    type,
    branding,
    libraryGroups,
    library_group_ids,
    lightVersion
  ) => {
    const { classes } = this.props;

    let orderLibraryGroups = [];

    library_group_ids.map((_id) => {
      const group = _.find(libraryGroups, { _id });
      orderLibraryGroups.push(group);
      const sub_groups = _.filter(libraryGroups, { parent: _id });

      if (sub_groups.length > 0) {
        orderLibraryGroups = orderLibraryGroups.concat(sub_groups);
      }
    });

    //type => 1: most recent, 2: related content
    return (
      <div
        className={`${
          type === 1 ? classes.showcaseMostRecentSection : classes.roomSection
        } ${lightVersion ? classes.showcaseMostRecentSectionLight : null}`}>
        <div
          key={Date.now()}
          className={
            type === 1
              ? classes.roomMostRecentSectionContainer
              : classes.roomSectionContainer
          }>
          {this.handleSessions(type, branding, orderLibraryGroups)}
        </div>
      </div>
    );
  };

  //**future for related content -> need to adjust**
  // handlePreviousSections = (allSessionEvents) => {
  // 	const { classes } = this.props;

  // 	return (
  // 		allSessionEvents.map((sessionEvents, index) => {
  // 			if (index !== 0) {
  // 				return (
  // 					<div key={sessionEvents.date} className={classes.roomFirstPreviousSection}>
  // 						{this.handleSection(2, sessionEvents)}
  // 					</div>
  // 				)
  // 			}
  // 		})
  // 	)
  // }

  disableGate = (contact) => {
    let details = this.state.details;

    details.contact = contact;

    this.setState({
      details: details,
      turnOnGate: false,
    });
  };

  gateFormContainer = () => {
    const { turnOnGate, details, openedId, gate_text, showcase } = this.state;
    const branding = showcase.branding;

    let primary = null,
      color = null;

    if (branding.branding_primary) {
      primary = branding.branding_primary;
      color = {
        color: primary,
      };
    }

    if (turnOnGate) {
      return (
        <GateFormContainer
          showcaseInstanceId={details.showcase_instance_id}
          openedId={openedId}
          color={color}
          gate_text={gate_text}
          company_field={details.showcase_company_field}
          name_field={details.showcase_name_field}
          phone_field={details.showcase_phone_field}
          disableGate={this.disableGate}
        />
      );
    }
  };

  render() {
    const { classes } = this.props;
    const {
      error,
      loading,
      expired,
      lightVersion,
      notBlackText,
      hideScrollDown,
    } = this.state;

    if (error) {
      return (
        <div>
          <Typography
            variant="h3"
            style={{ textAlign: "center", margin: "40px 0" }}>
            {error}
          </Typography>
        </div>
      );
    } else if (loading) {
      return <LoadingDivContainer />;
    } else {
      const {
        showcase,
        details,
        selectedLibraryItem,
        tradebooth /*backgroundHeight*/,
      } = this.state;
      const {
        library_group_ids,
        library_groups,
        allLibraryItems,
        items,
        branding,
      } = showcase;

      let selectedItemId = null,
        contactButton = null,
        backgroundColor = null,
        backgroundImageContainer = null,
        primary = null,
        roomContainerWhite = null,
        showcaseHeaderContainerLight = null;

      if (lightVersion) {
        showcaseHeaderContainerLight = classes.showcaseHeaderContainerLight;
        roomContainerWhite = classes.roomContainerWhite;
      }

      if (selectedLibraryItem) {
        selectedItemId = selectedLibraryItem._id;
      }

      if (branding.branding_primary) {
        primary = branding.branding_primary;

        contactButton = {
          borderColor: primary,
          backgroundColor: primary,
          color: white,
          "&:hover": {
            backgroundColor: `${primary}B3`,
          },
        };

        backgroundColor = {
          backgroundColor: primary,
        };
      }

      if (branding.branding_background_image) {
        backgroundImageContainer = {
          background: `rgba(1, 22, 39, 0) url("${branding.branding_background_image}")`,
          // backgroundBlendMode: 'multiply',
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "top center",
          // height: backgroundHeight,
        };
      } else {
        backgroundImageContainer = {
          background: notBlack,
        };
      }

      let pageTitle = "verbTEAMS Showcase";

      if (showcase.title) {
        pageTitle = showcase.title;

        if (showcase.subtitle) {
          pageTitle += ` ${showcase.subtitle}`;
        }
      }

      let imageWidth = null;
      let imageHeight = null;

      if (branding.branding_background_image) {
        if (branding.branding_background_image_width) {
          imageWidth = branding.branding_background_image_width;
          imageHeight = branding.branding_background_image_height;
        }
      } else if (branding.branding_small_logo) {
        if (branding.branding_small_logo_width) {
          imageWidth = branding.branding_small_logo_width;
          imageHeight = branding.branding_small_logo_height;
        }
      }

      let top = "calc(100vh - 90px)";
      const iOS =
        /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

      if (iOS) {
        top = "calc(100vh - 135px)";
      }

      let handleHideScrollDown = null;

      if (hideScrollDown) {
        handleHideScrollDown = { display: "none" };
      }

      return (
        <div className={`${classes.roomContainer} ${roomContainerWhite}`}>
          <Helmet>
            <title>{pageTitle}</title>
            <meta
              name="description"
              content={showcase.description ? showcase.description : "Showcase"}
            />
            <meta property="og:title" content={pageTitle} />
            <meta
              property="og:image"
              content={
                branding.branding_background_image
                  ? branding.branding_background_image
                  : branding.branding_small_logo
              }
            />
            <meta
              property="og:image:width"
              content={imageWidth ? imageWidth : null}
            />
            <meta
              property="og:image:height"
              content={imageHeight ? imageHeight : null}
            />
          </Helmet>
          {this.gateFormContainer()}
          {!tradebooth && (
            <React.Fragment>
              <div
                className={classes.showcaseScrollDown}
                style={{ top, ...handleHideScrollDown }}>
                <img src="/images/scroll_down.gif" />
              </div>
              <AppBar
                position="static"
                className={`${classes.showcaseHeaderContainer} ${showcaseHeaderContainerLight}`}>
                <Toolbar className={classes.showcaseHeader}>
                  {/*<div>
                    <Typography variant="body1" className={`${classes.roomHeaderText} ${classes.roomTextEnergy}`} style={color}>
                      Shared by
                    </Typography>
                    {details.user_name && 
                      <Typography variant="h3" className={`${classes.roomHeaderName} ${classes.truncateText}`}>
                        {details.user_name}
                      </Typography>
                    }
                    {(!details.user_name && details.user_email) && 
                      <Typography variant="h3" className={`${classes.roomHeaderName} ${classes.truncateText}`}>
                        {details.user_email}
                      </Typography>
                    }
                    <Typography variant="body1" className={classes.roomHeaderText}>
                      {details.company_name}
                    </Typography>
                  </div>*/}
                  <div className={classes.showcaseHeader}>
                    {!branding.branding_small_logo && (
                      <img
                        className={`${classes.showcaseHeaderLogo} ${classes.justVerticalMiddle}`}
                        src="/images/verb/verb-bug.png"
                        alt={""}
                      />
                    )}
                    {branding.branding_small_logo && (
                      <img
                        className={`${classes.showcaseHeaderLogo} ${classes.justVerticalMiddle}`}
                        src={branding.branding_small_logo}
                        alt={""}
                      />
                    )}
                  </div>
                  <div className={classes.showcaseHeader}>
                    <a
                      className={`${classes.showcaseHeaderContactInfo} ${classes.justVerticalMiddle}`}
                      href={`mailto:${details.user_email}`}
                      target="_blank">
                      <Button
                        className={classes.roomContactIconButton}
                        style={contactButton}
                        variant="outlined">
                        <MailOutline className={classes.roomContactIcon} />{" "}
                        Email Us
                      </Button>
                    </a>
                  </div>
                </Toolbar>
              </AppBar>
            </React.Fragment>
          )}
          <div className={classes.showcaseWideBackground}>
            <div className={classes.roomMostRecentContainer}>
              {!tradebooth && (
                <div
                  className={`${classes.showcaseTitleAreaContainer} ${classes.showcaseBackgroundImageContainer}`}
                  style={backgroundImageContainer}>
                  <div className={classes.showcaseTitleArea}>
                    {showcase.title && (
                      <Typography
                        variant="h1"
                        className={`${classes.showcaseTitle}`}>
                        {showcase.title}
                      </Typography>
                    )}
                    {showcase.subtitle && (
                      <Typography
                        variant="h2"
                        className={`${classes.showcaseSubtitle}`}
                        style={backgroundColor}>
                        {showcase.subtitle}
                      </Typography>
                    )}
                    {showcase.description && (
                      <Typography
                        variant="body1"
                        className={`${classes.showcaseDescription}`}>
                        {showcase.description}
                      </Typography>
                    )}
                  </div>
                </div>
              )}
              <PageVisibility
                onChange={this.handleVisibilityChange}></PageVisibility>
              <Beforeunload
                onBeforeunload={(event) => {
                  this.recordEvent("closed");
                }}
              />
              {!expired &&
                this.handleSection(
                  1,
                  branding,
                  library_groups,
                  library_group_ids,
                  lightVersion
                )}
              {expired && (
                <div className={classes.showcaseTitleArea}>
                  <Typography
                    variant="h2"
                    className={classes.roomHeaderContact}>
                    <span className={classes.expiredHeader}>
                      This link has expired, contact the sender to get a new
                      link.
                    </span>
                  </Typography>
                </div>
              )}
            </div>
          </div>
          {/*LATER IF RELATED CONTENT IS SHOWN 
            (!expired && allSessionEvents.length > 1) &&
            <div className={classes.roomWideBackgroundWhite}>
    	    		<div className={classes.roomPreviousSection}>
    	    			<Button  style={backgroundColor} className={classes.roomButton} variant="contained" color="secondary">
    	    				Previously Shared
    	    				<ArrowDropDown className={classes.addCircleIcon} />
    	    			</Button>
    	    			{this.handlePreviousSections(allSessionEvents)}
    	    		</div>
        		</div>
          */}
          {this.handlePreviewLibraryItemModal()}
          <div className={classes.roomPoweredByBar}>
            <Typography variant="body1" className={classes.roomPoweredByText}>
              <span className={classes.roomPoweredByTextSpan}>Powered by</span>
              <a href="https://solofire.com">
                <img
                  className={classes.roomPoweredByLogo}
                  src="/images/verb/verb-dark-logo.png"
                />
              </a>
            </Typography>
          </div>
        </div>
      );
    }
  }
}

function mapStateToProps(state) {
  return {};
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Showcase))
);
