import _ from 'lodash';
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as Filestack from 'filestack-js';

import { withStyles } from '@material-ui/core/styles';
import { styles } from '../styles';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Fab from '@material-ui/core/Fab';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';

import Add from '@material-ui/icons/Add';
import AddCircle from '@material-ui/icons/AddCircle';
import Search from '@material-ui/icons/Search';
import Edit from '@material-ui/icons/Edit';
import RemoveCircle from '@material-ui/icons/RemoveCircle';
import Delete from '@material-ui/icons/Delete';
import Close from '@material-ui/icons/Close';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import OfflineBoltIcon from '@material-ui/icons/OfflineBolt';
import MoreIcon from '@material-ui/icons/More';

import WithLoading from '../subcomponents/withLoading';
import GridContainer from '../subcomponents/gridContainer';

//Library Groups
import LibraryGroupList from './library_group/library_group_list';
import LibraryGroupMenu from './library_group/library_group_menu';
import LibraryGroupModalContainer from '../modals/libraryGroupModal';
import AddToLibraryGroupModalContainer from '../modals/addToLibraryGroupModal';
import { RenderAddRemoveButton } from '../subcomponents/form_subcomponents/renderAddRemoveButton';
//End Library Groups

//Library Item Modals
import PreviewLibraryItemModalContainer from '../modals/previewLibraryItemModal';
import LibraryItemModalContainer from '../modals/libraryItemModal';
import LibraryItemDetailsModalContainer from '../modals/libraryItemDetailsModal';
import DeleteLibraryItemModalContainer from '../modals/deleteLibraryItemModal';
import EditUploadedItemsModalContainer from '../modals/editUploadedItemsModal';
import FilestackButton, {
  API_KEY,
  OPTIONS,
} from '../subcomponents/filestackButton';
import InteractiveModalContainer from '../modals/interactiveModal';
//End Library Item Modals

import {
  verifyLoggedIn,
  menuPermissions,
  getOffset,
  libraryTableHeight,
  activeCampaignEvent,
  checkAlerts,
  moveArray,
  MDandAbove,
  SMandBelow,
  handleEnterpriseAppUser,
  handleAppUser,
  appProfileSettings,
} from '../helpers/helperFunctions';
import {
  multipleFilter,
  tagsFilter,
  libraryNameSort,
} from '../helpers/gridLibraryHelpers';

import {
  handlePageLoading,
  fetchLibrary,
  fetchLibraryGroups,
  fetchAppUserPersonal,
  fetchShowcasesInAppProfile,
  updateLibraryGroup,
  moveLibraryGroup,
  createLibraryItems,
  shareLibraryItems,
} from '../../actions/actions_index';

import { QUICK_ADD_LIBRARY_ITEM, QUICK_ADD_LIBRARY_GROUP } from '../header';

const FULL_LIBRARY_ID = 'full-library';
const FULL_LIBRARY = 'Content Library';

const MY_VIDEOS_ID = 'my-videos';
const MY_VIDEOS = 'My Videos';

const SHOWCASES_ID = 'showcases';
const SHOWCASES = 'Showcases';

//Map of all children and grandchildren of parent library groups so it closes everything under them
let CHILDREN_MAP = {};

class Library extends Component {
  constructor(props) {
    super(props);

    let handleDefaultLibrary = {
      level: 0,
      parent: null,
      order: null,
      _id: FULL_LIBRARY_ID,
      name: FULL_LIBRARY,
      library_files: null,
      editable_by: null,
      owners: null,
    };

    this.state = {
      loadingLibrary: true,
      loadingLibraryGroups: true,
      rowMenu: false,

      selectedGroup: handleDefaultLibrary,

      //separated to NULL out on close of menu
      selectedGroupMenuElement: null,
      selectedGroupMenu: null,

      openLibraryGroupModal: false,
      newLibraryGroup: false,
      addGroup: false,
      addSubGroup: false,
      deleteGroup: false,
      showAddToLibraryGroupButton: false,
      openAddToLibraryGroupModal: false,

      openPreviewLibraryItemModal: false,
      openLibraryItemModal: false,
      openLibraryItemDetailsModal: false,
      openDeleteLibraryItemModal: false,

      // selectedFiles: [],
      selectedLibraryItemPosition: null,
      selectedLibraryItemId: null,
      selectedLibraryItemFiletype: null,
      openEditUploadedItemsModal: false,
      uploadedItems: null,
      fromEdit: false,
      childrenMap: [],
      displayParentSubs: [],
      plusMenu: null,
      threeSixtyMenu: null,
      searchValue: null,
      bulkEdit: false,
      selectedItems: null,
      selectedContent: null,
      sorting: [{ columnName: 'updatedAt', direction: 'desc' }],
      anchor: false,
      clientWidth: 0,
      hideMenuDrawer: false,
      personalGroupSelected: false,
      interactiveVideoContentSelection: false,
      openInteractiveModal: false,
      appUserPersonalContent: false,
    };

    this.onSelectionChange = (selected) => {
      this.setState({
        selectedItems: selected,
      });
    };

    this.handleSortingChange = (sorting) => {
      this.setState({
        sorting,
      });
    };
  }

  updateWindowSize = () => {
    this.setState({ clientWidth: window.innerWidth });
  };

  findNonHiddenLockedLibraryGroup = (library_group_ids) => {
    const { libraryGroups } = this.props,
      topLevelGroups = _.find(libraryGroups, { level: 1 }).groups;

    let selectedGroupId = null;

    for (let i = 0; i < library_group_ids.length; i++) {
      const currentGroup = library_group_ids[i],
        group = _.find(topLevelGroups, { _id: library_group_ids[i] });

      if (group && !group.hidden && !group.locked) {
        selectedGroupId = currentGroup;
        break;
      }
    }

    return selectedGroupId;
  };

  componentDidMount() {
    const {
        enterprise,
        appUser,
        verb_live,
        appProfile,
        all_content_group,
        libraryGroups,
      } = this.props,
      { library_groups } = appProfile,
      { appUserPersonalContent, appUserInteractiveVideos } =
        appProfileSettings(appProfile);

    this.setState({
      appUserPersonalContent,
      appUserInteractiveVideos,
    });

    const { quick } = this.props.match.params;
    this.quickLibrary(quick);

    this.updateWindowSize();
    window.addEventListener('resize', this.updateWindowSize);

    if (
      appUser &&
      library_groups &&
      Array.isArray(library_groups) &&
      library_groups.length > 0
    ) {
      this.selectLibraryGroup(
        null,
        this.findNonHiddenLockedLibraryGroup(library_groups)
      );
    } else if (!enterprise && verb_live && all_content_group) {
      this.selectLibraryGroup(null, all_content_group, libraryGroups);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { appProfile } = this.props;
    const prevAppProfile = prevProps.appProfile;

    if (appProfile._id !== prevAppProfile._id) {
      // const enterpriseAppUser = handleEnterpriseAppUser(nextProps)
      this.handleUpdateLibraryGroup();
    }

    return true;
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowSize);
  }

  //when app profile switched, switch selected content group to one in app profile
  handleUpdateLibraryGroup = () => {
    const {
      token,
      companyId,
      appProfile,
      enterpriseAppUser,
      appUser,
      fetchLibrary,
      fetchLibraryGroups,
      fetchAppUserPersonal,
    } = this.props;
    const { selectedGroup } = this.state;
    const { library_groups } = appProfile;

    if (enterpriseAppUser && library_groups && library_groups.length > 0) {
      this.setState({
        loadingLibrary: true,
      });

      let loadData = [];

      loadData.push(
        fetchLibrary(token, companyId, library_groups, (data) => {})
      );
      loadData.push(
        fetchLibraryGroups(token, companyId, library_groups, (data) => {})
      );

      if (appUser) {
        loadData.push(fetchAppUserPersonal(token, (data) => {}));
      }

      Promise.all(loadData).then((result) => {
        this.selectLibraryGroup(null, library_groups[0]);

        this.setState({
          loadingLibrary: true,
        });
      });
    }
  };

  quickLibrary = (quick) => {
    if (quick === QUICK_ADD_LIBRARY_ITEM) {
      const { history } = this.props;
      const client = Filestack.init(API_KEY);
      OPTIONS.onUploadDone = (result) =>
        this.uploadSuccess(result, null, null, null);
      client.picker(OPTIONS).open();

      history.push('/content/library');
    } else if (quick === QUICK_ADD_LIBRARY_GROUP) {
      this.openAddNewLibraryGroupModal();
    }
  };

  componentWillReceiveProps(newProps) {
    const { quick } = newProps.match.params;
    this.quickLibrary(quick);
  }

  selectGroup = (parent, _id, libraryGroups) => {
    const selectedGroupArray = _.find(libraryGroups, { parent }).groups,
      selectedGroup = _.find(selectedGroupArray, { _id });

    return selectedGroup;
  };

  //COMPILE ALL DESCENDANTS...
  returnDescendants = (_id) => {
    let descendants = [];

    if (CHILDREN_MAP[_id]) {
      descendants = CHILDREN_MAP[_id];
    }

    return descendants;
  };

  checkForDescendants = (allDescendants, findDescendants) => {
    let newDescendants = [];

    if (Array.isArray(findDescendants) && findDescendants.length > 0) {
      findDescendants.map((_id) => {
        newDescendants = this.returnDescendants(_id);
        allDescendants = allDescendants.concat(newDescendants);
        findDescendants = findDescendants.concat(newDescendants);
        findDescendants = findDescendants.filter((item) => item !== _id);
        newDescendants = [];

        return null;
      });
    }

    if (findDescendants.length > 0) {
      allDescendants = this.checkForDescendants(
        allDescendants,
        findDescendants
      );
    }

    return allDescendants;
  };

  findAllDescendants = (_id) => {
    let allDescendants = this.returnDescendants(_id);

    if (Array.isArray(allDescendants) && allDescendants.length > 0) {
      allDescendants = this.checkForDescendants(allDescendants, allDescendants);
    } else {
      allDescendants = [];
    }

    return allDescendants;
  };
  //END COMPILE ALL DESCENDANTS

  //SECTION: LIBRARY GROUPS
  selectLibraryGroup = (parent, _id, groups, personal = false) => {
    const { enterprise, libraryGroups, appUserPersonalLibraryGroups } =
      this.props;

    let { displayParentSubs } = this.state,
      handleLibraryGroups = groups;

    if (personal) {
      handleLibraryGroups = appUserPersonalLibraryGroups;
    } else if (!handleLibraryGroups) {
      handleLibraryGroups = libraryGroups;
    }

    if (!parent) {
      parent = null;
    }

    if (!displayParentSubs.includes(_id)) {
      displayParentSubs.push(_id);
    } else {
      displayParentSubs = displayParentSubs.filter((item) => item !== _id);

      let allDescendants = this.findAllDescendants(_id);

      if (allDescendants.length > 0) {
        allDescendants.map((childId) => {
          displayParentSubs = displayParentSubs.filter(
            (item) => item !== childId
          );
          return null;
        });
      }
    }

    const selectedGroup = this.selectGroup(parent, _id, handleLibraryGroups);
    let sorting = [{ columnName: 'display_order', direction: 'asc' }];

    if (!enterprise) {
      sorting = [{ columnName: 'updatedAt', direction: 'desc' }];
    }

    this.setState({
      loadingLibraryGroups: false,
      showAddToLibraryGroupButton: true,
      selectedGroup: selectedGroup,
      selectedGroupMenu: selectedGroup,
      displayParentSubs: displayParentSubs,
      selectedItems: null,
      sorting,
      personalGroupSelected: personal,
    });
  };

  //HANDLE FULL LIBRARY OR LIBRARY GROUP SELECTION
  handleGroupClick = (event, personal) => {
    const _id = event.currentTarget.id;
    const parent = event.currentTarget.getAttribute
      ? event.currentTarget.getAttribute('parent')
      : null;

    if (
      _id === FULL_LIBRARY_ID ||
      _id === MY_VIDEOS_ID ||
      _id === SHOWCASES_ID
    ) {
      const sorting = [{ columnName: 'updatedAt', direction: 'desc' }];

      let handleID, handleName;

      switch (_id) {
        case FULL_LIBRARY_ID:
          handleID = FULL_LIBRARY_ID;
          handleName = FULL_LIBRARY;
          break;

        case MY_VIDEOS_ID:
          handleID = MY_VIDEOS_ID;
          handleName = MY_VIDEOS;
          break;

        case SHOWCASES_ID:
          handleID = SHOWCASES_ID;
          handleName = SHOWCASES;
          break;
      }

      this.setState({
        showAddToLibraryGroupButton: false,
        selectedGroup: {
          level: 0,
          parent: null,
          order: null,
          _id: handleID,
          name: handleName,
        },
        selectedItems: null,
        sorting,
        personalGroupSelected: personal,
      });
    } else {
      this.selectLibraryGroup(parent, _id, null, personal);
    }
  };

  childrenMap = (map) => {
    CHILDREN_MAP = map;
  };

  //OPEN LIBRARY GROUP MENU
  handleLibraryGroupMenu = (event) => {
    const { appUser, libraryGroups, appUserPersonalLibraryGroups } = this.props,
      _id = event.currentTarget.id,
      parent = event.currentTarget.getAttribute('parent'),
      handleLibraryGroups = appUser
        ? appUserPersonalLibraryGroups
        : libraryGroups,
      selectedGroupMenu = this.selectGroup(parent, _id, handleLibraryGroups);

    this.setState({
      selectedGroupMenuElement: event.currentTarget,
      selectedGroupMenu: selectedGroupMenu,
    });
  };

  handleMoveLibraryGroup = (direction, id) => {
    const { token, companyId, appProfile } = this.props;
    const { library_groups } = appProfile;

    this.props.moveLibraryGroup(token, direction, id, (results) => {
      this.props.fetchLibraryGroups(
        token,
        companyId,
        library_groups,
        (data) => {}
      );
    });
  };

  //CLOSE LIBRARY GROUP MENU AND HANDLE IF SELECTION (EDIT, ADD, ADD_GROUP, or DELETE)
  handleLibraryGroupMenuClose = (type, id = null, fromEdit = false) => {
    const { selectedGroupMenu } = this.state;

    let openLibraryGroupModal = false,
      direction = '',
      addGroup = false,
      addSubGroup = false,
      deleteGroup = false,
      openAddToLibraryGroupModal = false,
      hideMenuDrawer = true;

    switch (type) {
      case 'edit':
        openLibraryGroupModal = true;
        break;

      case 'move_up':
        direction = 'up';
        hideMenuDrawer = false;
        this.handleMoveLibraryGroup(direction, selectedGroupMenu._id);
        break;

      case 'move_down':
        direction = 'down';
        hideMenuDrawer = false;
        this.handleMoveLibraryGroup(direction, selectedGroupMenu._id);
        break;

      case 'add':
        if (fromEdit) {
          openLibraryGroupModal = true;
        }

        openAddToLibraryGroupModal = true;
        break;

      case 'add_group':
        openLibraryGroupModal = true;
        addSubGroup = true;

        break;

      case 'delete':
        openLibraryGroupModal = true;
        deleteGroup = true;
        break;

      default:
        hideMenuDrawer = false;
        break;
    }

    this.setState({
      selectedGroupMenuElement: false,
      openLibraryGroupModal: openLibraryGroupModal,
      openAddToLibraryGroupModal: openAddToLibraryGroupModal,
      addGroup: addGroup,
      addSubGroup: addSubGroup,
      deleteGroup: deleteGroup,
      newLibraryGroup: false,
      fromEdit: fromEdit,
      plusMenu: null,
      hideMenuDrawer,
    });
  };

  //OPEN ADD NEW LIBRARY GROUP MODAL
  openAddNewLibraryGroupModal = () => {
    this.setState({
      openLibraryGroupModal: true,
      addGroup: true,
    });
  };

  openAddNewLink = () => {
    this.setState({
      openLibraryItemModal: true,
      selectedLibraryItemId: null,
      plusMenu: null,
    });
  };

  //HANDLE CLOSED LIBRARY GROUP MODAL
  closedLibraryGroupModal = (updated, created, data) => {
    const {
        history,
        enterprise,
        verb_live,
        appProfile,
        appUser,
        all_content_group,
        libraryGroups,
        fetchAppUserPersonal,
        fetchLibraryGroups,
      } = this.props,
      { token, companyId } = this.props,
      { selectedGroup } = this.state,
      personalGroupSelected = appUser ? true : false;

    let handleSelectedGroup = data ? data : selectedGroup,
      showAddToLibraryGroupButton = true,
      hideMenuDrawer = false,
      anchor = false,
      selectedGroupMenu = {},
      newLibraryGroup = false;

    if (updated) {
      let loadData = [];

      if (appUser) {
        loadData.push(fetchAppUserPersonal(token, (data) => data));
      } else {
        loadData.push(
          fetchLibraryGroups(token, companyId, null, (data) => data)
        );
      }

      Promise.all(loadData).then((results) => {
        const { libraryGroups, appUserPersonalLibraryGroups } = this.props;
        const handleLibraryGroups = appUser
          ? appUserPersonalLibraryGroups
          : libraryGroups;

        //Added Library Group
        if (created) {
          const { _id, parent, name } = data;

          selectedGroupMenu._id = _id;
          selectedGroupMenu.name = name;
          newLibraryGroup = appUser ? false : true;
          hideMenuDrawer = true;
          anchor = true;

          this.selectLibraryGroup(parent, _id, handleLibraryGroups);
        }

        this.setState({
          openLibraryGroupModal: false,
          showAddToLibraryGroupButton,
          addGroup: false,
          addSubGroup: false,
          deleteGroup: false,
          selectedGroup: handleSelectedGroup,
          selectedGroupMenu: selectedGroupMenu,
          newLibraryGroup,
          fromEdit: false,
          hideMenuDrawer,
          anchor,
          personalGroupSelected,
        });

        if (!enterprise && verb_live && all_content_group && !created) {
          this.selectLibraryGroup(null, all_content_group, handleLibraryGroups);
        } else if (!created) {
          this.handleGroupClick(
            appUser
              ? { currentTarget: { id: MY_VIDEOS_ID } }
              : { currentTarget: { id: FULL_LIBRARY_ID } }
          );
        } else if (created && !appUser) {
          if (data.level != 0) {
            this.handleLibraryGroupMenuClose('add');
          }
        }
      });
    } else {
      this.setState({
        openLibraryGroupModal: false,
        addGroup: false,
        addSubGroup: false,
        deleteGroup: false,
        newLibraryGroup: newLibraryGroup,
        fromEdit: false,
        hideMenuDrawer,
        anchor,
      });
    }

    history.push('/content/library');
  };

  //HANDLE CLOSED ADD TO LIBRARY GROUP MODAL
  closedAddToLibraryGroupModal = (
    selectedGroupMenuId,
    libraryGroup,
    updated
  ) => {
    const { fromEdit, selectedGroup, selectedGroupMenu } = this.state;

    let selectedGroupUpdate = Object.assign({}, selectedGroup),
      selectedGroupMenuUpdate = Object.assign({}, selectedGroupMenu);

    if (updated) {
      const { token, companyId, appProfile, fetchLibraryGroups } = this.props;
      const { library_groups } = appProfile;

      fetchLibraryGroups(token, companyId, library_groups, (data) => {
        selectedGroupUpdate._id = selectedGroupMenuId;
        selectedGroupUpdate.name = libraryGroup.name;

        this.setState({
          selectedGroup: libraryGroup,
          openAddToLibraryGroupModal: false,
          selectedGroupMenu: selectedGroupMenuUpdate,
          hideMenuDrawer: false,
          anchor: false,
        });
      });
    } else {
      if (
        !updated &&
        !fromEdit &&
        (!selectedGroup._id || selectedGroup._id === FULL_LIBRARY_ID)
      ) {
        selectedGroupMenuUpdate._id = null;
        selectedGroupMenuUpdate.name = null;
        selectedGroupMenuUpdate.element = null;
      }

      if (!updated && !fromEdit) {
        libraryGroup = selectedGroupUpdate;
      }

      this.setState({
        selectedGroup: libraryGroup,
        openAddToLibraryGroupModal: false,
        selectedGroupMenu: selectedGroupMenuUpdate,
        hideMenuDrawer: false,
        anchor: false,
      });
    }
  };
  //END SECTION: LIBRARY GROUPS

  //SECTION: UPLOAD FILES
  saveUploadedFiles = (uploadedfiles, parent, groupId, buttonType) => {
    const {
      token,
      companyId,
      appUser,
      owner,
      appProfile,
      fetchLibrary,
      fetchLibraryGroups,
      createLibraryItems,
    } = this.props;
    const { library_groups } = appProfile;

    let saveFiles = [];

    uploadedfiles.map((value, index) => {
      let file = {};
      const name = value.filename.replace(/\//g, '');

      file.buttonType = buttonType;
      file.company = companyId;
      file.owners = [owner];
      file.filename = name;
      file.name = name.replace(/_/g, ' ');

      //**CHANGE HARD CODED LINK**
      file.aws_bucket = value.container;
      file.aws_key = value.key;
      file.path = `${process.env.REACT_APP_FILESTACK_S3_URL}${value.key.replace(
        / /g,
        '+'
      )}`;
      file.filestack_handle = value.handle;
      file.mimetype = value.mimetype;
      file.file_type = name.substring(name.lastIndexOf('.') + 1).toLowerCase();
      file.file_size = value.size;

      if (appUser) {
        file.personal = owner;
      }

      saveFiles.push(file);

      return null;
    });

    createLibraryItems(token, saveFiles, groupId, (libraryItems) => {
      if (libraryItems.error) {
        this.setState({
          error: libraryItems.error,
        });
      } else {
        //open modal to edit item details
        fetchLibrary(token, companyId, library_groups, (library) => {
          if (groupId) {
            fetchLibraryGroups(
              token,
              companyId,
              library_groups,
              (libraryGroups) => {
                this.selectLibraryGroup(parent, groupId, libraryGroups.data);
              }
            );
          }

          this.setState({
            uploadedItems: libraryItems.data,
          });
        });
      }
    });
  };

  uploadSuccess = (
    result,
    parent,
    groupId,
    selectedLibraryItemId,
    buttonType
  ) => {
    // filesFailed
    //do something for failed uploaded files

    this.saveUploadedFiles(result.filesUploaded, parent, groupId, buttonType);

    this.setState({
      openEditUploadedItemsModal: true,
      plusMenu: null,
    });
  };
  //END SECTION: UPLOAD FILES

  //SECTION: LIBRARY ITEMS
  //OPEN LIBRARY ITEM MENU
  handleLibraryItemMenu = (event) => {
    const element = document.getElementById(event.currentTarget.id);
    const top = getOffset(element).top;
    const left = getOffset(element).left;
    const position = { top: top, left: left };
    const filetype = event.currentTarget.dataset.filetype;

    this.setState({
      rowMenu: true,
      selectedLibraryItemPosition: position,
      selectedLibraryItemId: event.currentTarget.id,
      selectedLibraryItemFiletype: filetype,
    });
  };

  //CLOSE LIBRARY ITEM MENU AND HANDLE IF SELECTION (PREVIEW, EDIT, or DELETE)
  handleLibraryItemMenuClose = (
    type,
    selectedLibraryItemId,
    fromEdit = false
  ) => {
    const { appProfile, updateLibraryGroup, fetchLibraryGroups } = this.props;
    const { library_groups } = appProfile;

    let openPreviewLibraryItemModal = false,
      openLibraryItemModal = false,
      openDeleteLibraryItemModal = false,
      openLibraryItemDetailsModal = false;

    if (fromEdit) {
      openLibraryItemModal = true;
    }

    this.setState({
      selectedLibraryItemPosition: null,
    });

    switch (type) {
      case 'preview':
        openPreviewLibraryItemModal = true;
        break;

      case 'edit':
        openLibraryItemModal = true;
        break;

      case 'details':
        openLibraryItemDetailsModal = true;
        break;

      case 'remove':
        const { token, companyId } = this.props;
        const { selectedGroup } = this.state;

        let group_files = this.state.selectedGroup.library_files;
        group_files.splice(group_files.indexOf(selectedLibraryItemId), 1);

        let values = {
          library_files: group_files,
        };

        updateLibraryGroup(token, values, selectedGroup._id, (data) => {
          fetchLibraryGroups(
            token,
            companyId,
            library_groups,
            (libraryGroups) => {
              this.selectLibraryGroup(
                selectedGroup.parent,
                selectedGroup._id,
                libraryGroups.data
              );
            }
          );
        });

        break;

      case 'delete':
        openDeleteLibraryItemModal = true;
        break;

      default:
        break;
    }

    this.setState({
      rowMenu: false,
      fromEdit,
      openPreviewLibraryItemModal,
      openLibraryItemModal,
      openLibraryItemDetailsModal,
      openDeleteLibraryItemModal,
      selectedLibraryItemId,
    });
  };

  //HANDLE CLOSED LIBRARY ITEM MODAL
  closedLibraryItemModal = (updated, linkGroup) => {
    const {
      token,
      companyId,
      appProfile,
      appUser,
      fetchLibrary,
      fetchLibraryGroups,
      fetchAppUserPersonal,
    } = this.props;
    const { library_groups } = appProfile;
    const { fromEdit, selectedLibraryItemId, selectedGroup } = this.state;

    let openLibraryItemModal = false,
      newSelectedLibraryItemId = null;
    // loadingLibraryGroups = false;

    if (updated && appUser && selectedGroup) {
      fetchAppUserPersonal(token, (data) => {
        this.selectLibraryGroup(null, selectedGroup._id, null, true);
      });
    } else if (updated && linkGroup && linkGroup._id !== FULL_LIBRARY_ID) {
      // loadingLibraryGroups = true;

      fetchLibrary(token, companyId, library_groups, (library) => {
        //reload library groups to show newly added link inside the library group
        fetchLibraryGroups(
          token,
          companyId,
          library_groups,
          (libraryGroups) => {
            this.selectLibraryGroup(
              selectedGroup.parent,
              selectedGroup._id,
              null
            );
          }
        );
      });
    } else if (updated) {
      this.props.fetchLibrary(token, companyId, library_groups);
    }

    if (fromEdit) {
      openLibraryItemModal = true;
      newSelectedLibraryItemId = selectedLibraryItemId;
    }

    this.setState({
      // loadingLibraryGroups: loadingLibraryGroups,
      fromEdit: false,
      openPreviewLibraryItemModal: false,
      openLibraryItemModal: openLibraryItemModal,
      openDeleteLibraryItemModal: false,
      openEditUploadedItemsModal: false,
      selectedLibraryItemId: newSelectedLibraryItemId,
      uploadedItems: null,
      selectedItems: null,
      selectedContent: null,
    });
  };

  closedLibraryItemDetailsModal = () => {
    this.setState({ openLibraryItemDetailsModal: false });
  };

  findRows = () => {
    const {
        enterprise,
        appUser,
        enterpriseAppUser,
        library,
        showcases,
        appUserPersonal,
        appUserPersonalLibrary,
      } = this.props,
      { selectedGroup, personalGroupSelected } = this.state,
      handleLibrary = personalGroupSelected ? appUserPersonalLibrary : library;

    let rows = [],
      libraryGroupRows = [];

    if (selectedGroup && selectedGroup.library_files) {
      selectedGroup.library_files.map((value) => {
        const item = _.find(handleLibrary, ['_id', value]);
        if (item) {
          libraryGroupRows.push(item);
        }
        return null;
      });

      libraryGroupRows.map((group, index) => {
        group.display_order = index;
        return group;
      });

      rows = libraryGroupRows;
    } else if (selectedGroup && selectedGroup._id === MY_VIDEOS_ID) {
      rows = appUserPersonalLibrary.filter((item) => item.personalized_video);
    } else if (selectedGroup && selectedGroup._id === SHOWCASES_ID) {
      rows = showcases;
    } else if (enterprise && !appUser) {
      rows = library;
    } else {
      rows = [];
    }

    if (enterpriseAppUser) {
      rows = rows.filter((item) => !item.hidden);
    }

    return rows;
  };

  rowClick = (event) => {
    const { owner, currentRole, appUser, enterpriseAppUser } = this.props,
      { rowMenu, interactiveVideoContentSelection } = this.state,
      rows = this.findRows(),
      initialValues = rows[event[0]],
      { _id, locked } = initialValues ? initialValues : {};

    if ((!enterpriseAppUser || !locked) && !interactiveVideoContentSelection) {
      const openEdit = menuPermissions(
        'library',
        rowMenu,
        owner,
        currentRole,
        initialValues,
        appUser
      );
      (appUser || openEdit) && this.handleLibraryItemMenuClose('preview', _id);
    }
  };

  addToShare = (event) => {
    const { itemsToShare, shareLibraryItems } = this.props;
    const currentItems = this.findRows(),
      clickedItem = currentItems[event[0]]._id;

    let newShareItems = [...itemsToShare];

    if (newShareItems.includes(clickedItem)) {
      const index = newShareItems.indexOf(clickedItem);
      if (index > -1) {
        newShareItems.splice(index, 1);
      }
    } else {
      newShareItems.push(clickedItem);
    }

    shareLibraryItems(newShareItems, () => {});
  };

  emptyAddToShare = () => {
    const { shareLibraryItems } = this.props;

    shareLibraryItems([], () => {});

    this.setState({ interactiveVideoContentSelection: false });
  };
  //END SECTION: LIBRARY ITEMS

  //SECTION: MODALS

  //LIBRARY GROUP MODALS
  handleLibraryGroupModal = () => {
    const {
      openLibraryGroupModal,
      addGroup,
      addSubGroup,
      deleteGroup,
      selectedGroupMenu,
    } = this.state;

    if (openLibraryGroupModal) {
      return (
        <LibraryGroupModalContainer
          openLibraryGroupModal={openLibraryGroupModal}
          addGroup={addGroup}
          addSubGroup={addSubGroup}
          deleteGroup={deleteGroup}
          selectedGroup={selectedGroupMenu}
          addRemove={this.handleLibraryGroupMenuClose}
          closedLibraryGroupModal={this.closedLibraryGroupModal}
        />
      );
    }
  };

  handleAddToLibraryGroupModal = () => {
    const { all_content_group } = this.props;
    const { openAddToLibraryGroupModal, selectedGroupMenu } = this.state;

    //selectedGroupMenu && (selectedGroupMenu._id !== all_content_group)) ? selectedGroupMenu : null

    if (openAddToLibraryGroupModal) {
      return (
        <AddToLibraryGroupModalContainer
          openAddToLibraryGroupModal={openAddToLibraryGroupModal}
          selectedGroup={selectedGroupMenu}
          closedAddToLibraryGroupModal={this.closedAddToLibraryGroupModal}
        />
      );
    }
  };
  //END LIBRARY GROUP MODALS

  //LIBRARY ITEM MODALS
  handlePreviewLibraryItemModal = () => {
    const {
      openPreviewLibraryItemModal,
      selectedLibraryItemId,
      personalGroupSelected,
    } = this.state;

    if (openPreviewLibraryItemModal) {
      return (
        <PreviewLibraryItemModalContainer
          openPreviewLibraryItemModal={openPreviewLibraryItemModal}
          selectedLibraryItemId={selectedLibraryItemId}
          personalGroupSelected={personalGroupSelected}
          handleLibraryItemMenuClose={this.handleLibraryItemMenuClose}
          closedLibraryItemModal={this.closedLibraryItemModal}
        />
      );
    }
  };

  handleLibraryItemModal = () => {
    const {
      openLibraryItemModal,
      selectedLibraryItemId,
      selectedGroup,
      selectedContent,
    } = this.state;

    if (openLibraryItemModal) {
      return (
        <LibraryItemModalContainer
          openLibraryItemModal={openLibraryItemModal}
          selectedLibraryItemId={selectedLibraryItemId}
          closedLibraryItemModal={this.closedLibraryItemModal}
          previewModal={this.handleLibraryItemMenuClose}
          selectedGroup={selectedGroup}
          selectedContent={selectedContent}
        />
      );
    }
  };

  handleLibraryItemDetailsModal = () => {
    const {
      openLibraryItemDetailsModal,
      selectedLibraryItemId,
      selectedGroup,
      selectedContent,
    } = this.state;

    if (openLibraryItemDetailsModal) {
      return (
        <LibraryItemDetailsModalContainer
          openLibraryItemDetailsModal={openLibraryItemDetailsModal}
          selectedLibraryItemId={selectedLibraryItemId}
          closedLibraryItemDetailsModal={this.closedLibraryItemDetailsModal}
        />
      );
    }
  };

  handleDeleteLibraryItemModal = () => {
    const {
      openDeleteLibraryItemModal,
      selectedGroup,
      selectedLibraryItemId,
      selectedContent,
    } = this.state;

    if (openDeleteLibraryItemModal) {
      return (
        <DeleteLibraryItemModalContainer
          openDeleteLibraryItemModal={openDeleteLibraryItemModal}
          selectedGroup={selectedGroup}
          selectedContent={selectedContent}
          selectedLibraryItemId={selectedLibraryItemId}
          closedLibraryItemModal={this.closedLibraryItemModal}
        />
      );
    }
  };

  handleEditUploadedItemsModal = () => {
    const { openEditUploadedItemsModal, uploadedItems } = this.state;

    if (openEditUploadedItemsModal) {
      return (
        <EditUploadedItemsModalContainer
          openEditUploadedItemsModal={openEditUploadedItemsModal}
          uploadedItems={uploadedItems}
          closedLibraryItemModal={this.closedLibraryItemModal}
        />
      );
    }
  };

  handleInteractiveVideoContentSelectionSwitch = () => {
    const { interactiveVideoContentSelection } = this.state;

    this.emptyAddToShare();

    this.setState({
      interactiveVideoContentSelection: !interactiveVideoContentSelection,
      plusMenu: null,
    });
  };

  openInteractiveModalSwitch = () => {
    const { openInteractiveModal } = this.state;

    this.setState({
      openInteractiveModal: !this.state.openInteractiveModal,
    });
  };

  postSaveInteractiveVideo = () => {
    const { loadPageData } = this.props;
    const { selectedGroup } = this.state;

    loadPageData(null, () => {
      const selectGroup =
        selectedGroup && selectedGroup._id !== FULL_LIBRARY_ID
          ? this.selectLibraryGroup(
              selectedGroup.parent,
              selectedGroup._id,
              null
            )
          : null;
    });
  };

  handleOpenInteractiveModal = () => {
    const { loadPageData, libraryItems } = this.props;
    const { openInteractiveModal, selectedGroup } = this.state;

    if (openInteractiveModal) {
      return (
        <InteractiveModalContainer
          openInteractiveModal={openInteractiveModal}
          sharedItems={libraryItems}
          reloadShareCart={this.postSaveInteractiveVideo}
          selectedGroupId={
            selectedGroup
              ? selectedGroup._id !== FULL_LIBRARY_ID
                ? selectedGroup._id
                : null
              : null
          }
          closedInteractiveModal={this.openInteractiveModalSwitch}
        />
      );
    }
  };
  //END LIBRARY ITEM MODALS

  //SECTION: MODALS

  handleMenu = (event, menu) => {
    switch (menu) {
      case 'open-plus-menu':
        this.setState({ plusMenu: event.currentTarget });
        break;
      case 'open-three-sixty-menu':
        this.setState({ threeSixtyMenu: event.currentTarget });
        break;
      default:
        break;
    }
  };

  handleClose = (props) => {
    this.setState({
      plusMenu: null,
      threeSixtyMenu: null,
    });
  };

  handleMoveArray = (oldIndex, newIdex) => {
    const {
      token,
      companyId,
      appProfile,
      updateLibraryGroup,
      fetchLibraryGroups,
    } = this.props;
    const { library_groups } = appProfile;
    const { selectedGroup } = this.state;

    const newGroup = moveArray(selectedGroup.library_files, oldIndex, newIdex);

    updateLibraryGroup(
      token,
      { library_files: newGroup },
      selectedGroup._id,
      (data) => {
        fetchLibraryGroups(
          token,
          companyId,
          library_groups,
          (libraryGroups) => {
            this.selectLibraryGroup(
              selectedGroup.parent,
              selectedGroup._id,
              libraryGroups.data
            );
          }
        );
      }
    );
  };

  handleSearchValue = (searchValue) => {
    this.setState({
      searchValue,
    });
  };

  turnOnBulkEdit = () => {
    const bulkEdit = this.state.bulkEdit;

    this.setState({
      bulkEdit: !bulkEdit,
      selectedItems: null,
      selectedContent: null,
    });
  };

  bulkEdit = (deleteContent) => {
    const { selectedGroup, selectedItems } = this.state;
    const content = this.findRows(selectedGroup._id);

    let action = 'edit';

    if (deleteContent) {
      action = 'delete';
    }

    let selectedContent = selectedItems.map((value) => {
      return content[value]._id;
    });

    this.setState({
      selectedContent,
    });

    this.handleLibraryItemMenuClose(action);
  };

  toggleDrawer = (open) => (event) => {
    this.setState({ anchor: open });
  };

  leftSide = ({
    enterprise,
    enterpriseAppUser,
    appUser,
    appUserPersonal,
    appUserPersonalLibraryGroups,
    appUserPersonalContent,
    appUserInteractiveVideos,
    verb_live,
    all_content_group,
    owner,
    currentRole,
    libraryGroups,
    libraryCount,
    showcasesCount,
    selectedGroup,
    displayParentSubs,
    selectedGroupMenuElement,
    classes,
  }) => (
    <React.Fragment>
      {this.handleLibraryGroupModal()}
      {this.handleAddToLibraryGroupModal()}

      <LibraryGroupList
        enterprise={enterprise}
        enterpriseAppUser={enterpriseAppUser}
        appUser={appUser}
        verb_live={verb_live}
        all_content_group={all_content_group}
        FULL_LIBRARY_ID={FULL_LIBRARY_ID}
        MY_VIDEOS_ID={MY_VIDEOS_ID}
        SHOWCASES_ID={SHOWCASES_ID}
        owner={owner}
        currentRole={currentRole}
        libraryGroups={libraryGroups}
        libraryCount={libraryCount}
        showcasesCount={showcasesCount}
        selectedGroupParent={selectedGroup ? selectedGroup.parent : null}
        selectedGroupId={selectedGroup ? selectedGroup._id : null}
        childrenMap={this.childrenMap}
        displayParentSubs={displayParentSubs}
        handleLibraryGroupMenu={this.handleLibraryGroupMenu}
        handleGroupClick={this.handleGroupClick}
        openAddNewLibraryGroupModal={this.openAddNewLibraryGroupModal}
        appUserPersonal={appUserPersonal}
        appUserPersonalLibraryGroups={appUserPersonalLibraryGroups}
        appUserPersonalContent={appUserPersonalContent}
        appUserInteractiveVideos={appUserInteractiveVideos}
      />
      {selectedGroupMenuElement && (
        <LibraryGroupMenu
          appUser={appUser}
          anchorEl={selectedGroupMenuElement}
          open={Boolean(selectedGroupMenuElement)}
          onClose={this.handleLibraryGroupMenuClose}
          className={classes.tableMenu}
        />
      )}
    </React.Fragment>
  );

  addContentButton = ({
    enterprise,
    interactiveVideo,
    appUser,
    plusMenu,
    threeSixtyMenu,
    clientWidth,
    selectedGroup,
    currentRole,
    owner,
    toGroup,
    all_content_group,
    showAddToLibraryGroupButton,
    three_sixty,
    classes,
  }) => (
    <span>
      <Fab
        size='small'
        variant='extended'
        color='secondary'
        aria-label='add'
        aria-owns={plusMenu ? 'plus-menu' : null}
        aria-haspopup='true'
        onClick={(event) => this.handleMenu(event, 'open-plus-menu')}
        className={classes.plusButton}
      >
        <Add />
        {clientWidth > 960 ? 'Add Content' : ''}
      </Fab>
      {three_sixty && selectedGroup._id === FULL_LIBRARY_ID && (
        <React.Fragment>
          <Fab
            size='small'
            variant='extended'
            color='secondary'
            aria-label='add'
            aria-owns={threeSixtyMenu ? 'plus-menu' : null}
            aria-haspopup='true'
            onClick={(event) => this.handleMenu(event, 'open-three-sixty-menu')}
            className={classes.plusButton}
          >
            <Add />
            Add 360 Video
          </Fab>
          <Menu
            id='plus-menu'
            anchorEl={threeSixtyMenu}
            open={Boolean(threeSixtyMenu)}
            onClose={this.handleClose}
            PopoverClasses={{ paper: classes.quickPaper }}
          >
            <MenuItem classes={{ root: classes.menuItem }}>
              <FilestackButton
                buttonType={'360'}
                selectedGroup={null}
                FULL_LIBRARY_ID={FULL_LIBRARY_ID}
                uploadSuccess={this.uploadSuccess}
                extraClass={classes.plusButtonButton}
                close={this.handleClose}
              />
            </MenuItem>
          </Menu>
        </React.Fragment>
      )}
      <Menu
        id='plus-menu'
        anchorEl={plusMenu}
        open={Boolean(plusMenu)}
        onClose={this.handleClose}
        PopoverClasses={{ paper: classes.quickPaper }}
      >
        {selectedGroup &&
          selectedGroup._id !== FULL_LIBRARY_ID &&
          currentRole !== '0' &&
          selectedGroup.editable_by !== '0' &&
          !selectedGroup.owners.includes(owner) && (
            <Typography
              variant='body1'
              className={classes.contactAdministrator}
            >
              Contact your Administrator for Access to this Content Group
            </Typography>
          )}
        {!appUser &&
          selectedGroup &&
          selectedGroup._id !== FULL_LIBRARY_ID &&
          selectedGroup._id !== all_content_group &&
          selectedGroup &&
          (currentRole === '0' ||
            selectedGroup.editable_by === '0' ||
            selectedGroup.owners.includes(owner)) && (
            <MenuItem classes={{ root: classes.menuItem }}>
              <RenderAddRemoveButton
                show={showAddToLibraryGroupButton}
                selectedId={selectedGroup._id}
                addRemove={this.handleLibraryGroupMenuClose}
                type={'add'}
                name={'Content from Library'}
                subName={selectedGroup ? selectedGroup.name : ''}
                classes={classes}
                fromEdit={false}
              />
            </MenuItem>
          )}
        {selectedGroup &&
          (selectedGroup._id === FULL_LIBRARY_ID ||
            currentRole === '0' ||
            selectedGroup.editable_by === '0' ||
            selectedGroup.owners.includes(owner)) && (
            <div>
              <MenuItem classes={{ root: classes.menuItem }}>
                <FilestackButton
                  buttonType={'new'}
                  selectedGroup={selectedGroup}
                  FULL_LIBRARY_ID={FULL_LIBRARY_ID}
                  uploadSuccess={this.uploadSuccess}
                  extraClass={classes.plusButtonButton}
                  close={this.handleClose}
                />
              </MenuItem>
              <MenuItem classes={{ root: classes.menuItem }}>
                <Button
                  className={`${classes.plusButtonButton}`}
                  onClick={this.openAddNewLink}
                  focusRipple={false}
                >
                  <AddCircle className={classes.addCircleIcon} />
                  Add New Link
                  <span className={classes.truncateText}>{toGroup}</span>
                </Button>
              </MenuItem>
            </div>
          )}
        {enterprise &&
          interactiveVideo &&
          !appUser &&
          selectedGroup &&
          (selectedGroup._id === FULL_LIBRARY_ID ||
            currentRole === '0' ||
            selectedGroup.editable_by === '0' ||
            selectedGroup.owners.includes(owner)) && (
            <MenuItem classes={{ root: classes.menuItem }}>
              <Button
                className={`${classes.plusButtonButton}`}
                onClick={this.handleInteractiveVideoContentSelectionSwitch}
                focusRipple={false}
              >
                <AddCircle className={classes.addCircleIcon} />
                Add New Interactive Video
                <span className={classes.truncateText}>{toGroup}</span>
              </Button>
            </MenuItem>
          )}
      </Menu>
    </span>
  );

  render() {
    const {
      company,
      enterprise,
      interactiveVideo,
      enterpriseAppUser,
      appUser,
      appUserPersonal,
      appUserPersonalLibraryGroups,
      appProfile,
      live_only,
      verb_live,
      all_content_group,
      alerts,
      owner,
      currentRole,
      libraryGroups,
      library,
      itemsToShare,
      showcases,
      three_sixty,
      classes,
    } = this.props;
    const {
      selectedGroup,
      selectedGroupMenuElement,
      selectedLibraryItemId,
      selectedLibraryItemFiletype,
      showAddToLibraryGroupButton,
      selectedLibraryItemPosition,
      displayParentSubs,
      plusMenu,
      threeSixtyMenu,
      searchValue,
      bulkEdit,
      selectedItems,
      sorting,
      anchor,
      clientWidth,
      hideMenuDrawer,
      personalGroupSelected,
      interactiveVideoContentSelection,
      appUserPersonalContent,
      appUserInteractiveVideos,
    } = this.state;
    const alertsRoot = checkAlerts(company, alerts, classes);

    let tableType = 'large',
      rootExtendedHeader = null,
      thumbnailWidth = 90,
      toGroup,
      sortable = false,
      libraryCount,
      showcasesCount,
      liveTeam = null,
      drawerStyle = null,
      filenameColumn = 300,
      leftBarClass = classes.leftBarDrawer;

    const mobile =
      /iPad|iPhone|iPod|Android/.test(navigator.userAgent) && !window.MSStream;

    if (enterprise && !appUser) {
      rootExtendedHeader = classes.rootExtendedHeader;
    }

    if (!enterprise || appUser) {
      rootExtendedHeader = classes.rootTeamsHeader;
    }

    if (clientWidth < 1280) {
      filenameColumn = 250;
    }

    if (mobile) {
      thumbnailWidth = 70;
    }

    if (selectedGroup && selectedGroup._id !== FULL_LIBRARY_ID) {
      toGroup = (
        <span>
          &nbsp;to <i>{selectedGroup ? selectedGroup.name : ''}</i>
        </span>
      );
      sortable = true;
    }

    let onSelectionChange = interactiveVideoContentSelection
        ? this.addToShare
        : this.rowClick,
      showSelectAll = false,
      showSelectionColumn = false;

    if (bulkEdit) {
      onSelectionChange = this.onSelectionChange;
      showSelectAll = true;
      showSelectionColumn = true;
    }

    if (library) {
      libraryCount = library.length;
    }

    if (showcases) {
      showcasesCount = showcases.length;
    }

    if (hideMenuDrawer) {
      drawerStyle = { display: 'none' };
    }

    const rows = this.findRows();

    let columns = [
      { name: 'thumbnail', title: ' ' },
      { name: 'filename', title: 'Name / Filename / Tags' },
      { name: 'search_tags', title: '' },
      { name: 'file_type', title: 'Type' },
      { name: 'file_size', title: 'Size' },
      { name: 'updatedAt', title: 'Last Updated' },
      { name: 'rating', title: 'Rating' },
      {
        name: 'library_menu',
        title: ' ',
        getCellValue: (row) => {
          return {
            owner: owner,
            currentRole: currentRole,
          };
        },
      },
    ];

    let tableColumnExtensions = [
      { columnName: 'thumbnail', width: thumbnailWidth },
      { columnName: 'filename', width: filenameColumn },
      { columnName: 'search_tags', width: 1 },
      { columnName: 'file_type', width: 70 },
      { columnName: 'file_size', width: 100 },
      { columnName: 'updatedAt', width: 120 },
      { columnName: 'rating', width: 80 },
      { columnName: 'library_menu', width: 60, align: 'right' },
    ];

    let sortingStateColumnExtensions = [
      { columnName: 'thumbnail', sortingEnabled: false },
      { columnName: 'filename', sortingEnabled: true },
      { columnName: 'search_tags', sortingEnabled: false },
      { columnName: 'file_type', sortingEnabled: true },
      { columnName: 'file_size', sortingEnabled: true },
      { columnName: 'updatedAt', sortingEnabled: true },
      { columnName: 'rating', sortingEnabled: true },
      { columnName: 'library_menu', sortingEnabled: false },
    ];

    let filteringStateColumnExtensions = [
      { columnName: 'thumbnail', filteringEnabled: false },
      { columnName: 'filename', filteringEnabled: false },
      { columnName: 'search_tags', filteringEnabled: false },
      { columnName: 'file_type', filteringEnabled: true },
      { columnName: 'file_size', filteringEnabled: true },
      { columnName: 'updatedAt', filteringEnabled: true },
      { columnName: 'rating', filteringEnabled: true },
      { columnName: 'library_menu', filteringEnabled: false },
    ];

    let integratedFilteringColumnExtensions = [
      { columnName: 'filename', predicate: multipleFilter },
      { columnName: 'search_tags', predicate: tagsFilter },
    ];

    let integratedSortingColumnExtensions = [
      { columnName: 'filename', compare: libraryNameSort },
    ];

    const shareColumnName = interactiveVideoContentSelection
      ? 'select'
      : 'share';

    if (
      interactiveVideoContentSelection ||
      (!enterprise && verb_live) ||
      appUser
    ) {
      sortable = false;
      tableType = 'other';
      columns.splice(0, 0, {
        name: shareColumnName,
        title: shareColumnName === 'select' ? 'Select' : 'Share',
        getCellValue: (row) => {
          return {
            _id: row._id,
            locked: row.locked,
            shareable: row.shareable,
          };
        },
      });
      tableColumnExtensions.splice(0, 0, {
        columnName: shareColumnName,
        width: 55,
      });
    }

    if (sortable && (!searchValue || searchValue === '')) {
      tableType = 'sortable';

      columns.splice(0, 0, { name: 'drag', title: ' ' });
      tableColumnExtensions.splice(0, 0, { columnName: 'drag', width: 50 });

      columns.splice(1, 0, {
        name: 'display_order',
        title: 'Display Order',
        getCellValue: (row) => row.display_order + 1,
      });
      tableColumnExtensions.splice(1, 0, {
        columnName: 'display_order',
        width: 90,
      });

      sortingStateColumnExtensions = [
        { columnName: 'drag', sortingEnabled: false },
        { columnName: 'display_order', sortingEnabled: false },
        { columnName: 'thumbnail', sortingEnabled: false },
        { columnName: 'filename', sortingEnabled: false },
        { columnName: 'rating', sortingEnabled: false },
        { columnName: 'tags', sortingEnabled: false },
        { columnName: 'file_type', sortingEnabled: false },
        { columnName: 'file_size', sortingEnabled: false },
        { columnName: 'updatedAt', sortingEnabled: false },
        { columnName: 'library_menu', sortingEnabled: false },
      ];

      filteringStateColumnExtensions = [
        { columnName: 'drag', filteringEnabled: false },
        { columnName: 'display_order', filteringEnabled: false },
        { columnName: 'thumbnail', filteringEnabled: false },
        { columnName: 'filename', filteringEnabled: true },
        { columnName: 'rating', filteringEnabled: false },
        { columnName: 'tags', filteringEnabled: true },
        { columnName: 'file_type', filteringEnabled: true },
        { columnName: 'file_size', filteringEnabled: false },
        { columnName: 'updatedAt', filteringEnabled: false },
        { columnName: 'library_menu', filteringEnabled: false },
      ];
    }

    if (
      interactiveVideoContentSelection ||
      (!enterprise && verb_live) ||
      (enterprise && appUser)
    ) {
      liveTeam = 'liveTeam';
      sortingStateColumnExtensions.splice(0, 0, {
        columnName: shareColumnName,
        sortingEnabled: false,
      });
      filteringStateColumnExtensions.splice(0, 0, {
        columnName: shareColumnName,
        filteringEnabled: false,
      });
    }

    if (live_only || appUser) {
      rootExtendedHeader = classes.rootTeamsHeaderLiveOnly;
      leftBarClass = classes.leftBarDrawerLiveOnly;
      liveTeam = 'liveOnly';
    }

    if (selectedGroup && selectedGroup._id === SHOWCASES_ID) {
      //NOTE: had to add rating column in because it is cutting off the last column...could not figure out why

      columns = [
        {
          name: shareColumnName,
          title: shareColumnName === 'select' ? 'Select' : 'Share',
          getCellValue: (row) => row._id,
        },
        {
          name: 'thumbnail',
          title: ' ',
          getCellValue: (row) => row.branding.branding_background_image,
        },
        { name: 'name', title: 'Name' },
        { name: 'updatedAt', title: 'Last Updated' },
        { name: 'rating', title: 'Rating' },
      ];

      tableColumnExtensions = [
        { columnName: shareColumnName, width: 55 },
        { columnName: 'thumbnail', width: thumbnailWidth },
        { columnName: 'name' },
        { columnName: 'updatedAt', width: 120 },
        { columnName: 'rating', width: 120 },
      ];

      sortingStateColumnExtensions = [
        { columnName: shareColumnName, sortingEnabled: false },
        { columnName: 'thumbnail', sortingEnabled: false },
        { columnName: 'name', sortingEnabled: true },
        { columnName: 'updatedAt', sortingEnabled: true },
        { columnName: 'rating', sortingEnabled: false },
      ];

      filteringStateColumnExtensions = [
        { columnName: shareColumnName, filteringEnabled: false },
        { columnName: 'thumbnail', filteringEnabled: false },
        { columnName: 'name', filteringEnabled: true },
        { columnName: 'updatedAt', filteringEnabled: false },
        { columnName: 'rating', filteringEnabled: false },
      ];

      integratedFilteringColumnExtensions = [];

      integratedSortingColumnExtensions = [];
    }

    return (
      <div className={`${classes.root} ${alertsRoot} ${rootExtendedHeader}`}>
        <Grid
          container
          spacing={4}
          className={`${classes.mainRightGrid} ${
            live_only ? classes.mainRightGridLiveOnly : null
          } ${appUser ? classes.mainRightGridAppUser : null}`}
        >
          <SMandBelow>
            <Drawer
              anchor={'left'}
              open={anchor}
              onClose={this.toggleDrawer(false)}
              classes={{
                root: classes.hideMenuDrawerContainer,
                paper: leftBarClass,
              }}
              style={drawerStyle}
            >
              <IconButton
                className={classes.rightBarCloseIcon}
                onClick={this.toggleDrawer(false)}
              >
                <ChevronLeft />
              </IconButton>
              {this.leftSide({
                enterprise,
                enterpriseAppUser,
                appUser,
                appUserPersonal,
                appUserPersonalLibraryGroups,
                appUserPersonalContent,
                appUserInteractiveVideos,
                verb_live,
                all_content_group,
                owner,
                currentRole,
                libraryGroups,
                libraryCount,
                showcasesCount,
                selectedGroup,
                displayParentSubs,
                selectedGroupMenuElement,
                selectedGroupMenuElement,
                classes,
              })}
            </Drawer>
            <Grid
              item
              sm={1}
              className={`${classes.gridRightBorder} ${classes.leftBarArea}`}
            >
              <IconButton
                onClick={this.toggleDrawer(true)}
                className={classes.leftBarOpenIcon}
              >
                <ChevronRight />
                <Typography variant='h6' className={classes.verticalDrawerName}>
                  Content Groups
                </Typography>
              </IconButton>
            </Grid>
          </SMandBelow>

          <MDandAbove>
            <Grid item xs={3} className={`${classes.gridRightBorder}`}>
              {this.leftSide({
                enterprise,
                enterpriseAppUser,
                appUser,
                appUserPersonal,
                appUserPersonalLibraryGroups,
                appUserPersonalContent,
                appUserInteractiveVideos,
                verb_live,
                all_content_group,
                owner,
                currentRole,
                libraryGroups,
                libraryCount,
                showcasesCount,
                selectedGroup,
                displayParentSubs,
                selectedGroupMenuElement,
                selectedGroupMenuElement,
                classes,
              })}
            </Grid>
          </MDandAbove>

          <Grid
            item
            md={9}
            sm={11}
            className={`${classes.gridPaddingLeft} ${classes.rightBarArea}`}
          >
            {this.handlePreviewLibraryItemModal()}
            {this.handleLibraryItemModal()}
            {this.handleLibraryItemDetailsModal()}
            {this.handleDeleteLibraryItemModal()}
            {this.handleEditUploadedItemsModal()}
            {this.handleOpenInteractiveModal()}

            <Grid
              container
              spacing={1}
              alignItems='center'
              className={classes.libraryListHeader}
            >
              <Grid item className={classes.libraryListHeaderItem}>
                <Typography
                  variant='h3'
                  className={`${classes.selectedTeamHeader} ${
                    classes.selectedTeamHeaderLibrary
                  } ${classes.truncateText} ${
                    interactiveVideoContentSelection &&
                    classes.truncateTextExtra
                  }`}
                >
                  <SMandBelow>
                    <span>
                      {!interactiveVideoContentSelection &&
                        (!appUser || personalGroupSelected) &&
                        selectedGroup &&
                        selectedGroup._id !== MY_VIDEOS_ID &&
                        this.addContentButton({
                          enterprise,
                          interactiveVideo,
                          appUser,
                          plusMenu,
                          threeSixtyMenu,
                          clientWidth,
                          selectedGroup,
                          currentRole,
                          owner,
                          toGroup,
                          all_content_group,
                          showAddToLibraryGroupButton,
                          three_sixty,
                          classes,
                        })}
                    </span>
                  </SMandBelow>

                  {selectedGroup ? selectedGroup.name : ''}
                </Typography>
              </Grid>

              <Grid item style={{ zIndex: 100 }}>
                <MDandAbove>
                  <span>
                    {!interactiveVideoContentSelection &&
                      (!appUser || personalGroupSelected) &&
                      selectedGroup &&
                      selectedGroup._id !== MY_VIDEOS_ID &&
                      this.addContentButton({
                        enterprise,
                        interactiveVideo,
                        appUser,
                        plusMenu,
                        threeSixtyMenu,
                        clientWidth,
                        selectedGroup,
                        currentRole,
                        owner,
                        toGroup,
                        all_content_group,
                        showAddToLibraryGroupButton,
                        three_sixty,
                        classes,
                      })}
                  </span>
                </MDandAbove>

                {enterprise && !appUser && interactiveVideoContentSelection && (
                  <React.Fragment>
                    <Badge
                      badgeContent={itemsToShare.length}
                      color='secondary'
                      classes={{
                        badge: `${classes.shareBadgeGoLive} ${classes.shareBadgeDark}`,
                      }}
                      style={{ position: 'relative', top: -3 }}
                    >
                      <Button
                        className={`${classes.blueHeaderButton}`}
                        variant='contained'
                        color='secondary'
                        // component={NavLink}
                        onClick={this.openInteractiveModalSwitch}
                      >
                        <OfflineBoltIcon
                          className={`${classes.videoCamButton} ${classes.videoCamButtonNotLiveOnly}`}
                        />
                        <span>Create Interactive Video</span>
                      </Button>
                    </Badge>
                    <span
                      onClick={this.emptyAddToShare}
                      className={classes.cancelInteractiveVideo}
                    >
                      Cancel
                    </span>
                  </React.Fragment>
                )}
              </Grid>
            </Grid>

            <Grid item style={{ position: 'relative', height: '100%' }}>
              <GridContainer
                appUser={appUser}
                enterpriseAppUser={enterpriseAppUser}
                personalGroupSelected={personalGroupSelected}
                currentRole={currentRole}
                tableType={tableType}
                // gridHeight={libraryTableHeight(liveTeam)}
                rows={rows}
                columns={columns}
                tableColumnExtensions={tableColumnExtensions}
                sortingStateColumnExtensions={sortingStateColumnExtensions}
                sorting={sorting}
                handleSortingChange={this.handleSortingChange}
                filteringStateColumnExtensions={filteringStateColumnExtensions}
                integratedFilteringColumnExtensions={
                  integratedFilteringColumnExtensions
                }
                integratedSortingColumnExtensions={
                  integratedSortingColumnExtensions
                }
                showRowDetail={false}
                classes={classes}
                handleMenu={this.handleLibraryItemMenu}
                menuType={'library-new'}
                containerExtraClass={classes.plusButtonGrid}
                sortable={sortable}
                handleMoveArray={this.handleMoveArray}
                handleSearchValue={this.handleSearchValue}
                bulkEdit={this.turnOnBulkEdit}
                bulkEditOn={bulkEdit}
                alternateSelection={this.addToShare}
                onSelectionChange={onSelectionChange}
                selection={selectedItems}
                showSelectAll={showSelectAll}
                showSelectionColumn={showSelectionColumn}
              />
              <Menu
                anchorPosition={selectedLibraryItemPosition}
                anchorReference={'anchorPosition'}
                open={Boolean(selectedLibraryItemPosition)}
                onClose={() => this.handleLibraryItemMenuClose(null, null)}
              >
                <MenuItem
                  onClick={() =>
                    this.handleLibraryItemMenuClose(
                      'preview',
                      selectedLibraryItemId
                    )
                  }
                >
                  <ListItemIcon>
                    <Search />
                  </ListItemIcon>
                  Preview
                </MenuItem>
                <MenuItem
                  onClick={() =>
                    this.handleLibraryItemMenuClose(
                      'edit',
                      selectedLibraryItemId
                    )
                  }
                >
                  <ListItemIcon>
                    <Edit />
                  </ListItemIcon>
                  Edit
                </MenuItem>
                {selectedLibraryItemFiletype === 'iv' && (
                  <MenuItem
                    onClick={() =>
                      this.handleLibraryItemMenuClose(
                        'details',
                        selectedLibraryItemId
                      )
                    }
                  >
                    <ListItemIcon>
                      <MoreIcon />
                    </ListItemIcon>
                    Video Details
                  </MenuItem>
                )}
                {!appUser &&
                  selectedGroup &&
                  selectedGroup._id !== FULL_LIBRARY_ID &&
                  selectedGroup._id !== all_content_group && (
                    <MenuItem
                      onClick={() =>
                        this.handleLibraryItemMenuClose(
                          'remove',
                          selectedLibraryItemId
                        )
                      }
                    >
                      <ListItemIcon>
                        <RemoveCircle />
                      </ListItemIcon>
                      Remove from Group
                    </MenuItem>
                  )}
                <MenuItem
                  onClick={() =>
                    this.handleLibraryItemMenuClose(
                      'delete',
                      selectedLibraryItemId
                    )
                  }
                >
                  <ListItemIcon>
                    <Delete />
                  </ListItemIcon>
                  Delete from System
                </MenuItem>
              </Menu>
              {selectedItems && selectedItems.length > 0 && (
                <div
                  className={`${classes.bulkEditOptionsContainer} ${
                    live_only ? classes.bulkEditOptionsContainerLiveOnly : null
                  }`}
                >
                  <Grid container spacing={4}>
                    <Grid item sm={6} xs={12} style={{ textAlign: 'center' }}>
                      <Fab
                        variant='extended'
                        color='secondary'
                        onClick={() => this.bulkEdit(false)}
                        className={`${classes.bulkEditingButton}`}
                      >
                        <Edit className={classes.bulkIcon} />
                        <span style={{ paddingLeft: 3 }}>Bulk Edit</span>
                      </Fab>
                    </Grid>
                    <Grid item sm={6} xs={12} style={{ textAlign: 'center' }}>
                      <Fab
                        variant='extended'
                        color='secondary'
                        onClick={() => this.bulkEdit(true)}
                        className={`${classes.bulkEditingButton}`}
                      >
                        <Delete className={classes.bulkIcon} />
                        <span style={{ paddingLeft: 3 }}>Bulk Delete</span>
                      </Fab>
                    </Grid>
                  </Grid>
                </div>
              )}
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const LibraryWithLoading = WithLoading(Library);

class LibraryContainer extends Component {
  state = {
    loading: true,
  };

  loadPageData = (newAppProfile, callback) => {
    const {
      token,
      companyId,
      appProfile,
      appUser,
      handlePageLoading,
      fetchLibrary,
      fetchLibraryGroups,
      fetchAppUserPersonal,
      fetchShowcasesInAppProfile,
      history,
      loggedOut,
    } = this.props;

    const handleAppProfile = newAppProfile ? newAppProfile : appProfile,
      appProfileId = handleAppProfile._id,
      { library_groups } = handleAppProfile;

    verifyLoggedIn(history, loggedOut, () => {
      let loadData = [];

      loadData.push(
        fetchLibrary(token, companyId, library_groups, (data) => {})
      );
      loadData.push(
        fetchLibraryGroups(token, companyId, library_groups, (data) => {})
      );

      if (appUser && appProfileId) {
        loadData.push(fetchAppUserPersonal(token, (data) => {}));
        loadData.push(
          fetchShowcasesInAppProfile(token, appProfileId, (data) => {})
        );
      }

      Promise.all(loadData).then((result) => {
        const handleCallback = callback ? callback() : null;

        this.setState({ loading: false });
      });
    });
  };

  componentDidMount() {
    const { token, enterprise, currentUser, location, master_admin } =
      this.props;

    this.loadPageData(null, () => {});

    const values = {
      token,
      master_admin,
      enterprise,
      email: currentUser.email,
      page: location.pathname,
    };

    activeCampaignEvent(values);
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { appProfile, handlePageLoading } = this.props,
      newAppProfile = nextProps.appProfile,
      oldId = appProfile._id,
      newId = newAppProfile._id;

    if (oldId !== newId) {
      this.setState({ loading: true });
      this.loadPageData(newAppProfile);
    }

    return true;
  }

  render() {
    const { loading } = this.state;

    return (
      <LibraryWithLoading
        isLoading={loading}
        loadPageData={this.loadPageData}
        {...this.props}
      />
    );
  }
}

function mapStateToProps(state) {
  if (
    _.isEmpty(state.login.token) ||
    _.isEmpty(state.login.company) ||
    _.isEmpty(state.login.user)
  ) {
    return {
      loggedOut: true,
    };
  }

  const enterprise =
      state.login.user.user_type > '1' ? false : state.login.company.enterprise,
    { appProfile } = state,
    { library_groups } = appProfile,
    all_content_group = !enterprise
      ? library_groups && library_groups[0]
        ? library_groups[0]
        : state.company.all_content_group
      : null,
    appUser = handleAppUser(state);

  return {
    pageLoading: state.pageLoading,
    token: state.login.token,
    master_admin: state.login.master_admin,
    companyId: state.login.company._id,
    company: state.company,
    enterprise,
    live_only: state.login.company.live_only
      ? state.login.company.live_only
      : state.login.user.user_type > '1',
    three_sixty: state.company.three_sixty,
    enterpriseAppUser: handleEnterpriseAppUser(state),
    appUser,
    appUserPersonal: state.appUserPersonal,
    appUserPersonalLibraryGroups: state.appUserPersonal
      ? state.appUserPersonal.personalLibraryGroups
      : [],
    appUserPersonalLibrary: state.appUserPersonal
      ? state.appUserPersonal.personalLibrary
      : [],
    verb_live: state.company.verb_live,
    interactiveVideo: state.company.interactive_video,
    appProfile: appUser ? state.appProfile : {},
    all_content_group,
    alerts: state.alerts,
    owner: state.login.user._id,
    currentUser: state.login.user,
    currentRole: state.login.user.user_type,
    library: state.library,
    libraryGroups: state.libraryGroups,
    itemsToShare: state.itemsToShare,
    libraryItems: state.fetchLibraryItems,
    showcases: state.showcases,
  };
}

const mapDispatchToProps = (dispatch) => ({
  handlePageLoading: (data, callback) =>
    dispatch(handlePageLoading(data, callback)),
  fetchLibrary: (token, companyId, groups, callback) =>
    dispatch(fetchLibrary(token, companyId, groups, callback)),
  fetchLibraryGroups: (token, companyId, groups, callback) =>
    dispatch(fetchLibraryGroups(token, companyId, groups, callback)),
  fetchAppUserPersonal: (token, callback) =>
    dispatch(fetchAppUserPersonal(token, callback)),
  fetchShowcasesInAppProfile: (token, appProfileId, callback) =>
    dispatch(fetchShowcasesInAppProfile(token, appProfileId, callback)),
  updateLibraryGroup: (token, values, id, callback) =>
    dispatch(updateLibraryGroup(token, values, id, callback)),
  moveLibraryGroup: (token, direction, id, callback) =>
    dispatch(moveLibraryGroup(token, direction, id, callback)),
  createLibraryItems: (token, files, groupId, callback) =>
    dispatch(createLibraryItems(token, files, groupId, callback)),
  shareLibraryItems: (items, callback) =>
    dispatch(shareLibraryItems(items, callback)),
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(LibraryContainer);
