import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from "redux-form";

import { withStyles } from '@material-ui/core/styles';
import { styles } from '../styles';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Modal from '@material-ui/core/Modal';
import MenuItem from '@material-ui/core/MenuItem';

import Close from '@material-ui/icons/Close';

import LoadingDivContainer from '../subcomponents/loadingDiv';
import { siteUrl, validateEmail, getTagSearchOptions } from '../helpers/helperFunctions';
import ShareLibraryContentModalContainer from '../modals/shareLibraryContent';
import ShareLibraryGroupModalContainer from '../modals/shareLibraryGroup';
import ShareShowcaseModalContainer from '../modals/shareShowcase';

import { RenderTextField } from '../subcomponents/form_subcomponents/renderTextField';
import { RenderSelectField } from '../subcomponents/form_subcomponents/renderSelectField';
import TagsField from '../subcomponents/form_subcomponents/tagsField';
import { ErrorText } from '../subcomponents/form_subcomponents/errorText';

import { fetchContactsRaw, fetchShareContact, fetchAppProfiles, shareToContact } from '../../actions/actions_index';

class ShareForm extends Component {
  constructor(props) {
    super(props);

    const { formError } = this.props;

    this.state = {
      error: formError,
      openShareLibraryContentModal: false,
      openShareLibraryGroupModal: false,
      openShareShowcaseModal: false,
      shareContentItems: [],
      shareGroups: [],
      shareShowcases: [],
      multipleEmails: [],
      multipleEmailsError: []
    }

    this.props.reset();
  }

  submitShareModal = (values) => {
    const { selectedContact, submittedShareModal, doneShareModal } = this.props;
    const { leads, token, companyId, owner } = this.props;
    const { multipleEmails, shareContentItems, shareGroups, shareShowcases } = this.state;

    let type = 'Contact',
        user_agent = null

    if (leads) {
      type = 'Lead';
    }

    if (shareContentItems.length === 0 && shareGroups.length === 0 && shareShowcases.length === 0) {
      this.setState({
        error: 'No Library Items Attached'
      })

      return;
    }

    if (!selectedContact && values.contact_type === '0' && multipleEmails.length === 0) {
      this.setState({
        error: `No ${type} Emails Entered`
      })

      return;
    }

    let existingContacts = [],
          newContacts = []

    if (multipleEmails.length > 0) {
      multipleEmails.map(email => {
        if (email.label.includes("NewItem")) {
          newContacts.push({email: email.value})
        } else {
          existingContacts.push(email.label) 
        }
        return null
      })
    } else if (values.contact_type === '1') {
      const { email, phone, first_name, last_name, contact_company } = values

      newContacts.push({
        email, phone, first_name, last_name, contact_company
      })
    }

    submittedShareModal(true);

    if (selectedContact) {
      existingContacts.push(selectedContact._id)
    }

    const { app_profile, contact_type, link_message } = values,
          date = Date.now()

    let dateShareContentItems = [],
        dateShareShowcases = []

    if (shareContentItems.length > 0) {
      dateShareContentItems = shareContentItems.map(item => { return { _id: item, date } })
    }

    if (shareShowcases.length > 0) {
      dateShareShowcases = shareShowcases.map(showcase => { return { showcase_template_id: showcase, date } })
    }

    if (navigator && navigator.userAgent) {
      user_agent = navigator.userAgent
    }

    const submitData = {
      user_details: {
        company: companyId,
        app_profile,
        user: owner
      },
      session_details: {
        session_type: 4,
        share_type: 4,
        custom_text: {
          link_message
        },
        user_agent
      },
      leads,
      newContacts,
      existingContacts,
      shareContentItems: dateShareContentItems,
      shareGroups,
      shareShowcases: dateShareShowcases
    }

    this.props.shareToContact(submitData, (data) => {
      if (data.data.error) {
        submittedShareModal(false, data.data.error, values);
        console.log(data.data.error);
      } else {
        submittedShareModal(false)
        doneShareModal(data.data);
      }
    });
  }

  selectShareLibraryContent = (items) => {
    this.setState({
      shareContentItems: items,
      openShareLibraryContentModal: false
    })
  }

  selectShareLibraryGroup = (groups) => {
    this.setState({
      shareGroups: groups,
      openShareLibraryGroupModal: false
    })
  }

  selectShareShowcase = (showcases) => {
    this.setState({
      shareShowcases: showcases,
      openShareShowcaseModal: false
    })
  }

  closedModal = () => {
    this.setState({
      openShareLibraryContentModal: false,
      openShareLibraryGroupModal: false,
      openShareShowcaseModal: false
    })
  }

  handleOpenShareLibraryContentModal = () => {
    const { openShareLibraryContentModal, shareContentItems } = this.state;

    if (openShareLibraryContentModal) {
      return (
        <ShareLibraryContentModalContainer
          openShareLibraryContentModal={openShareLibraryContentModal}
          currentSelected={shareContentItems}
          selectShareLibraryContent={this.selectShareLibraryContent}
          closedShareLibraryContentModal={this.closedModal}
        />
      )
    }
  }

  handleOpenShareLibraryGroupModal = () => {
    const { openShareLibraryGroupModal, shareGroups } = this.state;

    if (openShareLibraryGroupModal) {
      return (
        <ShareLibraryGroupModalContainer
          openShareLibraryGroupModal={openShareLibraryGroupModal}
          currentSelected={shareGroups}
          selectShareLibraryGroup={this.selectShareLibraryGroup}
          closedShareLibraryGroupModal={this.closedModal}
        />
      )
    }
  }

  handleOpenShareShowcaseModal = () => {
    const { openShareShowcaseModal, shareShowcases } = this.state;

    if (openShareShowcaseModal) {
      return (
        <ShareShowcaseModalContainer
          openShareShowcaseModal={openShareShowcaseModal}
          currentSelected={shareShowcases}
          selectShareShowcase={this.selectShareShowcase}
          closedShareShowcaseModal={this.closedModal}
        />
      )
    }
  }

  openModal = (type) => {
    if (type === 'content') {
      this.setState({
        openShareLibraryContentModal: true
      })
    } else if (type === 'groups') {
      this.setState({
        openShareLibraryGroupModal: true
      })
    } else if (type === 'showcases') {
      this.setState({
        openShareShowcaseModal: true
      })
    }
  }

  selectAppProfiles = () => {
    const { appProfiles } = this.props;
    if (appProfiles.length > 0) {
      return (
        appProfiles.map(value => <MenuItem key={value._id} value={value._id}>{value.name}</MenuItem>)
      )
    }
  }

  handleMultipleEmailsError = (error) => {
    this.setState({
      multipleEmailsError: error
    })
  }

  updateMultipleEmails = (type, id, multipleEmails) => {
    this.setState({
      multipleEmails: multipleEmails
    })
  }

  shareForm = () => {
    const { leads, selectedContact, tagSearchOptions, classes, formValues, pristine, invalid } = this.props;
    const { error, multipleEmails, shareContentItems, shareGroups, shareShowcases } = this.state;

    let type = 'Contact';

    if (leads) {
      type = 'Lead';
    }

    let extraButtonClass = classes.modalButton;

    if (selectedContact) {
      extraButtonClass = classes.shareModalButton;
    }

    return (
      <div>
        <ErrorText className={classes.bigError} touched={true} error={error} />
        <Grid container spacing={10}>
          <Grid item xs={6} className={`${classes.gridBorderRight}`}>
            <Field
              name="app_profile"
              label="Select App Profile"
              component={RenderSelectField}
            >
              {this.selectAppProfiles()}
            </Field>
            {!selectedContact &&
              <div>
              <Field
                name="contact_type"
                label="Share Type"
                component={RenderSelectField}
              >
                <MenuItem key="0" value="0">{`Enter ${type} Email(s)`}</MenuItem>
                <MenuItem key="1" value="1">{`New Detailed ${type}`}</MenuItem>
              </Field>
                {(formValues.Contact && formValues.Contact.values && formValues.Contact.values.contact_type === '0') &&
                  //setup as tags entry
                  <TagsField 
                    type={'contacts'}
                    label={'Enter Contact Emails'}
                    name={'multiple_emails'}
                    tags={multipleEmails}
                    allTags={tagSearchOptions}
                    className={classes.tagsMarginTop}
                    handleTagError={this.handleMultipleEmailsError}
                    updateTags={this.updateMultipleEmails}
                  />
                }
                {(formValues.Contact && formValues.Contact.values && formValues.Contact.values.contact_type === '1') && 
                  <div>
                    <Field name="email" component={RenderTextField} type="email" label="Email" />
                    <Field name="phone" component={RenderTextField} type="phone" label="Phone" />
                    <Field name="first_name" component={RenderTextField} type="text" label="First Name" />
                    <Field name="last_name" component={RenderTextField} type="text" label="Last Name" />
                    <Field name="contact_company" component={RenderTextField} type="text" label="Company" />
                  </div>
                }
              </div>
            }
          </Grid>
          <Grid item xs={6} className={`${classes.containerPaddingLeft}`}>
            {this.handleOpenShareLibraryContentModal()}
            {this.handleOpenShareLibraryGroupModal()}
            {this.handleOpenShareShowcaseModal()}
            <Field type="text" name="link_message" multiline={3} component={RenderTextField} label="Message" />
            <br />
            <br />
            <Typography variant="h6" className={classes.openModalLink} onClick={() => this.openModal('content')}>
              Select Library Content
            </Typography>
            {shareContentItems.length > 0 &&
              <Typography variant="body1">
                {shareContentItems.length} Items Selected
              </Typography>
            }
            <br />
            <Typography variant="h6" className={classes.openModalLink} onClick={() => this.openModal('groups')}>
              Select Content Group(s)
            </Typography> 
            {shareGroups.length > 0 &&
              <Typography variant="body1">
                {shareGroups.length} Groups Selected
              </Typography>
            } 
            <br />
            <Typography variant="h6" className={classes.openModalLink} onClick={() => this.openModal('showcases')}>
              Select Showcase(s)
            </Typography> 
            {shareShowcases.length > 0 &&
              <Typography variant="body1">
                {shareShowcases.length} Showcases Selected
              </Typography>
            }         
          </Grid>
        </Grid>
        <Button className={`${classes.button} ${extraButtonClass}`} variant="contained" color="secondary" type="submit" disabled={pristine || invalid}>Share</Button>
      </div>
    )
  }

  render() {
    const { selectedContact, handleSubmit, classes } = this.props;

    let extraFormClass = '';

    if (selectedContact) {
      extraFormClass = classes.shareForm;
    }

    return (
      <form className={extraFormClass} noValidate autoComplete="off" onSubmit={handleSubmit(values => this.submitShareModal(values))}>
        {this.shareForm()} 
      </form>
    )
  }
}

function validate(values, props) {
  const errors = {};
  let requiredFields = ['app_profile'];

  //**when texting or emailing added => need to validate for phone or email**
  
  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = 'Required';
    }
  });

  if (values['contact_type'] === '1') {

    let oneOfRequiredFields = [
      'email', 'phone', 'first_name'
    ];

    let noFields = true;
    
    oneOfRequiredFields.forEach(field => {
      if (values[field]) {
        noFields = false;
      }
    })

    if (noFields) {
      errors['email'] = 'Email, Phone, or First and Last Name Required';
    }

    if (!noFields && values['first_name'] && !values['last_name'] && !values['email'] && !values['phone']) {
      props.touch('last_name');
      errors['last_name'] = 'If no email or phone, must have first and last name.'
    }

  } 

  if (values.email && validateEmail(values.email)) {
    errors['email'] = 'Invalid email address';
  }

  return errors;
}

function mapStateToProps(state) {
  
  return { 
    token: state.login.token,
    companyId: state.login.company._id,
    enterprise: state.login.company.enterprise,
    verb_live: state.login.company.enterprise,
    currentRole: state.login.user.user_type,
    currentUser: state.login.user,
    owner: state.login.user._id,
    contacts: state.contacts,
    appProfiles: state.appProfiles,
    formValues: state.form
  };
}

const mapDispatchToProps = dispatch => ({
  fetchContactsRaw: (token, companyId, callback) => dispatch(fetchContactsRaw(token, companyId, callback)),
  fetchShareContact: (token, id, callback) => dispatch(fetchShareContact(token, id, callback)),
  fetchAppProfiles: (token, companyId, callback) => dispatch(fetchAppProfiles(token, companyId, callback)),
  shareToContact: (values, callback) => dispatch(shareToContact(values, callback))
});

const ShareFormContainer = reduxForm({
  form: 'Contact',
  validate,
  enableReinitialize: true,
})(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ShareForm)));


class ShareModal extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loadingContacts: true,
      loadingAppProfile: true,
      contactInfo: null,
      formError: null,
      shareLinks: null,
      filteredContacts: []
    }
  }

  componentDidMount() {
    const { token, companyId, selectedContact, leads } = this.props;

    if (selectedContact) {
      this.props.fetchAppProfiles(token, companyId, appProfiles => {
        this.setState({
          loadingAppProfile: false
        })
      })

      this.props.fetchShareContact(token, selectedContact._id, contact => {
        this.setState({
          loadingContacts: false,
          contactInfo: contact.data
        })
      })
    } else {
      this.props.fetchContactsRaw(token, companyId, contacts => {
        let tagSearchOptions = getTagSearchOptions(leads, contacts.data);

        this.setState({
          loadingContacts: false,
          tagSearchOptions
        })
      })

      this.props.fetchAppProfiles(token, companyId, appProfiles => {
        this.setState({
          loadingAppProfile: false
        })
      })
    }
  }

  submittedShareModal = (loading, error, values) => {
    this.setState({
      loadingContacts: loading,
      formError: error
    })
  }

  doneShareModal = (data) => {
    const { closedShareModal} = this.props;

    data.map(obj => {
      return obj.link = `${siteUrl()}space/${obj.link_udid}`;
    })

    this.setState({
      shareLinks: data
    })

    closedShareModal(true, true);
  }

  shareFormContainer = () => {
    const { leads, selectedContact, closedShareModal } = this.props;
    const { tagSearchOptions, formError } = this.state;

    const initialValues = {
      contact_type: "0"
    }
    
    return (
      <ShareFormContainer 
        leads={leads}
        tagSearchOptions={tagSearchOptions}
        selectedContact={selectedContact}
        initialValues={initialValues}
        submittedShareModal={this.submittedShareModal}
        doneShareModal={this.doneShareModal}
        closedShareModal={closedShareModal}
        formError={formError}
      />
    )
  }

  modalSize = () => {
    const { classes } = this.props;
    
    return (
        classes.mediumModal
      )
  }

  copyLink = (link) => {
    navigator.clipboard.writeText(link)
  }

  displayShowcases = (showcases) => {
    const { classes } = this.props;

    if (showcases.length > 0) {
      return (
        showcases.map(showcase => (
          <Typography variant="subtitle1" key={showcase} className={`${classes.sharedLink}`}>
            <div className={classes.sharedHeading}>Shared Showcase:</div> 
            <div>{`${siteUrl()}showcase/${showcase}`}</div>
            <div style={{marginTop: 10}}>
              <a className={classes.previewSpaceLink} href={`${siteUrl()}showcase_instance_preview/${showcase}`} target="_blank">Preview</a> <a className={classes.previewSpaceLink} onClick={() => this.copyLink(`${siteUrl()}showcase/${showcase}`)}>Copy Link</a>
            </div>
          </Typography>
        ))
      )
    } else {
      return;
    }
  }

  displayShareLinks = () => {
    const { classes } = this.props;
    const { shareLinks } = this.state;
    
    return (
      shareLinks.map(obj => (
        <div key={obj.link_udid} style={{padding: '40px 20px 0px'}}>
          {obj.contact &&
            <Typography variant="h4" className={classes.shareContact}>
              {obj.contact}
            </Typography>
          }
          <Typography variant="subtitle1" className={`${classes.sharedLink}`}>
            <div className={classes.sharedHeading}>Shared Space:</div>
            <div className={classes.shareSpaceLink}>{obj.link}</div>  
            <div style={{marginTop: 10}}>
              <a className={classes.previewSpaceLink} href={obj.link.replace('space', 'space_preview')} target="_blank">Preview</a> 
              <a className={classes.previewSpaceLink} onClick={() => this.copyLink(obj.link)}>Copy Link</a>
            </div>
          </Typography>
          {this.displayShowcases(obj.showcases)}
        </div>
      ))
    )
  }

  handleModalBody = () => {
    const { selectedContact, classes } = this.props;
    const { loadingContacts, loadingAppProfile, contactInfo, shareLinks } = this.state;

    let withString = '',
        name = '',
        title = '',
        subheading = '',
        subheadingClass = classes.modalSubheading;

    if (selectedContact) {
      
      if (selectedContact.first_name) {
        withString = ' with ';
        name = selectedContact.first_name;

        if (selectedContact.last_name) {
          name += ` ${selectedContact.last_name}`
        }
      } else if (selectedContact.email) {
        withString = ' with ';
        name = selectedContact.email;
      }

      title = <span>Share Content{withString}<span className={classes.modalTitleItem}>{name}</span></span>;
      subheading = 'Share library content, groups, or showcases.';
    
    } else {
      
      title = "Share Content";
      subheading = 'Share library content, groups, or showcases.';

    }

    if (loadingContacts || loadingAppProfile) {
      return (
        <LoadingDivContainer 
          type={'small'}
        />
      )
    } else if (shareLinks) {
      return (
        <div style={{height: 'calc(100% - 50px)'}}>
          <Typography variant="h3">
              {(shareLinks.length > 1) ? 'Generated Links' : 'Generated Link'}
            </Typography>
          <div className={classes.generatedLinks}>
            {this.displayShareLinks()}
          </div>
        </div>
      )
    } else {
      return (
        <div className={classes.divContainer}>
          <Typography variant="h6">
            {title}
          </Typography>
          <Typography variant="subtitle1" className={subheadingClass}>
            {subheading}
          </Typography>
          {this.shareFormContainer()}
          {(contactInfo && contactInfo.latest_share) &&
            <div className={classes.sharedContentContainer}>
              <Typography variant="h6" style={{marginBottom: 10}}>
                Shared Content
              </Typography>
              <Typography variant="subtitle1" className={`${subheadingClass} ${classes.sharedLink}`}> 
                <div className={classes.sharedHeading}>Shared Space:</div>
                <div>{`${siteUrl()}space/${contactInfo.latest_share.link_udid}`}</div>
                <div style={{marginTop: 10}}>
                  <a className={classes.previewSpaceLink} href={`${siteUrl()}space_preview/${contactInfo.latest_share.link_udid}`} target="_blank">Preview</a> 
                  <a className={classes.previewSpaceLink} onClick={() => this.copyLink(`${siteUrl()}space/${contactInfo.latest_share.link_udid}`)}>Copy Link</a>
                </div>
              </Typography>
              {this.displayShowcases(contactInfo.showcases)}
            </div>
          }
        </div>
      )
    }
  }

  render() {
    const { openShareModal, closedShareModal, classes } = this.props;

    return (
      <Modal
        aria-labelledby="share-content"
        aria-describedby="share-content"
        open={openShareModal}
        onClose={() => closedShareModal(false, false)}
      >
        <div className={`${classes.modal} ${this.modalSize()}`}>
          <IconButton 
            className={classes.iconClose}
            onClick={() => closedShareModal(false, false)}
          >
            <Close />
          </IconButton>
          {this.handleModalBody()}
        </div>
      </Modal>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ShareModal));
