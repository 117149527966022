import _ from "lodash";
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import PageVisibility from 'react-page-visibility';
import { Beforeunload } from 'react-beforeunload';

import { withStyles } from '@material-ui/core/styles';
import { styles } from '../styles';

// import { Typography, AppBar, Toolbar, Grid, IconButton, Card, CardMedia, Button } from '@material-ui/core';
// import { CloudDownload, ArrowDropDown } from '@material-ui/icons';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';

import CloudDownload from '@material-ui/icons/CloudDownload';

import LoadingDivContainer from '../subcomponents/loadingDiv';
import ExplainerModalContainer from '../modals/explainerModal';
import PreviewLibraryItemModalContainer from '../modals/previewLibraryItemModal';

import { ROOT_URL } from '../../actions/actions_constants';
import { fetchSolofireShowcase } from '../../actions/actions_index';

import { energy } from '../../theme';

//Time Duration Tracker
let TOTAL_DURATION = 0;

class Showcase extends Component {
	constructor(props) {
    super(props);

    this.state = {
      loading: true,
      explainer: false,
      preview: false,
      openedId: null,
      showcase: null,
      branding: null,
      contact: null,
      library_files: null,
      content_details: null,
      timer: null,
      pageActive: true,
      selectedLibraryItem: {},
      openPreviewLibraryItemModal: false,
      whenClickedDuration: 0
    }
  }

	componentWillMount() {
		const { _id, email, first_name, last_name } = this.props.match.params;
    let preview = false;
    let values = null;

    if (email) {
      values = {
        email: email,
        first_name: first_name,
        last_name: last_name
      }
    }

    if (!values) {
      preview = true;
    }

		this.props.fetchSolofireShowcase(_id, values, data => {

			const info = data.data;

			this.setState({
				loading: false,
        preview: preview,
        openedId: info.openedId,
				showcase: info.showcase,
        contact: info.contact,
        library_files: info.library_files,
        content_details: info.content_details,
        branding: {
          branding_primary: energy,
          branding_background: '/images/showcase_banner.png'
        },
        details: {
          user_company: 'SoloFire',
          user_name: 'Dustin Kenyon',
          user_email: 'dustin@solofire.com',
          user_phone: '801-396-9742'
        }
			})
		});

		this.startTimer();
	}

  componentDidMount () {
    const script = document.createElement("script");
    script.type = 'text/javascript';
    script.innerHTML = 'window.$crisp=[];window.CRISP_WEBSITE_ID="0abe5e35-8164-4e39-8c76-e5a2bed85d86";(function(){d=document;s=d.createElement("script");s.src="https://client.crisp.chat/l.js";s.async=1;d.getElementsByTagName("head")[0].appendChild(s);})();';
    script.async = true;

    document.body.appendChild(script);

    this.setState({
      explainer: true
    })
  }

	//Count Duration
	startTimer = () => {
	  setInterval(this.tick, 1000)
	}

	handleVisibilityChange = active => {
	  this.setState({ 
	    pageActive: active 
	  });
	}

	tick = () => {
	  const { preview, pageActive, openedId } = this.state;

	  if (pageActive) {
	    TOTAL_DURATION += 1000;
	  }

    if (navigator && navigator.sendBeacon && pageActive && !preview && openedId && TOTAL_DURATION % 5000 === 0) {
      const data = {
        openedId: openedId,
        total_duration: TOTAL_DURATION
      }

      let formData = new FormData();
      _.map(data, (value, key) => {
        formData.append(key, value);
      });

      navigator.sendBeacon(`${ROOT_URL}showcase_closed`, formData);
    }
  }
	//End Duration

	//Record View/Download Event
  roomItemClick = (event, item) => {
    if (item.link) {
      this.setState({
        selectedLibraryItem: item
      })
      
      this.recordEvent(4, item._id);
    } else {

      this.setState({
        openPreviewLibraryItemModal: true,
        selectedLibraryItem: item,
        whenClickedDuration: TOTAL_DURATION
      })

    }

    
  }

  recordDownloadEvent = (event, downloadedItemId)  => {
	  event.stopPropagation();

    if (!downloadedItemId) {
      downloadedItemId = this.state.selectedLibraryItem._id;
    }

	  this.recordEvent(3, downloadedItemId);
	}

	// recordReshareEvent = (event, library_item_id)  => {
	//   event.stopPropagation();
	//   this.recordEvent('reshare', library_item_id);
	// }

	recordEvent = (type, downloadedItemId) => {
    const { preview, showcase, openedId, contact, selectedLibraryItem, whenClickedDuration } = this.state;
    
    if (!preview) {
      //type 1: opened (recorded on open, updated on closed), 2: view, 3: download, 4: link, 5: closed
  	  
      let data = {
        showcaseId: showcase._id, 
        openedId: openedId,
        contact: contact._id
      };

      let formData = new FormData();

      // console.log(type)
      // console.log(selectedLibraryItem)
      // console.log(downloadedItemId)
      // console.log(duration)

      if (type === 5) {
        data.total_duration = TOTAL_DURATION;

        if (selectedLibraryItem) {
          data.item = selectedLibraryItem._id;
          data.duration = TOTAL_DURATION - whenClickedDuration;
        }
      } else if (type === 4) {
        data.item = downloadedItemId; //link
        data.type = 4;
      } else if (type === 3 && downloadedItemId) {
        data.item = downloadedItemId;
        data.type = 3;
      } else if (selectedLibraryItem) {
        data.item = selectedLibraryItem._id;
        data.duration = TOTAL_DURATION - whenClickedDuration;
        data.type = 2;
      }

      _.map(data, (value, key) => {
        formData.append(key, value);
      });
      
      if (!preview && type === 5 && navigator && navigator.sendBeacon) {
        navigator.sendBeacon(`${ROOT_URL}showcase_closed`, formData);
      } else if (!preview && navigator && navigator.sendBeacon) {
        navigator.sendBeacon(`${ROOT_URL}showcase_event`, formData);
      }

      this.setState({
        selectedLibraryItem: null,
        dowloadedItem: null
      })
    }
	}

  closedLibraryItemModal = () => {
    this.recordEvent(2);

    this.setState({
  		openPreviewLibraryItemModal: false,
      selectedLibraryItem: null
  	})
  }

  closedExplainerModal = () => {
    this.setState({
      explainer: false
    })
  }

  openExplainerModal = () => {
    this.setState({
      explainer: true
    })
  }

  handleExplainerModal = () => {
    const { explainer } = this.state;

    return (
      <ExplainerModalContainer 
        openExplainerModal={explainer}
        closedExplainerModal={this.closedExplainerModal}
      />
    )
  }  

  handlePreviewLibraryItemModal = () => {
    const { openPreviewLibraryItemModal, selectedLibraryItem, branding } = this.state;
    let color = null;

    if (branding.branding_primary) {
    	color = {
    		color: branding.branding_primary
    	}
    }

    if (openPreviewLibraryItemModal) {
      return (
        <PreviewLibraryItemModalContainer
          openPreviewLibraryItemModal={openPreviewLibraryItemModal}
          selectedLibraryItem={selectedLibraryItem}
          closedLibraryItemModal={this.closedLibraryItemModal}
          type='room'
          color={color}
          //**RECORD
          recordDownloadEvent={this.recordDownloadEvent}
        />
      )
    }
  }

  card = (item) => {
    const { classes } = this.props;
    const { branding } = this.state;

    const color = {
      color: branding.branding_primary
    }

    const _id = item._id;

    return (
      <div key={_id} className={`${classes.showcaseItem}`}>
        {item.link &&
          <a href={item.filename} target="_blank">
            <Card
              _id={_id}
              key={_id}
              className={`${classes.card} ${classes.roomCard} ${classes.showcaseCard}`}
              type="link"
              onClick={event => this.roomItemClick(event, item)}
            >
              <CardMedia
                _id={_id}
                className={`${classes.cardMedia} ${classes.showcaseCardMedia}`}
                component="img"
                alt={item.name}
                image={item.custom_thumbnail ? item.custom_thumbnail : item.thumbnail}
                title={item.name}
              />
            </Card>
          </a>
        }
        {!item.link &&
          <Card
            _id={_id}
            key={_id}
            className={`${classes.card} ${classes.roomCard} ${classes.showcaseCard}`}
            type="view"
            onClick={event => this.roomItemClick(event, item)}
          >
            <CardMedia
              _id={_id}
              className={`${classes.cardMedia} ${classes.showcaseCardMedia}`}
              component="img"
              alt={item.name}
              image={item.custom_thumbnail ? item.custom_thumbnail : item.thumbnail}
              title={item.name}
            />
            {(item.downloadable && !item.link) && 
              <a 
                href={item.download_url} 
                download={item.name}
                type="download"
                onClick={(event) => this.recordDownloadEvent(event, _id)}
                target="_blank"
              >
                <IconButton className={classes.showcaseDownloadButton}>
                  <CloudDownload style={color} className={classes.roomDownloadIcon} />
                </IconButton>
              </a>
            }
          </Card>
        }
      </div>
    )

  }

  cardRow = (row) => {
    const { library_files, content_details } = this.state;

    let cards = [0,1,2];

    if (row === 2) {
      cards = [3,4,5];
    }

    return (
      cards.map((card,value) => {
        return (
          this.card(_.find(content_details, {_id: library_files[card]}))
        )
      })
    )
  }

  render() {
  	const { classes } = this.props;
  	const { loading, branding, details } = this.state;
  	
  	if (loading) {
      return (
        <LoadingDivContainer />
      )
    } else {

    	return (
    		<div className={classes.showcase}>
	    		<div style={{background: `url(${branding.branding_background}) no-repeat`, backgroundSize: 'cover', backgroundPosition: 'top'}}>
            <div className={classes.showcaseContainer}>
              <Grid container spacing={4} className={`${classes.grid} ${classes.showcaseMainheaderGrid}`}>
                <Grid item xs={6} className={`${classes.showcaseHeaderGrid} ${classes.showcaseHeaderGridText}`}>
                  <Grid container spacing={4} className={classes.grid}>
                    <Grid item xs={6}>
                      <Grid container spacing={4} className={classes.grid}>
                        <Grid item xs={2} className={classes.gridNoPadding}>
                          <img className={classes.showcaseIcon} src="/images/solofire_icon.png" alt={''} />
                        </Grid>
                        <Grid item xs={10}>
                          <Typography variant="body1" className={classes.showcaseHeaderEnergy}>
                            Prepared by
                          </Typography>
                          <Typography variant="h3" className={classes.showcaseHeaderName}>
                              {details.user_name}
                          </Typography>
                          <Typography variant="body1" className={classes.showcaseHeaderText}>
                            {details.user_company}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={6}>
                      <br />
                      <Typography variant="body1" className={classes.showcaseHeaderRightText}>
                        {details.user_phone}
                      </Typography>
                      <Typography variant="body1" className={classes.showcaseHeaderRightText}>
                        <a className={classes.showcaseLink} href={`mailto:${details.user_email}`}>{details.user_email}</a>
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={4} className={classes.grid}>
                    <Grid item xs={12} className={classes.showcaseMainTextContainer}>
                      <Typography variant="h2" className={classes.showcaseH2}>
                        <span className={classes.showcaseH2Light}>Welcome to</span> SoloFire
                      </Typography>
                      <Typography variant="body1" className={classes.showcaseMainText}>
                        Content is critical for every sales conversation, and here at SoloFire we set out to make an easy and beautiful experience for you and your buyers. SoloFire allows you to create and share <span className={classes.explainerLink} onClick={this.openExplainerModal}>custom engaging experiences</span> centered around your content for your customers, and track their engagement in real time.
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6} className={`${classes.showcaseHeaderGrid} ${classes.showcaseHeaderGridImage}`}>
                  <img className={classes.showcaseHeaderImage} src="/images/showcase_devices.png" alt={''} />
                </Grid>
              </Grid>
              <PageVisibility onChange={this.handleVisibilityChange}>
              </PageVisibility>
              <Beforeunload 
                onBeforeunload={event => {
                  this.recordEvent(5);
                }} 
              />
              
              <div className={classes.showcaseItemContainer}>
                {this.cardRow(1)}
              </div>
              <div className={classes.showcaseItemContainer}>
                {this.cardRow(2)}
              </div>

              {this.handleExplainerModal()}
              {this.handlePreviewLibraryItemModal()}
            </div>
          </div>
          <div className={classes.roomPoweredByBar}>
            <Typography variant="body1" className={classes.roomPoweredByText}>
              <span className={classes.roomPoweredByTextSpan}>Powered by <a href="https://solofire.com">SoloFire</a></span>
              <img className={classes.roomPoweredByLogo} src="/images/solofire_icon.png" />
            </Typography>
          </div>
    		</div>
    	)
    }
  }
}

function mapStateToProps(state) {
  return { 
    
  };
}

const mapDispatchToProps = dispatch => ({
  fetchSolofireShowcase: (_id, values, callback) => dispatch(fetchSolofireShowcase(_id, values, callback))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Showcase)));