import { FETCH_APP_PROFILE, ACTIVE_APP_PROFILE, CREATE_APP_PROFILE, UPDATE_APP_PROFILE, DELETE_APP_PROFILE } from "../actions/actions_app_profiles";

export default function(state = {}, action) {
  switch (action.type) {
    
    case FETCH_APP_PROFILE:
      if (!action.payload) {
        return state;
      }
      return action.payload;

    case ACTIVE_APP_PROFILE:
      if (!action.payload) {
        return state;
      }
      return action.payload;

    case CREATE_APP_PROFILE:
      if (!action.payload) {
        return state;
      }
      return action.payload;
      // return _.mapKeys(action.payload.data, "_id");

    case UPDATE_APP_PROFILE:
      if (!action.payload) {
        return state;
      }
      return action.payload;

    case DELETE_APP_PROFILE:
      if (!action.payload) {
        return state;
      }
      return action.payload;
    
    default:
      return state;
  }
}