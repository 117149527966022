import { FETCH_DASHBOARD, FETCH_VIEW_RATE, FETCH_USERS_ANALYTICS, FETCH_CONTACTS_ANALYTICS, FETCH_CONTENT_ANALYTICS, FETCH_DETAILS_ANALYTICS, FETCH_SHOWCASES_ANALYTICS, FETCH_SESSIONS_ANALYTICS, FETCH_SCREENS_ANALYTICS } from "../actions/actions_analytics";

export default function(state = [], action) {
  switch (action.type) {

    case FETCH_DASHBOARD:
      if (!action.payload) {
        return state;
      }
      return {
        ...state,
        dashboard: action.payload
      }
      // return _.mapKeys(action.payload.data, "_id");

    case FETCH_VIEW_RATE:
      if (!action.payload) {
        return state;
      }
      return {
        ...state,
        viewRate: action.payload
      }

    case FETCH_USERS_ANALYTICS:
      if (!action.payload) {
        return state;
      }
      return {
        ...state,
        users: action.payload
      }

    case FETCH_CONTACTS_ANALYTICS:
      if (!action.payload) {
        return state;
      }
      return {
        ...state,
        contacts: action.payload
      }

    case FETCH_CONTENT_ANALYTICS:
      if (!action.payload) {
        return state;
      }
      return {
        ...state,
        content: action.payload
      }

    case FETCH_SHOWCASES_ANALYTICS:
      if (!action.payload) {
        return state;
      }
      return {
        ...state,
        showcases: action.payload
      }

    case FETCH_SESSIONS_ANALYTICS:
      if (!action.payload) {
        return state;
      }
      return {
        ...state,
        sessions: action.payload
      }

    case FETCH_SCREENS_ANALYTICS:
      if (!action.payload) {
        return state;
      }
      return {
        ...state,
        screens: action.payload
      }

    case FETCH_DETAILS_ANALYTICS:
      if (!action.payload) {
        return state;
      }
      return {
        ...state,
        details: action.payload
      }
    
    default:
      return state;
  }
}