import axios from "axios";
import { ROOT_URL } from "./actions_constants";

export const FETCH_CONTACTS_RAW = "fetch_contacts_raw";
export const FETCH_CONTACTS = "fetch_contacts";
export const FETCH_CONTACT = "fetch_contact";
export const CREATE_CONTACT = "create_contact";
export const SHARE_CONTACT = "share_contact";
export const SHARE_TO_CONTACT = "share_to_contact";
export const GO_LIVE = "go_live";
export const UPDATE_CONTACT = "update_contact";
export const DELETE_CONTACT = "delete_contact";
export const CONTACT_FEED = "contact_feed";
export const SHARED_NOT_OPENED = "shared_not_opened";
export const TIMELINE = "timeline";

const fetchContactsRawSuccess = contacts => ({
  type: FETCH_CONTACTS_RAW,
  payload: contacts
});

export const fetchContactsRaw = (token, companyId, callback) => (dispatch) => {

  const TOKEN = token;

  return (axios.get(`${ROOT_URL}company/contacts_raw/${companyId}`, 
    { 
      headers: { 
        Authorization: 'Bearer ' + TOKEN
      }
    }))
    .then(result => {
      const contacts = result.data;

      dispatch(fetchContactsRawSuccess(contacts));

      if(callback) {
        callback(result);
      }
      
      return result;
    })
    .catch(error => {
      console.log(error)
      
      if (error.response && error.response.data) {
        callback(error.response.data)
      }
    })
}

const fetchContactsSuccess = contacts => ({
  type: FETCH_CONTACTS,
  payload: contacts
});

export const fetchContacts = (token, companyId, callback) => (dispatch) => {

  const TOKEN = token;

  return (axios.get(`${ROOT_URL}company/contacts/${companyId}`, 
    { 
      headers: { 
        Authorization: 'Bearer ' + TOKEN
      }
    }))
    .then(result => {
      const contacts = result.data;

      dispatch(fetchContactsSuccess(contacts));

      if(callback) {
        callback(result);
      }
      
      return result;
    })
    .catch(error => {
      console.log(error)
      
      if (error.response && error.response.data) {
        callback(error.response.data)
      }
    })
}

const fetchContactSuccess = contact => ({
  type: FETCH_CONTACT,
  payload: contact
});

export const fetchContact = (token, id, callback) => (dispatch) => {

  const TOKEN = token;

  return (axios.get(`${ROOT_URL}contacts/${id}`, 
    { 
      headers: { 
        Authorization: 'Bearer ' + TOKEN
      }
    }))
    .then(result => {
      const contact = result.data;

      dispatch(fetchContactSuccess(contact));

      if(callback) {
        callback(result);
      }
      
      return result;
    })
    .catch(error => {
      console.log(error)
      
      if (error.response && error.response.data) {
        callback(error.response.data)
      }
    })
}

const fetchShareContactSuccess = contact => ({
  type: FETCH_CONTACT,
  payload: contact
});

export const fetchShareContact = (token, id, callback) => (dispatch) => {

  const TOKEN = token;

  return (axios.get(`${ROOT_URL}share_contact/${id}`, 
    { 
      headers: { 
        Authorization: 'Bearer ' + TOKEN
      }
    }))
    .then(result => {
      const contact = result.data;

      dispatch(fetchShareContactSuccess(contact));

      if(callback) {
        callback(result);
      }
      
      return result;
    })
    .catch(error => {
      console.log(error)
      
      if (error.response && error.response.data) {
        callback(error.response.data)
      }
    })
}

const createLeadSuccess = lead => ({
  type: CREATE_CONTACT,
  payload: lead
});

export const createLead = (values, callback) => (dispatch) => {

  return (axios.post(`${ROOT_URL}create_lead`, values))
    .then(result => {
      const lead = result.data;

      dispatch(createLeadSuccess(lead));

      if(callback) {
        callback(result);
      }

      return result;
    })
    .catch(error => {
      console.log(error)
      
      if (error.response && error.response.data) {
        callback(error.response.data)
      }
    })
}

const createContactSuccess = contact => ({
  type: CREATE_CONTACT,
  payload: contact
});

export const createContact = (token, values, callback) => (dispatch) => {

  const TOKEN = token;

  return (axios.post(`${ROOT_URL}contacts`,
      values,
      { 
        headers: { 
          Authorization: 'Bearer ' + TOKEN
        }
      }))
    .then(result => {
      const contact = result.data;

      dispatch(createContactSuccess(contact));

      if(callback) {
        callback(result);
      }

      return result;
    })
    .catch(error => {
      console.log(error)
      
      if (error.response && error.response.data) {
        callback(error.response.data)
      }
    })
}

const shareContactSuccess = sessionEventLink => ({
  type: SHARE_CONTACT,
  payload: sessionEventLink
});

export const shareContact = (token, values, callback) => (dispatch) => {

  const TOKEN = token;

  return (axios.post(`${ROOT_URL}contacts/share`,
      values,
      { 
        headers: { 
          Authorization: 'Bearer ' + TOKEN
        }
      }))
    .then(result => {
      const sessionEventLink = result.data;

      dispatch(shareContactSuccess(sessionEventLink));

      if(callback) {
        callback(result);
      }

      return result;
    })
    .catch(error => {
      console.log(error)
      
      if (error.response && error.response.data) {
        callback(error.response.data)
      }
    })
}

//NEW SHARE
const shareToContactSuccess = sessionEventLink => ({
  type: SHARE_TO_CONTACT,
  payload: sessionEventLink
});

export const shareToContact = (values, callback) => (dispatch) => {

  return (axios.post(`${ROOT_URL}share_to_contact`,
      values))
    .then(result => {
      const sessionEventLink = result.data;

      dispatch(shareToContactSuccess(sessionEventLink));

      if(callback) {
        callback(result);
      }

      return result;
    })
    .catch(error => {
      console.log(error)
      
      if (error.response && error.response.data) {
        callback(error.response.data)
      }
    })
}

const goLiveSuccess = go_live => ({
  type: GO_LIVE,
  payload: go_live
});

export const goLive = (token, values, callback) => (dispatch) => {

  const TOKEN = token;

  return (axios.post(`${ROOT_URL}contacts/go_live`,
      values,
      { 
        headers: { 
          Authorization: 'Bearer ' + TOKEN
        }
      }))
    .then(result => {
      const go_live = result.data;

      dispatch(goLiveSuccess(go_live));

      if(callback) {
        callback(result);
      }

      return result;
    })
    .catch(error => {
      console.log(error)
      
      if (error.response && error.response.data) {
        callback(error.response.data)
      }
    })
}

const updateContactSuccess = contact => ({
  type: UPDATE_CONTACT,
  payload: contact
});

export const updateContact = (token, values, id, callback) => (dispatch) => {

  const TOKEN = token;

  return (axios.put(`${ROOT_URL}contacts/${id}`, 
    values,
    { 
      headers: { 
        Authorization: 'Bearer ' + TOKEN
      }
    }))
    .then(result => {
      const contactsItem = result.data;

      dispatch(updateContactSuccess(contactsItem));

      if(callback) {
        callback(result);
      }
      
      return result;
    })
    .catch(error => {
      console.log(error)
      
      if (error.response && error.response.data) {
        callback(error.response.data)
      }
    })
}

const contactFeedSuccess = contacts => ({
  type: CONTACT_FEED,
  payload: contacts
});

export const contactFeed = (token, values, callback) => (dispatch) => {

  const TOKEN = token;

  // values => { company, app_profile, user }
  return (axios.post(`${ROOT_URL}pulse_analytics/contact_feed`, 
    values,
    { 
      headers: { 
        Authorization: 'Bearer ' + TOKEN
      }
    }))
    .then(result => {
      const contactFeedList = result.data;

      dispatch(contactFeedSuccess(contactFeedList));

      if(callback) {
        callback(result);
      }
      
      return result;
    })
    .catch(error => {
      console.log(error)
      
      if (error.response && error.response.data) {
        callback(error.response.data)
      }
    })
}

const sharedNotOpenedSuccess = contacts => ({
  type: SHARED_NOT_OPENED,
  payload: contacts
});

export const sharedNotOpened = (token, values, callback) => (dispatch) => {

  const TOKEN = token;

  // values => { company, app_profile, user }
  return (axios.post(`${ROOT_URL}pulse_analytics/shared_not_opened`, 
    values,
    { 
      headers: { 
        Authorization: 'Bearer ' + TOKEN
      }
    }))
    .then(result => {
      const sharedNotOpenedList = result.data;

      dispatch(sharedNotOpenedSuccess(sharedNotOpenedList));

      if(callback) {
        callback(result);
      }
      
      return result;
    })
    .catch(error => {
      console.log(error)
      
      if (error.response && error.response.data) {
        callback(error.response.data)
      }
    })
}

const timelineSuccess = timeline => ({
  type: TIMELINE,
  payload: timeline
});

export const timeline = (token, values, callback) => (dispatch) => {

  const TOKEN = token;

  // values => { company, app_profile, user }
  return (axios.post(`${ROOT_URL}pulse_analytics/timeline`, 
    values,
    { 
      headers: { 
        Authorization: 'Bearer ' + TOKEN
      }
    }))
    .then(result => {
      const timelineList = result.data;

      dispatch(timelineSuccess(timelineList));

      if(callback) {
        callback(result);
      }
      
      return result;
    })
    .catch(error => {
      console.log(error)
      
      if (error.response && error.response.data) {
        callback(error.response.data)
      }
    })
}

const deleteContactSuccess = contact => ({
  type: DELETE_CONTACT,
  payload: contact
});

export const deleteContact = (token, id, callback) => (dispatch) => {

  const TOKEN = token;

  let url = `${ROOT_URL}contacts/${id}`

  return (axios.delete(url, 
    { 
      headers: { 
        Authorization: 'Bearer ' + TOKEN
      }
    }))
    .then(result => {
      const contact = result.data;

      dispatch(deleteContactSuccess(contact));

      if(callback) {
        callback(result);
      }
      
      return result;
    })
    .catch(error => {
      console.log(error)
      
      if (error.response && error.response.data) {
        callback(error.response.data)
      }
    })
}
