import { FETCH_CONTENT_GROUPS } from "../actions/actions_content_groups";

export default function(state = [], action) {
  switch (action.type) {
    case FETCH_CONTENT_GROUPS:
      if (!action.payload) {
        return state;
      }
      return action.payload;
      // return _.mapKeys(action.payload.data, "_id");
    default:
      return state;
  }
}