import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';
import { styles } from '../styles';

import Modal from '@material-ui/core/Modal';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import Close from '@material-ui/icons/Close';

import InteractiveVideo from '../content/interactiveVideo';

class InteractiveModal extends Component {

  state = {
    message: null
  }

  handleVideoSaved = (appUser) => {

    const message = appUser ? 'Your video has been saved to My Videos, and added to your Share Cart.' : 'Your video has been saved to the Library, and added to your Share Cart.'

    this.setState({
      message
    })
  }
	
	handleModalBody = () => {
		const { reloadShareCart, selectedGroupId } = this.props
    const { message } = this.state

    return (
			<InteractiveVideo 
        handleVideoSaved={this.handleVideoSaved}
        reloadShareCart={reloadShareCart}
        selectedGroupId={selectedGroupId}
        containerMessage={message}
			/>
		)
	}

  render() {
    const { openInteractiveModal, closedInteractiveModal, classes } = this.props;

    return (
      <Modal
        aria-labelledby="interactive-content"
        aria-describedby="interactive-content"
        open={openInteractiveModal}
        onClose={() => closedInteractiveModal(false, false)}
      >
        <div className={`${classes.modal} ${classes.fullModal}`}>
          <IconButton 
            className={classes.iconClose}
            onClick={() => closedInteractiveModal(false, false)}
          >
            <Close />
          </IconButton>

          {this.handleModalBody()}
        
        </div>
      </Modal>
    )
  }
}

export default (withStyles(styles)(InteractiveModal));