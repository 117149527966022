import { FETCH_ALERTS } from "../actions/actions_alerts";

export default function(state = [], action) {
  switch (action.type) {
    case FETCH_ALERTS:
      if (!action.payload) {
        return state;
      }
      return action.payload;
    default:
      return state;
  }
}