import axios from "axios";
import { ROOT_URL } from "./actions_constants";

export const CREATE_SUBSCRIPTION = "create_subscription";
export const CANCEL_SUBSCRIPTION = "cancel_subscription";

const createSubscriptionSuccess = subscription => ({
  type: CREATE_SUBSCRIPTION,
  payload: subscription
});

export const createSubscription = (token, values, callback) => (dispatch) => {

  const TOKEN = token;

  return (axios.post(`${ROOT_URL}billings/start_subscription`,
    values, 
    { 
      headers: { 
        Authorization: 'Bearer ' + TOKEN
      }
    }))
    .then(result => {
      const subscription = result.data;

      dispatch(createSubscriptionSuccess(subscription));

      if(callback) {
        callback(result);
      }

      return subscription;
    })
    .catch(error => {
      console.log(error)
      
      if (error.response && error.response.data) {
        callback(error.response.data)
      }
    })
}

const cancelSubscriptionSuccess = subscription => ({
  type: CANCEL_SUBSCRIPTION,
  payload: subscription
});

export const cancelSubscription = (token, id, callback) => (dispatch) => {

  const TOKEN = token;

  return (axios.delete(`${ROOT_URL}billings/cancel_subscription/${id}`, 
    { 
      headers: { 
        Authorization: 'Bearer ' + TOKEN
      }
    }))
    .then(result => {
      const subscription = result.data;

      dispatch(cancelSubscriptionSuccess(subscription));

      if(callback) {
        callback(result);
      }

      return result;
    })
    .catch(error => {
      console.log(error)
      
      if (error.response && error.response.data) {
        callback(error.response.data)
      }
    })
}
