import _ from "lodash";
import React, { PureComponent } from "react";
import { compose } from 'redux';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { Line as LineChart, Doughnut as DoughnutChart } from "react-chartjs-2";
import 'chart.piecelabel.js';

import { withStyles } from '@material-ui/core/styles';
import { styles } from '../styles';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import SupervisorAccount from '@material-ui/icons/SupervisorAccount';
import AvTimer from '@material-ui/icons/AvTimer';
import PeopleOutline from '@material-ui/icons/PeopleOutline';

import Counter from 'mdi-material-ui/Counter';
import MagnifyPlusOutline from 'mdi-material-ui/MagnifyPlusOutline';

import WithLoading from '../subcomponents/withLoading';
import GridContainer from '../subcomponents/gridContainer';

import { convertToGb, activeCampaignEvent } from '../helpers/helperFunctions';
import { multipleFilter } from '../helpers/gridLibraryHelpers';
import { compareName } from '../helpers/gridUsersHelpers';

import { fetchViewRate, fetchDashboard  } from "../../actions/actions_index";

class Dashboard extends PureComponent {
  state = {
    widgetHover: {
      users: null,
      sessions: null,
      sessionsAvg: null,
      viewedShared: null,
      libraryItems: null,
      sizeGb: null,
      contacts: null,
      viewRate: null,
      contentType: null,
      topReps: null,
      topSearches: null,
      topFilesViewed: null,
      topFilesShared: null,
      clientWidth: 0
    }
  }

  updateWindowSize = () => {
    this.setState({clientWidth: window.innerWidth})
  }

  componentDidMount() {
    this.updateWindowSize()
    window.addEventListener("resize", this.updateWindowSize)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowSize);
  }

  componentWillReceiveProps(newProps) {
    if (!_.isEqual(this.props.postData, newProps.postData)) {
      newProps.loadData(newProps.postData);
    }
  }

  handleWidget = (widget, hover) => {
    const { classes } = this.props;

    let hoverClass = null;

    switch(widget) {
      case 'users':

        if (hover) {
          hoverClass = classes.widgetHover
        }

        this.setState({
          widgetHover: { users: hoverClass }
        })
        break;
      case 'sessions':

        if (hover) {
          hoverClass = classes.widgetHover
        }

        this.setState({
          widgetHover: { sessions: hoverClass }
        })
        break;
      case 'sessionsAvg':

        if (hover) {
          hoverClass = classes.widgetHover
        }

        this.setState({
          widgetHover: { sessionsAvg: hoverClass }
        })
        break;
      case 'viewedShared':

        if (hover) {
          hoverClass = classes.widgetHover
        }
        
        this.setState({
          widgetHover: { viewedShared: hoverClass }
        })
        break;
      case 'libraryItems':

        if (hover) {
          hoverClass = classes.widgetHover
        }
        
        this.setState({
          widgetHover: { libraryItems: hoverClass }
        })
        break;
      case 'sizeGb':

        if (hover) {
          hoverClass = classes.widgetHover
        }
        
        this.setState({
          widgetHover: { sizeGb: hoverClass }
        })
        break;
      case 'contacts':

        if (hover) {
          hoverClass = classes.widgetHover
        }
        
        this.setState({
          widgetHover: { contacts: hoverClass }
        })
        break;
      case 'viewRate':

        if (hover) {
          hoverClass = classes.widgetHover
        }
        
        this.setState({
          widgetHover: { viewRate: hoverClass }
        })
        break;
      case 'contentType':

        if (hover) {
          hoverClass = classes.widgetHover
        }
        
        this.setState({
          widgetHover: { contentType: hoverClass }
        })
        break;
      case 'topReps':

        if (hover) {
          hoverClass = classes.widgetHover
        }
        
        this.setState({
          widgetHover: { topReps: hoverClass }
        })
        break;
      case 'topSearches':

        if (hover) {
          hoverClass = classes.widgetHover
        }
        
        this.setState({
          widgetHover: { topSearches: hoverClass }
        })
        break;
      case 'topFilesViewed':

        if (hover) {
          hoverClass = classes.widgetHover
        }
        
        this.setState({
          widgetHover: { topFilesViewed: hoverClass }
        })
        break;
      case 'topFilesShared':

        if (hover) {
          hoverClass = classes.widgetHover
        }
        
        this.setState({
          widgetHover: { topFilesShared: hoverClass }
        })
        break;
      default:
        break;
    }
  }

  render() {
    const { dashboard, viewRate, classes } = this.props;
    const { widgetHover, clientWidth } = this.state;

    const chart = {
      data: {
        labels: dashboard.dateRange,
        datasets: [{
          label: 'Sessions',
          data: dashboard.sessionsOverTime,
          backgroundColor: 'rgba(255, 99, 132, 0.2)',
          borderColor: 'rgba(255,99,132,1)',
          borderWidth: 1
        },
        {
          label: 'Shares',
          data: dashboard.sharesOverTime,
          backgroundColor: 'rgba(75, 192, 192, 0.2)',
          borderColor: 'rgba(75, 192, 192, 1)',
          borderWidth: 1
        },
        {
          label: 'Space Opens',
          data: dashboard.opensOverTime,
          backgroundColor: 'rgba(255, 159, 64, 0.2)',
          borderColor: 'rgba(255, 159, 64, 1)',
          borderWidth: 1
        },
        {
          label: 'All Views',
          data: dashboard.viewsOverTime,
          backgroundColor: 'rgba(54, 162, 235, 0.2)',
          borderColor: 'rgba(54, 162, 235, 1)',
          borderWidth: 1
        },
        {
          label: 'Downloads',
          data: dashboard.downloadsOverTime,
          backgroundColor: 'rgba(153, 102, 255, 0.2)',
          borderColor: 'rgba(153, 102, 255, 1)',
          borderWidth: 1
        }]
        //colors
        // background
          // 'rgba(54, 162, 235, 0.2)',
          // 'rgba(255, 206, 86, 0.2)',
          // 'rgba(75, 192, 192, 0.2)',
          // 'rgba(153, 102, 255, 0.2)',
          // 'rgba(255, 159, 64, 0.2)'
        // ],
        //border
          // 'rgba(54, 162, 235, 1)',
          // 'rgba(255, 206, 86, 1)',
          // 'rgba(75, 192, 192, 1)',
          // 'rgba(153, 102, 255, 1)',
          // 'rgba(255, 159, 64, 1)'
        // ],
      },
      options: {
        maintainAspectRatio: false,
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              fontSize: 13
              // steps: max/10 >= 1 ? max/10 : 1,
              // stepValue: max/10 >= 1 ? max/10 : 1,
              // max: max >=10 ? max : 10
            }
          }],
          xAxes: [{
            ticks: {
              maxTicksLimit: 10,
              fontSize: 13
            }
          }]
        }
      }
    };

    const doughnutBackgroundColors = [
      'rgba(54, 162, 235, .8)',
      'rgba(255, 206, 86, .8)',
      'rgba(75, 192, 192, .8)',
      'rgba(153, 102, 255, .8)',
      'rgba(255, 159, 64, .8)',
      'rgba(255, 162, 235, .8)',
      'rgba(54, 206, 86, .8)',
      'rgba(153, 192, 192, .8)',
      'rgba(75, 102, 255, .8)',
      'rgba(54, 159, 64, .8)',
      'rgba(206, 162, 235, .8)',
      'rgba(54, 255, 86, .8)',
      'rgba(102, 192, 192, .8)',
      'rgba(75, 153, 255, .8)',
      'rgba(159, 54, 64, .8)'
    ];

    const doughnutBorderColors = [
      'rgba(54, 162, 235, .8)',
      'rgba(255, 206, 86, .8)',
      'rgba(75, 192, 192, .8)',
      'rgba(153, 102, 255, .8)',
      'rgba(255, 159, 64, .8)',
      'rgba(255, 162, 235, .8)',
      'rgba(54, 206, 86, .8)',
      'rgba(153, 192, 192, .8)',
      'rgba(75, 102, 255, .8)',
      'rgba(54, 159, 64, .8)',
      'rgba(206, 162, 235, .8)',
      'rgba(54, 255, 86, .8)',
      'rgba(102, 192, 192, .8)',
      'rgba(75, 153, 255, .8)',
      'rgba(159, 54, 64, .8)'
    ];

    const pieceLabel = [
      {
        render: 'label',
        position: 'outside',
        outsidePadding: 8,
        textMargin: 8,
        fontStyle: 400,
        fontFamily: '"GillSans", Helvetica, Arial, "sans-serif"',
        fontSize: 13
      },
      {
        render: 'percentage',
        fontStyle: 600,
        fontFamily: '"GillSans", Helvetica, Arial, "sans-serif"',
        fontSize: 14
      }
    ]

    const viewDoughnut = {
      data: {
        labels: ['', ''],
        datasets: [{
          data: [viewRate.viewRate, viewRate.leftoverRate],
          backgroundColor: ['rgba(14, 205, 148, .8)','rgba(191, 191, 191, .8)'],
          borderColor: ['rgba(14, 205, 148, .8)','rgba(191, 191, 191, .8)'],
          borderWidth: 1
        }]
      },
      options: {
        maintainAspectRatio: false,
        cutoutPercentage: 80,
        legend: {
          display: false
        }
      }
    };

    const contentDoughnut = {
      data: {
        labels: dashboard.contentTypeLabels,
        datasets: [{
          data: dashboard.contentTypeData,
          backgroundColor: doughnutBackgroundColors,
          borderColor: doughnutBorderColors,
          borderWidth: 1
        }]
      },
      options: {
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        pieceLabel: pieceLabel
      }
    };

    const deviceDoughnut = {
      data: {
        labels: dashboard.deviceTypeLabels,
        datasets: [{
          data: dashboard.deviceTypeData,
          backgroundColor: doughnutBackgroundColors,
          borderColor: doughnutBorderColors,
          borderWidth: 1
        }]
      },
      options: {
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        pieceLabel: pieceLabel
      }
    };

    const rows = dashboard.repData.slice(0, 10);

    const columns = [
      { name: 'user_name', title: 'Name', getCellValue: row => ({first_name: row.first_name, last_name: row.last_name})},
      { name: 'totalSessions', title: 'Sessions'},
      // { name: 'repViews', title: 'All Content Views', getCellValue: row => { 
      //     return row.events.browses + row.events.shows + row.events.total_media_views
      //   }
      // },
      // { name: 'repShares', title: 'Shares', getCellValue: row => { 
      //     return row.events.shares + row.events.media_shares
      //   }
      // },
      { name: 'averageSessionLength', title: 'Avg Duration'}
    ];

    const tableColumnExtensions = [
      { columnName: 'name'},
      { columnName: 'totalSessions', width: 100 },
      // { columnName: 'repViews', width: 170 },
      // { columnName: 'repShares', width: 100 },
      { columnName: 'averageSessionLength', width: 200 }
    ]

    const sortingStateColumnExtensions = [
      { columnName: 'name', sortingEnabled: true },
      { columnName: 'totalSessions', sortingEnabled: true },
      // { columnName: 'repViews', sortingEnabled: true },
      // { columnName: 'repShares', sortingEnabled: true },
      { columnName: 'averageSessionLength', sortingEnabled: true }
    ]

    const filteringStateColumnExtensions = [
      { columnName: 'name', filteringEnabled: true },
      { columnName: 'totalSessions', filteringEnabled: false },
      // { columnName: 'repViews', filteringEnabled: false },
      // { columnName: 'repShares', filteringEnabled: false },
      { columnName: 'averageSessionLength', filteringEnabled: false }
    ]

    const integratedFilteringColumnExtensions = [
      { columnName: 'user_name', predicate: multipleFilter }
    ]

    const integratedSortingColumnExtensions = [
      { columnName: 'user_name', compare: compareName }
    ]

    const sorting = [
      { columnName: 'totalSessions', direction: 'desc' }
    ]

	  return (
	    <div className={classes.dashboardContentContainer}>
	      <Grid container spacing={4} className={classes.gridSmallMarginTop}>
          <Grid item md={3} xs={6}>
            <Typography variant="h5" className={classes.bigNumberHeading}><SupervisorAccount className={classes.bigNumberHeadingIcon} /> 
              Users
              {widgetHover.users &&
                <MagnifyPlusOutline className={`${classes.rightIcon}`} />
              }
            </Typography>
            <Link to="/analytics/users">
              <div className={`${classes.bigNumberBox} ${classes.usersBox} ${widgetHover.users}`} onMouseEnter={() => this.handleWidget('users', true)} onMouseLeave={() => this.handleWidget('users', false)} >
                <Typography variant="h2" className={classes.bigNumber}>
                  {dashboard.usersCount ? dashboard.usersCount : 0}
                </Typography>
              </div>
            </Link>
          </Grid>
          <Grid item md={3} xs={6}>
            <Typography variant="h5" className={classes.bigNumberHeading}><Counter className={classes.bigNumberHeadingIcon} /> 
              Sessions
              {widgetHover.sessions &&
                <MagnifyPlusOutline className={`${classes.rightIcon}`} />
              }
            </Typography>
            <Link to='/analytics/sessions'>
              <div className={`${classes.bigNumberBox} ${classes.sessionsBox} ${widgetHover.sessions}`} onMouseEnter={() => this.handleWidget('sessions', true)} onMouseLeave={() => this.handleWidget('sessions', false)} >
                <Typography variant="h2" className={classes.bigNumber}>
                  {dashboard.sessionsCount ? dashboard.sessionsCount : 0}
                </Typography>
              </div>
            </Link>
          </Grid>
          <Grid item md={3} xs={6}>
            <Typography variant="h5" className={classes.bigNumberHeading}><AvTimer className={classes.bigNumberHeadingIcon} /> 
              Avg Session {clientWidth > 960 ? ' (mins)' : ''}
              {widgetHover.sessionsAvg &&
                <MagnifyPlusOutline className={`${classes.rightIcon}`} />
              }
            </Typography>
            <Link to='/analytics/sessions'>
              <div className={`${classes.bigNumberBox} ${classes.sessionsBox} ${widgetHover.sessionsAvg}`} onMouseEnter={() => this.handleWidget('sessionsAvg', true)} onMouseLeave={() => this.handleWidget('sessionsAvg', false)}>
                <Typography variant="h3" className={`${classes.bigNumber} ${classes.bigNumberAvgSession}`}>
                  {dashboard.sessionsAvg && dashboard.sessionsAvg.averageSessionLength ? dashboard.sessionsAvg.averageSessionLength : 0}
                </Typography>
              </div>
            </Link>
          </Grid>
          <Grid item md={3} xs={6}>
            <Typography variant="h5" className={classes.bigNumberHeading}><PeopleOutline className={classes.bigNumberHeadingIcon} /> 
              Contacts
              {widgetHover.contacts &&
                <MagnifyPlusOutline className={`${classes.rightIcon}`} />
              }
            </Typography>
            <Link to='/analytics/contacts'>
              <div className={`${classes.bigNumberBox} ${classes.contactsBox} ${widgetHover.contacts}`} onMouseEnter={() => this.handleWidget('contacts', true)} onMouseLeave={() => this.handleWidget('contacts', false)}>
                <Typography variant="h2" className={classes.bigNumber}>
                  {dashboard.contacts ? dashboard.contacts : 0}
                </Typography>
              </div>
            </Link>
          </Grid>
          {/*<Grid item xs={3} onClick={() => this.handleWidget('viewedShared')} onMouseEnter={() => this.handleWidget('viewedShared', true)} onMouseLeave={() => this.handleWidget('viewedShared', false)} >
            <Typography variant="h5" className={classes.bigNumberHeading}><Pageview className={classes.bigNumberHeadingIcon} /> 
              Viewed / Shared
              {widgetHover.viewedShared &&
                <MagnifyPlusOutline className={`${classes.rightIcon}`} />
              }
            </Typography>
            <div className={`${classes.bigNumberBox} ${classes.usersBox} ${widgetHover.viewedShared}`}>
              <Typography variant="h2" className={classes.bigNumber}>{dashboard.appLibraryItemViewsCount + dashboard.mediaViewerViewsCount} / {dashboard.appLibraryItemSharesCount + dashboard.mediaViewerSharesCount}</Typography>
            </div>
          </Grid>*/}
          {/*<Grid item xs={2}>
            <Typography variant="h5" className={classes.bigNumberHeading}><Share className={classes.bigNumberHeadingIcon} /> Items Shared</Typography>
            <div className={classes.bigNumberBox}>
              <Typography variant="h2" className={classes.bigNumber}>{dashboard.appLibraryItemSharesCount + dashboard.mediaViewerSharesCount}</Typography>
            </div>
          </Grid>*/}
	      </Grid>

        {/*<Grid container spacing={4} className={`${classes.threeFourthGrid} ${classes.leftGrid}`}>*/}
        <Grid container spacing={4}>  
          <Grid item xs={12}>
            <LineChart 
              data={chart.data}
              options={chart.options}
              height={350}
            />
          </Grid>
        </Grid>
        <Grid container spacing={4} className={classes.gridMarginBottom}>
          <Grid item md={6} xs={12}>
            <Typography variant="subtitle1" className={`${classes.orderedListHeading}`}>
              View Rate
              {widgetHover.viewRate &&
                <MagnifyPlusOutline className={`${classes.rightIcon} ${classes.rightIconUp}`} />
              }
            </Typography>
            <Link to='/analytics/contacts'>
              <div className={`${classes.doughnutBox} ${widgetHover.viewRate}`} onMouseEnter={() => this.handleWidget('viewRate', true)} onMouseLeave={() => this.handleWidget('viewRate', false)}>
                <div className={classes.doughnutViewContainer}>
                  <DoughnutChart 
                    data={viewDoughnut.data}
                    options={viewDoughnut.options}
                  />
                </div>
                <Typography variant="h3" className={classes.viewRatePercentage}>
                  {viewRate.viewRate}<span className={classes.percentage}>%</span>
                </Typography>
                <Typography variant="h2" className={classes.bigColorNumber}>
                  <span className={classes.viewRateText}>Shared Content</span>
                  <span className={classes.shareCount}>
                    {viewRate.sessionEventCounts.shares ? viewRate.sessionEventCounts.shares : 0}
                  </span>
                  <span>
                    &nbsp;/&nbsp;
                  </span>  
                  <span className={classes.viewCount}>
                    {viewRate.unique_media_views ? viewRate.unique_media_views : 0}
                  </span>
                  <span className={classes.viewRateText}>Recipient Unique Views</span>
                </Typography>
              </div>
            </Link>
          </Grid>
          <Grid item md={6} xs={12}>
            <Typography variant="subtitle1" className={`${classes.orderedListHeading}`}>
              Content Type
              {widgetHover.contentType &&
                <MagnifyPlusOutline className={`${classes.rightIcon} ${classes.rightIconUp}`} />
              }
            </Typography>
            <Link to="/analytics/content">
              <div className={`${classes.doughnutBox} ${widgetHover.contentType}`} onMouseEnter={() => this.handleWidget('contentType', true)} onMouseLeave={() => this.handleWidget('contentType', false)}>
                <div className={classes.doughnutContainer}>
                  <DoughnutChart 
                    data={contentDoughnut.data}
                    options={contentDoughnut.options}
                  />
                </div>
                <Grid container spacing={4}>
                  <Grid item xs={6} className={`${classes.centerGrid} ${classes.contentSizeContainer}`}>
                    <Typography variant="h6" className={classes.contentSizeText}>
                      Total Assets
                    </Typography>
                    <Typography variant="h6" className={classes.contentSizeNumber}>
                      {dashboard.libraryCount ? dashboard.libraryCount : 0}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} className={`${classes.centerGrid} ${classes.contentSizeContainer}`}>
                    <Typography variant="h6">
                      Total Size in GB
                    </Typography>
                    <Typography variant="h6" className={classes.contentSizeNumber}>
                      {dashboard.librarySize ? convertToGb(dashboard.librarySize) : 0}
                    </Typography>
                  </Grid>
                </Grid>
              </div>
            </Link>
          </Grid>
        </Grid>

        {/*<Grid container direction={'column'} spacing={4} className={`${classes.oneFourthGrid} ${classes.rightGrid}`}>
          <Grid item xs={12} className={classes.columnGridPadding} onClick={() => this.handleWidget('libraryItems')} onMouseEnter={() => this.handleWidget('libraryItems', true)} onMouseLeave={() => this.handleWidget('libraryItems', false)} >
            <Typography variant="h5" className={classes.bigNumberHeading}><LibraryBooks className={classes.bigNumberHeadingIcon} /> 
              Library Items
              {widgetHover.libraryItems &&
                <MagnifyPlusOutline className={`${classes.rightIcon}`} />
              }
            </Typography>
            <div className={`${classes.bigNumberBox} ${classes.contentBox} ${widgetHover.libraryItems}`}>
              <Typography variant="h2" className={classes.bigNumber}>{dashboard.libraryCount}</Typography>
            </div>
          </Grid>
          <Grid item xs={12} className={classes.columnGridPadding} onClick={() => this.handleWidget('sizeGb')} onMouseEnter={() => this.handleWidget('sizeGb', true)} onMouseLeave={() => this.handleWidget('sizeGb', false)} >
            <Typography variant="h5" className={classes.bigNumberHeading}><Database className={classes.bigNumberHeadingIcon} /> 
              Size in GB
              {widgetHover.sizeGb &&
                <MagnifyPlusOutline className={`${classes.rightIcon}`} />
              }
            </Typography>
            <div className={`${classes.bigNumberBox} ${classes.contentBox} ${widgetHover.sizeGb}`}>
              <Typography variant="h2" className={classes.bigNumber}>{convertToGb(dashboard.librarySize)}</Typography>
            </div>
          </Grid>
        </Grid>*/}

        <Grid container spacing={4} className={classes.gridMarginBottom}>
          <Grid item md={6} xs={12} className={classes.topRepsContainer}>
            <Link to="/analytics/users">
              <Typography variant="subtitle1" className={`${classes.bigNumberHeading} ${classes.topRepsHeading}`}>
                {/*<VerifiedUser className={`${classes.bigNumberHeadingIcon} ${classes.topRepsHeading}`} />*/}
                Top Reps
                <MagnifyPlusOutline className={`${classes.topRepsIcon}`} />
              </Typography>
            </Link>
            <div style={{height: 440}} className={`${classes.repsBox} ${widgetHover.topReps}`}>
              <GridContainer 
                gridHeight={{height: 440, position: 'relative', top: -10}}
                tableType={'small'}
                rows={rows}
                columns={columns}
                hideSearch={true}
                tableColumnExtensions={tableColumnExtensions}
                sortingStateColumnExtensions={sortingStateColumnExtensions}
                sorting={sorting}
                filteringStateColumnExtensions={filteringStateColumnExtensions}
                integratedFilteringColumnExtensions={integratedFilteringColumnExtensions}
                integratedSortingColumnExtensions={integratedSortingColumnExtensions}
                showSelectAll={false}
                showSelectionColumn={false}
                showRowDetail={false}
                classes={classes}
              />
            </div>
          </Grid>
          <Grid item md={6} xs={12}>
            <Typography variant="subtitle1" className={`${classes.orderedListHeading} ${classes.deviceTypeHeading}`}>
              Device Type
            </Typography>
            <div className={`${classes.doughnutBox} ${classes.deviceBox}`}>
              <div className={classes.doughnutContainer}>
                <DoughnutChart 
                  data={deviceDoughnut.data}
                  options={deviceDoughnut.options}
                />
              </div>
            </div>
          </Grid>
        </Grid>

        <Grid container spacing={4}>
          <Grid item md={4} xs={12}>
            <Typography variant="subtitle1" className={classes.orderedListHeading}>
              Top Searches
              {widgetHover.topSearches &&
                <MagnifyPlusOutline className={`${classes.rightIcon} ${classes.rightIconUp}`} />
              }
            </Typography>
            <Link to="/analytics/content">
              <div className={`${classes.listBox} ${widgetHover.topSearches}`} onMouseEnter={() => this.handleWidget('topSearches', true)} onMouseLeave={() => this.handleWidget('topSearches', false)}>
                <ul className={classes.orderedList}>  
                {dashboard.topSearches.map((search, value) => {
                    if (value < 10) {
                      return (
                        <li key={search._id} className={classes.orderedListItem}>
                          <div className={`${classes.listLeft} ${classes.truncateText}`} style={{maxWidth: '75%'}}>
                            {search._id}
                          </div>
                          <div className={classes.listRight}>{search.searches}</div>
                        </li>
                      )
                    }
                    return null
                  }
                )}
                </ul>
              </div>
            </Link>
          </Grid>
          {/*<Grid item xs={4}>
            <Typography variant="subtitle1" className={classes.orderedListHeading}>Top Reps by Session</Typography>
            <div className={classes.listBox}>
              <ul className={classes.orderedList}>  
              {dashboard.mostActiveReps.map((rep) => 
                <li key={rep._id} className={classes.orderedListItem}><div className={classes.listLeft}>{rep.details.first_name} {rep.details.last_name}</div><div className={classes.listRight}>{rep.rep_sessions}</div></li>
              )}
              </ul>
            </div>
          </Grid>*/}
          <Grid item md={4} xs={12}>
            <Typography variant="subtitle1" className={classes.orderedListHeading}>
              Top Files by View
              {widgetHover.topFilesViewed &&
                <MagnifyPlusOutline className={`${classes.rightIcon} ${classes.rightIconUp}`} />
              }
            </Typography>
            <Link to="/analytics/content">
              <div className={`${classes.listBox} ${widgetHover.topFilesViewed}`} onMouseEnter={() => this.handleWidget('topFilesViewed', true)} onMouseLeave={() => this.handleWidget('topFilesViewed', false)}>
                <ul className={classes.orderedList}>  
                {dashboard.mostViewedLibraryItems.map((view, value) => {
                    if (value < 10) {
                      return (
                        <li key={view._id} className={classes.orderedListItem}>
                          <div className={`${classes.listLeft} ${classes.truncateText}`} style={{maxWidth: '75%'}}>
                            {view.details.name}
                          </div>
                          <div className={classes.listRight}>{view.views}</div>
                        </li>
                      )
                    }
                    return null
                  }
                )}
                </ul>
              </div>
            </Link>
          </Grid>
          <Grid item md={4} xs={12}>
            <Typography variant="subtitle1" className={classes.orderedListHeading}>
              Top Files by Share
              {widgetHover.topFilesShared &&
                <MagnifyPlusOutline className={`${classes.rightIcon} ${classes.rightIconUp}`} />
              }
            </Typography>
            <Link to="/analytics/content">
              <div className={`${classes.listBox} ${widgetHover.topFilesShared}`} onMouseEnter={() => this.handleWidget('topFilesShared', true)} onMouseLeave={() => this.handleWidget('topFilesShared', false)}>
                <ul className={classes.orderedList}>  
                {dashboard.mostSharedLibraryItems.map((share, value) => {
                    if (value < 10) {
                      return (
                        <li key={share._id} className={classes.orderedListItem}>
                          <div className={`${classes.listLeft} ${classes.truncateText}`} style={{maxWidth: '75%'}}>
                            {share.details.name}
                          </div>
                          <div className={classes.listRight}>{share.shares}</div>
                        </li>
                      )
                    }
                    return null
                  }
                )}
                </ul>
              </div>
            </Link>
          </Grid>
        </Grid>
      </div>
	  );
  }
	
}

const DashboardWithLoading = WithLoading(Dashboard);

class DashboardContainer extends PureComponent {
  state = {
    loading: true
  }

  loadData = (postData) => {
    const { token } = this.props;
    const loading = this.state.loading;

    if (!loading) {
      this.setState({
        loading: true
      })
    }

    let dataCalls = [];

    dataCalls.push(
      this.props.fetchViewRate(token, postData, result => {})
    )

    dataCalls.push(
      this.props.fetchDashboard(token, postData, result => {})
    )

    Promise.all(dataCalls)
      .then(result => {
        this.setState({
          loading: false
        })
      })
  }

  componentDidMount() {
    const { token, enterprise, currentUser, location, postData, master_admin } = this.props;

    this.loadData(postData);

    const values = {
      token,
      master_admin,
      enterprise,
      email: currentUser.email,
      page: location.pathname
    }

    activeCampaignEvent(values)
  }

  render() {
    const loading = this.state.loading;

    return (
      <DashboardWithLoading 
        isLoading={loading}
        loadingReport={true}
        loadData={this.loadData}
        {...this.props}
      />
    )
  }
}

function mapStateToProps(state) {
  if (_.isEmpty(state.login.token) || _.isEmpty(state.login.company) || _.isEmpty(state.login.user)) {
    return {
      loggedOut: true
    }
  }

  return { 
    token: state.login.token,
    master_admin: state.login.master_admin,
    companyId: state.login.company._id,
    owner: state.login.user._id,
    currentUser: state.login.user,
    currentRole: state.login.user.user_type,
    dashboard: state.analytics.dashboard,
    viewRate: state.analytics.viewRate
  };
}

const mapDispatchToProps = dispatch => ({
  fetchViewRate: (token, values, callback) => dispatch(fetchViewRate(token, values, callback)),
  fetchDashboard: (token, values, callback) => dispatch(fetchDashboard(token, values, callback))
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(DashboardContainer)
