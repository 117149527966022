// import _ from "lodash";
import { FETCH_ALL_META_TAGS } from "../actions/actions_tags";
import { FETCH_ALL_PERSONA_TAGS } from "../actions/actions_tags";
import { FETCH_ALL_PRODUCT_TAGS } from "../actions/actions_tags";

const mapTags = (tags) => {
  let data = [];

  tags.map(value => {
    const tag = {
      label: value,
      value: value
    }

    data.push(tag);

    return null;
  })

  return data;
}

export default function(state = [], action) {
  let data = [];

  switch (action.type) {
    case FETCH_ALL_META_TAGS:
      
      if (!action.payload) {
        return state;
      }
      
      data = mapTags(action.payload);

      return {
        ...state,
        meta: data
      }

    case FETCH_ALL_PERSONA_TAGS:
      
      if (!action.payload) {
        return state;
      }
      
      data = mapTags(action.payload);

      return {
        ...state,
        persona: data
      }

    case FETCH_ALL_PRODUCT_TAGS:
      
      if (!action.payload) {
        return state;
      }
      
      data = mapTags(action.payload);

      return {
        ...state,
        product: data
      }
    
    default:
      return state;
  }
}