import axios from "axios";
import { ROOT_URL } from "./actions_constants";
import React from 'react'
export const FETCH_ADMINS = "fetch_admins";

const fetchAdminsSuccess = admins => ({
  type: FETCH_ADMINS,
  payload: admins
});

export const fetchAdmins = (token, companyId, callback) => (dispatch) => {

  const TOKEN = token;

  return (axios.get(`${ROOT_URL}admins/${companyId}`, 
    { 
      headers: { 
        Authorization: 'Bearer ' + TOKEN
      }
    }))
    .then(result => {
    const sortedData = [...result.data].sort((a, b) =>
    a.last_name.toLowerCase().localeCompare(b.last_name.toLowerCase()) ||
               a.first_name.toLowerCase().localeCompare(b.first_name.toLowerCase())
    );
      const admins = sortedData;
      dispatch(fetchAdminsSuccess(admins));

      if(callback) {
        callback(result);
      }

      return admins;
    })
    .catch(error => {
      console.log(error)
      
      if (error.response && error.response.data) {
        callback(error.response.data)
      }
    })
}
