import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import { styles } from '../styles';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Modal from '@material-ui/core/Modal';
import MenuItem from '@material-ui/core/MenuItem';

import Close from '@material-ui/icons/Close';

import LoadingDivContainer from '../subcomponents/loadingDiv';

import { RenderTextField } from '../subcomponents/form_subcomponents/renderTextField';
import { RenderSelectField } from '../subcomponents/form_subcomponents/renderSelectField';
import TagsField from '../subcomponents/form_subcomponents/tagsField';
import { ErrorText } from '../subcomponents/form_subcomponents/errorText';

class ShareModal extends Component {

  componentDidMount() {
    this.props.handleGoLiveSubmit()
  }

  handleModalBody = () => {
    const { loadingLive, liveUrl, handleCopyLivestreamUrl, sharedItems, classes } = this.props;

    let withString = '',
        name = '',
        title = '',
        subheading = '',
        subheadingClass = classes.modalSubheading,
        numSharedItems = sharedItems.length,
        sharedItemsMessage = <span><span style={{fontWeight: 600}}>{numSharedItems} Content Items</span> will be shared to the LIVE Experience</span>

    title = "Go Live Session";
    subheading = numSharedItems > 0 ? sharedItemsMessage : 'The Best LIVE E-Commerce Selling Platform';

    if (loadingLive) {
      return (
        <React.Fragment>
          <Typography variant="h4">
              Generating Live Session
            </Typography>
          <LoadingDivContainer 
            type={'small'}
          />
        </React.Fragment>
      )
    } else {
      return (
        <div className={classes.divContainer}>
          <Typography variant="h4">
            {title}
          </Typography>
          <Typography variant="subtitle1" className={subheadingClass}>
            {subheading}
          </Typography>
         <a href={liveUrl} target="_blank" className={classes.goLiveLink}>
           <Button className={classes.goLiveButton} variant="contained" color="secondary">Go LIVE</Button>
         </a>
         <br />
         <br />
         <br />
         { handleCopyLivestreamUrl() }
        </div>
      )
    }
  }

  render() {
    const { openGoLiveModal, closedGoLiveModal, classes } = this.props;

    return (
      <Modal
        aria-labelledby="share-content"
        aria-describedby="share-content"
        open={openGoLiveModal}
        onClose={() => closedGoLiveModal(false, false)}
      >
        <div className={`${classes.modal} ${classes.bulkUsersDeleteModal}`}>
          <IconButton 
            className={classes.iconClose}
            onClick={() => closedGoLiveModal(false, false)}
          >
            <Close />
          </IconButton>
          {this.handleModalBody()}
        </div>
      </Modal>
    )
  }
}

function mapStateToProps(state) {
  
  return { 
    token: state.login.token,
    owner: state.login.user._id,
    owner_email: state.login.user.email,
    libraryItems: state.uploadedLibraryItems
  };
}

export default connect(mapStateToProps, null)(withStyles(styles)(ShareModal));
