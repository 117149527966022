import axios from "axios";
import { ROOT_URL } from "./actions_constants";

export const FETCH_USERS = "fetch_users";
export const FETCH_USER = "fetch_user";
export const VERIFY_USER = "verify_user";
export const CREATE_USER = "create_user";
export const CREATE_ONBOARDING_USERS = "create_onboarding_users";
export const BULK_UPDATE_USERS = "bulk_update_users";
export const UPDATE_USER = "update_user";
export const REMOVE_USER = "remove_user";
export const DELETE_USER = "delete_user";

const fetchUsersSuccess = users => ({
  type: FETCH_USERS,
  payload: users
});

export const fetchUsers = (token, companyId, callback) => (dispatch) => {

  const TOKEN = token;

  return (axios.get(`${ROOT_URL}company/users/${companyId}`, 
    { 
      headers: { 
        Authorization: 'Bearer ' + TOKEN
      }
    }))
    .then(result => {
      const users = result.data;

      dispatch(fetchUsersSuccess(users));

      if(callback) {
        callback(result);
      }
      
      return result;
    })
    .catch(error => {
      console.log(error)
      
      if (error.response && error.response.data) {
        callback(error.response.data)
      }
    })
}

const fetchUserSuccess = user => ({
  type: FETCH_USER,
  payload: user
});

export const fetchUser = (token, id, callback) => (dispatch) => {

  const TOKEN = token;

  return (axios.get(`${ROOT_URL}users/${id}`, 
    { 
      headers: { 
        Authorization: 'Bearer ' + TOKEN
      }
    }))
    .then(result => {
      const user = result.data;

      dispatch(fetchUserSuccess(user));

      if(callback) {
        callback(result);
      }
      
      return result;
    })
    .catch(error => {
      console.log(error)
      
      if (error.response && error.response.data) {
        callback(error.response.data)
      }
    })
}

const verifyUserSuccess = user => ({
  type: VERIFY_USER,
  payload: user
});

export const verifyUser = (verification_token, callback) => (dispatch) => {

  return (axios.get(`${ROOT_URL}verify_user/${verification_token}`))
    .then(result => {
      const user = result.data;

      dispatch(verifyUserSuccess(user));

      if(callback) {
        callback(result);
      }
      
      return result;
    })
    .catch(error => {
      console.log(error)
      
      if (error.response && error.response.data) {
        callback(error.response.data)
      }
    })
}

const createUserSuccess = user => ({
  type: CREATE_USER,
  payload: user
});

export const createUser = (token, values, callback) => (dispatch) => {

  const TOKEN = token;

  return (axios.post(`${ROOT_URL}users`,
      values,
      { 
        headers: { 
          Authorization: 'Bearer ' + TOKEN
        }
      }))
    .then(result => {
      const user = result.data;

      dispatch(createUserSuccess(user));

      if(callback) {
        callback(result);
      }

      return result;
    })
    .catch(error => {
      console.log(error)
      
      if (error.response && error.response.data) {
        callback(error.response.data)
      }
    })
}

const createOnboardingUsersSuccess = user => ({
  type: CREATE_ONBOARDING_USERS,
  payload: user
});

export const createOnboardingUsers = (token, values, companyId, callback) => (dispatch) => {

  const TOKEN = token;

  return (axios.post(`${ROOT_URL}onboarding_users`,
      values,
      { 
        headers: { 
          Authorization: 'Bearer ' + TOKEN
        }
      }))
    .then(result => {
      const users = result.data;

      dispatch(createOnboardingUsersSuccess(users));

      if(callback) {
        callback(result);
      }

      return result;
    })
    .catch(error => {
      console.log(error)
      
      if (error.response && error.response.data) {
        callback(error.response.data)
      }
    })
}

const updateUserSuccess = user => ({
  type: UPDATE_USER,
  payload: user
});

export const updateUser = (token, values, id, callback) => (dispatch) => {

  const TOKEN = token;

  return (axios.put(`${ROOT_URL}users/${id}`, 
    values,
    { 
      headers: { 
        Authorization: 'Bearer ' + TOKEN
      }
    }))
    .then(result => {
      const usersItem = result.data;

      dispatch(updateUserSuccess(usersItem));

      if(callback) {
        callback(result);
      }
      
      return result;
    })
    .catch(error => {
      console.log(error)
      
      if (error.response && error.response.data) {
        callback(error.response.data)
      }
    })
}

const bulkUpdateUsersSuccess = users => ({
  type: BULK_UPDATE_USERS,
  payload: users
});

export const bulkUpdateUsers = (token, values, callback) => (dispatch) => {

  const TOKEN = token;

  return (axios.post(`${ROOT_URL}bulk_edit_users`, 
    values,
    { 
      headers: { 
        Authorization: 'Bearer ' + TOKEN
      }
    }))
    .then(result => {
      const usersItem = result.data;

      dispatch(bulkUpdateUsersSuccess(usersItem));

      if(callback) {
        callback(result);
      }
      
      return result;
    })
    .catch(error => {
      console.log(error)
      
      if (error.response && error.response.data) {
        callback(error.response.data)
      }
    })
}

const removeUserFromTeamSuccess = user => ({
  type: REMOVE_USER,
  payload: user
});

export const removeUserFromTeam = (token, values, callback) => (dispatch) => {

  const TOKEN = token;

  return (axios.post(`${ROOT_URL}remove_from_team`,
      values,
      { 
        headers: { 
          Authorization: 'Bearer ' + TOKEN
        }
      }))
    .then(result => {
      const user = result.data;

      dispatch(removeUserFromTeamSuccess(user));

      if(callback) {
        callback(result);
      }

      return result;
    })
    .catch(error => {
      console.log(error)
      
      if (error.response && error.response.data) {
        callback(error.response.data)
      }
    })
}

const deleteUserSuccess = user => ({
  type: DELETE_USER,
  payload: user
});

export const deleteUser = (token, id, values, callback) => (dispatch) => {

  const TOKEN = token;

  let url = `${ROOT_URL}users/${id}`

  if (values && values.transferId) {
    url = `${ROOT_URL}users/${id}/${values.transferId}`;
  }

  return (axios.delete(url, 
    { 
      headers: { 
        Authorization: 'Bearer ' + TOKEN
      }
    }))
    .then(result => {
      const user = result.data;

      dispatch(deleteUserSuccess(user));

      if(callback) {
        callback(result);
      }
      
      return result;
    })
    .catch(error => {
      console.log(error)
      
      if (error.response && error.response.data) {
        callback(error.response.data)
      }
    })
}
