import axios from "axios";
import { ROOT_URL } from "./actions_constants";

export const FETCH_LIBRARY_GROUPS_MAIN = "fetch_library_groups_main";
export const FETCH_LIBRARY_GROUPS_RAW = "fetch_library_groups_raw";
export const FETCH_LIBRARY_GROUPS = "fetch_library_groups";
export const FETCH_LIBRARY_GROUP = "fetch_library_group";
export const CREATE_LIBRARY_GROUP = "create_library_group";
export const UPDATE_LIBRARY_GROUP = "update_library_group";
export const MOVE_LIBRARY_GROUP = "move_library_group";
export const DELETE_LIBRARY_GROUP = "delete_library_group";

const fetchLibraryGroupsMainSuccess = libraryGroupsMain => ({
  type: FETCH_LIBRARY_GROUPS_MAIN,
  payload: libraryGroupsMain
});

export const fetchLibraryGroupsMain = (token, companyId, callback) => (dispatch) => {

  const TOKEN = token;

  return (axios.get(`${ROOT_URL}company/library_groups/main/${companyId}`, 
    { 
      headers: { 
        Authorization: 'Bearer ' + TOKEN
      }
    }))
    .then(result => {
      const libraryGroupsMain = result.data;

      dispatch(fetchLibraryGroupsMainSuccess(libraryGroupsMain));

      if(callback) {
        callback(result);
      }
      
      return result;
    })
    .catch(error => {
      console.log(error)
      
      if (error.response && error.response.data) {
        callback(error.response.data)
      }
    })
}

const fetchLibraryGroupsRawSuccess = libraryGroupsRaw => ({
  type: FETCH_LIBRARY_GROUPS_RAW,
  payload: libraryGroupsRaw
});

export const fetchLibraryGroupsRaw = (token, companyId, callback) => (dispatch) => {

  const TOKEN = token;

  return (axios.get(`${ROOT_URL}company/library_groups/raw/${companyId}`, 
    { 
      headers: { 
        Authorization: 'Bearer ' + TOKEN
      }
    }))
    .then(result => {
      const libraryGroupsRaw = result.data;

      dispatch(fetchLibraryGroupsRawSuccess(libraryGroupsRaw));

      if(callback) {
        callback(result);
      }
      
      return result;
    })
    .catch(error => {
      console.log(error)
      
      if (error.response && error.response.data) {
        callback(error.response.data)
      }
    })
}

const fetchLibraryGroupsSuccess = libraryGroups => ({
  type: FETCH_LIBRARY_GROUPS,
  payload: libraryGroups
});

export const fetchLibraryGroups = (token, companyId, groups, callback) => (dispatch) => {

  const TOKEN = token;

  const values = {
    groups
  }

  return (axios.post(`${ROOT_URL}company/library_groups/${companyId}`, 
    values,
    { 
      headers: { 
        Authorization: 'Bearer ' + TOKEN
      }
    }))
    .then(result => {
      const libraryGroups = result.data;

      dispatch(fetchLibraryGroupsSuccess(libraryGroups));

      if(callback) {
        callback(result);
      }
      
      return result;
    })
    .catch(error => {
      console.log(error)
      
      if (error.response && error.response.data) {
        callback(error.response.data)
      }
    })
}

const fetchLibraryGroupSuccess = libraryGroup => ({
  type: FETCH_LIBRARY_GROUP,
  payload: libraryGroup
});

export const fetchLibraryGroup = (token, id, callback) => (dispatch) => {

  const TOKEN = token;

  return (axios.get(`${ROOT_URL}library_groups/${id}`, 
    { 
      headers: { 
        Authorization: 'Bearer ' + TOKEN
      }
    }))
    .then(result => {
      const libraryGroup = result.data;

      dispatch(fetchLibraryGroupSuccess(libraryGroup));

      if(callback) {
        callback(result);
      }
      
      return result;
    })
    .catch(error => {
      console.log(error)
      
      if (error.response && error.response.data) {
        callback(error.response.data)
      }
    })
}

const createLibraryGroupSuccess = libraryGroup => ({
  type: CREATE_LIBRARY_GROUP,
  payload: libraryGroup
});

export const createLibraryGroup = (token, values, callback) => (dispatch) => {

  const TOKEN = token;

  return (axios.post(`${ROOT_URL}library_groups`,
      values,
      { 
        headers: { 
          Authorization: 'Bearer ' + TOKEN
        }
      }))
    .then(result => {
      const libraryGroup = result.data;

      dispatch(createLibraryGroupSuccess(libraryGroup));

      if(callback) {
        callback(result);
      }

      return result;
    })
    .catch(error => {
      console.log(error)
      
      if (error.response && error.response.data) {
        callback(error.response.data)
      }
    })
}

const updateLibraryGroupSuccess = libraryGroup => ({
  type: UPDATE_LIBRARY_GROUP,
  payload: libraryGroup
});

export const updateLibraryGroup = (token, values, id, callback) => (dispatch) => {

  const TOKEN = token;

  return (axios.put(`${ROOT_URL}library_groups/${id}`, 
    values,
    { 
      headers: { 
        Authorization: 'Bearer ' + TOKEN
      }
    }))
    .then(result => {
      const libraryGroup = result.data;

      dispatch(updateLibraryGroupSuccess(libraryGroup));

      if(callback) {
        callback(result);
      }
      
      return result;
    })
    .catch(error => {
      console.log(error)
      
      if (error.response && error.response.data) {
        callback(error.response.data)
      }
    })
}

const moveLibraryGroupSuccess = libraryGroups => ({
  type: MOVE_LIBRARY_GROUP,
  payload: libraryGroups
});

export const moveLibraryGroup = (token, direction, id, callback) => (dispatch) => {

  const TOKEN = token;

  return (axios.put(`${ROOT_URL}move/library_groups/${direction}/${id}`,  
    {},
    { 
      headers: { 
        Authorization: 'Bearer ' + TOKEN
      }
    }))
    .then(results => {
      const libraryGroups = results.data;

      dispatch(moveLibraryGroupSuccess(libraryGroups));

      if(callback) {
        callback(results);
      }
      
      return results;
    })
    .catch(error => {
      console.log(error)
      
      if (error.response && error.response.data) {
        callback(error.response.data)
      }
    })
}

const deleteLibraryGroupSuccess = libraryGroup => ({
  type: DELETE_LIBRARY_GROUP,
  payload: libraryGroup
});

export const deleteLibraryGroup = (token, id, callback) => (dispatch) => {

  const TOKEN = token;

  return (axios.delete(`${ROOT_URL}library_groups/${id}`, 
    { 
      headers: { 
        Authorization: 'Bearer ' + TOKEN
      }
    }))
    .then(result => {
      const libraryGroup = result.data;

      dispatch(deleteLibraryGroupSuccess(libraryGroup));

      if(callback) {
        callback(result);
      }
      
      return result;
    })
    .catch(error => {
      console.log(error)
      
      if (error.response && error.response.data) {
        callback(error.response.data)
      }
    })
}
