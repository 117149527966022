import axios from "axios";
import { ROOT_URL } from "./actions_constants";

export const FETCH_ALL_META_TAGS = "fetch_all_meta_tags";
export const FETCH_ALL_PERSONA_TAGS = "fetch_all_persona_tags";
export const FETCH_ALL_PRODUCT_TAGS = "fetch_all_product_tags";

const fetchAllMetaTagsSuccess = meta_tags => ({
  type: FETCH_ALL_META_TAGS,
  payload: meta_tags
});

export const fetchAllMetaTags = (token, id, callback) => (dispatch) => {
  
  const TOKEN = token;

  return (axios.get(`${ROOT_URL}meta_tags_all/${id}`,
    { 
      headers: { 
        Authorization: 'Bearer ' + TOKEN
      }
    }))
    .then(result => {
      const meta_tags = result.data;

      dispatch(fetchAllMetaTagsSuccess(meta_tags));

      if(callback) {
        callback(result);
      }

      return result;
    })
    .catch(error => {
      console.log(error)
      
      if (error.response && error.response.data) {
        callback(error.response.data)
      }
    })

}

const fetchAllPersonaTagsSuccess = persona_tags => ({
  type: FETCH_ALL_PERSONA_TAGS,
  payload: persona_tags
});

export const fetchAllPersonaTags = (token, id, callback) => (dispatch) => {

  const TOKEN = token;

  return (axios.get(`${ROOT_URL}persona_tags_all/${id}`,
    { 
      headers: { 
        Authorization: 'Bearer ' + TOKEN
      }
    }))
    .then(result => {
      const persona_tags = result.data;

      dispatch(fetchAllPersonaTagsSuccess(persona_tags));

      if(callback) {
        callback(result);
      }

      return result;
    })
    .catch(error => {
      console.log(error)
      
      if (error.response && error.response.data) {
        callback(error.response.data)
      }
    })

}

const fetchAllProductTagsSuccess = product_tags => ({
  type: FETCH_ALL_PRODUCT_TAGS,
  payload: product_tags
});

export const fetchAllProductTags = (token, id, callback) => (dispatch) => {
  
  const TOKEN = token;

  return (axios.get(`${ROOT_URL}product_tags_all/${id}`,
    { 
      headers: { 
        Authorization: 'Bearer ' + TOKEN
      }
    }))
    .then(result => {
      const product_tags = result.data;

      dispatch(fetchAllProductTagsSuccess(product_tags));

      if(callback) {
        callback(result);
      }

      return result;
    })
    .catch(error => {
      console.log(error)
      
      if (error.response && error.response.data) {
        callback(error.response.data)
      }
    })

}
