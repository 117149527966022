import React from 'react';
import { connect } from 'react-redux';
import { injectStripe } from 'react-stripe-elements';

import { withStyles } from '@material-ui/core/styles';
import { styles } from '../../styles';

import Button from '@material-ui/core/Button';

// import AddressSection from './AddressSection';
import CardSection from './CardSection';
import { ErrorText } from '../../subcomponents/form_subcomponents/errorText';

import { createSubscription } from '../../../actions/actions_index';
 
class CheckoutForm extends React.Component {
  state = {
    complete: false,
    error: null
  }

  handleSubmit = (ev) => {
    const { token, companyId, billingCycle, update, handleBillingModal, handleSubmitBillingModal } = this.props;

    // We don't want to let default form submission happen here, which would refresh the page.
    ev.preventDefault();
 
    // Within the context of `Elements`, this call to createPaymentMethod knows from which Element to
    // create the PaymentMethod, since there's only one in this group.
    // See our createPaymentMethod documentation for more:
    // https://stripe.com/docs/stripe-js/reference#stripe-create-payment-method
    this.props.stripe
      .createToken()
      .then(data => {
        handleSubmitBillingModal(true, null)

        const chargeToken = data.token;

        const values = {
          companyId,
          update: update,
          token: chargeToken,
          billing_cycle: billingCycle
        }

        this.props.createSubscription(token, values, (data) => {
          const results = data.data;

          if (results.error) {
            handleBillingModal(true, true, false, results.error.raw.message)
          } else {
            handleSubmitBillingModal(false, null)
            handleBillingModal(false, true, false)
          }
          
        })
      })
      .catch(error => {
        handleBillingModal(true, false, false, error)
      });
      // .createPaymentMethod('card', {billing_details: {name: 'Jenny Rosen'}})
      // .then(({paymentMethod}) => {
      //   console.log('Received Stripe PaymentMethod:', paymentMethod);
      // });
 
    // You can also use handleCardPayment with the PaymentIntents API automatic confirmation flow.
    // See our handleCardPayment documentation for more:
    // https://stripe.com/docs/stripe-js/reference#stripe-handle-card-payment
    // this.props.stripe.handleCardPayment('{PAYMENT_INTENT_CLIENT_SECRET}', data);
 
    // You can also use handleCardSetup with the SetupIntents API.
    // See our handleCardSetup documentation for more:
    // https://stripe.com/docs/stripe-js/reference#stripe-handle-card-setup
    // this.props.stripe.handleCardSetup('{PAYMENT_INTENT_CLIENT_SECRET}', data);
 
    // You can also use createToken to create tokens.
    // See our tokens documentation for more:
    // https://stripe.com/docs/stripe-js/reference#stripe-create-token
    // this.props.stripe.createToken({type: 'card', name: 'Jenny Rosen'});
    // token type can optionally be inferred if there is only one Element
    // with which to create tokens
    // this.props.stripe.createToken({name: 'Jenny Rosen'});
 
    // You can also use createSource to create Sources.
    // See our Sources documentation for more:
    // https://stripe.com/docs/stripe-js/reference#stripe-create-source
    // this.props.stripe.createSource({
    //   type: 'card',
    //   owner: {
    //     name: 'Jenny Rosen',
    //   },
    // });
  };

  cardInfoComplete = () => {
    this.setState({
      complete: true
    })
  }

  cardInfoError = (message) => {
    console.log(message)

    this.setState({
      error: message
    })
  }

  clearAll = () => {
    this.setState({
      error: null,
      complete: false
    })
  }
 
  render() {
    const { update, billingError, classes } = this.props;
    const { complete, error } = this.state;

    return (
      <form onSubmit={this.handleSubmit} className={classes.billingForm}>
        {/*<AddressSection />*/}
        <CardSection 
          update={update} 
          cardInfoComplete={this.cardInfoComplete}
          cardInfoError={this.cardInfoError}
          clearAll={this.clearAll}
        />
        {!complete &&
          <ErrorText className={classes.bigError} touched={true} error={billingError || error} />
        }
        <Button className={`${classes.button} ${classes.modalButton}`} variant="contained" color="secondary" type="submit" disabled={!complete}>Submit</Button>
      </form>
    );
  }
}

function mapStateToProps(state) {
  return { 
    token: state.login.token,
    companyId: state.login.company._id,
    enterprise: state.login.company.enterprise,
    company: state.company,
    owner: state.login.user,
    currentRole: state.login.user.user_type
  };
}

const mapDispatchToProps = dispatch => ({
  createSubscription: (token, values, callback) => dispatch(createSubscription(token, values, callback))
})

export default connect(mapStateToProps, mapDispatchToProps)(injectStripe(withStyles(styles)(CheckoutForm)));
