import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from "redux-form";

import { withStyles } from '@material-ui/core/styles';
import { styles } from '../styles';

// import { Grid, Typography, Button, IconButton, Modal, MenuItem, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core';
// import { Close, ExpandMore } from '@material-ui/icons';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Modal from '@material-ui/core/Modal';
import MenuItem from '@material-ui/core/MenuItem';

import Close from '@material-ui/icons/Close';

import LoadingDivContainer from '../subcomponents/loadingDiv';
import { cleanContactPutValues, siteUrl } from '../helpers/helperFunctions';
import { DeletePrivileges } from '../subcomponents/commonComponents';

import { RenderTextField } from '../subcomponents/form_subcomponents/renderTextField';
import { RenderSwitch } from '../subcomponents/form_subcomponents/renderSwitch';
import { RenderSelectField } from '../subcomponents/form_subcomponents/renderSelectField';
import { RenderOwnerEditableByMenu } from '../subcomponents/form_subcomponents/renderOwnerEditableByMenu';
import { ErrorText } from '../subcomponents/form_subcomponents/errorText';

import { fetchAppProfileShowcases, fetchAdmins, fetchShowcase, createShowcase, updateShowcase, deleteShowcase } from '../../actions/actions_index';

class EditCampaignForm extends Component {
  constructor(props) {
    super(props);

    const { formError } = this.props;

    this.state = {
      error: formError,
      copied: null
    }

    this.props.reset();
  }

  submitCampaignModal = (values) => {
    const { selectedCampaignId, deleteCampaignModal, submitCampaignModal, closedCampaignModal } = this.props;
    const { token, companyId, owner } = this.props;

    submitCampaignModal(true, null);
    
    if (selectedCampaignId && !deleteCampaignModal) {
      values = cleanContactPutValues(values);
      
      this.props.updateShowcase(token, values, selectedCampaignId, (data) => {
        if (data.data.error) {
          submitCampaignModal(false, data.data.error, values);
        } else {
          closedCampaignModal(true);
        }
      });

    } else if (selectedCampaignId && deleteCampaignModal) {
      this.props.deleteShowcase(token, selectedCampaignId, () => {
        closedCampaignModal(true);
      });

    } else {

      values.company = companyId;
      values.user = owner;

      this.props.createShowcase(token, values, (data) => {
        if (data.data.error) {
          submitCampaignModal(false, data.data.error, values);
        } else {
          submitCampaignModal(false, null, null, data.data._id, true);
        }
      });

    } 
  }

  selectShowcaseTemplates = () => {
    const { showcaseTemplates } = this.props;
    if (showcaseTemplates.length > 0) {
      return (
        showcaseTemplates.map(value => <MenuItem key={value._id} value={value._id}>{value.name}</MenuItem>)
      )
    }
  }

  copyLink = (link) => {
    navigator.clipboard.writeText(link)

    this.setState({
      copied: 'Copied!'
    })
  }

  campaignForm = () => {
    const { owner, admins, currentRole, currentUser, selectedCampaignId, deleteCampaignModal, initialValues, formValues, classes, pristine, invalid } = this.props;
    const { error, copied } = this.state;

    let autoFocus = false;

    if (!selectedCampaignId) {
      autoFocus = true;
    }

    if (deleteCampaignModal) {
      return (
        <div>
          <Button className={`${classes.button} ${classes.modalButton}`} variant="contained" type="submit" color="secondary">Delete</Button>
        </div>
      )
    } else {
      return (
        <div className={classes.divContainer}>
          <ErrorText className={classes.bigError} touched={true} error={error} />
          <Grid container spacing={10}>
            <Grid item xs={12} className={`${classes.gridBorderRight}`}>
              <Field name="campaign" component={RenderTextField} type="text" label="Campaign" autoFocus={autoFocus} />
              <Field
                name="app_profile"
                label="Select Showcase Template"
                component={RenderSelectField}
              >
                {this.selectShowcaseTemplates()}
              </Field>
              <Field
                name="gate"
                label="Gate Type"
                component={RenderSelectField}
              >
                <MenuItem key="0" value="0">No Gate</MenuItem>
                <MenuItem key="1" value="1">Gate at Entry</MenuItem>
                <MenuItem key="2" value="2">Gate after 30 sec</MenuItem>
                <MenuItem key="3" value="3">Gate after 1 view</MenuItem>
                <MenuItem key="4" value="4">Gate after 2 views</MenuItem>
              </Field>
              {(formValues.Campaign && formValues.Campaign.values && formValues.Campaign.values.gate > 0) &&
                <div>
                  <Field name="gate_text" component={RenderTextField} type="text" label="Custom Gate Text" />
                  <Field type="checkbox" name="company_field" label="Show Company Field on Gate Form" component={RenderSwitch} />
                  <Field type="checkbox" name="name_field" label="Show Name Field on Gate Form" component={RenderSwitch} />
                  <Field type="checkbox" name="phone_field" label="Show Phone Field on Gate Form" component={RenderSwitch} />
                </div>
              }
              { (currentRole === '0' || initialValues.owners.includes(owner)) &&
              <RenderOwnerEditableByMenu
                currentUser={currentUser}
                admins={admins}
                selectedId={selectedCampaignId}
                initialValues={initialValues}
              />
              }
              {selectedCampaignId &&
                <div>
                  <br />
                  <br />
                  <Typography variant="h6">
                    {`Showcase Link: ${siteUrl()}showcase/${selectedCampaignId}`} <a className={classes.previewSpaceLink} href={`${siteUrl()}showcase_instance_preview/${selectedCampaignId}`} target="_blank">Preview</a> <a className={classes.previewSpaceLink} onClick={() => this.copyLink(`${siteUrl()}showcase/${selectedCampaignId}`)}>Copy Link</a> <span className={classes.copiedLink}>{copied}</span>
                  </Typography> 
                </div> 
              }
            </Grid>
          </Grid>
          <Button className={`${classes.button} ${classes.modalButton}`} variant="contained" color="secondary" type="submit" disabled={pristine || invalid}>Save</Button>
        </div>
      )
    }
  }

  render() {
    const { handleSubmit } = this.props; 
    return (
      <form noValidate autoComplete="off" onSubmit={handleSubmit(values => this.submitCampaignModal(values))}>
        {this.campaignForm()} 
      </form>
    )
  }
}

function validate(values, props) {
  const errors = {};
  let requiredFields = [
    'name', 'app_profile'
  ];
  
  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = 'Required';
    }
  });

  if (values.owners && values.owners.length === 0) {
    errors['owners'] = 'You must select at least one owner.'
  }

  return errors;
}

function mapStateToProps(state) {
  return { 
    token: state.login.token,
    companyId: state.login.company._id,
    currentRole: state.login.user.user_type,
    currentUser: state.login.user,
    owner: state.login.user._id,
    admins: state.admins,
    showcaseTemplates: state.showcaseTemplates,
    campaign: state.showcase,
    formValues: state.form
  };
}

const mapDispatchToProps = dispatch => ({
  fetchAppProfileShowcases: (token, companyId, callback) => dispatch(fetchAppProfileShowcases(token, companyId, callback)),
  fetchAdmins: (token, companyId, callback) => dispatch(fetchAdmins(token, companyId, callback)),
  fetchShowcase: (token, id, callback) => dispatch(fetchShowcase(token, id, callback)),
  createShowcase: (token, values, callback) => dispatch(createShowcase(token, values, callback)),
  updateShowcase: (token, values, id, callback) => dispatch(updateShowcase(token, values, id, callback)),
  deleteShowcase: (token, id, callback) => dispatch(deleteShowcase(token, id, callback))
});

const EditCampaignFormContainer = reduxForm({
  form: 'Campaign',
  validate,
  enableReinitialize: true,
})(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(EditCampaignForm)));


class CampaignModal extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loadingShowcase: true,
      loadingShowcaseTemplates: true,
      loadingAdmins: true,
      formError: null,
      previousValues: null,
      displayLink: false,
      copied: null,
      updated: false
    }
  }

  componentDidMount() {
    const { token, companyId, selectedCampaignId } = this.props;

    if (selectedCampaignId) {
      this.props.fetchShowcase(token, selectedCampaignId, campaign => {
        this.setState({
          loadingShowcase: false
        })
      })

      this.props.fetchAppProfileShowcases(token, companyId, campaigns => {
        this.setState({
          loadingShowcaseTemplates: false
        })
      })

      this.props.fetchAdmins(token, companyId, admins => {
        this.setState({
          loadingAdmins: false
        })
      })
    } else {
      this.props.fetchAppProfileShowcases(token, companyId, campaigns => {
        this.setState({
          loadingShowcase: false,
          loadingShowcaseTemplates: false
        })
      })

      this.props.fetchAdmins(token, companyId, admins => {
        this.setState({
          loadingAdmins: false
        })
      })
    }
  }

  campaignFormContainer = () => {
    const { owner, campaign, selectedCampaignId, deleteCampaignModal, closedCampaignModal } = this.props;
    const { formError, previousValues } = this.state;

    let initialValues = {};

    if (selectedCampaignId) {
      initialValues = campaign;
    } else {
      initialValues = {
        gate: '0',
        gate_text: 'Please Share Your Email to Continue',
        editable_by: '1',
        owners: [owner]
      };
    }

    if (previousValues) {
      initialValues = previousValues;
    }
    
    return (
      <EditCampaignFormContainer 
        selectedCampaignId={selectedCampaignId}
        deleteCampaignModal={deleteCampaignModal}
        initialValues={initialValues}
        submitCampaignModal={this.submitCampaignModal}
        closedCampaignModal={closedCampaignModal}
        formError={formError}
      />
    )
  }

  modalSize = () => {
    const { deleteCampaignModal, classes } = this.props;
    
    if (deleteCampaignModal) {
      return (
        classes.deleteModal
      )
    } else {
      return (
        classes.mediumModal
      )
    }
  }

  submitCampaignModal = (loading, error, values, link, updated) => {
    this.setState({
      loadingShowcase: loading,
      formError: error,
      previousValues: values,
      displayLink: link,
      updated: updated
    })
  }

  copyLink = (link) => {
    navigator.clipboard.writeText(link)

    this.setState({
      copied: 'Copied!'
    })
  }

  handleModalBody = () => {
    const { owner, currentRole, campaign, selectedCampaignId, deleteCampaignModal, classes } = this.props;
    const { loadingShowcase, loadingAdmins, loadingShowcaseTemplates, displayLink, copied } = this.state;

    let name = '',
        title = '',
        subheading = '',
        subheadingClass = classes.modalSubheading,
        previewLink = '',
        link = '';

    if (displayLink) {
      previewLink = `${siteUrl()}showcase_instance_preview/${displayLink}`;
      link = `${siteUrl()}showcase/${displayLink}`;
    }

    if (selectedCampaignId && !deleteCampaignModal) {
      
      name = campaign.campaign;
      title = <span>Edit - <span className={classes.modalTitleItem}>{name}</span></span>;
      subheading = 'Edit campaign details.';
    
    } else if (selectedCampaignId && deleteCampaignModal) {
      
      name = campaign.campaign;
      title = <span>Delete - <span className={classes.modalTitleItem}>{name}</span></span>;
      subheading = <span>Are you sure you want to delete <i>{name}</i>?</span>;
      
      if (campaign.campaign_type <= '1') {
        subheadingClass = classes.modalSubheadingDeleteSmall;
      } else {
        subheadingClass = classes.modalSubheadingDelete;
      }

    } else {
      
      title = 'Create New Campaign';
      subheading = 'Add campaign details.';

    }

    if (loadingShowcase || loadingAdmins || loadingShowcaseTemplates) {
      return (
        <LoadingDivContainer 
          type={'small'}
        />
      )
    } else if (displayLink) {
      return (
        <div>
          <br />
          <br />
          <Typography variant="h3">
            Showcase Link
          </Typography>
          <Typography variant="h6">
            {`${link}`} <a className={classes.previewSpaceLink} href={previewLink} target="_blank">Preview</a>  <a className={classes.previewSpaceLink} onClick={() => this.copyLink(link)}>Copy Link</a> <span className={classes.copiedLink}>{copied}</span>
          </Typography>
        </div>
      )
    } else if (deleteCampaignModal && currentRole !== '0' && campaign.user !== owner) {
      return (
        <DeletePrivileges 
          className={classes.modalSubheadingDelete}
        />
      )
    } else {
      return (
        <div className={classes.divContainer}>
          <Typography variant="h6">
            {title}
          </Typography>
          <Typography variant="subtitle1" className={subheadingClass}>
            {subheading}
          </Typography>
          {this.campaignFormContainer()}
        </div>
      )
    }
  }

  render() {
    const { openCampaignModal, closedCampaignModal, classes } = this.props;
    const { updated } = this.state;

    return (
      <Modal
        aria-labelledby="edit-campaign"
        aria-describedby="edit-campaign"
        open={openCampaignModal}
        onClose={() => closedCampaignModal(updated)}
      >
        <div className={`${classes.modal} ${this.modalSize()}`}>
          <IconButton 
            className={classes.iconClose}
            onClick={() => closedCampaignModal(updated)}
          >
            <Close />
          </IconButton>
          {this.handleModalBody()}
        </div>
      </Modal>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CampaignModal));
