import _ from "lodash";
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FieldArray, Field, reduxForm } from "redux-form";

import { withStyles } from '@material-ui/core/styles';
import { styles } from '../styles';

// import { Grid, Typography, Button, IconButton, Modal } from '@material-ui/core';
// import { Close } from '@material-ui/icons';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Modal from '@material-ui/core/Modal';

import Close from '@material-ui/icons/Close';

import LoadingDivContainer from '../subcomponents/loadingDiv';
import { RenderTextField } from '../subcomponents/form_subcomponents/renderTextField';
import TagsField from '../subcomponents/form_subcomponents/tagsField';
import { RenderSwitch } from '../subcomponents/form_subcomponents/renderSwitch';

import { fetchAllMetaTags, updateLibraryItems } from '../../actions/actions_index';

class EditUploadedItemsForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tags: [],
      tagError: null
    }

    this.props.reset();
  }

  componentDidMount() {
    const { token, companyId } = this.props;
    this.props.fetchAllMetaTags(token, companyId);
  }

  handleTagError = (error) => {
    this.setState({
      tagError: error
    })
  }

  updateTags = (type, id, tags) => {
    let stateTags = this.state.tags;

    if (id && tags) {
      stateTags[id] = tags;
    }

    this.setState({
      tags: stateTags
    })
  }

  renderItems = ({fields, classes}) => {
    const { allMetaTags } = this.props;

    return(
      fields.map((item, index) => {
        return (
          <Grid container spacing={4} key={index} className={classes.uploadedItemsGridContainer}>
            <Grid item lg={3} md={6} xs={12} className={classes.uploadedItemsGridItem}>
              <Field
                name={`${item}.filename`}
                type="text"
                className={classes.editUploadedTextfield}
                component={RenderTextField}
                label="Filename"
                disabled
              />
            </Grid>
            <Grid item lg={3} md={6} xs={12} className={classes.uploadedItemsGridItem}>
              <Field
                name={`${item}.name`}
                type="text"
                className={classes.editUploadedTextfield}
                component={RenderTextField}
                label="Display Name"
              />
            </Grid>
            <Grid item lg={3} md={6} xs={12} className={classes.uploadedItemsGridItem}>
              <TagsField 
                label={'Meta Tags'}
                name={'meta_tags'}
                id={`tags-${index}`}
                className={classes.editUploadedTagsfield}
                allTags={allMetaTags}
                handleTagError={this.handleTagError}
                updateTags={this.updateTags}
              />
            </Grid>
            <Grid item lg={3} md={6} xs={12} className={`${classes.gridSwitch} ${classes.uploadedItemsGridItem}`}>
              <Field 
                type="checkbox"
                name={`${item}.shareable`}
                className={classes.switchField}
                label="Can be shared"
                component={RenderSwitch}
              />
            </Grid>
          </Grid>
        )
      })
    )
  }

  renderRoomDemoItems = ({fields, classes}) => {

    return(
      fields.map((item, index) => {
        return (
          <Grid container spacing={4} key={index}>
            <Grid item xs={3}>
              <Field
                name={`${item}.filename`}
                type="text"
                className={classes.editUploadedTextfield}
                component={RenderTextField}
                label="Filename"
                disabled
              />
            </Grid>
            <Grid item xs={3}>
              <Field
                name={`${item}.name`}
                type="text"
                className={classes.editUploadedTextfield}
                component={RenderTextField}
                label="Display Name"
              />
            </Grid>
            <Grid item xs={3}>
              <Field 
                type="text" 
                name={`${item}.description`} 
                multiline={3} 
                component={RenderTextField} 
                label="Description" 
              />
            </Grid>
          </Grid>
        )
      })
    )
  }

  submitLibraryItemModal = (values) => {
    const { closeEditUploadedItemsModal } = this.props;
    const { tagError } = this.state;
    const token = this.props.token;
    let items = values.items;
    const stateTags = this.state.tags;

    if (tagError) {
      return
    }

    if (Object.keys(stateTags).length > 0) {
      _.mapKeys(stateTags, (value, key) => {
        const keyNumber = key.replace("tags-", "");

        //make sure no tag duplicates
        let meta_tags = _.uniqBy(value.slice(), 'value');
        meta_tags = JSON.stringify(meta_tags);
        
        items[keyNumber].meta_tags = meta_tags;
      });
    }
    
    // console.log(items)

    this.props.updateLibraryItems(token, items, (data) => {
      if (data.error) {
        this.setState({
          error: data.error
        })
      } else {
        closeEditUploadedItemsModal(true);
      }
    });
  }

  render() {
    const { classes, handleSubmit, roomDemo } = this.props;

    return(
      <form className={classes.scrollArea} noValidate autoComplete="off" onSubmit={handleSubmit(values => this.submitLibraryItemModal(values))}>
        {/*{this.uploadedItems()}*/}
        {!roomDemo && <FieldArray name="items" classes={classes} component={this.renderItems} />}
        {roomDemo && <FieldArray name="items" classes={classes} component={this.renderRoomDemoItems} />}
        <Button className={`${classes.button} ${classes.modalButton}`} variant="contained" color="secondary" type="submit">Save</Button>
      </form>
    )
  }
}

function mapStateToProps(state) {
  return { 
    token: state.login.token,
    companyId: state.login.company._id,
    user: state.login.user,
    library: state.library,
    libraryItem: state.libraryItem,
    allMetaTags: state.allTags.meta,
    admins: state.admins
  };
}

const mapDispatchToProps = dispatch => ({
  updateLibraryItems: (token, values, callback) => dispatch(updateLibraryItems(token, values, callback)),
  fetchAllMetaTags: (token, companyId) => dispatch(fetchAllMetaTags(token, companyId))
});

const EditUploadedItemsFormContainer = reduxForm({
  form: 'UploadedItems',
  // validate,
  enableReinitialize: true,
  // initialValues: {}
})(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(EditUploadedItemsForm)));


class EditUploadedItemsModal extends Component {

  closeEditUploadedItemsModal = (updated) => {
    const { closedLibraryItemModal } = this.props;
    closedLibraryItemModal(updated);
  }

  handleModalBody = () => {
    const { uploadedItems, roomDemo } = this.props;

    let initialValues = {
      items: []
    };

    if (!uploadedItems) {
      return (
        <LoadingDivContainer />
      )
    } else {
      uploadedItems.map((value, index) => {
        initialValues.items.push({
          _id: value._id,
          filename: value.filename,
          name: value.name,
          shareable: value.shareable
        })

        return null;
      })

      return (
        <EditUploadedItemsFormContainer
          initialValues={initialValues} 
          closeEditUploadedItemsModal={this.closeEditUploadedItemsModal}
          roomDemo={roomDemo}
        />
      )
    }
  }

  render() {

    const { openEditUploadedItemsModal, classes } = this.props;

    const title = "Update Uploaded Items",
          subheading = "Make updates to recently uploaded items.";

    return (
      <Modal
        aria-labelledby="edit-uploaded-items"
        aria-describedby="edit-uploaded-items"
        open={openEditUploadedItemsModal}
        onClose={() => this.closeEditUploadedItemsModal(false)}
      >
        <div className={`${classes.modal}`}>
          <IconButton 
            className={classes.iconClose}
            onClick={() => this.closeEditUploadedItemsModal(false)}
          >
            <Close />
          </IconButton>
          <Typography variant="h6">
            {title}
          </Typography>
          <Typography variant="subtitle1" className={classes.modalSubheading}>
            {subheading}
          </Typography>
          <br />
          {this.handleModalBody()}
        </div>
      </Modal>
    )
  }

}

export default connect(mapStateToProps, null)(withStyles(styles)(EditUploadedItemsModal));
