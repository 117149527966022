import { FETCH_ROOM_DEMO, CREATE_ROOM_DEMO, UPDATE_ROOM_DEMO, ROOM_DEMO_CLOSED, ROOM_DEMO_EVENT, DELETE_ROOM_DEMO } from "../actions/actions_room_demos";

export default function(state = [], action) {
  switch (action.type) {
    
    case FETCH_ROOM_DEMO:
      if (!action.payload) {
        return state;
      }
      return action.payload;

    case CREATE_ROOM_DEMO:
      if (!action.payload) {
        return state;
      }
      return action.payload;
      // return _.mapKeys(action.payload.data, "_id");

    case UPDATE_ROOM_DEMO:
      if (!action.payload) {
        return state;
      }
      return action.payload;

    case ROOM_DEMO_CLOSED:
      if (!action.payload) {
        return state;
      }
      return action.payload;

    case ROOM_DEMO_EVENT:
      if (!action.payload) {
        return state;
      }
      return action.payload;

    case DELETE_ROOM_DEMO:
      if (!action.payload) {
        return state;
      }
      return action.payload
    
    default:
      return state;
  }
}