import React from 'react';

import Typography from '@material-ui/core/Typography';

export const DeletePrivileges = ({className}) => {
	return (
		<div>
		  <br />
		  <Typography variant="subtitle1" className={className}>
		    You do not have the permissions needed to delete this item.
		  </Typography>
		</div>
	)
}
