import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from "redux-form";

import { withStyles } from '@material-ui/core/styles';
import { styles } from '../styles';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Modal from '@material-ui/core/Modal';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';

import Close from '@material-ui/icons/Close';
import Class from '@material-ui/icons/Class';
import LibraryBooks from '@material-ui/icons/LibraryBooks';

import LoadingDivContainer from '../subcomponents/loadingDiv';
import { cleanPutValues, siteUrl } from '../helpers/helperFunctions';
import { DeletePrivileges } from '../subcomponents/commonComponents';

import { RenderTextField } from '../subcomponents/form_subcomponents/renderTextField';
import { RenderSelectField } from '../subcomponents/form_subcomponents/renderSelectField';
import { RenderSwitch } from '../subcomponents/form_subcomponents/renderSwitch';
import { RenderOwnerEditableByMenu } from '../subcomponents/form_subcomponents/renderOwnerEditableByMenu';
import { RenderRadioGroup } from '../subcomponents/form_subcomponents/renderRadioGroup';

import { fetchAppProfile, createAppProfile, updateAppProfile, deleteAppProfile, fetchAdmins, fetchBrandings } from '../../actions/actions_index';

class AppProfileForm extends Component {
  constructor(props) {
    super(props);

    this.props.reset();
  }

  brandingMenu = () => {
    const { brandings } = this.props;

    if (brandings.length > 0) {
      return (
        brandings.map(value => <MenuItem key={value._id} value={value._id}>{value.name}</MenuItem>)
      )
    }
  }

  handleSpaceExpires = () => {
    const expires = [-1, 7, 14, 30, 60, 90]

    if (expires.length > 0) {
      return (
        expires.map(value => <MenuItem key={value} value={value}>{value === -1 ? 'Never' : `${value} Days`}</MenuItem>)
      )
    }
  }

  appProfileForm = () => {
    const { company, enterprise, showcases, verb_live, owner, currentRole, currentUser, admins, selectedAppProfileId, deleteAppProfileModal, pristine, invalid, initialValues, classes } = this.props,
          { interactive_video } = company

    let autoFocus = false;
    if (!selectedAppProfileId) {
      autoFocus = true;
    }

    const lightVersionText = showcases ? 'Showcase' : 'Spaces',
          radioOptions = ['Dark', 'Light']
    
    if (deleteAppProfileModal) {
      return (
        <div>
          <Button className={`${classes.button} ${classes.modalButton}`} variant="contained" type="submit" color="secondary">Delete</Button>
        </div>
      )
    } else {
      return (
        <Grid container spacing={10}> 
          <Grid item xs={6} className={classes.gridBorderRight}>
            <Field name="name" component={RenderTextField} type="text" label="Name" autoFocus={autoFocus} />
            <Field
              name="branding"
              label="Brand"
              component={RenderSelectField}
            >
              {this.brandingMenu()}
            </Field>
            {!showcases &&
              <div>
                <Field type="text" name="email_intro" multiline={3} component={RenderTextField} label="Email Intro" />
                <Field type="text" name="email_end" multiline={3} component={RenderTextField} label="Email End" />
                <Field type="text" name="email_cc" component={RenderTextField} label="Email CC" />
              </div>
            }
            {showcases &&
              <div>
                <Field type="text" name="title" component={RenderTextField} label="Title" />
                <Field type="text" name="subtitle" component={RenderTextField} label="Subtitle" />
                <Field type="text" name="description" multiline={3} component={RenderTextField} label="Description" />
              </div>
            }
          </Grid>
          <Grid item xs={6}>
            {!showcases &&
            <div>
              <Field type="checkbox" name="app_user_content" label="Allow App Users to Add Their Own Content" component={RenderSwitch} />
              {interactive_video && <Field type="checkbox" name="personalized_videos" label="Allow App Users to Share Interactive Videos" component={RenderSwitch} />}
              {(!enterprise || verb_live) &&
                <Field type="checkbox" name="verb_live" label="Allow App Users to Use Verb LIVE" component={RenderSwitch} />
              }
              <Field type="checkbox" name="force_downloads" label="Automatically Download Content to Device" component={RenderSwitch} />
              {/*
              <Field type="checkbox" name="contact_request" label="Request Contact Info at App Open" component={RenderSwitch} />
              */}
              {/*<Field type="checkbox" name="hidden" label="Hide content" component={RenderSwitch} />
              <Field type="checkbox" name="locked" label="Lock content" component={RenderSwitch} />*/}
            </div>
            }
            <Field type="checkbox" name="light_version" label={`Use Light Version for ${lightVersionText}`} component={RenderSwitch} />
            {/*<br />
            <Typography variant="subtitle1">Space Style</Typography>
            <Field type="radio" name="light_version" options={radioOptions} classes={classes} component={RenderRadioGroup} />*/}
            <br />
            <Field
              name="link_expiration"
              label="Shared Links Expire"
              component={RenderSelectField}
            >
              {this.handleSpaceExpires()}
            </Field>
            <br /><br />
            {(currentRole === '0' || initialValues.owners.includes(owner)) &&
            <RenderOwnerEditableByMenu
              currentUser={currentUser}
              admins={admins}
              selectedId={selectedAppProfileId}
              initialValues={initialValues}
            />
            }
            <Button className={`${classes.button} ${classes.modalButton}`} variant="contained" type="submit" color="secondary" disabled={pristine || invalid}>
              Save
            </Button>
          </Grid>
        </Grid>
      )
    } 
    
  }

  submitAppProfileModal = (values) => {
    const { token, companyId } = this.props;
    const { showcases, selectedAppProfileId, deleteAppProfileModal, submitAppProfileModal, closedAppProfileModal } = this.props;

    submitAppProfileModal();

    if (showcases) {
      values.showcase = true;
    }

    delete values.teams;
    delete values.library_groups;

    if (selectedAppProfileId && !deleteAppProfileModal) {
      values = cleanPutValues(values);
      
      this.props.updateAppProfile(token, values, selectedAppProfileId, () => {
        closedAppProfileModal(true, null);
      });
    } else if (selectedAppProfileId && deleteAppProfileModal) {
      this.props.deleteAppProfile(token, selectedAppProfileId, () => {
        closedAppProfileModal(true, null);
      });
    } else {
      values.company = companyId;

      this.props.createAppProfile(token, values, result => {
        closedAppProfileModal(true, result.data);
      });
    }    
  }

  render() {
    const { handleSubmit } =  this.props;

    return (
      <form noValidate autoComplete="off" onSubmit={handleSubmit(values => this.submitAppProfileModal(values))}>
        {this.appProfileForm()}
      </form>
    )
  }

}

function validate(values, props) {
  const errors = {};
  const requiredFields = [
    'name'
  ];

  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = 'Required';
    }
  })

  if (values.owners && values.owners.length === 0) {
    errors['owners'] = 'You must select at least one owner.'
  }

  return errors;
}

function mapStateToProps(state) {
  return { 
    token: state.login.token,
    company: state.company,
    companyId: state.login.company._id,
    enterprise: state.login.company.enterprise,
    verb_live: state.login.company.verb_live,
    owner: state.login.user._id,
    currentRole: state.login.user.user_type,
    currentUser: state.login.user,
    admins: state.admins,
    brandings: state.brandings,
    appProfile: state.appProfile
  };
}

const mapDispatchToProps = dispatch => ({
  fetchAdmins: (token, companyId, callback) => dispatch(fetchAdmins(token, companyId, callback)),
  fetchBrandings: (token, companyId, callback) => dispatch(fetchBrandings(token, companyId, callback)),
  fetchAppProfile: (token, id, callback) => dispatch(fetchAppProfile(token, id, callback)),
  createAppProfile: (token, values, callback) => dispatch(createAppProfile(token, values, callback)),
  updateAppProfile: (token, values, id, callback) => dispatch(updateAppProfile(token, values, id, callback)),
  deleteAppProfile: (token, id, callback) => dispatch(deleteAppProfile(token, id, callback)),
});

const AppProfileFormContainer = reduxForm({
  form: 'AppProfile',
  validate,
  enableReinitialize: true
})(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AppProfileForm)));

class AppProfileModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingAppProfile: true,
      loadingAdmins: true,
      loadingBrandings: true
    }
  }

  componentDidMount() {
    const { token, companyId, selectedAppProfileId, deleteAppProfileModal } = this.props;

    if (selectedAppProfileId && !deleteAppProfileModal) {
      this.props.fetchAppProfile(token, selectedAppProfileId, team => {
        this.setState({
          loadingAppProfile: false
        })
      })

      this.props.fetchAdmins(token, companyId, admins => {
        this.setState({
          loadingAdmins: false
        })
      })

      this.props.fetchBrandings(token, companyId, admins => {
        this.setState({
          loadingBrandings: false
        })
      })
    } else if (deleteAppProfileModal) {
      this.props.fetchAppProfile(token, selectedAppProfileId, team => {
        this.setState({
          loadingAppProfile: false,
          loadingAdmins: false,
          loadingBrandings: false
        })
      })
    } else {
      this.props.fetchAdmins(token, companyId, admins => {
        this.setState({
          loadingAppProfile: false,
          loadingAdmins: false
        })
      })

      this.props.fetchBrandings(token, companyId, admins => {
        this.setState({
          loadingBrandings: false
        })
      })
    }
  }

  modalSize = () => {
    const { deleteAppProfileModal, classes } = this.props;
    
    if (deleteAppProfileModal) {
      return (
        classes.deleteModal
      )
    }
  }

  submitAppProfileModal = () => {
    this.setState({
      loadingAppProfile: true
    })
  }

  handleModalBody = () => {
    const { showcases, owner, currentRole, appProfile, selectedAppProfileId, deleteAppProfileModal, viewTeamsLibraryGroups, closedAppProfileModal, classes } = this.props;
    const { loadingAppProfile, loadingAdmins, loadingBrandings } = this.state;

    let title = '',
        subheading = '',
        subheadingClass = null,
        type = 'App Profile'

    if (showcases) {
      type = 'Showcase Template';
    }

    if (selectedAppProfileId && !deleteAppProfileModal) {
      title = <span>Edit - <span className={classes.modalTitleItem}>{appProfile.name}</span></span>;
      subheading = `Edit ${type.toLowerCase()} details.`;
      subheadingClass = `${classes.modalSubheading} ${classes.smallHeightSubheading}`;
    } else if (selectedAppProfileId && deleteAppProfileModal) {
      title = <span>Delete - <span className={classes.modalTitleItem}>{appProfile.name}</span></span>;
      subheading = `Are you sure you want to delete ${appProfile.name}?`;
      subheadingClass = classes.modalSubheadingDelete;
    } else {
      title = `Add New ${type}`;
      subheading = `Add an ${type.toLowerCase()} to organize your library groups and teams.`;
      
      if (showcases) {
        subheading = 'Add a showcase template to share with campaigns and directly to leads and contacts.'
      }

      subheadingClass = classes.modalSubheading;
    }

    if (loadingAppProfile || loadingAdmins || loadingBrandings) {
      if(deleteAppProfileModal) {
        return (
          <LoadingDivContainer type={'small'} />
        )
      } else {
        return (
          <LoadingDivContainer />
        )
      }
    } else if (deleteAppProfileModal && (currentRole !== '0' && !appProfile.owners.includes(owner))) {
      return (
        <DeletePrivileges 
          className={classes.modalSubheadingDelete}
        />
      )
    } else {
      let initialValues = {
        editable_by: '1',
        owners: [owner],
        force_downloads: true,
        app_user_content: true,
        personalized_videos: true
      };

      if (selectedAppProfileId) {
        initialValues = appProfile;
      }

      return (
        <div className={classes.divContainer}>
          <Typography variant="h6">
            {title}
          </Typography>
          <Typography variant="subtitle1" className={subheadingClass}>
            {subheading}
          </Typography>
          <AppProfileFormContainer
            showcases={showcases}
            deleteAppProfileModal={deleteAppProfileModal}
            selectedAppProfileId={selectedAppProfileId}
            initialValues={initialValues}
            submitAppProfileModal={this.submitAppProfileModal}
            closedAppProfileModal={closedAppProfileModal}
          />
          {(selectedAppProfileId && !deleteAppProfileModal) &&
            <Button className={`${classes.addButton} ${classes.topModalOpenButton}`} onClick={() => viewTeamsLibraryGroups('view', selectedAppProfileId, true)}>
              <LibraryBooks className={classes.addCircleIcon} />
              {showcases ? 'Manage Content' : 'Manage Teams and Content'}
            </Button>
          }
        </div>
      )
    }
  }

  render() {
    const { showcases, openAppProfileModal, selectedAppProfileId, closedAppProfileModal, classes } = this.props;
    
    return (
      <Modal
        aria-labelledby="add-edit-app-profile"
        aria-describedby="add-edit-app-profile"
        open={openAppProfileModal}
        onClose={() => closedAppProfileModal(false, null)}
      >
        <div className={`${classes.modal} ${this.modalSize()}`}>
          {showcases && selectedAppProfileId && 
            <Button 
              className={`${classes.addButton} ${classes.previewEditItem} ${classes.showcasePreviewButton}`}
              onClick={() => window.open(`${siteUrl()}showcase_preview/${selectedAppProfileId}`, "_blank")}
              focusRipple={false}
            >
              <Class className={classes.addCircleIcon} />
              Preview
            </Button>
          }
          <IconButton 
            className={classes.iconClose}
            onClick={() => closedAppProfileModal(false, null)}
          >
            <Close />
          </IconButton>
          {this.handleModalBody()}
        </div>
      </Modal>
    )
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AppProfileModal));
