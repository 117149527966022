import _ from "lodash";
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import { styles } from '../styles';

// import { Grid, Typography, Button, IconButton, Modal, Menu, MenuItem, ListItemIcon } from '@material-ui/core';
// import { Close, ArrowUpward, ArrowDownward } from '@material-ui/icons';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Modal from '@material-ui/core/Modal';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import Close from '@material-ui/icons/Close';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import ArrowDownward from '@material-ui/icons/ArrowDownward';

import LoadingDivContainer from '../subcomponents/loadingDiv';
import { modalViewButtonHeight, getOffset } from '../helpers/helperFunctions';
import GridContainer from '../subcomponents/gridContainer';
import { RenderAddRemoveButton } from '../subcomponents/form_subcomponents/renderAddRemoveButton';

import { fetchAppProfile, fetchTeams, fetchLibraryGroupsRaw, fetchLibraryGroupsMain, fetchAppProfileShowcases, updateAppProfile } from '../../actions/actions_index';

class AddToAppProfileModal extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loadingAppProfile: true,
      loadingTeams: true,
      loadingLibraryGroups: true,
      loadingShowcases: true,
      selectedLibraryGroupPosition: null,
      selectedLibraryGroupId: null
    }
  }

  loadData = () => {
    const { token, companyId, showcases } = this.props;

    if (showcases) {
      this.props.fetchLibraryGroupsRaw(token, companyId, libraryGroupsRaw => {
        this.setState({
          loadingLibraryGroups: false,
          loadingTeams: false,
          loadingShowcases: false
        })
      })
    } else {
      this.props.fetchTeams(token, companyId, teams => {
        this.setState({
          loadingTeams: false
        })
      })

      this.props.fetchLibraryGroupsMain(token, companyId, libraryGroupsMain => {
        this.setState({
          loadingLibraryGroups: false
        })
      })

      this.props.fetchAppProfileShowcases(token, companyId, showcaseTemplates => {
        this.setState({
          loadingShowcases: false
        })
      })
    }
  }

  componentDidMount() {
    const { token, selectedAppProfileId } = this.props;

    this.props.fetchAppProfile(token, selectedAppProfileId, appProfile => {
      this.setState({
        loadingAppProfile: false
      })
    })

    this.loadData();
  }

  teamsGrid = () => {
    const { appProfile, teams, classes } = this.props;
    const { loadingTeams } = this.state;

    const columns = [
      { name: 'name', title: 'Teams' },
      { name: 'users', title: '# of Members', getCellValue: row => (row.users.length)}
    ];

    const tableColumnExtensions = [
      { columnName: 'name' },
      { columnName: 'users', width: 125 }
    ]

    const sortingStateColumnExtensions = [
      { columnName: 'name', sortingEnabled: true },
      { columnName: 'users', sortingEnabled: true }
    ]

    const filteringStateColumnExtensions = [
      { columnName: 'name', filteringEnabled: true },
      { columnName: 'users', filteringEnabled: true }
    ]

    if (loadingTeams) {
      return (
        <LoadingDivContainer 
          type={'small'}
        />
      )
    } else {
      const currentTeams = teams.filter(team => appProfile.teams.some(subTeam => team._id === subTeam))

      return (
        <GridContainer 
          gridHeight={modalViewButtonHeight()}
          rows={currentTeams}
          columns={columns}
          tableColumnExtensions={tableColumnExtensions}
          sortingStateColumnExtensions={sortingStateColumnExtensions}
          filteringStateColumnExtensions={filteringStateColumnExtensions}
          showSelectAll={false}
          showSelectionColumn={false}
          showRowDetail={false}
          classes={classes}
        />
      )
    }
  }

  handleLibraryGroupMenu = event => {
    const element = document.getElementById(event.currentTarget.id);
    const top = getOffset(element).top;
    const left = getOffset(element).left;
    const position = {top: top, left: left};

    this.setState({
      selectedLibraryGroupPosition: position,
      selectedLibraryGroupId: event.currentTarget.id
    });
  }

  clearLibraryGroupMenu = () => {
    this.setState({
      selectedLibraryGroupPosition: null,
      selectedLibraryGroupId: null
    })
  }

  handleMoveAppProfileLibraryGroup = (direction, id) => {
    const { token, appProfile } = this.props;
    let library_groups = appProfile.library_groups;

    //reorder library_groups
    const location = library_groups.indexOf(id);
    const length = library_groups.length;
    
    if (!direction) {
      this.clearLibraryGroupMenu();
    } else if (direction === 'up' && location === 0) {
      this.clearLibraryGroupMenu();
      return
    } else if (direction === 'down' && location === length - 1) {
      this.clearLibraryGroupMenu();
      return
    }

    let move = location - 1;

    if (direction === 'down') {
      move = move + 2;
    }

    //swap library groups in array
    const temp = library_groups[location];
    library_groups[location] = library_groups[move];
    library_groups[move] = temp;

    const data = {
      library_groups: library_groups
    };

    this.props.updateAppProfile(token, data, appProfile._id, () => {
      this.clearLibraryGroupMenu();
      return
    });
  }

  libraryGroupsGrid = () => {
    const { showcases, appProfile, libraryGroupsRaw, libraryGroupsMain, classes } = this.props;
    const { loadingLibraryGroups, selectedLibraryGroupPosition, selectedLibraryGroupId } = this.state;

    const columns = [
      { name: 'name', title: 'Content Groups' },
      // { name: 'library_files', title: '# of Files', getCellValue: row => (row.library_files.length)},
      { name: 'menu', title: ' ' }
    ];

    const tableColumnExtensions = [
      { columnName: 'name' },
      // { columnName: 'library_files', width: 200 }
      { columnName: 'menu', width: 60 }
    ]

    const sortingStateColumnExtensions = [
      { columnName: 'name', sortingEnabled: false },
      // { columnName: 'library_files', sortingEnabled: true }
      { columnName: 'menu', sortingEnabled: false }
    ]

    const filteringStateColumnExtensions = [
      { columnName: 'name', filteringEnabled: false },
      // { columnName: 'library_files', filteringEnabled: true }
      { columnName: 'menu', filteringEnabled: false }
    ]

    if (showcases) {
      columns.splice(1, 0, { name: 'parent', title: 'Parent Group', getCellValue: row => {
          if (row.parent) {
            return row.parent;
          } else {
            return 'none'
          }
        } 
      })
      tableColumnExtensions.splice(1, 0, { columnName: 'parent', width: 230 })
      sortingStateColumnExtensions.splice(1, 0, { columnName: 'parent', sortingEnabled: false })
      filteringStateColumnExtensions.splice(1, 0, { columnName: 'parent', filteringEnabled: false })
    }

    if (loadingLibraryGroups) {
      return (
        <LoadingDivContainer 
          type={'small'}
        />
      )
    } else {
      let findGroups = libraryGroupsMain;

      if (showcases) {
        findGroups = libraryGroupsRaw;
      }

      let currentLibraryGroups = []

      //need to preserve order of appProfile.library_groups for display purposes
      appProfile.library_groups.map(group => {
        currentLibraryGroups.push(_.find(findGroups, {_id: group}))
      })

      return (
        <div style={{height: '100%'}}>
          <GridContainer 
            gridHeight={modalViewButtonHeight()}
            rows={currentLibraryGroups}
            columns={columns}
            tableColumnExtensions={tableColumnExtensions}
            sortingStateColumnExtensions={sortingStateColumnExtensions}
            filteringStateColumnExtensions={filteringStateColumnExtensions}
            showSelectAll={false}
            showSelectionColumn={false}
            showRowDetail={false}
            classes={classes}
            handleMenu={this.handleLibraryGroupMenu}
          />
          <Menu
            anchorPosition={selectedLibraryGroupPosition}
            anchorReference={'anchorPosition'}
            open={Boolean(selectedLibraryGroupPosition)}
            onClose={() => this.handleMoveAppProfileLibraryGroup(null, null)}
          >
            <MenuItem onClick={() => this.handleMoveAppProfileLibraryGroup('up', selectedLibraryGroupId)}>
              <ListItemIcon>
                <ArrowUpward />
              </ListItemIcon>
              Move Up
            </MenuItem>
            <MenuItem onClick={() => this.handleMoveAppProfileLibraryGroup('down', selectedLibraryGroupId)}>
              <ListItemIcon>
                <ArrowDownward />
              </ListItemIcon>
              Move Down
            </MenuItem>
          </Menu>
        </div>
      )
    }
  }

  showcasesGrid = () => {
    const { appProfile, showcaseTemplates, classes } = this.props;
    const { loadingShowcases } = this.state;

    const columns = [
      { name: 'name', title: 'Showcases' },
    ];

    const tableColumnExtensions = [
      { columnName: 'name' },
    ]

    const sortingStateColumnExtensions = [
      { columnName: 'name', sortingEnabled: true }
    ]

    const filteringStateColumnExtensions = [
      { columnName: 'name', filteringEnabled: true }
    ]

    // let currentShowcases = [];

    if (loadingShowcases) {
      return (
        <LoadingDivContainer 
          type={'small'}
        />
      )
    } else {
      const currentShowcases = showcaseTemplates.filter(template => appProfile.showcases.some(showcase => template._id === showcase))

      return (
        <GridContainer 
          gridHeight={modalViewButtonHeight()}
          rows={currentShowcases}
          columns={columns}
          tableColumnExtensions={tableColumnExtensions}
          sortingStateColumnExtensions={sortingStateColumnExtensions}
          filteringStateColumnExtensions={filteringStateColumnExtensions}
          showSelectAll={false}
          showSelectionColumn={false}
          showRowDetail={false}
          classes={classes}
        />
      )
    }
  }

  closeViewAppProfileModal = () => {
    this.props.closedViewAppProfileModal();
  }

  //to update view of teams and library groups
  addedRemoved = () => {
    this.setState({
      loadingTeams: true,
      loadingLibraryGroups: true,
      loadingShowcases: true
    })

    this.loadData();
  }

  handleModalBody = () => {
    const { showcases, appProfile, selectedAppProfileId, fromEdit, addRemove, classes } = this.props;
    const { loadingAppProfile } = this.state;

    let height = classes.containerHeight,
        gridSize = 6,
        multipleGridItems = classes.teamLibraryGroupGridItem

    let title = <span>Content and Teams - <span className={classes.modalTitleItem}>{appProfile.name}</span></span>;

    if (showcases) {
      height = classes.containerHeight;
      gridSize = 12;
      title = <span>Content - <span className={classes.modalTitleItem}>{appProfile.name}</span></span>;
      multipleGridItems = null
    }

    if (loadingAppProfile) {
      return (
        <LoadingDivContainer />
      )
    } else {
      return (
        <div className={classes.teamLibraryGroupContainer}>
          <Typography variant="h6">
            {title}
          </Typography>
          <br />
          <Grid container spacing={4} className={height}>
            <Grid item xs={gridSize} className={multipleGridItems} style={{position: 'relative'}}>
              <RenderAddRemoveButton
                show={true}
                selectedId={selectedAppProfileId}
                fromEdit={fromEdit}
                fromView={true}
                addRemove={addRemove}
                type={'add-library-groups'}
                name={'Content Groups'}
                subName={appProfile.name}
                classes={classes}
                addedRemoved={this.addedRemoved}
              />
              {this.libraryGroupsGrid()}
            </Grid>
            {!showcases &&
              <Grid item xs={gridSize} className={multipleGridItems}>
                <RenderAddRemoveButton
                  show={true}
                  selectedId={selectedAppProfileId}
                  fromEdit={fromEdit}
                  fromView={true}
                  addRemove={addRemove}
                  type={'add-showcases'}
                  name={'Showcases'}
                  subName={appProfile.name}
                  classes={classes}
                  addedRemoved={this.addedRemoved}
                />
                {this.showcasesGrid()}
              </Grid>
            }
            {!showcases &&
              <Grid item xs={gridSize} className={multipleGridItems}>
                <RenderAddRemoveButton
                  show={true}
                  selectedId={selectedAppProfileId}
                  fromEdit={fromEdit}
                  fromView={true}
                  addRemove={addRemove}
                  type={'add-teams'}
                  name={'Teams'}
                  subName={appProfile.name}
                  classes={classes}
                  addedRemoved={this.addedRemoved}
                />
                {this.teamsGrid()}
              </Grid>
            }
          </Grid>
          <Button className={`${classes.button} ${classes.modalButton}`} variant="contained" color="secondary" onClick={this.closeViewAppProfileModal}>Close</Button>
        </div>
      )
    }
  }

  render() {
    const { openViewAppProfileModal, classes } = this.props;

    return (
      <Modal
        id="add-to-modal"
        aria-labelledby="add-to-team-modal"
        aria-describedby="add-to-team-modal"
        open={openViewAppProfileModal}
        onClose={this.closeViewAppProfileModal}
      >
        <div className={`${classes.modal} ${classes.mediumModal}`}>
          <IconButton 
            className={classes.iconClose}
            onClick={this.closeViewAppProfileModal}
          >
            <Close />
          </IconButton>
          {this.handleModalBody()}
        </div>
      </Modal>
    )
  }
}

function mapStateToProps(state) {
  if (_.isEmpty(state.login.token)) {
    return {}
  }

  return { 
    token: state.login.token,
    companyId: state.login.company._id,
    teams: state.teams,
    libraryGroupsRaw: state.libraryGroupsRaw,
    libraryGroupsMain: state.libraryGroupsMain,
    showcaseTemplates: state.showcaseTemplates,
    appProfile: state.appProfile
  };
}

const mapDispatchToProps = dispatch => ({
  fetchAppProfile: (token, id, callback) => dispatch(fetchAppProfile(token, id, callback)),
  fetchTeams: (token, companyId, callback) => dispatch(fetchTeams(token, companyId, callback)),
  fetchLibraryGroupsRaw: (token, companyId, callback) => dispatch(fetchLibraryGroupsRaw(token, companyId, callback)),
  fetchLibraryGroupsMain: (token, companyId, callback) => dispatch(fetchLibraryGroupsMain(token, companyId, callback)),
  fetchAppProfileShowcases: (token, companyId, callback) => dispatch(fetchAppProfileShowcases(token, companyId, callback)),
  updateAppProfile: (token, values, id, callback) => dispatch(updateAppProfile(token, values, id, callback))
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AddToAppProfileModal));