import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';
import { styles } from '../../styles';

import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';

import AddCircle from '@material-ui/icons/AddCircle';

import TeamItems from '../team/team_items';

const ALL_USERS_ID = 'all-users';
const ALL_USERS = 'All Users';
const ADMINS_ID = 'admins';
const ADMINS = 'Admins';
const APP_USERS_ID = 'app-users';
const APP_USERS = 'App Users';

class TeamList extends Component {
  state = {
    anchorEl: null
  }

  handlePopoverOpen = (event) => {
    this.setState({
      anchorEl: event.currentTarget
    })
  }

  handlePopoverClose = () => {
    this.setState({
      anchorEl: null
    })
  }

  render() {
    const { enterprise, verb_live, alerts, owner, currentRole, teams, adminsListCount, deactivatedAdminsListCount, appUsersListCount, deactivatedUsersListCount, selectedTeamId, handleTeamMenu, handleTeamClick, openAddNewTeamModal, classes } = this.props;
    const { anchorEl } = this.state

    const bigClass = {
      dense: classes.bigLeftMenuText
    }

    const selectedBigClass = {
      dense: classes.selectedBigLeftMenuText
    }

    return (
      <List dense className={classes.libraryGroupListMargin}>
        <ListItem 
          id={ALL_USERS_ID}
          key={ALL_USERS_ID}
          name={ALL_USERS}
          button 
          onClick={handleTeamClick}
          className={`${classes.listMainHeaders} ${selectedTeamId === ALL_USERS_ID ? classes.selectedItem : ''}`}
          disableRipple={true}
          focusRipple={true}
        >
          <ListItemText 
            primary={
              <span>
                <span className={selectedTeamId === ALL_USERS_ID ? classes.selectedBigLeftMenuText : classes.bigLeftMenuText}>All Users</span>
                  <span className={`${classes.listItemCount} tooltip`}>
                    {adminsListCount + appUsersListCount}
                    <span className="tooltiptext">
                      {`Activated: ${adminsListCount + appUsersListCount - deactivatedAdminsListCount - deactivatedUsersListCount}`}<br />{`Deactivated: ${deactivatedAdminsListCount + deactivatedUsersListCount}`}
                    </span>
                  </span>
              </span>}
          />
          <ListItemSecondaryAction />
        </ListItem>
        <ListItem 
          id={ADMINS_ID}
          key={ADMINS_ID}
          name={ADMINS}
          button 
          onClick={handleTeamClick}
          className={`${classes.listMainHeaders} ${selectedTeamId === ADMINS_ID ? classes.selectedItem : ''}`}
          disableRipple={true}
          focusRipple={true}
        >
          <ListItemText 
            primary={
              <span>
                <span className={selectedTeamId === ADMINS_ID ? classes.selectedBigLeftMenuText : classes.bigLeftMenuText}>{ADMINS}</span>
                <span className={`${classes.listItemCount} tooltip`}>
                  {adminsListCount}
                  <span className="tooltiptext">
                    {`Activated: ${adminsListCount - deactivatedAdminsListCount}`}<br />{`Deactivated: ${deactivatedAdminsListCount}`}
                  </span>
                </span>
              </span>
            }
          />
          <ListItemSecondaryAction />
        </ListItem>
        <ListItem 
          id={APP_USERS_ID}
          key={APP_USERS_ID}
          name={APP_USERS}
          button 
          onClick={handleTeamClick}
          className={`${classes.listMainHeaders} ${classes.fullLibraryItem} ${selectedTeamId === APP_USERS_ID ? classes.selectedItem : ''}`}
          disableRipple={true}
          focusRipple={true}
        >
          <ListItemText
            primary={
              <span>
                <span className={selectedTeamId === APP_USERS_ID ? classes.selectedBigLeftMenuText : classes.bigLeftMenuText}>{APP_USERS}</span>
                <span className={`${classes.listItemCount} tooltip`}>
                  {appUsersListCount}
                  <span className="tooltiptext">
                    {`Activated: ${appUsersListCount - deactivatedUsersListCount}`}<br />{`Deactivated: ${deactivatedUsersListCount}`}
                  </span>
                </span>
              </span>}
          />
          <ListItemSecondaryAction />
        </ListItem>
        {(enterprise || !verb_live) &&
          <React.Fragment>
            <Button 
              className={`${classes.addButton} ${classes.addToTeam}`}
              onClick={openAddNewTeamModal}
              focusRipple={false}
            >
              <AddCircle className={classes.addCircleIcon} />
              Add New Team
            </Button>
            <Typography variant="h3" className={classes.contentGroupsHeading}>
              Teams
            </Typography>
            <TeamItems
              key={'teams'}
              enterprise={enterprise}
              alerts={alerts}
              owner={owner}
              currentRole={currentRole}
              teams={teams}
              selectedTeamId={selectedTeamId}
              handleTeamMenu={handleTeamMenu}
              handleTeamClick={handleTeamClick}
            />
          </React.Fragment>
        }
      </List>
    )
    
  }

}

export default withStyles(styles)(TeamList);