import React, { Component } from 'react';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import { styles } from '../styles';

import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Modal from '@material-ui/core/Modal';

import Close from '@material-ui/icons/Close';

import LoadingDivContainer from '../subcomponents/loadingDiv';
import { DeletePrivileges } from '../subcomponents/commonComponents';

import { BrandingFormContainer } from '../content/brandingForm';

import { fetchBranding, fetchAdmins } from '../../actions/actions_index';

class BrandingModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingBranding: true,
      loadingAdmins: true
    }
  }

  componentDidMount() {
    const { token, companyId, selectedBrandingId, deleteBrandingModal } = this.props;

    if (selectedBrandingId && !deleteBrandingModal) {
      this.props.fetchBranding(token, selectedBrandingId, team => {
        this.setState({
          loadingBranding: false
        })
      })

      this.props.fetchAdmins(token, companyId, admins => {
        this.setState({
          loadingAdmins: false
        })
      })
    } else if (deleteBrandingModal) {
      this.props.fetchBranding(token, selectedBrandingId, team => {
        this.setState({
          loadingBranding: false,
          loadingAdmins: false
        })
      })
    } else {
      this.props.fetchAdmins(token, companyId, admins => {
        this.setState({
          loadingBranding: false,
          loadingAdmins: false
        })
      })
    }
  }

  modalSize = () => {
    const { deleteBrandingModal, classes } = this.props;
    
    if (deleteBrandingModal) {
      return (
        classes.deleteModal
      )
    } else {
      return (
        classes.fullModal
      )
    }
  }

  submitBrandingModal = () => {
    this.setState({
      loadingBranding: true
    })
  }

  handleModalBody = () => {
    const { owner, currentRole, branding, selectedBrandingId, deleteBrandingModal, closedBrandingModal, classes } = this.props;
    const { loadingBranding, loadingAdmins } = this.state;

    let title = '',
        subheading = '',
        subheadingClass = null;

    if (selectedBrandingId && !deleteBrandingModal) {
      title = <span>Edit - <span className={classes.modalTitleItem}>{branding.name}</span></span>;
      subheading = "Edit brand details.";
      subheadingClass = `${classes.modalSubheading} ${classes.smallHeightSubheading}`;
    } else if (selectedBrandingId && deleteBrandingModal) {
      title = <span>Delete - <span className={classes.modalTitleItem}>{branding.name}</span></span>;
      subheading = `Are you sure you want to delete ${branding.name}?`;
      subheadingClass = classes.modalSubheadingDelete;
    } else {
      title = "Add New Brand";
      subheading = "Add a brand theme.";
      subheadingClass = classes.modalSubheading;
    }

    if (loadingBranding || loadingAdmins) {
      if(deleteBrandingModal) {
        return (
          <LoadingDivContainer type={'small'} />
        )
      } else {
        return (
          <LoadingDivContainer />
        )
      }
    } else if (deleteBrandingModal && (currentRole !== '0' && !branding.owners.includes(owner))) {
      return (
        <DeletePrivileges 
          className={classes.modalSubheadingDelete}
        />
      )
    } else {
      let initialValues = {
        editable_by: '1',
        owners: [owner]
      };

      if (selectedBrandingId) {
        initialValues = branding;
      }

      return (
        <div className={classes.divContainer}>
          <Typography variant="h6">
            {title}
          </Typography>
          <Typography variant="subtitle1" className={subheadingClass}>
            {subheading}
          </Typography>
          <BrandingFormContainer
            deleteBrandingModal={deleteBrandingModal}
            selectedBrandingId={selectedBrandingId}
            initialValues={initialValues}
            submitBrandingModal={this.submitBrandingModal}
            closedBrandingModal={closedBrandingModal}
          />
        </div>
      )
    }
  }

  render() {
    const { openBrandingModal, closedBrandingModal, classes } = this.props;
    
    return (
      <Modal
        aria-labelledby="add-edit-branding"
        aria-describedby="add-edit-branding"
        open={openBrandingModal}
        onClose={() => closedBrandingModal(false, false, null)}
      >
        <div className={`${classes.modal} ${this.modalSize()}`}>
          <IconButton 
            className={classes.iconClose}
            onClick={() => closedBrandingModal(false, false, null)}
          >
            <Close />
          </IconButton>
          {this.handleModalBody()}
        </div>
      </Modal>
    )
  }

}

function mapStateToProps(state) {
  return { 
    token: state.login.token,
    companyId: state.login.company._id,
    owner: state.login.user._id,
    currentRole: state.login.user.user_type,
    branding: state.branding
  };
}

const mapDispatchToProps = dispatch => ({
  fetchAdmins: (token, companyId, callback) => dispatch(fetchAdmins(token, companyId, callback)),
  fetchBranding: (token, id, callback) => dispatch(fetchBranding(token, id, callback))
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(BrandingModal));
