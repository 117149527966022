import _ from "lodash";
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

import { withStyles } from '@material-ui/core/styles';
import { styles } from '../styles';

import Typography from '@material-ui/core/Typography';

import LoadingDivContainer from '../subcomponents/loadingDiv';

import { convertToDateString } from '../helpers/helperFunctions';

import { fetchRoomDemosAnalytics } from '../../actions/actions_index';

import { navy, peacock, torquoise, teal } from '../../theme';

class RoomDemosAnalytics extends Component {
	constructor(props) {
    super(props);

    this.state = {
      loading: true,
      analytics: null 
    }
  }

	componentWillMount() {
		const { token, companyId } = this.props;

		this.props.fetchRoomDemosAnalytics(token, companyId, data => {
			this.setState({
				loading: false,
				analytics: data.data
			})
		})
	}

	roomDemoEvents(events, content) {
		if (events && content) {
			const orderedEvents = _.orderBy(events, ['date'], ['desc']);

			return(
				orderedEvents.map(event => {
					return (
						<div key={event._id}>
							{event.date && 
								<Typography variant="h5" style={{color: teal}}>
									{convertToDateString(event.date)}
								</Typography>
							}
							<Typography variant="h5">
								{event.type === 1 ? "Viewed" : "Downloaded"}: <span style={{color: peacock}}>{event.item ? _.find(content, {_id: event.item}).name : null}</span>
							</Typography>
							<Typography variant="h5" style={{color: torquoise}}>
								Duration: {event.duration}
							</Typography>
							<br />
						</div>
					)
				})
			)
		} else {
			return null;
		}
	}

	roomDemoOpens(opened) {
		if (opened) {
			const orderedOpened = _.orderBy(opened, ['date'], ['desc']);

			return(
				orderedOpened.map(open => {
					return (
						<div key={open._id} >
							<Typography variant="h5" style={{color: teal}}>
								{convertToDateString(open.date)}
							</Typography>
							<Typography variant="h5" style={{color: torquoise}}>
								Total Duration: {open.duration}
							</Typography>
							<br />
						</div>
					)
				})
			)
		} else {
			return null;
		}
	}

	render() {
		const { loading, analytics } = this.state;

		if (loading) {
	    return (
	      <LoadingDivContainer />
	    )
	  } else {

			return (
				analytics.map(room => {
					return (
						<div key={room._id} style={{margin: 30}}>
							<Typography variant="h3" style={{fontWeight: 300}}>
								{room.contact_details.email}
							</Typography>
							<Typography variant="h4" style={{fontWeight: 300}}>
								{room.contact_details.contact_company}
							</Typography>
							<br />
							<Typography variant="h6" style={{color: navy, textDecoration: 'underline'}}>
								Opens
							</Typography>
							{this.roomDemoOpens(room.opened)}
							<br />
							<Typography variant="h6" style={{color: navy, textDecoration: 'underline'}}>
								Events
							</Typography>
							{this.roomDemoEvents(room.events, room.content_details)}
							<br />
							<br />
						</div>
					)
				})
			)
		}
	}

}

function mapStateToProps(state) {
  if (_.isEmpty(state.login.token) || _.isEmpty(state.login.company) || _.isEmpty(state.login.user)) {
    return {
      loggedOut: true
    }
  }

  return { 
    token: state.login.token,
    companyId: state.login.company._id
  };
}

const mapDispatchToProps = dispatch => ({
  fetchRoomDemosAnalytics: (token, companyId, callback) => dispatch(fetchRoomDemosAnalytics(token, companyId, callback))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(RoomDemosAnalytics)));