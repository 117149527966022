import { SHARE_CONTACT, SHARE_TO_CONTACT } from "../actions/actions_contacts";

export default function(state = {}, action) {
  switch (action.type) {
    
    case SHARE_CONTACT:
      if (!action.payload) {
        return state;
      }
      return action.payload;

    case SHARE_TO_CONTACT:
      if (!action.payload) {
        return state;
      }
      return action.payload;
    
    default:
      return state;
  }
}