import axios from "axios";
import { ROOT_URL } from "./actions_constants";

export const FETCH_DASHBOARD = "fetch_dashboard";
export const FETCH_VIEW_RATE = "fetch_view_rate";
export const FETCH_USERS_ANALYTICS = "fetch_users_analytics";
export const FETCH_CONTACTS_ANALYTICS = "fetch_contacts_analytics";
export const FETCH_CONTENT_ANALYTICS = "fetch_content_analytics";
export const FETCH_DETAILS_ANALYTICS = "fetch_content_details_analytics";
export const FETCH_SHOWCASES_ANALYTICS = "fetch_showcases_analytics";
export const FETCH_SESSIONS_ANALYTICS = "fetch_sessions_analytics";
export const FETCH_SCREENS_ANALYTICS = "fetch_screens_analytics";

const fetchDashboardSuccess = dashboard => ({
  type: FETCH_DASHBOARD,
  payload: dashboard
});

export const fetchDashboard = (token, values, callback) => (dispatch) => {
  const TOKEN = token;

  return (axios.post(`${ROOT_URL}analytics/dashboard`,
      values,
      { 
        headers: { 
          Authorization: 'Bearer ' + TOKEN
        }
      }
    )
    .then(result => {
      const dashboard = result.data;

      dispatch(fetchDashboardSuccess(dashboard));

      if(callback) {
        callback(result);
      }

      return result;
    })
    .catch(error => {
      console.log(error)
      
      if (error.response && error.response.data) {
        callback(error.response.data)
      }
    })
  )
}

const fetchViewRateSuccess = viewRate => ({
  type: FETCH_VIEW_RATE,
  payload: viewRate
});

export const fetchViewRate = (token, values, callback) => (dispatch) => {
  const TOKEN = token;

  return (
    axios.post(`${ROOT_URL}analytics/view_rate`,
      values,
      { 
        headers: { 
          Authorization: 'Bearer ' + TOKEN
        }
      }
    )
    .then(result => {
      const viewRate = result.data;

      dispatch(fetchViewRateSuccess(viewRate));

      if(callback) {
        callback(result);
      }

      return result;
    })
    .catch(error => {
      console.log(error)
      
      if (error.response && error.response.data) {
        callback(error.response.data)
      }
    })
  )
}

const fetchUsersAnalyticsSuccess = usersAnalytics => ({
  type: FETCH_USERS_ANALYTICS,
  payload: usersAnalytics
});

export const fetchUsersAnalytics = (token, values, callback) => (dispatch) => {
  const TOKEN = token;

  return (
    axios.post(`${ROOT_URL}analytics/users`,
      values,
      { 
        headers: { 
          Authorization: 'Bearer ' + TOKEN
        }
      }
    )
    .then(result => {
      const usersAnalytics = result.data;

      dispatch(fetchUsersAnalyticsSuccess(usersAnalytics));

      if(callback) {
        callback(result);
      }

      return result;
    })
    .catch(error => {
      console.log(error)
      
      if (error.response && error.response.data) {
        callback(error.response.data)
      }
    })
  )
}

const fetchContactsAnalyticsSuccess = contactsAnalytics => ({
  type: FETCH_CONTACTS_ANALYTICS,
  payload: contactsAnalytics
});

export const fetchContactsAnalytics = (token, values, callback) => (dispatch) => {
  const TOKEN = token;

  return (
    axios({
      method: 'post',
      url: `${ROOT_URL}analytics/contacts`,
      data: values,
      headers: { 
        Authorization: 'Bearer ' + TOKEN
      }
    })
    .then(result => {
      const contactsAnalytics = result.data;

      dispatch(fetchContactsAnalyticsSuccess(contactsAnalytics));

      if(callback) {
        callback(result);
      }

      return result;
    })
    .catch(error => {
      if (error.response && error.response.data) {
        callback(error.response.data)
      }
    })
  )
}

const fetchContentAnalyticsSuccess = contentAnalytics => ({
  type: FETCH_CONTENT_ANALYTICS,
  payload: contentAnalytics
});

export const fetchContentAnalytics = (token, values, callback) => (dispatch) => {
  const TOKEN = token;

  return (
    axios.post(`${ROOT_URL}analytics/content`,
      values,
      { 
        headers: { 
          Authorization: 'Bearer ' + TOKEN
        }
      }
    )
    .then(result => {
      const contentAnalytics = result.data;

      dispatch(fetchContentAnalyticsSuccess(contentAnalytics));

      if(callback) {
        callback(result);
      }

      return result;
    })
    .catch(error => {
      console.log(error)
      
      if (error.response && error.response.data) {
        callback(error.response.data)
      }
    })
  )
}

const fetchShowcasesAnalyticsSuccess = showcasesAnalytics => ({
  type: FETCH_SHOWCASES_ANALYTICS,
  payload: showcasesAnalytics
});

export const fetchShowcasesAnalytics = (token, values, callback) => (dispatch) => {
  const TOKEN = token;

  return (
    axios.post(`${ROOT_URL}analytics/showcases`,
      values,
      { 
        headers: { 
          Authorization: 'Bearer ' + TOKEN
        }
      }
    )
    .then(result => {
      const showcasesAnalytics = result.data;

      dispatch(fetchShowcasesAnalyticsSuccess(showcasesAnalytics));

      if(callback) {
        callback(result);
      }

      return result;
    })
    .catch(error => {
      console.log(error)
      
      if (error.response && error.response.data) {
        callback(error.response.data)
      }
    })
  )
}

const fetchSessionsAnalyticsSuccess = sessionsAnalytics => ({
  type: FETCH_SESSIONS_ANALYTICS,
  payload: sessionsAnalytics
});

export const fetchSessionsAnalytics = (token, values, callback) => (dispatch) => {
  const TOKEN = token;

  return (
    axios({
      method: 'post',
      url: `${ROOT_URL}analytics/sessions`,
      data: values,
      headers: { 
        Authorization: 'Bearer ' + TOKEN
      }
    })
    .then(result => {
      const sessionsAnalytics = result.data;

      dispatch(fetchSessionsAnalyticsSuccess(sessionsAnalytics));

      if(callback) {
        callback(result);
      }

      return result;
    })
    .catch(error => {
      console.log(error)

      if (error.response && error.response.data) {
        callback(error.response.data)
      }
    })
  )
}

const fetchScreensAnalyticsSuccess = screensAnalytics => ({
  type: FETCH_SCREENS_ANALYTICS,
  payload: screensAnalytics
});

export const fetchScreensAnalytics = (token, values, callback) => (dispatch) => {
  const TOKEN = token;

  return (
    axios({
      method: 'post',
      url: `${ROOT_URL}analytics/screens`,
      data: values,
      headers: { 
        Authorization: 'Bearer ' + TOKEN
      }
    })
    .then(result => {
      const screensAnalytics = result.data;

      dispatch(fetchScreensAnalyticsSuccess(screensAnalytics));

      if(callback) {
        callback(result);
      }

      return result;
    })
    .catch(error => {
      console.log(error)

      if (error.response && error.response.data) {
        callback(error.response.data)
      }
    })
  )
}

const fetchDetailsAnalyticsSuccess = detailsAnalytics => ({
  type: FETCH_DETAILS_ANALYTICS,
  payload: detailsAnalytics
});

export const fetchDetailsAnalytics = (token, type, values, callback) => (dispatch) => {
  const TOKEN = token;

  return (
    axios.post(`${ROOT_URL}analytics/${type}`,
      values,
      { 
        headers: { 
          Authorization: 'Bearer ' + TOKEN
        }
      }
    )
    .then(result => {
      const detailsAnalytics = result.data;

      dispatch(fetchDetailsAnalyticsSuccess(detailsAnalytics));

      if(callback) {
        callback(result);
      }

      return result;
    })
    .catch(error => {
      console.log(error)
      
      if (error.response && error.response.data) {
        callback(error.response.data)
      }
    })
  )
}


