import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
import { Field, reduxForm } from "redux-form";

import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';

// import { Grid, Button, FormHelperText, Typography } from '@material-ui/core';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import Typography from '@material-ui/core/Typography';

import { RenderTextField } from './subcomponents/form_subcomponents/renderTextField';

import { passwordHasSpaces } from '../components/helpers/helperFunctions';

import { resetPassword, logout } from "../actions/actions_index";

class Reset extends Component {

  constructor(props) {
    super(props);

    this.state = {
      resetSubmitted: false,
      expiredToken: false
    }

    this.props.logout()
    
    //resets form fields
    this.props.initialize();
    //resets any error messages
    this.props.resetPassword();
  }

  onSubmit = (values) => {
    const reset_token = this.props.match.params.token;

    delete values.confirm_password;

    this.props.resetPassword(reset_token, values, result => {
      const data = result.data

      if (!data.userType) {
        this.setState({
          expiredToken: true
        })
      } else {
        this.setState({
          resetSubmitted: true,
          userType: data.userType
        })
      }
    });
  }

  formErrorInsert = () => {
    const { classes } = this.props;
    const { userType, expiredToken } = this.state;

    if (this.props.formError) {
      return (
        <FormHelperText className={`${classes.centerInfoText} ${classes.centerErrorText}`}>{this.props.formError}</FormHelperText>
      )
    } else if (expiredToken) {
      return (
        <FormHelperText className={classes.centerInfoText}>That password reset token has expired.</FormHelperText>
      )
    } else if (userType > 1) {
      return (
        <FormHelperText className={classes.centerInfoText}>Your password has been reset, return to the app to login.</FormHelperText>
      )
    } else {
      return '';
    }
  }

	render() {
	  const { classes, handleSubmit, pristine, invalid, token } = this.props;
    const { resetSubmitted, userType } = this.state;

    if (!_.isEmpty(token)) {
      return (
        <Redirect to='/users/quick_profile'/>
      )
    } else if (resetSubmitted && userType < 2) {
      return (
        <Redirect to='/login'/>
      )
    }

	  return (
      <div className={classes.root}>
        <Grid container spacing={4}>
	        <Grid item lg={4} md={3} sm={false} xs={false} />
          <Grid item lg={4} md={6} sm={12} xs={12} >
            <div className={classes.loginContainer}>
            <Typography className={classes.headingBig} type="display1" color="primary">Update Password</Typography>
            <br />
            <div>
              {this.formErrorInsert()}
            </div>
            <form className={classes.container} noValidate autoComplete="off" onSubmit={handleSubmit(this.onSubmit)}>
              
              <Field name="password" component={RenderTextField} type="password" label="Password" />
              <Field name="confirm_password" component={RenderTextField} type="password" label="Confirm Password" />
              
              <Button className={classes.buttonWide} variant="contained" type="submit" color="secondary" disabled={pristine || invalid} >Submit</Button>
            </form>
            </div>
          </Grid>
          <Grid item lg={4} md={3} sm={false} xs={false} />
	      </Grid>
	    </div>
	  );
	}
}

function validate(values) {
  const errors = {};

  // Validate the inputs from 'values'
  const requiredFields = [
    'password', 'confirm_password'
  ];

  if (passwordHasSpaces(values.password)) {
    errors['password'] = 'No Spaces Allowed in Passwords';
  }
  
  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = 'Required';
    } else if (values.password !== values.confirm_password) {
      errors['confirm_password'] = 'Confirm Password does not match Password';
    }
  });

  // If errors is empty, the form is fine to submit
  // If errors has *any* properties, redux form assumes form is invalid
  return errors;
}

function mapStateToProps(state) {
  let formError = null;

  if (state.forgot && state.forgot.error) {
    formError = state.forgot.error;
  }

  return { 
    token: state.login.token,
    formError: formError
  };
}

const mapDispatchToProps = dispatch => ({
  resetPassword: (token, values, callback) => dispatch(resetPassword(token, values, callback)),
  logout: () => dispatch(logout())
});

export default reduxForm({
  validate,
  form: 'ResetPasswordForm'
})(withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Reset))));
