import { CREATE_LIBRARY_ITEMS, UPDATE_LIBRARY_ITEMS } from "../actions/actions_library";

export default function(state = [], action) {
  switch (action.type) {

    case CREATE_LIBRARY_ITEMS:
      if (!action.payload) {
        return state;
      }
      return action.payload;
    
    case UPDATE_LIBRARY_ITEMS:
      if (!action.payload) {
        return state;
      }
      return action.payload;

    default:
      return state;
  
  }
}