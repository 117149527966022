import axios from "axios";
import { ROOT_URL } from "./actions_constants";

export const FETCH_ALERTS = "fetch_alerts";

const fetchAlertsSuccess = alerts => ({
  type: FETCH_ALERTS,
  payload: alerts
});

export const fetchAlerts = (token, companyId, callback) => (dispatch) => {

  const TOKEN = token;

  return (axios.get(`${ROOT_URL}alerts/${companyId}`, 
    { 
      headers: { 
        Authorization: 'Bearer ' + TOKEN
      }
    }))
    .then(result => {
      const alerts = result.data;

      dispatch(fetchAlertsSuccess(alerts));

      if(callback) {
        callback(result);
      }

      return alerts;
    })
    .catch(error => {
      console.log(error)
      
      if (error.response && error.response.data) {
        callback(error.response.data)
      }
    })
}
