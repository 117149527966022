import React, { PureComponent } from "react";
import { withRouter, NavLink, Link } from "react-router-dom";

import { withStyles } from '@material-ui/core/styles';
import { styles } from '../styles';

import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';

import Videocam from '@material-ui/icons/Videocam';
import OfflineBoltIcon from '@material-ui/icons/OfflineBolt';
import Share from '@material-ui/icons/Share';
import Book from '@material-ui/icons/Book';

import { SMandAbove, XS } from '../helpers/helperFunctions';

import { QUICK_GO_LIVE, QUICK_INTERACTIVE, QUICK_CREATE_LINK } from '../header'

class HeaderButtons extends PureComponent {

	render() {
		const { appUser, enterpriseAppUser, itemsToShare, appUserInteractiveVideos, appUserVerbLive, learn, learnJwt, classes } = this.props

		return (
			<React.Fragment>
				{(enterpriseAppUser && learn) &&
				  <a href={`https://learn.verb.tech/paths?token=${learnJwt}`} target='_blank'>
					  <Button 
					    className={`${classes.goLiveHeaderButton} ${classes.shareHeaderButton}`}
					    variant="contained"
					    color="secondary"
					  >
					  	<React.Fragment>
			    	    <SMandAbove>
			    	    	<Book className={`${classes.videoCamButton} ${classes.videoCamButtonNotLiveOnly}`} />
			    	    	Learn
			    	    </SMandAbove>
		    	  		<XS>
		    	  			Learn
		    	  		</XS>
			      	</React.Fragment>
					  </Button>
				  </a>
				}
				{(!enterpriseAppUser || appUserVerbLive) &&
					<Badge badgeContent={itemsToShare.length} color="secondary" classes={{badge: classes.shareBadgeGoLive}}>
					  <Button 
					    className={`${classes.goLiveHeaderButton}`}
					    variant="contained"
					    color="secondary"
					    component={NavLink}
					    to={`/share_cart/${QUICK_GO_LIVE}`}
					  >
					  	<React.Fragment>
			    	    <SMandAbove>
			    	    	<Videocam className={`${classes.videoCamButton} ${classes.videoCamButtonNotLiveOnly}`} />
			    	    	Go LIVE
			    	    </SMandAbove>
		    	  		<XS>
		    	  			LIVE
		    	  		</XS>
			      	</React.Fragment>
					  </Button>
					</Badge>
				}
				{(!enterpriseAppUser || appUserInteractiveVideos) &&
					<Badge badgeContent={itemsToShare.length} color="secondary" classes={{badge: classes.shareBadgeGoLive}}>
					  <Button 
					    className={`${classes.goLiveHeaderButton} ${classes.blueHeaderButton} ${(enterpriseAppUser && !appUserVerbLive) ? classes.shareHeaderButton : null}`}
					    variant="contained"
					    color="secondary"
					    component={NavLink}
					    to={`/share_cart/${QUICK_INTERACTIVE}`}
					  >
			        <React.Fragment>
			    	    <SMandAbove>
			    	    	<OfflineBoltIcon className={`${classes.videoCamButton} ${classes.videoCamButtonNotLiveOnly}`} />
			    	    </SMandAbove>
		    	  		Interactive Video
			      	</React.Fragment>
					  </Button>
					</Badge>
				}
				<Badge badgeContent={itemsToShare.length} color="secondary" classes={{badge: classes.shareBadgeGoLive}}>
				  <Button 
				    className={`${classes.goLiveHeaderButton} ${classes.shareSpaceHeaderButton}`}
				    variant="contained"
				    color="secondary"
				    component={NavLink}
				    to={`/share_cart/${QUICK_CREATE_LINK}`}
				  >
		      	<React.Fragment>
		    	    <SMandAbove>
		    	    	<Share className={`${classes.videoCamButton} ${classes.videoCamButtonNotLiveOnly}`} />
		    	    </SMandAbove>
	    	  		Share Link
		      	</React.Fragment>
				  </Button>
				</Badge>
			</React.Fragment>
		)
	}
}

export default withRouter(withStyles(styles)(HeaderButtons));