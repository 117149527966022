import React from 'react';
import { Elements } from 'react-stripe-elements';
 
import InjectedCheckoutForm from './CheckoutForm';
 
class MyStoreCheckout extends React.Component {
  render() {
    const { billingCycle, update, billingError, handleBillingModal, handleSubmitBillingModal } = this.props;

    return (
      <Elements>
        <InjectedCheckoutForm billingCycle={billingCycle} update={update} billingError={billingError} handleBillingModal={handleBillingModal} handleSubmitBillingModal={handleSubmitBillingModal} />
      </Elements>
    );
  }
}
 
export default MyStoreCheckout