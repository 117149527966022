import { FETCH_LIBRARY_ITEM, UPDATE_LIBRARY_ITEM, DELETE_LIBRARY_ITEM } from "../actions/actions_library";

export default function(state = {}, action) {
  switch (action.type) {
    
    case FETCH_LIBRARY_ITEM:
      if (!action.payload) {
        return state;
      }
      return action.payload;

    case UPDATE_LIBRARY_ITEM:
      if (!action.payload) {
        return state;
      }
      return action.payload;

    case DELETE_LIBRARY_ITEM:
      if (!action.payload) {
        return state;
      }
      return action.payload;

    default:
      return state;
  }
}