import _ from "lodash";
import React, { PureComponent } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

import { withStyles } from '@material-ui/core/styles';
import { styles } from '../styles';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';

import AddCircle from '@material-ui/icons/AddCircle';
import Edit from '@material-ui/icons/Edit';
import LibraryBooks from '@material-ui/icons/LibraryBooks';

import WithLoading from '../subcomponents/withLoading';
import TradeBoothModalContainer from '../modals/tradeBoothModal';

import { torquoise, teal, energy, notBlack } from '../../theme';

import { verifyLoggedIn, menuPermissions } from '../helpers/helperFunctions';

import { verifyRole, fetchTradeBooths } from '../../actions/actions_index';

class TradeBooths extends PureComponent {
  state = {
    openTradeBoothModal: false,
    deleteTradeBoothModal: false,
    menuElement: null,
    selectedTradeBooth: null,
    addTeams: false,
    fromEdit: false,
    fromView: false
  }

  addTradeBooth = () => {
    this.setState({
      openTradeBoothModal: true
    });
  } 

  //OPEN LIBRARY ITEM MENU
  handleTradeBoothMenu = event => {
    event.stopPropagation();

    const id = event.currentTarget.getAttribute('_id');
    const element = event.currentTarget;

    this.setState({
      menuElement: element,
      selectedTradeBooth: id
    });
  }

  //CLOSE LIBRARY ITEM MENU AND HANDLE IF SELECTION (PREVIEW, EDIT, or DELETE)
  handleTradeBoothMenuClose = (type, selectedTradeBooth, fromEdit=false, fromView=false, event) => {

    let openTradeBoothModal = false,
        deleteTradeBoothModal = false,
        addTeams = false;

    if (!selectedTradeBooth && event) {
      selectedTradeBooth = event.currentTarget.getAttribute('_id');
    }

    this.setState({
      menuElement: null
    })

    switch (type) {
      case 'edit':
        openTradeBoothModal = true;
        break;
      
      case 'delete':
        openTradeBoothModal = true;
        deleteTradeBoothModal = true;
        break;
      
      default:  
        break;
    }

    this.setState({
      openTradeBoothModal: openTradeBoothModal,
      deleteTradeBoothModal: deleteTradeBoothModal,
      selectedTradeBooth: selectedTradeBooth,
      addTeams: addTeams,
      fromEdit: fromEdit,
      fromView: fromView
    });

  }

  //HANDLE CLOSED CREATE/EDIT/DELETE BRANDING MODAL
  closedTradeBoothModal = (updated) => {
    const { history } = this.props;
    const token = this.props.token;
    const companyId = this.props.companyId;

    if (updated) {      
      this.props.fetchTradeBooths(token, companyId, results => {

        this.setState({ 
          openTradeBoothModal: false,
          deleteTradeBoothModal: false,
          selectedTradeBooth: null,
          fromEdit: false,
          fromView: false
        });

      });

    } else {
      this.setState({ 
        openTradeBoothModal: false,
        deleteTradeBoothModal: false,
        selectedTradeBooth: null,
        fromEdit: false,
        fromView: false
      });
    }
  }

  handleOpenTradeBoothModal = () => {
    const { openTradeBoothModal, deleteTradeBoothModal, selectedTradeBooth } = this.state;

    if (openTradeBoothModal) {
      return (
        <TradeBoothModalContainer 
          openTradeBoothModal={openTradeBoothModal}
          deleteTradeBoothModal={deleteTradeBoothModal}
          selectedTradeBooth={selectedTradeBooth}
          viewTeamsLibraryGroups={this.handleTradeBoothMenuClose}
          closedTradeBoothModal={this.closedTradeBoothModal}
        />
      )
    }
  }

  tradeBoothClick = (event) => {
    const { owner, currentRole, tradeBooths } = this.props;
    const _id = event.currentTarget.getAttribute('_id');
    const initialValues = _.find(tradeBooths, {_id});

    const openEdit = menuPermissions('tradeBooths', false, owner, currentRole, initialValues);
    openEdit && this.handleTradeBoothMenuClose('edit', _id);
  }

  handleTradeBooths = () => {
    const { owner, currentRole, tradeBooths, classes } = this.props;

    return (
      tradeBooths.map(tradeBooth => (
        <Grid key={tradeBooth._id} item xs={12} sm={6} md={4} lg={4} xl={3}>
          <div className={`${classes.appProfileTitleArea} clearfix`}>
            <Typography _id={tradeBooth._id} className={classes.appProfileName} gutterBottom variant="h4">
              {tradeBooth.name}
            </Typography>
          </div>
          <Card
            id={tradeBooth._id}
            key={tradeBooth._id}
            className={`${classes.card} ${classes.appProfileCard} appProfileCardAfter`}
          >
            <div className={classes.cardMediaContainer}>
              {tradeBooth.card_image &&
                <CardMedia
                  _id={tradeBooth._id}
                  className={classes.cardMedia}
                  component="img"
                  alt={tradeBooth.name}
                  image={tradeBooth.branding_details.branding_background_image ? tradeBooth.branding_details.branding_background_image : null}
                  title={tradeBooth.name}
                  onClick={event => this.tradeBoothClick(event)}
                />
              }
              {!tradeBooth.card_image &&
                <div _id={tradeBooth._id} className={classes.appProfileColorBackground} onClick={event => this.tradeBoothClick(event)} />
              }
            </div>
            <CardContent className={classes.cardContent}>
              {(menuPermissions('tradeBooths', false, owner, currentRole, tradeBooth)) &&
                <Grid container spacing={4}>
                  <Grid item xs={6} className={classes.appProfileButtonContainer}>
                    <Button 
                      _id={tradeBooth._id}
                      className={`${classes.addButton}`}
                      onClick={event => this.handleTradeBoothMenuClose('edit', null, false, false, event)}
                      focusRipple={false}
                    >
                      <Edit className={classes.addCircleIcon} />
                      Edit
                    </Button>
                  </Grid>
                  <Grid item xs={6} className={classes.appProfileButtonContainer}>
                    <Button 
                      _id={tradeBooth._id}
                      className={`${classes.addButton}`}
                      onClick={event => this.handleTradeBoothMenuClose('delete', null, false, false, event)}
                      focusRipple={false}
                    >
                      <LibraryBooks className={classes.addCircleIcon} />
                      Delete
                    </Button>
                  </Grid>
                </Grid>
              }
            </CardContent>
          </Card>
          <div className={`${classes.appProfileTitleArea} ${classes.appProfileCardBrandArea} clearfix`}>
            <div className={classes.appProfileColorBlocks}>
              <div className={classes.appProfileColorBlock} style={{backgroundColor: tradeBooth.branding_details.branding_primary ? tradeBooth.branding_details.branding_primary : torquoise}} />
              <div className={classes.appProfileColorBlock} style={{backgroundColor: tradeBooth.branding_details.branding_secondary ? tradeBooth.branding_details.branding_secondary : teal}} />
              <div className={classes.appProfileColorBlock} style={{backgroundColor: tradeBooth.branding_details.branding_accent ? tradeBooth.branding_details.branding_accent : energy}} />
            </div>
          </div>
        </Grid>
      ))
    )
  }
  
  render() {
    const { classes } = this.props;

    return (
      <div className={`${classes.root} ${classes.rootExtendedHeader}`}>
        {this.handleOpenTradeBoothModal()}
        <Grid container spacing={4}>
          <Grid item xs={12} className={`${classes.noPaddingBottom}`}>
            <Typography variant="h4" className={classes.topButtonRow}> 
              <Button className={classes.addButton} onClick={this.addTradeBooth}>
                <AddCircle className={classes.addCircleIcon} />
                Add New Trade Booth
              </Button>
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.appProfileGrid}>
            <Grid container spacing={4}>
              {this.handleTradeBooths()}    
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const TradeBoothsWithLoading = WithLoading(TradeBooths)

class TradeBoothsContainer extends PureComponent {
  state = {
    loading: true
  }

  componentDidMount() {
    const { history, loggedOut, token, companyId, owner } = this.props;

    verifyLoggedIn(history, loggedOut, () => {
      this.props.verifyRole(token, owner, verifyUser => {
        if (verifyUser.data.user_type <= '2' || verifyUser.data.user_type === 'MA') {
          this.props.fetchTradeBooths(token, companyId, () => {
            this.setState({
              loading: false
            });
          });
        }
      });
    });
  }

  render() {
    const loading = this.state.loading;

    return (
      <TradeBoothsWithLoading 
        isLoading={loading}
        {...this.props}
      />
    )
  }
}

function mapStateToProps(state) {
  if (_.isEmpty(state.login.token) || _.isEmpty(state.login.company) || _.isEmpty(state.login.user)) {
    return {
      loggedOut: true
    }
  }

  return { 
    token: state.login.token,
    companyId: state.login.company._id,
    currentUser: state.login.user,
    currentRole: state.login.user.user_type,
    owner: state.login.user._id,
    tradeBooths: state.tradeBooths
  };
}

const mapDispatchToProps = dispatch => ({
  verifyRole: (token, owner, callback) => dispatch(verifyRole(token, owner, callback)),
  fetchTradeBooths: (token, companyId, callback) => dispatch(fetchTradeBooths(token, companyId, callback))
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(TradeBoothsContainer)
