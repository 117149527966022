import { FETCH_ROOM, CREATE_SESSION_EVENT } from "../actions/actions_media_viewer";

export default function(state = [], action) {
  switch (action.type) {

    case FETCH_ROOM:
      if (!action.payload) {
        return state;
      }
      return action.payload;

    case CREATE_SESSION_EVENT:
      if (!action.payload) {
        return state;
      }
      return action.payload;
    
    default:
      return state;
  }
}