import _ from "lodash";
import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter, Link, Redirect } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import PropTypes from "prop-types";
import queryString from "querystring";
import ReactPlayer from "react-player";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga";

import animation from "./subcomponents/animations/how_it_works.json";

import { withStyles } from "@material-ui/core/styles";
import { styles } from "./styles";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import FormHelperText from "@material-ui/core/FormHelperText";
import Typography from "@material-ui/core/Typography";

import WithLoading from "./subcomponents/withLoading";
import LoadingDivContainer from "./subcomponents/loadingDiv";
import { RenderTextField } from "./subcomponents/form_subcomponents/renderTextField";
import { RenderCheckbox } from "./subcomponents/form_subcomponents/renderCheckbox";

import {
  passwordHasSpaces,
  setCookie,
  checkCookie,
  validateEmail,
} from "../components/helpers/helperFunctions";

import {
  fetchCompany,
  createFullCompany,
  logout,
} from "../actions/actions_index";

import { black, white } from "../theme";

class CreateAccount extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      accountCreated: false,
      awaitingResponse: false,
    };

    //resets form fields
    this.props.initialize();
  }

  partnerStackSignup = (values) => {
    const { first_name, last_name, email } = values;
    // 1. Populate the growsumo.data object
    window.growsumo.data.name = `${first_name} ${last_name}`;
    window.growsumo.data.email = email;
    window.growsumo.data.customer_key = email;

    // Register the signup with PartnerStack
    window.growsumo.createSignup();
  };

  onSubmit = (values) => {
    const { token, createFullCompany, campaignid, mbsy, sscid } = this.props;

    this.setState({
      error: null,
      awaitingResponse: true,
    });

    values.email = values.email.toLowerCase();

    values.campaignid = campaignid;
    values.mbsy = mbsy;
    values.sscid = sscid;

    let planLevel = "Teams";

    if (!values.company_name) {
      planLevel = "Solopreneurs";
    }

    createFullCompany(values, (company) => {
      if (company.error) {
        this.setState({
          awaitingResponse: false,
          error: company.error,
        });
      } else {
        const { email, companyId, companyName, planLevel } = company.data;

        ReactGA.initialize("UA-188402182-1");
        ReactGA.event({
          category: "verbteams_form_submitted",
          action: "submit",
          label: "https://app.solofire.com/create_account",
        });

        //Send to partnerstack
        this.partnerStackSignup(values);

        this.setState({
          awaitingResponse: false,
          accountCreated: true,
        });
      }
    });

    // setTimeout(() => {
    //   this.setState({
    //     awaitingResponse: false,
    //     accountCreated: true
    //   })
    // }, 3000)
  };

  formNotificationInsert = () => {
    const { classes } = this.props;

    if (this.props.formError) {
      return (
        <FormHelperText
          className={`${classes.centerInfoText} ${classes.centerErrorText}`}>
          {this.props.formError}
        </FormHelperText>
      );
    } else if (this.props.forgot && this.props.forgot.resetSuccess) {
      return (
        <FormHelperText className={classes.centerInfoText}>
          Your password has been reset.
        </FormHelperText>
      );
    } else {
      return "";
    }
  };

  render() {
    const {
      initialValues,
      pristine,
      invalid,
      handleSubmit,
      token,
      company,
      currentUser,
      classes,
    } = this.props;
    const { error, accountCreated, awaitingResponse } = this.state;

    if (!_.isEmpty(token)) {
      if (currentUser.email === "master_admin@solofire.com") {
        return <Redirect to="/master_admin" />;
      }

      if (company && !company.enterprise && company.onboarding) {
        if (company.onboarding_step) {
          return <Redirect to={`/onboarding/${company.onboarding_step}`} />;
        } else {
          return <Redirect to="/onboarding/company" />;
        }
      } else {
        return <Redirect to="/content/library" />;
      }
    }

    // if (accountCreated) {
    //   return <Redirect to='/create_account#success' />
    // }

    let messageText = (
      <span>
        Your account has been created. Once you verify your email, you can{" "}
        <a
          href="/login"
          style={{ textDecoration: "underline", fontWeight: 600 }}>
          login
        </a>
        .
      </span>
    );

    const pageTitle =
        "Sales Enablement and Engagement Simplified with verbTEAMS",
      pageDescription =
        "The world has changed dramatically over the past couple of years. You need your content delivery to be relevant and timely. A sales rep needs quick, easy access to your company’s marketing and sales collateral so they can tailor their outreach to each buyer’s specific needs. Have you adapted your sales enablement and engagement strategy to keep up?",
      pageImage = "https://app.solofire.com/images/createAccountMetaImage.png";

    return (
      <div className={`${classes.root} ${classes.createAccountRoot}`}>
        <Helmet>
          <title>{pageTitle}</title>
          <meta name="description" content={pageDescription} />
          <meta property="og:title" content={pageTitle} />
          <meta property="og:image" content={pageImage} />
          <meta property="og:image:width" content="1920" />
          <meta property="og:image:height" content="1080" />
          <script
            type="text/javascript"
            id="hs-script-loader"
            async
            defer
            src="//js.hs-scripts.com/5096721.js"></script>
        </Helmet>
        <Grid container spacing={8}>
          <Grid item xs={12}>
            <div className={classes.createAccountContainerWrapper}>
              <div className={classes.createAccountContainer}>
                <Grid container spacing={6} alignItems="center">
                  <Grid
                    item
                    lg={6}
                    sm={12}
                    xs={12}
                    style={{ paddingBottom: 0 }}>
                    {/*<Typography variant="h1" className={classes.createAccountHeading} style={{color: white, fontSize: 32, fontFamily: 'Gotham', fontWeight: 600}}>Try verb<span style={{fontWeight: 300}}>TEAMS</span> FREE for 7 Days</Typography>
                    <Typography variant="body1" className={classes.createAccountBody} style={{color: white, fontSize: 18}}>Everything your sales force needs, right in the palms of their hands—including our interactive livestreaming platform, verbLIVE!</Typography>*/}
                    <Typography
                      variant="h1"
                      className={classes.createAccountHeading}
                      style={{
                        color: white,
                        fontSize: 32,
                        fontFamily: "Gotham",
                        fontWeight: 600,
                        marginBottom: 10,
                      }}>
                      Sales Enablement and Engagement Simplified
                    </Typography>
                    <Typography
                      variant="body1"
                      className={classes.createAccountBody}
                      style={{ color: white, fontSize: 18, marginBottom: 20 }}>
                      With verbTEAMS, quickly find and share all your marketing
                      content to accelerate sales and close deals.
                    </Typography>
                    <a href="https://5096721.hs-sites.com/verbteams-sales">
                      <Button
                        className={`${classes.loginButton} ${classes.createAccountButton}`}
                        variant="contained"
                        color="secondary">
                        Request Demo
                      </Button>
                    </a>
                  </Grid>
                  {/*
                  <Grid item lg={6} md={6} sm={12} style={{paddingTop: 10}}>
                    {awaitingResponse &&
                      <LoadingDivContainer type="smallWhite" />
                    }
                    {!awaitingResponse &&
                      <React.Fragment>
                        <div>
                          {this.formNotificationInsert()}
                        </div>
                        {accountCreated &&
                          <React.Fragment>
                            <br />
                            <div className={classes.topMessageContainer}>
                              <Typography className={classes.topMessage} type="body1" color="primary">{messageText}</Typography>
                            </div>
                          </React.Fragment>
                        }
                        {error &&
                          <div>
                            <Typography className={classes.centerBigErrorText} type="body1" color="primary">{error}</Typography>
                          </div>
                        }
                        {!accountCreated &&
                          <form id="verbteams-create-account" className={classes.loginForm} noValidate autoComplete="off" onSubmit={handleSubmit(this.onSubmit)}>
                            <Grid container spacing={2} className={classes.hideOnMobile}>
                              <Grid item lg={6} md={6} sm={12} xs={12}>
                                <Field name="first_name" size="small" component={RenderTextField} reshareInputLabel={classes.createAccountInputLabel} reshareInput={classes.createAccountInputField} customStyle={{color: white, borderColor: white}} customNotchedOutline={classes.textfieldWhiteBorder} customMargin="dense" type='text' label="First Name" autoComplete="off" />
                              </Grid>
                              <Grid item lg={6} md={6} sm={12} xs={12}>
                                <Field name="last_name" size="small" component={RenderTextField} reshareInputLabel={classes.createAccountInputLabel} reshareInput={classes.createAccountInputField} customStyle={{color: white, borderColor: white}} customNotchedOutline={classes.textfieldWhiteBorder} customMargin="dense" type='text' label="Last Name" autoComplete="off" />
                              </Grid>
                            </Grid>
                            <div className={classes.showOnMobile}>
                              <Field name="first_name" size="small" component={RenderTextField} reshareInputLabel={classes.createAccountInputLabel} reshareInput={classes.createAccountInputField} customStyle={{color: white, borderColor: white}} customNotchedOutline={classes.textfieldWhiteBorder} customMargin="dense" type='text' label="First Name" autoComplete="off" />
                              <Field name="last_name" size="small" component={RenderTextField} reshareInputLabel={classes.createAccountInputLabel} reshareInput={classes.createAccountInputField} customStyle={{color: white, borderColor: white}} customNotchedOutline={classes.textfieldWhiteBorder} customMargin="dense" type='text' label="Last Name" autoComplete="off" />
                            </div>
                            <Field name="email" size="small" component={RenderTextField} reshareInputLabel={classes.createAccountInputLabel} reshareInput={classes.createAccountInputField} customStyle={{color: white, borderColor: white}} customNotchedOutline={classes.textfieldWhiteBorder} customMargin="dense" type='email' label="Email" autoComplete="off" />
                            <Field name="password" size="small" component={RenderTextField} reshareInputLabel={classes.createAccountInputLabel} reshareInput={classes.createAccountInputField} customStyle={{color: white}} customNotchedOutline={classes.textfieldWhiteBorder} customMargin="dense" type='password' label="Create Password" custom='false' autoComplete="off" />
                            <Field name="company_name" size="small" component={RenderTextField} reshareInputLabel={classes.createAccountInputLabel} reshareInput={classes.createAccountInputField} customStyle={{color: white, borderColor: white}} customNotchedOutline={classes.textfieldWhiteBorder} customMargin="dense" type='text' label="Company Name (optional)" autoComplete="off" />
                            <Field name="phone" size="small" component={RenderTextField} reshareInputLabel={classes.createAccountInputLabel} reshareInput={classes.createAccountInputField} customStyle={{color: white, borderColor: white}} customNotchedOutline={classes.textfieldWhiteBorder} customMargin="dense" type='phone' label="Phone (optional)" autoComplete="off" />
                            <Field 
                              className={classes.onboardingCheckbox}
                              classes={classes} 
                              type="checkbox"
                              name="agree"
                              label={
                                <div>
                                  By checking this box, you agree to our <a href='https://solofire.com/terms' target='_blank' className={classes.createAccountLink}>terms and conditions</a> and <a href='https://solofire.com/privacy' target='_blank' className={classes.createAccountLink}>privacy policy</a>.
                                </div>
                              } 
                              component={RenderCheckbox} 
                            />
                            <div style={{textAlign: 'center'}}>
                              <Button className={`${classes.loginButton} ${classes.createAccountButton}`} variant="contained" type="submit" color="secondary">Submit</Button>
                            </div>
                            <Link to='/'><Typography variant="body1" style={{color: white, paddingTop: 20, fontWeight: 600}}>Already have an account? Login here.</Typography></Link>
                          </form>
                        }
                      </React.Fragment>
                    }
                  </Grid>
                  */}
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <div
                      className={`${classes.createAccountInfo} ${classes.reactPlayerWrapper}`}
                      style={{
                        minHeight: 200,
                        position: "relative",
                        top: -10,
                      }}>
                      <ReactPlayer
                        className={classes.reactPlayer}
                        width="100%"
                        height="100%"
                        url="https://vimeo.com/478994165"
                        controls={true}
                        playing={false}
                      />
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
            <div
              className={`${classes.createAccountSectionWrapper} ${classes.createAccountSectionWrapperLightGray}`}>
              <Grid
                container
                spacing={8}
                className={classes.createAccountSection}
                direction="row"
                justifyContent="center"
                alignItems="center">
                <Grid item sm={6} xs={6} className={classes.nonMobileSection}>
                  {/*<img className={classes.createAccountSectionImage} src="/images/verb/presentations.png" alt={''} />*/}
                  <div
                    className={`${classes.createAccountInfo} ${classes.reactPlayerWrapper}`}
                    style={{ minHeight: 200 }}>
                    <ReactPlayer
                      className={classes.reactPlayer}
                      width="100%"
                      height="100%"
                      url="https://vimeo.com/497045381"
                      controls={true}
                      playing={false}
                    />
                  </div>
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography
                    variant="h3"
                    className={classes.createAccountVerbHeading}>
                    LIVE E-Commerce Selling
                  </Typography>
                  <Typography
                    variant="body1"
                    className={classes.createAccountBody}>
                    verbTEAMS features verbLIVE, a live e-commerce selling
                    platform that allows users to connect with anyone,
                    everywhere. This effortless tool is ideal for anyone looking
                    to efficiently collaborate, present or train from a
                    distance, or sell in real time without limitations.
                  </Typography>
                </Grid>
                <Grid item lg={12} md={12} className={classes.mobileSection}>
                  <img
                    className={classes.createAccountSectionImage}
                    src="/images/verb/presentations.png"
                    alt={""}
                  />
                </Grid>
              </Grid>
            </div>
            <div className={classes.createAccountSectionWrapper}>
              <div
                className={`${classes.createAccountSection} ${classes.createAccountSectionHow}`}>
                <Typography
                  variant="h3"
                  className={`${classes.createAccountHeading3} ${classes.createAccountHeadingCenter} ${classes.createAccountVerbHeading}`}>
                  How It Works
                </Typography>
                <Typography
                  variant="body1"
                  className={`${classes.createAccountBody} ${classes.createAccountBodyCentered}`}>
                  Upload your training, sales, and product content to verbTEAMS
                  and add your users. Then, add your logos and colors to create
                  a custom branded experience for every user and customer to
                  share and view your content. We’ll round up all the data about
                  user activity and display it in an at-a-glance analytics
                  dashboard, so you know exactly how effective each asset is and
                  how to follow up. VerbTEAMS makes it easy to deliver the right
                  content, to the right people, at the right time.
                </Typography>

                {/*<Lottie
                  options={{
                    animationData: animation
                  }}
                  speed={.7}
                />*/}

                <div className={classes.reactPlayerWrapper}>
                  <ReactPlayer
                    className={classes.reactPlayer}
                    width="100%"
                    height="100%"
                    url="https://vimeo.com/479659568"
                    controls={false}
                    muted={true}
                    playing={true}
                    loop={true}
                  />
                </div>

                <div
                  style={{
                    position: "relative",
                    width: "100%",
                    textAlign: "center",
                    margin: "40px 0",
                  }}>
                  <img
                    style={{ width: "100%", maxWidth: 1000, margin: "0 auto" }}
                    src="/images/verb/how_it_works.png"
                    alt={""}
                  />
                </div>
              </div>
            </div>
            <div
              className={`${classes.createAccountSectionWrapper} ${classes.createAccountSectionWrapperTorquoise}`}>
              <Grid
                container
                spacing={8}
                className={classes.createAccountSection}
                direction="row"
                justifyContent="center"
                alignItems="center">
                <Grid item sm={12} xs={12} className={classes.mobileSection}>
                  <img
                    className={classes.createAccountSectionImage}
                    src="/images/verb/manage-content.png"
                    alt={""}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={12}>
                  <Typography
                    variant="h3"
                    className={classes.createAccountVerbHeading}
                    style={{ color: white }}>
                    VerbTEAMS enables you to securely share your content with
                    real-time control, leverage insights, and more:
                  </Typography>
                  <ul style={{ color: white, fontSize: 15 }}>
                    <li>
                      Effortlessly organize, distribute, and track content usage
                    </li>
                    <li>
                      Bridge the gap between your marketing and sales teams
                    </li>
                    <li>
                      Easily incorporate interactive and live videos into your
                      sales engagement strategy
                    </li>
                    <li>
                      Ensure your prospects have the most up-to-date content
                      through version control
                    </li>
                    <li>
                      Create micro-personalized content journeys for audiences
                      in real-time based on content consumption history
                    </li>
                    <li>
                      Gain insights into how content is being shared and
                      consumed
                    </li>
                    <li>
                      Facilitate employee training through easily deployable
                      content
                    </li>
                    <li>Monitor the effectiveness of your company’s content</li>
                  </ul>
                </Grid>
                <Grid item lg={6} md={6} className={classes.nonMobileSection}>
                  <img
                    className={classes.createAccountSectionImage}
                    src="/images/verb/verbteams_digital_devices.png"
                    alt={""}
                  />
                </Grid>
              </Grid>
            </div>
            {/*
            <div className={classes.createAccountSectionWrapper}>
              <div className={`${classes.createAccountSection} ${classes.createAccountSectionHow}`}>
                <Typography variant="h3" className={`${classes.createAccountVerbHeading} ${classes.createAccountHeadingCenter}`}>App Pricing</Typography>
                <Typography variant="body1" className={`${classes.createAccountBody} ${classes.createAccountBodyCentered}`}>
                  verbTEAMS offers competitive sales enablement app pricing, along with enterprise options to create a fully customizable experience. Take a look below to learn more.
                </Typography>
                <Grid container spacing={8} className={classes.createAccountSection} direction="row" justifyContent="center" alignItems="center" style={{padding: '35px 0'}}>
                  <Grid item lg={6} md={6} sm={12} xs={12} style={{textAlign: 'center', padding: '32px 15px'}}>
                    <a href='#'><img className={classes.createAccountSectionImage} src="/images/verb/new_team_pricing.png" alt={''} /></a>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12} style={{textAlign: 'center', padding: '32px 15px'}}>
                    <a href='https://5096721.hs-sites.com/verbteams-sales'><img className={classes.createAccountSectionImage} src="/images/verb/new_enterprise_pricing.png" alt={''} /></a>
                  </Grid>
                </Grid>
              </div>
            </div>
            */}
            <div className={classes.createAccountSectionWrapper}>
              <Grid
                container
                spacing={8}
                className={classes.createAccountSection}
                direction="row"
                justifyContent="center"
                alignItems="center">
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <img
                    className={classes.createAccountSectionImage}
                    src="/images/verb/analytics.jpg"
                    alt={""}
                    style={{ float: "right", maxWidth: 500 }}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography
                    variant="h3"
                    className={classes.createAccountVerbHeading}>
                    Access Detailed Analytics
                  </Typography>
                  <Typography
                    variant="body1"
                    className={classes.createAccountBody}>
                    App users can see detailed metrics on viewer activity,
                    providing you with insight into what content prospects have
                    viewed and when they viewed it. Use these analytics to fuel
                    effective follow up and create powerful funnels of marketing
                    content.
                  </Typography>
                </Grid>
              </Grid>
            </div>

            <div
              className={`${classes.createAccountSectionWrapper} ${classes.createAccountSectionWrapperTorquoise}`}>
              <Grid
                container
                spacing={8}
                className={classes.createAccountSection}
                direction="row"
                justifyContent="center"
                alignItems="center">
                <Grid item sm={12} xs={12} className={classes.mobileSection}>
                  <img
                    className={classes.createAccountSectionImage}
                    src="/images/verb/manage-content.png"
                    alt={""}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={12}>
                  <Typography
                    variant="h3"
                    className={classes.createAccountVerbHeading}
                    style={{ color: white }}>
                    Securely Manage and Distribute Content
                  </Typography>
                  <Typography
                    variant="body1"
                    className={classes.createAccountBody}
                    style={{ color: white }}>
                    Empower your stakeholders, including marketing and training
                    teams, with the ability to securely manage and distribute
                    content. verbTEAMS helps you get the right assets to the
                    right people, in a way that’s completely customized for
                    every prospect.
                  </Typography>
                </Grid>
                <Grid item lg={6} md={6} className={classes.nonMobileSection}>
                  <img
                    className={classes.createAccountSectionImage}
                    src="/images/verb/manage-content.png"
                    alt={""}
                  />
                </Grid>
              </Grid>
            </div>

            <div
              className={`${classes.createAccountSectionWrapper} ${classes.createAccountSectionWrapperLightGray}`}>
              <Grid
                container
                spacing={8}
                className={classes.createAccountSection}
                direction="row"
                justifyContent="center"
                alignItems="center">
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <img
                    className={classes.createAccountSectionImage}
                    src="/images/verb/presentations.png"
                    alt={""}
                    style={{ float: "right", maxWidth: 400 }}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography
                    variant="h3"
                    className={classes.createAccountVerbHeading}>
                    Create Custom Presentations
                  </Typography>
                  <Typography
                    variant="body1"
                    className={classes.createAccountBody}>
                    Your sales reps can create and share custom presentations
                    with prospects via e-mail and text, straight from the
                    verbTEAMS app. And with our easy-to-use dashboard, reps will
                    have access to useful insights on how they are doing
                    compared to their team, an interactive contact timeline,
                    trending content, and more!
                  </Typography>
                </Grid>
              </Grid>
            </div>
            <div
              className={`${classes.createAccountSectionWrapper} ${classes.createAccountSectionWrapperTorquoise}`}
              style={{ padding: "50px 0" }}>
              <div
                className={`${classes.createAccountSection} ${classes.createAccountSectionBottom}`}>
                <Typography
                  variant="h3"
                  className={classes.createAccountVerbHeading}
                  style={{ color: white, textAlign: "center" }}>
                  We are a Sales Enablement Platform that cares about every
                  aspect of the sales funnel.
                </Typography>
                <Typography
                  variant="body1"
                  className={`${classes.createAccountBody} ${classes.createAccountBodyCentered}`}
                  style={{ color: white, margin: "0 auto" }}>
                  verbTEAMS delivers a powerful experience for everyone in your
                  sales funnel, including sales reps, marketing and training
                  teams, and, most importantly, your customers!
                </Typography>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

function validate(values) {
  const errors = {};

  // Validate the inputs from 'values'
  const requiredFields = [
    "first_name",
    "last_name",
    "email",
    "password",
    "agree",
  ];

  if (passwordHasSpaces(values.password)) {
    errors["password"] = "No Spaces Allowed in Passwords";
  }

  if (values.email && validateEmail(values.email)) {
    errors.email = "Invalid email address";
  }

  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = "Required";
    }
  });

  // If errors is empty, the form is fine to submit
  // If errors has *any* properties, redux form assumes form is invalid
  return errors;
}

const CreateAccountWithLoading = reduxForm({
  form: "CreateAccountForm",
  validate,
  enableReinitialize: true,
})(WithLoading(CreateAccount));

class CreateAccountContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      mbsy_source: null,
      campaignid: null,
      mbsy: null,
      sscid: null,
    };

    this.props.logout();
  }

  componentDidMount() {
    const { logout, location } = this.props;

    const options = {
      autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
      debug: false, // enable logs
    };

    ReactGA.initialize("UA-188402182-1");
    ReactGA.pageview(window.location.pathname + window.location.search);

    const parsed = queryString.parse(location.search.replace("?", ""));
    let { mbsy_source, campaignid, mbsy, sscid } = parsed;

    if (mbsy) {
      setCookie("mbsy_source", mbsy_source, 30);
      setCookie("campaignid", campaignid, 30);
      setCookie("mbsy", mbsy, 30);
    } else if (sscid) {
      setCookie("sscid", sscid, 30);
    } else {
      mbsy_source = checkCookie("mbsy_source");
      campaignid = checkCookie("campaignid");
      mbsy = checkCookie("mbsy");
      sscid = checkCookie("sscid");
    }

    this.setState({
      mbsy_source,
      campaignid,
      mbsy,
    });
  }

  render() {
    const { loading, mbsy_source, campaignid, mbsy, sscid } = this.state;

    return (
      <CreateAccountWithLoading
        mbsy_source={mbsy_source}
        campaignid={campaignid}
        mbsy={mbsy}
        sscid={sscid}
        isLoading={loading}
        {...this.props}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    token: state.login.token,
    company: state.login.company,
    currentUser: state.login.user,
    formError: state.login.error,
    forgot: state.forgot,
  };
}

export default compose(
  withRouter,
  connect(mapStateToProps, { fetchCompany, createFullCompany, logout }),
  withStyles(styles)
)(CreateAccountContainer);
