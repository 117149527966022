import React from 'react';

// import { FormControl, FormControlLabel, Switch } from '@material-ui/core';

import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

export const RenderSwitch = (
  {
    label,
    type,
    className,
    input: { onChange, value, checked, name}
  }
) => (
    <FormControl margin="none" className={className}>
      <FormControlLabel
        label={label}
        style={{marginLeft: 0}}
        control={
          <Switch
            type={type}
            checked={checked}
            onChange={onChange}
            value={name}
            // color="secondary"
            // classes={{
            //   colorSecondary: '#0ECD94',
            //   checked: '#0ECD94'
            // }}
          />
        }
      />
    </FormControl>
  );