import _ from "lodash";
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import { styles } from '../styles';

import Modal from '@material-ui/core/Modal';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';

import Edit from '@material-ui/icons/Edit';
import Close from '@material-ui/icons/Close';
import CloudDownload from '@material-ui/icons/CloudDownload';
import NavigateBefore from '@material-ui/icons/NavigateBefore';
import NavigateNext from '@material-ui/icons/NavigateNext';

import FilePreview from '../subcomponents/filePreview';

import { showcaseDetails, getItemDetails, handleAppUser, isVideo } from '../helpers/helperFunctions';

class PreviewLibraryItemModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      windowWidth: null
    }
  }

  closePreviewLibraryItemModal = (closedLibraryItemModal, updated) => {
    closedLibraryItemModal(updated);
  }

  updateDimensions = () => {
    const windowWidth = document.documentElement.clientWidth;
    this.setState({windowWidth})
  }

  componentDidMount() {
    document.getElementById('html').style.overflow = "hidden";

    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillUnmount() {
    document.getElementById('html').removeAttribute('style');
    window.removeEventListener("resize", this.updateDimensions);
  }

  handleDetails = (position, item) => {
    const { roomItemClick, parentState, branding, backgroundColor, color, classes } = this.props;
    const { windowWidth } = this.state;

    const { link_udid, app_profile_name } = item

    let type = null,
        link_close = null,
        mobileWidth = {minWidth: 64},
        mobilePreviousNextThumb = null

    const {
      default_thumbnail,
      thumbnail,
      item_details,
      streamVideo,
      reshareDetails,
      itemClasses
    } = getItemDetails({ item, branding, state: parentState, classes })

    let { filename, link } = item_details

    if (app_profile_name) {
      type = 'link';
      link = <a href={filename} target="_blank">;
      link_close = </a>;
    } else {
      if (link) {
        type = 'link';
      } else {
        type = 'view_opened'
      }
    }

    if (windowWidth < 960) {
      mobileWidth = {minWidth: 40, padding: 3}
      mobilePreviousNextThumb = classes.mobilePreviousNextThumb;
    }

    if (position === 1) {
      return (
        <div className={classes.centerGridElement}>
          <img type={type} src={thumbnail} onClick={event => roomItemClick(item_details, link_udid, false, reshareDetails)} className={`${classes.previousNextThumb} ${mobilePreviousNextThumb}`} />
          <Button type={type} onClick={event => roomItemClick(item_details, link_udid, false, reshareDetails)} variant="contained" color="secondary" style={{marginLeft: 10, ...mobileWidth, ...backgroundColor}}><NavigateBefore style={{marginRight: 3, fontSize: 24}} />{(windowWidth >= 960) ? 'Prev' : null}</Button>
        </div>
      )
    } else if (position === 2) {
      return (
        <div className={classes.centerGridElement}>
          <Button type={type} onClick={event => roomItemClick(item_details, link_udid, false, reshareDetails)} variant="contained" color="secondary" style={{marginRight: 10, ...mobileWidth, ...backgroundColor}}>{(windowWidth >= 960) ? 'Next' : null}<NavigateNext style={{marginLeft: 3, fontSize: 24}} /></Button>
          <img type={type} src={thumbnail} onClick={event => roomItemClick(item_details, link_udid, false, reshareDetails)} className={`${classes.previousNextThumb} ${mobilePreviousNextThumb}`} />
        </div>
      )
    }
  }

  handleNav = () => {
    const { allLibraryItems, selectedLinkUdid, selectedLibraryItem, roomItemClick, classes } = this.props;
    const selectedIndex = _.findIndex(allLibraryItems, {_id: selectedLibraryItem._id, link_udid: selectedLinkUdid });

    if (allLibraryItems.length > 1) {
      let previous = selectedIndex - 1,
          next = selectedIndex + 1;

      if (previous === -1) {
        previous = allLibraryItems.length - 1;
      }

      if (next === allLibraryItems.length) {
        next = 0;
      }

      let previousItem = allLibraryItems[previous],
          nextItem = allLibraryItems[next];

      const nav = {
        previous: this.handleDetails(1, previousItem),
        next: this.handleDetails(2, nextItem)
      }

      return nav;
    }

    return null;
  }

  handleTitleName = ({name, type}) => {
    const { classes } = this.props;
    const { windowWidth } = this.state;

    if (windowWidth < 960) {
      return (
        <div className={classes.truncateText} style={{width: `${windowWidth} - 60`}}>
          {name}
        </div>
      )     
    } else {
      return <span className={classes.modalTitleItem}>{name}</span>  
    }
}

  handleTitle = ({name, interactive_video, view, type, item, color, recordDownloadEvent}) => {
    const { handleShareButton, reshareDetails, classes } = this.props;
    const { windowWidth } = this.state;

    const updatedReshareDetails = {...reshareDetails, location: 'preview_window'}

    return (
      <div className={`${classes.centerGridElement} ${classes.centerGridElementPreviewTitle}`}>
        <Typography variant="h6" className={classes.modalTitle}>
          {this.handleTitleName({name, type})}
          {!interactive_video && windowWidth >= 960 &&
            this.handleDownloadIcon({item, type, color, recordDownloadEvent})
          }
          {(type === 'room' && item.reshareable !== false && windowWidth >= 960 && handleShareButton) &&
            handleShareButton(updatedReshareDetails)
          }
        </Typography>
      </div>
    )
  }

  handleDownloadIcon = ({item, type, color, recordDownloadEvent}) => {
    const { classes } = this.props;
    const { windowWidth } = this.state;

    let iconButtonClass = classes.roomModalDownloadButton,
        iconClass = null;

    if (windowWidth < 960) {
      iconButtonClass = null;
      iconClass = classes.roomDownloadIconMobile;
    }

    if (!item.link && item.downloadable && type === 'room') {
      return (
        <a 
          href={item.download_url} 
          download={item.name}
          type="download"
          onClick={(event) => recordDownloadEvent(event, item._id)}
          target="_blank"
        >
          <IconButton className={iconButtonClass}>
            <CloudDownload style={color} className={`${classes.roomDownloadIcon} ${iconClass}`} />
          </IconButton>
        </a>
      )
    } else {
      return null
    }
  }

  render() {
    const { selectedLibraryItem, selectedLibraryItemId, openPreviewLibraryItemModal, initiateClosePreviewLibraryItemModal, classes, handleLibraryItemMenuClose, closedLibraryItemModal, library, roomItemClick, recordDownloadEvent, selectedLinkUdid, type, backgroundColor, color, handleShareButton, reshareDetails, appUser, appUserPersonalLibrary, personalGroupSelected, fullscreen } = this.props,
          { windowWidth } = this.state,
          handleLibrary = personalGroupSelected ? appUserPersonalLibrary : library

    const filePreviewContainerFullscreen = fullscreen ? classes.filePreviewContainerFullscreen : null

    let item = {},
        mobileIconClose = null,
        mobileFilePreviewContainer = null,
        filePreviewContainerExtra = null,
        modalShowClass = null,
        nav = null,
        subheading = '',
        view = 'media'

    if (selectedLibraryItem) {
      item = selectedLibraryItem;
    } else if (selectedLibraryItemId) {
      item = _.find(handleLibrary, {_id: selectedLibraryItemId});
    }

    const { name, description, interactive_video, path, alt_path, file_type, reshareable } = item ? item : {},
          handleIsVideo = isVideo(file_type) ? classes.filePreviewContainerVideo : null

    if (roomItemClick) {
      modalShowClass = 'showModal'
    }

    if (openPreviewLibraryItemModal) {
      let handlePath = ''

      if (alt_path) {
        handlePath = alt_path;
      } else {
        handlePath = path;
      }

      if (type === 'room') {
        nav = this.handleNav();
      }

      if (windowWidth < 960) {
        mobileIconClose = classes.mobileIconClose;
        mobileFilePreviewContainer = classes.mobileFilePreviewContainer;
      }

      if (roomItemClick && initiateClosePreviewLibraryItemModal) {
        modalShowClass = 'hideModal'
      }

      if (type !== 'room') {
        view = 'admin'
      }

      if (interactive_video) {
        filePreviewContainerExtra = classes.filePreviewContainerInteractiveVideo
      }
    }

    console.log(fullscreen)

    return (
      <Modal
        aria-labelledby="preview-library-item"
        aria-describedby="preview-library-item"
        open={openPreviewLibraryItemModal}
        onClose={() => this.closePreviewLibraryItemModal(closedLibraryItemModal, false)}
        className={`${classes.modalContainer} ${modalShowClass}`}
        disableScrollLock={true}
      >
        <div style={{outline: 'none !important'}}>
        {openPreviewLibraryItemModal &&
          <div className={`${classes.modal} ${classes.fullModal} ${classes.previewModal}`}>
            {(handleLibraryItemMenuClose && !appUser) &&
              <Button 
                className={`${classes.addButton} ${classes.previewEditItem}`}
                onClick={() => handleLibraryItemMenuClose('edit', selectedLibraryItemId)}
                focusRipple={false}
              >
                <Edit className={classes.addCircleIcon} />
                Edit Details
              </Button>
            }
            <IconButton 
              className={`${classes.iconClose} ${mobileIconClose}`}
              onClick={() => this.closePreviewLibraryItemModal(closedLibraryItemModal, false)}
            >
              <Close />
            </IconButton>
            {(!fullscreen && (!interactive_video || view !== 'admin')) && windowWidth < 960 &&
              this.handleTitle({name, interactive_video, view, type, item, color, recordDownloadEvent})
            }
            {!fullscreen && 
              <div className={classes.previewModalHeader}>
                <Grid 
                  container 
                  spacing={4}
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  {nav &&
                    <Grid item xs={3}>
                      {nav.previous}
                    </Grid>
                  }
                  <Grid item xs={6} style={{textAlign: 'center'}}>
                    {(!interactive_video || view !== 'admin') && windowWidth >= 960 &&
                      this.handleTitle({name, interactive_video, view, type, item, color, recordDownloadEvent})
                    }
                    {!interactive_video && windowWidth < 960 &&
                      this.handleDownloadIcon({item, type, color, recordDownloadEvent})
                    }
                    {(type === 'room' && reshareable !== false && windowWidth < 960 && handleShareButton) &&
                      handleShareButton({...reshareDetails, location: 'preview_window_mobile'})
                    }
                    {/*{subheading !== '' && 
                      <Typography variant="subtitle1" className={`${classes.modalSubheading} ${classes.modalPreviewSubheading}`}>
                        {subheading}
                      </Typography>
                    }*/}
                  </Grid>
                  {nav &&
                    <Grid item xs={3}>
                      {nav.next}
                    </Grid>
                  }
                </Grid>
              </div>
            }
            <div className={`${classes.filePreviewContainer} ${handleIsVideo} ${mobileFilePreviewContainer} ${filePreviewContainerExtra} ${filePreviewContainerFullscreen}`}>
              <FilePreview 
                item={item}
                classes={classes}
                view={view} //designates if from admin or space
                color={color}
                backgroundColor={backgroundColor}
                roomItemClick={roomItemClick}
                selectedLinkUdid={selectedLinkUdid}
                closedLibraryItemModal={closedLibraryItemModal}
              />
            </div>
            {(!appUser && type !== 'room' && file_type !== 'iv') &&
              <Typography variant="subtitle1" className={classes.editLinkContainer}>
                <a href={path} target="_blank">Remote Download Content Link</a>
              </Typography>
            }
          </div>
        }
        </div>
      </Modal>
    )
  }
}

function mapStateToProps(state) {
  return { 
    library: state.library,
    appUser: handleAppUser(state),
    appUserPersonalLibrary: state.appUserPersonal ? state.appUserPersonal.personalLibrary : []
  };
}

export default connect(mapStateToProps, null)(withStyles(styles)(PreviewLibraryItemModal));
