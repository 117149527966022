import _ from "lodash";
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

import { withStyles } from '@material-ui/core/styles';
import { styles } from '../styles';

import Typography from '@material-ui/core/Typography';

import LoadingDivContainer from '../subcomponents/loadingDiv';

import { convertToDateString, convertToTimeString } from '../helpers/helperFunctions';

import { fetchShowcasesAnalytics } from '../../actions/actions_index';

import { navy, peacock, torquoise, teal } from '../../theme';

class ShowcasesAnalytics extends Component {
	constructor(props) {
    super(props);

    this.state = {
      loading: true,
      analytics: null 
    }
  }

	componentWillMount() {
		const { token, companyId } = this.props;

		this.props.fetchShowcasesAnalytics(token, companyId, data => {

			this.setState({
				loading: false,
				analytics: data.data
			})
		})
	}

	showcaseEvents(events) {
		if (events) {
			// const orderedEvents = _.orderBy(events, ['date'], ['asc']);

			return(
				events.map(event => {
					return (
						<div key={event._id} showcase={event.showcase}>
							{event.date && 
								<Typography variant="h5" style={{color: teal}}>
									{convertToDateString(event.date)} at {convertToTimeString(event.date)}
								</Typography>
							}
							<Typography variant="h5">
								{event.type}
							</Typography>
							{(event.content_details[0] && event.content_details[0].name) &&
								<Typography variant="h5" style={{color: peacock}}>
									Item: {event.content_details[0].name}
								</Typography>
							}
							{(event.type === 'Opened' || event.type === 'View') &&
								<Typography variant="h5" style={{color: torquoise}}>
									Duration: {event.duration/1000}s
								</Typography>
							}
							<br />
						</div>
					)
				})
			)
		} else {
			return null;
		}
	}

	render() {
		const { loading, analytics } = this.state;

		if (loading) {
	    return (
	      <LoadingDivContainer />
	    )
	  } else {

			return (
				analytics.map(contact => {
					return (
						<div key={contact._id} style={{margin: 30}}>
							{contact.contact_details[0] && 
								<div>
									<Typography variant="h3" style={{fontWeight: 300}}>
										{contact.contact_details[0].email}
									</Typography>
									<Typography variant="h4" style={{fontWeight: 300}}>
										{contact.contact_details[0].first_name ? contact.contact_details[0].first_name : null} {contact.contact_details[0].last_name ? contact.contact_details[0].last_name : null}
									</Typography>
								</div>
							}
							<br />
							<Typography variant="h6" style={{color: navy, textDecoration: 'underline'}}>
								Events
							</Typography>
							{this.showcaseEvents(contact.events)}
							<br />
							<br />
						</div>
					)
				})
			)
		}
	}

}

function mapStateToProps(state) {
  if (_.isEmpty(state.login.token) || _.isEmpty(state.login.company) || _.isEmpty(state.login.user)) {
    return {
      loggedOut: true
    }
  }

  return { 
    token: state.login.token,
    companyId: state.login.company._id
  };
}

const mapDispatchToProps = dispatch => ({
  fetchShowcasesAnalytics: (token, companyId, callback) => dispatch(fetchShowcasesAnalytics(token, companyId, callback))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ShowcasesAnalytics)));