import React, { useRef, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import WebViewer from '@pdftron/webviewer';
import QueryString from 'query-string';
import {
  isMobile,
  isEdge,
  isChromium,
  isIE,
  isChrome,
  isSafari,
} from 'react-device-detect';

const PdfViewer = () => {
  const viewer = useRef(null);
  const queryParams = QueryString.parse(window.location.search);
  const { pdfUrl, fileName, isEditable } = queryParams;
  
  /*
  const isAndroidWebView = /Android/.test(navigator.userAgent);
  const isChromium1 = !isAndroidWebView && navigator.vendor === "Google Inc.";
  const isEdge = /Edge/.test(navigator.userAgent);
  const isWebKit = !isAndroidWebView && !isChromium && !isEdge;
  if (isChromium1) {
    // Important: CefSharp binding must now be performed async
    (async () => {
        await window.CefSharp.BindObjectAsync("webViewANE", "bound").then((res) => {
            if (res.Success) {
            }
        });
    })();
  }

  const webViewANEWrapper = {
    postMessage: (message) => { // object
        if (isChromium1) {
            window.webViewANE.postMessage(message);
        } else if (isAndroidWebView) {
            window.webViewANE.postMessage(JSON.stringify(message));
        } else if (isEdge) {
            window.external.notify(JSON.stringify(message));
        } else {
            window.webkit.messageHandlers.webViewANE.postMessage(message);
        }
      }
    };
  */


  useEffect(() => {
    const handleScroll = (event) => {
      setTimeout(() => {
        if (window.scrollY > 0 && window.scrollY < 100) {
          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        }
      }, 400);
    };

    window.addEventListener('scroll', handleScroll);

    WebViewer(
      {
        path: 'pdftron',
        initialDoc: pdfUrl,
        fullAPI: true,
        useDownloader: false,
        licenseKey:
          'Verb Technology Company, Inc. (verb.tech):OEM:verbTEAMS::B+:AMS(20230608):E5A5BB1D0417060AF360B13AC9A2537860612F5EAB26780595E51DBA34347CC644A231F5C7',
        disabledElements: [
          'ribbons',
          'menuButton',
          'viewControlsButton',
          'toggleNotesButton',
          'toolsHeader',
          'selectToolButton',
        ],
      },
      viewer.current
    ).then((instance) => {
      const { documentViewer, annotationManager, Annotations } = instance.Core;
      const theme = instance.UI.Theme;

      instance.Core.documentViewer.addEventListener('documentLoaded', () => {
        Annotations.WidgetAnnotation.getCustomStyles = (widget) => {
          if (widget instanceof Annotations.TextWidgetAnnotation) {
            if (widget.getValue() !== '') {
              return {
                'background-color': 'rgba(0,0,0,0)',
              };
            }
            return {
              'background-color': 'rgba(243,222,80,0.25)',
            };
          }
        };
      });

      instance.UI.setTheme(theme.DARK);
      instance.UI.setHeaderItems((header) => {
        header.push({
          type: 'actionButton',
          img: 'icon-header-download',
          onClick: async () => {
            const doc = documentViewer.getDocument();
            const xfdfString = await annotationManager.exportAnnotations();
            const data = await doc.getFileData({ xfdfString });
            const arr = new Uint8Array(data);
            if (isMobile) {
              const blob = new Blob([arr], { type: 'application/pdf' });
              var file = new File([blob], fileName, {
                type: 'application/pdf',
              });
              var filesArray = [file];
              if (
                navigator.canShare &&
                navigator.canShare({ files: filesArray })
              ) {
                navigator.share({
                  text: 'Your pdf file',
                  files: filesArray,
                  title: 'PDF file',
                });
              } else {
                alert('Device does not appear to be compatible to share');
              }
            } else if (isEdge || isChromium || isIE || isChrome) {
              
              if(isEditable !== 'yes') {
                instance.UI.downloadPdf({
                  xfdfString,
                  filename: fileName,
                });
              } else {
                const encodedPDF = Buffer.from(arr).toString('base64');
                window.location =
                'pdf://google.com?type=savepdf&pdfData=' + encodedPDF;
              
                /* 
                // TOBE used latter if needed , this is callback which will call AS from JS
                webViewANEWrapper.postMessage({
                    functionName: "js_to_as",
                    callbackName: "jsCallback",
                    args: ['pdf://google.com?type=savepdf&pdfData=' + encodedPDF],
                  }); */ 
              }
            } else {
              const encodedPDF = Buffer.from(arr).toString('base64');
              window.location =
                'pdf://google.com?type=savepdf&pdfData=' + encodedPDF;
            }
          },
        });
      });
    });
  }, []);

  return (
    <div className='App'>
      <div className='pdftronviewer' ref={viewer}></div>
    </div>
  );
};

export default compose(withRouter)(PdfViewer);
