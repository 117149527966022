import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';

import Grid from '@material-ui/core/Grid';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Drawer from '@material-ui/core/Drawer';
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos';

import Add from '@material-ui/icons/Add';
import Settings from '@material-ui/icons/Settings';
import Launch from '@material-ui/icons/Launch';
import MenuIcon from '@material-ui/icons/Menu';
import Close from '@material-ui/icons/Close';
import ArrowLeft from '@material-ui/icons/ArrowLeft';
import ArrowRight from '@material-ui/icons/ArrowRight';

import {
  appProfileSettings,
  expiredAccount,
  handleAppUser,
  handleEnterpriseAppUser,
  LGandAbove,
  MDandAbove,
  MDandBelow,
  SMandBelow,
  webAppAllowedCompanies,
} from '../components/helpers/helperFunctions';
import TourModalContainer from './modals/tourModal';
import UserModalContainer from './modals/userModal';
import HeaderButtons from './subcomponents/headerButtons';

import {
  activeAppProfile,
  fetchAppProfile,
  fetchAppUserAppProfiles,
  fetchLearnJwt,
  handlePageLoading,
  logout,
  shareLibraryItems,
  turnOnAdminAppUser,
  updateCompany,
} from '../actions/actions_index';
import { ROOT_URL } from '../actions/actions_constants';

const MASTER_ADMIN = 'Master Admin';
const ACTIVITY = 'Analytics';
const CONTENT = 'Content';
const BRAND = 'Brand';
const USERS = 'Users';
const MESSAGES = 'Messages';
const SHARE = 'Share';
const ROOM = 'Room';
const SPACE = 'Space';
const SHOWCASES = 'Showcases';
const SHOWCASE = 'Showcase';
const INTERACTIVE = 'Interactive';
const VIEWER = 'viewer';
const CONTACTS = 'Contacts';
const LEARN = 'Learn';

//quick add links
const BILLING = 'billing';
const QUICK_PROFILE = 'quick_profile';
const QUICK_ADD_APP_USER = 'quick_add_app_user';
const QUICK_ADD_ADMIN = 'quick_add_admin';
const QUICK_ADD_CAMPAIGN = 'quick_add_campaign';
const QUICK_ADD_LEAD = 'quick_add_lead';
const QUICK_ADD_CONTACT = 'quick_add_contact';
const QUICK_SHARE = 'quick_share';
const QUICK_ADD_TEAM = 'quick_add_team';
const QUICK_ADD_LIBRARY_ITEM = 'quick_add_library_item';
const QUICK_ADD_LIBRARY_GROUP = 'quick_add_library_group';
const QUICK_ADD_BRANDING = 'quick_add_branding';
const QUICK_ADD_SHOWCASE = 'quick_add_showcase';
const QUICK_ADD_APP_PROFILE = 'quick_add_app_profile';
const QUICK_SEND_NEW_MESSAGE = 'quick_send_new_message';
const QUICK_GO_LIVE = 'quick_go_live';
const QUICK_INTERACTIVE = 'quick_interactive';
const QUICK_CREATE_LINK = 'quick_create_link';

export {
  QUICK_PROFILE,
  QUICK_ADD_APP_USER,
  QUICK_ADD_ADMIN,
  QUICK_ADD_CAMPAIGN,
  QUICK_ADD_LEAD,
  QUICK_ADD_CONTACT,
  QUICK_SHARE,
  QUICK_ADD_TEAM,
  QUICK_ADD_LIBRARY_ITEM,
  QUICK_ADD_LIBRARY_GROUP,
  QUICK_ADD_BRANDING,
  QUICK_ADD_APP_PROFILE,
  QUICK_ADD_SHOWCASE,
  QUICK_SEND_NEW_MESSAGE,
  QUICK_GO_LIVE,
  QUICK_INTERACTIVE,
  QUICK_CREATE_LINK,
};

class MenuAppBar extends Component {
  constructor(props) {
    super(props);

    const {
      token,
      enterpriseAppUser,
      appUser,
      selectedAppProfile,
      fetchAppProfile,
    } = this.props;

    this.state = {
      loading: false,
      plusMenu: null,
      userMenu: null,
      switchAppProfileMenu: null,
      anchor: false,
      scrolled: false,
      clientWidth: 0,
      openUserModal: false,
      appUserInteractiveVideos: false,
      appUserVerbLive: false,
    };

    this.logoutUser = this.logoutUser.bind(this);

    //update the selected app profile in case the settings have changed
    if (enterpriseAppUser && appUser) {
      fetchAppProfile(
        token,
        selectedAppProfile ? selectedAppProfile._id : null,
        (data) => {
          const { appUserInteractiveVideos, appUserVerbLive } =
            appProfileSettings(data.data);

          this.setState({
            appUserInteractiveVideos,
            appUserVerbLive,
          });
        }
      );
    }
  }

  updateWindowSize = () => {
    this.setState({ clientWidth: window.innerWidth });
  };

  componentDidMount() {
    const {
      token,
      company,
      enterprise,
      enterpriseAppUser,
      owner,
      appUser,
      currentUser,
      selectedAppProfile,
      learn,
      fetchAppUserAppProfiles,
      fetchAppProfile,
      handlePageLoading,
      fetchLearnJwt,
    } = this.props;

    handlePageLoading(false, () => {});

    if (enterpriseAppUser && appUser) {
      fetchAppUserAppProfiles(token, (data) => {});
    }

    if (learn) {
      const type = enterpriseAppUser ? 'user' : 'admin';
      fetchLearnJwt(token, type, (data) => {});
    }

    this.updateWindowSize();
    window.addEventListener('resize', this.updateWindowSize);

    if (owner) {
      window.zESettings = {
        webWidget: {
          authenticate: {
            chat: {
              jwtFn: function (callback) {
                fetch(`${ROOT_URL}zendesk_jwt/${owner}`).then(function (res) {
                  res.text().then(function (jwt) {
                    callback(jwt);
                  });
                });
              },
            },
          },
        },
      };
    }
  }

  logoutUser() {
    this.handleClose();
    this.props.logout();
  }

  handleMenu = (event, menu) => {
    switch (menu) {
      case 'open-plus-menu':
        this.setState({ plusMenu: event.currentTarget });
        break;
      case 'open-user-menu':
        this.setState({ userMenu: event.currentTarget });
        break;
      case 'open-switch-app-profile-menu':
        this.setState({
          userMenu: null,
          switchAppProfileMenu: event.currentTarget,
        });
        break;
      default:
        break;
    }
  };

  handleClose = (type) => {
    const { enterprise, appUser, live_only, history } = this.props;

    this.setState({
      plusMenu: null,
      userMenu: null,
      switchAppProfileMenu: null,
      anchor: false,
    });

    switch (type) {
      case BILLING:
        history.push(`/${BILLING}`);
        break;
      case QUICK_PROFILE:
        if (live_only || appUser) {
          this.setState({
            openUserModal: true,
          });
        } else {
          history.push(`/users/${QUICK_PROFILE}`);
        }
        break;
      case QUICK_ADD_APP_USER:
        history.push(`/users/${QUICK_ADD_APP_USER}`);
        break;
      case QUICK_ADD_ADMIN:
        history.push(`/users/${QUICK_ADD_ADMIN}`);
        break;
      case QUICK_ADD_CAMPAIGN:
        history.push(`/share/campaigns/${QUICK_ADD_CAMPAIGN}`);
        break;
      case QUICK_ADD_LEAD:
        history.push(`/share/leads/${QUICK_ADD_LEAD}`);
        break;
      case QUICK_ADD_CONTACT:
        history.push(`/share/contacts/${QUICK_ADD_CONTACT}`);
        break;
      case QUICK_SHARE:
        history.push(`/share/contacts/${QUICK_SHARE}`);
        break;
      case QUICK_ADD_TEAM:
        history.push(`/users/${QUICK_ADD_TEAM}`);
        break;
      case QUICK_ADD_LIBRARY_ITEM:
        history.push(`/content/library/${QUICK_ADD_LIBRARY_ITEM}`);
        break;
      case QUICK_ADD_LIBRARY_GROUP:
        history.push(`/content/library/${QUICK_ADD_LIBRARY_GROUP}`);
        break;
      case QUICK_ADD_BRANDING:
        history.push(`/content/brands/${QUICK_ADD_BRANDING}`);
        break;
      case QUICK_ADD_SHOWCASE:
        history.push(`/content/showcases/${QUICK_ADD_SHOWCASE}`);
        break;
      case QUICK_ADD_APP_PROFILE:
        history.push(`/content/app_profiles/${QUICK_ADD_APP_PROFILE}`);
        break;
      case QUICK_SEND_NEW_MESSAGE:
        enterprise
          ? history.push(`/users/messages/${QUICK_SEND_NEW_MESSAGE}`)
          : history.push(`/messages/${QUICK_SEND_NEW_MESSAGE}`);
        break;
      default:
        break;
    }
  };

  handleUserMenu = ({
    companyId,
    enterprise,
    admin,
    enterpriseAppUser,
    appUser,
    onboarding,
    verb_live,
    live_only,
    web_app,
    userMenu,
    adminAppUser,
    classes,
  }) => (
    <Menu
      id='user-menu'
      className={classes.headerSettingsMenu}
      anchorEl={userMenu}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={Boolean(userMenu)}
      onClose={this.handleClose}
    >
      {!enterprise && !onboarding && !appUser && (
        <MenuItem onClick={() => this.handleClose(BILLING)}>Billing</MenuItem>
      )}
      {!onboarding && (
        <MenuItem onClick={() => this.handleClose(QUICK_PROFILE)}>
          User Profile
        </MenuItem>
      )}
      {admin && (web_app || webAppAllowedCompanies(companyId)) && (
        <MenuItem onClick={this.handleAdminAppUser}>
          {adminAppUser ? 'Switch to Admin' : 'Switch to App User'}
        </MenuItem>
      )}
      {enterpriseAppUser && (
        <MenuItem
          onClick={(event) =>
            this.handleMenu(event, 'open-switch-app-profile-menu')
          }
        >
          Switch App Profiles{' '}
          <ArrowForwardIos style={{ marginLeft: 5, fontSize: 13 }} />
        </MenuItem>
      )}
      {!enterprise && verb_live && !onboarding && !live_only && (
        <MenuItem onClick={this.handleTour}>Tour</MenuItem>
      )}
      {((!onboarding && !live_only) || enterpriseAppUser) && (
        <MenuItem onClick={this.toggleDrawer(false)}>
          <a href='https://solofire.com/download' target='_blank'>
            Download Apps
          </a>
        </MenuItem>
      )}
      {/*<MenuItem onClick={this.handleClose}>My account</MenuItem>*/}
      <MenuItem onClick={this.toggleDrawer(false)}>
        <a href='https://solofire.zendesk.com/hc/en-us' target='_blank'>
          Support{' '}
          <span style={{ position: 'relative', top: 3 }}>
            <Launch />
          </span>
        </a>
      </MenuItem>
      <MenuItem onClick={this.logoutUser}>Logout</MenuItem>
      <Typography variant='body1' className={classes.versionNumber}>
        Version {process.env.REACT_APP_VERSION}
      </Typography>
    </Menu>
  );

  handleActiveAppProfile = (appProfiles, appProfile, adminAppUser) => {
    const {
        activeAppProfile,
        shareLibraryItems,
        handlePageLoading,
        turnOnAdminAppUser,
        history,
      } = this.props,
      { loading } = this.state;

    const handleAppProfile =
        appProfile && appProfile._id ? appProfile : appProfiles[0],
      { appUserInteractiveVideos, appUserVerbLive } = appProfileSettings(
        handleAppProfile ? handleAppProfile : {}
      );

    if (!loading) {
      this.setState({ loading: true });
      handlePageLoading(true, () => {});
    }

    activeAppProfile(handleAppProfile, () => {
      shareLibraryItems([], () => {
        this.setState({
          loading: false,
          appUserInteractiveVideos,
          appUserVerbLive,
        });

        this.handleClose();
        turnOnAdminAppUser(adminAppUser);

        handlePageLoading(false, () => {
          history.push('/content/library');
        });
      });
    });
  };

  handleAdminAppUser = () => {
    const {
      token,
      learn,
      fetchAppUserAppProfiles,
      adminAppUser,
      turnOnAdminAppUser,
      handlePageLoading,
      history,
    } = this.props;

    this.setState({ loading: true });

    if (!adminAppUser) {
      handlePageLoading(true, () => {
        fetchAppUserAppProfiles(token, (result) => {
          this.handleActiveAppProfile(result.data, {}, true);
        });
      });
    } else {
      if (learn) {
        console.log('back to admin');
        fetchLearnJwt(token, 'admin', (data) => {});
      }
      this.handleActiveAppProfile([], {}, false);
    }
  };

  handleSwitchAppProfilesMenu = () => {
    const { appProfiles, selectedAppProfile, classes } = this.props;
    const { switchAppProfileMenu } = this.state;

    return (
      <Menu
        id='switch-app-profiles-menu'
        className={classes.headerSettingsMenu}
        anchorEl={switchAppProfileMenu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(switchAppProfileMenu)}
        onClose={this.handleClose}
      >
        {appProfiles.map((appProfile) => {
          const handleSelectedAppProfile =
            selectedAppProfile && selectedAppProfile._id === appProfile._id
              ? true
              : false;

          return (
            <MenuItem
              key={appProfile._id}
              onClick={() => this.handleActiveAppProfile([], appProfile, true)}
              className={
                handleSelectedAppProfile ? classes.selectedAppProfile : null
              }
            >
              {appProfile.name}
            </MenuItem>
          );
        })}
      </Menu>
    );
  };

  activeMenu = (name, currentPath, vertical) => {
    const { enterprise, appUser, live_only, classes } = this.props;
    const activity = ACTIVITY.toLowerCase();
    const brand = BRAND.toLowerCase();
    const includes = name.toLowerCase().replace(/ /g, '_');
    let menuClass = classes.menuButton;
    let activeMenuButton = classes.activeMenuButton;

    if (live_only || appUser) {
      menuClass += ` ${classes.menuButtonAppUser}`;
    }

    if (vertical) {
      menuClass += ` ${classes.menuButtonDrawer}`;
      activeMenuButton += ` ${classes.activeMenuButtonDrawer}`;
    }

    if (enterprise && !appUser) {
      menuClass += ` ${classes.menuButtonExtended}`;

      if (!currentPath.includes(activity)) {
        if (currentPath.includes(includes)) {
          menuClass += ` ${activeMenuButton}`;
        }
      } else {
        if (includes.includes(activity)) {
          menuClass += ` ${activeMenuButton}`;
        }
      }
    } else {
      if (includes.includes(activity)) {
        menuClass += ` ${classes.menuButtonExtended}`;
      }

      if (currentPath.includes(activity) && includes.includes(activity)) {
        menuClass += ` ${activeMenuButton}`;
      } else if (currentPath.includes(brand) && includes.includes(brand)) {
        menuClass += ` ${activeMenuButton}`;
      } else if (currentPath.includes('team') && includes.includes('user')) {
        menuClass += ` ${activeMenuButton}`;
      } else if (
        !currentPath.includes(activity) &&
        !currentPath.includes(brand) &&
        currentPath.includes(includes)
      ) {
        menuClass += ` ${activeMenuButton}`;
      }
    }

    return menuClass;
  };

  menuButtonInner = ({
    enterprise,
    live_only,
    verb_live,
    itemsToShare,
    path,
    name,
    classes,
  }) => (
    <React.Fragment>
      {/*(!enterprise && verb_live && path.includes('share')) &&
        <React.Fragment>
          {name}
          <Badge badgeContent={itemsToShare.length} color="secondary" classes={{badge: classes.shareBadge}}>
            <Share className={classes.headerShareIcon}></Share>
          </Badge>
        </React.Fragment>
      */}
      {(enterprise || !verb_live || !path.includes('share')) && (
        <React.Fragment>{name}</React.Fragment>
      )}
    </React.Fragment>
  );

  menuButton = (name, currentPath, path) => {
    const {
        token,
        company,
        enterprise,
        live_only,
        verb_live,
        appUser,
        currentUser,
        itemsToShare,
        classes,
      } = this.props,
      { clientWidth } = this.state;

    const buttonStyle = appUser || clientWidth > 1280 ? false : true;

    return (
      <React.Fragment>
        <Button
          className={this.activeMenu(name, currentPath, buttonStyle)}
          color='default'
          component={NavLink}
          to={
            !enterprise && verb_live && path.includes('share')
              ? '/share_cart'
              : path
          }
          disableRipple={true}
          onClick={this.toggleDrawer(false)}
        >
          {this.menuButtonInner({
            enterprise,
            live_only,
            verb_live,
            itemsToShare,
            path,
            name,
            classes,
          })}
        </Button>
      </React.Fragment>
    );
  };

  subMenuButton = (currentPath, main, name) => {
    const { enterpriseAppUser, learnJwt, classes } = this.props;
    const linkName = name.toLowerCase().replace(/ /g, '_');

    let link = `/${main}/${linkName}`;
    let extraClasses = classes.subMenu;

    if (linkName === 'users' && !currentPath.includes(ACTIVITY.toLowerCase())) {
      link = `/${main}`;
    }

    if (linkName === 'users' && currentPath.includes('messages')) {
      let test = null;
    } else if (currentPath.includes(linkName)) {
      extraClasses = `${classes.subMenu} ${classes.subMenuActive}`;
    }

    if (linkName === 'learn') {
      link = `https://learn${
        !enterpriseAppUser ? '-admin' : ''
      }.verb.tech/paths?token=${learnJwt}`;

      return (
        <Typography key={name} type='display1' className={extraClasses}>
          <a href={link} target='_blank'>
            {name}
          </a>
        </Typography>
      );
    } else {
      return (
        <Typography key={name} type='display1' className={extraClasses}>
          <NavLink to={link}>{name}</NavLink>
        </Typography>
      );
    }
  };

  subMenuButtonGroup = (currentPath) => {
    const { enterprise, screens, trade_booths, learn, currentRole } =
      this.props;
    let main = '';
    let subLinks = [];

    if (currentPath.includes(ACTIVITY.toLowerCase())) {
      main = ACTIVITY;

      if (enterprise) {
        subLinks = [
          'Dashboard',
          'Users',
          'Contacts',
          'Content',
          'Showcases',
          'Sessions',
        ];
      } else {
        subLinks = ['Dashboard', 'Users', 'Contacts', 'Content', 'Sessions'];
      }

      const handleScreens = screens ? subLinks.push('Screens') : null;
    } else if (currentPath.includes(CONTENT.toLowerCase())) {
      main = CONTENT;

      if (enterprise) {
        subLinks = ['Library', 'Brands', 'Showcases', 'App Profiles'];
      }

      if (trade_booths) {
        subLinks.push('Trade Booths');
      }

      if (learn) {
        subLinks.push('Learn');
      }
    } else if (currentPath.includes(USERS.toLowerCase())) {
      main = USERS;
      if (enterprise && currentRole < '3') {
        subLinks = ['Users', 'Messages'];
      }
    } else if (currentPath.includes(SHARE.toLowerCase())) {
      if (enterprise) {
        main = SHARE;
        subLinks = ['Campaigns', 'Leads', 'Contacts'];
      }
    }

    return {
      main,
      subLinks,
    };
  };

  handleTour = () => {
    const { token, companyId, updateCompany, history } = this.props;

    this.setState({
      plusMenu: null,
      userMenu: null,
      anchor: false,
    });

    updateCompany(token, { tour: true }, companyId, (data) => {
      history.push('/content/library');
    });
  };

  scrollSubNav = (direction) => {
    const { scrolled } = this.state;
    const subNav = document.getElementById('sub-nav');
    const subNavWidth = subNav.scrollWidth;

    let update = false;

    if (scrolled && direction === 'left') {
      subNav.scrollTo(0, 0);
      update = true;
    } else if (!scrolled && direction === 'right') {
      subNav.scrollTo(subNavWidth, 0);
      update = true;
    }

    if (update) {
      this.setState({ scrolled: !scrolled });
    }
  };

  menuSub = ({
    main,
    enterprise,
    appUser,
    subLinks,
    currentPath,
    name,
    classes,
  }) => {
    const { clientWidth, scrolled } = this.state;
    const subNavWidth = subLinks.length * 115;

    let leftArrowClass = classes.subNavMenuArrowDeactivated,
      rightArrowClass = null,
      scrollSubMenu = false;

    if (scrolled) {
      leftArrowClass = null;
      rightArrowClass = classes.subNavMenuArrowDeactivated;
    }

    if (subNavWidth > clientWidth) {
      scrollSubMenu = true;
    }

    return (
      <React.Fragment>
        {!appUser &&
          (main === ACTIVITY ||
            (enterprise && main === CONTENT) ||
            (enterprise && main === USERS) ||
            (enterprise && main === SHARE)) && (
            <Grid
              container
              spacing={1}
              alignItems='center'
              className={classes.subNavContainer}
            >
              <div id='sub-nav' className={classes.subNav}>
                <Grid
                  item
                  className={classes.subNavMarginLeft}
                  style={{ width: subNavWidth }}
                >
                  {subLinks.map((name) =>
                    this.subMenuButton(currentPath, main.toLowerCase(), name)
                  )}
                  {scrollSubMenu && (
                    <React.Fragment>
                      <ArrowLeft
                        className={`${classes.subNavMenuArrow} ${leftArrowClass}`}
                        style={{ position: 'absolute', bottom: 9, left: 5 }}
                        onClick={() => this.scrollSubNav('left')}
                      />
                      <ArrowRight
                        className={`${classes.subNavMenuArrow} ${rightArrowClass}`}
                        style={{ position: 'absolute', bottom: 9, right: 5 }}
                        onClick={() => this.scrollSubNav('right')}
                      />
                    </React.Fragment>
                  )}
                </Grid>
              </div>
            </Grid>
          )}
      </React.Fragment>
    );
  };

  billingNotifications = ({
    company,
    appUser,
    onboarding,
    daysLeftText,
    needBilling,
    needBillingMessage,
    trialNotificationStyle,
    trialNotificationActivity,
    classes,
  }) => (
    <React.Fragment>
      {!appUser && company.payment_status === '0' && (
        <Typography
          variant='h3'
          className={`${classes.trialNotification} ${trialNotificationActivity}`}
          style={trialNotificationStyle}
          onClick={() => (onboarding ? null : this.handleClose(BILLING))}
        >
          <span className={classes.trialNotificationStrong}>
            {daysLeftText}
          </span>{' '}
          Days Left of your Trial!
        </Typography>
      )}
      {!appUser && needBilling && (
        <Typography variant='h3' className={classes.trialNotification}>
          {needBillingMessage}
        </Typography>
      )}
    </React.Fragment>
  );

  menuRight = ({
    company,
    companyId,
    enterprise,
    admin,
    enterpriseAppUser,
    appUser,
    live_only,
    verb_live,
    web_app,
    onboarding,
    currentUser,
    daysLeftText,
    needBilling,
    needBillingMessage,
    userMenu,
    trialNotificationStyle,
    trialNotificationActivity,
    activeProfileHeaderContainer,
    clientWidth,
    adminAppUser,
    classes,
  }) => (
    <React.Fragment>
      <Grid
        container
        spacing={1}
        className={`${
          live_only ? classes.userContainerLiveOnly : classes.userContainer
        }`}
      >
        {!onboarding && !live_only && !appUser && (
          <Grid item className={classes.downloadAppsButtonContainer}>
            <Button
              variant='contained'
              color='secondary'
              className={classes.downloadAppsButton}
              href='/download'
              onClick={this.toggleDrawer(false)}
            >
              Download Apps
            </Button>
          </Grid>
        )}

        <MDandAbove>
          {this.billingNotifications({
            company,
            appUser,
            onboarding,
            daysLeftText,
            needBilling,
            needBillingMessage,
            trialNotificationStyle,
            trialNotificationActivity,
            classes,
          })}
        </MDandAbove>

        <Grid
          item
          className={`${!live_only && classes.moveDownHeaderName} ${
            !live_only && classes.profileHeaderContainer
          } ${activeProfileHeaderContainer} ${
            appUser && clientWidth < 960 && classes.hideUserName
          }`}
        >
          {(currentUser.first_name || currentUser.last_name) && (
            <React.Fragment>
              <Typography
                variant='subtitle1'
                className={classes.userName}
                onClick={(event) => this.handleMenu(event, 'open-user-menu')}
              >
                {currentUser.first_name} {currentUser.last_name}
              </Typography>
              <br />
            </React.Fragment>
          )}
          <Typography
            variant='subtitle1'
            className={`${classes.userCompany} ${
              !currentUser.first_name || !currentUser.last_name
                ? classes.onlyUserCompanyName
                : null
            } ${classes.truncateText}`}
            onClick={(event) => this.handleMenu(event, 'open-user-menu')}
          >
            {company.name}
          </Typography>
        </Grid>
        <Grid item>
          <IconButton
            className={`${classes.userDrop} ${
              live_only && classes.userDropLiveOnly
            }`}
            aria-owns={userMenu ? 'user-menu' : null}
            aria-haspopup='true'
            onClick={(event) => this.handleMenu(event, 'open-user-menu')}
          >
            <Settings />
          </IconButton>
          {this.handleUserMenu({
            companyId,
            enterprise,
            admin,
            enterpriseAppUser,
            appUser,
            onboarding,
            verb_live,
            live_only,
            web_app,
            userMenu,
            adminAppUser,
            classes,
          })}
          {this.handleSwitchAppProfilesMenu()}
        </Grid>
      </Grid>
    </React.Fragment>
  );

  menuMain = ({ enterprise, appUser, live_only, onboarding, currentPath }) => {
    return (
      <React.Fragment>
        {!onboarding && (
          <Grid
            item
            style={
              live_only && !appUser ? { position: 'relative', top: 2 } : null
            }
          >
            {this.menuButton(CONTENT, currentPath, '/content/library')}
            {!enterprise &&
              !appUser &&
              !live_only &&
              this.menuButton(BRAND, currentPath, '/brand')}
            {!appUser &&
              !live_only &&
              this.menuButton(USERS, currentPath, '/users')}
            {/*{!enterprise && this.menuButton(MESSAGES, currentPath, '/messages')}*/}
            {!appUser &&
              !live_only &&
              this.menuButton(ACTIVITY, currentPath, '/analytics/dashboard')}
            {enterprise &&
              !appUser &&
              !live_only &&
              this.menuButton(SHARE, currentPath, '/share/contacts')}
            {(live_only || appUser) &&
              this.menuButton(CONTACTS, currentPath, '/contacts')}
            {/*enterprise && this.menuButton(LEARN, currentPath, '/learn')*/}
          </Grid>
        )}
      </React.Fragment>
    );
  };

  menuMainRight = ({
    enterprise,
    appUser,
    enterpriseAppUser,
    live_only,
    verb_live,
    onboarding,
    currentPath,
    currentRole,
    viewer,
    plusMenu,
    itemsToShare,
    master_admin,
    anchor,
    userMenu,
    clientWidth,
    appUserInteractiveVideos,
    appUserVerbLive,
    learn,
    learnJwt,
    classes,
  }) => (
    <React.Fragment>
      {!onboarding && (
        <Grid
          item
          className={`${classes.borderLeft} ${
            live_only ? classes.buttonAreaContainerLiveOnly : null
          }`}
        >
          {((enterprise && !appUser) ||
            (!enterprise && !verb_live && currentRole < '2' && !appUser)) && (
            <Fab
              size='small'
              color='secondary'
              aria-label='add'
              aria-owns={plusMenu ? 'plus-menu' : null}
              aria-haspopup='true'
              onClick={(event) => this.handleMenu(event, 'open-plus-menu')}
              className={classes.miniPlusButton}
            >
              <Add />
            </Fab>
          )}
          <Menu
            id='plus-menu'
            anchorEl={plusMenu}
            open={Boolean(plusMenu)}
            onClose={this.handleClose}
            PopoverClasses={{ paper: classes.quickPaper }}
          >
            <MenuItem
              classes={{ root: classes.menuItem }}
              onClick={() => this.handleClose(QUICK_ADD_APP_USER)}
            >
              <Add className={classes.quickMenuIcon} /> New App User
            </MenuItem>
            {(enterprise || !verb_live) && (
              <MenuItem
                classes={{ root: classes.menuItem }}
                onClick={() => this.handleClose(QUICK_ADD_LIBRARY_ITEM)}
              >
                <Add className={classes.quickMenuIcon} /> New Library Item
              </MenuItem>
            )}
            <MenuItem
              classes={{ root: classes.menuItem }}
              onClick={() => this.handleClose(QUICK_ADD_LIBRARY_GROUP)}
            >
              <Add className={classes.quickMenuIcon} /> New Content Group
            </MenuItem>
            {enterprise && (
              <span>
                <MenuItem
                  classes={{ root: classes.menuItem }}
                  onClick={() => this.handleClose(QUICK_SEND_NEW_MESSAGE)}
                >
                  <Add className={classes.quickMenuIcon} /> Send New Message
                </MenuItem>
                <MenuItem
                  classes={{ root: classes.menuItem }}
                  onClick={() => this.handleClose(QUICK_ADD_BRANDING)}
                >
                  <Add className={classes.quickMenuIcon} /> New Brand
                </MenuItem>
                <MenuItem
                  classes={{ root: classes.menuItem }}
                  onClick={() => this.handleClose(QUICK_ADD_SHOWCASE)}
                >
                  <Add className={classes.quickMenuIcon} /> New Showcase
                </MenuItem>
                <MenuItem
                  classes={{ root: classes.menuItem }}
                  onClick={() => this.handleClose(QUICK_ADD_APP_PROFILE)}
                >
                  <Add className={classes.quickMenuIcon} /> New App Profile
                </MenuItem>
                <MenuItem
                  classes={{ root: classes.menuItem }}
                  onClick={() => this.handleClose(QUICK_ADD_CAMPAIGN)}
                >
                  <Add className={classes.quickMenuIcon} /> New Campaign
                </MenuItem>
                <MenuItem
                  classes={{ root: classes.menuItem }}
                  onClick={() => this.handleClose(QUICK_ADD_LEAD)}
                >
                  <Add className={classes.quickMenuIcon} /> New Lead
                </MenuItem>
                <MenuItem
                  classes={{ root: classes.menuItem }}
                  onClick={() => this.handleClose(QUICK_ADD_CONTACT)}
                >
                  <Add className={classes.quickMenuIcon} /> New Contact
                </MenuItem>
                <MenuItem
                  classes={{ root: classes.menuItem }}
                  onClick={() => this.handleClose(QUICK_SHARE)}
                >
                  <Add className={classes.quickMenuIcon} /> Share Content
                </MenuItem>
              </span>
            )}
            {(enterprise || !verb_live) && (
              <MenuItem
                classes={{ root: classes.menuItem }}
                onClick={() => this.handleClose(QUICK_ADD_TEAM)}
              >
                <Add className={classes.quickMenuIcon} /> New Team
              </MenuItem>
            )}
            <MenuItem
              classes={{ root: classes.menuItem }}
              onClick={() => this.handleClose(QUICK_ADD_ADMIN)}
            >
              <Add className={classes.quickMenuIcon} /> New Admin
            </MenuItem>
          </Menu>
          {((!enterprise && (verb_live || live_only)) ||
            (enterprise && appUser)) && (
            <HeaderButtons
              appUser={appUser}
              enterpriseAppUser={enterpriseAppUser}
              itemsToShare={itemsToShare}
              clientWidth={clientWidth}
              appUserInteractiveVideos={appUserInteractiveVideos}
              appUserVerbLive={appUserVerbLive}
              learn={learn}
              learnJwt={learnJwt}
            />
          )}
        </Grid>
      )}
      {(currentRole === 'MA' || master_admin) && (
        <span style={{ marginLeft: 10 }}>
          {this.menuButton('Master Admin', currentPath, '/master_admin')}
        </span>
      )}
    </React.Fragment>
  );

  toggleDrawer = (open) => (event) => {
    this.setState({
      plusMenu: null,
      userMenu: null,
      anchor: open,
    });
  };

  mainMenuList = ({
    enterprise,
    appUser,
    live_only,
    verb_live,
    onboarding,
    currentPath,
    currentRole,
    viewer,
    plusMenu,
    itemsToShare,
    master_admin,
    anchor,
    classes,
  }) => (
    <div
      role='presentation'
      onClick={this.toggleDrawer(false)}
      onKeyDown={this.toggleDrawer(false)}
    >
      {this.menuMain({
        enterprise,
        appUser,
        live_only,
        onboarding,
        currentPath,
      })}
    </div>
  );

  handleMenuMain = ({
    enterprise,
    appUser,
    enterpriseAppUser,
    live_only,
    verb_live,
    onboarding,
    currentPath,
    currentRole,
    viewer,
    plusMenu,
    itemsToShare,
    master_admin,
    anchor,
    userMenu,
    clientWidth,
    appUserInteractiveVideos,
    appUserVerbLive,
    learn,
    learnJwt,
    classes,
  }) => (
    <Grid
      container
      spacing={1}
      alignItems='center'
      className={`${classes.menuMainContainer} ${
        live_only || appUser ? classes.menuMainContainerLiveOnly : null
      }`}
    >
      <Grid item className={classes.menuMarginRight}>
        <NavLink to={'/content/library'}>
          {(!live_only || appUser) && (
            <img
              className={`${classes.logoIcon} ${
                appUser && classes.logoIconAppUserExtraSmall
              }`}
              style={appUser ? { width: 38 } : null}
              src='/images/verb/verb-bug.png'
              alt={''}
            />
          )}
          {live_only && !appUser && (
            <img
              className={classes.liveOnlylogoIcon}
              src='/images/verb/verb-live-logo.svg'
              alt={''}
            />
          )}
        </NavLink>
      </Grid>
      {!viewer && (
        <React.Fragment>
          {(appUser || clientWidth > 1279) &&
            this.menuMain({
              enterprise,
              appUser,
              live_only,
              onboarding,
              currentPath,
            })}
          {!live_only &&
            !appUser &&
            this.menuMainRight({
              enterprise,
              appUser,
              enterpriseAppUser,
              live_only,
              verb_live,
              onboarding,
              currentPath,
              currentRole,
              viewer,
              plusMenu,
              itemsToShare,
              master_admin,
              anchor,
              userMenu,
              clientWidth,
              appUserInteractiveVideos,
              appUserVerbLive,
              learn,
              learnJwt,
              classes,
            })}
          {((!appUser && live_only && clientWidth > 959) ||
            (appUser && clientWidth > 1279)) &&
            this.menuMainRight({
              enterprise,
              appUser,
              enterpriseAppUser,
              live_only,
              verb_live,
              onboarding,
              currentPath,
              currentRole,
              viewer,
              plusMenu,
              itemsToShare,
              master_admin,
              anchor,
              userMenu,
              clientWidth,
              appUserInteractiveVideos,
              appUserVerbLive,
              learn,
              learnJwt,
              classes,
            })}
        </React.Fragment>
      )}
    </Grid>
  );

  closedUserModal = () => {
    this.setState({
      openUserModal: false,
    });
  };

  handleUserModal = () => {
    const { owner } = this.props;
    const { openUserModal } = this.state;

    if (openUserModal) {
      return (
        <UserModalContainer
          openUserModal={openUserModal}
          selectedUserId={owner}
          closedUserModal={this.closedUserModal}
        />
      );
    }
  };

  render() {
    const {
      company,
      companyId,
      token,
      enterprise,
      admin,
      enterpriseAppUser,
      appUser,
      live_only,
      verb_live,
      web_app,
      appProfiles,
      alerts,
      owner,
      currentUser,
      currentRole,
      master_admin,
      classes,
      loggedOut,
      itemsToShare,
      adminAppUser,
      turnOnAdminAppUser,
      learn,
      learnJwt,
    } = this.props;
    const {
      loading,
      plusMenu,
      userMenu,
      anchor,
      clientWidth,
      appUserInteractiveVideos,
      appUserVerbLive,
    } = this.state;
    const currentPath = this.props.location.pathname;
    const { main, subLinks } = this.subMenuButtonGroup(currentPath);

    let headerContainerExtended = null,
      topBarExtended = null,
      mainNavExtended = null,
      activeProfileHeaderContainer = null,
      needBilling = false,
      needBillingMessage = null,
      expired = expiredAccount(company),
      alertsHeader = null,
      onboarding = false,
      viewer = false,
      check = 0,
      mainNavLiveOnly = null,
      headerButtonAreaLiveOnly = null,
      headerNameAreaLiveOnly = null;

    if (currentPath.includes('onboarding')) {
      onboarding = true;
    }

    if (
      !appUser &&
      (main === ACTIVITY ||
        (enterprise && main === CONTENT) ||
        (enterprise && main === USERS) ||
        (enterprise && main === SHARE))
    ) {
      headerContainerExtended = classes.headerContainerExtended;
      topBarExtended = classes.topBarExtended;
      mainNavExtended = classes.mainNavExtended;
    }

    if (currentPath.includes('company') || currentPath.includes('billing')) {
      activeProfileHeaderContainer = classes.activeProfileHeaderContainer;
    }

    if (expired) {
      needBilling = true;

      if (expired === 0) {
        needBillingMessage = <span>Your account has expired.</span>;
      } else if (expired === 1) {
        needBillingMessage = <span>Your account is canceled.</span>;
      } else if (expired === 2) {
        needBillingMessage = <span>Your payment failed.</span>;
      } else if (expired === 3) {
        needBillingMessage = <span>Your subscription has been canceled.</span>;
      } else if (expired === 4) {
        needBillingMessage = (
          <span>Your trial has ended please add your billing info.</span>
        );
      }
    }

    if (!viewer && !loggedOut && !enterprise && clientWidth > 760) {
      window.get_ambassador_raf = true;
    } else {
      window.get_ambassador_raf = false;
    }

    let daysLeftText = '';

    if (company && company.seven_day_trial && company.trial_countdown > 3) {
      daysLeftText = company.trial_countdown - 3;
    } else if (
      company &&
      company.seven_day_trial &&
      company.trial_countdown <= 3
    ) {
      daysLeftText = `${company.trial_countdown} Bonus`;
    } else if (
      company &&
      !company.seven_day_trial &&
      company.trial_countdown > 10
    ) {
      daysLeftText = company.trial_countdown - 10;
    } else if (
      company &&
      !company.seven_day_trial &&
      company.trial_countdown <= 10
    ) {
      daysLeftText = `${company.trial_countdown} Bonus`;
    }

    let trialNotificationStyle = {},
      trialNotificationActivity = null;

    if (onboarding) {
      trialNotificationStyle.cursor = 'default';
    }

    if (currentPath.includes(ACTIVITY.toLowerCase())) {
      trialNotificationActivity = classes.trialNotificationActivity;
    }

    // const { company, alerts, classes } = this.props
    // const { anchor } = this.state
    // const currentPath = this.props.location.pathname;
    const checkArray = [MASTER_ADMIN, ROOM, SPACE, SHOWCASE, SHOWCASES, VIEWER];
    const width =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;

    checkArray.map((path) => {
      if (currentPath.includes(path.toLowerCase().replace(/ /g, '_'))) {
        check++;
      }
    });

    if (
      (token && currentPath.includes('login')) ||
      currentPath.includes('sso_verification') ||
      (check > 0 &&
        !currentPath.includes('content/showcases') &&
        !currentPath.includes('analytics/showcases'))
    ) {
      viewer = true;
    }

    // if ((!viewer && !currentPath.includes('onboarding') && company && !company.tour && !company.enterprise && Object.keys(alerts).length > 0) || (!viewer && !checkDoesNotInclude(currentPath) && width < 1024)) {
    if (
      !viewer &&
      !currentPath.includes('onboarding') &&
      company &&
      !company.tour &&
      !company.enterprise &&
      Object.keys(alerts).length > 0
    ) {
      alertsHeader = classes.alertsHeader;
    }

    if (live_only || appUser) {
      mainNavLiveOnly = classes.mainNavLiveOnly;
    }

    if (!appUser && live_only) {
      headerButtonAreaLiveOnly = classes.headerButtonAreaLiveOnly;
      headerNameAreaLiveOnly = classes.headerNameAreaLiveOnly;
    }

    const menuMainSizeSmall = appUser ? 10 : 12;

    return (
      <div className={`${classes.header} ${alertsHeader}`}>
        {loading && ( //when switching between admin and app user
          <div className={classes.overlay}></div>
        )}

        {company && company.tour && !currentPath.includes('completed') && (
          <div className={classes.tourContainer}>
            <TourModalContainer />
          </div>
        )}

        {!viewer && (
          <AppBar position='static' className={classes.appBar}>
            <Toolbar className={`${classes.topBar} ${topBarExtended}`}>
              {!loggedOut && !appUser && (
                <MDandBelow>
                  <Drawer
                    anchor={'left'}
                    open={anchor}
                    onClose={this.toggleDrawer(false)}
                    classes={{
                      root: classes.menuDrawerContainer,
                      paper: classes.menuDrawer,
                    }}
                  >
                    <IconButton
                      className={classes.iconClose}
                      onClick={this.toggleDrawer(false)}
                    >
                      <Close />
                    </IconButton>
                    {this.mainMenuList({
                      enterprise,
                      appUser,
                      live_only,
                      verb_live,
                      onboarding,
                      currentPath,
                      currentRole,
                      viewer,
                      plusMenu,
                      itemsToShare,
                      master_admin,
                      anchor,
                      classes,
                    })}
                    <SMandBelow>
                      {this.menuRight({
                        company,
                        companyId,
                        enterprise,
                        admin,
                        enterpriseAppUser,
                        appUser,
                        live_only,
                        verb_live,
                        web_app,
                        onboarding,
                        currentUser,
                        daysLeftText,
                        needBilling,
                        needBillingMessage,
                        userMenu,
                        trialNotificationStyle,
                        trialNotificationActivity,
                        clientWidth,
                        adminAppUser,
                        classes,
                      })}
                    </SMandBelow>
                  </Drawer>
                </MDandBelow>
              )}
              <div
                className={`${classes.headerContainer} ${headerContainerExtended}`}
              >
                {loggedOut && (
                  <Grid
                    container
                    spacing={4}
                    className={`${classes.mainNav} ${classes.mainNavLogin}`}
                    style={{ justifyContent: 'center', alignItems: 'center' }}
                  >
                    <Grid item xs={6} className={classes.logoContainer}>
                      <a href='https://verb.tech'>
                        <img
                          className={classes.logoLogin}
                          src='/images/verb/verb-logo.png'
                          alt={''}
                        />
                      </a>
                    </Grid>

                    <Grid xs={6} item={true}>
                      <LGandAbove>
                        <Button
                          variant='contained'
                          color='secondary'
                          className={`${classes.downloadAppsButton} ${classes.loginButtonHeader}`}
                          href='/login'
                        >
                          Login
                        </Button>
                      </LGandAbove>
                    </Grid>
                  </Grid>
                )}
                {!loggedOut && (
                  <div style={{ width: '100%' }}>
                    {!live_only && !appUser && (
                      <Button
                        onClick={this.toggleDrawer(true)}
                        className={classes.mobileMenuButton}
                      >
                        <MenuIcon className={classes.drawerIcon} />
                      </Button>
                    )}
                    <Grid
                      container
                      spacing={1}
                      className={`${classes.mainNav} ${mainNavLiveOnly} ${mainNavExtended}`}
                    >
                      <Grid
                        item
                        md={8}
                        xs={menuMainSizeSmall}
                        className={`${classes.menuContainerReducedFour} ${headerButtonAreaLiveOnly}`}
                      >
                        {this.handleMenuMain({
                          enterprise,
                          appUser,
                          enterpriseAppUser,
                          live_only,
                          verb_live,
                          onboarding,
                          currentPath,
                          currentRole,
                          viewer,
                          plusMenu,
                          itemsToShare,
                          master_admin,
                          anchor,
                          userMenu,
                          clientWidth,
                          appUserInteractiveVideos,
                          appUserVerbLive,
                          learn,
                          learnJwt,
                          classes,
                        })}
                      </Grid>

                      <Grid
                        item
                        md={4}
                        xs={2}
                        className={`${
                          classes.menuContainerReducedEight
                        } ${headerNameAreaLiveOnly} ${
                          appUser && classes.appUserGearMenu
                        }`}
                      >
                        {this.menuRight({
                          company,
                          companyId,
                          enterprise,
                          admin,
                          enterpriseAppUser,
                          appUser,
                          live_only,
                          verb_live,
                          web_app,
                          onboarding,
                          currentUser,
                          daysLeftText,
                          needBilling,
                          needBillingMessage,
                          userMenu,
                          trialNotificationStyle,
                          trialNotificationActivity,
                          clientWidth,
                          adminAppUser,
                          classes,
                        })}
                      </Grid>
                    </Grid>

                    <LGandAbove>
                      {this.menuSub({
                        main,
                        enterprise,
                        appUser,
                        subLinks,
                        currentPath,
                        classes,
                      })}
                    </LGandAbove>

                    {(live_only || appUser) && this.handleUserModal()}
                  </div>
                )}
              </div>
              {!loggedOut && (
                <React.Fragment>
                  <SMandBelow>
                    {this.billingNotifications({
                      company,
                      appUser,
                      onboarding,
                      daysLeftText,
                      needBilling,
                      needBillingMessage,
                      trialNotificationStyle,
                      trialNotificationActivity,
                      classes,
                    })}
                  </SMandBelow>
                  <MDandBelow>
                    {this.menuSub({
                      main,
                      enterprise,
                      appUser,
                      subLinks,
                      currentPath,
                      classes,
                    })}
                  </MDandBelow>
                </React.Fragment>
              )}
            </Toolbar>
          </AppBar>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  if (
    _.isEmpty(state.login.token) ||
    _.isEmpty(state.login.company) ||
    _.isEmpty(state.login.user)
  ) {
    return {
      loggedOut: true,
    };
  }

  return {
    token: state.login.token,
    company: state.company,
    companyId: state.login.company._id,
    enterprise:
      state.login.user.user_type > '1' ? false : state.login.company.enterprise,
    live_only: state.login.company.live_only
      ? state.login.company.live_only
      : state.login.user.user_type > '1',
    adminAppUser: state.adminAppUser,
    enterpriseAppUser: handleEnterpriseAppUser(state),
    appUser: handleAppUser(state),
    verb_live: state.login.company.verb_live,
    web_app: state.company.web_app,
    appProfiles: state.appProfiles,
    selectedAppProfile: state.appProfile,
    screens: state.company.screens,
    trade_booths: state.company.trade_booths,
    alerts: state.alerts,
    owner: state.login.user._id,
    currentUser: state.login.user,
    currentRole: state.login.user.user_type,
    admin: state.login.user.user_type < '2',
    master_admin: state.login.master_admin,
    itemsToShare: state.itemsToShare,
    learn: state.company.learn,
    learnJwt: state.learn,
  };
}

MenuAppBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(
  connect(mapStateToProps, {
    MenuAppBar,
    logout,
    updateCompany,
    fetchAppUserAppProfiles,
    fetchAppProfile,
    activeAppProfile,
    shareLibraryItems,
    handlePageLoading,
    turnOnAdminAppUser,
    fetchLearnJwt,
  })(withStyles(styles)(MenuAppBar))
);
