import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";
import { styles } from "../styles";

import Typography from "@material-ui/core/Typography";

import WithLoading from "../subcomponents/withLoading";
import FilePreview from "../subcomponents/filePreview";

import { fetchLibraryItemView } from "../../actions/actions_index";

class Viewer extends Component {
  state = {
    windowWidth: null,
  };

  updateDimensions = () => {
    const windowWidth = document.documentElement.clientWidth;
    this.setState({ windowWidth });
  };

  componentDidMount() {
    document.getElementById("html").style.overflow = "hidden";

    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillUnmount() {
    document.getElementById("html").removeAttribute("style");
    window.removeEventListener("resize", this.updateDimensions);
  }

  render() {
    const { libraryItem, classes } = this.props;
    const { windowWidth } = this.state;

    let mobileFilePreviewContainer = null,
      filePreviewContainerExtra = null;

    if (windowWidth < 650) {
      mobileFilePreviewContainer = classes.mobileFilePreviewContainer;
    }

    if (libraryItem && libraryItem.interactive_video) {
      filePreviewContainerExtra = classes.filePreviewContainerInteractiveVideo;
    }

    return (
      <div className={classes.viewerContainer}>
        <div
          className={`${classes.filePreviewContainer} ${mobileFilePreviewContainer} ${filePreviewContainerExtra}`}
          style={{ paddingTop: 20, minHeight: "100%" }}>
          <FilePreview
            item={libraryItem}
            classes={classes}
            view={"single"} //designates if from admin or space
            // color={color}
            // backgroundColor={backgroundColor}
          />
        </div>
        <div className={classes.roomPoweredByBar}>
          <Typography variant="body1" className={classes.roomPoweredByText}>
            <span className={classes.roomPoweredByTextSpan}>Powered by</span>
            <a href="https://solofire.com">
              <img
                className={classes.roomPoweredByLogo}
                src="/images/verb/verb-dark-logo.png"
              />
            </a>
          </Typography>
        </div>
      </div>
    );
  }
}

const ViewerWithLoading = WithLoading(Viewer);

class ViewerContainer extends Component {
  state = {
    loading: true,
  };

  componentDidMount() {
    const { fetchLibraryItemView, match } = this.props;
    const { _id } = match.params;

    fetchLibraryItemView(_id, (libraryItem) => {
      this.setState({
        loading: false,
      });
    });
  }

  render() {
    const { loadingUser, loadingItems } = this.state;

    return <ViewerWithLoading {...this.props} />;
  }
}

function mapStateToProps(state) {
  return {
    libraryItem: state.libraryItem,
  };
}

const mapDispatchToProps = (dispatch) => ({
  fetchLibraryItemView: (id, callback) =>
    dispatch(fetchLibraryItemView(id, callback)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(ViewerContainer))
);
