import { TURN_ON_ADMIN_APP_USER } from "../actions/actions_login";

export default function(state = false, action) {
  switch (action.type) {
    case TURN_ON_ADMIN_APP_USER:

      if (typeof action.payload === 'undefined') {
        return state;
      }

      return action.payload;
    default:
      return state;
  }
}