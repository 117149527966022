import _ from "lodash";
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import { styles } from '../styles';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Modal from '@material-ui/core/Modal';

import Close from '@material-ui/icons/Close';

import LoadingDivContainer from '../subcomponents/loadingDiv';
import { modalTableHeight, handleAppUser } from '../helpers/helperFunctions';
import { multipleFilter, tagsFilter, libraryNameSort } from '../helpers/gridLibraryHelpers';
import GridContainer from '../subcomponents/gridContainer';

import { fetchLibrary } from '../../actions/actions_index';

class ShareLibraryContentModal extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      selectedItems: []
    }

    this.onSelectionChange = selectedItems => {
      this.setState({ selectedItems });
    }
  }

  componentDidMount() {
    const { token, companyId, appProfile, currentSelected} = this.props;
    const { library_groups } = appProfile

    this.props.fetchLibrary(token, companyId, library_groups, library => {
      const currentItems = currentSelected.map(value => _.findIndex(library.data, ['_id', value]));

      this.setState({
        loading: false,
        selectedItems: currentItems
      })
    })
  }

  submitShareLibraryContentModal = () => {
    const { library, selectShareLibraryContent } = this.props;
    const { selectedItems } = this.state;
    const addedFiles = selectedItems.map(value => library[value]._id);

    selectShareLibraryContent(addedFiles);
  }

  closeShareLibraryContentModal = () => {
    this.props.closedShareLibraryContentModal();
  }

  libraryGrid = () => {
    const { library, classes } = this.props;
    const { selectedItems } = this.state;

    const columns = [
      { name: 'thumbnail', title: ' ' },
      { name: 'file_type', title: 'Type' },
      { name: 'filename', title: 'Name / Filename' },
      { name: 'search_tags', title: '' },
      { name: 'updatedAt', title: 'Last Updated' }
    ];

    const tableColumnExtensions = [
      { columnName: 'thumbnail', width: 80 },
      { columnName: 'file_type', width: 80 },
      { columnName: 'filename' },
      { columnName: 'search_tags', width: 1 },
      { columnName: 'updatedAt', width: 170 }
    ]

    const sortingStateColumnExtensions = [
      { columnName: 'thumbnail', sortingEnabled: false },
      { columnName: 'file_type', sortingEnabled: true },
      { columnName: 'filename', sortingEnabled: true },
      { columnName: 'search_tags', sortingEnabled: false },
      { columnName: 'updatedAt', sortingEnabled: true }
    ]

    const filteringStateColumnExtensions = [
      { columnName: 'thumbnail', filteringEnabled: false },
      { columnName: 'file_type', filteringEnabled: true },
      { columnName: 'filename', filteringEnabled: false },
      { columnName: 'search_tags', filteringEnabled: false },
      { columnName: 'updatedAt', filteringEnabled: false }
    ]

    const rows = library;

    const integratedFilteringColumnExtensions = [
      { columnName: 'filename', predicate: multipleFilter },
      { columnName: 'search_tags', predicate: tagsFilter }
    ]

    const integratedSortingColumnExtensions = [
      { columnName: 'filename', compare: libraryNameSort }
    ]

    const sorting = [
      { columnName: 'updatedAt', direction: 'desc' }
    ]

    return (
      <GridContainer 
        gridHeight={modalTableHeight()}
        rows={rows}
        columns={columns}
        tableColumnExtensions={tableColumnExtensions}
        sortingStateColumnExtensions={sortingStateColumnExtensions}
        sorting={sorting}
        selection={selectedItems}
        onSelectionChange={this.onSelectionChange}
        filteringStateColumnExtensions={filteringStateColumnExtensions}
        integratedFilteringColumnExtensions={integratedFilteringColumnExtensions}
        integratedSortingColumnExtensions={integratedSortingColumnExtensions}
        showSelectAll={true}
        showSelectionColumn={true}
        showRowDetail={false}
        classes={classes}
      />
    )
  }

  handleModalBody = () => {
    const { classes } = this.props;
    const { loading } = this.state;

    let title = '',
        subheading = '';

    if (loading) {
      return (
        <LoadingDivContainer />
      )
    } else {
      title = <span>Share Library Content</span>;
      subheading = "Select content from the library.";

      return (
        <div className={classes.divContainer}>
          <Typography variant="h6">
            {title}
          </Typography>
          <Typography variant="subtitle1" className={`${classes.modalSubheading} ${classes.modalSubheadingTable}`}>
            {subheading}
          </Typography>
          {this.libraryGrid()}
          <Button className={`${classes.button} ${classes.modalButton}`} variant="contained" type="submit" color="secondary" onClick={this.submitShareLibraryContentModal}>Select</Button>
        </div>
      )
    }
  }

  render() {
    const { openShareLibraryContentModal, classes } = this.props;

    return (
      <Modal
        id="add-to-modal"
        aria-labelledby="share-library-content"
        aria-describedby="share-library-content"
        open={openShareLibraryContentModal}
        onClose={() => this.closeShareLibraryContentModal()}
      >
        <div className={`${classes.modal} ${classes.mediumModal}`}>
          <IconButton 
            className={classes.iconClose}
            onClick={() => this.closeShareLibraryContentModal()}
          >
            <Close />
          </IconButton>
          {this.handleModalBody()}
        </div>
      </Modal>
    )
  }
}

function mapStateToProps(state) {
  if (_.isEmpty(state.login.token)) {
    return {}
  }

  const appUser = handleAppUser(state)

  return { 
    token: state.login.token,
    companyId: state.login.company._id,
    library: state.library,
    appProfile: appUser ? state.appProfile : {}
  };
}

const mapDispatchToProps = dispatch => ({
  fetchLibrary: (token, companyId, groups, callback) => dispatch(fetchLibrary(token, companyId, groups, callback))
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ShareLibraryContentModal));