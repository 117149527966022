import Axios from "axios";
import { ROOT_URL } from "../../actions/actions_constants";
import { v4 as uuidv4 } from "uuid";

const base64ToBlob = (base64) => {
  const byteCharacters = atob(base64);
  const byteArray = new Uint8Array(byteCharacters.length);

  for (let i = 0; i < byteCharacters.length; i++) {
    byteArray[i] = byteCharacters.charCodeAt(i);
  }

  return new Blob([byteArray], { type: "application/pdf" });
};

export const saveEditedPDF = async (
  encodedPDF,
  newFileName,
  token,
  folderId,
  userId,
  companyId,
  onSaveFile
) => {
  const fileNames = newFileName;
  const uuid = uuidv4();
  const fileName = `${newFileName.split(" ")[0] + "_" + uuid}.pdf`;

  const blob = base64ToBlob(encodedPDF);
  const file = new File([blob], fileName, {
    type: "application/pdf",
  }); // Change filename and type accordingly

  const formData = new FormData();
  formData.append("file", file);

  try {
    const response = await Axios({
      method: "post",
      url: `${ROOT_URL}upload_editable_pdf`,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    console.log(response.data);

    const response2 = await Axios({
      method: "post",
      url: `${ROOT_URL}libraries/${folderId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: [
        {
          filename: fileName,
          personal: userId,
          file_type: "pdf",
          name: fileNames,
          order: 1,
          isNew: false,
          localFolder: `${userId}/uploadfiles/`,
          tempId: "EEFA0ED0-0398-838C-CB7B-4464017267A2",
          isTemp: true,
          link: false,
          owners: [userId],
          editable_pdf: true,
          mimeType: "application/pdf",
          path: `https://d3ll6i7hde758h.cloudfront.net/useruploads/${fileName}`,
          folderId: folderId,
          file_size: 83169,
          localFilename: fileName,
          aws_key: `useruploads/${fileName}`,
          company: companyId,
          aws_bucket: "solo-uploads",
        },
      ],
    });
    console.log(response2);
    onSaveFile();
  } catch (error) {
    console.error("Error uploading file:", error);
  }
};
