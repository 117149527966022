import axios from "axios";
import { ROOT_URL } from "./actions_constants";

export const FETCH_ROOM_DEMOS = "fetch_room_demos";
export const FETCH_ROOM_DEMO = "fetch_room_demo";
export const FETCH_ROOM_DEMOS_ANALYTICS = "fetch_room_demos_analytics";
export const CREATE_ROOM_DEMO = "create_room_demo";
export const UPDATE_ROOM_DEMO = "update_room_demo";
export const ROOM_DEMO_CLOSED = "room_demo_closed";
export const ROOM_DEMO_EVENT = "room_demo_event";
export const DELETE_ROOM_DEMO = "delete_room_demo";

const fetchRoomDemosSuccess = roomDemos => ({
  type: FETCH_ROOM_DEMOS,
  payload: roomDemos
});

export const fetchRoomDemos = (token, companyId, callback) => (dispatch) => {

  const TOKEN = token;

  return (axios.get(`${ROOT_URL}company/room_demos/${companyId}`, 
    { 
      headers: { 
        Authorization: 'Bearer ' + TOKEN
      }
    }))
    .then(result => {
      const roomDemos = result.data;

      dispatch(fetchRoomDemosSuccess(roomDemos));

      if(callback) {
        callback(result);
      }
      
      return result;
    })
    .catch(error => {
      console.log(error)
      
      if (error.response && error.response.data) {
        callback(error.response.data)
      }
    })
}

const fetchRoomDemoSuccess = roomDemo => ({
  type: FETCH_ROOM_DEMO,
  payload: roomDemo
});

export const fetchRoomDemo = (link_udid, preview, callback) => (dispatch) => {

  let link = 'room_demo';

  if (preview) {
    link = 'room_demo_preview';
  } 

  return (axios.get(`${ROOT_URL}${link}/${link_udid}`))
    .then(result => {
      const roomDemo = result.data;

      dispatch(fetchRoomDemoSuccess(roomDemo));

      if(callback) {
        callback(result);
      }
      
      return result;
    })
    .catch(error => {
      console.log(error)
      
      if (error.response && error.response.data) {
        callback(error.response.data)
      }
    })
}

const fetchRoomDemosAnalyticsSuccess = roomDemo => ({
  type: FETCH_ROOM_DEMOS_ANALYTICS,
  payload: roomDemo
});

export const fetchRoomDemosAnalytics = (token, companyId, callback) => (dispatch) => {

  const TOKEN = token;

  return (axios.get(`${ROOT_URL}company/room_demos_analytics/${companyId}`, 
    { 
      headers: { 
        Authorization: 'Bearer ' + TOKEN
      }
    }))
    .then(result => {
      const roomDemoAnalytics = result.data;

      dispatch(fetchRoomDemosAnalyticsSuccess(roomDemoAnalytics));

      if(callback) {
        callback(result);
      }
      
      return result;
    })
    .catch(error => {
      console.log(error)
      
      if (error.response && error.response.data) {
        callback(error.response.data)
      }
    })
}

const createRoomDemoSuccess = roomDemo => ({
  type: CREATE_ROOM_DEMO,
  payload: roomDemo
});

export const createRoomDemo = (token, values, callback) => (dispatch) => {

  const TOKEN = token;

  return (axios.post(`${ROOT_URL}room_demos`,
      values,
      { 
        headers: { 
          Authorization: 'Bearer ' + TOKEN
        }
      }))
    .then(result => {
      const roomDemo = result.data;

      dispatch(createRoomDemoSuccess(roomDemo));

      if(callback) {
        callback(result);
      }

      return result;
    })
    .catch(error => {
      console.log(error)
      
      if (error.response && error.response.data) {
        callback(error.response.data)
      }
    })
}

const updateRoomDemoSuccess = roomDemo => ({
  type: UPDATE_ROOM_DEMO,
  payload: roomDemo
});

export const updateRoomDemo = (token, values, id, callback) => (dispatch) => {

  const TOKEN = token;

  return (axios.put(`${ROOT_URL}room_demos/${id}`, 
    values,
    { 
      headers: { 
        Authorization: 'Bearer ' + TOKEN
      }
    }))
    .then(result => {
      const roomDemo = result.data;

      dispatch(updateRoomDemoSuccess(roomDemo));

      if(callback) {
        callback(result);
      }
      
      return result;
    })
    .catch(error => {
      console.log(error)
      
      if (error.response && error.response.data) {
        callback(error.response.data)
      }
    })
}

const roomDemoEventSuccess = roomDemo => ({
  type: ROOM_DEMO_EVENT,
  payload: roomDemo
});

export const roomDemoEvent = (values, callback) => (dispatch) => {

  return (axios.post(`${ROOT_URL}room_demo_event`))
    .then(result => {
      const roomDemo = result.data;

      dispatch(roomDemoEventSuccess(roomDemo));

      if(callback) {
        callback(result);
      }

      return result;
    })
    .catch(error => {
      console.log(error)
      
      if (error.response && error.response.data) {
        callback(error.response.data)
      }
    })
}

const roomDemoClosedSuccess = roomDemo => ({
  type: ROOM_DEMO_CLOSED,
  payload: roomDemo
});

export const roomDemoClosed = (duration, callback) => (dispatch) => {

  return (axios.get(`${ROOT_URL}room_demo_closed/${duration}`))
    .then(result => {
      const roomDemo = result.data;

      dispatch(roomDemoClosedSuccess(roomDemo));

      if(callback) {
        callback(result);
      }

      return result;
    })
    .catch(error => {
      console.log(error)
      
      if (error.response && error.response.data) {
        callback(error.response.data)
      }
    })
}

const deleteRoomDemoSuccess = roomDemo => ({
  type: DELETE_ROOM_DEMO,
  payload: roomDemo
});

export const deleteRoomDemo = (token, id, callback) => (dispatch) => {

  const TOKEN = token;

  return (axios.delete(`${ROOT_URL}room_demos/${id}`, 
    { 
      headers: { 
        Authorization: 'Bearer ' + TOKEN
      }
    }))
    .then(result => {
      const roomDemo = result.data;

      dispatch(deleteRoomDemoSuccess(roomDemo));

      if(callback) {
        callback(result);
      }
      
      return result;
    })
    .catch(error => {
      console.log(error)
      
      if (error.response && error.response.data) {
        callback(error.response.data)
      }
    })
}
