import _ from "lodash";
import React, { PureComponent } from "react";
import { compose } from 'redux';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { withStyles } from '@material-ui/core/styles';
import { styles } from '../styles';

// import { Grid, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import WithLoading from '../subcomponents/withLoading';
import GridContainer from '../subcomponents/gridContainer';
import DrillDown from '../modals/drillDown';

import { analyticsContentTableHeight, numberSort, activeCampaignEvent } from '../helpers/helperFunctions';
import { multipleFilter, libraryNameSort } from '../helpers/gridLibraryHelpers';

import { fetchViewRate, fetchContentAnalytics  } from "../../actions/actions_index";

class Content extends PureComponent {
  state = {
    openDrillDown: false,
    selectedDetailId: null,
    selectedDetailName: null,
    sorting: [{ columnName: 'totalViewsAfter', direction: 'desc' }]
  }

  handleSortingChange = sorting => {
    this.setState({
      sorting
    })
  }

  componentWillReceiveProps(newProps) {
    if (!_.isEqual(this.props.postData, newProps.postData)) {
      newProps.loadData(newProps.postData);
    }
  }

  rowClick = (event) => {
    const { contentAnalytics } = this.props
    const rows = contentAnalytics.contentData
    const { _id, name } = rows[event[0]]

    this.setState({
      openDrillDown: true,
      selectedDetailId: _id,
      selectedDetailName: name
    })
  }

  closeDrillDown = () => {
    this.setState({
      openDrillDown: false
    })
  }

  handleDrillDown = () => {
    const { openDrillDown, selectedDetailId, selectedDetailName } = this.state
    const { postData } = this.props

    if (openDrillDown) {
      return (
        <DrillDown
          type="content_details"
          openDrillDown={openDrillDown}
          selectedDetailId={selectedDetailId}
          selectedDetailName={selectedDetailName}
          postData={postData}
          closeDrillDown={this.closeDrillDown}
        />
      )
    }
  }

  render() {
    const { enterprise, verb_live, page, postData, contentAnalytics, viewRate, classes } = this.props,
          { sorting } = this.state

    const rows = contentAnalytics.contentData;

    const columns = [
      { name: 'filename', title: 'Name/Filename', getCellValue: row => ({name: row.name, filename: row.filename})},
      { name: 'updatedAtDivider', title: 'Last Updated', getCellValue: row => row.updatedAt },
      // { name: 'last_share', title: 'Last Share', getCellValue: row => row.last_share },
      // { name: 'last_view', title: 'Last View', getCellValue: row => row.last_view },
      { name: 'browsesAfter', title: 'Browses', getCellValue: row => row.browses },
      { name: 'shows', title: 'Shows', getCellValue: row => row.shows },
      { name: 'shares', title: 'Shares', getCellValue: row => row.shares },
      { name: 'user_avg_total', title: 'Avg / Total Duration', getCellValue: row => ({avg: row.user_avg_view, total: row.user_total_view}) },
      
      { name: 'totalViewsAfter', title: 'Total Views', getCellValue: row => row.total_media_views },
      { name: 'unique_views', title: 'Unique Views', getCellValue: row => row.unique_media_views },
      { name: 'view_rate', title: 'View Rate', getCellValue: row => row.view_rate },
      { name: 'downloads', title: 'Downloads', getCellValue: row => row.media_downloads },
      { name: 'media_shares', title: 'Re-Shares', getCellValue: row => row.media_shares },
      { name: 'avg_total', title: 'Avg / Total Duration', getCellValue: row => ({avg: row.avg_view, total: row.total_view}) }
    ]

    let columnBands = [
      {
        title: '',
        children: [
          { columnName: 'filename' },
          { columnName: 'updatedAtDivider' }
        ]
      },
      {
        title: 'User Activity',
        children: [
          { columnName: 'browsesAfter' },
          { columnName: 'shows' },
          { columnName: 'shares' },
          { columnName: 'user_avg_total' }
        ],
      },
      {
        title: 'Contact Activity',
        children: [
          { columnName: 'totalViewsAfter' },
          { columnName: 'unique_views' },
          { columnName: 'view_rate' },
          { columnName: 'downloads' },
          { columnName: 'media_shares' },
          { columnName: 'avg_total' }
        ],
      }
    ]

    const tableColumnExtensions = [
      { columnName: 'filename', width: 300 },
      { columnName: 'updatedAtDivider', width: 100 },
      // { columnName: 'last_share', width: 140 },
      // { columnName: 'last_view', width: 140 },
      { columnName: 'browsesAfter', width: 110 },
      { columnName: 'shows', width: 80 },
      { columnName: 'shares', width: 80 },
      { columnName: 'user_avg_total', width: 130 },
      { columnName: 'totalViewsAfter', width: 85 },
      { columnName: 'unique_views', width: 85 },
      { columnName: 'view_rate', width: 70 },
      { columnName: 'downloads', width: 110 },
      { columnName: 'media_shares', width: 80 },
      { columnName: 'avg_total', width: 130 }
    ]

    const sortingStateColumnExtensions = [
      { columnName: 'filename', sortingEnabled: true },
      { columnName: 'updatedAtDivider', sortingEnabled: true },
      // { columnName: 'last_share', sortingEnabled: true },
      // { columnName: 'last_view', sortingEnabled: true },
      { columnName: 'browsesAfter', sortingEnabled: true },
      { columnName: 'shows', sortingEnabled: true },
      { columnName: 'shares', sortingEnabled: true },
      { columnName: 'user_avg_total', sortingEnabled: true },
      { columnName: 'totalViewsAfter', sortingEnabled: true },
      { columnName: 'unique_views', sortingEnabled: true },
      { columnName: 'view_rate', sortingEnabled: true },
      { columnName: 'downloads', sortingEnabled: true },
      { columnName: 'media_shares', sortingEnabled: true },
      { columnName: 'avg_total', sortingEnabled: true }
    ]

    const filteringStateColumnExtensions = [
      { columnName: 'filename', filteringEnabled: true },
      { columnName: 'updatedAtDivider', filteringEnabled: false },
      // { columnName: 'last_share', filteringEnabled: false },
      // { columnName: 'last_view', filteringEnabled: false },
      { columnName: 'browsesAfter', filteringEnabled: false },
      { columnName: 'shows', filteringEnabled: false },
      { columnName: 'shares', filteringEnabled: false },
      { columnName: 'user_avg_total', filteringEnabled: false },
      { columnName: 'totalViewsAfter', filteringEnabled: false },
      { columnName: 'unique_views', filteringEnabled: false },
      { columnName: 'view_rate', filteringEnabled: false },
      { columnName: 'downloads', filteringEnabled: false },
      { columnName: 'media_shares', filteringEnabled: false },
      { columnName: 'avg_total', filteringEnabled: false }
    ]

    const integratedFilteringColumnExtensions = [
      { columnName: 'filename', predicate: multipleFilter }
    ]

    const integratedSortingColumnExtensions = [
      { columnName: 'filename', compare: libraryNameSort },
      { columnName: 'user_avg_total', compare: numberSort },
      { columnName: 'avg_total', compare: numberSort }
    ]

    if (!enterprise && verb_live) {
      columns.splice(2, 0, { name: 'verb_live', title: 'LIVE' })
      tableColumnExtensions.splice(2, 0, { columnName: 'verb_live', width: 80 })
      sortingStateColumnExtensions.splice(2, 0, { columnName: 'verb_live', sortingEnabled: false })
      filteringStateColumnExtensions.splice(2, 0, { columnName: 'verb_live', filteringEnabled: false })

      columnBands = [
        {
          title: '',
          children: [
            { columnName: 'filename' },
            { columnName: 'updatedAtDivider' }
          ]
        },
        {
          title: 'User Activity',
          children: [
            { columnName: 'browsesAfter' },
            { columnName: 'shows' },
            { columnName: 'verb_live' },
            { columnName: 'shares' },
            { columnName: 'user_avg_total' }
          ],
        },
        {
          title: 'Contact Activity',
          children: [
            { columnName: 'totalViewsAfter' },
            { columnName: 'unique_views' },
            { columnName: 'view_rate' },
            { columnName: 'downloads' },
            { columnName: 'media_shares' },
            { columnName: 'avg_total' }
          ],
        }
      ]
    }

    return (
      <Grid item className={classes.topDrilldownPages}>
        
        {this.handleDrillDown()}

        <Grid container spacing={1} className={classes.gridSmallMarginTop}>
          <Grid item xs={1} className={classes.gridAnalyticsSpacer}>
          </Grid>
          <Grid item xs={1} className={classes.gridAnalyticsSpacer}>
          </Grid>
          <Grid item xs={1} className={classes.gridAnalyticsSpacer}>
          </Grid>
          <Grid item xs={1} className={classes.gridAnalyticsSpacer}>
          </Grid>
          <Grid item xs={1} className={classes.gridAnalyticsSpacer}>
          </Grid>
          <Grid item xs={1} className={`${classes.gridNine} ${classes.gridNineSmallPaddingRight}`}>
            <Typography variant="h5" className={classes.smallNumberHeading}>
              Total Assets
            </Typography>
            <div className={`${classes.smallNumberBox} ${classes.contentBox}`}>
              <Typography variant="h3" className={classes.smallNumber}>
                {contentAnalytics.libraryCount ? contentAnalytics.libraryCount : 0}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={1} className={`${classes.gridNine} ${classes.gridNineSmallPaddingLeft}`}>
            <Typography variant="h5" className={classes.smallNumberHeading}>
              Avg Viewing
            </Typography>
            <div className={`${classes.smallNumberBox} ${classes.contentBox}`}>
              <Typography variant="h3" className={`${classes.smallNumber}`}>
                {contentAnalytics.libraryItemSessionsViewAvg && contentAnalytics.libraryItemSessionsViewAvg.averageLibraryItemView ? contentAnalytics.libraryItemSessionsViewAvg.averageLibraryItemView : 0}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={1} className={`${classes.gridNine}`}>
            <Typography variant="h5" className={classes.smallNumberHeading}>
              Browse
            </Typography>
            <div className={`${classes.smallNumberBox} ${classes.sessionsBox}`}>
              <Typography variant="h3" className={`${classes.smallNumber}`}>
                {viewRate.sessionEventCounts.browses ? viewRate.sessionEventCounts.browses : 0}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={1} className={`${classes.gridNine} ${classes.gridNineSmallPaddingRight}`}>
            <Typography variant="h5" className={classes.smallNumberHeading}> 
              Shows
            </Typography>
            <div className={`${classes.smallNumberBox} ${classes.showsBox}`}>
              <Typography variant="h3" className={classes.smallNumber}>
                {viewRate.sessionEventCounts.shows ? viewRate.sessionEventCounts.shows : 0}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={1} className={`${classes.gridNine} ${classes.gridNineSmallPaddingLeft}`}>
            <Typography variant="h5" className={classes.smallNumberHeading}> 
              Shares
            </Typography>
            <div className={`${classes.smallNumberBox} ${classes.showsBox}`}>
              <Typography variant="h3" className={classes.smallNumber}>
                {viewRate.sessionEventCounts.shares ? viewRate.sessionEventCounts.shares : 0}
              </Typography>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={1} className={classes.gridSmallMarginTop}>
          <Grid item xs={1} className={classes.gridAnalyticsSpacer}>
          </Grid>
          <Grid item xs={1} className={classes.gridAnalyticsSpacer}>
          </Grid>
          <Grid item xs={1} className={classes.gridAnalyticsSpacer}>
          </Grid>
          <Grid item xs={1} className={classes.gridAnalyticsSpacer}>
          </Grid>
          <Grid item xs={1} className={classes.gridAnalyticsSpacer}>
          </Grid>
          <Grid item xs={1} className={`${classes.gridNine} ${classes.gridNineSmallPaddingRight}`}>
            <Typography variant="h5" className={classes.smallNumberHeading}> 
              Total Views
            </Typography>
            <div className={`${classes.smallNumberBox} ${classes.viewsBox}`}>
              <Typography variant="h3" className={classes.smallNumber}>
                {viewRate.sessionEventCounts.media_views ? viewRate.sessionEventCounts.media_views : 0}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={1} className={`${classes.gridNine} ${classes.gridNineSmallPaddingRightLeft}`}>
            <Typography variant="h5" className={classes.smallNumberHeading}> 
              Unique Views
            </Typography>
            <div className={`${classes.smallNumberBox} ${classes.viewsBox}`}>
              <Typography variant="h3" className={classes.smallNumber}>
                {viewRate.sessionEventCounts.media_views ? viewRate.unique_media_views : 0}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={1} className={`${classes.gridNine} ${classes.gridNineSmallPaddingRightLeft}`}>
            <Typography variant="h5" className={classes.smallNumberHeading}> 
              View Rate
            </Typography>
            <div className={`${classes.smallNumberBox} ${classes.viewsBox}`}>
              <Typography variant="h3" className={classes.smallNumber}>
                {viewRate.viewRate}%
              </Typography>
            </div>
          </Grid>
          <Grid item xs={1} className={`${classes.gridNine} ${classes.gridNineSmallPaddingRightLeft}`}>
            <Typography variant="h5" className={classes.smallNumberHeading}> 
              Downloads
            </Typography>
            <div className={`${classes.smallNumberBox} ${classes.viewsBox}`}>
              <Typography variant="h3" className={classes.smallNumber}>
                {viewRate.sessionEventCounts.media_downloads ? viewRate.sessionEventCounts.media_downloads : 0}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={1} className={`${classes.gridNine} ${classes.gridNineSmallPaddingLeft}`}>
            <Typography variant="h5" className={classes.smallNumberHeading}> 
              Re-Shares
            </Typography>
            <div className={`${classes.smallNumberBox} ${classes.viewsBox}`}>
              <Typography variant="h3" className={classes.smallNumber}>
                {viewRate.sessionEventCounts.media_shares ? viewRate.sessionEventCounts.media_shares : 0}
              </Typography>
            </div>
          </Grid>
        </Grid>
        <Grid item className={`${classes.analyticsTableArea} ${classes.analyticsContentTableArea}`}>
          <GridContainer 
            tableType={'separation'}
            menuType={'-new'}
            appWebSeparation={'content'}
            rows={rows}
            columns={columns}
            columnBands={columnBands}
            tableColumnExtensions={tableColumnExtensions}
            sortingStateColumnExtensions={sortingStateColumnExtensions}
            sorting={sorting}
            handleSortingChange={this.handleSortingChange}
            filteringStateColumnExtensions={filteringStateColumnExtensions}
            integratedFilteringColumnExtensions={integratedFilteringColumnExtensions}
            integratedSortingColumnExtensions={integratedSortingColumnExtensions}
            showSelectAll={false}
            showSelectionColumn={false}
            showRowDetail={false}
            onSelectionChange={this.rowClick}
            classes={classes}
            //Export to CSV
            page={page}
            postData={postData}
            //END Export to CSV
          />
        </Grid>
      </Grid>
    );
  }
}

const ContentWithLoading = WithLoading(Content);

class ContentContainer extends PureComponent {
  state = {
    loading: true
  }

  loadData = (postData) => {
    const { token } = this.props;

    let dataCalls = [];

    dataCalls.push(
      this.props.fetchViewRate(token, postData, result => {})
    )

    dataCalls.push(
      this.props.fetchContentAnalytics(token, postData, result => {})
    )

    Promise.all(dataCalls)
      .then(result => {
        this.setState({
          loading: false
        })
      })
  }

  componentDidMount() {
    const { token, enterprise, currentUser, location, master_admin } = this.props;

    this.loadData(this.props.postData);

    const values = {
      token,
      master_admin,
      enterprise,
      email: currentUser.email,
      page: location.pathname
    }

    activeCampaignEvent(values)
  }

  render() {
    const loading = this.state.loading;

    return (
      <ContentWithLoading 
        isLoading={loading}
        loadingReport={true}
        loadData={this.loadData}
        {...this.props}
      />
    )
  }
}

function mapStateToProps(state) {
  if (_.isEmpty(state.login.token) || _.isEmpty(state.login.company) || _.isEmpty(state.login.user)) {
    return {
      loggedOut: true
    }
  }

  return { 
    token: state.login.token,
    master_admin: state.login.master_admin,
    companyId: state.login.company._id,
    enterprise: state.company.enterprise,
    verb_live: state.company.verb_live,
    owner: state.login.user._id,
    currentUser: state.login.user,
    currentRole: state.login.user.user_type,
    contentAnalytics: state.analytics.content,
    viewRate: state.analytics.viewRate
  };
}

const mapDispatchToProps = dispatch => ({
  fetchViewRate: (token, values, callback) => dispatch(fetchViewRate(token, values, callback)),
  fetchContentAnalytics: (token, values, callback) => dispatch(fetchContentAnalytics(token, values, callback))
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(ContentContainer)
