import _ from 'lodash';
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

import { withStyles } from '@material-ui/core/styles';
import { styles } from '../styles';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import AddCircle from '@material-ui/icons/AddCircle';
import Class from '@material-ui/icons/Class';
import Link from '@material-ui/icons/Link';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';

import WithLoading from '../subcomponents/withLoading';
import GridContainer from '../subcomponents/gridContainer';
import CampaignModalContainer from '../modals/campaignModal';

import { verifyLoggedIn, menuPermissions, getOffset, contactTableHeight, siteUrl } from '../helpers/helperFunctions';
import { multipleFilter } from '../helpers/gridLibraryHelpers';
import { compareName } from '../helpers/gridUsersHelpers';

import { verifyRole, fetchShowcases } from '../../actions/actions_index';

import { QUICK_ADD_CAMPAIGN } from '../header';
import {  } from '../header';

class Campaigns extends Component {
	
  constructor(props) {
    super(props);

    this.state = {
      rowMenu: false,
      openCampaignModal: false,
      deleteCampaignModal: false,
      selectedCampaignPosition: null,
      selectedCampaignId: null,
      selectedCampaignInitialValues: null,
      selectedContent: null,
      sorting: [{ columnName: 'updatedAt', direction: 'desc' }]
    }

    this.handleSortingChange = sorting => {
      this.setState({
        sorting
      })
    }
  }

  quickCampaign = (quick) => {
    if (quick === QUICK_ADD_CAMPAIGN) {
      this.addCampaign();
    }
  }

  componentDidMount() {
    const { quick } = this.props.match.params;
    this.quickCampaign(quick);
  }

  componentWillReceiveProps(newProps) {
    const { quick } = newProps.match.params;

    this.quickCampaign(quick);
  }

  addCampaign = () => {
    this.setState({
      openCampaignModal: true,
      selectedCampaignId: null
    });
  } 

  //OPEN LIBRARY ITEM MENU
  handleCampaignMenu = event => {

    const element = document.getElementById(event.currentTarget.id);
    const top = getOffset(element).top;
    const left = getOffset(element).left;
    const position = {top: top, left: left};

    this.setState({
      rowMenu: true,
      selectedCampaignPosition: position,
      selectedCampaignId: event.currentTarget.id
    });
    
  }

  copyStringToClipboard = (link) => {
    // navigator.clipboard.writeText(link)

    //this.textArea is working but not .select()
    //to use need to uncomment <textarea> at the bottom
    // this.textArea.focus();
    // this.textArea.select();
    // document.execCommand('copy');
  }

  //CLOSE LIBRARY ITEM MENU AND HANDLE IF SELECTION (PREVIEW, EDIT, or DELETE)
  handleCampaignMenuClose = (type, selectedCampaignId) => {
    const { campaigns } = this.props;

    let openShareModal = false,
        openCampaignModal = false,
        deleteCampaignModal = false,
        selectedCampaignInitialValues = null;

    this.setState({
      selectedCampaignPosition: null
    })

    if (selectedCampaignId) {
      selectedCampaignInitialValues = _.find(campaigns, {_id: selectedCampaignId});
    }

    switch (type) {
      case 'preview':
        window.open(`${siteUrl()}showcase_instance_preview/${selectedCampaignId}`, "_blank");
        break;
      case 'copy':
        const link = `${siteUrl()}showcase/${selectedCampaignId}`;
        navigator.clipboard.writeText(link)

        // this.setState({
        //   linkText: link 
        // })
        // this.copyStringToClipboard(link);

        break;
      case 'create':
        openShareModal = true;
        break;
      case 'edit':
        openCampaignModal = true;
        break;
      case 'delete':
        deleteCampaignModal = true;
        openCampaignModal = true;
        break;
      default:  
        break;
    }

    this.setState({
      rowMenu: false,
      openShareModal: openShareModal,
      openCampaignModal: openCampaignModal,
      deleteCampaignModal: deleteCampaignModal,
      selectedCampaignId: selectedCampaignId,
      selectedCampaignInitialValues: selectedCampaignInitialValues
    });
  }

  //HANDLE CLOSED PREVIEW LIBRARY ITEM MODAL
  closedCampaignModal = (updated) => {
    const { history } = this.props;

    if(updated) {
      const token = this.props.token;
      const companyId = this.props.companyId;

      this.props.fetchShowcases(token, companyId, campaigns => {
        

        //**need to user loading?**
      

      });
    }

    this.setState({
      openCampaignModal: false,
      openShareModal: false,
      deleteCampaignModal: false,
      selectedCampaignId: null,
      selectedCampaignInitialValues: null
    });

    history.push(`/share/campaigns`)
  }

  rowClick = (event) => {
    const { owner, currentRole, campaigns } = this.props;
    const { rowMenu } = this.state;
    const id = campaigns[event[0]]._id;
    const initialValues = campaigns[event[0]];

    console.log(initialValues)
    console.log(owner)

    let openEdit = false;
    openEdit = menuPermissions('campaigns', rowMenu, owner, currentRole, initialValues);

    console.log(openEdit)

    if (openEdit) {
      this.setState({
        selectedCampaignId: id,
        openCampaignModal: true
      })
    }
  }

  handleOpenCampaignModal = () => {
    const { openCampaignModal, selectedCampaignId, deleteCampaignModal } = this.state;

    if (openCampaignModal) {
      return (
        <CampaignModalContainer
          openCampaignModal={openCampaignModal}
          deleteCampaignModal={deleteCampaignModal}
          closedCampaignModal={this.closedCampaignModal}
          selectedCampaignId={selectedCampaignId}
        />
      )
    }
  }

  handleCampaignTable = () => {
    const { currentRole, owner, campaigns, classes } = this.props;
    const { selectedCampaignPosition, selectedCampaignId, sorting } = this.state;
    let rows = campaigns;

    const columns = [
      { name: 'campaign', title: 'Name' },
      { name: 'gate', title: 'Gate Type',  getCellValue: row => {
          switch (row.gate) {
            case '0':
              return 'No Gate'
            case '1':
              return 'Gate at Entry'
            case '2':
              return 'Gate after 30 sec'
            case '3':
              return 'Gate after 1 view'
            case '4':
              return 'Gate after 2 views'
            default:
              return 'No Gate'
          }
        }
      },
      // { name: 'link', title: 'Link', getCellValue: row => `${siteUrl()}/showcase/${row._id}` },
      { name: 'leads', title: 'Leads' },
      { name: 'opens', title: 'Showcase Opens' },
      { name: 'views', title: 'Item Views' },
      { name: 'downloads', title: 'Downloads' },
      // { name: 'reshares', title: 'Re-Shares' },
      { name: 'owner', title: 'Owner', getCellValue: row => {
          let first_name = '',
              last_name = '';

          if (row.user_details && row.user_details.first_name) {
            first_name = row.user_details.first_name;
          } 

          if (row.user_details && row.user_details.last_name) {
            last_name = row.user_details.last_name;
          }

          return ({first_name: first_name, last_name: last_name})
        }
      },
      { name: 'updatedAt', title: 'Last Updated' },
      { name: 'menu', title: ' ', getCellValue: row => {
        return {
          owner: owner,
          currentRole: currentRole
        }
      }},
    ];

    const tableColumnExtensions = [
      { columnName: 'campaign', width: 250 },
      { columnName: 'gate', width: 120 },
      // { columnName: 'link', width: 180 },
      { columnName: 'leads', width: 75 },
      { columnName: 'opens', width: 100 },
      { columnName: 'views', width: 75 },
      { columnName: 'downloads', width: 110 },
      // { columnName: 'reshares', width: 100 },
      { columnName: 'owner', width: 170 },
      { columnName: 'updatedAt', width: 160 },
      { columnName: 'menu', width: 50 }
    ]

    const sortingStateColumnExtensions = [
      { columnName: 'campaign', sortingEnabled: true },
      { columnName: 'gate', sortingEnabled: true },
      // { columnName: 'link', sortingEnabled: false },
      { columnName: 'leads', sortingEnabled: true },
      { columnName: 'opens', sortingEnabled: true },
      { columnName: 'views', sortingEnabled: true },
      { columnName: 'downloads', sortingEnabled: true },
      // { columnName: 'reshares', sortingEnabled: true },
      { columnName: 'owner', sortingEnabled: true },
      { columnName: 'updatedAt', sortingEnabled: true },
      { columnName: 'menu', sortingEnabled: false }
    ]

    const filteringStateColumnExtensions = [
      { columnName: 'campaign', filteringEnabled: true },
      { columnName: 'gate', filteringEnabled: false },
      // { columnName: 'link', filteringEnabled: false },
      { columnName: 'leads', filteringEnabled: false },
      { columnName: 'opens', filteringEnabled: false },
      { columnName: 'views', filteringEnabled: false },
      { columnName: 'downloads', filteringEnabled: false },
      // { columnName: 'reshares', filteringEnabled: false },
      { columnName: 'owner', filteringEnabled: true },
      { columnName: 'updatedAt', filteringEnabled: false },
      { columnName: 'menu', filteringEnabled: false }
    ]

    const integratedFilteringColumnExtensions = [
      { columnName: 'owner', predicate: multipleFilter }
    ]

    const integratedSortingColumnExtensions = [
      { columnName: 'owner', compare: compareName }
    ]

    return (
      <div>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <GridContainer 
              gridHeight={contactTableHeight()}
              rows={rows}
              columns={columns}
              tableColumnExtensions={tableColumnExtensions}
              sortingStateColumnExtensions={sortingStateColumnExtensions}
              sorting={sorting}
              handleSortingChange={this.handleSortingChange}
              filteringStateColumnExtensions={filteringStateColumnExtensions}
              integratedFilteringColumnExtensions={integratedFilteringColumnExtensions}
              integratedSortingColumnExtensions={integratedSortingColumnExtensions}
              showSelectAll={false}
              showSelectionColumn={false}
              showRowDetail={false}
              classes={classes}
              handleMenu={this.handleCampaignMenu}
              menuType={'campaigns'}
              onSelectionChange={this.rowClick}
            />
            <Menu
              anchorPosition={selectedCampaignPosition}
              anchorReference={'anchorPosition'}
              open={Boolean(selectedCampaignPosition)}
              onClose={() => this.handleCampaignMenuClose(null, null)}
            >
              <MenuItem onClick={() => this.handleCampaignMenuClose('preview', selectedCampaignId)}>
                <ListItemIcon>
                  <Class />
                </ListItemIcon>
                Preview
              </MenuItem>
              <MenuItem onClick={() => this.handleCampaignMenuClose('copy', selectedCampaignId)}>
                <ListItemIcon>
                  <Link />
                </ListItemIcon>
                Copy Link
              </MenuItem>
              <MenuItem onClick={() => this.handleCampaignMenuClose('edit', selectedCampaignId)}>
                <ListItemIcon>
                  <Edit />
                </ListItemIcon>
                Edit
              </MenuItem>
              { (currentRole <= '1') &&
                <MenuItem onClick={() => this.handleCampaignMenuClose('delete', selectedCampaignId)}>
                  <ListItemIcon>
                    <Delete />
                  </ListItemIcon>
                  Delete
                </MenuItem>
              }
            </Menu>
          </Grid>
        </Grid>
      </div>
    );
  }

	render() {
	  const { classes } = this.props;

    return (
      <div className={`${classes.root} ${classes.rootExtendedHeader}`}>
        {this.handleOpenCampaignModal()}
        <Typography variant="h4" className={classes.topButtonRow}>
          <Button className={classes.addButton} onClick={this.addCampaign}>
            <AddCircle className={classes.addCircleIcon} />
            {`Create Campaign`}
          </Button>
        </Typography>
        {this.handleCampaignTable()}
        {/*
        <form style={{position: 'absolute', left: -9999, width: 1, height: 1}}>
          <textarea
            ref={(textarea) => this.textArea = textarea}
            value={linkText}
            readOnly
          />
        </form>
        */}
      </div>
    )
    
	}
}

const CampaignsWithLoading = WithLoading(Campaigns)

class CampaignsContainer extends Component {
  state = {
    loading: true
  }

  componentDidMount() {
    const { history, loggedOut, token, companyId, owner } = this.props;

    verifyLoggedIn(history, loggedOut, () => {
      this.props.verifyRole(token, owner, verifyCampaign => {
        
        //depending on role/user -> decides what campaigns to display
        this.props.fetchShowcases(token, companyId, campaigns => {
          this.setState({
            loading: false
          })
        });
      });
    });
  }

  render() {
    const loading = this.state.loading;

    return (
      <CampaignsWithLoading 
        isLoading={loading}
        {...this.props}
      />
    )
  }
}

function mapStateToProps(state) {
  if (_.isEmpty(state.login.token) || _.isEmpty(state.login.company) || _.isEmpty(state.login.user)) {
    return {
      loggedOut: true
    }
  }

  return { 
    token: state.login.token,
    companyId: state.login.company._id,
    currentUser: state.login.user,
    currentRole: state.login.user.user_type,
    owner: state.login.user._id,
    campaigns: state.showcases
  };
}

const mapDispatchToProps = dispatch => ({
  verifyRole: (token, owner, callback) => dispatch(verifyRole(token, owner, callback)),
  fetchShowcases: (token, companyId, callback) => dispatch(fetchShowcases(token, companyId, callback))
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(CampaignsContainer)
