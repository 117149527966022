import React from 'react';
import { SortableHandle } from "react-sortable-hoc";

import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import MoreVert from '@material-ui/icons/MoreVert';
import Star from '@material-ui/icons/Star';
import CheckCircle from '@material-ui/icons/CheckCircle';
import RadioButtonUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import Lock from '@material-ui/icons/Lock';

import { getFileType } from '../helpers/gridLibraryHelpers';
import { menuPermissions, convertToDateString, convertToTimeString, convertToMb, convertDuration } from '../helpers/helperFunctions';

import { energy } from '../../theme';

export const StandardRow = (props) => {
  return (
    <TableRow children={props.children} />
  )
}

export const SeparationRow = (props) => {
  return (
    <TableRow className={props.classes.separationRow} children={props.children} />
  )
}

export const SmallRow = (props) => {
  return (
    <TableRow className={props.classes.smallRow} children={props.children} />
  )
}

const DragHandle = SortableHandle(() => (<div style={{ cursor: 'move', height: '50px', width: '50px' }}><span style={{ position: 'relative', top: 'calc(50% - 9px)' }}>{'::::'}</span></div>))

export const DragCell = ({ value, classes }) => {
  return (
    <TableCell
      className={classes.standardCell}
    >
        <DragHandle />
    </TableCell>
  )
}

export const StandardTitleCell = ({ value, classes, selectByRowClick, onSelectionChange, tableRow }) => (
  <TableCell 
    className={classes.standardCell}
    onClick={e => {
      if (onSelectionChange) {
        return !selectByRowClick ? onSelectionChange([tableRow.rowId]) : null
      }
    }}
  >
      <Typography variant="h6">{value}</Typography>
  </TableCell>
)

export const DividerCell = ({ value, classes, selectByRowClick, onSelectionChange, tableRow }) => (
  <TableCell 
    className={`${classes.standardCell} ${classes.dividerCell}`}
    onClick={e => {
      if (onSelectionChange) {
        return !selectByRowClick ? onSelectionChange([tableRow.rowId]) : null
      }
    }}
  >
    <Typography variant="subtitle1">{value}</Typography>
  </TableCell>
)

export const AfterCell = ({ value, classes, selectByRowClick, onSelectionChange, tableRow }) => (
  <TableCell 
    className={`${classes.standardCell} ${classes.afterCell}`}
    onClick={e => {
      if (onSelectionChange) {
        return !selectByRowClick ? onSelectionChange([tableRow.rowId]) : null
      }
    }}
  >
    <Typography variant="subtitle1">{value}</Typography>
  </TableCell>
)

export const StandardCell = ({ value, classes, tableType, selectByRowClick, onSelectionChange, tableRow }) => {
  let borderClass = null;

  if (tableType === 'small' || tableType === 'iv-analytics') {
    borderClass = classes.noBorder;
  }
  
  return (
    <TableCell
      className={`${classes.standardCell} ${borderClass}`}
      onClick={e => {
        if (onSelectionChange) {
          return !selectByRowClick ? onSelectionChange([tableRow.rowId]) : null
        }
      }}
    >
      <Typography variant="subtitle1">{value}</Typography>
    </TableCell>
  )
}

const handleShareLinkClick = (event) => {
  event.stopPropagation();
}

export const ShareLinkCell = ({ value, classes }) => {
  return (
    <TableCell id="share-link" onClick={event => handleShareLinkClick(event)} className={`${classes.standardCell} ${classes.noCursor}`}><Typography variant="subtitle1">{value}</Typography></TableCell>
  )
}

export const ViewRateCell = ({ value, classes, selectByRowClick, onSelectionChange, tableRow }) => {
  return (
    <TableCell 
      className={classes.standardCell}
      onClick={e => {
        if (onSelectionChange) {
          return !selectByRowClick ? onSelectionChange([tableRow.rowId]) : null
        }
      }}
    >
      <Typography variant="subtitle1">{value}%</Typography>
    </TableCell>
  )
}

export const DurationCell = ({ value, classes, selectByRowClick, onSelectionChange, tableRow }) => {
  let duration = '0 seconds'

  if (value) {
    duration = convertDuration(value)
  }

  return (
    <TableCell 
      className={classes.standardCell}
      onClick={e => {
        if (onSelectionChange) {
          return !selectByRowClick ? onSelectionChange([tableRow.rowId]) : null
        }
      }}
    >
      <Typography variant="subtitle1">{duration}</Typography>
    </TableCell>
  )
}

export const DurationDividerCell = ({ value, classes, selectByRowClick, onSelectionChange, tableRow }) => {
  let duration = '0 seconds'

  if (value) {
    duration = convertDuration(value)
  }

  return (
    <TableCell 
      className={`${classes.standardCell} ${classes.dividerCell}`}
      onClick={e => {
        if (onSelectionChange) {
          return !selectByRowClick ? onSelectionChange([tableRow.rowId]) : null
        }
      }}
    >
      <Typography variant="subtitle1">{duration}</Typography>
    </TableCell>
  )
}

export const NameCell = ({ value, classes, tableType }) => {
  let borderClass = null;

  if (tableType === 'small') {
    borderClass = classes.noBorder;
  }

  return (
    <TableCell className={`${classes.standardCell} ${borderClass}`}><Typography variant="h6">{value.first_name} {value.last_name ? value.last_name: ''}</Typography></TableCell>
  )
}

export const NameDividerCell = ({ value, classes }) => (
  <TableCell className={`${classes.standardCell} ${classes.dividerCell}`}><Typography variant="h6">{value.first_name} {value.last_name ? value.last_name: ''}</Typography></TableCell>
)

export const ThumbnailCell = ({ value, classes }) => {
  if (value) {
    return (
      <TableCell
        className={classes.standardCell}
      >
        <img className={classes.cellThumbnail} src={value} alt={''} />
      </TableCell>
    )
  }

  return (<TableCell className={classes.standardCell}></TableCell>)
}

export const TypeCell = ({ value, classes, selectByRowClick, onSelectionChange, tableRow }) => {
  return (
    <TableCell
      className={classes.standardCell}
      onClick={e => {
        if (onSelectionChange) {
          return !selectByRowClick ? onSelectionChange([tableRow.rowId]) : null
        }
      }}
    >
      {getFileType(value)}
    </TableCell>
  )
}

const handleStatus = (value) => {
  if (value === 'Deactivated') {
    return <span style={{ color: energy }}>Deactivated</span>
  }

  return value
}

export const StatusCell = ({ value, classes, selectByRowClick, onSelectionChange, tableRow }) => {
  return (
    <TableCell
      className={classes.standardCell}
      onClick={e => {
        if (onSelectionChange) {
          return !selectByRowClick ? onSelectionChange([tableRow.rowId]) : null
        }
      }}
    >
      {handleStatus(value)}
    </TableCell>
  )
}

export const FilenameCell = ({ value, classes, selectByRowClick, onSelectionChange, tableRow }) => {
  return (
    <TableCell
      className={classes.standardCell}
      onClick={e => {
        if (onSelectionChange) {
          return !selectByRowClick ? onSelectionChange([tableRow.rowId]) : null
        }
      }}
    >
      <Typography variant="h6">{value.name}</Typography>
      <Typography variant="body1" className={classes.libraryFilename}>{value.filename}</Typography>
      <Typography variant="body1" className={classes.libraryTags}>{value.tags}</Typography>
    </TableCell>
  )
}

export const TagsCell = ({ classes }) => {
  return (
    <TableCell className={classes.standardCell}></TableCell>
  )
}

export const FilenameDividerCell = ({ value, classes, selectByRowClick, onSelectionChange, tableRow }) => {
  return (
    <TableCell 
      className={`${classes.standardCell} ${classes.dividerCell}`}
      onClick={e => {
        if (onSelectionChange) {
          return !selectByRowClick ? onSelectionChange([tableRow.rowId]) : null
        }
      }}
    >
      <Typography variant="h6">{value.name}</Typography><Typography variant="body1">{value.filename}</Typography>
    </TableCell>
  )
}

export const ShareCell = ({ value, enterpriseAppUser, classes, selectByRowClick, onSelectionChange, tableRow, itemsToShare, handleMenuType }) => {
  let selected = false

  if (value) {
    if (enterpriseAppUser && value.locked) {
      return <TableCell><Lock /></ TableCell>
    } else if (enterpriseAppUser && !value.shareable) {
      return <TableCell></ TableCell>
    } else {
      if (itemsToShare.includes(value._id) || itemsToShare.includes(value)) {
        selected = true
      }

      return (
        <TableCell 
          className={`${classes.standardCell} ${classes.shareCell}`}
          style={{cursor: 'pointer'}}
          onClick={e => {
            if (onSelectionChange) {
              return selectByRowClick && !handleMenuType.includes('share') ? null : onSelectionChange([tableRow.rowId])
            }
          }}
        >
          {!selected &&
            <RadioButtonUnchecked 
              variant="extended"
              color="secondary" 
              className={classes.libraryShareIcon}
            >
            </RadioButtonUnchecked>
          }
          {selected &&
            <CheckCircle 
              variant="extended"
              color="secondary" 
              className={`${classes.libraryShareIcon} ${classes.selectedLibraryShareIcon}`}
            >
            </CheckCircle>
          }
        </TableCell>
      )
    }
  } else {
    return <TableCell></ TableCell>
  }
}

export const RatingCell = ({ value, classes, selectByRowClick, onSelectionChange, tableRow }) => {
  return (
    <TableCell 
      className={`${classes.standardCell}`}
      onClick={e => {
        if (onSelectionChange) {
          return !selectByRowClick ? onSelectionChange([tableRow.rowId]) : null
        }
      }}
    >
      {value !== '' ? <Typography variant="subtitle1">{parseInt(value).toFixed(2)} <Star className={classes.starRating} /></Typography> : null}
    </TableCell>
  )
}

export const ContactInfoCell = ({ value, classes }) => {
  return (
    <TableCell className={classes.standardCell}>
      <Typography variant="subtitle1">{value ? value.email : ''}</Typography>
      <Typography variant="subtitle1">{value ? value.phone : ''}</Typography>
    </TableCell>
  )
}

export const AvgTotalCell = ({ value, classes, selectByRowClick, onSelectionChange, tableRow, divider }) => {

  let dividerClass = null

  if (divider) {
    dividerClass = classes.dividerCell
  }

  let avgDuration = '0 seconds',
      totalDuration = '0 seconds'

  if (value.avg) {
    avgDuration = convertDuration(value.avg);
  }

  if (value.total) {
    totalDuration = convertDuration(value.total);
  }

  return (
    <TableCell 
      className={`${classes.standardCell} ${dividerClass}`}
      onClick={e => {
        if (onSelectionChange) {
          return !selectByRowClick ? onSelectionChange([tableRow.rowId]) : null
        }
      }}
    >
      <Typography variant="subtitle1">{avgDuration}</Typography><Typography variant="body1">{totalDuration}</Typography>
    </TableCell>
  )
}

export const SizeCell = ({ value, classes, selectByRowClick, onSelectionChange, tableRow }) => {
  let text = convertToMb(value);

  if (value === 'n/a') {
    text = value; 
  }

  return (
    <TableCell
      className={classes.standardCell}
      onClick={e => {
        if (onSelectionChange) {
          return !selectByRowClick ? onSelectionChange([tableRow.rowId]) : null
        }
      }}
    >
      <Typography variant="subtitle1">{text}</Typography>
    </TableCell>
  )
}

export const DateCell = ({ value, classes, selectByRowClick, onSelectionChange, tableRow }) => {
  let dateString = 'none';

  if (value) {
    dateString = convertToDateString(value);
  }

  return(
    <TableCell
      className={classes.standardCell}
      onClick={e => {
        if (onSelectionChange) {
          return !selectByRowClick ? onSelectionChange([tableRow.rowId]) : null
        }
      }}
    >
      <Typography variant="subtitle1">{dateString}</Typography>
    </TableCell>
  )
}

export const DateCellDivider = ({ value, classes, selectByRowClick, onSelectionChange, tableRow }) => {
  let dateString = 'none';

  if (value) {
    dateString = convertToDateString(value);
  }

  return(
    <TableCell
      className={`${classes.standardCell} ${classes.dividerCell}`}
      onClick={e => {
        if (onSelectionChange) {
          return !selectByRowClick ? onSelectionChange([tableRow.rowId]) : null
        }
      }}
    >
      <Typography variant="subtitle1">{dateString}</Typography>
    </TableCell>
  )
}

export const DateAfterCell = ({ value, classes, selectByRowClick, onSelectionChange, tableRow }) => {
  let dateString = 'none';

  if (value) {
    dateString = convertToDateString(value);
  }

  return(
    <TableCell 
      className={`${classes.standardCell} ${classes.afterCell}`}
      onClick={e => {
        if (onSelectionChange) {
          return !selectByRowClick ? onSelectionChange([tableRow.rowId]) : null
        }
      }}
    >
      <Typography variant="subtitle1">{dateString}</Typography>
    </TableCell>
  )
}

export const DateTimeCell = ({ value, classes }) => {
  const dateString = convertToDateString(value);
  const timeString = convertToTimeString(value);
  
  return(
    <TableCell className={classes.standardCell}><Typography variant="subtitle1">{dateString}</Typography><Typography variant="body1">{timeString}</Typography></TableCell>
  )
}

//NOTE: possibly don't need this since all menus have the same setup, just need to change the name of the JSX
const menuTypeSwitch = (props) => {
  const { _id, file_type } = props.row;
  const { classes, handleMenu } = props;

  return (
    <div>
      <IconButton 
        id={_id}
        data-filetype={file_type}
        aria-label="Action Menu"
        onClick={handleMenu}
        className={classes.iconRight}
      >
        <MoreVert className={`${classes.verticalDots} ${classes.verticalDotsBig}`} />
      </IconButton>
    </div>
  )
}

export const MenuCell = (props) => {
  const { appUser, personalGroupSelected, handleMenuType, row, value, classes } = props
  let show = true

  if (handleMenuType === 'users' || handleMenuType === 'contacts' || handleMenuType === 'messages' || handleMenuType === 'teams' || handleMenuType === 'library' || handleMenuType === 'appProfiles' || handleMenuType === 'share' || handleMenuType === 'campaigns' || handleMenuType.includes('-new')) {
    show = menuPermissions(handleMenuType, false, value.owner, value.currentRole, row, appUser, personalGroupSelected);
  }

  if (show) {
    return (
      <TableCell
        className={`${classes.standardCell} ${classes.menuCell}`}
      >
        {menuTypeSwitch(props)}
      </TableCell>
    )
  }

  return (
    <TableCell className={classes.standardCell}></TableCell>
  )
}

export const handleInteractions = interactions => {
  if (Array.isArray(interactions)) {
    let i = 0

    return interactions.map(interaction => {
      const { name, clicks } = interaction ? interaction : {}
      i++
      return <span key={`${name}-${i}`}>{name} - {clicks} click{clicks > 1 ? 's' : ''}<br/></span>
    })
  }

  return 'none'
}

export const InteractionsCell = ({ value, classes }) => {
  
  return(
    <TableCell className={classes.standardCell}>
      {handleInteractions(value)}
    </TableCell>
  )
}

