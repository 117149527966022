import _ from "lodash";
import React, { Component } from "react";
import { compose } from 'redux';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import QueryString from 'query-string';

import { withStyles } from '@material-ui/core/styles';
import { styles } from '../styles';

import Typography from '@material-ui/core/Typography';

import WithLoading from '../subcomponents/withLoading';

import { checkAlerts, siteUrl, getCoreUrl, handleAppUser } from '../helpers/helperFunctions';

import { verifyToken, fetchUser, fetchLibraryItems, createLibraryItems, updateLibraryItem, shareLibraryItems } from '../../actions/actions_index';

const InteractiveVideo = props => {
  const { company, fromApp, libraryItems, handleLibraryId, handleVideoId, handleEmail, handleTitle, handleDescription, classes } = props
  const extraInteractiveVideoContainer = fromApp ? classes.extraInteractiveVideoContainer : null

  let newMedia = []

  if (libraryItems && libraryItems.length > 0) {
    newMedia = libraryItems.reduce((result, item) => {
      const { _id, description, name, path, custom_thumbnail, big_thumbnail, filename, file_type } = item
      
      if (_id !== handleLibraryId) {
        let newThumbnail = custom_thumbnail ? custom_thumbnail : (big_thumbnail ? big_thumbnail : 'https://orphanedappicons.s3.amazonaws.com/file.png')
        let newPath = file_type === 'link' ? filename.filename : `${siteUrl()}viewer/${_id}`

        const newItem = {
          type: [ "asset_section_1" ],
          asset_id: _id,
          title: name,
          description: description ? description : '',
          url: newPath,
          thumbnail_url: newThumbnail,
          create_on_load: true
        }

        result.push(newItem)
      }

      return result
    }, [])
  }

  // console.log('handleVideoId: ', handleVideoId)

  const mediaConfiguration = JSON.stringify({
    "label": "My Content",
    "media_types": [
      {
        "value": "my media type",
        "key": "asset_section_1"
      }
    ],
    media: newMedia
  })

  const config = JSON.stringify({
    default_form_data: {
      // title: `'${Date.now()}'`,
      title: handleTitle ? handleTitle : '',
      description: handleDescription ? handleDescription : ''
    },
    desired_language: 'en',
    form_data: {
      language_selected: 'en',
      languages: [
        // { key: '', value: 'Select...' },
        // { key: 'en', value: 'English' },
        // { key: 'es', value: 'Spanish' },
      ],
      market_selected: '267',
      markets: [
        // {
        //   flag_url: '',
        //   key: '',
        //   value: 'Select...',
        // },
        // {
        //   flag_url:
        //     'https://sachemshare.mysecureoffice.com/shared/img/flags/us.png',
        //   key: '267',
        //   value: 'United States',
        // },
        // {
        //   flag_url:
        //     'https://sachemshare.mysecureoffice.com/shared/img/flags/us.png',
        //   key: '268',
        //   value: 'Mexico',
        // },
      ],
      styles: [
        {
          default: true,
          icon: 'https://verb-core-configs.s3.amazonaws.com/assets/link.svg',
          name: 'Button',
          options: [
            {
              default: true,
              icon: 'https://verb-core-configs.s3.amazonaws.com/assets/star.svg',
              name: 'Button',
              value: 'icon',
            },
            {
              icon:
                'https://verb-core-configs.s3.amazonaws.com/assets/star-bar.svg',
              name: 'Button & Text',
              value: 'icon_text',
            },
            {
              icon: 'https://verb-core-configs.s3.amazonaws.com/assets/bar.svg',
              name: 'Text',
              value: 'text',
            },
          ],
          value: 'button',
        },
        {
          icon:
            'https://verb-core-configs.s3.amazonaws.com/assets/photo-gray.svg',
          name: 'Photo',
          value: 'photo',
        },
        {
          icon: 'https://verb-core-configs.s3.amazonaws.com/assets/text-gray.svg',
          name: 'Text',
          options: [
            {
              default: true,
              icon:
                'https://verb-core-configs.s3.amazonaws.com/assets/text-gray.svg',
              name: 'No Background',
              value: '',
            },
            {
              icon:
                'https://verb-core-configs.s3.amazonaws.com/assets/text-gray.svg',
              name: 'Background',
              value: '',
            },
          ],
          value: 'text',
        },
        {
          icon:
            'https://verb-core-configs.s3.amazonaws.com/assets/invisible-gray.svg',
          name: 'Invisible',
          options: [
            {
              icon:
                'https://verb-core-configs.s3.amazonaws.com/assets/invisible-square.svg',
              name: 'Rectangle',
              value: 'rectangle',
            },
            {
              default: true,
              icon:
                'https://verb-core-configs.s3.amazonaws.com/assets/invisible-gray.svg',
              name: 'Circle',
              value: 'circle',
            },
          ],
          value: 'invisible',
        },
      ],
      tags: [
        // { key: 'favorite', value: 'Liked' },
        // { key: 'disliked', value: 'Disliked' },
        // { key: 'sachem', value: 'Sachem' },
        // { key: 'social', value: 'Social' },
        // { key: 'training', value: 'Training' },
      ],
      tags_applied: [],
      // user_email: 'ccemail@soundconcepts.com',
      user_email: handleEmail
    },
    overlay: {
      // export enum InteractionOptionsMode {
      //   Viewer = 'viewer',
      //   Editor = 'editor',
      //   Live = 'live',
      //   Attendee = 'attendee',
      // }
      options: {
        mode: 'editor',
      },
    },
    styles_data: {
      delete_icon:
        'https://verb-core-configs.s3.amazonaws.com/assets/transh-can-gray.svg',
      interactions: [
        {
          icon: 'https://verb-core-configs.s3.amazonaws.com/assets/media.svg',
          name: 'Media',
          type: 'media',
        },
        {
          icon: 'https://verb-core-configs.s3.amazonaws.com/assets/link.svg',
          name: 'Link',
          type: 'link',
        },
        {
          icon: 'https://verb-core-configs.s3.amazonaws.com/assets/shop.svg',
          name: 'Shop',
          type: 'buy-now',
        },
        {
          icon: 'https://verb-core-configs.s3.amazonaws.com/assets/social.svg',
          name: 'Social',
          type: 'social',
        },
        {
          icon: 'https://verb-core-configs.s3.amazonaws.com/assets/calendar.svg',
          name: 'Calendar',
          type: 'schedule',
        },
        {
          icon: 'https://verb-core-configs.s3.amazonaws.com/assets/call.svg',
          name: 'Call',
          type: 'call',
        },
        {
          icon: 'https://verb-core-configs.s3.amazonaws.com/assets/email.svg',
          name: 'Email',
          type: 'email',
        },
      ],
      left_icon:
        'https://verb-core-configs.s3.amazonaws.com/assets/chevron-left-white.svg',
      right_icon:
        'https://verb-core-configs.s3.amazonaws.com/assets/chevron-right-white.svg',
    },
    video_data: {
      bucket_name: 'corejs-videos-upload',
      client_id: 'webroot_nctverb',
      user_id: '936179',
      video_id: handleVideoId ? handleVideoId : ''
      // video_id: null
    },
  })

  // player
  // http://player-staging.verb.tech/?client_id=abc&user_id=111&video_id=AMBBxMxGW6x02vNqfA6t22BWqKIJ6uMD00
  // console.log('verb core element: ', window.customElements.get('verb-core'))
        
  return (
    <div className={`${classes.interactiveVideoContainer} ${extraInteractiveVideoContainer}`}>
      <verb-core
        editor-configuration={config}
        media-configuration={mediaConfiguration}
      >
      </verb-core>
    </div>
  )
}

const InteractiveVideoWithLoading = WithLoading(InteractiveVideo)

class InteractiveVideoContainer extends Component {

  constructor(props) {
    const { token, companyId, appUser, currentUser, itemsToShare, location } = props

    super(props);

    const queryParams = QueryString.parse(location.search);
    const { jwt, userId, media, appLibraryId, appVideoId, appTitle, appDescription } = queryParams

    // const jwt = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJfaWQiOiI2MDM2YTFhZjJmNjBmMzVjM2M4YTk0OTcifQ.8l7A9hUyhq66_gK6DqN6Qu5Yf8jUb7JEgaMx5gQ2FwQ'
    // const userId = '6036a1af2f60f35c3c8a9497'
    // const media = decodeURIComponent('6036a1b12f60f35c3c8a949f%2C6036a1b12f60f35c3c8a94a0%2C6036a1b12f60f35c3c8a949d').split(',')

    const handleToken = jwt ? jwt : token,
          handleUserId = userId ? userId : ((currentUser && currentUser._id) ? currentUser._id : null),
          handleItemsToShare = media ? media.split(',') : (itemsToShare ? itemsToShare : []),
          handleAppEditVideo = {
            appLibraryId,
            appVideoId,
            appTitle,
            appDescription
          }

    // const handleItemsToShare = media ? media : itemsToShare

    this.state = {
      loadingItems: true,
      coreComponent: null,
      newLibraryId: null,
      newVideoId: null,
      fromApp: jwt ? true : false,
      handleToken,
      handleUserId,
      handleCompanyId: companyId ? companyId : null,
      handleEmail: currentUser ? currentUser.email : null,
      handleItemsToShare,
      handleAppEditVideo,
      message: null
    }
  }

  loadData = () => {
    const { fetchLibraryItems } = this.props
    const { handleToken, handleItemsToShare } = this.state

    fetchLibraryItems(handleToken, handleItemsToShare, data => { 
      this.setState({
        loadingItems: false
      })

      const coreComponent = document.querySelector('verb-core');
      this.setState({ coreComponent })

      coreComponent.addEventListener('hooks', (event) => {
        this.handleHook(event)
      })
    })
  }

  beforeReload = e => {
    e.returnValue = "All progress will be lost on refresh. Proceed?"; //Chrome shows their own message
  }
  
  componentDidMount() {
    const { currentUser, verifyToken, fetchUser } = this.props;
    const { fromApp, handleToken, handleUserId } = this.state

    window.addEventListener('beforeunload', this.beforeReload)

    if (!currentUser || fromApp) {
      verifyToken(handleToken, handleUserId, (data) => {
        if (data.data) {
          fetchUser(handleToken, handleUserId, (data) => {
            const { company, email } = data.data

            this.setState({
              handleCompanyId: company,
              handleEmail: email
            })
            this.loadData()
          }) 
        }
      })
    } else {
      this.loadData()
    }
  }

  componentDidUnMount() {
    const { coreComponent } = this.state

    if (coreComponent) {
      coreComponent.removeEventListener('hooks')
    }

    window.removeEventListener('beforeunload', this.beforeReload)
  }

  handleHook = (event) => {
    const { appUser, libraryId, createLibraryItems, updateLibraryItem, handleVideoSaved, closedLibraryItemModal, shareLibraryItems, itemsToShare, reloadShareCart, selectedGroupId } = this.props
    const { newLibraryId, fromApp, handleToken, handleCompanyId, handleUserId, handleAppEditVideo } = this.state
    const { appLibraryId } = handleAppEditVideo

    let handleLibraryId = libraryId ? libraryId : appLibraryId
    
    if (!handleLibraryId) {
      handleLibraryId = newLibraryId
    }

    const type = event.detail.type;
    const eventPayload = event.detail.payload;

    console.log(type)
    console.log(eventPayload)

    if (['metadataSaved', 'metadataUpdated'].includes(type)) {
      window.removeEventListener('beforeunload', this.beforeReload)

      const { title, description, thumbnail_url, video_id } = eventPayload.video

      const handleTitle = (title && title !== '') ? title : 'Interactive Video'

      console.log('saved video to verbCORE end')

      let data = {
        company: handleCompanyId,
        owners: [handleUserId],
        interactive_video: true,
        filename: handleTitle,
        name: handleTitle,
        path: video_id,
        description: description,
        thumbnail: thumbnail_url,
        file_type: 'iv',
        groupId: ''
      }

      if (appUser || fromApp) {
        data.personal = handleUserId
        data.personalized_video = true
      }

      console.log('before save or update: ', handleLibraryId)

      if (!handleLibraryId) { 
        createLibraryItems(handleToken, data, selectedGroupId, (data) => {
          console.log('saved to library: ', data)
          const newlyCreatedlibraryId = data.data._id,
                message = fromApp ? 'Video saved in your app. Sync your content and go to My Videos.' : null

          console.log('newlyCreatedlibraryId: ', newlyCreatedlibraryId)

          this.setState({
            newLibraryId: newlyCreatedlibraryId,
            newVideoId: video_id,
            message
          })

          if (handleVideoSaved) {
            handleVideoSaved(appUser)
            
            const newShareItems = [...itemsToShare, newlyCreatedlibraryId]

            shareLibraryItems(newShareItems, () => {
              if (reloadShareCart) {
                reloadShareCart()
              }
            })
          }
        })
      } else {
        const message = (appUser || fromApp) ? 'Video has been updated. Sync your content in the app to see updates.' : null

        const updateData = {
          name: handleTitle,
          description: description,
        }

        updateLibraryItem(handleToken, updateData, handleLibraryId, data => {
          this.setState({
            message
          })

          if (closedLibraryItemModal) {
            closedLibraryItemModal(true)
          }
        })
      }
    }
  }

  render() {
    const { location, libraryId, videoId, title, description, currentUser, libraryItems, containerMessage, classes } = this.props,
          { fromApp, handleEmail, newLibraryId, newVideoId, message, handleAppEditVideo } = this.state,
          { appLibraryId, appVideoId, appTitle, appDescription } = handleAppEditVideo,
          loading = this.state.loadingItems,
          handleMessage = containerMessage ? containerMessage : (message ? message : null)

    let handleLibraryId = libraryId ? libraryId : appLibraryId,
        handleVideoId = videoId ? videoId : appVideoId,
        handleTitle = title ? title : appTitle,
        handleDescription = description ? description : appDescription,
        numSharedItems = libraryItems ? libraryItems.length : 0

    if (!handleLibraryId) {
      handleLibraryId = newLibraryId
    }

    if (!handleVideoId) {
      handleVideoId = newVideoId
    }

    const sharedItemsMessage = <span><span style={{fontWeight: 600}}>{numSharedItems} Content Items</span> available in Interactions > Media</span>,
          starterMessage = <span>{numSharedItems > 0 ? sharedItemsMessage : null}</span>

    const editorTitle = handleLibraryId ? 'Edit Interactive Video' : 'Create Interactive Video',
          fromAppinteractiveVideoMainContainer = fromApp ? classes.fromAppinteractiveVideoMainContainer : null,
          subheading = !handleLibraryId ? starterMessage : null

    return (
      <React.Fragment>
        <Helmet>
          <link rel="stylesheet" href="/interactive_video.css" />
          {/*<link rel="stylesheet" href="https://core-staging.verb.tech/styles.css" />
          <script type="text/javascript" src="https://core-staging.verb.tech/bundle.js"></script>*/}
          <link rel="stylesheet" href="https://core.verb.tech/styles.css" />
          {/*<script type="text/javascript" src="https://deploy-preview-2719--frontend-apps-core.netlify.app/bundle.js"></script>*/}
          <script type="text/javascript" src={`${getCoreUrl()}`}></script>
        </Helmet>
        <div className={`${classes.interactiveVideoMainContainer} ${fromAppinteractiveVideoMainContainer}`}>
          <Typography variant="h4" style={{textAlign: 'center', margin: '5px 0px'}}>
            {editorTitle}
          </Typography>
          {subheading &&
            <Typography variant="subtitle1" className={classes.modalSubheading} style={{maxWidth: '100%', textAlign: 'center', paddingRight: 0, marginBottom: 5}}>
              {subheading}
            </Typography>
          }
          {handleMessage &&
            <div className={classes.topMessageContainer} style={{width: 630}}>
              <Typography className={classes.topMessage} type="body1" color="primary">{handleMessage}</Typography>
            </div>
          }
          <InteractiveVideoWithLoading 
            isLoading={loading}
            {...this.props}
            fromApp={fromApp}
            handleLibraryId={handleLibraryId}
            handleVideoId={handleVideoId}
            handleEmail={handleEmail}
            handleTitle={handleTitle}
            handleDescription={handleDescription}
          />
        </div>
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  if (_.isEmpty(state.login.token) || _.isEmpty(state.login.company) || _.isEmpty(state.login.user)) {
    return {
      currentUser: null,
      loggedOut: true,
      libraryItems: state.fetchLibraryItems
    }
  }

  return { 
    token: state.login.token,
    companyId: state.login.company._id,
    company: state.company,
    enterprise: state.company.enterprise,
    appUser: handleAppUser(state),
    currentUser: state.login.user,
    itemsToShare: state.itemsToShare,
    libraryItems: state.fetchLibraryItems
  };
}

const mapDispatchToProps = dispatch => ({
  verifyToken: (token, userId, callback) => dispatch(verifyToken(token, userId, callback)),
  fetchUser: (token, id, callback) => dispatch(fetchUser(token, id, callback)),
  fetchLibraryItems: (token, values, callback) => dispatch(fetchLibraryItems(token, values, callback)),
  createLibraryItems: (token, files, groupId, callback) => dispatch(createLibraryItems(token, files, groupId, callback)),
  updateLibraryItem: (token, values, id, callback) => dispatch(updateLibraryItem(token, values, id, callback)),
  shareLibraryItems: (items, callback) => dispatch(shareLibraryItems(items, callback))
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(InteractiveVideoContainer)
