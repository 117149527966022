import React from 'react';
import ReactDOM from 'react-dom';

//datepicker
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './custom_datepicker.css';
//end datepicker

import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import theme from './theme';
import Routes from './routes/routes';

import configureStore from './configureStore'

const { store, persistor } = configureStore();
const MOUNT_NODE = document.getElementById("root");

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <MuiThemeProvider theme={theme}>
        <Routes />
      </MuiThemeProvider>
    </PersistGate>
  </Provider>,
  MOUNT_NODE
)

