import { PAGE_LOADING } from "../actions/actions_page_loading";

export default function(state = false, action) {
  switch (action.type) {
    case PAGE_LOADING:

      if (typeof action.payload === 'undefined') {
        return state;
      }

      return action.payload;
    default:
      return state;
  }
}