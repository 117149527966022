import _ from "lodash";
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Field, reduxForm } from "redux-form";

import { withStyles } from '@material-ui/core/styles';
import { styles } from '../styles';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';

import WithLoading from '../subcomponents/withLoading';
import { ErrorText } from '../subcomponents/form_subcomponents/errorText';
import { RenderTextField } from '../subcomponents/form_subcomponents/renderTextField';

import { activeCampaignEvent } from '../helpers/helperFunctions';

import { fetchAllAppProfiles, updateAppProfile, updateCompany } from '../../actions/actions_index';

class OnboardingTeamForm extends Component {
  constructor(props) {
    super(props);

    const appProfileId = this.props.appProfiles[0]._id;

    this.state = {
      appProfileId,
      error: null
    }

    this.props.reset();
  }

  submitOnboardingTeam = (values) => {
    const { token, companyId, owner, updateAppProfile, updateCompany, history } = this.props;
    const { appProfileId } = this.state

    values = {
      company: companyId,
      owners: [owner],
      name: values.name,
      onboarding: true
    }

    updateAppProfile(token, values, appProfileId, () => {
      updateCompany(token, {onboarding_step: 'users'}, companyId, () => {
        history.push('/onboarding/users');
      });
    });
  }

  render() {
    const { handleSubmit, pristine, invalid, classes } = this.props;
    const { error } = this.state;

    return (
      <div className={classes.marginTop}>
        <Typography variant="h1" className={classes.onboardingH1}>
          <div className={classes.onboardingStepNumber}>3</div>Team
        </Typography>
        <Grid container spacing={4} className={classes.gridExtraSpacing} justify="center" alignItems="center">
          <Grid item xs={3}>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle1" className={classes.onboardingSteps}>
              Step <span className={classes.onboardingStepsBold}>3</span> of 6
            </Typography>
            <LinearProgress color="secondary" variant="determinate" value={50.01} />
          </Grid>
          <Grid item xs={3}>
          </Grid>
        </Grid>
        <Grid className={`${classes.onboardingBackgroundReverseContainer} ${classes.gridTagsSpacing}`} container spacing={4} justify="center" alignItems="center">
          <Grid item xs={6} className={`${classes.onboardingGrid} ${classes.onboardingGridLeftTextArea}`}>
            <Typography variant="h2" className={classes.onboardingH2}>
              Let's create your first <span className={classes.onboardingH2Bold}>TEAM</span>
            </Typography>
            <form className={classes.formNoMaxHeight} noValidate autoComplete="off" onSubmit={handleSubmit(values => this.submitOnboardingTeam(values))}>
              <ErrorText className={`${classes.bigError} ${classes.onboardingTextfield}`} touched={true} error={error} />
              <Typography variant="subtitle1" className={`${classes.onboardingSubtitle} ${classes.onboardingSubtitleContentGroups}`}>
                Users belong to teams, this is how you will decide who sees what content in their apps. Team names should follow your existing organizational structure...but, if you won't need mutiple teams, TEAM EVERYONE is a good choice.
              </Typography>
              <Field className={classes.onboardingTextfield} name="name" component={RenderTextField} type="text" label="Team Name" />
              <br />
              <div className={classes.onboardingExtraText}>
                <Typography variant="subtitle1" className={`${classes.onboardingSubtitle} ${classes.onboardingSubtitleContentGroups}`}>
                  <i>If I didn't mention it earlier, you can change all of this later.</i>
                </Typography>
              </div>
              <Button className={`${classes.button} ${classes.onboardingButton}`} variant="contained" color="secondary" type="submit" disabled={pristine || invalid}>Next</Button>
            </form>
          </Grid>
          <Grid item xs={6} className={classes.onboardingGrid}>
            <div className={classes.onboardingImageContainer}>
              <img className={classes.onboardingImage} src="/images/team.png" />
            </div>
          </Grid>
        </Grid> 
      </div>
    )
  }
}

function validate(values, props) {
  const errors = {};
  const requiredFields = [
    'name'
  ];
  
  if (!_.isEmpty(values)) {
    requiredFields.forEach(field => {
      if (!values[field]) {
        errors[field] = 'Required';
      }
    });
  }

  return errors;
}

const OnboardingTeamFormWithLoading = WithLoading(OnboardingTeamForm)

class OnboardingTeamFormContainer extends Component {
  state = {
    loading: true
  }

  componentDidMount() {
    const { token, enterprise, companyId, fetchAllAppProfiles, currentUser, history, master_admin } = this.props;

    fetchAllAppProfiles(token, companyId, appProfile => {
      this.setState({
        loading: false
      })        
    })

    const values = {
      token,
      master_admin,
      enterprise,
      email: currentUser.email,
      page: history.location.pathname
    }

    activeCampaignEvent(values)
  }

  render() {
    const loading = this.state.loading;

    return (
      <OnboardingTeamFormWithLoading
        isLoading={loading}
        {...this.props}
      />
    )
  }
}

function mapStateToProps(state) {
  if (_.isEmpty(state.login.token) || _.isEmpty(state.login.company) || _.isEmpty(state.login.user)) {
    return {
      loggedOut: true
    }
  }

  return { 
    token: state.login.token,
    master_admin: state.login.master_admin,
    companyId: state.login.company._id,
    enterprise: state.login.company.enterprise,
    owner: state.login.user._id,
    currentUser: state.login.user,
    appProfiles: state.appProfiles
  };
}

const mapDispatchToProps = dispatch => ({
  fetchAllAppProfiles: (token, companyId, callback) => dispatch(fetchAllAppProfiles(token, companyId, callback)),
  updateAppProfile: (token, values, id, callback) => dispatch(updateAppProfile(token, values, id, callback)),
  updateCompany: (token, values, id, callback) => dispatch(updateCompany(token, values, id, callback))
});

export const OnboardingTeam =
compose(
  reduxForm({
    form: 'OnboardingTeam',
    validate,
    enableReinitialize: true
  }),
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(OnboardingTeamFormContainer)
