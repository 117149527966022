import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from "redux-form";
import { StripeProvider } from 'react-stripe-elements';

import { withStyles } from '@material-ui/core/styles';
import { styles } from '../styles';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Modal from '@material-ui/core/Modal';

import Close from '@material-ui/icons/Close';

import LoadingDivContainer from '../subcomponents/loadingDiv';

import { stripeAPI } from '../helpers/helperFunctions';
import MyStoreCheckout from '../subcomponents/billing_subcomponents/MyStoreCheckout';
import { ErrorText } from '../subcomponents/form_subcomponents/errorText';

//updateBilling
import { fetchCompany, cancelSubscription } from '../../actions/actions_index';

class BillingForm extends Component {
  constructor(props) {
    super(props);

    const { formError } = this.props;

    this.state = {
      error: formError
    }

    this.props.reset();
  }

  submitBillingModal = (values) => {
    const { companyId, cancelSubscription, handleSubmitBillingModal, handleBillingModal } = this.props;
    const token = this.props.token;

    handleSubmitBillingModal(true, null);

    cancelSubscription(token, companyId, data => {
      if (data.data.errors) {
        let error = null;
        if (data.data.errors.email) {
          error = data.data.errors.email.message;
        }
        handleSubmitBillingModal(false, error)
      } else {
        handleSubmitBillingModal(false, true);
      }
      
      handleBillingModal(false, true)
    });

    // if (values.confirm_password) {
    //   delete values.confirm_password;
    // }

    // if (selectedBillingId && !cancelSubscription) {
    //   values = cleanPutValues(values);
      
    //   this.props.updateBilling(token, values, selectedBillingId, (data) => {
    //     if (data.data.errors) {
    //       let error = null;
    //       if (data.data.errors.email) {
    //         error = data.data.errors.email.message;
    //       }
    //       submitBillingModal(false, error);
    //     } else {
    //       handleBillingModal(false, true);
    //     }
    //   });

    // } else if (selectedBillingId && cancelSubscription) {
    //   this.props.deleteBilling(token, selectedBillingId, values, () => {
    //     handleBillingModal(false, true);
    //   });

    // } else {

    //   values.company = companyId;
    //   values.enterprise = enterprise;

    //   this.props.createBilling(token, values, selectedTeamId, (data) => {
    //     if (data.data.errors) {
    //       let error = null;
    //       if (data.data.errors.email) {
    //         error = data.data.errors.email.message;
    //       }

    //       submitBillingModal(false, error);
    //     } else {
    //       handleBillingModal(false, true);
    //     }
    //   });

    // } 
  }

  billingForm = () => {
    const { classes } = this.props;
    // const { error } = this.state;

    // if (cancelSubscription) {
      return (
        <div>
          <Typography variant="subtitle1" className={classes.noteSubheading}>
            Canceling your subscription will disable your account when the next billing period starts.
          </Typography>
          <Button className={`${classes.button} ${classes.modalButton}`} style={{maxWidth: 210}} variant="contained" type="submit" color="secondary">Cancel Subscription</Button>
        </div>
      )
    // } else {
    //   return (
    //     <div className={classes.divContainer}>
    //       <ErrorText className={classes.bigError} touched={true} error={error} />
    //       <Grid container spacing={10}>
    //         <Grid item xs={12} className={`${classes.gridBorderRight}`}>
    //           <Field name="first_name" component={RenderTextField} type="text" label="First Name" />
    //           <Field name="last_name" component={RenderTextField} type="text" label="Last Name" />
    //           <Field name="card_number" component={RenderTextField} type="text" label="Card Number" />
    //           <Field name="expiration_date" component={RenderTextField} type="text" label="Expiration Date" />
    //           <Field name="security_code" component={RenderTextField} type="text" label="Security Code" />
    //         </Grid>
    //       </Grid>
    //       <Button className={`${classes.button} ${classes.modalButton}`} variant="contained" color="secondary" type="submit" disabled={pristine || invalid}>Save</Button>
    //     </div>
    //   )
    // }
  }

  render() {
    const { handleSubmit } = this.props; 
    return (
      <form noValidate autoComplete="off" onSubmit={handleSubmit(values => this.submitBillingModal(values))}>
        {this.billingForm()} 
      </form>
    )
  }
}

function validate(values, props) {
  const errors = {};
  let requiredFields = [
    'first_name', 'last_name', 'card_number', 'expiration_date', 'security_code'
  ];
  
  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = 'Required';
    }
  });

  return errors;
}

function mapStateToProps(state) {
  return { 
    token: state.login.token,
    companyId: state.login.company._id,
    enterprise: state.login.company.enterprise,
    owner: state.login.user._id,
    currentRole: state.login.user.user_type
  };
}

const mapDispatchToProps = dispatch => ({
  fetchCompany: (token, id, callback) => dispatch(fetchCompany(token, id, callback)),
  cancelSubscription: (token, id, callback) => dispatch(cancelSubscription(token, id, callback))
});

const BillingFormContainer = reduxForm({
  form: 'Billing',
  validate,
  enableReinitialize: true,
})(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(BillingForm)));


class EditBillingModal extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loadingCompany: true,
      formError: null
    }
  }

  componentDidMount() {
    const { token, companyId } = this.props;

    this.props.fetchCompany(token, companyId, company => {
      this.setState({
        loadingCompany: false
      })
    })
  }

  billingFormContainer = () => {
    const { cancelSubscription, handleBillingModal } = this.props;
    const { formError } = this.state;
    
    return (
      <BillingFormContainer 
        cancelSubscription={cancelSubscription}
        handleSubmitBillingModal={this.handleSubmitBillingModal}
        handleBillingModal={handleBillingModal}
        formError={formError}
      />
    )
  }

  modalSize = () => {
    const { classes } = this.props;
    
    return (
      classes.userDeleteModal
    )
  }

  handleSubmitBillingModal = (loading, error) => {
    this.setState({
      loadingCompany: loading,
      formError: error
    })
  }

  handleModalBody = () => {
    const { billingCycle, billingError, cancel, update, handleBillingModal, classes } = this.props;
    const { loadingCompany } = this.state;

    let title = '',
        subheading = '',
        subheadingClass = classes.modalSubheading;

    if (cancel) {
      
      title = <span>Cancel Subscription?</span>;
      subheading = <span>Are you sure you want to cancel your subscription?</span>;
      
      subheadingClass = classes.modalSubheadingDelete;

    }

    if (loadingCompany) {
      return (
        <LoadingDivContainer 
          type={'small'}
        />
      )
    } else if (cancel) {
      return (
        <div className={classes.divContainer}>
          <Typography variant="h6">
            {title}
          </Typography>
          <Typography variant="subtitle1" className={subheadingClass}>
            {subheading}
          </Typography>
          <ErrorText className={classes.bigError} touched={true} error={billingError} />
          {this.billingFormContainer()}
        </div>
      )
    } else {
      return (
        <StripeProvider apiKey={stripeAPI().key}>
          <MyStoreCheckout billingCycle={billingCycle} update={update} billingError={billingError} handleBillingModal={handleBillingModal} handleSubmitBillingModal={this.handleSubmitBillingModal} />
        </StripeProvider>
      )
    }
  }

  render() {
    const { openBillingModal, handleBillingModal, classes } = this.props;

    return (
      <Modal
        aria-labelledby="edit-billing"
        aria-describedby="edit-billing"
        open={openBillingModal}
        onClose={() => handleBillingModal(false, false)}
      >
        <div className={`${classes.modal} ${this.modalSize()}`}>
          <IconButton 
            className={classes.iconClose}
            onClick={() => handleBillingModal(false, false)}
          >
            <Close />
          </IconButton>
          {this.handleModalBody()}
        </div>
      </Modal>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(EditBillingModal));
