import _ from 'lodash';
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import { styles } from '../styles';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Fab from '@material-ui/core/Fab';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';

import Add from '@material-ui/icons/Add';
import AddCircle from '@material-ui/icons/AddCircle';
import Edit from '@material-ui/icons/Edit';
import RemoveCircleOutline from '@material-ui/icons/RemoveCircleOutline';
import Delete from '@material-ui/icons/Delete';
import Close from '@material-ui/icons/Close';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';

import WithLoading from '../subcomponents/withLoading';
import GridContainer from '../subcomponents/gridContainer';

//Teams
import TeamList from './team/team_list';
import TeamMenu from './team/team_menu';
import TeamModalContainer from '../modals/teamModal';
import AddToTeamModalContainer from '../modals/addToTeamModal';
import { RenderAddRemoveButton } from '../subcomponents/form_subcomponents/renderAddRemoveButton';
//End Teams

//Users Item Modals
import UserModalContainer from '../modals/userModal';
import AddToAppProfileModalContainer from '../modals/addToAppProfileModal';
//End Users Item Modals

import {
  verifyLoggedIn,
  menuPermissions,
  getOffset,
  mainTableHeight,
  convertUserType,
  convertStatus,
  checkAlerts,
  activeCampaignEvent,
  MDandAbove,
  SMandBelow,
} from '../helpers/helperFunctions';
import { multipleFilter, statusFilter } from '../helpers/gridLibraryHelpers';
import { compareName } from '../helpers/gridUsersHelpers';

import {
  verifyRole,
  fetchUsers,
  fetchTeams,
  fetchAppProfiles,
  removeUserFromTeam,
  fetchAlerts,
} from '../../actions/actions_index';

import {
  QUICK_PROFILE,
  QUICK_ADD_APP_USER,
  QUICK_ADD_ADMIN,
  QUICK_ADD_TEAM,
} from '../header';

const ALL_USERS_ID = 'all-users';
const ALL_USERS = 'All Users';
const ADMINS_ID = 'admins';
const APP_USERS_ID = 'app-users';

class Users extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rows: [],
      rowMenu: false,
      selectedTeam: {
        _id: ALL_USERS_ID,
        name: ALL_USERS,
        users: null,
        editable_by: null,
        owners: null,
      },

      addUserType: 'App User',

      //separated to NULL out on close of menu
      selectedTeamMenuElement: null,
      selectedTeamMenu: null,

      openTeamModal: false,
      newTeam: false,
      addToTeam: false,
      deleteTeamModal: false,
      showAddToTeamButton: false,
      openAddToTeamModal: false,
      openAddContentToTeamModal: false,

      openEditUserModal: false,
      openDeleteUserModal: false,

      selectedUserPosition: null,
      selectedUserId: null,
      openEditUploadedItemsModal: false,
      fromEdit: false,
      plusMenu: null,
      bulkEdit: false,
      selectedItems: null,
      selectedUsers: null,
      selectedContent: null,
      sorting: [{ columnName: 'updatedAt', direction: 'desc' }],
      anchor: false,
      clientWidth: 0,
    };

    this.onSelectionChange = (selected) => {
      this.setState({
        selectedItems: selected,
      });
    };

    this.handleSortingChange = (sorting) => {
      this.setState({
        sorting,
      });
    };
  }

  updateWindowSize = () => {
    this.setState({ clientWidth: window.innerWidth });
  };

  //QUICK ADD
  quickUser = (quick) => {
    const { owner } = this.props;
    let type = 'Admin';

    if (quick === QUICK_ADD_APP_USER) {
      type = 'App User';
    }

    if (quick === QUICK_ADD_APP_USER || quick === QUICK_ADD_ADMIN) {
      this.addUser(type);
    } else if (quick === QUICK_ADD_TEAM) {
      this.openAddNewTeamModal();
    } else if (quick === QUICK_PROFILE) {
      this.handleUserMenuClose('edit', owner);
    }
  };

  findRows = (selectedTeam) => {
    const { users, adminsList, appUsersList } = this.props;
    // const { selectedTeam } = this.state;

    let rows = users,
      selectedTeamId = null;

    if (selectedTeam) {
      selectedTeamId = selectedTeam._id;
    }

    if (selectedTeamId === ADMINS_ID) {
      rows = adminsList;
    } else if (selectedTeamId === APP_USERS_ID) {
      rows = appUsersList;
    } else if (selectedTeam && selectedTeam.users) {
      rows = users.filter((user) =>
        selectedTeam.users.some((member) => user._id === member)
      );
    }

    return rows;
  };

  componentDidMount() {
    const { token, enterprise, currentUser, location, master_admin } =
      this.props;
    const { quick } = this.props.match.params;

    this.updateWindowSize();
    window.addEventListener('resize', this.updateWindowSize);

    this.setState({
      rows: this.findRows(),
    });

    this.quickUser(quick);

    const values = {
      token,
      master_admin,
      enterprise,
      email: currentUser.email,
      page: location.pathname,
    };

    activeCampaignEvent(values);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowSize);
  }

  componentWillReceiveProps(newProps) {
    const { quick } = newProps.match.params;
    this.quickUser(quick);
  }
  //END QUICK ADD

  //SECTION: TEAMS
  findTeam = (_id) => {
    const { teams } = this.props;
    const findTeam = _.find(teams, { _id });

    return findTeam;
  };

  selectTeam = (_id) => {
    const selectedTeam = this.findTeam(_id);

    this.setState({
      rows: this.findRows(selectedTeam),
      showAddToTeamButton: true,
      selectedTeam: selectedTeam,
      selectedTeamMenu: selectedTeam,
      addUserType: 'App User',
      selectedItems: null,
    });
  };

  //HANDLE ALL USERS OR TEAM SELECTION
  handleTeamClick = (event, selectedTeam) => {
    const { enterprise } = this.props;

    let _id = null,
      name = null;

    if (event) {
      _id = event.currentTarget.id;
      name = event.currentTarget.getAttribute('name');
    } else {
      _id = selectedTeam._id;
      name = selectedTeam.name;
    }

    let addUserType = 'App User';

    if (_id === ALL_USERS_ID || _id === ADMINS_ID || _id === APP_USERS_ID) {
      if (enterprise && _id === ADMINS_ID) {
        addUserType = 'Admin';
      } else if (!enterprise && _id === ADMINS_ID) {
        addUserType = 'Super Admin';
      }

      const newSelectedTeam = {
        _id: _id,
        name: name,
        users: null,
        editable_by: null,
        owners: null,
      };

      this.setState({
        rows: this.findRows(newSelectedTeam),
        showAddToTeamButton: false,
        selectedTeam: newSelectedTeam,
        addUserType: addUserType,
        selectedItems: null,
        anchor: false,
      });
    } else {
      this.selectTeam(_id);
    }
  };

  //OPEN TEAM MENU
  handleTeamMenu = (event) => {
    const _id = event.currentTarget.id;
    const selectedTeamMenu = this.findTeam(_id);

    this.setState({
      selectedTeamMenuElement: event.currentTarget,
      selectedTeamMenu: selectedTeamMenu,
    });
  };

  //CLOSE TEAM MENU AND HANDLE IF SELECTION (EDIT, ADD, ADD_TEAM, or DELETE)
  handleTeamMenuClose = (type, id = null, fromEdit = false) => {
    const { enterprise } = this.props;
    const newTeam = this.state.newTeam;
    let openTeamModal = false,
      addToTeam = false,
      deleteTeamModal = false,
      openAddToTeamModal = false,
      openAddContentToTeamModal = false,
      newTeamUpdate = false;

    switch (type) {
      case 'edit':
        openTeamModal = true;
        break;

      case 'add-users':
        if (fromEdit) {
          openTeamModal = true;
        }

        openAddToTeamModal = true;
        break;

      case 'add-content':
        if (fromEdit) {
          openTeamModal = true;
        }

        if (!enterprise) {
          openAddContentToTeamModal = true;
        }
        break;

      case 'delete':
        openTeamModal = true;
        deleteTeamModal = true;
        break;

      default:
        break;
    }

    if (newTeam && type === 'add-users') {
      newTeamUpdate = true;
    }

    this.setState({
      selectedTeamMenuElement: false,
      openTeamModal: openTeamModal,
      openAddToTeamModal: openAddToTeamModal,
      openAddContentToTeamModal: openAddContentToTeamModal,
      addToTeam: addToTeam,
      deleteTeamModal: deleteTeamModal,
      newTeam: newTeamUpdate,
      fromEdit: fromEdit,
      plusMenu: null,
    });
  };

  //OPEN ADD NEW TEAM MODAL
  openAddNewTeamModal = () => {
    this.setState({
      openTeamModal: true,
      addToTeam: true,
      selectedTeamMenu: null,
    });
  };

  //HANDLE CLOSED TEAM MODAL
  closedTeamModal = (updated, created, data) => {
    const { history, handleLoadData } = this.props;
    const { selectedTeam } = this.state;

    let selectedTeamUpdate = selectedTeam;
    let showAddToTeamButton = true;

    let deleted = false;

    if (!data) {
      deleted = true;
    }

    if (updated) {
      handleLoadData(false, deleted, () => {
        if (!data) {
          //Deleted Team
          showAddToTeamButton = false;
          selectedTeamUpdate = null;
        } else {
          selectedTeamUpdate = data;
        }

        this.setState({
          openTeamModal: false,
          showAddToTeamButton: showAddToTeamButton,
          deleteTeamModal: false,
          selectedTeam: selectedTeamUpdate,
          selectedTeamMenu: null,
          newTeam: created,
          fromEdit: false,
        });

        if (created) {
          this.handleTeamMenuClose('add-users');
        }

        if (selectedTeamUpdate) {
          this.selectTeam(selectedTeamUpdate._id);
        }
      });
    } else {
      this.setState({
        openTeamModal: false,
        deleteTeamModal: false,
        selectedTeamMenu: null,
        newTeam: false,
        fromEdit: false,
      });
    }

    history.push('/users');
  };

  //HANDLE CLOSED ADD TO TEAM MODAL
  closedAddToTeamModal = (updated, team) => {
    const { handleLoadData } = this.props;
    const { selectedTeam, newTeam } = this.state;

    let selectedTeamUpdate = team;

    if (!updated) {
      selectedTeamUpdate = selectedTeam;
    }

    this.setState({
      selectedTeam: selectedTeamUpdate,
      selectedTeamMenu: null,
      openAddToTeamModal: false,
      openAddContentToTeamModal: false,
    });

    if (newTeam) {
      handleLoadData(false, false, () => {
        this.handleTeamMenuClose('add-content');
        if (selectedTeamUpdate) {
          this.selectTeam(selectedTeamUpdate._id);
        }
      });
    } else if (updated) {
      handleLoadData(false, false, () => {
        if (selectedTeamUpdate) {
          this.selectTeam(selectedTeamUpdate._id);
        }
      });
    }
  };
  //END SECTION: TEAMS

  //SECTION: USERS
  //OPEN LIBRARY USER MENU
  handleUserMenu = (event) => {
    const element = document.getElementById(event.currentTarget.id);
    const top = getOffset(element).top;
    const left = getOffset(element).left;
    const position = { top: top, left: left };

    this.setState({
      rowMenu: true,
      selectedUserPosition: position,
      selectedUserId: event.currentTarget.id,
    });
  };

  //CLOSE USER MENU AND HANDLE IF SELECTION (EDIT, or DELETE)
  handleUserMenuClose = (type, selectedUserId, fromEdit = false) => {
    const { token, enterprise, handleLoadData } = this.props;
    const { selectedTeam } = this.state;

    let openUserModal = false,
      deleteUserModal = false;

    if (fromEdit) {
      openUserModal = true;
    }

    this.setState({
      selectedUserPosition: null,
    });

    switch (type) {
      case 'edit':
        openUserModal = true;
        break;

      case 'remove':
        let values = {
          enterprise: false,
          teamId: selectedTeam._id,
          id: selectedUserId,
        };

        if (enterprise) {
          values.enterprise = true;
        }

        this.props.removeUserFromTeam(token, values, () => {
          handleLoadData(false, false, () => {});
        });

        selectedUserId = null;

        break;

      case 'delete':
        openUserModal = true;
        deleteUserModal = true;
        break;

      default:
        break;
    }

    this.setState({
      rowMenu: false,
      fromEdit: fromEdit,
      openUserModal: openUserModal,
      deleteUserModal: deleteUserModal,
      selectedUserId: selectedUserId,
    });
  };

  //HANDLE CLOSED LIBRARY ITEM MODAL
  closedUserModal = (updated) => {
    const { history, handleLoadData } = this.props;
    const { fromEdit, selectedUserId, selectedTeam } = this.state;
    const selectedTeamId = selectedTeam._id;
    let openEditUserModal = false,
      newSelectedUserId = null;

    if (updated) {
      handleLoadData(false, false, () => {
        // if (selectedTeamId !== ALL_USERS_ID || selectedTeamId !== ADMINS_ID || selectedTeamId !== APP_USERS_ID) {
        //   this.selectTeam(selectedTeamId)
        // }
        this.handleTeamClick(null, selectedTeam);
      });
    }

    if (fromEdit) {
      openEditUserModal = true;
      newSelectedUserId = selectedUserId;
    }

    this.setState({
      fromEdit: false,
      openUserModal: openEditUserModal,
      deleteUserModal: false,
      selectedUserId: newSelectedUserId,
      selectedItems: null,
      selectedUsers: null,
    });

    history.push('/users');
  };

  rowClick = (event) => {
    const { owner, currentRole } = this.props;
    const { rowMenu, selectedTeam } = this.state;
    const rows = this.findRows(selectedTeam);
    const id = rows[event[0]]._id;
    const initialValues = rows[event[0]];

    const openEdit = menuPermissions(
      'users',
      rowMenu,
      owner,
      currentRole,
      initialValues
    );
    openEdit && this.handleUserMenuClose('edit', id);
  };
  //END SECTION: LIBRARY ITEMS

  //SECTION: MODALS

  reCheckAlerts = (callback) => {
    const { token, companyId, enterprise } = this.props;

    if (!enterprise) {
      this.props.fetchAlerts(token, companyId, (data) => {
        callback();
      });
    } else {
      callback();
    }
  };

  //TEAM MODALS
  handleTeamModal = () => {
    const { openTeamModal, addToTeam, deleteTeamModal, selectedTeamMenu } =
      this.state;

    let selectedId = null;

    if (selectedTeamMenu) {
      selectedId = selectedTeamMenu._id;
    }

    if (openTeamModal) {
      return (
        <TeamModalContainer
          openTeamModal={openTeamModal}
          addToTeam={addToTeam}
          deleteTeamModal={deleteTeamModal}
          selectedTeamId={selectedId}
          addRemove={this.handleTeamMenuClose}
          closedTeamModal={this.closedTeamModal}
          reCheckAlerts={this.reCheckAlerts}
        />
      );
    }
  };

  handleAddToTeamModal = () => {
    const { openAddToTeamModal, selectedTeamMenu, selectedTeam } = this.state;

    let selectedId = null;

    if (selectedTeamMenu) {
      selectedId = selectedTeamMenu._id;
    } else if (selectedTeam) {
      selectedId = selectedTeam._id;
    }

    if (openAddToTeamModal) {
      return (
        <AddToTeamModalContainer
          openAddToTeamModal={openAddToTeamModal}
          selectedTeamId={selectedId}
          closedAddToTeamModal={this.closedAddToTeamModal}
          reCheckAlerts={this.reCheckAlerts}
        />
      );
    }
  };

  handleAddContentToTeamModal = () => {
    const {
      openAddContentToTeamModal,
      newTeam,
      selectedTeamMenu,
      selectedTeam,
    } = this.state;
    let selectedId = null;

    if (selectedTeamMenu) {
      selectedId = selectedTeamMenu._id;
    } else if (selectedTeam) {
      selectedId = selectedTeam._id;
    }

    if (openAddContentToTeamModal) {
      return (
        <AddToAppProfileModalContainer
          showcases={null}
          openAddToAppProfileModal={openAddContentToTeamModal}
          newAppProfile={newTeam}
          selectedAppProfileId={selectedId}
          addTeams={false}
          addShowcases={false}
          closedAddToAppProfileModal={this.closedAddToTeamModal}
          reCheckAlerts={this.reCheckAlerts}
        />
      );
    }
  };
  //END TEAM MODALS

  //USER MODALS
  addUser = (type) => {
    this.setState({
      openUserModal: true,
      addUserType: type,
      plusMenu: null,
    });
  };

  handleUserModal = () => {
    const {
      openUserModal,
      addUserType,
      deleteUserModal,
      selectedUserId,
      selectedTeam,
      selectedUsers,
    } = this.state;

    let selectedTeamId = null;

    if (selectedTeam) {
      selectedTeamId = selectedTeam._id;
    }

    if (
      selectedTeamId === ALL_USERS_ID ||
      selectedTeamId === ADMINS_ID ||
      selectedTeamId === APP_USERS_ID
    ) {
      selectedTeamId = null;
    }

    if (openUserModal) {
      return (
        <UserModalContainer
          openUserModal={openUserModal}
          addUserType={addUserType}
          selectedTeamId={selectedTeamId}
          deleteUserModal={deleteUserModal}
          closedUserModal={this.closedUserModal}
          selectedUserId={selectedUserId}
          selectedUsers={selectedUsers}
        />
      );
    }
  };
  //END USER MODALS

  //SECTION: MODALS

  turnOnBulkEdit = () => {
    const bulkEdit = this.state.bulkEdit;

    this.setState({
      bulkEdit: !bulkEdit,
      selectedItems: null,
      selectedUsers: null,
    });
  };

  bulkEdit = (deleteUsers) => {
    const { selectedTeam, selectedItems } = this.state;
    const users = this.findRows(selectedTeam);

    let action = 'edit';

    if (deleteUsers) {
      action = 'delete';
    }

    let selectedUsers = selectedItems.map((value) => {
      return users[value]._id;
    });

    this.setState({
      selectedUsers,
    });

    this.handleUserMenuClose(action);
  };

  handleUserTable = () => {
    const { enterprise, verb_live, currentRole, owner, classes } = this.props;
    const {
      rows,
      selectedUserPosition,
      selectedTeam,
      selectedUserId,
      bulkEdit,
      selectedItems,
      sorting,
    } = this.state;

    const columns = [
      {
        name: 'user_name',
        title: 'Name',
        getCellValue: (row) => ({
          first_name: row.first_name,
          last_name: row.last_name,
        }),
      },
      { name: 'email', title: 'Email' },
      //0: super_admin, 1: admin, 2: app user
      {
        name: 'user_type',
        title: 'User Type',
        getCellValue: (row) => {
          const user_type = convertUserType(row.user_type, enterprise);
          return user_type;
        },
      },
      {
        name: 'status',
        title: 'Status',
        getCellValue: (row) => {
          const status = convertStatus(row.status);
          return status;
        },
      },
      { name: 'updatedAt', title: 'Last Updated' },
      {
        name: 'user_menu',
        title: ' ',
        getCellValue: (row) => {
          return {
            owner: owner,
            currentRole: currentRole,
          };
        },
      },
    ];

    let onSelectionChange = this.rowClick,
      showSelectAll = false,
      showSelectionColumn = false;

    if (bulkEdit) {
      onSelectionChange = this.onSelectionChange;
      showSelectAll = true;
      showSelectionColumn = true;
    }

    const tableColumnExtensions = [
      { columnName: 'user_name', width: 150 },
      { columnName: 'email', width: 200 },
      { columnName: 'user_type', width: 115 },
      { columnName: 'status', width: 115 },
      { columnName: 'updatedAt', width: 120 },
      { columnName: 'user_menu', width: 60 },
    ];

    const sortingStateColumnExtensions = [
      { columnName: 'user_name', sortingEnabled: true },
      { columnName: 'email', sortingEnabled: true },
      { columnName: 'user_type', sortingEnabled: true },
      { columnName: 'status', sortingEnabled: true },
      { columnName: 'updatedAt', sortingEnabled: true },
      { columnName: 'user_menu', sortingEnabled: false },
    ];

    const filteringStateColumnExtensions = [
      { columnName: 'user_name', filteringEnabled: true },
      { columnName: 'email', filteringEnabled: true },
      { columnName: 'user_type', filteringEnabled: true },
      { columnName: 'status', filteringEnabled: true },
      { columnName: 'updatedAt', filteringEnabled: true },
      { columnName: 'user_menu', filteringEnabled: false },
    ];

    // console.log(library);

    const integratedFilteringColumnExtensions = [
      { columnName: 'user_name', predicate: multipleFilter },
      { columnName: 'status', predicate: statusFilter },
    ];

    const integratedSortingColumnExtensions = [
      { columnName: 'user_name', compare: compareName },
    ];

    return (
      <Grid item style={{ position: 'relative', height: '100%' }}>
        <GridContainer
          currentRole={currentRole}
          // gridHeight={mainTableHeight()}
          rows={rows}
          appWebSeparation='teams'
          columns={columns}
          tableColumnExtensions={tableColumnExtensions}
          sortingStateColumnExtensions={sortingStateColumnExtensions}
          sorting={sorting}
          handleSortingChange={this.handleSortingChange}
          filteringStateColumnExtensions={filteringStateColumnExtensions}
          integratedFilteringColumnExtensions={
            integratedFilteringColumnExtensions
          }
          integratedSortingColumnExtensions={integratedSortingColumnExtensions}
          showSelectionColumn={false}
          showRowDetail={false}
          classes={classes}
          handleMenu={this.handleUserMenu}
          menuType={'users-new'}
          bulkEdit={this.turnOnBulkEdit}
          bulkEditOn={bulkEdit}
          onSelectionChange={onSelectionChange}
          selection={selectedItems}
          showSelectAll={showSelectAll}
          showSelectionColumn={showSelectionColumn}
        />

        <Menu
          anchorPosition={selectedUserPosition}
          anchorReference={'anchorPosition'}
          open={Boolean(selectedUserPosition)}
          onClose={() => this.handleUserMenuClose(null, null)}
        >
          <MenuItem
            onClick={() => this.handleUserMenuClose('edit', selectedUserId)}
          >
            <ListItemIcon>
              <Edit />
            </ListItemIcon>
            Edit
          </MenuItem>
          {(enterprise || !verb_live) &&
            selectedTeam &&
            selectedTeam._id !== ALL_USERS_ID && (
              <MenuItem
                onClick={() =>
                  this.handleUserMenuClose('remove', selectedUserId)
                }
              >
                <ListItemIcon>
                  <RemoveCircleOutline />
                </ListItemIcon>
                Remove from Team
              </MenuItem>
            )}
          {currentRole <= '1' && (
            <MenuItem
              onClick={() => this.handleUserMenuClose('delete', selectedUserId)}
            >
              <ListItemIcon>
                <Delete />
              </ListItemIcon>
              Delete from System
            </MenuItem>
          )}
        </Menu>
        {selectedItems && selectedItems.length > 0 && (
          <div className={classes.bulkEditOptionsContainer}>
            <Grid container spacing={4}>
              <Grid item xs={6} style={{ textAlign: 'center' }}>
                <Fab
                  variant='extended'
                  color='secondary'
                  onClick={() => this.bulkEdit(false)}
                  className={`${classes.bulkEditingButton}`}
                >
                  <Edit className={classes.bulkIcon} />
                  <span style={{ paddingLeft: 3 }}>Bulk Edit</span>
                </Fab>
              </Grid>
              <Grid item xs={6} style={{ textAlign: 'center' }}>
                <Fab
                  variant='extended'
                  color='secondary'
                  onClick={() => this.bulkEdit(true)}
                  className={`${classes.bulkEditingButton}`}
                >
                  <Delete className={classes.bulkIcon} />
                  <span style={{ paddingLeft: 3 }}>Bulk Delete</span>
                </Fab>
              </Grid>
            </Grid>
          </div>
        )}
      </Grid>
    );
  };

  handleMenu = (event, menu) => {
    switch (menu) {
      case 'open-plus-menu':
        this.setState({ plusMenu: event.currentTarget });
        break;
      default:
        break;
    }
  };

  handleClose = (type) => {
    this.setState({
      plusMenu: null,
    });
  };

  toggleDrawer = (open) => (event) => {
    this.setState({ anchor: open });
  };

  leftSide = ({
    enterprise,
    verb_live,
    alerts,
    owner,
    currentRole,
    teams,
    adminsListCount,
    deactivatedAdminsListCount,
    appUsersListCount,
    deactivatedUsersListCount,
    selectedId,
    selectedTeamMenuElement,
  }) => (
    <React.Fragment>
      {this.handleTeamModal()}
      {this.handleAddToTeamModal()}
      {this.handleAddContentToTeamModal()}

      <TeamList
        enterprise={enterprise}
        verb_live={verb_live}
        alerts={alerts}
        owner={owner}
        currentRole={currentRole}
        teams={teams}
        adminsListCount={adminsListCount}
        deactivatedAdminsListCount={deactivatedAdminsListCount}
        appUsersListCount={appUsersListCount}
        deactivatedUsersListCount={deactivatedUsersListCount}
        selectedTeamId={selectedId}
        handleTeamMenu={this.handleTeamMenu}
        handleTeamClick={this.handleTeamClick}
        openAddNewTeamModal={this.openAddNewTeamModal}
      />

      {selectedTeamMenuElement && (
        <TeamMenu
          enterprise={enterprise}
          anchorEl={selectedTeamMenuElement}
          open={Boolean(selectedTeamMenuElement)}
          onClose={this.handleTeamMenuClose}
        />
      )}
    </React.Fragment>
  );

  addNewUser = ({
    currentRole,
    selectedId,
    plusMenu,
    addUserType,
    addUserTypeButton,
    clientWidth,
    classes,
  }) => (
    <React.Fragment>
      {currentRole < '2' &&
        (selectedId === ALL_USERS_ID ||
          selectedId === ADMINS_ID ||
          selectedId === APP_USERS_ID) && (
          <Fab
            size='small'
            variant='extended'
            color='secondary'
            aria-label='add'
            aria-owns={plusMenu ? 'plus-menu' : null}
            aria-haspopup='true'
            onClick={() => this.addUser(addUserType)}
            className={`${classes.plusButton}`}
          >
            <Add />
            {clientWidth > 960 ? `Add New ${addUserTypeButton}` : ''}
          </Fab>
        )}
    </React.Fragment>
  );

  render() {
    const {
      company,
      enterprise,
      verb_live,
      alerts,
      currentRole,
      owner,
      teams,
      adminsList,
      appUsersList,
      classes,
    } = this.props;
    const {
      selectedTeam,
      selectedTeamMenuElement,
      showAddToTeamButton,
      addUserType,
      plusMenu,
      anchor,
      clientWidth,
    } = this.state;
    const alertsRoot = checkAlerts(company, alerts, classes);

    let rootExtendedHeader = null;

    if (enterprise) {
      rootExtendedHeader = classes.rootExtendedHeader;
    }

    if (!enterprise) {
      rootExtendedHeader = classes.rootTeamsHeader;
    }

    let selectedId = null,
      selectedName = null,
      toTeam = '';

    if (selectedTeam) {
      selectedId = selectedTeam._id;
      selectedName = selectedTeam.name;
    }

    let addUserTypeButton = 'User';

    if (selectedTeam && selectedTeam._id === ALL_USERS_ID) {
      addUserTypeButton = 'User';
    } else if (addUserType === 'Super Admin') {
      addUserTypeButton = 'Admin';
    } else {
      addUserTypeButton = addUserType;
    }

    if (
      selectedTeam &&
      selectedId !== ALL_USERS_ID &&
      selectedId !== ADMINS_ID &&
      selectedId !== APP_USERS_ID
    ) {
      toTeam = (
        <span>
          &nbsp;to <i>{selectedName}</i>
        </span>
      );
    }

    let adminsListCount,
      appUsersListCount,
      deactivatedAdminsListCount,
      deactivatedUsersListCount;

    if (adminsList) {
      adminsListCount = adminsList.length;
      deactivatedAdminsListCount = adminsList.filter(
        (admin) => !admin.status
      ).length;
    }

    if (appUsersList) {
      appUsersListCount = appUsersList.length;
      deactivatedUsersListCount = appUsersList.filter(
        (user) => !user.status
      ).length;
    }

    return (
      <div className={`${classes.root} ${alertsRoot} ${rootExtendedHeader}`}>
        <Grid container spacing={4} className={classes.mainRightGrid}>
          <SMandBelow>
            <Drawer
              anchor={'left'}
              open={anchor}
              onClose={this.toggleDrawer(false)}
              classes={{
                root: classes.menuDrawerContainer,
                paper: classes.leftBarDrawer,
              }}
            >
              <IconButton
                className={classes.rightBarCloseIcon}
                onClick={this.toggleDrawer(false)}
              >
                <ChevronLeft />
              </IconButton>
              {this.leftSide({
                enterprise,
                verb_live,
                alerts,
                owner,
                currentRole,
                teams,
                adminsListCount,
                deactivatedAdminsListCount,
                appUsersListCount,
                deactivatedUsersListCount,
                selectedId,
                selectedTeamMenuElement,
              })}
            </Drawer>
            <Grid
              item
              sm={1}
              className={`${classes.gridRightBorder} ${classes.leftBarArea}`}
            >
              <IconButton
                onClick={this.toggleDrawer(true)}
                className={classes.leftBarOpenIcon}
              >
                <ChevronRight />
                <Typography variant='h6' className={classes.verticalDrawerName}>
                  User Types
                </Typography>
              </IconButton>
            </Grid>
          </SMandBelow>

          <MDandAbove>
            <Grid item xs={3} className={`${classes.gridRightBorder}`}>
              {this.leftSide({
                enterprise,
                verb_live,
                alerts,
                owner,
                currentRole,
                teams,
                adminsListCount,
                deactivatedAdminsListCount,
                appUsersListCount,
                deactivatedUsersListCount,
                selectedId,
                selectedTeamMenuElement,
              })}
            </Grid>
          </MDandAbove>

          <Grid
            item
            md={9}
            sm={11}
            className={`${classes.gridPaddingLeft} ${classes.rightBarArea}`}
          >
            <Typography variant='h3' className={classes.selectedTeamHeader}>
              <SMandBelow>
                {this.addNewUser({
                  currentRole,
                  selectedId,
                  plusMenu,
                  addUserType,
                  addUserTypeButton,
                  clientWidth,
                  classes,
                })}
              </SMandBelow>

              {selectedName}
              <React.Fragment>
                {currentRole < '2' &&
                  selectedId !== ALL_USERS_ID &&
                  selectedId !== ADMINS_ID &&
                  selectedId !== APP_USERS_ID && (
                    <Fab
                      size='small'
                      variant='extended'
                      color='secondary'
                      aria-label='add'
                      aria-owns={plusMenu ? 'plus-menu' : null}
                      aria-haspopup='true'
                      onClick={(event) =>
                        this.handleMenu(event, 'open-plus-menu')
                      }
                      className={`${classes.plusButton}`}
                    >
                      <Add />
                      Assign to Team
                    </Fab>
                  )}

                <MDandAbove>
                  {this.addNewUser({
                    currentRole,
                    selectedId,
                    plusMenu,
                    addUserType,
                    addUserTypeButton,
                    clientWidth,
                    classes,
                  })}
                </MDandAbove>

                <Menu
                  id='plus-menu'
                  anchorEl={plusMenu}
                  open={Boolean(plusMenu)}
                  onClose={this.handleClose}
                  PopoverClasses={{ paper: classes.quickPaper }}
                >
                  {selectedId !== ALL_USERS_ID &&
                    selectedId !== ADMINS_ID &&
                    selectedId !== APP_USERS_ID &&
                    selectedTeam &&
                    currentRole !== '0' &&
                    selectedTeam.editable_by !== '0' &&
                    !selectedTeam.owners.includes(owner) && (
                      <Typography
                        variant='body1'
                        className={classes.contactAdministrator}
                      >
                        Contact your Administrator for Access to this Team
                      </Typography>
                    )}
                  {selectedId !== ALL_USERS_ID &&
                    selectedId !== ADMINS_ID &&
                    selectedId !== APP_USERS_ID &&
                    selectedTeam &&
                    (currentRole === '0' ||
                      selectedTeam.editable_by === '0' ||
                      selectedTeam.owners.includes(owner)) && (
                      <div>
                        {!enterprise && (
                          <MenuItem classes={{ root: classes.menuItem }}>
                            <RenderAddRemoveButton
                              show={showAddToTeamButton}
                              selectedId={selectedId}
                              addRemove={this.handleTeamMenuClose}
                              type={'add-content'}
                              name={'Content Groups'}
                              subName={selectedName}
                              classes={classes}
                              fromEdit={false}
                            />
                          </MenuItem>
                        )}
                        <MenuItem classes={{ root: classes.menuItem }}>
                          <RenderAddRemoveButton
                            show={showAddToTeamButton}
                            selectedId={selectedId}
                            addRemove={this.handleTeamMenuClose}
                            type={'add-users'}
                            name={'Team Members'}
                            subName={selectedName}
                            classes={classes}
                            fromEdit={false}
                          />
                        </MenuItem>
                      </div>
                    )}
                  {selectedId !== ALL_USERS_ID &&
                    selectedId !== ADMINS_ID &&
                    selectedId !== APP_USERS_ID &&
                    selectedTeam &&
                    (currentRole === '0' ||
                      selectedTeam.editable_by === '0' ||
                      selectedTeam.owners.includes(owner)) && (
                      <MenuItem classes={{ root: classes.menuItem }}>
                        <Button
                          className={`${classes.plusButtonButton}`}
                          onClick={() => this.addUser(addUserType)}
                        >
                          <AddCircle className={classes.addCircleIcon} />
                          Add New {addUserTypeButton}
                          {toTeam}
                        </Button>
                      </MenuItem>
                    )}
                </Menu>
              </React.Fragment>
            </Typography>

            {this.handleUserModal()}
            {this.handleUserTable()}
          </Grid>
        </Grid>
      </div>
    );
  }
}

const UsersWithLoading = WithLoading(Users);

class UsersContainer extends Component {
  state = {
    loading: true,
    adminsList: [],
    appUsersList: [],
  };

  handleLoadData = (mounting, deletedTeam, callback) => {
    const {
      companyId,
      enterprise,
      token,
      fetchUsers,
      fetchTeams,
      fetchAppProfiles,
    } = this.props;
    const { selectedTeam } = this.state;

    let currentSelectedTeam = selectedTeam;
    let selectedTeamId = null;

    if (selectedTeam) {
      selectedTeamId = selectedTeam._id;
    }

    if (deletedTeam) {
      currentSelectedTeam = {
        _id: ALL_USERS_ID,
        name: ALL_USERS,
        users: null,
        editable_by: null,
        owners: null,
      };
      selectedTeamId = ALL_USERS_ID;
    }

    let loadData = [],
      newTeams = [],
      adminsList = [],
      appUsersList = [];

    loadData.push(
      fetchUsers(token, companyId, (users) => {
        adminsList = users.data.filter((user) => user.user_type < '2');
        appUsersList = users.data.filter((user) => user.user_type >= '2');
      })
    );

    if (enterprise) {
      loadData.push(
        fetchTeams(token, companyId, (data) => {
          newTeams = data.data;
        })
      );
    } else {
      loadData.push(
        fetchAppProfiles(token, companyId, (data) => {
          newTeams = data.data;
        })
      );
    }

    Promise.all(loadData).then((result) => {
      if (
        selectedTeamId !== ALL_USERS_ID &&
        selectedTeamId !== ADMINS_ID &&
        selectedTeamId !== APP_USERS_ID
      ) {
        currentSelectedTeam = _.find(newTeams, { _id: selectedTeamId });
      }

      if (mounting) {
        this.setState({
          loading: false,
          adminsList: adminsList,
          appUsersList: appUsersList,
        });
      } else {
        this.setState({
          adminsList: adminsList,
          appUsersList: appUsersList,
          selectedTeam: currentSelectedTeam,
        });
      }

      callback();
    });
  };

  componentDidMount() {
    const { history, loggedOut, token, owner } = this.props;
    // const { type } = this.props.match.params;

    verifyLoggedIn(history, loggedOut, () => {
      this.props.verifyRole(token, owner, (verifyUser) => {
        if (verifyUser.data.user_type < '2') {
          this.handleLoadData(true, false, () => {});
        } else {
          //WHAT TO DO IF APP USER? user_type > 2
          this.setState({
            loading: false,
          });
        }
      });
    });
  }

  render() {
    const { loading, adminsList, appUsersList } = this.state;

    return (
      <UsersWithLoading
        isLoading={loading}
        adminsList={adminsList}
        appUsersList={appUsersList}
        handleLoadData={this.handleLoadData}
        {...this.props}
      />
    );
  }
}

function mapStateToProps(state) {
  if (
    _.isEmpty(state.login.token) ||
    _.isEmpty(state.login.company) ||
    _.isEmpty(state.login.user)
  ) {
    return {
      loggedOut: true,
    };
  }

  const enterprise = state.login.company.enterprise;
  let teams = state.teams;

  if (!enterprise) {
    teams = state.appProfiles;
  }

  return {
    token: state.login.token,
    master_admin: state.login.master_admin,
    companyId: state.login.company._id,
    company: state.company,
    enterprise: state.company.enterprise,
    verb_live: state.company.verb_live,
    alerts: state.alerts,
    owner: state.login.user._id,
    currentUser: state.login.user,
    currentRole: state.login.user.user_type,
    users: state.users,
    teams: teams,
  };
}

const mapDispatchToProps = (dispatch) => ({
  verifyRole: (token, owner, callback) =>
    dispatch(verifyRole(token, owner, callback)),
  fetchUsers: (token, companyId, callback) =>
    dispatch(fetchUsers(token, companyId, callback)),
  fetchTeams: (token, companyId, callback) =>
    dispatch(fetchTeams(token, companyId, callback)),
  fetchAppProfiles: (token, companyId, callback) =>
    dispatch(fetchAppProfiles(token, companyId, callback)),
  removeUserFromTeam: (token, values, callback) =>
    dispatch(removeUserFromTeam(token, values, callback)),
  fetchAlerts: (token, companyId, callback) =>
    dispatch(fetchAlerts(token, companyId, callback)),
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(UsersContainer);
