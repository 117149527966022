import _ from "lodash";
import React, { PureComponent } from "react";
import { compose } from 'redux';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { withStyles } from '@material-ui/core/styles';
import { styles } from '../styles';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import WithLoading from '../subcomponents/withLoading';
import GridContainer from '../subcomponents/gridContainer';
import DrillDown from '../modals/drillDown';

import { analyticsTableHeight, activeCampaignEvent } from '../helpers/helperFunctions';

import { fetchViewRate, fetchContactsAnalytics  } from "../../actions/actions_index";

class Contacts extends PureComponent {
  state = {
    openDrillDown: false,
    selectedDetailId: null,
    selectedDetailName: null,
    sorting: [{ columnName: 'total_media_views', direction: 'desc' }]
  }

  handleSortingChange = sorting => {
    this.setState({
      sorting
    })
  }

  componentWillReceiveProps(newProps) {
    if (!_.isEqual(this.props.postData, newProps.postData)) {
      newProps.loadData(newProps.postData);
    }
  }

  rowClick = (event) => {
    const { contactsAnalytics } = this.props
    const rows = contactsAnalytics.contactData
    const { _id, email, name, phone } = rows[event[0]]

    let displayName = ''

    if (email && email !== '') {
      displayName = email
    } else if (name && name !== '' && name !== ' ') {
      displayName = name
    } else if (phone) {
      displayName = phone
    }

    this.setState({
      openDrillDown: true,
      selectedDetailId: _id,
      selectedDetailName: displayName
    })
  }

  closeDrillDown = () => {
    this.setState({
      openDrillDown: false
    })
  }

  handleDrillDown = () => {
    const { openDrillDown, selectedDetailId, selectedDetailName } = this.state
    const { postData } = this.props

    if (openDrillDown) {
      return (
        <DrillDown
          type="contact_details"
          openDrillDown={openDrillDown}
          selectedDetailId={selectedDetailId}
          selectedDetailName={selectedDetailName}
          postData={postData}
          closeDrillDown={this.closeDrillDown}
        />
      )
    }
  }

  render() {
    const { page, postData, contactsAnalytics, viewRate, classes } = this.props,
          { sorting } = this.state

    const rows = contactsAnalytics.contactData;

    const columns = [
      { name: 'emailDivider', title: 'Contact', getCellValue: row => {
          console.log(row.share_type_text)
          let contact = ''

          if (row.email && row.email !== '') {
            contact = row.email
          } else if (row.name && row.name !== '' && row.name !== ' ') {
            contact = row.name
          } else if (row.phone) {
            contact = row.phone
          }

          return (row.share_type_text ? `${row.share_type_text}: ${contact}` : contact)
        }
      },
      { name: 'lastShareAfter', title: 'Last Share', getCellValue: row => row.last_share },
      { name: 'last_view', title: 'Last View', getCellValue: row => row.last_view },
      { name: 'shows', title: 'Shows', getCellValue: row => row.shows },
      { name: 'shares', title: 'Shares', getCellValue: row => row.shares },
      { name: 'totalAppSessionLengthDivider', title: 'Total Duration', getCellValue: row => row.totalAppSessionLength },
      
      { name: 'totalMediaOpensAfter', title: 'Total Opens', getCellValue: row => row.total_media_opens },
      { name: 'total_media_views', title: 'Total Views', getCellValue: row => row.total_media_views },
      { name: 'unique_views', title: 'Unique Views', getCellValue: row => row.unique_media_views },
      { name: 'view_rate', title: 'View Rate', getCellValue: row => row.view_rate },
      { name: 'media_downloads', title: 'Downloads', getCellValue: row => row.media_downloads },
      { name: 'media_shares', title: 'Re-Shares', getCellValue: row => row.media_shares },
      { name: 'averageMediaSessionLength', title: 'Avg Duration' },
      { name: 'totalMediaSessionLength', title: 'Total Duration' },
    ];

    const columnBands = [
      {
        title: '',
        children: [
          { columnName: 'emailDivider' }
        ]
      },
      {
        title: 'User Activity',
        children: [
          { columnName: 'lastShareAfter' },
          { columnName: 'last_view' },
          { columnName: 'shows' },
          { columnName: 'shares' },
          { columnName: 'totalAppSessionLengthDivider' }
        ],
      },
      {
        title: 'Contact Activity',
        children: [
          { columnName: 'totalMediaOpensAfter' },
          { columnName: 'total_media_views' },
          { columnName: 'unique_views' },
          { columnName: 'view_rate' },
          { columnName: 'media_downloads' },
          { columnName: 'media_shares' },
          { columnName: 'averageMediaSessionLength' },
          { columnName: 'totalMediaSessionLength' }
        ],
      }
    ]

    const tableColumnExtensions = [
      { columnName: 'emailDivider', width: 220 },
      { columnName: 'lastShareAfter', width: 150},
      { columnName: 'last_view', width: 150 },
      { columnName: 'shows', width: 80 },
      { columnName: 'shares', width: 80 },
      { columnName: 'totalAppSessionLengthDivider', width: 95 },

      { columnName: 'totalMediaOpensAfter', width: 90 },
      { columnName: 'total_media_views', width: 80 },
      { columnName: 'unique_views', width: 85 },
      { columnName: 'view_rate', width: 75 },
      { columnName: 'media_downloads', width: 110 },
      { columnName: 'media_shares', width: 80 },
      { columnName: 'averageMediaSessionLength', width: 95 },
      { columnName: 'totalMediaSessionLength', width: 95 }
    ]

    const sortingStateColumnExtensions = [
      { columnName: 'emailDivider', sortingEnabled: true },
      { columnName: 'lastShareAfter', sortingEnabled: true },
      { columnName: 'last_view', sortingEnabled: true },
      { columnName: 'shows', sortingEnabled: true },
      { columnName: 'shares', sortingEnabled: true },
      { columnName: 'totalAppSessionLengthDivider', sortingEnabled: true },

      { columnName: 'totalMediaOpensAfter', sortingEnabled: true },
      { columnName: 'total_media_views', sortingEnabled: true },
      { columnName: 'unique_views', sortingEnabled: true },
      { columnName: 'view_rate', sortingEnabled: true },
      { columnName: 'media_downloads', sortingEnabled: true },
      { columnName: 'media_shares', sortingEnabled: true },
      { columnName: 'averageMediaSessionLength', sortingEnabled: true },
      { columnName: 'totalMediaSessionLength', sortingEnabled: true }
    ]

    const filteringStateColumnExtensions = [
      { columnName: 'emailDivider', filteringEnabled: true },
      { columnName: 'lastShareAfter', filteringEnabled: false },
      { columnName: 'last_view', filteringEnabled: false },
      { columnName: 'shows', filteringEnabled: false },
      { columnName: 'shares', filteringEnabled: false },
      { columnName: 'totalAppSessionLengthDivider', filteringEnabled: false },

      { columnName: 'totalMediaOpensAfter', filteringEnabled: false },
      { columnName: 'total_media_views', filteringEnabled: false },
      { columnName: 'unique_views', filteringEnabled: false },
      { columnName: 'view_rate', filteringEnabled: false },
      { columnName: 'media_downloads', filteringEnabled: false },
      { columnName: 'media_shares', filteringEnabled: false },
      { columnName: 'averageMediaSessionLength', filteringEnabled: false },
      { columnName: 'totalMediaSessionLength', filteringEnabled: false }
    ]

    return (
      <Grid item className={classes.topDrilldownPages}>

        {this.handleDrillDown()} 
        
        <Grid container spacing={1} className={classes.gridSmallMarginTop}>
          <Grid item lg={1} className={`${classes.gridNine} ${classes.gridAnalyticsSpacer}`}>
          </Grid>
          <Grid item lg={1} className={`${classes.gridNine} ${classes.gridNineSmallPaddingRight} ${classes.gridAnalyticsSpacer}`}>
          </Grid>
          <Grid item lg={1} className={`${classes.gridNine} ${classes.gridNineSmallPaddingRightLeft} ${classes.gridAnalyticsSpacer}`}>
          </Grid>
          <Grid item lg={1} className={`${classes.gridNine} ${classes.gridNineSmallPaddingLeft}`}>
            <Typography variant="h5" className={classes.smallNumberHeading}> 
              Total Contacts
            </Typography>
            <div className={`${classes.smallNumberBox} ${classes.contactsBox}`}>
              <Typography variant="h3" className={classes.smallNumber}>
                {contactsAnalytics.contacts ? contactsAnalytics.contacts : 0}
              </Typography>
            </div>
          </Grid>
          <Grid item lg={1} className={classes.gridNine}>
            <Typography variant="h5" className={classes.smallNumberHeading}>
              Avg Session
            </Typography>
            <div className={`${classes.smallNumberBox} ${classes.sessionsBox}`}>
              <Typography variant="h3" className={`${classes.smallNumber}`}>
                {contactsAnalytics.mediaViewerSessionsAvg && contactsAnalytics.mediaViewerSessionsAvg.averageMediaViewerSessionLength ? contactsAnalytics.mediaViewerSessionsAvg.averageMediaViewerSessionLength : 0}
              </Typography>
            </div>
          </Grid>
          <Grid item lg={1} className={`${classes.gridNine} ${classes.gridNineSmallPaddingRight}`}>
            <Typography variant="h5" className={classes.smallNumberHeading}> 
              Shows
            </Typography>
            <div className={`${classes.smallNumberBox} ${classes.showsBox}`}>
              <Typography variant="h3" className={classes.smallNumber}>
                {viewRate.sessionEventCounts.shows ? viewRate.sessionEventCounts.shows : 0}
              </Typography>
            </div>
          </Grid>
          <Grid item lg={1} className={`${classes.gridNine} ${classes.gridNineSmallPaddingLeft}`}>
            <Typography variant="h5" className={classes.smallNumberHeading}> 
              Shares
            </Typography>
            <div className={`${classes.smallNumberBox} ${classes.showsBox}`}>
              <Typography variant="h3" className={classes.smallNumber}>
                {viewRate.sessionEventCounts.shares ? viewRate.sessionEventCounts.shares : 0}
              </Typography>
            </div>
          </Grid>
          <Grid item lg={1} className={`${classes.gridNine} ${classes.gridNineSmallPaddingRight}`}>
            <Typography variant="h5" className={classes.smallNumberHeading}> 
              Total Views
            </Typography>
            <div className={`${classes.smallNumberBox} ${classes.viewsBox}`}>
              <Typography variant="h3" className={classes.smallNumber}>
                {viewRate.sessionEventCounts.media_views ? viewRate.sessionEventCounts.media_views : 0}
              </Typography>
            </div>
          </Grid>
          <Grid item lg={1} className={`${classes.gridNine} ${classes.gridNineSmallPaddingLeft}`}>
            <Typography variant="h5" className={classes.smallNumberHeading}> 
              View Rate
            </Typography>
            <div className={`${classes.smallNumberBox} ${classes.viewsBox}`}>
              <Typography variant="h3" className={classes.smallNumber}>
                {viewRate.viewRate}%
              </Typography>
            </div>
          </Grid>
        </Grid>
        <Grid item className={`${classes.analyticsTableArea} ${classes.analyticsUsersTableArea}`}>
          <GridContainer 
            tableType={'separation'}
            menuType={'-new'}
            appWebSeparation={'contacts'}
            rows={rows}
            columns={columns}
            columnBands={columnBands}
            tableColumnExtensions={tableColumnExtensions}
            sortingStateColumnExtensions={sortingStateColumnExtensions}
            sorting={sorting}
            handleSortingChange={this.handleSortingChange}
            filteringStateColumnExtensions={filteringStateColumnExtensions}
            showSelectAll={false}
            showSelectionColumn={false}
            showRowDetail={false}
            onSelectionChange={this.rowClick}
            classes={classes}
            //Export to CSV
            page={page}
            postData={postData}
            //END Export to CSV
          />
        </Grid>
      </Grid>
    );
  }
}

const ContactsWithLoading = WithLoading(Contacts);

class ContactsContainer extends PureComponent {
  state = {
    loading: true
  }

  loadData = (postData) => {
    const { token } = this.props;
    const loading = this.state.loading;

    if (!loading) {
      this.setState({
        loading: true
      })
    }

    let dataCalls = [];

    dataCalls.push(
      this.props.fetchViewRate(token, postData, result => {})
    )

    dataCalls.push(
      this.props.fetchContactsAnalytics(token, postData, result => {})
    )

    Promise.all(dataCalls)
      .then(result => {
        this.setState({
          loading: false
        })
      })
  }

  componentDidMount() {
    const { token, enterprise, currentUser, location, postData, master_admin } = this.props;

    this.loadData(postData);

    const values = {
      token,
      master_admin,
      enterprise,
      email: currentUser.email,
      page: location.pathname
    }

    activeCampaignEvent(values)
  }

  render() {
    const loading = this.state.loading;

    return (
      <ContactsWithLoading 
        isLoading={loading}
        loadingReport={true}
        loadData={this.loadData}
        {...this.props}
      />
    )
  }
}

function mapStateToProps(state) {
  if (_.isEmpty(state.login.token) || _.isEmpty(state.login.company) || _.isEmpty(state.login.user)) {
    return {
      loggedOut: true
    }
  }

  return { 
    token: state.login.token,
    master_admin: state.login.master_admin,
    companyId: state.login.company._id,
    owner: state.login.user._id,
    currentUser: state.login.user,
    currentRole: state.login.user.user_type,
    contactsAnalytics: state.analytics.contacts,
    viewRate: state.analytics.viewRate
  };
}

const mapDispatchToProps = dispatch => ({
  fetchViewRate: (token, values, callback) => dispatch(fetchViewRate(token, values, callback)),
  fetchContactsAnalytics: (token, values, callback) => dispatch(fetchContactsAnalytics(token, values, callback))
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(ContactsContainer)
