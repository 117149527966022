import _ from "lodash";
import React, { PureComponent } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

import { withStyles } from '@material-ui/core/styles';
import { styles } from '../styles';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';

import AddCircle from '@material-ui/icons/AddCircle';
import Edit from '@material-ui/icons/Edit';
import LibraryBooks from '@material-ui/icons/LibraryBooks';

import WithLoading from '../subcomponents/withLoading';
import BrandingModalContainer from '../modals/brandingModal';

import { torquoise, teal, energy, notBlack } from '../../theme';

import { verifyLoggedIn, menuPermissions } from '../helpers/helperFunctions';

import { verifyRole, fetchBrandings } from '../../actions/actions_index';

import { QUICK_ADD_BRANDING } from '../header';

class Brandings extends PureComponent {
  state = {
    openBrandingModal: false,
    deleteBrandingModal: false,
    menuElement: null,
    selectedBrandingId: null,
    addTeams: false,
    fromEdit: false,
    fromView: false
  }

  quickBranding = (quick) => {
    if (quick === QUICK_ADD_BRANDING) {
      this.addBranding();
    }
  }

  componentDidMount() {
    const { quick } = this.props.match.params;
    this.quickBranding(quick);
  }

  componentWillReceiveProps(newProps) {
    const { quick } = newProps.match.params;
    this.quickBranding(quick);
  }

  addBranding = () => {
    this.setState({
      openBrandingModal: true
    });
  } 

  //OPEN LIBRARY ITEM MENU
  handleBrandingMenu = event => {
    event.stopPropagation();

    const id = event.currentTarget.getAttribute('_id');
    const element = event.currentTarget;

    this.setState({
      menuElement: element,
      selectedBrandingId: id
    });
  }

  //CLOSE LIBRARY ITEM MENU AND HANDLE IF SELECTION (PREVIEW, EDIT, or DELETE)
  handleBrandingMenuClose = (type, selectedBrandingId, fromEdit=false, fromView=false, event) => {

    let openBrandingModal = false,
        deleteBrandingModal = false,
        addTeams = false;

    if (!selectedBrandingId && event) {
      selectedBrandingId = event.currentTarget.getAttribute('_id');
    }

    this.setState({
      menuElement: null
    })

    switch (type) {
      case 'edit':
        openBrandingModal = true;
        break;
      
      case 'delete':
        openBrandingModal = true;
        deleteBrandingModal = true;
        break;
      
      default:  
        break;
    }

    this.setState({
      openBrandingModal: openBrandingModal,
      deleteBrandingModal: deleteBrandingModal,
      selectedBrandingId: selectedBrandingId,
      addTeams: addTeams,
      fromEdit: fromEdit,
      fromView: fromView
    });

  }

  //HANDLE CLOSED CREATE/EDIT/DELETE BRANDING MODAL
  closedBrandingModal = (updated, created, data) => {
    const { history } = this.props;
    const token = this.props.token;
    const companyId = this.props.companyId;

    if (updated) {      
      this.props.fetchBrandings(token, companyId, results => {

        this.setState({ 
          openBrandingModal: false,
          deleteBrandingModal: false,
          selectedBrandingId: null,
          fromEdit: false,
          fromView: false
        });

      });

    } else {
      this.setState({ 
        openBrandingModal: false,
        deleteBrandingModal: false,
        selectedBrandingId: null,
        fromEdit: false,
        fromView: false
      });
    }

    history.push('/content/brands');
  }

  handleOpenBrandingModal = () => {
    const { openBrandingModal, deleteBrandingModal, selectedBrandingId } = this.state;

    if (openBrandingModal) {
      return (
        <BrandingModalContainer 
          openBrandingModal={openBrandingModal}
          deleteBrandingModal={deleteBrandingModal}
          selectedBrandingId={selectedBrandingId}
          viewTeamsLibraryGroups={this.handleBrandingMenuClose}
          closedBrandingModal={this.closedBrandingModal}
        />
      )
    }
  }

  brandingClick = (event) => {
    const { owner, currentRole, brandings } = this.props;
    const _id = event.currentTarget.getAttribute('_id');
    const initialValues = _.find(brandings, {_id});

    const openEdit = menuPermissions('brandings', false, owner, currentRole, initialValues);
    openEdit && this.handleBrandingMenuClose('edit', _id);
  }

  handleBrandings = () => {
    const { owner, currentRole, brandings, classes } = this.props;

    return (
      brandings.map(branding => (
        <Grid key={branding._id} item xs={12} sm={6} md={4} lg={4} xl={3}>
          <div className={`${classes.appProfileTitleArea} clearfix`}>
            <Typography _id={branding._id} className={classes.appProfileName} gutterBottom variant="h4">
              {branding.name}
            </Typography>
          </div>
          <Card
            id={branding._id}
            key={branding._id}
            className={`${classes.card} ${classes.appProfileCard} appProfileCardAfter`}
          >
            <div className={classes.cardMediaContainer}>
              {branding.branding_background_image &&
                <CardMedia
                  _id={branding._id}
                  className={classes.cardMedia}
                  component="img"
                  alt={branding.name}
                  image={branding.branding_background_image}
                  title={branding.name}
                  onClick={event => this.brandingClick(event)}
                />
              }
              {!branding.branding_background_image &&
                <div _id={branding._id} className={classes.appProfileColorBackground} onClick={event => this.brandingClick(event)} />
              }
            </div>
            <CardContent className={classes.cardContent}>
              {(menuPermissions('brandings', false, owner, currentRole, branding)) &&
                <Grid container spacing={4}>
                  <Grid item xs={6} className={classes.appProfileButtonContainer}>
                    <Button 
                      _id={branding._id}
                      className={`${classes.addButton}`}
                      onClick={event => this.handleBrandingMenuClose('edit', null, false, false, event)}
                      focusRipple={false}
                    >
                      <Edit className={classes.addCircleIcon} />
                      Edit
                    </Button>
                  </Grid>
                  <Grid item xs={6} className={classes.appProfileButtonContainer}>
                    <Button 
                      _id={branding._id}
                      className={`${classes.addButton}`}
                      onClick={event => this.handleBrandingMenuClose('delete', null, false, false, event)}
                      focusRipple={false}
                    >
                      <LibraryBooks className={classes.addCircleIcon} />
                      Delete
                    </Button>
                  </Grid>
                </Grid>
              }
            </CardContent>
          </Card>
          <div className={`${classes.appProfileTitleArea} ${classes.appProfileCardBrandArea} clearfix`}>
            <div className={classes.appProfileColorBlocks}>
              <div className={classes.appProfileColorBlock} style={{backgroundColor: branding.branding_primary ? branding.branding_primary : torquoise}} />
              <div className={classes.appProfileColorBlock} style={{backgroundColor: branding.branding_secondary ? branding.branding_secondary : teal}} />
              <div className={classes.appProfileColorBlock} style={{backgroundColor: branding.branding_accent ? branding.branding_accent : energy}} />
            </div>
            {branding.branding_small_logo &&
              <img className={classes.appProfileSmallLogo} src={branding.branding_small_logo} alt="logo" />
            }
          </div>
        </Grid>
      ))
    )
  }
  
  render() {
    const { classes } = this.props;

    return (
      <div className={`${classes.root} ${classes.rootExtendedHeader}`}>
        {this.handleOpenBrandingModal()}
        <Grid container spacing={4} style={{height: '100%'}}>
          <Grid item xs={12} className={`${classes.noPaddingBottom}`}>
            <Typography variant="h4" className={classes.topButtonRow}> 
              <Button className={classes.addButton} onClick={this.addBranding}>
                <AddCircle className={classes.addCircleIcon} />
                Add New Brand
              </Button>
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.appProfileGrid}>
            <Grid container spacing={4}>
              {this.handleBrandings()}    
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const BrandingsWithLoading = WithLoading(Brandings)

class BrandingsContainer extends PureComponent {
  state = {
    loading: true
  }

  componentDidMount() {
    const { history, loggedOut, token, companyId, owner } = this.props;

    verifyLoggedIn(history, loggedOut, () => {
      this.props.verifyRole(token, owner, verifyUser => {
        if (verifyUser.data.user_type <= '2' || verifyUser.data.user_type === 'MA') {
          this.props.fetchBrandings(token, companyId, () => {
            this.setState({
              loading: false
            });
          });
        }
      });
    });
  }

  render() {
    const loading = this.state.loading;

    return (
      <BrandingsWithLoading 
        isLoading={loading}
        {...this.props}
      />
    )
  }
}

function mapStateToProps(state) {
  if (_.isEmpty(state.login.token) || _.isEmpty(state.login.company) || _.isEmpty(state.login.user)) {
    return {
      loggedOut: true
    }
  }

  return { 
    token: state.login.token,
    companyId: state.login.company._id,
    currentUser: state.login.user,
    currentRole: state.login.user.user_type,
    owner: state.login.user._id,
    brandings: state.brandings
  };
}

const mapDispatchToProps = dispatch => ({
  verifyRole: (token, owner, callback) => dispatch(verifyRole(token, owner, callback)),
  fetchBrandings: (token, companyId, callback) => dispatch(fetchBrandings(token, companyId, callback))
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(BrandingsContainer)
