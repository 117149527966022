import { FETCH_SHOWCASES, FETCH_SHOWCASES_IN_APP_PROFILE } from "../actions/actions_showcases";

export default function(state = [], action) {
  switch (action.type) {
    case FETCH_SHOWCASES:
      if (!action.payload) {
        return state;
      }
      return action.payload;
    
    case FETCH_SHOWCASES_IN_APP_PROFILE:
      if (!action.payload) {
        return state;
      }
      return action.payload;
      
    default:
      return state;
  }
}