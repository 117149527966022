import { VERIFY_USER, FETCH_USER, CREATE_USER, UPDATE_USER, REMOVE_USER, DELETE_USER } from "../actions/actions_users";

export default function(state = {}, action) {
  switch (action.type) {
    case VERIFY_USER:
      if (!action.payload) {
        return state;
      }
      return action.payload;    

    case FETCH_USER:
      if (!action.payload) {
        return state;
      }
      return action.payload;

    case CREATE_USER:
      if (!action.payload) {
        return state;
      }
      return action.payload;
      // return _.mapKeys(action.payload.data, "_id");

    case UPDATE_USER:
      if (!action.payload) {
        return state;
      } else if (action.payload.errors) {
        return state;
      }
      return action.payload;

    case REMOVE_USER:
      if (!action.payload ) {
        return state;
      }
      return action.payload;

    case DELETE_USER:
      if (!action.payload ) {
        return state;
      }
      return action.payload;
    
    default:
      return state;
  }
}