import React from "react";

import moment from "moment";
import omit from "lodash/omit";

import { withStyles, css } from "react-with-styles";

import { DateRangePicker, isInclusivelyBeforeDay } from "react-dates";

const START_DATE = "startDate";
const END_DATE = "endDate";
const HORIZONTAL_ORIENTATION = "horizontal";
const ANCHOR_LEFT = "left";

function isSameDay(a, b) {
  if (!moment.isMoment(a) || !moment.isMoment(b)) return false;
  // Compare least significant, most likely to change units first
  // Moment's isSame clones moment inputs and is a tad slow
  return (
    a.date() === b.date() && a.month() === b.month() && a.year() === b.year()
  );
}

const today = moment();

const presets = [
  {
    text: "Last Week",
    start: moment().subtract(1, "week"),
    end: today,
  },
  {
    text: "Last Month",
    start: moment().subtract(1, "month"),
    end: today,
  },
  {
    text: "Last 60 days",
    start: moment().subtract(60, "days"),
    end: today,
  },
  {
    text: "Last 90 days",
    start: moment().subtract(90, "days"),
    end: today,
  },
];

let readOnly = false;
const mobile =
  /iPad|iPhone|iPod|Android/.test(navigator.userAgent) && !window.MSStream;

if (mobile) {
  readOnly = true;
}

const defaultProps = {
  // example props for the demo
  autoFocus: false,
  autoFocusEndDate: false,
  initialStartDate: null,
  initialEndDate: null,
  presets: presets,
  readOnly: readOnly,

  // input related props
  startDateId: START_DATE,
  startDatePlaceholderText: "Start Date",
  endDateId: END_DATE,
  endDatePlaceholderText: "End Date",
  disabled: false,
  required: false,
  screenReaderInputMessage: "",
  showClearDates: false,
  showDefaultInputIcon: false,
  customInputIcon: null,
  customArrowIcon: null,
  customCloseIcon: null,

  // calendar presentation and interaction related props
  renderMonthText: null,
  orientation: HORIZONTAL_ORIENTATION,
  anchorDirection: ANCHOR_LEFT,
  horizontalMargin: 0,
  withPortal: false,
  withFullScreenPortal: false,
  initialVisibleMonth: () => moment().subtract(1, "month"),
  numberOfMonths: 2,
  keepOpenOnDateSelect: true,
  reopenPickerOnClearDates: false,
  isRTL: false,

  // navigation related props
  navPrev: null,
  navNext: null,
  onPrevMonthClick() {},
  onNextMonthClick() {},
  onClose() {},

  // day presentation and interaction related props
  renderDayContents: null,
  minimumNights: 0,
  enableOutsideDays: false,
  isDayBlocked: () => false,
  isOutsideRange: (day) => !isInclusivelyBeforeDay(day, moment()),
  isDayHighlighted: () => false,

  // internationalization
  displayFormat: () => moment.localeData().longDateFormat("L"),
  monthFormat: "MMMM YYYY",
};

class DateRangePickerWrapper extends React.Component {
  constructor(props) {
    super(props);

    const { autoFocus, autoFocusEndDate, initialStartDate, initialEndDate } =
      props;

    let focusedInput = null;
    if (autoFocus) {
      focusedInput = START_DATE;
    } else if (autoFocusEndDate) {
      focusedInput = END_DATE;
    }

    this.state = {
      focusedInput,
      startDate: moment(initialStartDate),
      endDate: moment(initialEndDate),
    };

    this.onDatesChange = this.onDatesChange.bind(this);
    this.onFocusChange = this.onFocusChange.bind(this);
    this.renderDatePresets = this.renderDatePresets.bind(this);
  }

  onDatesChange({ startDate, endDate }) {
    const { setDates } = this.props;
    this.setState({ startDate, endDate });
    setDates(startDate, endDate);
  }

  onFocusChange(focusedInput) {
    this.setState({ focusedInput });
  }

  renderDatePresets() {
    const { presets, styles } = this.props;
    const { startDate, endDate } = this.state;

    return (
      <div {...css(styles.PresetDateRangePicker_panel)}>
        {presets.map(({ text, start, end }) => {
          const isSelected =
            isSameDay(start, startDate) && isSameDay(end, endDate);
          return (
            <button
              key={text}
              {...css(
                styles.PresetDateRangePicker_button,
                isSelected && styles.PresetDateRangePicker_button__selected
              )}
              type="button"
              onClick={() =>
                this.onDatesChange({ startDate: start, endDate: end })
              }>
              {text}
            </button>
          );
        })}
      </div>
    );
  }

  render() {
    const { focusedInput, startDate, endDate } = this.state;

    // autoFocus, autoFocusEndDate, initialStartDate and initialEndDate are helper props for the
    // example wrapper but are not props on the SingleDatePicker itself and
    // thus, have to be omitted.
    const props = omit(this.props, [
      "autoFocus",
      "autoFocusEndDate",
      "initialStartDate",
      "initialEndDate",
      "presets",
      "setDates",
    ]);

    return (
      <div>
        <DateRangePicker
          {...props}
          renderCalendarInfo={this.renderDatePresets}
          onDatesChange={this.onDatesChange}
          onFocusChange={this.onFocusChange}
          focusedInput={focusedInput}
          startDate={startDate}
          endDate={endDate}
        />
      </div>
    );
  }
}

// DateRangePickerWrapper.propTypes = propTypes;
DateRangePickerWrapper.defaultProps = defaultProps;

export default withStyles(({ reactDates: { color } }) => ({
  //For styling -> custom_datepicker.css

  PresetDateRangePicker_panel: {},
  PresetDateRangePicker_button: {},
  PresetDateRangePicker_button__selected: {},
}))(DateRangePickerWrapper);
