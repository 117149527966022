import React, { useRef, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import WebViewer from '@pdftron/webviewer';
import QueryString from 'query-string';
import { saveEditedPDF } from './uploadFileToMyFiles';
import FileNameModal from './FileNameModal';
import { Buffer } from 'buffer';
import { isMobile } from 'react-device-detect';

const PdfViewer = () => {
  const [showSaveFile, setShowSaveFile] = useState(false);
  const [savingFile, setSavingFile] = useState({
    loading: false,
    message: '',
  });
  const [pdfData, setPdfData] = useState();
  const viewer = useRef(null);
  const queryParams = QueryString.parse(window.location.search);
  const { pdfUrl, token, folderId, userId, companyId } = queryParams;
  useEffect(() => {
    const handleScroll = (event) => {
      setTimeout(() => {
        if (window.scrollY > 0 && window.scrollY < 100) {
          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        }
      }, 400);
    };

    window.addEventListener('scroll', handleScroll);

    WebViewer(
      {
        path: 'pdftron',
        initialDoc: pdfUrl,
        fullAPI: true,
        useDownloader: false,
        licenseKey:
          'SW Direct Sales, LLC (dba Hussle) (Formerly Verb Technology Company, Inc.):OEM:verbTEAMS::B+:AMS(20240608):F1B5B6630417060A0360B03AC9A2537860612F5EAB2618E006CD4D82BD044E65E3F631F5C7',
        disabledElements: [
          'ribbons',
          'menuButton',
          'viewControlsButton',
          'toggleNotesButton',
          'toolsHeader',
          'selectToolButton',
        ],
      },
      viewer.current
    )
      .then((instance) => {
        const { documentViewer, annotationManager, Annotations } =
          instance.Core;
        const theme = instance.UI.Theme;

        instance.Core.documentViewer.addEventListener('documentLoaded', () => {
          Annotations.WidgetAnnotation.getCustomStyles = (widget) => {
            if (widget instanceof Annotations.TextWidgetAnnotation) {
              if (widget.getValue() !== '') {
                return {
                  'background-color': 'rgba(0,0,0,0)',
                };
              }
              return {
                'background-color': 'rgba(243,222,80,0.25)',
              };
            }
          };
        });

        instance.UI.setTheme(theme.DARK);
        instance.UI.setHeaderItems((header) => {
          header.push({
            type: 'actionButton',
            img: 'icon-header-download',
            onClick: async () => {
              const doc = documentViewer.getDocument();
              const xfdfString = await annotationManager.exportAnnotations();
              const data = await doc.getFileData({ xfdfString });
              const arr = new Uint8Array(data);
              const encodedPDF = Buffer.from(arr).toString('base64');

              setPdfData(encodedPDF);
              setShowSaveFile(true);
            },
          });
        });
      })
      .catch((error) => console.log({ error }));
  }, []);

  return (
    <div className='App'>
      <div className='pdftronviewer' ref={viewer}></div>
      <FileNameModal
        openTeamModal={showSaveFile}
        savingFile={savingFile}
        handleClose={() => {
          setShowSaveFile(false);
          setSavingFile({
            loading: false,
            message: '',
          });
        }}
        onSubmitForm={(values) => {
          setSavingFile({ loading: true });
          saveEditedPDF(
            pdfData,
            values.fileName,
            token,
            folderId,
            userId,
            companyId,
            () => {
              setSavingFile({
                loading: true,
                message: 'File saved successfully to my files',
              });

              if (!isMobile) {
                setTimeout(function () {
                  window.location =
                    window.location.pathname + '?type=savepdf&pdfData=';
                }, 2500);

                //window.location = "pdf://google.com?type=savepdf&pdfData=";
              }
            }
          );
        }}
      />
    </div>
  );
};

export default compose(withRouter)(PdfViewer);
