import _ from "lodash";
import React, { PureComponent } from "react";
import { compose } from 'redux';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { withStyles } from '@material-ui/core/styles';
import { styles } from '../styles';

// import { Grid, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import WithLoading from '../subcomponents/withLoading';
import GridContainer from '../subcomponents/gridContainer';

import { analyticsContentTableHeight, /*numberSort,*/ activeCampaignEvent } from '../helpers/helperFunctions';
// import { multipleFilter, libraryNameSort } from '../helpers/gridLibraryHelpers';

import { fetchViewRate, fetchShowcasesAnalytics  } from "../../actions/actions_index";

class Content extends PureComponent {
  state = {
    sorting: [{ columnName: 'opens', direction: 'desc' }]
  }

  componentWillReceiveProps(newProps) {
    if (!_.isEqual(this.props.postData, newProps.postData)) {
      newProps.loadData(newProps.postData);
    }
  }

  handleSortingChange = sorting => {
    this.setState({
      sorting
    })
  }

  render() {
    const { page, postData, showcasesAnalytics, viewRate, classes } = this.props,
          { sorting } = this.state

    const rows = showcasesAnalytics.showcaseData

    const columns = [
      { name: 'name', title: 'Name' },
      { name: 'showcases_total', title: 'Showcases Shared' },
      { name: 'leads_total', title: 'Leads' },
      { name: 'opens', title: 'Showcase Opens', getCellValue: row => row.event_totals.opens ? row.event_totals.opens : 0 },
      { name: 'views', title: 'Total Views', getCellValue: row => row.event_totals.item_views ? row.event_totals.item_views : 0 },
      { name: 'contacts_total', title: 'Unique Views' },
      { name: 'downloads', title: 'Downloads', getCellValue: row => row.event_totals.downloads ? row.event_totals.downloads : 0 },
      { name: 'total_duration', title: 'Total Duration' }
    ]

    const tableColumnExtensions = [
      { columnName: 'name' },
      { columnName: 'showcases_total', width: 110 },
      { columnName: 'leads_total', width: 80 },
      { columnName: 'opens', width: 100 },
      { columnName: 'views', width: 130 },
      { columnName: 'contacts_total', width: 150 },
      { columnName: 'downloads', width: 110 },
      { columnName: 'total_duration', width: 130 }
    ]

    const sortingStateColumnExtensions = [
      { columnName: 'name', sortingEnabled: true },
      { columnName: 'showcases_total', sortingEnabled: true },
      { columnName: 'leads_total', sortingEnabled: true },
      { columnName: 'opens', sortingEnabled: true },
      { columnName: 'views', sortingEnabled: true },
      { columnName: 'contacts_total', sortingEnabled: true },
      { columnName: 'downloads', sortingEnabled: true },
      { columnName: 'total_duration', sortingEnabled: true }
    ]

    const filteringStateColumnExtensions = [
      { columnName: 'name', filteringEnabled: true },
      { columnName: 'showcases_total', filteringEnabled: false },
      { columnName: 'leads_total', filteringEnabled: false },
      { columnName: 'opens', filteringEnabled: false },
      { columnName: 'views', filteringEnabled: false },
      { columnName: 'contacts_total', filteringEnabled: false },
      { columnName: 'downloads', filteringEnabled: false },
      { columnName: 'total_duration', filteringEnabled: false }
    ]

    return (
      <Grid item className={classes.topDrilldownPages}> 
        <Grid container spacing={1} className={classes.gridSmallMarginTop}>
          <Grid item xs={1} className={`${classes.gridNine} ${classes.gridNineSmallPaddingRight}`}>
            <Typography variant="h5" className={classes.smallNumberHeading}>
              Showcases Shared
            </Typography>
            <div className={`${classes.smallNumberBox} ${classes.showsBox}`}>
              <Typography variant="h3" className={classes.smallNumber}>
                {showcasesAnalytics.totalShowcases ? showcasesAnalytics.totalShowcases : 0}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={1} className={`${classes.gridNine} ${classes.gridNineSmallPaddingRight}`}>
            <Typography variant="h5" className={classes.smallNumberHeading}> 
              Leads
            </Typography>
            <div className={`${classes.smallNumberBox} ${classes.viewsBox}`}>
              <Typography variant="h3" className={classes.smallNumber}>
                {showcasesAnalytics.totalLeads ? showcasesAnalytics.totalLeads : 0}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={1} className={`${classes.gridNine} ${classes.gridNineSmallPaddingRight}`}>
            <Typography variant="h5" className={classes.smallNumberHeading}> 
              Total Opens
            </Typography>
            <div className={`${classes.smallNumberBox} ${classes.viewsBox}`}>
              <Typography variant="h3" className={classes.smallNumber}>
                {showcasesAnalytics.totalOpens ? showcasesAnalytics.totalOpens : 0}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={1} className={`${classes.gridNine} ${classes.gridNineSmallPaddingRight}`}>
            <Typography variant="h5" className={classes.smallNumberHeading}> 
              Total Views
            </Typography>
            <div className={`${classes.smallNumberBox} ${classes.viewsBox}`}>
              <Typography variant="h3" className={classes.smallNumber}>
                {showcasesAnalytics.totalViews ? showcasesAnalytics.totalViews : 0}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={1} className={`${classes.gridNine} ${classes.gridNineSmallPaddingRight}`}>
            <Typography variant="h5" className={classes.smallNumberHeading}> 
              Unique Views
            </Typography>
            <div className={`${classes.smallNumberBox} ${classes.viewsBox}`}>
              <Typography variant="h3" className={classes.smallNumber}>
                {showcasesAnalytics.uniqueViews ? showcasesAnalytics.uniqueViews : 0}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={1} className={`${classes.gridNine} ${classes.gridNineSmallPaddingRight}`}>
            <Typography variant="h5" className={classes.smallNumberHeading}> 
              Total Downloads
            </Typography>
            <div className={`${classes.smallNumberBox} ${classes.viewsBox}`}>
              <Typography variant="h3" className={classes.smallNumber}>
                {showcasesAnalytics.totalDownloads ? showcasesAnalytics.totalDownloads : 0}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={1} className={`${classes.gridNine} ${classes.gridNineSmallPaddingRight}`}>
            <Typography variant="h5" className={classes.smallNumberHeading}> 
              Total Duration
            </Typography>
            <div className={`${classes.smallNumberBox} ${classes.viewsBox}`}>
              <Typography variant="h3" className={classes.smallNumber}>
                {showcasesAnalytics.totalDuration ? showcasesAnalytics.totalDuration : 0}
              </Typography>
            </div>
          </Grid>
        </Grid>
        <Grid item className={`${classes.analyticsTableArea} ${classes.analyticsUsersTableArea}`}>
          <GridContainer 
            tableType={'separation'}
            menuType={'-new'}
            appWebSeparation={'showcases'}
            rows={rows}
            columns={columns}
            tableColumnExtensions={tableColumnExtensions}
            sortingStateColumnExtensions={sortingStateColumnExtensions}
            sorting={sorting}
            handleSortingChange={this.handleSortingChange}
            filteringStateColumnExtensions={filteringStateColumnExtensions}
            showSelectAll={false}
            showSelectionColumn={false}
            showRowDetail={false}
            classes={classes}
            //Export to CSV
            page={page}
            postData={postData}
            //END Export to CSV
          />
        </Grid>
      </Grid>
    );
  }
}

const ContentWithLoading = WithLoading(Content);

class ContentContainer extends PureComponent {
  state = {
    loading: true
  }

  loadData = (postData) => {
    const { token } = this.props;

    let dataCalls = [];

    // dataCalls.push(
    //   this.props.fetchViewRate(token, postData, result => {})
    // )

    dataCalls.push(
      this.props.fetchShowcasesAnalytics(token, postData, result => {})
    )

    Promise.all(dataCalls)
      .then(result => {
        this.setState({
          loading: false
        })
      })
  }

  componentDidMount() {
    const { token, enterprise, currentUser, location, master_admin } = this.props;

    this.loadData(this.props.postData);

    const values = {
      token,
      master_admin,
      enterprise,
      email: currentUser.email,
      page: location.pathname
    }

    activeCampaignEvent(values)
  }

  render() {
    const loading = this.state.loading;

    return (
      <ContentWithLoading 
        isLoading={loading}
        loadingReport={true}
        loadData={this.loadData}
        {...this.props}
      />
    )
  }
}

function mapStateToProps(state) {
  if (_.isEmpty(state.login.token) || _.isEmpty(state.login.company) || _.isEmpty(state.login.user)) {
    return {
      loggedOut: true
    }
  }

  return { 
    token: state.login.token,
    master_admin: state.login.master_admin,
    companyId: state.login.company._id,
    owner: state.login.user._id,
    currentUser: state.login.user,
    currentRole: state.login.user.user_type,
    showcasesAnalytics: state.analytics.showcases,
    viewRate: state.analytics.viewRate
  };
}

const mapDispatchToProps = dispatch => ({
  fetchViewRate: (token, values, callback) => dispatch(fetchViewRate(token, values, callback)),
  fetchShowcasesAnalytics: (token, values, callback) => dispatch(fetchShowcasesAnalytics(token, values, callback))
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(ContentContainer)
