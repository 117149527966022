import _ from "lodash";
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FieldArray, Field, reduxForm } from "redux-form";

import { withStyles } from '@material-ui/core/styles';
import { styles } from '../styles';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';

import AddCircle from '@material-ui/icons/AddCircle';

import { ErrorText } from '../subcomponents/form_subcomponents/errorText';
import { RenderCheckboxes } from '../subcomponents/form_subcomponents/renderCheckboxes';
import { RenderTextField } from '../subcomponents/form_subcomponents/renderTextField';

import { activeCampaignEvent } from '../helpers/helperFunctions';

import { createLibraryGroup, updateCompany } from '../../actions/actions_index';

class OnboardingContentGroupsForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null
    }

    this.props.reset();
  }

  componentDidMount() {
    const { token, enterprise, currentUser, history, master_admin } = this.props;

    const values = {
      token,
      master_admin,
      enterprise,
      email: currentUser.email,
      page: history.location.pathname
    }

    activeCampaignEvent(values)
  }

  compileGroup = (group, i) => {
    const { companyId, owner } = this.props;

    const addGroup = {
      company: companyId,
      owners: [owner],
      name: group,
      order: i
    }

    return addGroup;
  }

  proceed = () => {
    const { token, companyId, history, updateCompany } = this.props;

    updateCompany(token, {onboarding_step: 'users'}, companyId, () => {
      history.push('/onboarding/users');
    });
  }

  closedContentGroups = () => {
    this.proceed();
  }

  submitOnboardingContentGroups = (values) => {
    const { token, createLibraryGroup } = this.props;

    let groups = [],
        i = 1;

    if (values.content_groups.length > 0) {
      values.content_groups.map(group => {
        const addGroup = this.compileGroup(group, i);
        groups.push(addGroup)
        i++;
        return null
      })
    }

    if (values.added_groups.length > 0) {
      values.added_groups.map(group => {
        if (group.name) {
          const addGroup = this.compileGroup(group.name, i);
          groups.push(addGroup)
          i++;
          return null
        }
      })
    }

    createLibraryGroup(token, groups, () => {
      this.proceed();
    });
  }

  renderGroups = ({ fields }) => {
    const { classes } = this.props;

    return (
      <div>
        {
          fields.map((group, i) => (
            <Field key={i} className={classes.onboardingTextfield} name={`${group}.name`} component={RenderTextField} type="text" label="Group Name" />
          ))
        }
        <div className={classes.onboardingContentGroupsCreateContainer}>
          <Button 
            className={`${classes.addButton} ${classes.onboardingAdd}`}
            onClick={() => fields.push({})}
            focusRipple={false}
          >
            <AddCircle className={classes.addCircleIcon} />
            Create Group
          </Button>
          <Typography variant="subtitle1" className={`${classes.onboardingSubtitle} ${classes.onboardingContentGroupsExtraText}`}>
            <i>...You can create more groups later.</i>
          </Typography>
        </div>
      </div>
    )
  }

  render() {
    const { handleSubmit, pristine, classes } = this.props;
    const { error } = this.state;

    const checkboxOptions = [
      {
        _id: "Marketing Content", 
        name: "Marketing Content"
      },
      {
        _id: "Sales Collateral", 
        name: "Sales Collateral"
      },
      {
        _id: "Training", 
        name: "Training"
      },
      {
        _id: "HR Documentation", 
        name: "HR Documentation"
      },
      {
        _id: "IT Documentation",
        name: "IT Documentation"
      }
    ];

    return (
      <div className={classes.marginTop}>
        <Typography variant="h1" className={classes.onboardingH1}>
          <div className={classes.onboardingStepNumber}>3</div>Content Groups
        </Typography>
        <Grid container spacing={4} className={classes.gridExtraSpacing}>
          <Grid item sm={3} xs={false}>
          </Grid>
          <Grid item sm={6} xs={12}>
            <Typography variant="subtitle1" className={classes.onboardingSteps}>
              Step <span className={classes.onboardingStepsBold}>3</span> of 6
            </Typography>
            <LinearProgress color="secondary" variant="determinate" value={51} />
          </Grid>
          <Grid item sm={3} xs={false}>
          </Grid>
        </Grid>
        <Grid className={`${classes.onboardingBackgroundReverseContainer} ${classes.gridTagsSpacing}`} container spacing={4}>
          <Grid item md={6} xs={12} className={classes.onboardingGrid}>
            <div className={`${classes.onboardingImageContainer} ${classes.onboardingImageOrganizationContainer}`}>
              <img className={classes.onboardingImage} src="/images/verb_onboarding/content_groups.png" />
            </div>
          </Grid>
          <Grid item md={6} xs={12} className={`${classes.onboardingGrid} ${classes.onboardingGridLeftTextArea}`}>
            <Typography variant="h2" className={classes.onboardingH2}>
              Let's organize your content.
            </Typography>
            <form className={classes.onboardingFormNoMaxHeight} noValidate autoComplete="off" onSubmit={handleSubmit(values => this.submitOnboardingContentGroups(values))}>
              <ErrorText className={classes.bigError} touched={true} error={error} />
              <div>
                <Typography variant="subtitle1" className={`${classes.onboardingSubtitle} ${classes.onboardingSubtitleContentGroups}`}>
                  Content groups establish the structure of your app. They allow you to segment your content for you and your users. Select some standard categories below or create your own.
                </Typography>
                <Field className={classes.onboardingCheckbox} type="checkbox" name="content_groups" options={checkboxOptions} component={RenderCheckboxes} />
                <FieldArray name="added_groups" component={this.renderGroups} />
              </div>
              <div className={classes.onboardingButtonContainer}>
                <Button className={`${classes.button} ${classes.onboardingSkipButton}`} color="primary" onClick={this.closedContentGroups}>Skip for Now</Button>
                <Button className={`${classes.button} ${classes.onboardingButton}`} variant="contained" color="secondary" type="submit" disabled={pristine}>Next</Button>
              </div>
            </form>
          </Grid>  
        </Grid> 
      </div>
    )
  }
}

function validate(values, props) {
  const errors = {};
  return errors;
}

function mapStateToProps(state) {
  if (_.isEmpty(state.login.token) || _.isEmpty(state.login.company) || _.isEmpty(state.login.user)) {
    return {
      loggedOut: true
    }
  }

  return { 
    token: state.login.token,
    master_admin: state.login.master_admin,
    companyId: state.login.company._id,
    enterprise: state.login.company.enterprise,
    owner: state.login.user._id,
    currentUser: state.login.user
  };
}

const mapDispatchToProps = dispatch => ({
  createLibraryGroup: (token, values, callback) => dispatch(createLibraryGroup(token, values, callback)),
  updateCompany: (token, values, id, callback) => dispatch(updateCompany(token, values, id, callback))
});

export const OnboardingContentGroups =
compose(
  reduxForm({
    form: 'OnboardingContentGroups',
    validate,
    enableReinitialize: true,
    initialValues: {
      content_groups: [],
      added_groups: []
    }
  }),
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(OnboardingContentGroupsForm)
