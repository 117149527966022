import { FETCH_BRANDING, CREATE_BRANDING, UPDATE_BRANDING, DELETE_BRANDING } from "../actions/actions_brandings";

export default function(state = {}, action) {
  switch (action.type) {
    
    case FETCH_BRANDING:
      if (!action.payload) {
        return state;
      }
      return action.payload;

    case CREATE_BRANDING:
      if (!action.payload) {
        return state;
      }
      return action.payload;
      // return _.mapKeys(action.payload.data, "_id");

    case UPDATE_BRANDING:
      if (!action.payload) {
        return state;
      }
      return action.payload;

    case DELETE_BRANDING:
      if (!action.payload) {
        return state;
      }
      return action.payload;
    
    default:
      return state;
  }
}