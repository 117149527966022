import _ from "lodash";
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

import { withStyles } from '@material-ui/core/styles';
import { styles } from '../styles';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import AddCircle from '@material-ui/icons/AddCircle';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';

import WithLoading from '../subcomponents/withLoading';
import GridContainer from '../subcomponents/gridContainer';
import MessageModalContainer from '../modals/messageModal';

import { verifyLoggedIn, verifyPermissions, menuPermissions, getOffset, messageTableHeight, checkAlerts, activeCampaignEvent } from '../helpers/helperFunctions';

import { verifyRole, fetchMessages  } from '../../actions/actions_index';

import { QUICK_SEND_NEW_MESSAGE } from '../header';

class Messages extends Component {
  
  constructor(props) {
    super(props);
    
    this.state = {
      rowMenu: false,
      openViewMessageModal: false,
      openMessageModal: false,
      openAddToMessageModal: false,
      deleteMessageModal: false,
      selectedMessagePosition: null,
      selectedMessageId: null,
      newMessage: false,
      fromEdit: false,
      fromView: false,
      selectedContent: null,
      sorting: [{ columnName: 'updatedAt', direction: 'desc' }]
    }

    this.handleSortingChange = sorting => {
      this.setState({
        sorting
      })
    }
  }

  componentDidMount() {
    const { quick } = this.props.match.params;
    this.quickMessage(quick);
  }

  componentWillReceiveProps(newProps) {
    const { quick } = newProps.match.params;
    this.quickMessage(quick);
  }

  quickMessage = (quick) => {
    if (quick === QUICK_SEND_NEW_MESSAGE) {
      this.addMessage();
    }
  }

  addMessage = () => {
    this.setState({
      openMessageModal: true
    });
  } 

  //OPEN LIBRARY ITEM MENU
  handleMessageMenu = event => {

    const element = document.getElementById(event.currentTarget.id);
    const top = getOffset(element).top;
    const left = getOffset(element).left;
    const position = {top: top, left: left};
    const id = event.currentTarget.id;

    this.setState({
      rowMenu: true,
      selectedMessagePosition: position,
      selectedMessageId: id
    });
    
  }

  //CLOSE LIBRARY ITEM MENU AND HANDLE IF SELECTION (PREVIEW, EDIT, or DELETE)
  handleMessageMenuClose = (type, selectedMessageId, fromEdit=false, fromView=false) => {

    let openViewMessageModal = false,
        openMessageModal = false,
        openAddToMessageModal = false,
        deleteMessageModal = false;

    this.setState({
      selectedMessagePosition: null
    })

    switch (type) {
      case 'edit':
        openMessageModal = true;
        break;
      
      case 'delete':
        openMessageModal = true;
        deleteMessageModal = true;
        break;
      
      default:  
        break;
    }

    this.setState({
      rowMenu: false,
      openViewMessageModal: openViewMessageModal,
      openMessageModal: openMessageModal,
      openAddToMessageModal: openAddToMessageModal,
      deleteMessageModal: deleteMessageModal,
      selectedMessageId: selectedMessageId,
      newMessage: false,
      fromEdit: fromEdit,
      fromView: fromView
    });

  }

  //HANDLE CLOSED VIEW TEAM MODAL
  closedViewMessageModal = () => {
    this.setState({
      openViewMessageModal: false,
      selectedMessageId: null
    });
  }

  //HANDLE CLOSED CREATE/EDIT/DELETE TEAM MODAL
  closedMessageModal = (updated, created, data) => {
    const { enterprise, history } = this.props;
    const token = this.props.token;
    const companyId = this.props.companyId;

    let openAddToMessageModal = false,
        selectedMessageId = null,
        newMessage = false;

    console.log('updated',updated)
    console.log('created',created)
    console.log('data',data)

    if (updated) {      
      this.props.fetchMessages(token, companyId, results => {

        if (created) {
          openAddToMessageModal = true;
          selectedMessageId = data._id;
          newMessage = true;
        }

        this.setState({ 
          openMessageModal: false,
          deleteMessageModal: false,
          openAddToMessageModal: openAddToMessageModal,
          selectedMessageId: selectedMessageId,
          newMessage: newMessage,
          fromEdit: false,
          fromView: false
        });

        if (created) {
          this.setState({selectedMessageId: null})
        }
      });

    } else {
      this.setState({ 
        openMessageModal: false,
        deleteMessageModal: false,
        openAddToMessageModal: openAddToMessageModal,
        selectedMessageId: selectedMessageId,
        newMessage: newMessage,
        fromEdit: false,
        fromView: false
      });
    }

    enterprise ? history.push('/users/messages') : history.push('/messages');
  }

  //HANDLE CLOSED ADD TO TEAM MODAL
  closedAddToMessageModal = (addedFiles, updated) => {
    let { fromEdit, fromView, selectedMessageId } = this.state;

    if (updated) {
      const token = this.props.token;
      const companyId = this.props.companyId;

      this.props.fetchMessages(token, companyId, data => {});
    }

    if (!fromEdit && !fromView) {
      selectedMessageId = null;
    }

    this.setState({ 
      selectedMessageId: selectedMessageId,
      openAddToMessageModal: false,
      newMessage: false
    });
  }

  rowClick = (event) => {
    const { owner, currentRole, messages } = this.props;
    const { rowMenu } = this.state;
    const id = messages[event[0]]._id;
    const initialValues = messages[event[0]];

    const openEdit = menuPermissions('messages', rowMenu, owner, currentRole, initialValues);
    openEdit && this.handleMessageMenuClose('edit', id);
  }

  handleOpenMessageModal = () => {
    const { openMessageModal, deleteMessageModal, selectedMessageId } = this.state;

    if (openMessageModal) {
      return (
        <MessageModalContainer 
          openMessageModal={openMessageModal}
          deleteMessageModal={deleteMessageModal}
          selectedMessageId={selectedMessageId}
          closedMessageModal={this.closedMessageModal}
        />
      )
    }
  }

  render() {
    const { enterprise, company, alerts, owner, currentRole, classes } = this.props;
    const alertsRoot = checkAlerts(company, alerts, classes);

    let selector = enterprise ? 'App Profiles' : 'Teams';

    const { messages } = this.props;
    const { selectedMessagePosition, selectedMessageId, sorting } = this.state;
    const rows = messages;

    const columns = [
      { name: 'title', title: 'Title' },
      { name: 'body', title: 'Body' },
      { name: 'user', title: 'Sent By' },
      { name: 'app_profiles', title: selector, getCellValue: row => (row.app_profiles ? row.app_profiles.join(', ') : 0)},
      { name: 'updatedAt', title: 'Last Updated' },
      { name: 'menu', title: ' ', getCellValue: row => {
        return {
          owner: owner,
          currentRole: currentRole
        }
      }},
    ];

    const tableColumnExtensions = [
      { columnName: 'title' },
      { columnName: 'body', width: 500 },
      { columnName: 'user', width: 150 },
      { columnName: 'app_profiles', width: 250 },
      { columnName: 'updatedAt', width: 200 },
      { columnName: 'menu', width: 75 }
    ]

    const sortingStateColumnExtensions = [
      { columnName: 'title', sortingEnabled: true },
      { columnName: 'body', sortingEnabled: true },
      { columnName: 'user', sortingEnabled: true },
      { columnName: 'app_profiles', sortingEnabled: true },
      { columnName: 'updatedAt', sortingEnabled: true },
      { columnName: 'menu', sortingEnabled: false }
    ]

    const filteringStateColumnExtensions = [
      { columnName: 'title', filteringEnabled: true },
      { columnName: 'body', filteringEnabled: true },
      { columnName: 'user', filteringEnabled: true },
      { columnName: 'app_profiles', filteringEnabled: true },
      { columnName: 'updatedAt', filteringEnabled: true },
      { columnName: 'menu', filteringEnabled: false }
    ]

    return (
      <div className={`${classes.root} ${alertsRoot} ${enterprise ? classes.rootExtendedHeader: null}`}>
        {this.handleOpenMessageModal()}
        <Typography variant="h4" className={classes.topButtonRow}>  
          <Button className={classes.addButton} onClick={this.addMessage}>
            <AddCircle className={classes.addCircleIcon} />
            Send New Message
          </Button>
        </Typography>
        <GridContainer 
          // gridHeight={messageTableHeight()}
          rows={rows}
          columns={columns}
          tableColumnExtensions={tableColumnExtensions}
          sortingStateColumnExtensions={sortingStateColumnExtensions}
          sorting={sorting}
          handleSortingChange={this.handleSortingChange}
          filteringStateColumnExtensions={filteringStateColumnExtensions}
          showSelectAll={false}
          showSelectionColumn={false}
          showRowDetail={false}
          classes={classes}
          handleMenu={this.handleMessageMenu}
          menuType={'messages-new'}
          onSelectionChange={this.rowClick}
        />
      <Menu
        anchorPosition={selectedMessagePosition}
        anchorReference={'anchorPosition'}
        open={Boolean(selectedMessagePosition)}
        onClose={() => this.handleMessageMenuClose(null, null)}
      >
        <MenuItem onClick={() => this.handleMessageMenuClose('edit', selectedMessageId)}>
          <ListItemIcon>
            <Edit />
          </ListItemIcon>
          Edit
        </MenuItem>
        <MenuItem onClick={() => this.handleMessageMenuClose('delete', selectedMessageId)}>
          <ListItemIcon>
            <Delete />
          </ListItemIcon>
          Delete
        </MenuItem>
      </Menu>
      </div>
    );
  }
}

const MessagesWithLoading = WithLoading(Messages)

class MessagesContainer extends Component {
  state = {
    loading: true
  }

  componentDidMount() {
    const { token, enterprise, companyId, owner, currentUser, location, master_admin, history, loggedOut } = this.props;

    verifyLoggedIn(history, loggedOut, () => {
      this.props.verifyRole(token, owner, verifyUser => {

        verifyPermissions(history, 'messages', verifyUser.data.user_type);

        if (verifyUser.data.user_type <= '2') {
          this.props.fetchMessages(token, companyId, messages => {
            this.setState({
              loading: false
            });
          });
        }
      });
    });

    const values = {
      token,
      master_admin,
      enterprise,
      email: currentUser.email,
      page: location.pathname
    }

    activeCampaignEvent(values)
  }

  render() {
    const loading = this.state.loading;

    return (
      <MessagesWithLoading 
        isLoading={loading}
        {...this.props}
      />
    )
  }
}

function mapStateToProps(state) {
  if (_.isEmpty(state.login.token) || _.isEmpty(state.login.company) || _.isEmpty(state.login.user)) {
    return {
      loggedOut: true
    }
  }

  return {
    token: state.login.token,
    master_admin: state.login.master_admin,
    companyId: state.login.company._id,
    company: state.company,
    enterprise: state.login.company.enterprise,
    alerts: state.alerts,
    owner: state.login.user._id,
    currentUser: state.login.user,
    currentRole: state.login.user.user_type,
    messages: state.messages
  };
}

const mapDispatchToProps = dispatch => ({
  verifyRole: (token, owner, callback) => dispatch(verifyRole(token, owner, callback)),
  fetchMessages: (token, companyId, callback) => dispatch(fetchMessages(token, companyId, callback))
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(MessagesContainer)
