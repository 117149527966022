import _ from "lodash";
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from "redux-form";

import { withStyles } from '@material-ui/core/styles';
import { styles } from '../styles';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';

import Info from '@material-ui/icons/Info';
import Refresh from '@material-ui/icons/Refresh';

import { cleanPutValues } from '../helpers/helperFunctions';

import { RenderTextField } from '../subcomponents/form_subcomponents/renderTextField';
import { RenderOwnerEditableByMenu } from '../subcomponents/form_subcomponents/renderOwnerEditableByMenu';
import { RenderUploadLabel } from '../subcomponents/form_subcomponents/renderUploadLabel';
import { RenderUpload } from '../subcomponents/form_subcomponents/renderUpload';
import RenderColorPicker from '../subcomponents/form_subcomponents/renderColorPicker';

import { torquoise, teal, energy, notBlack, verbPrimary, verbSecondary, verbAccent } from '../../theme';

import { fetchBranding, createBranding, updateBranding, deleteBranding, fetchAdmins } from '../../actions/actions_index';

class BrandingForm extends PureComponent {
  constructor(props) {
    super(props);

    const { initialValues } = this.props;

    let primary = verbPrimary,
        secondary = verbSecondary,
        accent = verbAccent,
        background = notBlack,
        logo = null,
        small_logo = '/images/verb/verb-bug.png',
        background_image = '/images/verb/default_background.png';

    const { branding_primary, branding_secondary, branding_accent, branding_background, branding_logo, branding_small_logo, branding_background_image } = initialValues;

    if (initialValues) {
      branding_primary && (primary = branding_primary);
      branding_secondary && (secondary = branding_secondary);
      branding_accent && (accent = branding_accent);
      branding_background && (background = branding_background);
    
      branding_logo && (logo = branding_logo);
      branding_small_logo && (small_logo = branding_small_logo);
      branding_background_image && (background_image = branding_background_image);
    }

    this.state = {
      primary: primary,
      secondary: secondary,
      accent: accent,
      background: background,
      logo: logo,
      small_logo: small_logo,
      background_image: background_image,
      anchorEl: null,
      popoverText: null
    }

    this.props.reset();
  }

  handleColor = (type, color) => {

    switch(type) {
      case 'Primary': 
        this.props.change('branding_primary', color);
        this.setState({
          primary: color
        })
        break;
      case 'Secondary':
        this.props.change('branding_secondary', color);
        this.setState({
          secondary: color
        })
        break;
      case 'Accent': 
        this.props.change('branding_accent', color);
        this.setState({
          accent: color
        })
        break;
      case 'Background': 
        this.props.change('branding_background', color);
        this.setState({
          background: color
        })
        break;
      default:
        break;
    }
  
  }

  updatePreview = (name, value, uploaded) => {
    let reader = new FileReader();

    switch (name) {

      // case 'logo':
      //   reader.onload = (e) => {
      //     this.setState({logo: e.target.result});
      //   };
      //   reader.readAsDataURL(value[0]);
      //   break;
      case 'small_logo':
        if (uploaded) {
          reader.onload = (e) => {
            this.setState({small_logo: e.target.result});
          };
          reader.readAsDataURL(value[0]);
        }
        break;
      case 'background_image':
        if (uploaded) {
          reader.onload = (e) => {
            this.setState({background_image: e.target.result});
          };
          reader.readAsDataURL(value[0]);
        }
        break;
      default:
        break;

    }

    return null;
  }

  openPopover = (event, type) => {
    let text = '';

    switch (type) {
      case 'logo':
        text = 'PNG with transparent background. This is used as the top logo in the Mobile App.';
        break;

      case 'small_logo':
        text = 'PNG with transparent background. This is used as the top logo for Showcases and Spaces.';
        break;

      case 'background_image':
        text = 'Use a background image. This is used in the App main panel area and in Spaces/Showcases top right area. For best results use an image with around a 5:3 width:height ratio.';
        break;

      default:
        break;
    }

    this.setState({
      anchorEl: event.currentTarget,
      popoverText: text
    });
  }

  closePopover = () => {
    this.setState({
      anchorEl: null,
      popoverText: null
    });
  }

  resetBranding = () => {
    this.props.change('branding_primary', null);
    this.props.change('branding_secondary', null);
    this.props.change('branding_accent', null);
    this.props.change('branding_background', null);
    // this.props.change('logo', null);
    // this.props.change('small_logo', null);
    this.props.change('background_image', null);
    this.props.change('branding_logo', null);
    this.props.change('branding_small_logo', null);
    this.props.change('branding_background_image', null);

    this.setState({
      primary: verbPrimary,
      secondary: verbSecondary,
      accent: verbAccent,
      background: notBlack,
      logo: null,
      small_logo: '/images/verb/verb-bug.png',
      background_image: '/images/verb/default_background.png'
    })
  }

  submitBrandingModal = (values) => {
    const { token, companyId, enterprise } = this.props;
    const { selectedBrandingId, deleteBrandingModal, submitBrandingModal, closedBrandingModal } = this.props;

    submitBrandingModal();

    delete values.teams;
    delete values.library_groups;

    // values.branding_primary === null ? delete values.branding_primary : null;
    // values.branding_secondary === null ? delete values.branding_secondary : null;
    // values.branding_accent === null ? delete values.branding_accent : null;
    // values.branding_background === null ? delete values.branding_background : null;

    if (values.logo) {
      delete values.branding_logo;
    }
    
    if (values.small_logo) {
      delete values.branding_small_logo;
    }

    if (values.background_image) {
      delete values.branding_background_image
    }

    let data = new FormData();

    _.map(values, (value, key) => {
      if (key === 'owners') {
        value = JSON.stringify(value)
      }

      data.append(key, value);
    });

    if (values.logo) {
      data.append('branding_logo', values.logo[0]);
    }

    if (values.small_logo) {
      data.append('branding_small_logo', values.small_logo[0]);
    }

    if (values.background_image) {
      data.append('branding_background_image', values.background_image[0]);
    }

    // for (var pair of data.entries()) {
    //   console.log(pair[0]+ ', '+ pair[1]); 
    // }

    if (!enterprise || (selectedBrandingId && !deleteBrandingModal)) {
      values = cleanPutValues(values);
      
      this.props.updateBranding(token, data, selectedBrandingId, () => {
        closedBrandingModal(true, false, null);
      });
    } else if (selectedBrandingId && deleteBrandingModal) {
      this.props.deleteBranding(token, selectedBrandingId, () => {
        closedBrandingModal(true, false, null);
      });
    } else {
      data.append('company', companyId);

      this.props.createBranding(token, data, result => {
        closedBrandingModal(true, true, result.data);
      });
    }    
  }

  brandingForm = () => {
    const { enterprise, onboarding, forms, owner, currentRole, currentUser, admins, selectedBrandingId, deleteBrandingModal, pristine, invalid, initialValues, classes } = this.props;
    const { primary, secondary, accent, small_logo, background_image, anchorEl, popoverText } = this.state;

    let autoFocus = false,
        brandFormContainer = null,
        onboardingButtonContainer = null,
        extraButtonClass = classes.brandSubmit,
        extraThumbContainerClass = null,
        extraBrandingBackgroundThumbnail = null,
        uploadOnboardingButton = null,
        backgroundImageUploaded = false,
        logoUploaded = false,
        webBackgroundImageContainer = classes.webLogoContainer,
        webLogoImageContainer = classes.webLogoContainer;

    if (!enterprise && !background_image) {
      webBackgroundImageContainer = classes.webBackgroundImageContainer;
    }

    if (!enterprise && !small_logo) {
      webLogoImageContainer = classes.webLogoImageContainer;
    }

    if (onboarding) {
      onboardingButtonContainer = classes.onboardingButtonContainer;
      extraButtonClass = `${classes.onboardingButton} ${classes.onboardingBrandButton}`;
      extraThumbContainerClass = classes.extraThumbContainerClass;
      extraBrandingBackgroundThumbnail = classes.extraBrandingBackgroundThumbnail;
      uploadOnboardingButton = classes.uploadOnboardingButton;
    }

    if (!selectedBrandingId) {
      autoFocus = true;
    }

    if (!pristine) {
      if (forms.Branding.values.background_image && forms.Branding.values.background_image[0] && forms.Branding.values.background_image[0].lastModified) {
        backgroundImageUploaded = true;
        webBackgroundImageContainer = classes.webLogoContainer;
      }

      if (forms.Branding.values.small_logo && forms.Branding.values.small_logo[0] && forms.Branding.values.small_logo[0].lastModified) {
        logoUploaded = true;
        webLogoImageContainer = classes.webLogoContainer;
      }
    }
    
    if (deleteBrandingModal) {
      return (
        <div>
          <Button className={`${classes.button} ${classes.modalButton}`} variant="contained" type="submit" color="secondary">Delete</Button>
        </div>
      )
    } else {
      return (
        <div className={brandFormContainer}>
          <div>
            {enterprise &&
              <Grid container spacing={10} className={classes.colorPickerContainer}> 
                <Grid item sm={8} xs={12} className={classes.gridNoPadding}>
                  <Field name="name" component={RenderTextField} type="text" label="Name" autoFocus={autoFocus} />
                </Grid>
                <Grid item sm={4} xs={12} className={classes.gridNoPadding}>
                  <Button className={`${classes.addButton} ${classes.addButtonBrandingReset}`} onClick={this.resetBranding}>
                    Reset to Default
                    <Refresh className={classes.addCircleIconReset} />
                  </Button>
                </Grid>
              </Grid>
            }
            {(!enterprise && !onboarding) && 
              <Grid container spacing={2} className={classes.colorPickerContainer}> 
                <Grid item xs={12}>
                  <Typography variant="h2" className={classes.teamBrandHeading}>
                    Brand
                  </Typography>
                  <Button className={`${classes.addButton} ${classes.addButtonBrandingReset} ${classes.teamAddButtonBrandingReset}`} onClick={this.resetBranding}>
                    Reset to Default
                    <Refresh className={classes.addCircleIconReset} />
                  </Button>
                </Grid>
              </Grid>
            }
          </div>
          <Grid container spacing={1} className={classes.colorPickerContainer}>
            <Grid item xs={12}>
              <div className={classes.colorPickerRow}>
                <Grid container spacing={1} className={classes.colorPickerContainer} style={{padding: 0, background: '#fff', borderRadius: 15}}>
                  <Grid item xs={4}>
                    <RenderColorPicker 
                      label={'Primary'}
                      selectedColor={primary}
                      handleColor={this.handleColor}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <RenderColorPicker 
                      label={'Secondary'}
                      selectedColor={secondary}
                      handleColor={this.handleColor}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <RenderColorPicker 
                      label={'Accent'}
                      selectedColor={accent}
                      handleColor={this.handleColor}
                    />
                  </Grid>
                  {/*<Grid item xs={3}>
                    <RenderColorPicker 
                      label={'Background'}
                      selectedColor={background}
                      handleColor={this.handleColor}
                    />
                  </Grid>*/}
                </Grid>
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={2} className={classes.colorPickerContainer} style={{padding: 0, background: '#fff', borderRadius: 15}}>
            <Grid item sm={8} xs={12}>
              <div className={`${classes.thumbContainer} ${extraThumbContainerClass} ${webBackgroundImageContainer}`}>
                {background_image &&
                  <img className={`${classes.libraryThumbnail} ${classes.brandingThumbnail} ${classes.brandingBackgroundThumbnail} ${extraBrandingBackgroundThumbnail}`} src={background_image} alt={''} />
                }
              </div>
              <Typography variant="subtitle1" className={classes.subheadingUpload}>
                Upload a Background Image (min 500px x 300px, png/jpg/gif).<br />Can be larger, just keep to around a 5:3 width to height ratio.
                <IconButton 
                  className={classes.popoverIconButton}
                  onClick={(event) => this.openPopover(event, 'background_image')}
                >
                  <Info className={classes.popoverIcon} />
                </IconButton>
              </Typography>
              <FormControl margin="normal">
                <RenderUploadLabel
                  htmlFor={'background-image-upload'}
                  value={initialValues.branding_background_image}
                  addText={'Add Background'}
                  changeText={'Change Background'}
                  className={`${classes.uploadBrandingButton} ${uploadOnboardingButton}`}
                  classes={classes}
                />
                <Field 
                  id={'background-image-upload'}
                  name={'background_image'}
                  type={'file'}
                  className={`${classes.uploadedName} ${classes.uploadedNameBranding}`}
                  uploaded={backgroundImageUploaded}
                  updatePreview={this.updatePreview}
                  component={RenderUpload}
                />
              </FormControl>
            </Grid> 
            <Grid item sm={4} xs={12}>
              {/*<div className={classes.colorPickerRow}></div>
              <div className={`${classes.thumbContainer} ${classes.smallThumbContainer}`}>
                {logo ? <img className={`${classes.libraryThumbnail} ${classes.brandingThumbnail}`} src={logo} alt={''} /> : null}
              </div>
              <Typography variant="subtitle1" className={classes.subheadingUpload}>
                Upload an App Logo (min 450px x 112px,<br />Can be larger, just keep to around a 4:1-4:2 width to height ratio.
                <IconButton 
                  className={classes.popoverIconButton}
                  onClick={(event) => this.openPopover(event, 'logo')}
                >
                  <Info className={classes.popoverIcon} />
                </IconButton>
              </Typography>
              <FormControl margin="normal">
                <RenderUploadLabel
                  htmlFor={'logo-upload'}
                  value={initialValues.branding_logo}
                  addText={'Add App Logo'}
                  changeText={'Change App Logo'}
                  className={classes.uploadBrandingButton}
                  classes={classes}
                />
                <Field 
                  id={'logo-upload'}
                  name={'logo'}
                  type={'file'}
                  className={`${classes.uploadedName} ${classes.uploadedNameBranding}`}
                  updatePreview={this.updatePreview}
                  component={RenderUpload}
                />
              </FormControl>*/}
              <div className={`${classes.thumbContainer} ${classes.smallThumbContainer} ${webLogoImageContainer}`}>
                {small_logo && 
                  <img className={`${classes.libraryThumbnail} ${classes.brandingThumbnail}`} src={small_logo} alt={''} />
                }
              </div>
              <Typography variant="subtitle1" className={classes.subheadingUpload}>
                Upload a Web Logo (min 450px x 112px, png/jpg/gif).<br />Can be larger, just keep to around a 4:1-4:2 width to height ratio.
                <IconButton 
                  className={classes.popoverIconButton}
                  onClick={(event) => this.openPopover(event, 'small_logo')}
                >
                  <Info className={classes.popoverIcon} />
                </IconButton>
              </Typography>
              <FormControl margin="normal">
                <RenderUploadLabel
                  htmlFor={'small-logo-upload'}
                  value={initialValues.branding_small_logo}
                  addText={'Add Web Logo'}
                  changeText={'Change Web Logo'}
                  className={`${classes.uploadBrandingButton} ${uploadOnboardingButton}`}
                  classes={classes}
                />
                <Field 
                  id={'small-logo-upload'}
                  name={'small_logo'}
                  type={'file'}
                  className={`${classes.uploadedName} ${classes.uploadedNameBranding}`}
                  uploaded={logoUploaded}
                  updatePreview={this.updatePreview}
                  component={RenderUpload}
                />
              </FormControl>
              <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={this.closePopover}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                classes={{paper:classes.brandPaper}}
              >
                <Typography variant="subtitle1" className={classes.popoverSubheading}>
                  {popoverText}
                </Typography>
              </Popover>  
            </Grid>
          </Grid>

          {(enterprise && (currentRole === '0' || initialValues.owners.includes(owner))) &&
            <Grid container spacing={5}> 
              <Grid item sm={2} xs={0}>
              </Grid>
              <Grid item sm={8} xs={12}>
                <RenderOwnerEditableByMenu
                  currentUser={currentUser}
                  admins={admins}
                  selectedId={selectedBrandingId}
                  initialValues={initialValues}
                />
              </Grid>
              <Grid item sm={2} xs={0}>
              </Grid>
            </Grid>
          }

          <div className={onboardingButtonContainer}>
            <Button className={`${classes.button} ${extraButtonClass}`} variant="contained" type="submit" color="secondary" disabled={pristine || invalid}>
              {onboarding ? 'Next': 'Save'}
            </Button>
          </div>
          
        </div>
      )
    } 
    
  }

  render() {
    const { enterprise, handleSubmit, classes } =  this.props;

    let teamBrandFormStyle = null;

    if (!enterprise) {
      teamBrandFormStyle = classes.teamBrandForm
    }

    return (
      <form className={teamBrandFormStyle} noValidate autoComplete="off" onSubmit={handleSubmit(values => this.submitBrandingModal(values))}>
        {this.brandingForm()}
      </form>
    )
  }

}

function validate(values, props) {
  const errors = {};
  const requiredFields = [
    'name'
  ];

  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = 'Required';
    }
  });

  if (values.owners && values.owners.length === 0) {
    errors['owners'] = 'You must select at least one owner.'
  }

  return errors;
}

function mapStateToProps(state) {
  return { 
    token: state.login.token,
    companyId: state.login.company._id,
    enterprise: state.login.company.enterprise,
    owner: state.login.user._id,
    currentRole: state.login.user.user_type,
    currentUser: state.login.user,
    admins: state.admins,
    branding: state.branding,
    forms: state.form
  };
}

const mapDispatchToProps = dispatch => ({
  fetchAdmins: (token, companyId, callback) => dispatch(fetchAdmins(token, companyId, callback)),
  fetchBranding: (token, id, callback) => dispatch(fetchBranding(token, id, callback)),
  createBranding: (token, values, callback) => dispatch(createBranding(token, values, callback)),
  updateBranding: (token, values, id, callback) => dispatch(updateBranding(token, values, id, callback)),
  deleteBranding: (token, id, callback) => dispatch(deleteBranding(token, id, callback)),
});

export const BrandingFormContainer = reduxForm({
  form: 'Branding',
  validate,
  enableReinitialize: true
})(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(BrandingForm)));