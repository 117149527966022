import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

import { withStyles } from '@material-ui/core/styles';
import { styles } from '../styles';

// import { Grid, Typography, Button, IconButton, Modal, MenuItem, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core';
// import { Close, ExpandMore } from '@material-ui/icons';

import Grid from '@material-ui/core/Grid';
import GridContainer from '../subcomponents/gridContainer';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Modal from '@material-ui/core/Modal';
import MenuItem from '@material-ui/core/MenuItem';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

import Close from '@material-ui/icons/Close';
import ExpandMore from '@material-ui/icons/ExpandMore';

import LoadingDivContainer from '../subcomponents/loadingDiv';
import {
  cleanPutValues,
  modalMiniTableHeight,
  passwordHasSpaces,
  validateEmail,
  handleAppUser,
} from '../helpers/helperFunctions';
import { DeletePrivileges } from '../subcomponents/commonComponents';

import { RenderTextField } from '../subcomponents/form_subcomponents/renderTextField';
import { RenderSwitch } from '../subcomponents/form_subcomponents/renderSwitch';
import { RenderSelectField } from '../subcomponents/form_subcomponents/renderSelectField';
import { RenderOwnerEditableByMenu } from '../subcomponents/form_subcomponents/renderOwnerEditableByMenu';
import { RenderTransferOwnerMenu } from '../subcomponents/form_subcomponents/renderTransferOwnerMenu';
import { ErrorText } from '../subcomponents/form_subcomponents/errorText';

import {
  fetchUser,
  fetchAdmins,
  fetchTeams,
  fetchAppProfiles,
  createUser,
  updateUser,
  bulkUpdateUsers,
  deleteUser,
} from '../../actions/actions_index';

import { energy } from '../../theme';

class EditUserForm extends Component {
  constructor(props) {
    super(props);

    const { formError } = this.props;

    this.state = {
      error: formError,
      selectedChange: false,
      selectedItems: [],
      resetPassword: false,
      resetPasswordComplete: false,
    };

    this.onSelectionChange = (selected) => {
      this.setState({
        selectedChange: true,
        selectedItems: selected,
      });
    };

    this.props.reset();
  }

  componentDidMount() {
    const { enterprise, selectedTeamId, selectedUserId, teams, appProfiles } =
      this.props;

    let currentTeams = [];

    if (enterprise) {
      currentTeams = teams;
    } else {
      currentTeams = appProfiles;
    }

    let selectedGroups = [];

    if (!selectedUserId && currentTeams.length === 1) {
      selectedGroups.push(0);
    }

    if (!selectedUserId && selectedTeamId) {
      selectedGroups.push(_.findIndex(currentTeams, { _id: selectedTeamId }));
    }

    if (selectedUserId) {
      currentTeams.map((profile, index) => {
        if (profile.users.includes(selectedUserId)) {
          selectedGroups.push(index);
        }
        return null;
      });
    }

    this.setState({
      selectedItems: selectedGroups,
    });
  }

  teams = () => {
    const { enterprise, teams, appProfiles, classes } = this.props;
    const { selectedItems } = this.state;

    const columns = [
      { name: 'name', title: 'Team Name' },
      { name: 'updatedAt', title: 'Last Updated' },
    ];

    const tableColumnExtensions = [
      { columnName: 'name' },
      { columnName: 'updatedAt', width: 170 },
    ];

    const sortingStateColumnExtensions = [
      { columnName: 'name', sortingEnabled: true },
      { columnName: 'updatedAt', sortingEnabled: true },
    ];

    const filteringStateColumnExtensions = [
      { columnName: 'name', filteringEnabled: true },
      { columnName: 'updatedAt', filteringEnabled: false },
    ];

    const sorting = [{ columnName: 'name', direction: 'asc' }];

    let rows = teams,
      padding = 160;
    //     padding = 175;

    if (!enterprise) {
      rows = appProfiles;
      padding = 100;
    }

    return (
      <div
        style={{
          position: 'relative',
          marginTop: 10,
          height: `calc(100% - ${padding}px)`,
        }}
      >
        <Typography variant='h6'>Assign User to Teams</Typography>
        <GridContainer
          gridHeight={modalMiniTableHeight()}
          rows={rows}
          columns={columns}
          tableColumnExtensions={tableColumnExtensions}
          sortingStateColumnExtensions={sortingStateColumnExtensions}
          sorting={sorting}
          selection={selectedItems}
          onSelectionChange={this.onSelectionChange}
          filteringStateColumnExtensions={filteringStateColumnExtensions}
          showSelectAll={true}
          showSelectionColumn={true}
          showRowDetail={false}
          classes={classes}
        />
      </div>
    );
  };

  handleUserTypes = () => {
    const { enterprise, currentRole } = this.props;

    if (enterprise) {
      return (
        <span>
          {currentRole === '0' && (
            <MenuItem key='0' value='0'>
              Super Admin
            </MenuItem>
          )}
          {currentRole < '2' && (
            <MenuItem key='1' value='1'>
              Admin
            </MenuItem>
          )}
          {currentRole < '2' && (
            <MenuItem key='2' value='2'>
              App User
            </MenuItem>
          )}
        </span>
      );
    } else {
      return (
        <span>
          <MenuItem key='0' value='0'>
            Admin
          </MenuItem>
          <MenuItem key='2' value='2'>
            App User
          </MenuItem>
        </span>
      );
    }
  };

  userType = () => {
    //0: super_admin, 1: admin, 2: app user
    return (
      <div>
        <Field name='user_type' label='User Type' component={RenderSelectField}>
          {this.handleUserTypes()}
        </Field>
      </div>
    );
  };

  afterSubmitUserModal = (data) => {
    const { submitUserModal, closedUserModal } = this.props;

    if (data.data.errors) {
      let error = null;
      if (data.data.errors.email) {
        error = data.data.errors.email.message;
      }
      submitUserModal(false, error);
    } else {
      closedUserModal(true);
    }
  };

  submitUserModal = (values) => {
    const {
      token,
      companyId,
      enterprise,
      teams,
      appProfiles,
      selectedUserId,
      selectedUsers,
      initialValues,
      createUser,
      updateUser,
      bulkUpdateUsers,
      deleteUserModal,
      deleteUser,
      submitUserModal,
      closedUserModal,
    } = this.props;
    const { selectedItems } = this.state;

    submitUserModal(true, null);

    delete values.resetPassword;

    if (values.confirm_password) {
      delete values.confirm_password;
    }

    let added = [];

    if (enterprise) {
      added = selectedItems.map((value) => {
        return teams[value]._id;
      });
    } else {
      added = selectedItems.map((value) => {
        return appProfiles[value]._id;
      });
    }

    values.enterprise = enterprise;
    values.teams = added;

    if (selectedUsers) {
      if (!values.edit_teams) {
        delete values.teams;
      }

      values.users = selectedUsers;

      if (deleteUserModal) {
        values.deleteUsers = true;
      }

      bulkUpdateUsers(token, values, (data) => {
        this.afterSubmitUserModal(data);
      });
    } else if (selectedUserId && !deleteUserModal) {
      values = cleanPutValues(values);

      if (!initialValues.status && values.status) {
        values.sendInvite = true;
      }

      updateUser(token, values, selectedUserId, (data) => {
        if (data.errors) {
          let message = 'There was a problem submitting your request.';
          if (data.errors.email) {
            message = data.errors.email.message;
          }

          this.setState({
            error: message,
          });
        } else {
          this.afterSubmitUserModal(data);
        }
      });
    } else if (selectedUserId && deleteUserModal) {
      deleteUser(token, selectedUserId, values, () => {
        closedUserModal(true);
      });
    } else {
      values.company = companyId;

      createUser(token, values, (data) => {
        if (data.errors) {
          let message = 'There was a problem submitting your request.';
          if (data.errors.email) {
            message = data.errors.email.message;
          }

          this.setState({
            error: message,
          });
        } else {
          this.afterSubmitUserModal(data);
        }
      });
    }
  };

  startResetPassword = () => {
    this.setState({
      resetPassword: true,
    });
  };

  resetPassword = () => {
    const { token, selectedUserId, updateUser } = this.props;

    updateUser(token, { resetPassword: true }, selectedUserId, (data) => {
      this.setState({
        resetPasswordComplete: true,
      });
    });
  };

  handleUserStatuses = () => {
    return (
      <span>
        <MenuItem key='0' value='0'>
          Leave Users at Their Current Status
        </MenuItem>
        <MenuItem key='1' value='1'>
          Activate ALL Selected Users
        </MenuItem>
        <MenuItem key='2' value='2'>
          Deactivate ALL Selected Users
        </MenuItem>
      </span>
    );
  };

  userForm = () => {
    const {
      enterprise,
      appUser,
      live_only,
      verb_live,
      owner,
      currentRole,
      currentUser,
      admins,
      selectedUserId,
      selectedUsers,
      userForm,
      deleteUserModal,
      initialValues,
      classes,
      pristine,
      invalid,
    } = this.props;
    const { error, selectedChange, resetPassword, resetPasswordComplete } =
      this.state;

    let activeSwitchText = 'Activated',
      autoFocus = false,
      forceTouched = false,
      inviteText =
        'User will NOT receive an invite or have access to their app(s).',
      userText = 'user',
      showResetPassword = false,
      updatePasswordText = 'Update Password',
      resetPasswordText =
        'This will reset the users password and send them an email.';

    if (!selectedUserId) {
      autoFocus = true;
    }

    if (userForm && userForm.values && !userForm.values.status) {
      activeSwitchText = 'Deactivated';
    }

    if (
      selectedUserId &&
      !initialValues.status &&
      userForm &&
      userForm.values &&
      userForm.values.status
    ) {
      forceTouched = true;
    }

    if (
      userForm &&
      userForm.values &&
      userForm.values.status &&
      (!selectedUserId || (selectedUserId && !initialValues.status))
    ) {
      inviteText =
        'User will receive an invite email and can log into all verbTEAMS apps immediately.';
    } else if (
      selectedUserId &&
      initialValues.status &&
      userForm &&
      userForm.values &&
      !userForm.values.status
    ) {
      inviteText =
        'User will be deactivated and not be able to access verbTEAMS.';
    } else if (selectedUserId && initialValues.status) {
      inviteText = 'User is Active and can log into all verbTEAMS apps.';
    }

    if (selectedUsers) {
      inviteText = 'All selected users will remain at their current status.';

      if (userForm && userForm.values && userForm.values.status === '1') {
        inviteText =
          'All selected users be activated and will receive an invite email.';
      } else if (
        userForm &&
        userForm.values &&
        userForm.values.status === '2'
      ) {
        inviteText = 'All selected users will be deactivated.';
      }
    }

    if (owner !== selectedUserId && selectedUserId && initialValues.status) {
      showResetPassword = true;
      updatePasswordText = 'Manually Update Password';
    }

    if (live_only || appUser) {
      updatePasswordText = 'Update Password';
    }

    if (resetPasswordComplete) {
      resetPasswordText =
        "User's Password has been reset, and email with password and confirmation has been sent.";
    }

    let userType = '';

    switch (initialValues.user_type) {
      case '0':
        userType = 'a Super Admin';
        break;

      case '1':
        userType = 'an Admin';
        break;

      case '2':
        userType = 'an App User';
        break;

      default:
        break;
    }

    if (!enterprise && userType === '0') {
      userType = 'an Admin';
    }

    if (selectedUsers && selectedUsers.length > 1) {
      userText = 'users';
    }

    let gridSpacing = 6;

    if ((!enterprise && verb_live) || appUser) {
      gridSpacing = 12;
    }

    if (deleteUserModal) {
      return (
        <div>
          {(initialValues.user_type <= '1' ||
            (selectedUsers && selectedUsers.length > 0)) && (
            <div>
              <Typography
                variant='subtitle1'
                className={classes.noteSubheading}
              >
                {`NOTE: You must select a new owner for any items owned by the ${userText} you are deleting.`}
              </Typography>
              <br />
              <RenderTransferOwnerMenu
                deleting={initialValues._id}
                admins={admins}
              />
            </div>
          )}
          <Button
            className={`${classes.button} ${classes.modalButton}`}
            variant='contained'
            type='submit'
            color='secondary'
          >
            Delete
          </Button>
        </div>
      );
    } else {
      return (
        <div className={classes.divContainer}>
          <ErrorText
            className={classes.bigError}
            touched={true}
            error={error}
          />
          <Grid container spacing={5} className={classes.gridList}>
            <Grid
              item
              xs={gridSpacing}
              className={`${
                (enterprise && !appUser) || !verb_live
                  ? classes.gridBorderRight
                  : null
              } ${classes.gridItemLimitHeight} ${classes.gridNoPaddingTop}`}
            >
              {!selectedUsers && !live_only && !appUser && this.userType()}
              {!selectedUsers && !live_only && !appUser && (
                <span>
                  <Field
                    type='checkbox'
                    name='status'
                    label={activeSwitchText}
                    component={RenderSwitch}
                  />
                  <Typography
                    className={classes.importantInfoText}
                    variant='h5'
                  >
                    {inviteText}
                  </Typography>
                </span>
              )}
              {selectedUsers && !appUser && (
                <div>
                  <div style={{ marginBottom: 20 }}>
                    <Field
                      name='status'
                      label='Update Activation Status of ALL Selected Users'
                      component={RenderSelectField}
                    >
                      {this.handleUserStatuses()}
                    </Field>
                    <Typography
                      className={classes.importantInfoText}
                      variant='h5'
                    >
                      {inviteText}
                    </Typography>
                  </div>
                  <Field
                    type='checkbox'
                    name='edit_permissions'
                    label='Change Ownership Permissions of ALL Selected Users'
                    component={RenderSwitch}
                  />
                </div>
              )}
              {selectedUsers &&
                enterprise &&
                !appUser &&
                currentRole === '0' &&
                userForm &&
                userForm.values &&
                userForm.values.edit_permissions && (
                  <div>
                    <Typography
                      className={classes.importantInfoText}
                      variant='h5'
                    >
                      NOTE: Bulk assigning will override ALL selected user's
                      current ownership permissions.
                    </Typography>
                    <RenderOwnerEditableByMenu
                      currentUser={currentUser}
                      admins={admins}
                      selectedId={selectedUserId}
                      initialValues={initialValues}
                    />
                  </div>
                )}
              {!selectedUsers && (
                <span>
                  <Field
                    name='email'
                    component={RenderTextField}
                    type='email'
                    label='Email'
                    autoFocus={autoFocus}
                    autoComplete='new-email'
                  />
                  <Field
                    name='first_name'
                    component={RenderTextField}
                    type='text'
                    label='First Name'
                    autoComplete='new-email'
                  />
                  <Field
                    name='last_name'
                    component={RenderTextField}
                    type='text'
                    label='Last Name'
                    autoComplete='new-email'
                  />
                  <Field
                    name='phone'
                    component={RenderTextField}
                    type='text'
                    label='Phone Number'
                    autoComplete='new-email'
                  />
                  <Field
                    name='external_id'
                    component={RenderTextField}
                    type='text'
                    label='External Id'
                    autoComplete='new-email'
                  />
                </span>
              )}
              {/*(!selectedUsers && !selectedUserId && userForm && userForm.values && userForm.values.status) &&
                <span>  
                  <Typography className={classes.importantInfoText} variant="h5">Leave password blank if you want the user to create their own password.</Typography>
                  <Field name="password" component={RenderTextField} type="password" label="Password" autoComplete="new-password" />
                  <Field name="confirm_password" component={RenderTextField} type="password" label="Confirm Password" autoComplete="new-password" />
                </span>
              */}
              {/*{(!selectedUsers && owner === selectedUserId && selectedUserId) &&*/}
              {!selectedUsers && selectedUserId && (
                <ExpansionPanel className='expansionPanel'>
                  <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                    <div className={classes.column}>
                      <Typography variant='h5'>{updatePasswordText}</Typography>
                    </div>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails style={{ padding: '0px 15px 15px' }}>
                    <Field
                      formControlClassName={classes.textFieldNoMarginTop}
                      name='password'
                      component={RenderTextField}
                      type='password'
                      label='Password'
                      forceTouched={forceTouched}
                      autoComplete='new-password'
                    />
                    <Field
                      formControlClassName={classes.textFieldNoMarginBottom}
                      name='confirm_password'
                      component={RenderTextField}
                      type='password'
                      label='Confirm Password'
                      forceTouched={forceTouched}
                      autoComplete='new-password'
                    />
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              )}
              {!appUser && showResetPassword && (
                <React.Fragment>
                  <Typography
                    variant='h6'
                    style={{ padding: '20px 0 0 10px', fontSize: 18 }}
                  >
                    Or
                  </Typography>
                  <Button
                    className={`${classes.button} ${classes.resetButton}`}
                    variant='contained'
                    color='secondary'
                    onClick={this.startResetPassword}
                  >
                    Auto Reset Password
                  </Button>
                </React.Fragment>
              )}
              {initialValues.status && resetPassword && (
                <span>
                  <Typography
                    className={`${classes.importantInfoText} ${classes.importantConfirmResetPassword}`}
                    variant='h5'
                  >
                    {resetPasswordText}
                  </Typography>
                  <Button
                    className={`${classes.button} ${classes.resetButton} ${classes.confirmResetPassword}`}
                    variant='contained'
                    color='secondary'
                    onClick={this.resetPassword}
                    disabled={resetPasswordComplete}
                  >
                    Confirm Reset Password
                  </Button>
                </span>
              )}
            </Grid>
            {((enterprise && !appUser) || !verb_live) && currentRole <= '2' && (
              <Grid
                item
                xs={6}
                className={`${classes.containerPaddingLeft} ${classes.gridItemLimitHeight} ${classes.gridNoPaddingTop}`}
              >
                {/*(enterprise && initialValues.user_type <= '1') &&
                  <Field type="checkbox" name="force_downloads_option" label="Force Downloads Option" component={RenderSwitch} />
                */}
                {!selectedUsers &&
                  enterprise &&
                  (currentRole === '0' ||
                    initialValues.owners.includes(owner)) &&
                  userForm &&
                  userForm.values &&
                  userForm.values.user_type === '2' && (
                    <RenderOwnerEditableByMenu
                      currentUser={currentUser}
                      admins={admins}
                      selectedId={selectedUserId}
                      initialValues={initialValues}
                    />
                  )}
                {enterprise &&
                  userForm &&
                  userForm.values &&
                  userForm.values.user_type === '1' && (
                    <Typography
                      className={classes.importantInfoText}
                      variant='h5'
                    >
                      Once an Admin is created, ONLY Super Admins can edit them.
                    </Typography>
                  )}
                {selectedUsers && enterprise && (
                  <Field
                    type='checkbox'
                    name='edit_teams'
                    label='Change Assigned Teams of ALL Selected Users'
                    component={RenderSwitch}
                  />
                )}
                {selectedUsers &&
                  userForm &&
                  userForm.values &&
                  userForm.values.edit_teams && (
                    <Typography
                      className={classes.importantInfoText}
                      variant='h5'
                    >
                      NOTE: Bulk assigning users to teams will override any
                      selected user's current team selections.
                    </Typography>
                  )}
                {(!selectedUsers ||
                  (userForm &&
                    userForm.values &&
                    userForm.values.edit_teams)) &&
                  this.teams()}
              </Grid>
            )}
          </Grid>
          {userForm && userForm.values && !userForm.values.resetPassword && (
            <Button
              className={`${classes.button} ${classes.modalButton}`}
              variant='contained'
              color='secondary'
              type='submit'
              disabled={(pristine || invalid) && !selectedChange}
            >
              Save
            </Button>
          )}
        </div>
      );
    }
  };

  render() {
    const { handleSubmit } = this.props;
    return (
      <form
        noValidate
        autoComplete='off'
        onSubmit={handleSubmit((values) => this.submitUserModal(values))}
      >
        {this.userForm()}
      </form>
    );
  }
}

function validate(values, props) {
  const { selectedUserId, deleteUserModal, initialValues } = props;
  const errors = {};
  let requiredFields = ['first_name', 'last_name', 'email', 'user_type'];

  // if ((!selectedUserId && values.status)|| (!initialValues.status && values.status)) {
  //   requiredFields.push('password');
  //   requiredFields.push('confirm_password');
  // }

  if (deleteUserModal && initialValues && initialValues.user_type <= '1') {
    if (!values.transferId) {
      errors['transferId'] = 'Must select a transfer owner.';
    }
  }

  if (values.edit_permissions) {
    requiredFields.push('editable_by');
    requiredFields.push('owners');
  }

  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = 'Required';
    }
  });

  if (values.email && validateEmail(values.email)) {
    errors['email'] = 'Invalid email address';
  }

  if (values.password && passwordHasSpaces(values.password)) {
    errors['password'] = 'No Spaces Allowed in Passwords';
  }

  if (values.confirm_password && values.password !== values.confirm_password) {
    errors['confirm_password'] = 'Confirm Password does not match Password';
  }

  if (values.owners && values.owners.length === 0) {
    errors['owners'] = 'You must select at least one owner.';
  }

  return errors;
}

function mapStateToProps(state) {
  const currentRole = state.login.user.user_type;
  let owner = state.login.user._id;

  if (currentRole >= '2') {
    owner = state.login.user.parent_admin;
  }

  return {
    token: state.login.token,
    companyId: state.login.company._id,
    enterprise:
      state.login.user.user_type > '1' ? false : state.login.company.enterprise,
    live_only: state.login.company.live_only
      ? state.login.company.live_only
      : state.login.user.user_type > '1',
    appUser: handleAppUser(state),
    verb_live: state.login.company.verb_live,
    owner: owner,
    currentRole: state.login.user.user_type,
    currentUser: state.login.user,
    admins: state.admins,
    teams: state.teams,
    appProfiles: state.appProfiles,
    user: state.user,
    userForm: state.form.User,
  };
}

const mapDispatchToProps = (dispatch) => ({
  fetchUser: (token, id, callback) => dispatch(fetchUser(token, id, callback)),
  fetchAdmins: (token, companyId, callback) =>
    dispatch(fetchAdmins(token, companyId, callback)),
  fetchTeams: (token, companyId, callback) =>
    dispatch(fetchTeams(token, companyId, callback)),
  fetchAppProfiles: (token, companyId, callback) =>
    dispatch(fetchAppProfiles(token, companyId, callback)),
  createUser: (token, values, teamId, callback) =>
    dispatch(createUser(token, values, teamId, callback)),
  updateUser: (token, values, id, callback) =>
    dispatch(updateUser(token, values, id, callback)),
  bulkUpdateUsers: (token, values, callback) =>
    dispatch(bulkUpdateUsers(token, values, callback)),
  deleteUser: (token, id, values, callback) =>
    dispatch(deleteUser(token, id, values, callback)),
});

const EditUserFormContainer = reduxForm({
  form: 'User',
  validate,
  enableReinitialize: true,
})(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(EditUserForm))
);

class EditUserModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingUsers: true,
      loadingOther: true,
      formError: null,
    };
  }

  handleLoadingOther = () => {
    const { token, enterprise, companyId, appUser } = this.props;

    if (enterprise && !appUser) {
      this.props.fetchAdmins(token, companyId, (admins) => {
        this.props.fetchTeams(token, companyId, (admins) => {
          this.setState({
            loadingOther: false,
          });
        });
      });
    } else {
      this.props.fetchAdmins(token, companyId, (admins) => {
        this.props.fetchAppProfiles(token, companyId, (admins) => {
          this.setState({
            loadingOther: false,
          });
        });
      });
    }
  };

  componentDidMount() {
    const { token, companyId, selectedUserId } = this.props;

    if (selectedUserId) {
      this.props.fetchUser(token, selectedUserId, (user) => {
        this.setState({
          loadingUsers: false,
        });
      });

      this.handleLoadingOther();
    } else {
      this.setState({
        loadingUsers: false,
      });

      this.handleLoadingOther();
    }
  }

  userFormContainer = () => {
    const {
      enterprise,
      live_only,
      owner,
      user,
      selectedUserId,
      selectedUsers,
      addUserType,
      deleteUserModal,
      selectedTeamId,
      closedUserModal,
    } = this.props;
    const { formError } = this.state;

    let initialValues = {};

    if (selectedUserId) {
      initialValues = user;
    } else if (selectedUsers) {
      initialValues = {
        edit_permissions: false,
        edit_teams: false,
        status: '0',
      };
    } else {
      initialValues = {
        editable_by: '1',
        owners: [owner],
        status: false,
      };

      if (!enterprise && addUserType === 'Admin') {
        initialValues.user_type = '0';
      } else if (addUserType === 'Admin') {
        initialValues.user_type = '1';
      } else if (addUserType === 'App User') {
        initialValues.user_type = '2';
      } else if (addUserType === 'Super Admin') {
        initialValues.user_type = '0';
      }
    }

    return (
      <EditUserFormContainer
        selectedUserId={selectedUserId}
        selectedUsers={selectedUsers}
        deleteUserModal={deleteUserModal}
        initialValues={initialValues}
        selectedTeamId={selectedTeamId}
        submitUserModal={this.submitUserModal}
        closedUserModal={closedUserModal}
        formError={formError}
      />
    );
  };

  modalSize = () => {
    const {
      enterprise,
      appUser,
      verb_live,
      selectedUsers,
      deleteUserModal,
      classes,
    } = this.props;

    if (selectedUsers && deleteUserModal) {
      return classes.bulkUsersDeleteModal;
    } else if (deleteUserModal) {
      return classes.userDeleteModal;
    } else if ((!enterprise && verb_live) || appUser) {
      return classes.smallMediumModal;
    } else {
      return classes.mediumModal;
    }
  };

  submitUserModal = (value, error) => {
    this.setState({
      loadingUsers: value,
      formError: error,
    });
  };

  handleModalBody = () => {
    const {
      appUser,
      owner,
      live_only,
      currentRole,
      user,
      selectedUserId,
      selectedUsers,
      deleteUserModal,
      classes,
    } = this.props;
    const { loadingUsers, loadingOther } = this.state;

    let name = '',
      title = '',
      subheading = '',
      subheadingClass = classes.modalSubheading;

    if (user.first_name) {
      name = user.first_name;

      if (user.last_name) {
        name += ` ${user.last_name}`;
      }
    } else if (user.email) {
      name = user.email;
    }

    if (live_only || appUser) {
      title = 'Edit Profile Details';
      subheading = 'Edit your account information.';
    } else if (selectedUserId && !deleteUserModal) {
      title = (
        <span>
          Edit - <span className={classes.modalTitleItem}>{name}</span>
        </span>
      );
      subheading = 'Edit user details.';
    } else if (selectedUserId && deleteUserModal) {
      title = (
        <span>
          Delete - <span className={classes.modalTitleItem}>{name}</span>
        </span>
      );
      subheading = (
        <span style={{ color: energy }}>
          Are you sure you want to delete <i>{name}</i>?
        </span>
      );

      if (user.user_type <= '1') {
        subheadingClass = classes.modalSubheadingDeleteSmall;
      } else {
        subheadingClass = classes.modalSubheadingDelete;
      }
    } else if (selectedUsers && deleteUserModal) {
      title = <span>Bulk Delete Users</span>;
      subheading = (
        <span style={{ color: energy }}>
          DANGER: This action cannot be undone this will delete ALL selected
          users
        </span>
      );

      if (user.user_type <= '1') {
        subheadingClass = classes.modalSubheadingDeleteSmall;
      } else {
        subheadingClass = classes.modalSubheadingDelete;
      }
    } else if (selectedUsers) {
      title = 'Bulk Edit Users';
      subheading = 'Bulk edit user details.';
    } else {
      title = 'Add New User';
      subheading = 'Add user details.';
    }

    if (loadingUsers || loadingOther) {
      return <LoadingDivContainer type={'small'} />;
    } else if (
      deleteUserModal &&
      currentRole !== '0' &&
      (!user.owners.includes(owner) ||
        (currentRole >= '1' && user.user_type <= '1'))
    ) {
      return <DeletePrivileges className={classes.modalSubheadingDelete} />;
    } else {
      return (
        <div className={classes.divContainer}>
          <Typography variant='h6'>{title}</Typography>
          <Typography variant='subtitle1' className={subheadingClass}>
            {subheading}
          </Typography>
          {this.userFormContainer()}
        </div>
      );
    }
  };

  render() {
    const { openUserModal, closedUserModal, classes } = this.props;

    return (
      <Modal
        aria-labelledby='edit-user'
        aria-describedby='edit-user'
        open={openUserModal}
        onClose={() => closedUserModal(false)}
      >
        <div className={`${classes.modal} ${this.modalSize()}`}>
          <IconButton
            className={classes.iconClose}
            onClick={() => closedUserModal(false)}
          >
            <Close />
          </IconButton>
          {this.handleModalBody()}
        </div>
      </Modal>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(EditUserModal));
