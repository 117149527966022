import React from 'react';
import { Field } from "redux-form";

import MenuItem from '@material-ui/core/MenuItem';

import { RenderSelectField } from './renderSelectField';

const adminMenu = (deleting, admins) => {
  if (admins.length > 0) {
    return (
      admins.map(value => {
        if (deleting !== value._id) {
          return (
            <MenuItem key={value._id} value={value._id}>{value.name}</MenuItem>
          )
        }

        return null;
      })
    )
  }
}

export const RenderTransferOwnerMenu = ({ deleting, admins }) => {
  return (
    <Field
      name="transferId"
      label="Transfer Ownership"
      component={RenderSelectField}
      marginClass={'dense'}
    >
      {adminMenu(deleting, admins)}
    </Field>
  )
}