import { FETCH_ROOM_DEMOS, FETCH_ROOM_DEMOS_ANALYTICS } from "../actions/actions_room_demos";

export default function(state = [], action) {
  switch (action.type) {
    case FETCH_ROOM_DEMOS:
      if (!action.payload) {
        return state;
      }
      return action.payload;
      // return _.mapKeys(action.payload.data, "_id");

    case FETCH_ROOM_DEMOS_ANALYTICS:
      if (!action.payload) {
        return state;
      }
      return action.payload;
      
    default:
      return state;
  }
}