import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";

import { withStyles } from "@material-ui/core/styles";
import { styles } from "../styles";

import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Modal from "@material-ui/core/Modal";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Checkbox from "@material-ui/core/Checkbox";
import Close from "@material-ui/icons/Close";
import AddCircle from "@material-ui/icons/AddCircle";
import Refresh from "@material-ui/icons/Refresh";
import Star from "@material-ui/icons/Star";
import StarHalf from "@material-ui/icons/StarHalf";
import StarBorder from "@material-ui/icons/StarBorder";

import LoadingDivContainer from "../subcomponents/loadingDiv";
import {
  convertToDateString,
  convertToMb,
  cleanPutValues,
  handleAppUser,
} from "../helpers/helperFunctions";
import { RenderTextField } from "../subcomponents/form_subcomponents/renderTextField";
import { RenderSwitch } from "../subcomponents/form_subcomponents/renderSwitch";
import { RenderUploadLabel } from "../subcomponents/form_subcomponents/renderUploadLabel";
import { RenderUpload } from "../subcomponents/form_subcomponents/renderUpload";
import { RenderOwnerEditableByMenu } from "../subcomponents/form_subcomponents/renderOwnerEditableByMenu";
import TagsField from "../subcomponents/form_subcomponents/tagsField";
import { ErrorText } from "../subcomponents/form_subcomponents/errorText";
import FilestackButton from "../subcomponents/filestackButton";

import AddEmailAlternateModalContainer from "../modals/addEmailAlternateModal";

import {
  fetchLibraryItem,
  createLibraryItems,
  updateLibraryItem,
  updateLibraryItems,
  fetchAllMetaTags,
  fetchAllPersonaTags,
  fetchAllProductTags,
  fetchAdmins,
} from "../../actions/actions_index";

class EditLibraryItemForm extends Component {
  constructor(props) {
    super(props);

    const { library, initialValues, metaTags, personaTags, productTags } =
      this.props;

    let thumbnail = null,
      selectedFile = "",
      altEmailContentId = null,
      altEmailContentName = null;

    initialValues.thumbnail && (thumbnail = initialValues.thumbnail);
    initialValues.custom_thumbnail &&
      (thumbnail = initialValues.custom_thumbnail);

    if (initialValues.alt_email_content) {
      selectedFile = _.findIndex(library, [
        "_id",
        initialValues.alt_email_content,
      ]);
      altEmailContentId = initialValues.alt_email_content;
      altEmailContentName = library[selectedFile].name;
    }

    this.state = {
      metaTags: metaTags,
      personaTags: personaTags,
      productTags: productTags,
      tagError: null,
      openAddEmailAlternateModal: false,
      selectedFile: [selectedFile],
      altEmailContentId: altEmailContentId,
      altEmailContentName: altEmailContentName,
      error: null,
      uploadedNewVersionText: false,
      thumbnail: thumbnail,
      tabValue: 0,
      replaceLoading: false,
      tagAdded: false,
      canModifyAppShare: false,
      canModifyDownloadable: false,
      canModifyReshareable: false,
      canModifyHidden: false,
      canModifyLocked: false,
    };

    this.onSelectionChange = (selectedFile) => {
      if (selectedFile.length > 1) {
        selectedFile.shift();
      }

      this.setState({ selectedFile });
    };

    this.props.reset();
  }

  handleTagError = (error) => {
    this.setState({
      tagError: error,
    });
  };

  updateTags = (type, id, tags, error, newTag) => {
    if (newTag) {
      this.setState({
        tagAdded: true,
      });
    }

    switch (type) {
      case "Meta Tags":
        this.setState({
          metaTags: tags,
        });

        break;

      case "Persona Tags":
        this.setState({
          personaTags: tags,
        });

        break;

      case "Product Tags":
        this.setState({
          productTags: tags,
        });

        break;

      default:
        break;
    }
  };

  openAddEmailAlternateModal = () => {
    this.setState({
      openAddEmailAlternateModal: true,
    });
  };

  closedAddEmailAlternateModal = (updated, altContentId, altContentName) => {
    if (updated) {
      this.setState({
        altEmailContentId: altContentId,
        altEmailContentName: altContentName,
      });
    }

    this.setState({
      openAddEmailAlternateModal: false,
    });
  };

  uploadNewVersionSuccess = (
    result,
    parentId,
    groupId,
    selectedLibraryItemId
  ) => {
    const { token, itemUpdated, initialValues, updateLibraryItem } = this.props;

    let data = {};

    const newFile = result.filesUploaded[0];
    const name = newFile.originalPath.replace(/\//g, "");

    data.previous_path = initialValues.path;
    data.aws_bucket = newFile.container;
    data.aws_key = newFile.key;
    data.filename = name;
    data.path = `${process.env.REACT_APP_FILESTACK_S3_URL}${newFile.key.replace(
      / /g,
      "+"
    )}`;
    data.filestack_handle = newFile.handle;
    data.mimetype = newFile.mimetype;
    data.file_type = name.substring(name.lastIndexOf(".") + 1).toLowerCase();
    data.file_size = newFile.size;

    this.setState({
      replaceLoading: true,
    });

    updateLibraryItem(token, data, selectedLibraryItemId, (data) => {
      if (data.error) {
        this.setState({
          error: data.error,
        });
      } else {
        let thumbnail = data.data.details.thumbnail;

        if (data.data.details.custom_thumbnail) {
          thumbnail = data.data.details.custom_thumbnail;
        }

        this.setState({
          thumbnail: thumbnail,
          uploadedNewVersionText: true,
          replaceLoading: false,
        });

        itemUpdated();
      }
    });
  };

  submitLibraryItemModal = (values) => {
    //setTimeout is to wait for any errors from not entering the tags correctly, bad way to do it, but best option without lots of work
    setTimeout(() => {
      const {
        token,
        companyId,
        owner,
        selectedGroup,
        selectedLibraryItemId,
        selectedContent,
        createLibraryItems,
        updateLibraryItem,
        updateLibraryItems,
        submitLibraryItem,
        closedLibraryItemModal,
        appUser,
      } = this.props;
      const {
        metaTags,
        personaTags,
        productTags,
        tagError,
        altEmailContentId,
      } = this.state;

      //check if bulk editing and if there is an altEmailContentId
      if (!selectedContent && altEmailContentId) {
        values.alt_email_content = this.state.altEmailContentId;
      } else if (!selectedContent && altEmailContentId === "") {
        //if altEmailContentId removed
        values.alt_email_content = null;
      }

      if (tagError) {
        return;
      }

      submitLibraryItem();

      if (appUser) {
        values.personal = owner;
      }

      delete values.path;
      delete values.previous_path;
      delete values.filestack_handle;
      delete values.mimetype;
      delete values.file_size;
      delete values.reviews;

      values = cleanPutValues(values);

      //make sure no tag duplicates
      let metaTagsClean = _.uniqBy(metaTags.slice(), "value"),
        personaTagsClean = _.uniqBy(personaTags.slice(), "value"),
        productTagsClean = _.uniqBy(productTags.slice(), "value");

      values.meta_tags = JSON.stringify(metaTagsClean);
      values.persona_tags = JSON.stringify(personaTagsClean);
      values.product_tags = JSON.stringify(productTagsClean);

      if (!selectedLibraryItemId) {
        values.company = companyId;
      }

      let data = new FormData();

      if (values.files) {
        data.append("custom_thumbnail", values.files[0]);
      }

      _.map(values, (value, key) => {
        if (key === "owners") {
          value = JSON.stringify(value);
        }

        data.append(key, value);
      });

      // for (var pair of data.entries()) {
      //  console.log(pair[0]+ ', '+ pair[1]);
      // }

      let groupId = null;

      if (selectedGroup && selectedGroup._id !== "full-library") {
        groupId = selectedGroup._id;
      }

      if (selectedContent) {
        if (values.meta_tags === "[]") {
          delete values.meta_tags;
        }

        if (values.persona_tags === "[]") {
          delete values.persona_tags;
        }

        if (values.product_tags === "[]") {
          delete values.product_tags;
        }

        values.content = selectedContent;

        updateLibraryItems(token, values, (data) => {
          if (data.error) {
            this.setState({
              error: data.error,
            });
          } else if (groupId) {
            closedLibraryItemModal(true, true);
          } else {
            closedLibraryItemModal(true);
          }
        });
      } else if (!selectedLibraryItemId) {
        createLibraryItems(token, data, groupId, (data) => {
          if (data.error) {
            this.setState({
              error: data.error,
            });
          } else if (groupId) {
            closedLibraryItemModal(true, true);
          } else {
            closedLibraryItemModal(true);
          }
        });
      } else {
        updateLibraryItem(token, data, selectedLibraryItemId, (data) => {
          if (data.error) {
            this.setState({
              error: data.error,
            });
          } else {
            closedLibraryItemModal(true);
          }
        });
      }
    }, 350);
  };

  updatePreview = (name, value) => {
    let reader = new FileReader();

    reader.onload = (e) => {
      this.setState({ thumbnail: e.target.result });
    };
    reader.readAsDataURL(value[0]);
  };

  handleTabChange = (event, tabValue) => {
    this.setState({ tabValue });
  };

  handleRating = ({ rating, starRatingClass }) => {
    if (rating <= 0.5) {
      return (
        <span className={starRatingClass}>
          <StarHalf />
          <StarBorder />
          <StarBorder />
          <StarBorder />
          <StarBorder />
        </span>
      );
    } else if (rating <= 1) {
      return (
        <span className={starRatingClass}>
          <Star />
          <StarBorder />
          <StarBorder />
          <StarBorder />
          <StarBorder />
        </span>
      );
    } else if (rating <= 1.5) {
      return (
        <span className={starRatingClass}>
          <Star />
          <StarHalf />
          <StarBorder />
          <StarBorder />
          <StarBorder />
        </span>
      );
    } else if (rating <= 2) {
      return (
        <span className={starRatingClass}>
          <Star />
          <Star />
          <StarBorder />
          <StarBorder />
          <StarBorder />
        </span>
      );
    } else if (rating <= 2.5) {
      return (
        <span className={starRatingClass}>
          <Star />
          <Star />
          <StarHalf />
          <StarBorder />
          <StarBorder />
        </span>
      );
    } else if (rating <= 3) {
      return (
        <span className={starRatingClass}>
          <Star />
          <Star />
          <Star />
          <StarBorder />
          <StarBorder />
        </span>
      );
    } else if (rating <= 3.5) {
      return (
        <span className={starRatingClass}>
          <Star />
          <Star />
          <Star />
          <StarHalf />
          <StarBorder />
        </span>
      );
    } else if (rating <= 4) {
      return (
        <span className={starRatingClass}>
          <Star />
          <Star />
          <Star />
          <Star />
          <StarBorder />
        </span>
      );
    } else if (rating <= 4.5) {
      return (
        <span className={starRatingClass}>
          <Star />
          <Star />
          <Star />
          <Star />
          <StarHalf />
        </span>
      );
    } else if (rating <= 5) {
      return (
        <span className={starRatingClass}>
          <Star />
          <Star />
          <Star />
          <Star />
          <Star />
        </span>
      );
    }
  };

  submitBulkEdit(
    values,
    canModifyAppShare,
    canModifyDownloadable,
    canModifyReshareable,
    canModifyHidden,
    canModifyLocked
  ) {
    if (!canModifyAppShare) delete values.shareable;
    if (!canModifyDownloadable) delete values.downloadable;
    if (!canModifyReshareable) delete values.reshareable;
    if (!canModifyHidden) delete values.hidden;
    if (!canModifyLocked) delete values.locked;

    this.submitLibraryItemModal(values);
  }

  render() {
    const {
      enterprise,
      editablePdfs,
      owner,
      appUser,
      currentRole,
      selectedLibraryItemId,
      selectedContent,
      initialValues,
      itemStats,
      itemViewDuration,
      reviewStats,
      allMetaTags,
      allPersonaTags,
      allProductTags,
      classes,
      handleSubmit,
      currentUser,
      admins,
      previewModal,
      libraryForm,
      pristine,
      invalid,
    } = this.props;
    const {
      openAddEmailAlternateModal,
      selectedFile,
      altEmailContentId,
      altEmailContentName,
      error,
      uploadedNewVersionText,
      thumbnail,
      tabValue,
      metaTags,
      personaTags,
      productTags,
      replaceLoading,
      tagAdded,
      canModifyAppShare,
      canModifyDownloadable,
      canModifyReshareable,
      canModifyHidden,
      canModifyLocked,
    } = this.state;

    const {
      file_type,
      alt_path,
      custom_thumbnail,
      createdAt,
      updatedAt,
      file_size,
      owners,
      reviews,
    } = initialValues;

    let link = false,
      contentType = "File",
      gridSpacing = 4,
      extraBorder = classes.gridBorderRight,
      convertedPpt = false;

    if (!enterprise || appUser) {
      gridSpacing = 6;
      extraBorder = null;
    }

    if (selectedContent) {
      gridSpacing = 8;
    }

    if (!selectedLibraryItemId || file_type === "link") {
      link = true;
      contentType = "Link";
    }

    if (!selectedLibraryItemId || alt_path) {
      convertedPpt = true;
    }
    if (selectedContent)
      return (
        <form
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit((values) =>
            this.submitBulkEdit(
              values,
              canModifyAppShare,
              canModifyDownloadable,
              canModifyReshareable,
              canModifyHidden,
              canModifyLocked
            )
          )}>
          <ErrorText
            errorClassName={classes.bigError}
            touched={true}
            error={error}
          />
          <AppBar position="static" className={classes.modalTabBar}>
            <Tabs value={tabValue} onChange={this.handleTabChange}>
              <Tab label="Properties" className={classes.modalTab} />
              <Tab label="Tags" className={classes.modalTab} />
            </Tabs>
          </AppBar>
          {tabValue === 0 && (
            <Grid container spacing={3} className={classes.gridExtraSpacing}>
              {enterprise && !appUser && (
                <Grid
                  item
                  xs={gridSpacing}
                  className={`${classes.gridExtraSpacing} ${classes.containerPaddingLeft}`}>
                  <Typography
                    variant="subtitle1"
                    className={classes.editHeading}>
                    App Content Permissions
                  </Typography>
                  <Grid container spacing={12}>
                    <Grid item xs={1}>
                      modify
                    </Grid>
                    <Grid item xs={11}>
                      set permission
                    </Grid>
                  </Grid>

                  <Grid container spacing={12}>
                    <Grid item xs={1}>
                      <Checkbox
                        onChange={(event) =>
                          this.setState({
                            canModifyAppShare: event.target.checked,
                          })
                        }
                      />
                    </Grid>

                    <Grid item xs={11}>
                      <div style={{ opacity: canModifyAppShare ? 1 : 0.5 }}>
                        <Field
                          type="checkbox"
                          name="shareable"
                          label="Can be Shared from App"
                          component={RenderSwitch}
                        />
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container spacing={12}>
                    <Grid item xs={1}>
                      <Checkbox
                        onChange={(event) =>
                          this.setState({
                            canModifyDownloadable: event.target.checked,
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={11}>
                      <div style={{ opacity: canModifyDownloadable ? 1 : 0.5 }}>
                        <Field
                          type="checkbox"
                          name="downloadable"
                          label="Can be Downloaded from Space"
                          component={RenderSwitch}
                        />
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container spacing={12}>
                    <Grid item xs={1}>
                      <Checkbox
                        onChange={(event) =>
                          this.setState({
                            canModifyReshareable: event.target.checked,
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={11}>
                      <div style={{ opacity: canModifyReshareable ? 1 : 0.5 }}>
                        <Field
                          type="checkbox"
                          name="reshareable"
                          label="Can be Reshared from Space"
                          component={RenderSwitch}
                        />
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container spacing={12}>
                    <Grid item xs={1}>
                      <Checkbox
                        onChange={(event) =>
                          this.setState({
                            canModifyHidden: event.target.checked,
                          })
                        }
                      />
                    </Grid>

                    <Grid item xs={11}>
                      <div style={{ opacity: canModifyHidden ? 1 : 0.5 }}>
                        <Field
                          type="checkbox"
                          name="hidden"
                          label="Hide Content from App"
                          component={RenderSwitch}
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={12}>
                    <Grid item xs={1}>
                      <Checkbox
                        onChange={(event) =>
                          this.setState({
                            canModifyLocked: event.target.checked,
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={11}>
                      <div style={{ opacity: canModifyLocked ? 1 : 0.5 }}>
                        <Field
                          type="checkbox"
                          name="locked"
                          label="Lock Content from App"
                          component={RenderSwitch}
                        />
                      </div>
                    </Grid>
                  </Grid>

                  <span>
                    <Typography
                      variant="subtitle1"
                      className={`${classes.editHeading} ${classes.editSecondHeading}`}>
                      Change Ownership of Selected Items
                    </Typography>
                    <Field
                      type="checkbox"
                      name="edit_permissions"
                      label="Change Ownership Permissions of ALL Selected Items"
                      component={RenderSwitch}
                    />
                    {libraryForm &&
                      libraryForm.values &&
                      libraryForm.values.edit_permissions && (
                        <Typography
                          className={classes.importantInfoText}
                          variant="h5">
                          NOTE: Bulk assigning will override ALL selected
                          library item's current ownership permissions.
                        </Typography>
                      )}
                    {((!selectedContent &&
                      (currentRole === "0" || owners.includes(owner))) ||
                      (selectedContent &&
                        libraryForm &&
                        libraryForm.values &&
                        libraryForm.values.edit_permissions)) && (
                      <RenderOwnerEditableByMenu
                        currentUser={currentUser}
                        admins={admins}
                        selectedId={selectedLibraryItemId}
                        initialValues={initialValues}
                      />
                    )}
                  </span>
                </Grid>
              )}
            </Grid>
          )}
          {tabValue === 1 && (
            <Grid container spacing={3} className={classes.gridTagsSpacing}>
              <Grid item xs={12} className={classes.gridTagsSpacing}>
                <Typography
                  variant="subtitle1"
                  className={classes.bulkWarningHeading}>
                  NOTE: Entering Tags, will COMPLETELY Replace Tags for ALL
                  Selected Items, <br />
                  unless you select "Add to existing tags"
                </Typography>
                <Field
                  className={classes.tagsMarginTop}
                  type="checkbox"
                  name="add_to_existing_tags"
                  label="Add to Existing Tags"
                  component={RenderSwitch}
                />
                <TagsField
                  label={"Meta Tags"}
                  name={"meta_tags"}
                  tags={metaTags}
                  allTags={allMetaTags}
                  className={classes.tagsMarginTop}
                  handleTagError={this.handleTagError}
                  updateTags={this.updateTags}
                />
                <TagsField
                  label={"Persona Tags"}
                  name={"persona_tags"}
                  tags={personaTags}
                  allTags={allPersonaTags}
                  className={classes.tagsMarginTop}
                  handleTagError={this.handleTagError}
                  updateTags={this.updateTags}
                />
                <TagsField
                  label={"Product Tags"}
                  name={"product_tags"}
                  tags={productTags}
                  allTags={allProductTags}
                  className={classes.tagsMarginTop}
                  handleTagError={this.handleTagError}
                  updateTags={this.updateTags}
                />
              </Grid>
            </Grid>
          )}
          <Button
            className={`${classes.button} ${classes.modalButton}`}
            variant="contained"
            color="secondary"
            type="submit">
            Save
          </Button>
        </form>
      );
    else
      return (
        <form
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit((values) =>
            this.submitLibraryItemModal(values)
          )}>
          <ErrorText
            errorClassName={classes.bigError}
            touched={true}
            error={error}
          />
          <AppBar position="static" className={classes.modalTabBar}>
            {selectedLibraryItemId && !selectedContent && (
              <Tabs value={tabValue} onChange={this.handleTabChange}>
                <Tab label="Properties" className={classes.modalTab} />
                <Tab label="Tags" className={classes.modalTab} />
                <Tab label="Stats" className={classes.modalTab} />
                {!appUser && (
                  <Tab label="Ratings" className={classes.modalTab} />
                )}
              </Tabs>
            )}
            {(!selectedLibraryItemId || selectedContent) && (
              <Tabs value={tabValue} onChange={this.handleTabChange}>
                <Tab label="Properties" className={classes.modalTab} />
                <Tab label="Tags" className={classes.modalTab} />
              </Tabs>
            )}
          </AppBar>
          {tabValue === 0 && (
            <Grid container spacing={3} className={classes.gridExtraSpacing}>
              {!selectedContent && (
                <Grid
                  item
                  sm={gridSpacing}
                  xs={12}
                  className={`${classes.gridExtraSpacing} ${classes.gridBorderRight}`}>
                  <Typography variant="subtitle1">
                    {contentType} Properties
                  </Typography>
                  {thumbnail ? (
                    <img
                      className={classes.libraryThumbnail}
                      src={thumbnail}
                      alt={""}
                      onClick={() => {
                        previewModal("preview", selectedLibraryItemId, true);
                      }}
                    />
                  ) : null}
                  <FormControl margin="normal">
                    <RenderUploadLabel
                      htmlFor={"thumb-upload"}
                      value={custom_thumbnail}
                      addText={"Add Custom Thumbnail"}
                      changeText={"Change Custom Thumbnail"}
                      classes={classes}
                    />
                    <Field
                      id="thumb-upload"
                      name="files"
                      type="file"
                      className={classes.uploadedName}
                      updatePreview={this.updatePreview}
                      component={RenderUpload}
                    />
                  </FormControl>
                  <br />
                  <br />
                  {!link && (
                    <Grid container spacing={3}>
                      <Grid item xs={5}>
                        <Typography variant="h5">Date Created</Typography>
                        <Typography variant="h5">Last Updated</Typography>
                        <Typography variant="h5">File Size</Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Typography
                          variant="h5"
                          className={classes.headlineTorquoise}>
                          {convertToDateString(createdAt)}
                        </Typography>
                        <Typography
                          variant="h5"
                          className={classes.headlineTorquoise}>
                          {convertToDateString(updatedAt)}
                        </Typography>
                        <Typography
                          variant="h5"
                          className={classes.headlineTorquoise}>
                          {convertToMb(file_size)}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                  {(currentRole === "0" || owners.includes(owner)) && !link && (
                    <FilestackButton
                      buttonType={"newVersion"}
                      selectedGroup={{ parent: null, _id: null }}
                      selectedLibraryItemId={selectedLibraryItemId}
                      FULL_LIBRARY_ID={null}
                      uploadSuccess={this.uploadNewVersionSuccess}
                      uploadedNewVersionText={uploadedNewVersionText}
                    />
                  )}
                  {(currentRole === "0" || owners.includes(owner)) &&
                    !link &&
                    replaceLoading && (
                      <img
                        className={classes.replaceLoading}
                        src="/images/loading.svg"
                        alt={""}
                      />
                    )}
                </Grid>
              )}
              {(!enterprise || (enterprise && !selectedContent)) && (
                <Grid
                  item
                  sm={gridSpacing}
                  xs={12}
                  className={`${classes.gridExtraSpacing} ${extraBorder}`}>
                  {!selectedContent && (
                    <span>
                      <Typography
                        variant="subtitle1"
                        className={classes.editHeading}>
                        {contentType} Details
                      </Typography>
                      {!link && (
                        <Field
                          name="filename"
                          component={RenderTextField}
                          type="text"
                          label="Filename"
                          disabled
                        />
                      )}
                      {link && (
                        <Field
                          name="filename"
                          component={RenderTextField}
                          type="text"
                          label="URL"
                        />
                      )}
                      <Field
                        name="name"
                        component={RenderTextField}
                        type="text"
                        label="Display Name"
                      />
                      <Field
                        type="text"
                        name="description"
                        multiline={3}
                        component={RenderTextField}
                        label="Description"
                      />

                      {
                        //Public file link not needed for now
                        /*<Field type="checkbox" name="public" label="Public File Link" component={RenderSwitch} />*/
                      }
                    </span>
                  )}
                  {!enterprise && (
                    <Field
                      type="checkbox"
                      name="shareable"
                      label="Can be Shared from App"
                      component={RenderSwitch}
                    />
                  )}
                </Grid>
              )}
              {enterprise && !appUser && (
                <Grid
                  item
                  xs={gridSpacing}
                  className={`${classes.gridExtraSpacing} ${classes.containerPaddingLeft}`}>
                  <Typography
                    variant="subtitle1"
                    className={classes.editHeading}>
                    App Content Permissions
                  </Typography>
                  <Field
                    type="checkbox"
                    name="shareable"
                    label="Can be Shared from App"
                    component={RenderSwitch}
                  />
                  {!selectedContent && (
                    <span>
                      <FormLabel className={classes.emailAlternateContentLabel}>
                        Share Alternate Content
                      </FormLabel>
                      <Button
                        className={`${classes.addButton} ${classes.addAlternateContentButton}`}
                        onClick={this.openAddEmailAlternateModal}
                        focusRipple={false}>
                        {altEmailContentId ? (
                          <Refresh className={classes.addCircleIcon} />
                        ) : (
                          <AddCircle className={classes.addCircleIcon} />
                        )}
                        {altEmailContentId ? "Change" : "Add"}
                      </Button>
                      <Typography
                        className={classes.altEmailContentName}
                        variant="body1">
                        {altEmailContentName}
                      </Typography>
                      <AddEmailAlternateModalContainer
                        openAddEmailAlternateModal={openAddEmailAlternateModal}
                        selectedLibraryItemId={selectedLibraryItemId}
                        selectedFile={selectedFile}
                        onSelectionChange={this.onSelectionChange}
                        submitAddEmailAlternateModal={
                          this.submitAddEmailAlternateModal
                        }
                        closedAddEmailAlternateModal={
                          this.closedAddEmailAlternateModal
                        }
                      />
                    </span>
                  )}
                  {(!link || selectedContent) && (
                    <Field
                      type="checkbox"
                      name="downloadable"
                      label="Can be Downloaded from Space"
                      component={RenderSwitch}
                    />
                  )}
                  {/*<Field type="checkbox" name="shareable" label="Can be shared" component={RenderSwitch} />*/}
                  <Field
                    type="checkbox"
                    name="reshareable"
                    label="Can be Reshared from Space"
                    component={RenderSwitch}
                  />
                  <Field
                    type="checkbox"
                    name="hidden"
                    label="Hide Content from App"
                    component={RenderSwitch}
                  />
                  <Field
                    type="checkbox"
                    name="locked"
                    label="Lock Content from App"
                    component={RenderSwitch}
                  />
                  {editablePdfs && file_type === "pdf" && (
                    <Field
                      type="checkbox"
                      name="editable_pdf"
                      label="PDF can be edited from App"
                      component={RenderSwitch}
                    />
                  )}
                  {/*<<Field type="checkbox" name="sorter" label="Show Slide Sorter" component={RenderSwitch} />*/}
                  {!selectedContent && convertedPpt && (
                    <Field
                      type="checkbox"
                      name="not_app_editable"
                      label="Do not allow slide editing in the app"
                      component={RenderSwitch}
                    />
                  )}
                  {selectedContent && (
                    <span>
                      <Typography
                        variant="subtitle1"
                        className={`${classes.editHeading} ${classes.editSecondHeading}`}>
                        Change Ownership of Selected Items
                      </Typography>
                      <Field
                        type="checkbox"
                        name="edit_permissions"
                        label="Change Ownership Permissions of ALL Selected Items"
                        component={RenderSwitch}
                      />
                      {libraryForm &&
                        libraryForm.values &&
                        libraryForm.values.edit_permissions && (
                          <Typography
                            className={classes.importantInfoText}
                            variant="h5">
                            NOTE: Bulk assigning will override ALL selected
                            library item's current ownership permissions.
                          </Typography>
                        )}
                    </span>
                  )}
                  {((!selectedContent &&
                    (currentRole === "0" || owners.includes(owner))) ||
                    (selectedContent &&
                      libraryForm &&
                      libraryForm.values &&
                      libraryForm.values.edit_permissions)) && (
                    <RenderOwnerEditableByMenu
                      currentUser={currentUser}
                      admins={admins}
                      selectedId={selectedLibraryItemId}
                      initialValues={initialValues}
                    />
                  )}
                </Grid>
              )}
            </Grid>
          )}
          {tabValue === 1 && (
            <Grid container spacing={3} className={classes.gridTagsSpacing}>
              <Grid item xs={12} className={classes.gridTagsSpacing}>
                {selectedContent && (
                  <Typography
                    variant="subtitle1"
                    className={classes.bulkWarningHeading}>
                    NOTE: Entering Tags, will COMPLETELY Replace Tags for ALL
                    Selected Items
                  </Typography>
                )}
                <TagsField
                  label={"Meta Tags"}
                  name={"meta_tags"}
                  tags={metaTags}
                  allTags={allMetaTags}
                  className={classes.tagsMarginTop}
                  handleTagError={this.handleTagError}
                  updateTags={this.updateTags}
                />
                <TagsField
                  label={"Persona Tags"}
                  name={"persona_tags"}
                  tags={personaTags}
                  allTags={allPersonaTags}
                  className={classes.tagsMarginTop}
                  handleTagError={this.handleTagError}
                  updateTags={this.updateTags}
                />
                <TagsField
                  label={"Product Tags"}
                  name={"product_tags"}
                  tags={productTags}
                  allTags={allProductTags}
                  className={classes.tagsMarginTop}
                  handleTagError={this.handleTagError}
                  updateTags={this.updateTags}
                />
              </Grid>
            </Grid>
          )}
          {tabValue === 2 && (
            <Grid
              container
              spacing={3}
              className={`${classes.gridExtraSpacing}`}>
              <Grid item xs={6}>
                <Grid container spacing={3}>
                  <Grid item xs={8} className={classes.gridExtraSpacing}>
                    <Typography variant="h5">Browses in App</Typography>
                    <Typography variant="h5">Shows in App</Typography>
                    <Typography variant="h5">Shares from App</Typography>
                    <Typography variant="h5">Views on Web</Typography>
                    <Typography variant="h5">Avg. Web View Duration</Typography>
                    <Typography variant="h5">
                      Total Web View Duration
                    </Typography>
                    <Typography variant="h5">Re-Shares from Web</Typography>
                    <Typography variant="h5">Downloads from Web</Typography>
                  </Grid>
                  <Grid item xs={4} className={classes.gridExtraSpacing}>
                    <Typography
                      variant="h5"
                      className={classes.headlineTorquoise}>
                      {itemStats.browses ? itemStats.browses : 0}
                    </Typography>
                    <Typography
                      variant="h5"
                      className={classes.headlineTorquoise}>
                      {itemStats.shows ? itemStats.shows : 0}
                    </Typography>
                    <Typography
                      variant="h5"
                      className={classes.headlineTorquoise}>
                      {itemStats.shares ? itemStats.shares : 0}
                    </Typography>
                    <Typography
                      variant="h5"
                      className={classes.headlineTorquoise}>
                      {itemStats.total_media_views
                        ? itemStats.total_media_views
                        : 0}
                    </Typography>
                    <Typography
                      variant="h5"
                      className={classes.headlineTorquoise}>
                      {itemViewDuration.avg_view
                        ? itemViewDuration.avg_view
                        : "00:00"}
                    </Typography>
                    <Typography
                      variant="h5"
                      className={classes.headlineTorquoise}>
                      {itemViewDuration.total_view
                        ? itemViewDuration.total_view
                        : "00:00"}
                    </Typography>
                    <Typography
                      variant="h5"
                      className={classes.headlineTorquoise}>
                      {itemStats.media_shares ? itemStats.media_shares : 0}
                    </Typography>
                    <Typography
                      variant="h5"
                      className={classes.headlineTorquoise}>
                      {itemStats.media_downloads
                        ? itemStats.media_downloads
                        : 0}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
          {tabValue === 3 && (
            <Grid container spacing={3} className={`${classes.reviewsGrid}`}>
              <Grid item xs={12} className={classes.reviewsGridItem}>
                <Typography variant="h2" className={classes.teamRating}>
                  Average Rating{" "}
                  <span
                    className={
                      reviewStats.average_review === "No Reviews"
                        ? classes.averageReviewNoRatings
                        : classes.averageReview
                    }>
                    {reviewStats.average_review}
                  </span>{" "}
                </Typography>
                {reviews.map((review) => (
                  <div key={review._id} className={classes.review}>
                    <Typography variant="h6">
                      {review.name}{" "}
                      {this.handleRating({
                        rating: review.rating,
                        starRatingClass: classes.starRating,
                      })}
                    </Typography>
                    <Typography variant="h6" className={classes.reviewDate}>
                      {convertToDateString(review.date)}
                    </Typography>
                    <Typography variant="h5" className={classes.reviewReview}>
                      {review.review}
                    </Typography>
                  </div>
                ))}
              </Grid>
            </Grid>
          )}
          {/*{this.handleRating({ rating: reviewStats.average_review, starRatingClass: classes.starRatingAverage })*/}
          <Button
            className={`${classes.button} ${classes.modalButton}`}
            variant="contained"
            color="secondary"
            disabled={
              (!altEmailContentId &&
                pristine &&
                !selectedContent &&
                !selectedLibraryItemId) ||
              (!altEmailContentId &&
                pristine &&
                selectedLibraryItemId &&
                !tagAdded) ||
              (!altEmailContentId &&
                pristine &&
                selectedContent &&
                !tagAdded) ||
              invalid
            }
            type="submit">
            Save
          </Button>
        </form>
      );
  }
}

function validate(values, props) {
  const errors = {};
  const requiredFields = ["filename", "name"];

  if (!_.isEmpty(values)) {
    requiredFields.forEach((field) => {
      if (!values[field]) {
        errors[field] = "Required";
      }
    });

    if (values.owners && values.owners.length === 0) {
      errors["owners"] = "You must select at least one owner.";
    }
  }

  return errors;
}

function mapStateToProps(state) {
  return {
    token: state.login.token,
    companyId: state.login.company._id,
    enterprise: state.login.company.enterprise,
    owner: state.login.user._id,
    appUser: handleAppUser(state),
    currentUser: state.login.user,
    currentRole: state.login.user.user_type,
    library: state.library,
    libraryItem: state.libraryItem,
    allMetaTags: state.allTags.meta,
    allPersonaTags: state.allTags.persona,
    allProductTags: state.allTags.product,
    admins: state.admins,
    libraryForm: state.form.LibraryItem,
    editablePdfs: state.company.editable_pdfs,
  };
}

const mapDispatchToProps = (dispatch) => ({
  fetchLibraryItem: (token, id, callback) =>
    dispatch(fetchLibraryItem(token, id, callback)),
  createLibraryItems: (token, files, groupId, callback) =>
    dispatch(createLibraryItems(token, files, groupId, callback)),
  updateLibraryItem: (token, values, id, callback) =>
    dispatch(updateLibraryItem(token, values, id, callback)),
  updateLibraryItems: (token, values, callback) =>
    dispatch(updateLibraryItems(token, values, callback)),
  fetchAllMetaTags: (token, companyId, callback) =>
    dispatch(fetchAllMetaTags(token, companyId, callback)),
  fetchAllPersonaTags: (token, companyId, callback) =>
    dispatch(fetchAllPersonaTags(token, companyId, callback)),
  fetchAllProductTags: (token, companyId, callback) =>
    dispatch(fetchAllProductTags(token, companyId, callback)),
  fetchAdmins: (token, companyId, callback) =>
    dispatch(fetchAdmins(token, companyId, callback)),
});

const LibraryItemFormContainer = reduxForm({
  form: "LibraryItem",
  validate,
  enableReinitialize: true,
  // initialValues: {}
})(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(EditLibraryItemForm))
);

class EditLibraryItemModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingLibraryItem: true,
      loadingAdmins: true,
      loadingMetaTags: true,
      loadingPersonaTags: true,
      loadingProductTags: true,
      updated: false,
      newLink: false,
    };
  }

  componentDidMount() {
    const { token, companyId, selectedLibraryItemId } = this.props;

    if (selectedLibraryItemId) {
      this.props.fetchLibraryItem(
        token,
        selectedLibraryItemId,
        (libraryItem) => {
          this.setState({
            loadingLibraryItem: false,
          });
        }
      );
    } else {
      this.setState({
        newLink: true,
        loadingLibraryItem: false,
      });
    }

    this.props.fetchAdmins(token, companyId, (admins) => {
      this.setState({
        loadingAdmins: false,
      });
    });

    this.props.fetchAllMetaTags(token, companyId, (metaTags) => {
      this.setState({
        loadingMetaTags: false,
      });
    });

    this.props.fetchAllPersonaTags(token, companyId, (personaTags) => {
      this.setState({
        loadingPersonaTags: false,
      });
    });

    this.props.fetchAllProductTags(token, companyId, (productTags) => {
      this.setState({
        loadingProductTags: false,
      });
    });
  }

  itemUpdated = () => {
    this.setState({
      updated: true,
    });
  };

  submitLibraryItem = () => {
    this.setState({
      loadingLibraryItem: true,
    });
  };

  handleModalBody = () => {
    const {
      libraryItem,
      selectedLibraryItemId,
      selectedContent,
      selectedGroup,
      classes,
      closedLibraryItemModal,
      previewModal,
      owner,
    } = this.props;
    const {
      loadingLibraryItem,
      loadingAdmins,
      loadingMetaTags,
      loadingPersonaTags,
      loadingProductTags,
      newLink,
    } = this.state;

    let name = "",
      metaTags = [],
      personaTags = [],
      productTags = [],
      details = {},
      itemStats = null,
      itemViewDuration = null,
      reviewStats = null;

    if (
      loadingLibraryItem ||
      loadingAdmins ||
      loadingMetaTags ||
      loadingPersonaTags ||
      loadingProductTags
    ) {
      return <LoadingDivContainer />;
    } else {
      if (!newLink) {
        name = libraryItem.details.name;
        metaTags = libraryItem.details.meta_tags;
        personaTags = libraryItem.details.persona_tags;
        productTags = libraryItem.details.product_tags;

        if (!libraryItem.details.editable_by) {
          libraryItem.details.editable_by = "0";
        }

        details = libraryItem.details;
        itemStats = libraryItem.itemStats;
        itemViewDuration = libraryItem.itemViewDuration;
        reviewStats = libraryItem.reviewStats;
      } else if (!selectedContent) {
        details.link = true;
        details.file_type = "link";
        details.editable_by = "0";
        details.owners = [owner];
        details.shareable = true;
      } else if (selectedContent) {
        details.shareable = true;
        details.downloadable = true;
        details.reshareable = true;
        details.hidden = false;
        details.locked = false;
      }

      return (
        <div className={classes.divContainer}>
          {selectedContent && (
            <div>
              <Typography variant="h6">Bulk Edit Selected Items</Typography>
              <Typography
                variant="subtitle1"
                className={classes.modalSubheading}>
                NOTE: This will update all selected items to the settings and
                tags you enter.
              </Typography>
            </div>
          )}
          {!selectedContent && !newLink && (
            <div>
              <Typography variant="h6">
                Edit - <span className={classes.modalTitleItem}>{name}</span>
              </Typography>
              <Typography
                variant="subtitle1"
                className={classes.modalSubheading}>
                Edit library item settings.
              </Typography>
            </div>
          )}
          {!selectedContent && newLink && (
            <div>
              <Typography variant="h6">Add Link</Typography>
              <Typography
                variant="subtitle1"
                className={classes.modalSubheading}>
                Add a link to the library.
              </Typography>
            </div>
          )}
          <LibraryItemFormContainer
            selectedLibraryItemId={selectedLibraryItemId}
            selectedContent={selectedContent}
            selectedGroup={selectedGroup}
            initialValues={details}
            itemStats={itemStats}
            itemViewDuration={itemViewDuration}
            reviewStats={reviewStats}
            metaTags={metaTags}
            personaTags={personaTags}
            productTags={productTags}
            submitLibraryItem={this.submitLibraryItem}
            closedLibraryItemModal={closedLibraryItemModal}
            itemUpdated={this.itemUpdated}
            previewModal={previewModal}
          />
        </div>
      );
    }
  };

  render() {
    const { openLibraryItemModal, closedLibraryItemModal, classes } =
      this.props;
    const { updated } = this.state;

    return (
      <Modal
        aria-labelledby="preview-library-item"
        aria-describedby="preview-library-item"
        open={openLibraryItemModal}
        onClose={() => closedLibraryItemModal(updated)}>
        <div className={`${classes.modal}`}>
          <IconButton
            className={classes.iconClose}
            onClick={() => closedLibraryItemModal(updated)}>
            <Close />
          </IconButton>
          {this.handleModalBody()}
        </div>
      </Modal>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(EditLibraryItemModal));
