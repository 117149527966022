import React, { Component } from 'react';

// import { ListItemIcon, Menu, MenuItem } from '@material-ui/core';
// import { Edit, SupervisorAccount, Delete } from '@material-ui/icons';

import ListItemIcon from '@material-ui/core/ListItemIcon';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import Edit from '@material-ui/icons/Edit';
import SupervisorAccount from '@material-ui/icons/SupervisorAccount';
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import Delete from '@material-ui/icons/Delete';

class TeamMenu extends Component {

	render() {
		const { enterprise, anchorEl, open, onClose } = this.props;

		return (
			<Menu
			  anchorEl={anchorEl}
			  open={open}
			  onClose={onClose}
			>
			  <MenuItem onClick={() => onClose('edit', null, true)}>
			    <ListItemIcon>
			      <Edit  />
			    </ListItemIcon>
			    Edit
			  </MenuItem>
			  <MenuItem onClick={() => onClose('add-users')}>
			    <ListItemIcon>
			      <SupervisorAccount />
			    </ListItemIcon>
			    Assign Team Members
			  </MenuItem>
			  {!enterprise &&
				  <MenuItem onClick={() => onClose('add-content')}>
				    <ListItemIcon>
				      <LibraryBooks />
				    </ListItemIcon>
				    Assign Content Groups
				  </MenuItem>
			  }
			  <MenuItem onClick={() => onClose('delete')}>
			    <ListItemIcon>
			      <Delete />
			    </ListItemIcon>
			    Delete from System
			  </MenuItem>
			</Menu>
		)

	}
}

export default TeamMenu;