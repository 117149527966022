import React from 'react';

import AddCircle from '@material-ui/icons/AddCircle';
import Refresh from '@material-ui/icons/Refresh';

export const RenderUploadLabel = ({
  htmlFor,
  value,
  changeText,
  addText,
  className,
  classes
}) => {
  return (
    <label htmlFor={htmlFor} className={`${classes.uploadButton} ${className}`}>
    {value ? <Refresh className={`${classes.addCircleIcon} ${classes.uploadButtonIcon}`} /> : <AddCircle className={`${classes.addCircleIcon} ${classes.uploadButtonIcon}`} /> }
    {value ? changeText : addText }
    </label>
  )
};
