import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';

import Info from '@material-ui/icons/Info';

import HeaderButtons from './subcomponents/headerButtons'

import { MDandBelow, handleAppUser, handleEnterpriseAppUser, appProfileSettings, checkDoesNotInclude } from './helpers/helperFunctions';
import { black, verbBlue, evenLighterGray } from '../theme';

class Footer extends Component {
	constructor(props) {
		super(props)

		this.state = {
			clientWidth: null,
			appUserInteractiveVideo: true,
			appUserVerbLive: true
		}
	}

	reloadAppProfileSettings(newAppProfile) {
		const { selectedAppProfile } = this.props,
					handleAppProfile = newAppProfile ? newAppProfile : selectedAppProfile,
					{ appUserInteractiveVideos, appUserVerbLive } = appProfileSettings(handleAppProfile)

		this.setState({
			appUserInteractiveVideos,
			appUserVerbLive
		})
	}
	
	componentDidMount() {
		this.reloadAppProfileSettings()

		this.updateWindowSize()
    window.addEventListener("resize", this.updateWindowSize)
	}

	shouldComponentUpdate(nextProps, nextState) {
    const { selectedAppProfile } = this.props,
          newAppProfile = nextProps.selectedAppProfile,
          oldId = selectedAppProfile ? selectedAppProfile._id : null,
          newId = newAppProfile ? newAppProfile._id : null

    if (oldId !== newId) {
      this.reloadAppProfileSettings(newAppProfile)
    }

    return true
  }

	updateWindowSize = () => {
    this.setState({clientWidth: window.innerWidth})
  }

  reloadPage = () => {
  	window.location.reload()
  }

	render() {
		const { appVersion, enterpriseAppUser, live_only, appUser, itemsToShare, location, learn, learnJwt, classes } = this.props,
					{ clientWidth, appUserInteractiveVideos, appUserVerbLive } = this.state,
					current_version = process.env.REACT_APP_VERSION,
					currentPath = location.pathname,
					showFooter = !checkDoesNotInclude(currentPath)

		return(
			<footer>
				{showFooter &&
					<React.Fragment>
					{appVersion && current_version < appVersion &&
						<Card style={{position: 'fixed', bottom: 5, left: 5}}>
				      <CardContent style={{paddingBottom: 4}}>
				        <Typography variant="body2" component="p">
				          <Info className={classes.infoRefreshIcon} />A new version of verbTEAMS is available!
				        </Typography>
				        <Typography variant="body2" component="p" color="textSecondary" style={{marginLeft: 19}}>
				          Refresh to get the latest features and fixes.
				        </Typography>
				      </CardContent>
				      <CardActions style={{justifyContent: 'right'}}>
				        <Button size="small" onClick={this.reloadPage} style={{padding: '4px 12px 0px', color: verbBlue, backgroundColor: evenLighterGray, fontWeight: 600}}>REFRESH</Button>
				      </CardActions>
				    </Card>
					}
					{((!appUser && live_only && clientWidth < 960) || (appUser && clientWidth < 1280)) && 
						<div className={classes.footerLiveOnly}>
							<Grid container spacing={0} justifyContent="center" alignItems="center" style={{height: '100%'}}>
								<HeaderButtons
									enterpriseAppUser={enterpriseAppUser}
									learnJwt={learnJwt}
									learn={learn}
									live_only={live_only}
									itemsToShare={itemsToShare}
									appUserInteractiveVideos={appUserInteractiveVideos}
									appUserVerbLive={appUserVerbLive}
								/>
							</Grid>
						</div>
					}
					</React.Fragment>
				}
			</footer>
		)
	}
}

function mapStateToProps(state) {
  if (_.isEmpty(state.login.token) || _.isEmpty(state.login.company) || _.isEmpty(state.login.user)) {
    return {
      loggedOut: true
    }
  }

  return {
  	appVersion: state.appVersion.version,
  	enterprise: state.login.user.user_type > '1' ? false : state.login.company.enterprise,
  	selectedAppProfile: state.appProfile,
    live_only: state.login.company.live_only ? state.login.company.live_only : state.login.user.user_type > '1',
    appUser: handleAppUser(state),
    enterpriseAppUser: handleEnterpriseAppUser(state),
    itemsToShare: state.itemsToShare,
    learn: state.company.learn,
    learnJwt: state.learn
  };
}

export default withRouter(connect(mapStateToProps, null)(withStyles(styles)(Footer)));