import axios from "axios";
import { ROOT_URL } from "./actions_constants";

export const FETCH_MESSAGES = "fetch_messages";
export const FETCH_MESSAGE = "fetch_message";
export const CREATE_MESSAGE = "create_message";
export const UPDATE_MESSAGE = "update_message";
export const DELETE_MESSAGE = "delete_message";

const fetchMessagesSuccess = messages => ({
  type: FETCH_MESSAGES,
  payload: messages
});

export const fetchMessages = (token, companyId, callback) => (dispatch) => {

  const TOKEN = token;

  return (axios.get(`${ROOT_URL}company/messages/${companyId}`, 
    { 
      headers: { 
        Authorization: 'Bearer ' + TOKEN
      }
    }))
    .then(result => {
      const messages = result.data;

      dispatch(fetchMessagesSuccess(messages));

      if(callback) {
        callback(result);
      }
      
      return result;
    })
    .catch(error => {
      console.log(error)
      
      if (error.response && error.response.data) {
        callback(error.response.data)
      }
    })
}

const fetchMessageSuccess = message => ({
  type: FETCH_MESSAGE,
  payload: message
});

export const fetchMessage = (token, id, callback) => (dispatch) => {

  const TOKEN = token;

  return (axios.get(`${ROOT_URL}messages/${id}`, 
    { 
      headers: { 
        Authorization: 'Bearer ' + TOKEN
      }
    }))
    .then(result => {
      const message = result.data;

      dispatch(fetchMessageSuccess(message));

      if(callback) {
        callback(result);
      }
      
      return result;
    })
    .catch(error => {
      console.log(error)
      
      if (error.response && error.response.data) {
        callback(error.response.data)
      }
    })
}

const createMessageSuccess = message => ({
  type: CREATE_MESSAGE,
  payload: message
});

export const createMessage = (token, values, callback) => (dispatch) => {

  const TOKEN = token;

  return (axios.post(`${ROOT_URL}messages`,
      values,
      { 
        headers: { 
          Authorization: 'Bearer ' + TOKEN
        }
      }))
    .then(result => {
      const message = result.data;

      dispatch(createMessageSuccess(message));

      if(callback) {
        callback(result);
      }

      return result;
    })
    .catch(error => {
      console.log(error)
      
      if (error.response && error.response.data) {
        callback(error.response.data)
      }
    })
}

const updateMessageSuccess = message => ({
  type: UPDATE_MESSAGE,
  payload: message
});

export const updateMessage = (token, values, id, callback) => (dispatch) => {

  const TOKEN = token;

  return (axios.put(`${ROOT_URL}messages/${id}`, 
    values,
    { 
      headers: { 
        Authorization: 'Bearer ' + TOKEN
      }
    }))
    .then(result => {
      const messagesItem = result.data;

      dispatch(updateMessageSuccess(messagesItem));

      if(callback) {
        callback(result);
      }
      
      return result;
    })
    .catch(error => {
      console.log(error)
      
      if (error.response && error.response.data) {
        callback(error.response.data)
      }
    })
}

const deleteMessageSuccess = message => ({
  type: DELETE_MESSAGE,
  payload: message
});

export const deleteMessage = (token, id, callback) => (dispatch) => {

  const TOKEN = token;

  let url = `${ROOT_URL}messages/${id}`

  return (axios.delete(url, 
    { 
      headers: { 
        Authorization: 'Bearer ' + TOKEN
      }
    }))
    .then(result => {
      const message = result.data;

      dispatch(deleteMessageSuccess(message));

      if(callback) {
        callback(result);
      }
      
      return result;
    })
    .catch(error => {
      console.log(error)
      
      if (error.response && error.response.data) {
        callback(error.response.data)
      }
    })
}
