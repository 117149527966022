import { FETCH_CONTACT, CREATE_CONTACT, UPDATE_CONTACT, TIMELINE, DELETE_CONTACT } from "../actions/actions_contacts";

export default function(state = {}, action) {
  switch (action.type) {
    
    case FETCH_CONTACT:
      if (!action.payload) {
        return state;
      }
      return action.payload;

    case CREATE_CONTACT:
      if (!action.payload) {
        return state;
      }
      return action.payload;
      // return _.mapKeys(action.payload.data, "_id");

    case UPDATE_CONTACT:
      if (!action.payload) {
        return state;
      } else if (action.payload.errors) {
        return state;
      }
      return action.payload;

    case TIMELINE:
      if (!action.payload) {
        return state;
      } else if (action.payload.errors) {
        return state;
      }
      return action.payload;

    case DELETE_CONTACT:
      if (!action.payload ) {
        return state;
      }
      return action.payload;
    
    default:
      return state;
  }
}