export const PAGE_LOADING = "page_loading";

const handlePageLoadingSuccess = result => ({
  type: PAGE_LOADING,
  payload: result
});

//page loading
export const handlePageLoading = (data, callback) => (dispatch) => {
  dispatch(handlePageLoadingSuccess(data))
  callback(data)
}
