import _ from "lodash";
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import { styles } from '../styles';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Modal from '@material-ui/core/Modal';

import Close from '@material-ui/icons/Close';

import LoadingDivContainer from '../subcomponents/loadingDiv';
import { modalTableHeight } from '../helpers/helperFunctions';
import GridContainer from '../subcomponents/gridContainer';

import { fetchLibraryGroupsRaw } from '../../actions/actions_index';

class ShareLibraryGroupModal extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      selectedItems: []
    }

    this.onSelectionChange = selectedItems => {
      this.setState({ selectedItems });
    }
  }

  componentDidMount() {
    const { token, companyId, currentSelected, fetchLibraryGroupsRaw } = this.props;

    fetchLibraryGroupsRaw(token, companyId, libraryGroups => {
      const currentItems = currentSelected.map(value => _.findIndex(libraryGroups.data, ['_id', value]));

      this.setState({
        loading: false,
        selectedItems: currentItems
      })
    })
  }

  submitShareLibraryGroupModal = () => {
    const { libraryGroups, selectShareLibraryGroup } = this.props;
    const { selectedItems } = this.state;
    const addedFiles = selectedItems.map(value => libraryGroups[value]._id);

    selectShareLibraryGroup(addedFiles);
  }

  closeShareLibraryGroupModal = () => {
    this.props.closedShareLibraryGroupModal();
  }

  libraryGrid = () => {
    const { libraryGroups, classes } = this.props;
    const { selectedItems } = this.state;

    const rows = libraryGroups;

    const columns = [
      { name: 'name', title: 'Name' },
      { name: 'parent', title: 'Parent Group', getCellValue: row => {
          if (row.parent) {
            return row.parent;
          } else {
            return 'none'
          }
        } 
      },
      { name: 'updatedAt', title: 'Last Updated' }
    ];

    const tableColumnExtensions = [
      { columnName: 'name' },
      { columnName: 'parent', width: 230 },
      { columnName: 'updatedAt', width: 170 }
    ]

    const sortingStateColumnExtensions = [
      { columnName: 'name', sortingEnabled: true },
      { columnName: 'parent', sortingEnabled: true },
      { columnName: 'updatedAt', sortingEnabled: true }
    ]

    const filteringStateColumnExtensions = [
      { columnName: 'name', filteringEnabled: true },
      { columnName: 'parent', filteringEnabled: true },
      { columnName: 'updatedAt', filteringEnabled: false }
    ]

    const sorting = [
      { columnName: 'name', direction: 'asc' }
    ]

    return (
      <GridContainer 
        gridHeight={modalTableHeight()}
        rows={rows}
        columns={columns}
        tableColumnExtensions={tableColumnExtensions}
        sortingStateColumnExtensions={sortingStateColumnExtensions}
        sorting={sorting}
        selection={selectedItems}
        onSelectionChange={this.onSelectionChange}
        filteringStateColumnExtensions={filteringStateColumnExtensions}
        // integratedFilteringColumnExtensions={integratedFilteringColumnExtensions}
        // integratedSortingColumnExtensions={integratedSortingColumnExtensions}
        showSelectAll={true}
        showSelectionColumn={true}
        showRowDetail={false}
        classes={classes}
      />
    )
  }

  handleModalBody = () => {
    const { classes } = this.props;
    const { loading } = this.state;

    let title = '',
        subheading = '';

    if (loading) {
      return (
        <LoadingDivContainer />
      )
    } else {
      title = <span>Share Library Group(s)</span>;
      subheading = "Select group(s) from the library.";

      return (
        <div className={classes.divContainer}>
          <Typography variant="h6">
            {title}
          </Typography>
          <Typography variant="subtitle1" className={`${classes.modalSubheading} ${classes.modalSubheadingTable}`}>
            {subheading}
          </Typography>
          {this.libraryGrid()}
          <Button className={`${classes.button} ${classes.modalButton}`} variant="contained" type="submit" color="secondary" onClick={this.submitShareLibraryGroupModal}>Select</Button>
        </div>
      )
    }
  }

  render() {
    const { openShareLibraryGroupModal, classes } = this.props;

    return (
      <Modal
        id="add-to-modal"
        aria-labelledby="share-library-group"
        aria-describedby="share-library-group"
        open={openShareLibraryGroupModal}
        onClose={this.closeShareLibraryGroupModal}
      >
        <div className={`${classes.modal} ${classes.mediumModal}`}>
          <IconButton 
            className={classes.iconClose}
            onClick={this.closeShareLibraryGroupModal}
          >
            <Close />
          </IconButton>
          {this.handleModalBody()}
        </div>
      </Modal>
    )
  }
}

function mapStateToProps(state) {
  if (_.isEmpty(state.login.token)) {
    return {}
  }

  return { 
    token: state.login.token,
    companyId: state.login.company._id,
    libraryGroups: state.libraryGroupsRaw
  };
}

const mapDispatchToProps = dispatch => ({
  fetchLibraryGroupsRaw: (token, companyId, callback) => dispatch(fetchLibraryGroupsRaw(token, companyId, callback))
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ShareLibraryGroupModal));