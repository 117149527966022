import axios from "axios";
import { ROOT_URL } from "./actions_constants";

export const FETCH_LEARN_JWT = "fetch_learn_jwt";

const fetchLearnJwtSuccess = learn_jwt => ({
  type: FETCH_LEARN_JWT,
  payload: learn_jwt
});

export const fetchLearnJwt = (token, type, callback) => (dispatch) => {

  const TOKEN = token;

  return (axios.get(`${ROOT_URL}learn/authenticate/${type}`, 
    { 
      headers: { 
        Authorization: 'Bearer ' + TOKEN
      }
    }))
    .then(result => {
      const learn_jwt = result.data;

      dispatch(fetchLearnJwtSuccess(learn_jwt));

      if(callback) {
        callback(result);
      }

      return result;
    })
    .catch(error => {
      console.log(error)
      
      if (error.response && error.response.data) {
        callback(error.response.data)
      }
    })
}