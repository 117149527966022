import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CsvDownload from 'react-json-to-csv';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

import { withStyles } from '@material-ui/core/styles';
import { styles } from '../styles';

import Typography from '@material-ui/core/Typography';
import Input from '@material-ui/core/Input';
import Fab from '@material-ui/core/Fab';

import SupervisorAccount from '@material-ui/icons/SupervisorAccount';
import PeopleOutline from '@material-ui/icons/PeopleOutline';
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import AvTimer from '@material-ui/icons/AvTimer';
import Search from '@material-ui/icons/Search';
import Close from '@material-ui/icons/Close';
import CloudDownload from '@material-ui/icons/CloudDownload';

import PencilBoxMultiple from 'mdi-material-ui/PencilBoxMultiple';
import PencilBoxMultipleOutline from 'mdi-material-ui/PencilBoxMultipleOutline';

import { isiOS } from '../helpers/helperFunctions';

import { energy } from '../../theme';

import {
  SortingState,
  SelectionState,
  IntegratedFiltering,
  IntegratedSorting,
  IntegratedSelection,
  SearchState,
} from '@devexpress/dx-react-grid';

import {
  Table,
  Grid as GridTable,
  TableHeaderRow,
  TableBandHeader,
  TableSelection,
  VirtualTable,
  TableColumnVisibility,
} from '@devexpress/dx-react-grid-material-ui';

import {
  StandardRow,
  SeparationRow,
  SmallRow,
  StandardTitleCell,
  StandardCell,
  DragCell,
  ShareLinkCell,
  ViewRateCell,
  DurationCell,
  DurationDividerCell,
  DividerCell,
  AfterCell,
  NameCell,
  NameDividerCell,
  ThumbnailCell,
  TypeCell,
  StatusCell,
  FilenameCell,
  TagsCell,
  ShareCell,
  RatingCell,
  FilenameDividerCell,
  ContactInfoCell,
  AvgTotalCell,
  SizeCell,
  DateCell,
  DateCellDivider,
  DateAfterCell,
  DateTimeCell,
  MenuCell,
  InteractionsCell,
} from './gridCellTypes';

import { Getter, Plugin } from '@devexpress/dx-react-core';

//added space at bottom when bulkediton = true, but caused re-render issues
// const Root = (props, bulkEditOn) => {
//   let bottom = bulkEditOn ? 115 : 40

//   return (<GridTable.Root {...props} style={{ height: `calc(100% - ${bottom}px)` }} />)
// }

const Root = (props) => (
  <GridTable.Root {...props} style={{ height: 'calc(100% - 40px)' }} />
);

// Good way to conditionally insert a property, had been used for shifting the table position
// const TableContainer = (props, classes, tableType) => {
//   let tableClass = null;

//   if (tableType === 'iv-analytics') {
//     tableClass = classes.tableContainerSmall;
//   }

//   return <Table.Container {...props} className={`${tableClass}`} />;
// }

const Row = (props, classes, tableType, onSelectionChange) => {
  switch (tableType) {
    case 'separation':
      return <SeparationRow {...props} classes={classes} />;
    case 'small':
    case 'iv-analytics':
      return <SmallRow {...props} classes={classes} />;
    default:
      return (
        <StandardRow
          {...props}
          classes={classes}
          className={!onSelectionChange ? classes.noRowPointer : null}
        />
      );
  }
};

const HeaderRow = (props, classes, appWebSeparation) => {
  let borderClass = null;

  return <Table.Row {...props} className={borderClass} />;
};

const HeaderCell = (
  props,
  classes,
  bulkEdit,
  bulkEditOn,
  appUser,
  currentRole,
  tableType
) => {
  const columnName = props.column.name;

  let dividerClass = null,
    leftStickyClass = null;

  if (columnName.includes('Divider')) {
    dividerClass = classes.dividerCell;
  }

  if (columnName.includes('After')) {
    dividerClass = classes.afterCellHead;
  }

  if (columnName.includes('share') && tableType !== 'separation') {
    leftStickyClass = classes.shareCell;
  }

  let bulkEditButtonOff = classes.bulkEditButtonOff;

  if (bulkEditOn) {
    bulkEditButtonOff = null;
  }

  if (
    !appUser &&
    currentRole === '0' &&
    (props.column.name === 'user_menu' || props.column.name === 'library_menu')
  ) {
    return (
      <TableHeaderRow.Cell
        {...props}
        className={`${classes.headerCell} ${classes.menuCell}`}
      >
        <Fab
          color='secondary'
          onClick={bulkEdit}
          className={`${classes.bulkEditButton} ${bulkEditButtonOff}`}
          disableRipple={true}
        >
          {!bulkEditOn && <PencilBoxMultipleOutline />}
          {bulkEditOn && <PencilBoxMultiple />}
        </Fab>
      </TableHeaderRow.Cell>
    );
  }

  return (
    <TableHeaderRow.Cell
      {...props}
      className={`${classes.headerCell} ${dividerClass} ${leftStickyClass}`}
    />
  );
};

const Cell = (
  props,
  classes,
  handleMenu,
  handleMenuType,
  tableType,
  selectByRowClick,
  onSelectionChange,
  alternateSelection,
  itemsToShare,
  appUser,
  enterpriseAppUser,
  personalGroupSelected
) => {
  switch (props.column.name) {
    case 'drag':
      return (
        <DragCell
          {...props}
          classes={classes}
          selectByRowClick={selectByRowClick}
          onSelectionChange={onSelectionChange}
        />
      );
    case 'name':
    case 'title':
      return (
        <StandardTitleCell
          {...props}
          classes={classes}
          selectByRowClick={selectByRowClick}
          onSelectionChange={onSelectionChange}
        />
      );
    case 'sharesDivider':
    case 'emailDivider':
    case 'contactsDivider':
      return (
        <DividerCell
          {...props}
          classes={classes}
          selectByRowClick={selectByRowClick}
          onSelectionChange={onSelectionChange}
        />
      );
    case 'totalSessionsAfter':
    case 'totalMediaOpensAfter':
    case 'totalViewsAfter':
    case 'browsesAfter':
      return (
        <AfterCell
          {...props}
          classes={classes}
          selectByRowClick={selectByRowClick}
          onSelectionChange={onSelectionChange}
        />
      );
    case 'branding_logo':
    case 'thumbnail':
      return (
        <ThumbnailCell
          {...props}
          classes={classes}
          selectByRowClick={selectByRowClick}
          onSelectionChange={onSelectionChange}
        />
      );
    case 'file_type':
      return (
        <TypeCell
          {...props}
          classes={classes}
          selectByRowClick={selectByRowClick}
          onSelectionChange={onSelectionChange}
        />
      );
    case 'status':
      return (
        <StatusCell
          {...props}
          classes={classes}
          selectByRowClick={selectByRowClick}
          onSelectionChange={onSelectionChange}
        />
      );
    case 'contact_name':
    case 'filename':
    case 'campaign_stats':
      return (
        <FilenameCell
          {...props}
          classes={classes}
          selectByRowClick={selectByRowClick}
          onSelectionChange={onSelectionChange}
        />
      );
    case 'rating':
      return (
        <RatingCell
          {...props}
          classes={classes}
          selectByRowClick={selectByRowClick}
          onSelectionChange={onSelectionChange}
        />
      );
    case 'filenameDivider':
      return (
        <FilenameDividerCell
          {...props}
          classes={classes}
          selectByRowClick={selectByRowClick}
          onSelectionChange={onSelectionChange}
        />
      );
    case 'contact_info':
      return (
        <ContactInfoCell
          {...props}
          classes={classes}
          selectByRowClick={selectByRowClick}
          onSelectionChange={onSelectionChange}
        />
      );
    case 'user_avg_total':
      return (
        <AvgTotalCell
          {...props}
          classes={classes}
          selectByRowClick={selectByRowClick}
          onSelectionChange={onSelectionChange}
          divider={true}
        />
      );
    case 'avg_total':
      return (
        <AvgTotalCell
          {...props}
          classes={classes}
          selectByRowClick={selectByRowClick}
          onSelectionChange={onSelectionChange}
        />
      );
    case 'file_size':
      return (
        <SizeCell
          {...props}
          classes={classes}
          selectByRowClick={selectByRowClick}
          onSelectionChange={onSelectionChange}
        />
      );
    case 'last_show':
    case 'last_share':
    case 'last_view':
    case 'updatedAt':
    case 'createdAt':
      return (
        <DateCell
          {...props}
          classes={classes}
          selectByRowClick={selectByRowClick}
          onSelectionChange={onSelectionChange}
        />
      );
    case 'updatedAtDivider':
      return (
        <DateCellDivider
          {...props}
          classes={classes}
          selectByRowClick={selectByRowClick}
          onSelectionChange={onSelectionChange}
        />
      );
    case 'lastShareAfter':
      return (
        <DateAfterCell
          {...props}
          classes={classes}
          selectByRowClick={selectByRowClick}
          onSelectionChange={onSelectionChange}
        />
      );
    case 'session_start':
      return (
        <DateTimeCell
          {...props}
          classes={classes}
          selectByRowClick={selectByRowClick}
          onSelectionChange={onSelectionChange}
        />
      );
    case 'menu':
    case 'user_menu':
    case 'library_menu':
      return (
        <MenuCell
          {...props}
          classes={classes}
          handleMenu={handleMenu}
          handleMenuType={handleMenuType}
          appUser={appUser}
          personalGroupSelected={personalGroupSelected}
          selectByRowClick={selectByRowClick}
          onSelectionChange={onSelectionChange}
        />
      );
    case 'owner':
    case 'user_name':
      return (
        <NameCell
          {...props}
          classes={classes}
          tableType={tableType}
          selectByRowClick={selectByRowClick}
          onSelectionChange={onSelectionChange}
        />
      );
    case 'userNameDivider':
      return (
        <NameDividerCell
          {...props}
          classes={classes}
          tableType={tableType}
          selectByRowClick={selectByRowClick}
          onSelectionChange={onSelectionChange}
        />
      );
    case 'shareLink':
      return (
        <ShareLinkCell
          {...props}
          classes={classes}
          selectByRowClick={selectByRowClick}
          onSelectionChange={onSelectionChange}
        />
      );
    case 'view_rate':
      return (
        <ViewRateCell
          {...props}
          classes={classes}
          selectByRowClick={selectByRowClick}
          onSelectionChange={onSelectionChange}
        />
      );
    case 'averageSessionLength':
    case 'totalSessionLength':
    case 'averageMediaSessionLength':
    case 'totalMediaSessionLength':
    case 'session_length':
    case 'total':
    case 'avg':
    case 'total_duration':
      return (
        <DurationCell
          {...props}
          classes={classes}
          selectByRowClick={selectByRowClick}
          onSelectionChange={onSelectionChange}
        />
      );
    case 'totalAppSessionLengthDivider':
      return (
        <DurationDividerCell
          {...props}
          classes={classes}
          selectByRowClick={selectByRowClick}
          onSelectionChange={onSelectionChange}
        />
      );
    case 'tags':
      return (
        <TagsCell
          {...props}
          classes={classes}
          selectByRowClick={selectByRowClick}
          onSelectionChange={onSelectionChange}
        />
      );
    case 'share':
    case 'select':
      return (
        <ShareCell
          {...props}
          classes={classes}
          selectByRowClick={selectByRowClick}
          onSelectionChange={alternateSelection}
          itemsToShare={itemsToShare}
          enterpriseAppUser={enterpriseAppUser}
          handleMenuType={handleMenuType}
        />
      );
    case 'interactions':
      return <InteractionsCell {...props} classes={classes} />;
    default:
      return (
        <StandardCell
          {...props}
          classes={classes}
          tableType={tableType}
          selectByRowClick={selectByRowClick}
          onSelectionChange={onSelectionChange}
        />
      );
  }
};

Cell.propTypes = {
  column: PropTypes.shape({ name: PropTypes.string }).isRequired,
};

const ExportToCsv = ({ page, rows, postData, buttonStyle }) => {
  console.log(rows);

  let filename = `${page}.csv`;

  if (postData) {
    filename = `${page}_${postData.app_profile_name}_START-${postData.start_date}_END-${postData.end_date}_EXPORTED-${postData.today}.csv`;
  }

  return (
    <CsvDownload data={rows} filename={filename} style={buttonStyle}>
      <CloudDownload />
    </CsvDownload>
  );
};

const SearchInput = ({
  hideSearch,
  searchValue,
  appWebSeparation,
  changeSearchValue,
  resetSearch,
  exportButton,
  page,
  rows,
  postData,
  classes,
}) => {
  let displayTop = false,
    title = '',
    extraSearchBarClass = null,
    searchClass = {
      position: 'relative',
      top: -3,
    },
    closeIcon = classes.closeIcon;

  if (appWebSeparation) {
    searchClass.top = 4;
  }

  const exportButtonStyle = {
    display: 'inline',
    marginLeft: 1,
    position: 'relative',
    top: 4,
    fontSize: 14,
    cursor: 'pointer',
    background: 'none',
    border: 'none',
    color: energy,
  };

  if (appWebSeparation) {
    displayTop = true;
    extraSearchBarClass = classes.searchBarAnalytics;
    searchClass = {
      display: 'inline',
      position: 'relative',
      top: -6,
    };
    closeIcon = `${classes.closeIcon} ${classes.closeIconActivity}`;

    switch (appWebSeparation) {
      case 'users':
        title = (
          <span>
            <SupervisorAccount className={classes.bigNumberHeadingIcon} />
            <span className={classes.analyticsTableTitleText}>
              Users Report
            </span>
            <ExportToCsv
              page={page}
              rows={rows}
              postData={postData}
              buttonStyle={exportButtonStyle}
            />
          </span>
        );
        break;
      case 'contacts':
        title = (
          <span>
            <PeopleOutline className={classes.bigNumberHeadingIcon} />
            <span className={classes.analyticsTableTitleText}>
              Contacts Report
            </span>
            <ExportToCsv
              page={page}
              rows={rows}
              postData={postData}
              buttonStyle={exportButtonStyle}
            />
          </span>
        );
        break;
      case 'content':
        title = (
          <span>
            <LibraryBooks className={classes.bigNumberHeadingIcon} />
            <span className={classes.analyticsTableTitleText}>
              Content Report
            </span>
            <ExportToCsv
              page={page}
              rows={rows}
              postData={postData}
              buttonStyle={exportButtonStyle}
            />
          </span>
        );
        break;
      case 'sessions':
        title = (
          <span>
            <AvTimer className={classes.bigNumberHeadingIcon} />
            <span className={classes.analyticsTableTitleText}>
              Sessions Report
            </span>
            <ExportToCsv
              page={page}
              rows={rows}
              postData={postData}
              buttonStyle={exportButtonStyle}
            />
          </span>
        );
        break;
      case 'showcases':
        title = (
          <span>
            <AvTimer className={classes.bigNumberHeadingIcon} />
            <span className={classes.analyticsTableTitleText}>
              Showcases Report
            </span>
            <ExportToCsv
              page={page}
              rows={rows}
              postData={postData}
              buttonStyle={exportButtonStyle}
            />
          </span>
        );
        break;
      case 'screens':
        title = (
          <span>
            <AvTimer className={classes.bigNumberHeadingIcon} />
            <span className={classes.analyticsTableTitleText}>
              Screens Report
            </span>
            <ExportToCsv
              page={page}
              rows={rows}
              postData={postData}
              buttonStyle={exportButtonStyle}
            />
          </span>
        );
        break;
      case 'teams':
        let teamRows = [];
        rows.forEach(function (team) {
          teamRows.push({
            first_name: team.first_name,
            last_name: team.last_name,
            email: team.email,
            phone: team.phone,
            external_Id: team.external_id,
            activated: team.status,
          });
        });

        title = (
          <span>
            <ExportToCsv
              page={'team'}
              rows={teamRows}
              postData={postData}
              buttonStyle={exportButtonStyle}
            />
          </span>
        );
        break;
      default:
        break;
    }
  }

  if (typeof hideSearch === 'undefined' || !hideSearch) {
    return (
      <React.Fragment>
        <div
          className={`${classes.searchBar} ${extraSearchBarClass}`}
          style={{ float: 'right' }}
        >
          {displayTop && (
            <Typography variant='h5' className={classes.analyticsTableTitle}>
              {title}
            </Typography>
          )}
          <div style={searchClass}>
            {exportButton && (
              <ExportToCsv
                page={page}
                rows={rows}
                postData={postData}
                buttonStyle={exportButtonStyle}
              />
            )}
            <Search className={classes.searchIcon} />
            <Input
              id='search-grid-field'
              key='search-grid-field'
              type={'text'}
              className={classes.searchField}
              placeholder={'Search or Filter...'}
              value={searchValue}
              onChange={(event) => {
                const value = event.target.value;
                changeSearchValue(value);
              }}
            />
            {searchValue !== '' ? (
              <Close className={closeIcon} onClick={resetSearch} />
            ) : null}
          </div>
        </div>
        <div className='clearfix'></div>
      </React.Fragment>
    );
  } else {
    return null;
  }
};

const BandCell = ({
  children,
  tableRow,
  tableColumn,
  column,
  classes,
  ...restProps
}) => {
  return (
    <TableBandHeader.Cell
      {...restProps}
      column={column}
      style={{ border: 'none' }}
    >
      {children}
    </TableBandHeader.Cell>
  );
};

const recalculateViewport = ({ props, handleMenuType, bulkEditOn }) => {
  const { viewport, tableColumns } = props;
  let columnUpdate = tableColumns.length - 1;

  if (
    !bulkEditOn &&
    (handleMenuType.includes('library') || handleMenuType.includes('share'))
  ) {
    columnUpdate--;
  } else if (bulkEditOn && handleMenuType.includes('users')) {
    columnUpdate++;
  }

  return {
    ...viewport,
    columns: [[0, columnUpdate]],
  };
};

const ColumnVirtualizationDisabler = ({ handleMenuType, bulkEditOn }) => {
  return (
    <Plugin>
      <Getter
        name='viewport'
        computed={(props) =>
          recalculateViewport({ props, handleMenuType, bulkEditOn })
        }
      />
    </Plugin>
  );
};

class GridContainer extends Component {
  constructor(props) {
    super(props);

    let sorting = [];

    if (props.sorting) {
      sorting = props.sorting;
    }

    this.state = {
      searchValue: '',
      sorting: sorting,
      filters: [],
      clientWidth: 0,
    };
  }

  updateWindowSize = () => {
    this.setState({ clientWidth: window.innerWidth });
  };

  componentDidMount() {
    this.updateWindowSize();
    window.addEventListener('resize', this.updateWindowSize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowSize);
  }

  componentWillReceiveProps(newProps) {
    if (this.props.sorting !== newProps.sorting) {
      this.setState({
        sorting: newProps.sorting,
      });
    }
  }

  resetTable = () => {
    this.setState({
      sorting: [],
      filters: [],
    });
  };

  onSortingChange = (sorting, handleSortingChange) => {
    if (handleSortingChange) {
      handleSortingChange(sorting);
    }

    this.setState({
      sorting,
    });
  };

  resetSearch = () => {
    this.setState({
      searchValue: '',
    });

    if (this.props.handleSearchValue) {
      this.props.handleSearchValue(null);
    }
  };

  changeSearchValue = (value) => {
    this.setState({ searchValue: value });

    if (this.props.handleSearchValue) {
      this.props.handleSearchValue(value);
    }
  };

  onSortEnd = (props) => {
    this.props.handleMoveArray(props.oldIndex, props.newIndex);
  };

  sortableBody = ({ row, ...props }) => {
    const TableBody = SortableContainer(Table.TableBody);
    return <TableBody {...props} onSortEnd={this.onSortEnd} useDragHandle />;
  };

  renderGrid = () => {
    const {
      appUser,
      enterpriseAppUser,
      personalGroupSelected,
      currentRole,
      rows,
      columns,
      columnBands,
      tableColumnExtensions,
      sortingStateColumnExtensions,
      onSelectionChange,
      alternateSelection,
      integratedFilteringColumnExtensions,
      integratedSortingColumnExtensions,
      showSelectAll,
      showSelectionColumn,
      classes,
      tableType,
      handleMenu,
      menuType,
      appWebSeparation,
      hideSearch,
      exportButton,
      page,
      postData,
      sortable,
      bulkEdit,
      bulkEditOn,
      handleSortingChange,
      itemsToShare,
    } = this.props;

    const handleMenuType = menuType ? menuType : '';

    let { selection } = this.props;

    const { searchValue, sorting } = this.state;

    let selectByRowClick = true,
      highlightRow = true,
      handleSortable = false;

    if (tableType && tableType !== 'large') {
      selectByRowClick = false;
      highlightRow = false;
    }

    if (sortable && searchValue === '') {
      handleSortable = sortable;
    }

    //can click row, and not selecting boxes
    if (!selection) {
      selection = [];
    }

    return (
      <GridTable
        rows={rows}
        columns={columns}
        //added space at bottom when bulkediton = true, but caused re-render issues
        // rootComponent={props => Root(props, bulkEditOn)}
        rootComponent={Root}
      >
        <SearchState value={searchValue} />
        <IntegratedFiltering
          columnExtensions={integratedFilteringColumnExtensions}
        />

        <SortingState
          sorting={sorting}
          columnExtensions={sortingStateColumnExtensions}
          onSortingChange={(sorting) =>
            this.onSortingChange(sorting, handleSortingChange)
          }
        />
        <IntegratedSorting
          columnExtensions={integratedSortingColumnExtensions}
        />

        <SelectionState
          selection={selection}
          onSelectionChange={onSelectionChange}
        />
        <IntegratedSelection />

        {/*Good way to conditionally insert a property, had been used for shifting the table position
        {...(tableType === 'iv-analytics' ? {containerComponent: props => TableContainer(props, classes, tableType)} : {})}
        */}

        <VirtualTable
          height={'auto'}
          columnExtensions={tableColumnExtensions}
          bodyComponent={(props) => {
            if (handleSortable) {
              return this.sortableBody(props);
            } else {
              return <Table.TableBody {...props} />;
            }
          }}
          rowComponent={({ row, ...props }) => {
            if (handleSortable) {
              const TableRow = SortableElement(Table.Row);
              return (
                <TableRow
                  className={classes.sortableRow}
                  {...props}
                  index={rows.indexOf(row)}
                />
              );
            } else {
              return Row(props, classes, tableType, onSelectionChange);
            }
          }}
          cellComponent={(props) => {
            return Cell(
              props,
              classes,
              handleMenu,
              handleMenuType,
              tableType,
              selectByRowClick,
              onSelectionChange,
              alternateSelection,
              itemsToShare,
              appUser,
              enterpriseAppUser,
              personalGroupSelected
            );
          }}
        />

        <ColumnVirtualizationDisabler
          handleMenuType={handleMenuType}
          bulkEditOn={bulkEditOn}
        />

        <TableSelection
          selectByRowClick={selectByRowClick}
          highlightRow={highlightRow}
          showSelectAll={showSelectAll}
          showSelectionColumn={showSelectionColumn}
        />

        <TableHeaderRow
          showSortingControls
          rowComponent={(props) => HeaderRow(props, classes, appWebSeparation)}
          cellComponent={(props) =>
            HeaderCell(
              props,
              classes,
              bulkEdit,
              bulkEditOn,
              appUser,
              currentRole,
              tableType
            )
          }
        />
        {columnBands && (
          <TableBandHeader columnBands={columnBands} cellComponent={BandCell} />
        )}

        <SearchInput
          hideSearch={hideSearch}
          appWebSeparation={appWebSeparation}
          searchValue={searchValue}
          changeSearchValue={this.changeSearchValue}
          resetSearch={this.resetSearch}
          //Export to CSV
          exportButton={exportButton}
          page={page}
          rows={rows}
          postData={postData}
          //END Export to CSV
          classes={classes}
        />

        <TableColumnVisibility hiddenColumnNames={['search_tags']} />
      </GridTable>
    );
  };

  updateGridHeight = ({ gridHeight, handleMenuType }) => {
    const { clientWidth } = this.state;
    let handleGridHeight = { ...gridHeight };
    const heightStyle = handleGridHeight.height;

    if (
      (handleMenuType === 'library' || handleMenuType === 'users') &&
      clientWidth < 961 &&
      heightStyle &&
      typeof heightStyle === 'string' &&
      heightStyle.includes('calc(100vh - ')
    ) {
      const height = parseInt(
        heightStyle.replace('calc(100vh - ', '').replace('px)', '')
      );
      handleGridHeight.height = `calc(100vh - ${height + 30}px`;
    } else if (handleMenuType.includes('-new')) {
      return null;
    }

    return handleGridHeight;
  };

  render() {
    const { gridHeight, menuType, containerExtraClass, classes } = this.props,
      handleMenuType = menuType ? menuType : '';

    return (
      <div
        id='table-container'
        style={this.updateGridHeight({ gridHeight, handleMenuType })}
        className={`${containerExtraClass} ${
          handleMenuType.includes('-new') ? classes.newGridHeight : null
        }`}
      >
        {this.renderGrid()}
      </div>
    );
  }
}

GridContainer.propTypes = {
  rows: PropTypes.array.isRequired,
  tableColumnExtensions: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => {
  if (_.isEmpty(state.login.token)) {
    return {};
  }

  return {
    itemsToShare: state.itemsToShare,
  };
};

export default connect(
  mapStateToProps,
  null
)(withStyles(styles)(GridContainer));
