import _ from "lodash";
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import { styles } from '../styles';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Modal from '@material-ui/core/Modal';

import Close from '@material-ui/icons/Close';

import LoadingDivContainer from '../subcomponents/loadingDiv';
import { modalTableHeight, convertToDateString } from '../helpers/helperFunctions';
import GridContainer from '../subcomponents/gridContainer';

import { fetchDetailsAnalytics } from '../../actions/actions_index';

class AddToLibraryGroupModal extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: true
    }
  }

  componentDidMount() {
    const { token, type, companyId, fetchDetailsAnalytics, postData, selectedDetailId, selectedDetailName } = this.props;
    const { app_profile, start_date, end_date } = postData

    const values = {
      company: companyId,
      app_profile,
      start_date,
      end_date,
      detail: selectedDetailId
    }

    this.props.fetchDetailsAnalytics(token, type, values, details => {
      this.setState({
        loading: false
      })
    })
  }

  handleCloseDrillDown = () => {
    this.props.closeDrillDown()
  }

  sessionDetails = (details) => {
    const { classes } = this.props

    return details.map(event => {
      const { _id, date, type, userName, allScreens, allBrowsedItems, allShowedItems, allSharedItems, allContacts, screen_name, contactName, reshareContactName, spaceItem, screensDuration, browsedDuration, showedDuration, humanDuration } = event

      switch (type) {
        //aggregates screens
        // case 1:
        //   return <li key={_id}><span className={classes.sessionDetailsDate}>Screens viewed on {convertToDateString(date)}</span>: <br /><span className={classes.sessionDetailsUser}>{userName}</span> viewed <span className={classes.sessionDetailsItem}>{allScreens.join(', ')}</span> for <span className={classes.sessionDetailsDuration}>{screensDuration}</span></li>
        case 1:
          return <li key={_id}><span className={classes.sessionDetailsDate}>Screen Viewed on {convertToDateString(date)}</span>: <br /><span className={classes.sessionDetailsContact}>{(contactName && contactName !== '') ? contactName : userName}</span> viewed <span className={classes.sessionDetailsItem}>{screen_name}</span> for <span className={classes.sessionDetailsDuration}>{humanDuration}</span></li>
        //aggregates browses
        // case 2:
        //   return <li key={_id}><span className={classes.sessionDetailsDate}>Browsed Items on {convertToDateString(date)}</span>: <br /><span className={classes.sessionDetailsUser}>{(contactName && contactName !== '') ? contactName : userName}</span> browsed <span className={classes.sessionDetailsItem}>{allBrowsedItems.join(', ')}</span> for <span className={classes.sessionDetailsDuration}>{browsedDuration}</span></li>
        case 2:
          return <li key={_id}><span className={classes.sessionDetailsDate}>Browsed Item on {convertToDateString(date)}</span>: <br /><span className={classes.sessionDetailsContact}>{(contactName && contactName !== '') ? contactName : userName}</span> viewed <span className={classes.sessionDetailsItem}>{spaceItem}</span> for <span className={classes.sessionDetailsDuration}>{humanDuration}</span></li>
        case 3:
          return <li key={_id}><span className={classes.sessionDetailsDate}>Showed Items on {convertToDateString(date)}</span>: <br /><span className={classes.sessionDetailsUser}>{userName}</span> showed <span className={classes.sessionDetailsItem}>{allShowedItems.join(', ')}</span> to <span className={classes.sessionDetailsContact}>{allContacts.join(', ')}</span> for <span className={classes.sessionDetailsDuration}>{showedDuration}</span></li>
        case 5:
          return <li key={_id}><span className={classes.sessionDetailsDate}>Shared Items on {convertToDateString(date)}</span>: <br /><span className={classes.sessionDetailsUser}>{userName}</span> shared <span className={classes.sessionDetailsItem}>{allSharedItems.join(', ')}</span> with <span className={classes.sessionDetailsContact}>{allContacts.join(', ')}</span></li>
        case 7:
          return <li key={_id}><span className={classes.sessionDetailsDate}>Space Opened on {convertToDateString(date)}</span>: <br /><span className={classes.sessionDetailsContact}>{contactName}</span> opened space for <span className={classes.sessionDetailsDuration}>{humanDuration}</span></li>  
        case 8:
          return <li key={_id}><span className={classes.sessionDetailsDate}>Space Item Viewed on {convertToDateString(date)}</span>: <br /><span className={classes.sessionDetailsContact}>{contactName}</span> viewed <span className={classes.sessionDetailsItem}>{spaceItem}</span> for <span className={classes.sessionDetailsDuration}>{humanDuration}</span></li> 
        case 9:
          return <li key={_id}><span className={classes.sessionDetailsDate}>Space Item Reshared on {convertToDateString(date)}</span>: <br /><span className={classes.sessionDetailsContact}>{contactName}</span> reshared <span className={classes.sessionDetailsItem}>{spaceItem}</span> with <span className={classes.sessionDetailsContact}>{reshareContactName}</span></li> 
        case 10:
          return <li key={_id}><span className={classes.sessionDetailsDate}>Space Item Downloaded on {convertToDateString(date)}</span>: <br /><span className={classes.sessionDetailsContact}>{contactName}</span> downloaded <span className={classes.sessionDetailsItem}>{spaceItem}</span></li> 
      }
    })
  }

  libraryGrid = () => {
    const { type, detailsAnalytics, classes } = this.props;

    const rows = detailsAnalytics

    if (type !== 'session_details') {
      
      let columns,
          tableColumnExtensions,
          sortingStateColumnExtensions,
          filteringStateColumnExtensions,
          sorting

      if (type === 'screen_details') {

        columns = [
          { name: 'email', title: 'Email' },
          { name: 'views', title: 'Views' },
          { name: 'total', title: 'Total Duration' },
          { name: 'avg', title: 'Average Duration' }
        ]

        tableColumnExtensions = [
          { columnName: 'email' },
          { columnName: 'views', width: 120 },
          { columnName: 'total', width: 150 },
          { columnName: 'avg', width: 150 }
        ]

        sortingStateColumnExtensions = [
          { columnName: 'email', sortingEnabled: true },
          { columnName: 'views', sortingEnabled: true },
          { columnName: 'total', sortingEnabled: true },
          { columnName: 'avg', sortingEnabled: true }
        ]

        filteringStateColumnExtensions = [
          { columnName: 'email', filteringEnabled: true },
          { columnName: 'views', filteringEnabled: false },
          { columnName: 'total', filteringEnabled: false },
          { columnName: 'avg', filteringEnabled: false }
        ]

        sorting = [
          { columnName: 'views', direction: 'desc' }
        ]

      } else {
        columns = [
          { name: 'shows', title: 'Shows', getCellValue: row => row.event_totals.shows ? row.event_totals.shows : (!row.showcase ? 0 : '-') },
          { name: 'shares', title: 'Shares', getCellValue: row => row.event_totals.shares ? row.event_totals.shares : 0 },
          { name: 'views', title: 'Views', getCellValue: row => row.event_totals.views ? row.event_totals.views : 0 },
          { name: 'downloads', title: 'Downloads', getCellValue: row => row.event_totals.downloads ? row.event_totals.downloads : (!row.showcase ? 0 : '-') },
          { name: 'reshares', title: 'Reshares', getCellValue: row => row.event_totals.reshares ? row.event_totals.reshares : 0 },
          { name: 'last_show', title: 'Last Showed', getCellValue: row => row.latest.last_show },
          { name: 'last_share', title: 'Last Shared', getCellValue: row => row.latest.last_share },
          { name: 'last_view', title: 'Last Viewed', getCellValue: row => row.latest.last_view }
        ]

        if (type === 'content_details') {
          columns.unshift(
            { name: 'name', title: 'Name', getCellValue: row => {
                let share_type = ''

                if (row.session_type === 2 && row.share_type === 4) {
                  share_type = '(Link)'
                }

                if (row.contact_details.email && row.contact_details.email !== '') {
                  return `${row.contact_details.email} ${share_type}`
                } else if (row.contact_details.first_name && row.contact_details.first_name !== '' && row.contact_details.first_name !== ' ') {
                  return `${row.contact_details.first_name} ${row.contact_details.last_name ? row.contact_details.last_name : '' } ${share_type}`
                } else if (row.contact_details.phone) {
                  return `${row.contact_details.phone} ${share_type}`
                } else {
                  return ''
                }
              }
            }
          )
        } else if (type === 'contact_details') {
          columns.unshift(
            { name: 'name', title: 'Name', getCellValue: row => row.content_details ? row.content_details.name : 'Showcases' }
          )
        }

        tableColumnExtensions = [
          { columnName: 'shows', width: 80 },
          { columnName: 'shares', width: 80 },
          { columnName: 'views', width: 80 },
          { columnName: 'downloads', width: 110 },
          { columnName: 'reshares', width: 100 },
          { columnName: 'last_show', width: 120 },
          { columnName: 'last_share', width: 120 },
          { columnName: 'last_view', width: 120 }
        ]

        sortingStateColumnExtensions = [
          { columnName: 'shows', sortingEnabled: true },
          { columnName: 'shares', sortingEnabled: true },
          { columnName: 'views', sortingEnabled: true },
          { columnName: 'downloads', sortingEnabled: true },
          { columnName: 'reshares', sortingEnabled: true },
          { columnName: 'last_show', sortingEnabled: true },
          { columnName: 'last_share', sortingEnabled: true },
          { columnName: 'last_view', sortingEnabled: true }
        ]

        filteringStateColumnExtensions = [
          { columnName: 'shows', filteringEnabled: true },
          { columnName: 'shares', filteringEnabled: false },
          { columnName: 'views', filteringEnabled: false },
          { columnName: 'downloads', filteringEnabled: false },
          { columnName: 'reshares', filteringEnabled: false },
          { columnName: 'last_show', filteringEnabled: false },
          { columnName: 'last_share', filteringEnabled: false },
          { columnName: 'last_view', filteringEnabled: false }
        ]

        sorting = [
          { columnName: 'views', direction: 'desc' }
        ]
      }

      return (
        <GridContainer 
          gridHeight={modalTableHeight()}
          rows={rows}
          columns={columns}
          tableColumnExtensions={tableColumnExtensions}
          sortingStateColumnExtensions={sortingStateColumnExtensions}
          sorting={sorting}
          filteringStateColumnExtensions={filteringStateColumnExtensions}
          showSelectAll={false}
          showSelectionColumn={false}
          showRowDetail={false}
          classes={classes}
        />
      )
    } else if (type === 'session_details') {
      return (
        <div className={classes.sessionDetailsListContainer}>
          <ul className={classes.sessionDetailsList}>
            { this.sessionDetails(rows) }
          </ul>
        </div>
      )
    }

  }

  handleModalBody = () => {
    const { type, selectedDetailId, selectedDetailName, classes } = this.props;
    const { loading } = this.state;

    let title = '',
        subheading = '',
        typeText = '',
        extraText = ''

    if (loading) {
      return (
        <LoadingDivContainer />
      )
    } else {

      if (type === 'content_details') {
        typeText = 'Contact'
      } else if (type === 'contact_details') {
        typeText = 'Content'
      } else if (type === 'session_details') {
        typeText = 'Session Event'
        extraText = 'Session ID: '
      } else if (type === 'screen_details') {
        typeText = 'Screen'
      }

      title = <span>{`Analytics ${typeText} Details for ${extraText}`}<span className={classes.modalTitleItem}>{selectedDetailName}</span></span>;
      subheading = `Drill down ${typeText.toLowerCase()} details`;

      return (
        <div className={classes.divContainer}>
          <Typography variant="h6">
            {title}
          </Typography>
          <Typography variant="subtitle1" className={`${classes.modalSubheading} ${classes.modalSubheadingTable}`}>
            {subheading}
          </Typography>
          {this.libraryGrid()}
          <Button className={`${classes.button} ${classes.modalButton}`} variant="contained" type="submit" color="secondary" onClick={this.handleCloseDrillDown}>Close</Button>
        </div>
      )
    }
  }

  render() {
    const { selectedDetailId, openDrillDown, classes } = this.props;

    return (
      <Modal
        id="add-to-modal"
        aria-labelledby="add-to-library-group-modal"
        aria-describedby="add-to-library-group-modal"
        open={openDrillDown}
        onClose={() => this.handleCloseDrillDown()}
      >
        <div className={`${classes.modal} ${classes.mediumModal}`}>
          <IconButton 
            className={classes.iconClose}
            onClick={() => this.handleCloseDrillDown()}
          >
            <Close />
          </IconButton>
          {this.handleModalBody()}
        </div>
      </Modal>
    )
  }
}

function mapStateToProps(state) {
  if (_.isEmpty(state.login.token)) {
    return {}
  }

  return { 
    token: state.login.token,
    companyId: state.login.company._id,
    detailsAnalytics: state.analytics.details,
  };
}

const mapDispatchToProps = dispatch => ({
  fetchDetailsAnalytics: (token, type, values, callback) => dispatch(fetchDetailsAnalytics(token, type, values, callback))
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AddToLibraryGroupModal));