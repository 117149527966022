import React from 'react';
import { Redirect } from "react-router-dom";

import LoadingDivContainer from '../subcomponents/loadingDiv';

import { CREATE_ACCOUNT, CREATE_YOUR_SOLOFIRE_ACCOUNT, checkDoesNotInclude } from '../../components/helpers/helperFunctions';

const WithLoading = Component => {
	const Loading = ({ loggedOut, isLoading, loadingType, loadingReport, form, ...otherProps }) => {

		let currentPath = ''

		if (otherProps.location && otherProps.location.pathname) {
			currentPath = otherProps.location.pathname
		}

		const check = checkDoesNotInclude(currentPath)

		if (loggedOut && (!check || currentPath.includes('content/showcases'))) {
		  return (
		    <Redirect to='/login'/>
		  )
		} else if (form && isLoading) {
			return null
		} else if (isLoading) {
			return <LoadingDivContainer type={loadingType} report={loadingReport} />
		} else {
			return <Component {...otherProps} />
		}
		
	}

	return Loading
}

export default WithLoading