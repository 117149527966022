import axios from "axios";
import { ROOT_URL } from "./actions_constants";

export const FETCH_COMPANIES = "fetch_companies";
export const FETCH_COMPANY = "fetch_company";
export const UPDATE_COMPANY = "update_company";
export const CREATE_FULL_COMPANY = "create_full_company";

const fetchCompaniesSuccess = company => ({
  type: FETCH_COMPANIES,
  payload: company
});

export const fetchCompanies = (token, userId, callback) => (dispatch) => {

  const TOKEN = token;

  return (axios.get(`${ROOT_URL}all/companies/${userId}`,
    { 
      headers: { 
        Authorization: 'Bearer ' + TOKEN
      }
    }))
  .then(result => {
    const companies = result.data;

    dispatch(fetchCompaniesSuccess(companies));

    if(callback) {
      callback(result);
    }

    return result;
  })
  .catch(error => {
    console.log(error)
    
    if (error.response && error.response.data) {
      callback(error.response.data)
    }
  })

}

const fetchCompanySuccess = company => ({
  type: FETCH_COMPANY,
  payload: company
});

export const fetchCompany = (token, id, callback) => (dispatch) => {

  const TOKEN = token;

  return (axios.get(`${ROOT_URL}companies/${id}`,
    { 
      headers: { 
        Authorization: 'Bearer ' + TOKEN
      }
    }))
  .then(result => {
    const company = result.data;

    dispatch(fetchCompanySuccess(company));

    if(callback) {
      callback(result);
    }

    return result;
  })
  .catch(error => {
    console.log(error)
    
    if (error.response && error.response.data) {
      callback(error.response.data)
    }
  })

}

const updateCompanySuccess = company => ({
  type: UPDATE_COMPANY,
  payload: company
});

export const updateCompany = (token, values, id, callback) => (dispatch) => {

  const TOKEN = token;

  return (axios.put(`${ROOT_URL}companies/${id}`, 
    values,
    { 
      headers: { 
        Authorization: 'Bearer ' + TOKEN
      }
    }))
    .then(result => {
      const company = result.data;

      dispatch(updateCompanySuccess(company));

      if (callback) {
        callback(company);
      }

      return result;
    })
    .catch(error => {
      console.log(error)
      
      if (error.response && error.response.data) {
        callback(error.response.data)
      }
    })
}

const createFullCompanySuccess = company => ({
  type: CREATE_FULL_COMPANY,
  payload: company
});

// No Token needed because of Free Trial Account Creation
// export const createFullCompany = (token, values, callback) => (dispatch) => {
export const createFullCompany = (values, callback) => (dispatch) => {

  // const TOKEN = token;

  return (axios.post(`${ROOT_URL}create_full_company`,
      values,
      // { 
      //   headers: { 
      //     Authorization: 'Bearer ' + TOKEN
      //   }
      // }
      ))
    .then(result => {
      const company = result.data;

      dispatch(createFullCompanySuccess(company));

      if(callback) {
        callback(result);
      }

      return result;
    })
    .catch(error => {
      console.log(error)
      
      if (error.response && error.response.data) {
        callback(error.response.data)
      }
    })
}
