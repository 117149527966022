import { CREATE_SUBSCRIPTION, CANCEL_SUBSCRIPTION } from "../actions/actions_billings";

export default function(state = {}, action) {
  switch (action.type) {
    case CREATE_SUBSCRIPTION:
      if (!action.payload) {
        return state;
      }
      return action.payload;

    case CANCEL_SUBSCRIPTION:
      if (!action.payload) {
        return state;
      }
      return action.payload;

    default:
      return state;
  }
}