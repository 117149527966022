import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';
import { styles } from '../../styles';

// import { ListItem, ListItemSecondaryAction, ListItemText, ListItemIcon } from '@material-ui/core';
// import { SupervisorAccount } from '@material-ui/icons';

import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import SupervisorAccount from '@material-ui/icons/SupervisorAccount';
import Warning from '@material-ui/icons/Warning';

import TeamMenuButton from '../team/team_menu_button';

class TeamItems extends Component {

  //ADD CLASS IF SELECTED
  selectedTeamTextClass = (selectedTeamId, value, classes) => {
    let textClass = {
      root: classes.teamItemText
    }

    if(selectedTeamId === value) {
      textClass.dense = classes.selectedItemText;
    }

    return textClass;
  }

  eachTeam = (value) => {
    const { enterprise, alerts, owner, currentRole, selectedTeamId, handleTeamMenu, handleTeamClick, classes } = this.props;

    const id = value._id;

    let itemClasses = classes.libraryGroupItem,
        alertTeam = false;

    if (selectedTeamId === id) {
      itemClasses += ` ${classes.selectedItem}`;
    }

    if (!enterprise && alerts && alerts.team_no_groups && alerts.team_no_groups.includes(id)) {
      alertTeam = true;
    }

    return (
      <ListItem 
        id={id}
        key={id}
        button 
        onClick={handleTeamClick}
        className={itemClasses}
        disableRipple={true}
        focusRipple={true}
      >
        <ListItemIcon
          className={`${classes.libraryGroupIcon} ${selectedTeamId === id ? classes.selectedItemText : ''}`}
        >
          {alertTeam ? <Warning className={classes.alertTeamIcon} /> : <SupervisorAccount />}
        </ListItemIcon>
        <ListItemText 
          primary={<div className={`${classes.truncateText} ${selectedTeamId === id ? classes.selectedItemText : ''}`}>{value.name}</div>}
          classes={this.selectedTeamTextClass(selectedTeamId, id, classes)}
          style={{width: 'calc(100% - 16px)'}}
        />
        <ListItemSecondaryAction>
          {(currentRole === '0' || value.editable_by === '0' || value.owners.includes(owner)) &&
          <TeamMenuButton
            value={value}
            selectedTeamId={selectedTeamId}
            handleTeamMenu={handleTeamMenu}
          />
          }
        </ListItemSecondaryAction>
      </ListItem>
    )
  }

  render() {
    const { teams } = this.props;
    
    let teamItems = [];

    teams.map(team => {
      teamItems.push(this.eachTeam(team))
      return null
    })

    return teamItems;
  }

}

export default withStyles(styles)(TeamItems);