import _ from 'lodash';
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

import { withStyles } from '@material-ui/core/styles';
import { styles } from '../styles';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import AddCircle from '@material-ui/icons/AddCircle';
import Share from '@material-ui/icons/Share';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';

import WithLoading from '../subcomponents/withLoading';
import GridContainer from '../subcomponents/gridContainer';
import ContactModalContainer from '../modals/contactModal';
import ShareModalContainer from '../modals/shareModal';

import { verifyLoggedIn, menuPermissions, getOffset, contactTableHeight } from '../helpers/helperFunctions';
import { multipleFilter, libraryNameSort } from '../helpers/gridLibraryHelpers';
import { compareName } from '../helpers/gridUsersHelpers';

import { verifyRole, fetchContacts } from '../../actions/actions_index';

import { QUICK_ADD_CONTACT, QUICK_ADD_LEAD, QUICK_SHARE } from '../header';

class Contacts extends Component {
	constructor(props) {
    super(props);

    this.state = {
      leads: false,
      filteredContacts: [],
      rowMenu: false,
      openContactModal: false,
      deleteContactModal: false,
      selectedContactPosition: null,
      selectedContactId: null,
      selectedContactInitialValues: null,
      link: null,
      selectedContent: null,
      sorting: [{ columnName: 'updatedAt', direction: 'desc' }]
    }

    this.handleSortingChange = sorting => {
      this.setState({
        sorting
      })
    }
  }

  quickContact = (quick) => {
    if (quick === QUICK_ADD_CONTACT || quick === QUICK_ADD_LEAD) {
      this.addContact();
    } else if (quick === QUICK_SHARE) {
      this.shareContent();
    }
  }

  filterContacts = () => {
    const { history, contacts } = this.props;

    let leads = true,
        filteredContacts = [];

    if (history.location.pathname.includes('leads')) {
      filteredContacts = _.filter(contacts, {type: '1'});
    } else {
      leads = false;

      filteredContacts = _.filter(contacts, function(item) {
        return !item.type|| item.type === '0' || item.type === '2';
      });
    }

    this.setState({
      leads,
      filteredContacts
    })
  }

  componentDidMount() {
    const { quick } = this.props.match.params;
    
    this.filterContacts();

    if (quick) {
      this.quickContact(quick);
    }
  }

  componentWillReceiveProps(newProps) {
    const { quick } = newProps.match.params;

    this.filterContacts();

    if (quick) {
      this.quickContact(quick);
    }
  }

  addContact = () => {
    this.setState({
      openContactModal: true,
      selectedContactId: null
    });
  }

  shareContent = () => {
    //if row clicked => contact

    this.setState({
      openShareModal: true,
      selectedContactId: null
    });
  } 

  //OPEN LIBRARY ITEM MENU
  handleContactMenu = event => {

    const element = document.getElementById(event.currentTarget.id);
    const top = getOffset(element).top;
    const left = getOffset(element).left;
    const position = {top: top, left: left};

    this.setState({
      rowMenu: true,
      selectedContactPosition: position,
      selectedContactId: event.currentTarget.id
    });
    
  }

  //CLOSE LIBRARY ITEM MENU AND HANDLE IF SELECTION (PREVIEW, EDIT, or DELETE)
  handleContactMenuClose = (type, selectedContactId) => {
    const { contacts } = this.props;

    let openShareModal = false,
        openContactModal = false,
        deleteContactModal = false,
        selectedContactInitialValues = null;

    this.setState({
      selectedContactPosition: null
    })

    if (selectedContactId) {
      selectedContactInitialValues = _.find(contacts, {_id: selectedContactId});
    }

    switch (type) {
      case 'share':
        openShareModal = true;
        break;
      case 'edit':
        openContactModal = true;
        break;
      case 'delete':
        deleteContactModal = true;
        openContactModal = true;
        break;
      default:  
        break;
    }

    this.setState({
      rowMenu: false,
      openShareModal: openShareModal,
      openContactModal: openContactModal,
      deleteContactModal: deleteContactModal,
      selectedContactId: selectedContactId,
      selectedContactInitialValues: selectedContactInitialValues
    });
  }

  //HANDLE CLOSED PREVIEW LIBRARY ITEM MODAL
  closedContactModal = (updated, share) => {
    const { token, companyId, history, fetchContacts } = this.props;
    const { leads } = this.state;

    if (updated) {
      fetchContacts(token, companyId, contacts => {
        this.filterContacts();
      });
    }

    if (!share) {
      this.setState({
        openContactModal: false,
        openShareModal: false,
        deleteContactModal: false,
        selectedContactId: null,
        selectedContactInitialValues: null
      });
    }

    if (leads) {
      history.push(`/share/leads`);
    } else {
      history.push(`/share/contacts`);
    }
  }

  rowClick = (event) => {
    const { owner, currentRole } = this.props;
    const { filteredContacts, rowMenu } = this.state;

    const id = filteredContacts[event[0]]._id;
    const initialValues = filteredContacts[event[0]];

    let openShare = false;
    openShare = menuPermissions('contacts', rowMenu, owner, currentRole, initialValues);

    if (openShare) {
      this.setState({
        selectedContactId: id,
        selectedContactInitialValues: initialValues,
        openShareModal: true
      })
    }
  }

  handleOpenContactModal = () => {
    const { leads, openContactModal, selectedContactId, deleteContactModal } = this.state;

    if (openContactModal) {
      return (
        <ContactModalContainer
          leads={leads}
          openContactModal={openContactModal}
          deleteContactModal={deleteContactModal}
          closedContactModal={this.closedContactModal}
          selectedContactId={selectedContactId}
        />
      )
    }
  }

  handleOpenShareModal = () => {
    const { leads, openShareModal, selectedContactInitialValues } = this.state;

    if (openShareModal) {
      return (
        <ShareModalContainer
          leads={leads}
          openShareModal={openShareModal}
          closedShareModal={this.closedContactModal}
          selectedContact={selectedContactInitialValues}
        />
      )
    }
  }

  handleContactTable = () => {
    const { currentRole, owner, classes } = this.props;
    const { leads, filteredContacts, selectedContactPosition, selectedContactId, sorting } = this.state;
    let rows = filteredContacts,
        page = 'share-contacts'

    let columns = [
      { name: 'contact_name', title: 'Name / Company' },
      { name: 'contact_info', title: 'Email / Phone' },
      { name: 'shared_links', title: 'Shared Sessions' },
      { name: 'shared_items', title: 'Shared Items' },
      { name: 'opens', title: 'Space Opens' },
      { name: 'views', title: 'Item Views' },
      { name: 'downloads', title: 'Downloads' },
      { name: 'shared_showcases', title: 'Shared Showcases' },
      { name: 'showcase_opens', title: 'Showcase Opens' },
      { name: 'showcase_views', title: 'Showcase Views' },
      { name: 'showcase_downloads', title: 'Showcase Downloads' },
      // { name: 'reshares', title: 'Re-Shares' },
      { name: 'owner', title: 'Owner' },
      { name: 'updatedAt', title: 'Last Updated' },
      { name: 'menu', title: ' ', getCellValue: row => {
        return {
          owner: owner,
          currentRole: currentRole
        }
      }},
    ];

    let tableColumnExtensions = [
      { columnName: 'contact_name', width: 210 },
      { columnName: 'contact_info', width: 210 },
      { columnName: 'shared_links', width: 100 },
      { columnName: 'shared_items', width: 100 },
      { columnName: 'opens', width: 80 },
      { columnName: 'views', width: 75 },
      { columnName: 'downloads', width: 110 },
      { columnName: 'shared_showcases', width: 110 },
      { columnName: 'showcase_opens', width: 110 },
      { columnName: 'showcase_views', width: 110 },
      { columnName: 'showcase_downloads', width: 110 },
      // { columnName: 'reshares', width: 100 },
      { columnName: 'owner', width: 170 },
      { columnName: 'updatedAt', width: 160 },
      { columnName: 'menu', width: 50 }
    ]

    let sortingStateColumnExtensions = [
      { columnName: 'contact_name', sortingEnabled: true },
      { columnName: 'contact_info', sortingEnabled: true },
      { columnName: 'shared_links', sortingEnabled: true },
      { columnName: 'shared_items', sortingEnabled: true },
      { columnName: 'opens', sortingEnabled: true },
      { columnName: 'views', sortingEnabled: true },
      { columnName: 'shared_showcases', sortingEnabled: true },
      { columnName: 'showcase_opens', sortingEnabled: true },
      { columnName: 'showcase_views', sortingEnabled: true },
      { columnName: 'showcase_downloads', sortingEnabled: true },
      { columnName: 'downloads', sortingEnabled: true },
      // { columnName: 'reshares', sortingEnabled: true },
      { columnName: 'owner', sortingEnabled: true },
      { columnName: 'updatedAt', sortingEnabled: true },
      { columnName: 'menu', sortingEnabled: false }
    ]

    let filteringStateColumnExtensions = [
      { columnName: 'contact_name', filteringEnabled: true },
      { columnName: 'contact_info', filteringEnabled: true },
      { columnName: 'shared_links', filteringEnabled: false },
      { columnName: 'shared_items', filteringEnabled: false },
      { columnName: 'opens', filteringEnabled: false },
      { columnName: 'views', filteringEnabled: false },
      { columnName: 'shared_showcases', filteringEnabled: false },
      { columnName: 'showcase_opens', filteringEnabled: false },
      { columnName: 'showcase_views', filteringEnabled: false },
      { columnName: 'showcase_downloads', filteringEnabled: false },
      { columnName: 'downloads', filteringEnabled: false },
      // { columnName: 'reshares', filteringEnabled: false },
      { columnName: 'owner', filteringEnabled: true },
      { columnName: 'updatedAt', filteringEnabled: false },
      { columnName: 'menu', filteringEnabled: false }
    ]

    // console.log(library);

    const integratedFilteringColumnExtensions = [
      { columnName: 'contact_name', predicate: multipleFilter },
      { columnName: 'contact_info', predicate: multipleFilter },
      { columnName: 'campaign_stats', predicate: multipleFilter },
      { columnName: 'owner', predicate: multipleFilter }
    ]

    const integratedSortingColumnExtensions = [
      { columnName: 'contact_name', compare: libraryNameSort },
      { columnName: 'campaign_stats', predicate: libraryNameSort },
      { columnName: 'contact_info', compare: libraryNameSort },
      { columnName: 'owner', compare: compareName }
    ]

    if (leads) {
      columns.splice(2, 0, { name: 'campaign_stats', title: 'Campaign / Opens - Views - Downloads', getCellValue: row => {
          return (
            {
              name: row.campaign,
              filename: `${row.campaign_opens} - ${row.campaign_views} - ${row.campaign_downloads}`
            }
          )
        } 
      })
      tableColumnExtensions.splice(2, 0, { columnName: 'campaign_stats', width: 220 })
      sortingStateColumnExtensions.splice(2, 0, { columnName: 'campaign_stats', sortingEnabled: false })
      filteringStateColumnExtensions.splice(2, 0, { columnName: 'campaign_stats', filteringEnabled: true })
      page = 'share-leads'
    }

    return (
      <div>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <GridContainer 
              gridHeight={contactTableHeight()}
              rows={rows}
              columns={columns}
              tableColumnExtensions={tableColumnExtensions}
              sortingStateColumnExtensions={sortingStateColumnExtensions}
              sorting={sorting}
              handleSortingChange={this.handleSortingChange}
              filteringStateColumnExtensions={filteringStateColumnExtensions}
              integratedFilteringColumnExtensions={integratedFilteringColumnExtensions}
              integratedSortingColumnExtensions={integratedSortingColumnExtensions}
              showSelectAll={false}
              showSelectionColumn={false}
              showRowDetail={false}
              classes={classes}
              handleMenu={this.handleContactMenu}
              menuType={'contacts'}
              onSelectionChange={this.rowClick}
              exportButton={true}
              page={page}
            />
            <Menu
              anchorPosition={selectedContactPosition}
              anchorReference={'anchorPosition'}
              open={Boolean(selectedContactPosition)}
              onClose={() => this.handleContactMenuClose(null, null)}
            >
              <MenuItem onClick={() => this.handleContactMenuClose('share', selectedContactId)}>
                <ListItemIcon>
                  <Share />
                </ListItemIcon>
                Share
              </MenuItem>
              <MenuItem onClick={() => this.handleContactMenuClose('edit', selectedContactId)}>
                <ListItemIcon>
                  <Edit />
                </ListItemIcon>
                Edit
              </MenuItem>
              { (currentRole <= '1') &&
                <MenuItem onClick={() => this.handleContactMenuClose('delete', selectedContactId)}>
                  <ListItemIcon>
                    <Delete />
                  </ListItemIcon>
                  Delete
                </MenuItem>
              }
            </Menu>
          </Grid>
        </Grid>
      </div>
    );
  }

	render() {
	  const { classes } = this.props;
    const { leads } = this.state;

    let type = "Contact";

    if (leads) {
      type = 'Lead';
    }

    return (
      <div className={`${classes.root} ${classes.rootExtendedHeader}`}>
        {this.handleOpenContactModal()}
        {this.handleOpenShareModal()}
        <Typography variant="h4" className={classes.topButtonRow}>
          <Button className={classes.addButton} onClick={this.addContact}>
            <AddCircle className={classes.addCircleIcon} />
            {`Add New ${type}`}
          </Button>
          <Button className={classes.addButton} onClick={this.shareContent}>
            <AddCircle className={classes.addCircleIcon} />
            Share Content
          </Button>
        </Typography>
        {this.handleContactTable()}
      </div>
    )
    
	}
}

const ContactsWithLoading = WithLoading(Contacts)

class ContactsContainer extends Component {
  state = {
    loading: true
  }

  componentDidMount() {
    const { history, loggedOut, token, companyId, owner } = this.props;

    verifyLoggedIn(history, loggedOut, () => {
      this.props.verifyRole(token, owner, verifyContact => {
        
        //depending on role/user -> decides what contacts to display
        this.props.fetchContacts(token, companyId, contacts => {
          this.setState({
            loading: false
          })
        });
      });
    });
  }

  render() {
    const loading = this.state.loading;

    return (
      <ContactsWithLoading 
        isLoading={loading}
        {...this.props}
      />
    )
  }
}

function mapStateToProps(state) {
  if (_.isEmpty(state.login.token) || _.isEmpty(state.login.company) || _.isEmpty(state.login.user)) {
    return {
      loggedOut: true
    }
  }

  return { 
    token: state.login.token,
    companyId: state.login.company._id,
    currentUser: state.login.user,
    currentRole: state.login.user.user_type,
    owner: state.login.user._id,
    contacts: state.contacts
  };
}

const mapDispatchToProps = dispatch => ({
  verifyRole: (token, owner, callback) => dispatch(verifyRole(token, owner, callback)),
  fetchContacts: (token, companyId, callback) => dispatch(fetchContacts(token, companyId, callback))
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(ContactsContainer)
