import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from "redux-form";

import { withStyles } from '@material-ui/core/styles';
import { styles } from '../styles';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Modal from '@material-ui/core/Modal';
import MenuItem from '@material-ui/core/MenuItem';

import Close from '@material-ui/icons/Close';

import LoadingDivContainer from '../subcomponents/loadingDiv';
import { siteUrl, getTagSearchOptions } from '../helpers/helperFunctions';
import ShareLibraryContentModalContainer from '../modals/shareLibraryContent';
import ShareLibraryGroupModalContainer from '../modals/shareLibraryGroup';
import ShareShowcaseModalContainer from '../modals/shareShowcase';

import { RenderTextField } from '../subcomponents/form_subcomponents/renderTextField';
import TagsField from '../subcomponents/form_subcomponents/tagsField';
import { ErrorText } from '../subcomponents/form_subcomponents/errorText';

import { fetchContactsRaw, fetchShareContact, fetchAppProfiles, shareToContact } from '../../actions/actions_index';

class ShareForm extends Component {
  constructor(props) {
    super(props);

    const { formError } = this.props;

    this.state = {
      error: formError,
      shareContentItems: []
    }

    this.props.reset();
  }

  submitShareModal = (values) => {
    const { appProfiles, appProfile, sharedItems, submittedShareModal, doneShareModal } = this.props
    const { token, companyId, owner } = this.props
    const { multipleLinks } = this.state
    const handleAppProfile = (appProfile && appProfile._id) ? appProfile._id : appProfiles[0]

    const { link_message } = values

    submittedShareModal(true)

    let newContacts = []

    multipleLinks.map(name => {
      newContacts.push({name: name.value, type: '0'})
    })

    const submitData = {
      user_details: {
        company: companyId,
        app_profile: handleAppProfile,
        user: owner
      },
      session_details: {
        session_type: 4,
        share_type: 4,
        custom_text: {
          link_message
        }
      },
      newContacts,
      shareContentItems: sharedItems.libraryItemsInCart,
      shareShowcases: sharedItems.showcasesInCart
    }

    this.props.shareToContact(submitData, (data) => {
      if (data.data.error) {
        submittedShareModal(false, data.data.error, values)
        console.log(data.data.error)
      } else {
        submittedShareModal(false)
        doneShareModal(data.data)
      }
    })
  }

  handleMultipleLinksError = (error) => {
    this.setState({
      multipleLinksError: error
    })
  }

  updateMultipleLinks = (type, id, multipleLinks) => {
    this.setState({
      multipleLinks: multipleLinks
    })
  }

  shareForm = () => {
    const { tagSearchOptions, classes, formValues, pristine, invalid } = this.props;
    const { error, multipleLinks } = this.state;

    let handleDisabled = false

    if ((pristine || invalid) && (!multipleLinks || (multipleLinks && multipleLinks.length === 0))) {
      handleDisabled = true
    }

    return (
      <div>
        <ErrorText className={classes.bigError} touched={true} error={error} />
        <Grid container spacing={10}>
          <Grid item xs={12}>
            <TagsField 
              type={'links'}
              label={'Enter Unique Link Names'}
              name={'multiple_emails'}


              //test if unique link name when tag entered


              tags={multipleLinks}
              allTags={tagSearchOptions}
              className={classes.tagsMarginTop}
              handleTagError={this.handleMultipleLinksError}
              updateTags={this.updateMultipleLinks}
            />
            <Field type="text" name="link_message" multiline={3} component={RenderTextField} label="Message" />
          </Grid>
        </Grid>
        <Button className={`${classes.button}`} variant="contained" color="secondary" type="submit" disabled={handleDisabled}>Create</Button>
      </div>
    )
  }

  render() {
    const { selectedContact, handleSubmit, classes } = this.props;

    let extraFormClass = '';

    if (selectedContact) {
      extraFormClass = classes.shareForm;
    }

    return (
      <form className={extraFormClass} noValidate autoComplete="off" onSubmit={handleSubmit(values => this.submitShareModal(values))}>
        {this.shareForm()} 
      </form>
    )
  }
}

function validate(values, props) {
  const errors = {};
  let requiredFields = [];
  
  // requiredFields.forEach(field => {
  //   if (!values[field]) {
  //     errors[field] = 'Required';
  //   }
  // })

  return errors;
}

function mapStateToProps(state) {
  
  return { 
    token: state.login.token,
    companyId: state.login.company._id,
    enterprise: state.login.company.enterprise,
    verb_live: state.login.company.enterprise,
    currentRole: state.login.user.user_type,
    currentUser: state.login.user,
    owner: state.login.user._id,
    contacts: state.contacts,
    appProfiles: state.appProfiles,
    appProfile: state.appProfile,
    formValues: state.form
  };
}

const mapDispatchToProps = dispatch => ({
  fetchContactsRaw: (token, companyId, callback) => dispatch(fetchContactsRaw(token, companyId, callback)),
  fetchShareContact: (token, id, callback) => dispatch(fetchShareContact(token, id, callback)),
  fetchAppProfiles: (token, companyId, callback) => dispatch(fetchAppProfiles(token, companyId, callback)),
  shareToContact: (values, callback) => dispatch(shareToContact(values, callback))
});

const ShareFormContainer = reduxForm({
  form: 'Contact',
  validate,
  enableReinitialize: true,
})(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ShareForm)));


class ShareModal extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loadingAppProfile: true,
      contactInfo: null,
      formError: null,
      shareLinks: null,
      filteredContacts: [],
      copied: [],
      tagSearchOptions: []
    }
  }

  componentDidMount() {
    const { token, companyId} = this.props;

    // let tagSearchOptions = this.getTagSearchOptions(leads, contacts.data);

    //Load in all current link names for the company

    this.props.fetchAppProfiles(token, companyId, appProfiles => {
      this.props.fetchContactsRaw(token, companyId, contacts => {
        let tagSearchOptions = getTagSearchOptions(null, contacts.data);

        this.setState({
          loadingAppProfile: false,
          tagSearchOptions
        })
      })
    })
  }

  submittedShareModal = (loading, error, values) => {
    this.setState({
      loadingLinks: loading,
      formError: error
    })
  }

  doneShareModal = (data) => {
    const { closedShareModal} = this.props;

    data.map(obj => {
      return obj.link = `${siteUrl()}space/${obj.link_udid}`;
    })

    this.setState({
      shareLinks: data
    })
  }

  shareFormContainer = () => {
    const { sharedItems, closedShareModal } = this.props;
    const { tagSearchOptions, formError } = this.state;
    
    return (
      <ShareFormContainer 
        sharedItems={sharedItems}
        tagSearchOptions={tagSearchOptions}
        submittedShareModal={this.submittedShareModal}
        doneShareModal={this.doneShareModal}
        closedShareModal={closedShareModal}
        formError={formError}
      />
    )
  }

  modalSize = () => {
    const { classes } = this.props;
    const { shareLinks } = this.state;

    if (shareLinks) {
      return classes.smallMediumModal  
    }
    
    return classes.smallModal
  }

  copyLink = (link_udid, link) => {
    const { copied } = this.state

    navigator.clipboard.writeText(link)

    this.setState({
      copied: [
        ...copied,
        link_udid
      ]
    })
  }

  displayShareLinks = () => {
    const { classes } = this.props;
    const { shareLinks, copied } = this.state;
    
    return (
      shareLinks.map(obj => {
        const { link_udid, link, contact, contact_name } = obj

        return (
          <div key={link_udid} style={{padding: '20px 20px 0px'}}>
            {contact &&
              <Typography variant="h4" className={classes.shareContact} style={{marginBottom: 5}}>
                {contact}
              </Typography>
            }
            {(!contact && contact_name) &&
              <Typography variant="h4" className={classes.shareContact} style={{marginBottom: 5}}>
                {contact_name}
              </Typography>
            }
            <Typography variant="subtitle1" className={`${classes.sharedLink}`}>
              <div>{link}</div>  
              <div style={{marginTop: 10}}>
                <a className={classes.previewSpaceLink} href={link.replace('space', 'space_preview')} target="_blank">Preview</a> 
                <span>
                  <a className={classes.previewSpaceLink} onClick={() => this.copyLink(link_udid, link)}>Copy Link</a>
                  {copied.includes(link_udid) && <span style={{paddingLeft: 5, fontSize: 12}}>Copied</span>}
                </span>
              </div>
            </Typography>
          </div>
        )
      })
    )
  }

  handleModalBody = () => {
    const { sharedItems, classes } = this.props;
    const { loadingLinks, loadingAppProfile, contactInfo, shareLinks } = this.state;
    const numSharedItems = sharedItems ? sharedItems.libraryItemsInCart.length + sharedItems.showcasesInCart.length : 0

    let withString = '',
        name = '',
        title = '',
        subheading = '',
        subheadingClass = classes.modalSubheading,
        sharedItemsMessage = <span><br/><span style={{fontWeight: 600}}>{numSharedItems} Content Items</span> will be shared in the Space.</span>

    title = "Create Space Link(s)";
    subheading = <span>Share unique Space links with tracking analytics.{numSharedItems > 0 ? sharedItemsMessage : null}</span>;

    if (loadingLinks || loadingAppProfile) {
      return (
        <LoadingDivContainer 
          type={'small'}
        />
      )
    } else if (shareLinks) {
      return (
        <div style={{height: 'calc(100% - 50px)'}}>
          <Typography variant="h3">
              {(shareLinks.length > 1) ? 'Generated Links' : 'Generated Link'}
            </Typography>
          <div className={classes.generatedLinks}>
            {this.displayShareLinks()}
          </div>
        </div>
      )
    } else {
      return (
        <div className={classes.divContainer}>
          <Typography variant="h4">
            {title}
          </Typography>
          <Typography variant="subtitle1" className={subheadingClass}>
            {subheading}
          </Typography>
          {this.shareFormContainer()}
        </div>
      )
    }
  }

  render() {
    const { openShareModal, closedShareModal, classes } = this.props;

    return (
      <Modal
        aria-labelledby="share-content"
        aria-describedby="share-content"
        open={openShareModal}
        onClose={() => closedShareModal(false, false)}
      >
        <div className={`${classes.modal} ${this.modalSize()}`}>
          <IconButton 
            className={classes.iconClose}
            onClick={() => closedShareModal(false, false)}
          >
            <Close />
          </IconButton>
          {this.handleModalBody()}
        </div>
      </Modal>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ShareModal));
