import axios from "axios";
import { ROOT_URL } from "./actions_constants";

export const FETCH_ROOM = "fetch_room";
export const CREATE_SESSION_EVENT = "create_session_event";

const fetchRoomSuccess = room => ({
  type: FETCH_ROOM,
  payload: room
});

export const fetchRoom = (link_udid, preview, callback) => (dispatch) => {

  let link = 'room';

  if (preview) {
    link = 'room_preview';
  }
  
  return (axios.get(`${ROOT_URL}${link}/${link_udid}`))
    .then(result => {
      const room = result.data;

      dispatch(fetchRoomSuccess(room));

      if(callback) {
        callback(result);
      }
      
      return result;
    })
    .catch(error => {
      console.log(error)
      
      if (error.response && error.response.data) {
        callback(error.response.data)
      }
    })
}

const createSessionEventSuccess = sessionEvent => ({
  type: CREATE_SESSION_EVENT,
  payload: sessionEvent
});

export const createSessionEvent = (values, callback) => (dispatch) => {

  return (axios.post(`${ROOT_URL}session_events`, values))
    .then(result => {
      const sessionEvent = result.data;

      dispatch(createSessionEventSuccess(sessionEvent));

      if(callback) {
        callback(result);
      }

      return result;
    })
    .catch(error => {
      console.log(error)
      
      if (error.response && error.response.data) {
        callback(error.response.data)
      }
    })
}
