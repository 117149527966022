import React, { Component } from 'react';
import ReactFilestack from 'filestack-react';

import { withStyles } from '@material-ui/core/styles';
import { styles } from '../styles';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import AddCircle from '@material-ui/icons/AddCircle';
import Refresh from '@material-ui/icons/Refresh';

//FILESTACK PARAMETERS
export const API_KEY = process.env.REACT_APP_FILESTACK_API_KEY

export const onFileSelected = function(file) {
  if (file.size > 262144000) {
    throw new Error(`${file.filename} is too big. For the best experience files should be 250 MB or less. Typically compressing videos is the best way to reduce file size.`);
  }

  var filenameClean = file.filename.replace(/[^A-Z0-9.-]+/ig, "_")
  file.name = filenameClean
  file.filename = filenameClean

  return file
}

export let OPTIONS = {
  fromSources: ['local_file_system', 'box', 'dropbox', 'clouddrive', 'googledrive', 'onedrive'],
  maxFiles: 100,
  maxSize: 262144000, //250MB in bytes
  errorsTimeout: 10000,
  storeTo: {
    location: 's3',
    container: 'solofire-usercontent',
    region: 'us-east-1',
    access: 'public'
  },
  onFileSelected: function (file) {
    onFileSelected(file)
  }
}

class FilestackButton extends Component {
	uploadedNewVersion = () => {
		const { uploadedNewVersionText, classes } = this.props;

		if (uploadedNewVersionText) {
			return (
				<Typography variant="h5" className={`${classes.headlineTorquoise} ${classes.updatedNewVersion}`}>File has been updated.</Typography>
			)
		}
	}

  // filestackPickerCalled = () => {
  //   const close = this.props.close;

  //   // if (close) {
  //   //   setTimeout(() => {
  //   //     close();
  //   //   }, 250);
  //   // }
  // }

  on360FileSelected = file => {
    var filenameClean = file.filename.replace(/[^A-Z0-9.-]+/ig, "_")
    file.name = filenameClean
    file.filename = filenameClean

    return file
  }

  checkAndAddHeaderText() {
    const fspContent = document.querySelector(".fsp-content");

    if (fspContent) {
      clearInterval(this.intervalId);

      const customMessage = document.createElement("div");
      customMessage.className = "custom-message-wrapper";
      customMessage.style.marginTop = "-40px";
      customMessage.innerHTML = `
        <p>
          <strong>Uploading Sensitive Files?</strong> 
          Make sure to password protect any sensitive files that are uploaded to the platform.
        </p>
    `;

      const dropAreaContainer = document.querySelector(
        ".fsp-drop-area-container"
      );
      if (dropAreaContainer) {
        dropAreaContainer.insertBefore(
          customMessage,
          dropAreaContainer.firstChild
        );
      }
    }
  }

  componentDidMount() {
    this.intervalId = setInterval(() => {
      this.checkAndAddHeaderText();
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

	render() {
    const { onboarding, buttonType, FULL_LIBRARY_ID, uploadSuccess, selectedLibraryItemId, extraClass, classes } = this.props;
    let { selectedGroup } = this.props;
    let parentGroupId = null,
        groupId = null,
        onboardingButton = null;

    OPTIONS.maxSize = 262144000
    OPTIONS.storeTo.container = 'solofire-usercontent'
    OPTIONS.storeTo.path = ''
    OPTIONS.onFileSelected = onFileSelected

    if (selectedGroup) {
      parentGroupId = selectedGroup.parent;
      groupId = selectedGroup._id;
    }

    let buttonClass = `${extraClass}`,
    		buttonText = '';

    if (!extraClass) {
      buttonClass = `${classes.addButton} ${classes.addButtonLibraryContent}`
    }

    if (groupId === FULL_LIBRARY_ID) {
    	groupId = null;
    }

    if (onboarding) {
      onboardingButton = classes.onboardingFilestackButton;
      buttonClass += ` ${classes.addButtonOnboarding}`
    }

    if (buttonType === 'newVersion') {
      
      OPTIONS.maxFiles = 1;

      buttonClass = `${classes.borderFilestack} ${classes.addButtonLibraryContent} ${classes.buttonWhiteBackground}`;
      buttonText = <span className={classes.addButtonLibraryContentText}><Refresh className={`${classes.addCircleIcon} ${classes.addCircleIconLibraryContent}`} />Upload New Version</span>;

    } else if (buttonType === 'users') {
      
      OPTIONS.maxFiles = 1;

      buttonClass = `${classes.borderFilestack} ${classes.addButtonLibraryContent} ${classes.buttonWhiteBackground}`;
      buttonText = <span className={classes.addButtonLibraryContentText}><Refresh className={`${classes.addCircleIcon} ${classes.addCircleIconLibraryContent}`} />Upload Users</span>;

    } else {

      let buttonNameText = 'Content'
      OPTIONS.maxFiles = 100

      if (buttonType === '360') {
        buttonNameText = '360 Video'
        OPTIONS.maxFiles = 1
        OPTIONS.maxSize = 262144000000
        OPTIONS.storeTo.container = 'solofire360'
        OPTIONS.storeTo.path = `/${Date.now()}-360-Video/`
        OPTIONS.onFileSelected = this.on360FileSelected
      }

      if (groupId) {
        buttonText = <div className={classes.truncateText} style={{maxWidth: 'calc(100vw - 85px'}}><AddCircle className={`${classes.addCircleIcon} ${classes.addCircleIconLibraryContent}`} />Upload New {buttonNameText} to <i>{selectedGroup.name}</i></div>
      } else {
        buttonText = <span><AddCircle className={`${classes.addCircleIcon} ${classes.addCircleIconLibraryContent}`} />Upload New {buttonNameText}</span>
      }
      
    }

    //NOTE: in /public/style.css > .fsp-picker-appear-active - preventing picker slow transition because it was causing UI problems with elements fading at different times whent modal popped up

    return (
    	<div className={`${classes.inline} ${onboardingButton}`}>
	    	<ReactFilestack
	        apikey={API_KEY}
	        actionOptions={OPTIONS}
          customRender={({ onPick }) => (
            <Button className={buttonClass} onClick={onPick}>
              {buttonText}
            </Button>
          )}
          onSuccess={result => uploadSuccess(result, parentGroupId, groupId, selectedLibraryItemId, buttonType)}
	      />
	      {this.uploadedNewVersion()}
      </div>
    )
  }
}

export default withStyles(styles)(FilestackButton);