import _ from "lodash";
import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactPlayer from 'react-player';

import { withStyles } from '@material-ui/core/styles';
import { styles } from '../styles';

import Button from '@material-ui/core/Button';

import { updateCompany } from '../../actions/actions_index';

class TourModal extends Component {

	constructor(props) {
    super(props);

    this.state = {
      card: 1
    };
  }

  handleCard = (change) => {
  	const { token, companyId, updateCompany } = this.props;
  	const { card } = this.state;

  	let newCard = card;
  	newCard += change;

  	this.setState({
  		card: newCard
  	})

  	if (newCard === 11) {
  		const values = {
  		  tour: false
  		}

  		updateCompany(token, values, companyId, () => {

  		})
  	}
  }

	render() {
		const { classes } = this.props;
		const { card } = this.state;

		let previousDisabled = null,
				nextDisabled = null;

		if (card === 1) {
			previousDisabled = classes.tourButtonDisabled;
		}

		return (
			<div className={classes.tourModal}>
			{card <= 9 &&	
        <React.Fragment>
          <img className={classes.tourCard} src={`/images/verb_tour/Tour_0${card}.png`} alt={''} onClick={() => this.handleCard(1)} />
  				<div className={classes.tourButtonContainer}>
  					<Button className={`${classes.tourButton} ${classes.tourPreviousButton} ${previousDisabled}`} variant="contained" color="secondary" onClick={() => this.handleCard(-1)} disabled={card === 1}>Previous</Button>
  					<Button className={`${classes.tourButton} ${classes.tourNextButton} ${nextDisabled}`} variant="contained" color="secondary" onClick={() => this.handleCard(1)}>Next</Button>
  				</div>
        </React.Fragment>
			}
      {card >= 10 &&
        <div style={{width: '100%', height: '100vh'}}>
          <ReactPlayer 
            // className={classes.reactPlayer}
            width='100%'
            height='100vh'
            url="https://vimeo.com/498404111"
            controls={true} 
            // muted={true} 
            // playing={true}
          />
          <div className={classes.videoTourButtonContainer}>
            <Button className={`${classes.tourButton} ${classes.tourPreviousButton} ${previousDisabled}`} variant="contained" color="secondary" onClick={() => this.handleCard(-1)} disabled={card === 1}>Previous</Button>
            <Button className={`${classes.tourButton} ${classes.tourNextButton} ${nextDisabled}`} variant="contained" color="secondary" onClick={() => this.handleCard(1)}>Finished</Button>
          </div>
        </div>
      }
      </div>
		)
	}

}

function mapStateToProps(state) {
  if (_.isEmpty(state.login.token)) {
    return {}
  }

  return { 
    token: state.login.token,
    companyId: state.login.company._id
  };
}

const mapDispatchToProps = dispatch => ({
  updateCompany: (token, values, id, callback) => dispatch(updateCompany(token, values, id, callback))
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TourModal));