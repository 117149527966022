import _ from "lodash";
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import PageVisibility from 'react-page-visibility';
import { Beforeunload } from 'react-beforeunload';

import { withStyles } from '@material-ui/core/styles';
import { styles } from '../styles';

// import { Typography, AppBar, Toolbar, Grid, IconButton, Card, CardMedia, Button } from '@material-ui/core';
// import { CloudDownload, ArrowDropDown } from '@material-ui/icons';

import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';

import MailOutline from '@material-ui/icons/MailOutline';
import CloudDownload from '@material-ui/icons/CloudDownload';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';

import LoadingDivContainer from '../subcomponents/loadingDiv';
import PreviewLibraryItemModalContainer from '../modals/previewLibraryItemModal';

import { convertToDateString } from '../helpers/helperFunctions';
import { getFileType } from '../helpers/gridLibraryHelpers';

import { ROOT_URL } from '../../actions/actions_constants';
import { fetchRoomDemo } from '../../actions/actions_index';

import { notBlack } from '../../theme';

//Time Duration Tracker
let DURATION = 0;
let TOTAL_DURATION = 0;

class Room extends Component {
	constructor(props) {
    super(props);

    this.state = {
      loading: true,
      preview: false,
      openedId: null,
      timer: null,
      pageActive: true,
      selectedLibraryItem: {},
      room: {},
      details: {},
      branding: {},
      allSessionEvents: [],
      openPreviewLibraryItemModal: false
    }
  }

	componentWillMount() {
    const { path } = this.props.match;
		const { link_udid } = this.props.match.params;

    let preview = false;

    if (path.includes('preview')) {
      preview = true;
    }

		this.props.fetchRoomDemo(link_udid, preview, data => {

			const info = data.data;
      let allSessionEvents = [];
      allSessionEvents[0] = {
        sessions: [],
        date: info.mostRecentDate
      }

      // let message = info.message;

      // // if (!info.message) {
      //   message = "With SoloFire your sales reps can access all of your marketing material in one place AND share it on an intelligent platform that actually moves the sale forward. Imagine your customers getting this same experience when your sales reps share your content with them."
      // // }

			allSessionEvents[0].sessions[0] = {
        link_udid: '',
        app_profile: '',
        // link_message: message,
        library_items: []
      };

      info.content_details.map(content => {
        allSessionEvents[0].sessions[0].library_items.push({
          _id: content._id,
          details: [content]
        });
        return null
      })

      if (info.previous_content) {
        allSessionEvents[1] = {
          sessions: [],
          date: info.previousShareDate
        }

        allSessionEvents[1].sessions[0] = {
          link_udid: '',
          app_profile: '',
          // link_message: message,
          library_items: []
        };

        info.previous_content.map(content => {
          allSessionEvents[1].sessions[0].library_items.push({
            _id: content._id,
            details: [content]
          });
          return null
        })
      }

			this.setState({
				loading: false,
        preview: preview,
        openedId: info.openedId,
				// room: room, //empty for now
				roomDemoId: info._id,
        details: {
          company_name: info.contact_details.contact_company,
          user_name: info.contact_details.name,
          user_email: info.contact_details.email,
          contact_name: "Future Customer",
          user_initials: info.contact_details.user_initials,
          mostRecentDate: info.mostRecentDate,
          previousShareDate: info.previousShareDate
        },
				branding: {
          branding_primary: info.branding_color,
          branding_small_logo: info.branding_logo
        },
        allSessionEvents: allSessionEvents
			})
		});

		this.startTimer();
	}

  componentDidMount () {
    const script = document.createElement("script");
    script.type = 'text/javascript';
    script.innerHTML = 'window.$crisp=[];window.CRISP_WEBSITE_ID="0abe5e35-8164-4e39-8c76-e5a2bed85d86";(function(){d=document;s=d.createElement("script");s.src="https://client.crisp.chat/l.js";s.async=1;d.getElementsByTagName("head")[0].appendChild(s);})();';
    script.async = true;

    document.body.appendChild(script);
  }

	//Count Duration
	startTimer = () => {
	  setInterval(this.tick, 1000)
	}

	handleVisibilityChange = active => {
	  this.setState({ 
	    pageActive: active 
	  });
	}

	tick = () => {
	  const { pageActive } = this.state;

	  if (pageActive) {
	    DURATION += 1000;
	  }
  }
	//End Duration

	//Record View/Download Event
  roomItemClick = (event, item) => {
    TOTAL_DURATION += DURATION;
    DURATION = 0;

    this.setState({
      openPreviewLibraryItemModal: true,
      selectedLibraryItem: item
    })
  }

  recordDownloadEvent = (event, downloadedItemId)  => {
	  event.stopPropagation();

    if (!downloadedItemId) {
      downloadedItemId = this.state.selectedLibraryItem._id;
    }

	  this.recordEvent(2, 0, downloadedItemId);
	}

	// recordReshareEvent = (event, library_item_id)  => {
	//   event.stopPropagation();
	//   this.recordEvent('reshare', library_item_id);
	// }

	recordEvent = (type, duration, downloadedItemId) => {
    //type 1: view, 2: download, 3: closed
	  const { preview, roomDemoId, openedId, selectedLibraryItem } = this.state;
    let data = {
      roomDemoId: roomDemoId, 
      openedId: openedId
    };
    let formData = new FormData();

    // console.log(type)
    // console.log(selectedLibraryItem)
    // console.log(downloadedItemId)
    // console.log(duration)

    if (type === 3) {
      TOTAL_DURATION += DURATION;
      data.total_duration = TOTAL_DURATION;

      if (selectedLibraryItem) {
        data.item = selectedLibraryItem._id;
        data.duration = DURATION;
      }
    } else if (type === 2 && downloadedItemId) {
      data.item = downloadedItemId;
      data.type = 2;
    } else if (selectedLibraryItem) {
      data.item = selectedLibraryItem._id;
      data.duration = duration;
      data.type = 1;
    }

    _.map(data, (value, key) => {
      formData.append(key, value);
    });
    
    if (!preview && type === 3 && navigator && navigator.sendBeacon) {
      navigator.sendBeacon(`${ROOT_URL}room_demo_closed`, formData);
    } else if (!preview && navigator && navigator.sendBeacon) {
      navigator.sendBeacon(`${ROOT_URL}room_demo_event`, formData);
    }

    this.setState({
      dowloadedItem: null
    })
	}

  closedLibraryItemModal = () => {
    this.recordEvent(1, DURATION);
    TOTAL_DURATION += DURATION;
    DURATION = 0;

    this.setState({
  		openPreviewLibraryItemModal: false,
      selectedLibraryItem: null
  	})
  }

  handlePreviewLibraryItemModal = () => {
    const { openPreviewLibraryItemModal, selectedLibraryItem, branding } = this.state;
    let color = null;

    if (branding.branding_primary) {
    	color = {
    		color: branding.branding_primary
    	}
    }

    if (openPreviewLibraryItemModal) {
      return (
        <PreviewLibraryItemModalContainer
          openPreviewLibraryItemModal={openPreviewLibraryItemModal}
          selectedLibraryItem={selectedLibraryItem}
          closedLibraryItemModal={this.closedLibraryItemModal}
          type='room'
          color={color}
          //**RECORD
          recordDownloadEvent={this.recordDownloadEvent}
        />
      )
    }
  }

  handleContentItems = (type, library_items) => {
    const { classes } = this.props;
    const { branding } = this.state;
    let color = null,
        itemExtraClass = null,
        titleExtraClass = null,
        nameExtraClass = null,
        cardExtraClass = null;

    if (branding.branding_primary) {
      color = {
        color: branding.branding_primary
      }
    }

    if (type === 1) {
      itemExtraClass = classes.roomMostRecentItem;
      titleExtraClass = classes.roomMostRecentCardTitle;
      nameExtraClass = classes.roomMostRecentItemName;
      cardExtraClass = classes.roomMostRecentCard;
    }
    

    return (
      library_items.map(item => {
        const _id = item._id;
        const item_details = item.details[0];
        let thumbnail = '';
        let default_thumbnail = null;

        if (item_details.custom_thumbnail) {
          thumbnail = item_details.custom_thumbnail;
        } else if (item_details.thumbnail) {
          thumbnail = item_details.thumbnail;
        } else {
          default_thumbnail = classes.roomCardNoBorder;
          thumbnail = '/images/default_thumbnail.png';
        }

        return (
          <div key={_id} className={`${classes.roomItem} ${itemExtraClass}`}>
            
            {item_details.link &&
              <a href={item_details.filename} target="_blank">
                <Card
                  _id={_id}
                  key={_id}
                  className={`${classes.card} ${classes.roomCard} ${cardExtraClass} ${type === 1 ? 'roomMostRecentCard' : null} ${default_thumbnail} roomCard`}
                  type="link"
                  onClick={event => this.roomItemClick(event, item_details)}
                >
                  <CardMedia
                    _id={_id}
                    className={`${classes.cardMedia} ${classes.roomCardMedia}`}
                    component="img"
                    alt={item_details.name}
                    image={thumbnail}
                    title={item_details.name}
                  />
                </Card>
                <div className={`${classes.roomCardTitle} ${titleExtraClass}`}>
                  {getFileType(item_details.file_type, 'room')}
                  <div className={`${classes.roomItemName} ${nameExtraClass} ${classes.truncateText}`}> 
                    {item_details.name}
                  </div>
                </div>
              </a>
            }
            {!item_details.link &&
              <div>
                <Card
                  _id={_id}
                  key={_id}
                  className={`${classes.card} ${classes.roomCard} ${cardExtraClass} ${type === 1 ? 'roomMostRecentCard' : null} ${default_thumbnail} roomCard`}
                  type="view"
                  onClick={event => this.roomItemClick(event, item_details)}
                >
                  <CardMedia
                    _id={_id}
                    className={`${classes.cardMedia} ${classes.roomCardMedia}`}
                    component="img"
                    alt={item_details.name}
                    image={thumbnail}
                    title={item_details.name}
                  />
                  {(!item_details.link && item_details.downloadable) && 
                    <a 
                      href={item_details.download_url} 
                      download={item_details.name}
                      type="download"
                      onClick={(event) => this.recordDownloadEvent(event, _id)}
                      target="_blank"
                    >
                      <IconButton className={classes.roomDownloadButton}>
                        <CloudDownload style={color} className={classes.roomDownloadIcon} />
                      </IconButton>
                    </a>
                  }
                </Card>
                <div className={`${classes.roomCardTitle} ${titleExtraClass}`} type="view" onClick={event => this.roomItemClick(event, item_details)}>
                  {getFileType(item_details.file_type, 'room')}
                  <div className={`${classes.roomItemName} ${nameExtraClass} ${classes.truncateText}`}> 
                    {item_details.name}
                  </div>
                </div>
              </div>
            }

          </div>
        )
      })
    )
  }
  
  handleSessions = (type, sessionEvents) => {
    const { classes } = this.props;
    const { details } = this.state;
    let sectionExtraClass = null,
        containerExtraClass = null,
        textExtraClass = null,
        initialsExtraClass = null;

    if (type === 1) {
      sectionExtraClass = classes.roomMostRecentSectionSession;
      containerExtraClass = classes.roomMostRecentItemsContainer;
      textExtraClass = classes.roomMostRecentText;
      initialsExtraClass = classes.roomMostRecentInitialsContainer;
    }

    return (
      sessionEvents.sessions.map(session => (
        <div key={session.link_udid} className={`${classes.roomSectionSession} ${sectionExtraClass}`}>
          <Typography variant="body1" className={`${classes.roomText} ${textExtraClass}`}>
            <span className={classes.roomSectionHeader}>{convertToDateString(sessionEvents.date)} -</span>
            {details.user_initials &&
              <span className={`${classes.roomInitialsContainer} ${initialsExtraClass}`}>  
                <span className={classes.roomInitials}>{details.user_initials}</span> 
              </span>
            }
            {details.user_name && 
              <i style={{fontWeight: 600}}>{details.user_name}</i>
            }
            {(!details.user_name && details.user_email) && 
              <i style={{fontWeight: 600}}>{details.user_email}</i>
            }: {session.link_message}
          </Typography>
          <div className={`${classes.roomItemsContainer} ${containerExtraClass}`}>
            {this.handleContentItems(type, session.library_items)}
          </div>
        </div>
      ))
    )
  }

  handleSection = (type, sessionEvents) => {
    const { classes } = this.props;
    
    //type => 1: most recent, 2: previous
    return (
      <div className={type === 1 ? classes.roomMostRecentSection : classes.roomSection}>
        <div className={type === 1 ? classes.roomMostRecentSectionContainer : classes.roomSectionContainer}>
          {this.handleSessions(type, sessionEvents)}
        </div>
      </div>
    )
  }

  handlePreviousSections = (allSessionEvents) => {
    const { classes } = this.props;

    return (
      allSessionEvents.map((sessionEvents, index) => {
        if (index !== 0) {
          return (
            <div key={sessionEvents.date} className={classes.roomFirstPreviousSection}>
              {this.handleSection(2, sessionEvents)}
            </div>
          )
        }
        return null
      })
    )
  }

  render() {
    const { classes } = this.props;
    const { loading, details, branding, allSessionEvents } = this.state;
    let backgroundColor = null,
        backgroundImageContainer = null,
        gradientHeight = null;
    const windowWidth = document.documentElement.clientWidth;

    if (branding.branding_primary) {
      backgroundColor = {
        backgroundColor: branding.branding_primary
      }
    }

    if (windowWidth > 820) {
      gradientHeight = 400; //400
    } else if (windowWidth <= 820 && windowWidth > 620) {
      gradientHeight = 310; //250
    } else if (windowWidth <= 620 && windowWidth > 520) {
      gradientHeight = 260; //200
    } else if (windowWidth <= 520 && windowWidth > 470) {
      gradientHeight = 210; //150
    } else {
      gradientHeight = 160; //125
    }


    if (branding.branding_background_image) {
      backgroundImageContainer = {
        background: `
          url("${branding.branding_background_image}"),
          radial-gradient(38% ${gradientHeight}px at 62% top, rgba(1, 22, 39, 0) 0%, rgba(1, 22, 39, 0) 40%, rgba(1, 22, 39, 1))
        `,
        // radial-gradient(75% ${gradientHeight}px at right top, rgba(1, 22, 39, 0), rgba(1, 22, 39, 0), rgba(1, 22, 39, 1))
        backgroundBlendMode: 'darken',
        backgroundRepeat: 'no-repeat, no-repeat',
        backgroundSize: '75% auto, auto 100%',
        backgroundPosition: 'right top, 50% top'
        // [theme.breakpoints.down('xs')]: {
        //   background: `
        //     url("${branding.branding_background_image}"),
        //     radial-gradient(75% 150px at top right, rgba(1, 22, 39, 0), rgba(1, 22, 39, 0), rgba(1, 22, 39, 1))
        //   `
        // }
      }
    } else {
      backgroundImageContainer = {
        background: notBlack
      }
    }

    if (loading) {
      return (
        <LoadingDivContainer />
      )
    } else {
      return (
        <div className={classes.roomContainer}>
          <div className={classes.roomWideBackground}>
            <div className={classes.roomMostRecentContainer} style={backgroundImageContainer}>
              <AppBar position="static" className={classes.roomHeader}>
                <Toolbar className={classes.roomHeader}>
                  <div className={`${classes.roomHeaderContainer} ${classes.roomHeaderContainerLogo}`}>
                    {!branding.branding_small_logo && (
                      <img src="/images/solofire_icon.png" alt={''} />
                    )}
                    {branding.branding_small_logo && (
                      <img src={branding.branding_small_logo} alt={''} />
                    )}
                  </div>
                  <div className={classes.roomHeaderContainer}>
                    <Typography variant="body1" className={`${classes.roomHeaderText} ${classes.roomTextEnergy}`}>
                      Shared by
                    </Typography>
                    {details.user_name && 
                      <Typography variant="h3" className={classes.roomHeaderName}>
                        {details.user_name}
                      </Typography>
                    }
                    {(!details.user_name && details.user_email) && 
                      <Typography variant="h3" className={`${classes.roomHeaderName} ${classes.truncateText}`}>
                        {details.user_email}
                      </Typography>
                    }
                    <Typography variant="body1" className={classes.roomHeaderText}>
                      {details.company_name}
                    </Typography>
                    <Typography variant="h2" className={classes.roomHeaderContact}>
                      {details.contact_name &&
                        <span>{details.contact_name}<span className={classes.roomHeaderWelcome}>, welcome!</span></span>
                      }
                      {(!details.contact_name && details.contact_email) &&
                        <span>{details.contact_email}<span className={classes.roomHeaderWelcome}>, welcome!</span></span>
                      }
                      {!details.contact_name && !details.contact_email && details.contact_phone &&
                        <span>{details.contact_phone}<span className={classes.roomHeaderWelcome}>, welcome!</span></span>
                      }
                    </Typography>
                  </div>
                </Toolbar>
              </AppBar>
              <PageVisibility onChange={this.handleVisibilityChange}>
              </PageVisibility>
              <Beforeunload 
                onBeforeunload={event => {
                  this.recordEvent('view');
                }} 
              />
              {this.handleSection(1, allSessionEvents[0])}
            </div>
            <div className={classes.roomContactInfoContainer}>
              <a href={`mailto:${details.user_email}`} target="_blank">
                <div className={classes.roomContactInfoBox}>
                  <IconButton>
                    <MailOutline className={classes.roomContactIcon} />
                  </IconButton>
                </div>
              </a>
            </div>
          </div>
          {allSessionEvents.length > 1 &&
            <div className={classes.roomWideBackgroundWhite}>
              <div className={classes.roomPreviousSection}>
                <Button  style={backgroundColor} className={classes.roomButton} variant="contained" color="secondary">
                  Previously Shared
                  <ArrowDropDown className={classes.addCircleIcon} />
                </Button>
                {this.handlePreviousSections(allSessionEvents)}
              </div>
            </div>
          }
          {this.handlePreviewLibraryItemModal()}
          <div className={classes.roomPoweredByBar}>
            <Typography variant="body1" className={classes.roomPoweredByText}>
              <span className={classes.roomPoweredByTextSpan}>Powered by <a href="https://solofire.com">SoloFire</a></span>
              <img className={classes.roomPoweredByLogo} src="/images/solofire_icon.png" />
            </Typography>
          </div>
        </div>
      )
    }
  }
}

function mapStateToProps(state) {
  return { 
    
  };
}


const mapDispatchToProps = dispatch => ({
  fetchRoomDemo: (link_udid, preview, callback) => dispatch(fetchRoomDemo(link_udid, preview, callback))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Room)));