import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';
import { styles } from '../../styles';

// import { IconButton } from '@material-ui/core';
// import { MoreVert } from '@material-ui/icons';

import IconButton from '@material-ui/core/IconButton';

import MoreVert from '@material-ui/icons/MoreVert';

class TeamMenu extends Component {
 
  render() {
    const { value, handleTeamMenu, selectedTeamId, classes } = this.props;

    return (
      <div className={classes.marginRightSmall}>
        <IconButton 
          id={value._id}
          name={value.name}
          aria-label="Team Menu"
          onClick={handleTeamMenu}
          className={classes.iconRight}
        >
          <MoreVert 
            className={selectedTeamId === value._id ? `${classes.verticalDots} ${classes.verticalDotsSmall} ${classes.selectedVerticalDots}` : `${classes.verticalDots} ${classes.verticalDotsSmall}`}
          />
        </IconButton>
      </div>
    )
  }
  
}

export default withStyles(styles)(TeamMenu);