import _ from 'lodash';
import React, { PureComponent } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import { styles } from '../styles';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import WithLoading from '../subcomponents/withLoading';
import GridContainer from '../subcomponents/gridContainer';

import {
  analyticsTableHeight,
  activeCampaignEvent,
} from '../helpers/helperFunctions';
import { multipleFilter } from '../helpers/gridLibraryHelpers';
import { compareName } from '../helpers/gridUsersHelpers';

import {
  fetchViewRate,
  fetchUsersAnalytics,
} from '../../actions/actions_index';

class Users extends PureComponent {
  state = {
    sorting: [{ columnName: 'totalSessionsAfter', direction: 'desc' }],
  };

  componentWillReceiveProps(newProps) {
    if (!_.isEqual(this.props.postData, newProps.postData)) {
      newProps.loadData(newProps.postData);
    }
  }

  handleSortingChange = (sorting) => {
    this.setState({
      sorting,
    });
  };

  render() {
    const {
        enterprise,
        verb_live,
        page,
        postData,
        usersAnalytics,
        viewRate,
        classes,
      } = this.props,
      { sorting } = this.state;

    const rows = usersAnalytics.repData;

    const columns = [
      {
        name: 'userNameDivider',
        title: 'Name',
        getCellValue: (row) => ({
          first_name: row.first_name,
          last_name: row.last_name,
        }),
      },
      {
        name: 'totalSessionsAfter',
        title: 'Sessions',
        getCellValue: (row) => row.totalSessions,
      },
      { name: 'averageSessionLength', title: 'Avg Duration' },
      { name: 'totalSessionLength', title: 'Total Duration' },
      //contacts **NEED**
      {
        name: 'contacts',
        title: 'Contacts',
        getCellValue: (row) => row.contactsCount,
      },
      { name: 'browses', title: 'Browses', getCellValue: (row) => row.browses },
      { name: 'shows', title: 'Shows', getCellValue: (row) => row.shows },
      {
        name: 'sharesDivider',
        title: 'Shares',
        getCellValue: (row) => row.shares,
      },

      {
        name: 'totalMediaOpensAfter',
        title: 'Total Opens',
        getCellValue: (row) => row.total_media_opens,
      },
      {
        name: 'total_media_views',
        title: 'Total Views',
        getCellValue: (row) => row.total_media_views,
      },
      { name: 'unique_media_views', title: 'Unique Views' },
      {
        name: 'view_rate',
        title: 'View Rate',
        getCellValue: (row) => row.view_rate,
      },
      { name: 'averageMediaSessionLength', title: 'Avg Duration' },
      { name: 'totalMediaSessionLength', title: 'Total Duration' },
    ];

    let columnBands = [
      {
        title: '',
        children: [{ columnName: 'userNameDivider' }],
      },
      {
        title: 'User Activity',
        children: [
          { columnName: 'totalSessionsAfter' },
          { columnName: 'averageSessionLength' },
          { columnName: 'totalSessionLength' },
          { columnName: 'contacts' },
          { columnName: 'browses' },
          { columnName: 'shows' },
          { columnName: 'sharesDivider' },
        ],
      },
      {
        title: 'Contact Activity',
        children: [
          { columnName: 'totalMediaOpensAfter' },
          { columnName: 'total_media_views' },
          { columnName: 'unique_media_views' },
          { columnName: 'view_rate' },
          { columnName: 'averageMediaSessionLength' },
          { columnName: 'totalMediaSessionLength' },
        ],
      },
    ];

    const tableColumnExtensions = [
      { columnName: 'userNameDivider', width: 250 },
      { columnName: 'totalSessionsAfter', width: 105 },
      { columnName: 'averageSessionLength', width: 95 },
      { columnName: 'totalSessionLength', width: 95 },
      { columnName: 'contacts', width: 95 },
      { columnName: 'browses', width: 95 },
      { columnName: 'shows', width: 80 },
      { columnName: 'sharesDivider', width: 85 },

      { columnName: 'totalMediaOpensAfter', width: 95 },
      { columnName: 'total_media_views', width: 80 },
      { columnName: 'unique_media_views', width: 95 },
      { columnName: 'view_rate', width: 70 },
      { columnName: 'averageMediaSessionLength', width: 95 },
      { columnName: 'totalMediaSessionLength', width: 100 },
    ];

    const sortingStateColumnExtensions = [
      { columnName: 'userNameDivider', sortingEnabled: true },
      { columnName: 'totalSessionsAfter', sortingEnabled: true },
      { columnName: 'averageSessionLength', sortingEnabled: true },
      { columnName: 'totalSessionLength', sortingEnabled: true },
      { columnName: 'contacts', sortingEnabled: true },
      { columnName: 'browses', sortingEnabled: true },
      { columnName: 'shows', sortingEnabled: true },
      { columnName: 'sharesDivider', sortingEnabled: true },

      { columnName: 'totalMediaOpensAfter', sortingEnabled: true },
      { columnName: 'total_media_views', sortingEnabled: true },
      { columnName: 'unique_media_views', sortingEnabled: true },
      { columnName: 'view_rate', sortingEnabled: true },
      { columnName: 'averageMediaSessionLength', sortingEnabled: true },
      { columnName: 'totalMediaSessionLength', sortingEnabled: true },
    ];

    const filteringStateColumnExtensions = [
      { columnName: 'userNameDivider', filteringEnabled: true },
      { columnName: 'totalSessionsAfter', filteringEnabled: false },
      { columnName: 'averageSessionLength', filteringEnabled: false },
      { columnName: 'totalSessionLength', filteringEnabled: false },
      { columnName: 'contacts', filteringEnabled: false },
      { columnName: 'browses', filteringEnabled: false },
      { columnName: 'shows', filteringEnabled: false },
      { columnName: 'sharesDivider', filteringEnabled: false },

      { columnName: 'totalMediaOpensAfter', filteringEnabled: false },
      { columnName: 'total_media_views', filteringEnabled: false },
      { columnName: 'unique_media_views', filteringEnabled: false },
      { columnName: 'view_rate', filteringEnabled: false },
      { columnName: 'averageMediaSessionLength', filteringEnabled: false },
      { columnName: 'totalMediaSessionLength', filteringEnabled: false },
    ];

    const integratedFilteringColumnExtensions = [
      { columnName: 'userNameDivider', predicate: multipleFilter },
    ];

    const integratedSortingColumnExtensions = [
      { columnName: 'userNameDivider', compare: compareName },
    ];

    if (!enterprise && verb_live) {
      columns.splice(6, 0, { name: 'verb_live', title: 'LIVE' });
      tableColumnExtensions.splice(6, 0, {
        columnName: 'verb_live',
        width: 80,
      });
      sortingStateColumnExtensions.splice(6, 0, {
        columnName: 'verb_live',
        sortingEnabled: false,
      });
      filteringStateColumnExtensions.splice(6, 0, {
        columnName: 'verb_live',
        filteringEnabled: false,
      });

      columnBands = [
        {
          title: '',
          children: [{ columnName: 'userNameDivider' }],
        },
        {
          title: 'User Activity',
          children: [
            { columnName: 'totalSessionsAfter' },
            { columnName: 'averageSessionLength' },
            { columnName: 'totalSessionLength' },
            { columnName: 'contacts' },
            { columnName: 'browses' },
            { columnName: 'shows' },
            { columnName: 'verb_live' },
            { columnName: 'sharesDivider' },
          ],
        },
        {
          title: 'Contact Activity',
          children: [
            { columnName: 'totalMediaOpensAfter' },
            { columnName: 'total_media_views' },
            { columnName: 'unique_media_views' },
            { columnName: 'view_rate' },
            { columnName: 'averageMediaSessionLength' },
            { columnName: 'totalMediaSessionLength' },
          ],
        },
      ];
    }

    return (
      <Grid item className={classes.topDrilldownPages}>
        <Grid container spacing={1} className={classes.gridSmallMarginTop}>
          <Grid item xs={1} className={classes.gridNine}>
            <Typography variant='h5' className={classes.smallNumberHeading}>
              Total Users
            </Typography>
            <div className={`${classes.smallNumberBox} ${classes.usersBox}`}>
              <Typography variant='h3' className={classes.smallNumber}>
                {usersAnalytics.usersCount ? usersAnalytics.usersCount : 0}
              </Typography>
            </div>
          </Grid>
          <Grid
            item
            xs={1}
            className={`${classes.gridNine} ${classes.gridNineSmallPaddingRight}`}
          >
            <Typography variant='h5' className={classes.smallNumberHeading}>
              Sessions
            </Typography>
            <div className={`${classes.smallNumberBox} ${classes.sessionsBox}`}>
              <Typography variant='h3' className={`${classes.smallNumber}`}>
                {usersAnalytics.sessionsCount
                  ? usersAnalytics.sessionsCount
                  : 0}
              </Typography>
            </div>
          </Grid>
          <Grid
            item
            xs={1}
            className={`${classes.gridNine} ${classes.gridNineSmallPaddingRightLeft}`}
          >
            <Typography variant='h5' className={classes.smallNumberHeading}>
              Avg App Session
            </Typography>
            <div className={`${classes.smallNumberBox} ${classes.sessionsBox}`}>
              <Typography variant='h3' className={`${classes.smallNumber}`}>
                {usersAnalytics.sessionsAvg &&
                usersAnalytics.sessionsAvg.averageSessionLength
                  ? usersAnalytics.sessionsAvg.averageSessionLength
                  : 0}
              </Typography>
            </div>
          </Grid>
          <Grid
            item
            xs={1}
            className={`${classes.gridNine} ${classes.gridNineSmallPaddingLeft}`}
          >
            <Typography variant='h5' className={classes.smallNumberHeading}>
              Browse
            </Typography>
            <div className={`${classes.smallNumberBox} ${classes.sessionsBox}`}>
              <Typography variant='h3' className={`${classes.smallNumber}`}>
                {viewRate.sessionEventCounts.browses
                  ? viewRate.sessionEventCounts.browses
                  : 0}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={1} className={classes.gridNine}>
            <Typography variant='h5' className={classes.smallNumberHeading}>
              Total Contacts
            </Typography>
            <div className={`${classes.smallNumberBox} ${classes.contactsBox}`}>
              <Typography variant='h3' className={classes.smallNumber}>
                {usersAnalytics.contacts ? usersAnalytics.contacts : 0}
              </Typography>
            </div>
          </Grid>
          <Grid
            item
            xs={1}
            className={`${classes.gridNine} ${classes.gridNineSmallPaddingRight}`}
          >
            <Typography variant='h5' className={classes.smallNumberHeading}>
              Shows
            </Typography>
            <div className={`${classes.smallNumberBox} ${classes.showsBox}`}>
              <Typography variant='h3' className={classes.smallNumber}>
                {viewRate.sessionEventCounts.shows
                  ? viewRate.sessionEventCounts.shows
                  : 0}
              </Typography>
            </div>
          </Grid>
          <Grid
            item
            xs={1}
            className={`${classes.gridNine} ${classes.gridNineSmallPaddingLeft}`}
          >
            <Typography variant='h5' className={classes.smallNumberHeading}>
              Shares
            </Typography>
            <div className={`${classes.smallNumberBox} ${classes.showsBox}`}>
              <Typography variant='h3' className={classes.smallNumber}>
                {viewRate.sessionEventCounts.shares
                  ? viewRate.sessionEventCounts.shares
                  : 0}
              </Typography>
            </div>
          </Grid>
          <Grid
            item
            xs={1}
            className={`${classes.gridNine} ${classes.gridNineSmallPaddingRight}`}
          >
            <Typography variant='h5' className={classes.smallNumberHeading}>
              Total Views
            </Typography>
            <div className={`${classes.smallNumberBox} ${classes.viewsBox}`}>
              <Typography variant='h3' className={classes.smallNumber}>
                {viewRate.sessionEventCounts.media_views
                  ? viewRate.sessionEventCounts.media_views
                  : 0}
              </Typography>
            </div>
          </Grid>
          <Grid
            item
            xs={1}
            className={`${classes.gridNine} ${classes.gridNineSmallPaddingLeft}`}
          >
            <Typography variant='h5' className={classes.smallNumberHeading}>
              View Rate
            </Typography>
            <div className={`${classes.smallNumberBox} ${classes.viewsBox}`}>
              <Typography variant='h3' className={classes.smallNumber}>
                {viewRate.viewRate}%
              </Typography>
            </div>
          </Grid>
        </Grid>
        <Grid
          item
          className={`${classes.analyticsTableArea} ${classes.analyticsUsersTableArea}`}
        >
          <GridContainer
            // gridHeight={analyticsTableHeight()}
            tableType={'separation'}
            menuType={'-new'}
            appWebSeparation={'users'}
            rows={rows}
            columns={columns}
            columnBands={columnBands}
            tableColumnExtensions={tableColumnExtensions}
            sortingStateColumnExtensions={sortingStateColumnExtensions}
            sorting={sorting}
            handleSortingChange={this.handleSortingChange}
            filteringStateColumnExtensions={filteringStateColumnExtensions}
            integratedFilteringColumnExtensions={
              integratedFilteringColumnExtensions
            }
            integratedSortingColumnExtensions={
              integratedSortingColumnExtensions
            }
            showSelectAll={false}
            showSelectionColumn={false}
            showRowDetail={false}
            classes={classes}
            //Export to CSV
            page={page}
            postData={postData}
            //END Export to CSV
          />
        </Grid>
      </Grid>
    );
  }
}

const UsersWithLoading = WithLoading(Users);

class UsersContainer extends PureComponent {
  state = {
    loading: true,
  };

  loadData = (postData) => {
    const { token } = this.props;
    const loading = this.state.loading;

    if (!loading) {
      this.setState({
        loading: true,
      });
    }

    let dataCalls = [];

    dataCalls.push(this.props.fetchViewRate(token, postData, (result) => {}));

    dataCalls.push(
      this.props.fetchUsersAnalytics(token, postData, (result) => {})
    );

    Promise.all(dataCalls).then((result) => {
      this.setState({
        loading: false,
      });
    });
  };

  componentDidMount() {
    const { token, enterprise, currentUser, location, postData, master_admin } =
      this.props;

    this.loadData(postData);

    const values = {
      token,
      master_admin,
      enterprise,
      email: currentUser.email,
      page: location.pathname,
    };

    activeCampaignEvent(values);
  }

  render() {
    const loading = this.state.loading;

    return (
      <UsersWithLoading
        isLoading={loading}
        loadingReport={true}
        loadData={this.loadData}
        {...this.props}
      />
    );
  }
}

function mapStateToProps(state) {
  if (
    _.isEmpty(state.login.token) ||
    _.isEmpty(state.login.company) ||
    _.isEmpty(state.login.user)
  ) {
    return {
      loggedOut: true,
    };
  }

  return {
    token: state.login.token,
    master_admin: state.login.master_admin,
    companyId: state.login.company._id,
    enterprise: state.company.enterprise,
    verb_live: state.company.verb_live,
    owner: state.login.user._id,
    currentUser: state.login.user,
    currentRole: state.login.user.user_type,
    usersAnalytics: state.analytics.users,
    viewRate: state.analytics.viewRate,
  };
}

const mapDispatchToProps = (dispatch) => ({
  fetchViewRate: (token, values, callback) =>
    dispatch(fetchViewRate(token, values, callback)),
  fetchUsersAnalytics: (token, values, callback) =>
    dispatch(fetchUsersAnalytics(token, values, callback)),
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(UsersContainer);
