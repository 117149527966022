import _ from "lodash";
import React, { PureComponent } from "react";
import { compose } from 'redux';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { withStyles } from '@material-ui/core/styles';
import { styles } from '../styles';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import WithLoading from '../subcomponents/withLoading';
import GridContainer from '../subcomponents/gridContainer';
import DrillDown from '../modals/drillDown';

import { analyticsScreensTableHeight, activeCampaignEvent } from '../helpers/helperFunctions';

import { fetchScreensAnalytics  } from "../../actions/actions_index";

class Content extends PureComponent {
  state = {
    openDrillDown: false,
    selectedDetailId: null,
    selectedDetailName: null,
    sorting: [{ columnName: 'views', direction: 'desc' }]
  }

  handleSortingChange = sorting => {
    this.setState({
      sorting
    })
  }

  componentWillReceiveProps(newProps) {
    if (!_.isEqual(this.props.postData, newProps.postData)) {
      newProps.loadData(newProps.postData);
    }
  }

  rowClick = (event) => {
    const { screensAnalytics } = this.props
    const rows = screensAnalytics
    const { _id } = rows[event[0]]

    this.setState({
      openDrillDown: true,
      selectedDetailId: _id,
      selectedDetailName: _id
    })
  }

  closeDrillDown = () => {
    this.setState({
      openDrillDown: false
    })
  }

  handleDrillDown = () => {
    const { openDrillDown, selectedDetailId, selectedDetailName } = this.state
    const { postData } = this.props

    if (openDrillDown) {
      return (
        <DrillDown
          type="screen_details"
          openDrillDown={openDrillDown}
          selectedDetailId={selectedDetailId}
          selectedDetailName={selectedDetailName}
          postData={postData}
          closeDrillDown={this.closeDrillDown}
        />
      )
    }
  }

  render() {
    const { page, postData, screensAnalytics, classes } = this.props,
          { sorting } = this.state

    const rows = screensAnalytics

    const columns = [
      { name: 'name', title: 'Name' },
      { name: 'views', title: 'Views' },
      { name: 'total', title: 'Total Duration' },
      { name: 'avg', title: 'Average Duration' }
    ]

    const tableColumnExtensions = [
      { columnName: 'name' },
      { columnName: 'views', width: 120 },
      { columnName: 'total', width: 150 },
      { columnName: 'avg', width: 150 }
    ]

    const sortingStateColumnExtensions = [
      { columnName: 'name', sortingEnabled: true },
      { columnName: 'views', sortingEnabled: true },
      { columnName: 'total', sortingEnabled: true },
      { columnName: 'avg', sortingEnabled: true }
    ]

    const filteringStateColumnExtensions = [
      { columnName: 'name', filteringEnabled: true },
      { columnName: 'views', filteringEnabled: false },
      { columnName: 'total', filteringEnabled: false },
      { columnName: 'avg', filteringEnabled: false }
    ]

    return (
      <Grid item className={classes.topDrilldownPages}>   
        <Grid item className={`${classes.analyticsTableArea} ${classes.analyticsScreensTableArea}`}>
          
          {this.handleDrillDown()}

          <GridContainer 
            tableType={'separation'}
            menuType={'-new'}
            appWebSeparation={'screens'}
            rows={rows}
            columns={columns}
            tableColumnExtensions={tableColumnExtensions}
            sortingStateColumnExtensions={sortingStateColumnExtensions}
            sorting={sorting}
            handleSortingChange={this.handleSortingChange}
            filteringStateColumnExtensions={filteringStateColumnExtensions}
            showSelectAll={false}
            showSelectionColumn={false}
            showRowDetail={false}
            onSelectionChange={this.rowClick}
            classes={classes}
            //Export to CSV
            page={page}
            postData={postData}
            //END Export to CSV
          />
        </Grid>
      </Grid>
    );
  }
}

const ContentWithLoading = WithLoading(Content);

class ContentContainer extends PureComponent {
  state = {
    loading: true
  }

  loadData = (postData) => {
    const { token } = this.props;

    let dataCalls = [];

    dataCalls.push(
      this.props.fetchScreensAnalytics(token, postData, result => {})
    )

    Promise.all(dataCalls)
      .then(result => {
        this.setState({
          loading: false
        })
      })
  }

  componentDidMount() {
    const { token, enterprise, currentUser, location, master_admin } = this.props;

    this.loadData(this.props.postData);

    const values = {
      token,
      master_admin,
      enterprise,
      email: currentUser.email,
      page: location.pathname
    }

    activeCampaignEvent(values)
  }

  render() {
    const loading = this.state.loading;

    return (
      <ContentWithLoading 
        isLoading={loading}
        loadingReport={true}
        loadData={this.loadData}
        {...this.props}
      />
    )
  }
}

function mapStateToProps(state) {
  if (_.isEmpty(state.login.token) || _.isEmpty(state.login.company) || _.isEmpty(state.login.user)) {
    return {
      loggedOut: true
    }
  }

  return { 
    token: state.login.token,
    master_admin: state.login.master_admin,
    companyId: state.login.company._id,
    owner: state.login.user._id,
    currentUser: state.login.user,
    currentRole: state.login.user.user_type,
    screensAnalytics: state.analytics.screens
  };
}

const mapDispatchToProps = dispatch => ({
  fetchScreensAnalytics: (token, values, callback) => dispatch(fetchScreensAnalytics(token, values, callback))
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(ContentContainer)
